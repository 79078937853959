













import { Mixins } from 'vue-mixin-decorator'
import { Component, Prop } from 'vue-property-decorator'
import ApiMixin from '../../../mixin/Api'

import AppId from '@/util/appid'
import { BrandRouteResolver } from '@/util/brandRouteResolver'

/**
 * TODO:
 * spa/ と ajax/ が統合されたら、
 * 正しい型定義を参照させる
 */
interface Category {
  id: number
  name: string
  status_id: number
  title: string
}

@Component
export default class CategoryList extends Mixins<ApiMixin>(ApiMixin) {
  categories: Category[] = []

  @Prop({ required: true })
  brandName: string

  @Prop({ required: true })
  basePath: string

  get appId(): string {
    return AppId.getByBrandName(this.brandName)
  }

  linkUrl(category: Category): string {
    const query = `category=${category.name}`

    return `${this.basePath}?${query}`
  }

  get brandEnglishName(): string {
    return BrandRouteResolver.resolveBrandFromPathElement(this.brandName)
  }

  get categoryPathname(): string {
    if (this.brandEnglishName) {
      return `company.product.category.${this.brandEnglishName}`
    }

    return 'mycolor.product.category'
  }

  async mounted () {
    Promise.resolve().then(() => {
      return this.fetchFirstCategories()
    }).then((response) => {
      return this.onlyPublic(response.body.data)
    }).then((categories) => {
      this.categories = categories
    })
  }

  private async fetchFirstCategories () {
    return this.$get(`/api/common-proxy/${this.appId}/tags`, {
      pathname: this.categoryPathname,
    })
  }

  private onlyPublic (categories: Category[]) {
    return categories.filter(category => category.status_id === 1)
  }

}
