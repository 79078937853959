import max from 'lodash/max'
import min from 'lodash/min'

import { HOST_ID } from '@/util/appid'

/**
 * Config
 */
export default class MyColorConfig {
  /**
   * Ajax
   * ※Engine API直 -> cakePHPのAPI経由に変更になったためいくつかの設定値は不要になったが、
   * 今後利用する可能性も考慮して残しておく
   */
  static get AJAX() {

    return  {
      baseUrl: null,
      version: null,
      appid: HOST_ID,
      token: null,
    }

  }

  /**
   * Autoload
   */
  static get AUTOLOAD() {
    return {
      limit: 24, // 1回にロードするデータ数
    }
  }

  static get MESSAGE_NO_ITEM() {
    return 'No Recently Viewed Item.' // TODO -> configに
  }

  /**
   * 現在Itemlistの条件絞り込みのみで使用
   */
  static get DISP_NONE() {
    return '指定なし'
  }

  /**
   * 条件絞り込みの指数関数的な値段
   */
  static get CONDITION_PRICE() {
    return [
      // tslint:disable-next-line
      0, 100, 500, 1000, 1500, 2000, 2500, 3000, 3500, // Add by 500
      // tslint:disable-next-line
      4000, 5000, 6000, 7000, 8000, 9000, 10000, // Add by 1,000
      // tslint:disable-next-line
      15000, 20000, 25000, 30000, 35000, 40000, 45000, // Add by 5,000
      // tslint:disable-next-line
      50000, 60000, 70000, 80000, 90000, // Add by 10,000
      // tslint:disable-next-line
      100000, 150000, 200000, 250000, 300000, // Add by 50,000
    ]
  }

  static get CONDITION_PRICE_MIN(): number {
    return min(MyColorConfig.CONDITION_PRICE)!
  }

  static get CONDITION_PRICE_MAX(): number {
    return max(MyColorConfig.CONDITION_PRICE)!
  }

  static get MESSAGE_PRICE_MIN() {
    return '下限なし'
  }
  static get MESSAGE_PRICE_MAX() {
    return '上限なし'
  }

  /**
   * 後段で、商品毎に税率を変更可能にする対応が必須.
   * 対応後は、ここで参照している環境変数は廃止する.
   *
   * TEMPORARYは「第一弾の対応としての一時的なもので、すぐに廃止」の意.
   * @see https://github.com/my-color/front/issues/4358
   * @see https://github.com/my-color/front/issues/4359
   */
  static get TAX_RATE(): number {
    return parseFloat(process.env.CONSUMPTION_TAX_RATE_TEMPORARY || '0.1')
  }
}
