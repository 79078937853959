import { Either, Left, Right } from 'fp-ts/lib/Either'
import _ from 'lodash'

import Parameter, { ParameterList } from '@/models/api/payment/parameter/parameter'
import Constant, { isConstant } from '@/models/api/payment/parameter/type/constant'
import UserSelect, {
  isUserSelect,
  SelectOptionList
} from '@/models/api/payment/parameter/type/user-select'
import Variable, { isVariable } from '@/models/api/payment/parameter/type/variable'
import UserSelectResolverInterface, { DefaultResolver as UserSelectResolver } from './user-select-resolver'
import VariableResolverInterface, {
  DefaultResolver as VariableResolver,
  ResolverResource as VariableResolverResource,
} from './variable-resolver'

/**
 * @see https://my-color.esa.io/posts/534
 */
export default class ParametersBuilder {

  static create(
    parameterList: ParameterList<Parameter>,
    selectedOptionList: SelectOptionList,
    resource: VariableResolverResource
  ): ParametersBuilder {
    return new ParametersBuilder(
      parameterList,
      new VariableResolver(resource),
      new UserSelectResolver(selectedOptionList)
    )
  }

  constructor(
    private readonly parameterList: ParameterList<Parameter>,
    private readonly variableResolver: VariableResolverInterface,
    private readonly userSelectResolver: UserSelectResolverInterface,
    private readonly defaultValue: string = ''
  ) {}

  build(): Either<Error[], { [key: string]: string | null }> {
    const results: Array<Either<Error, NullableKeyValue>> = _.compact(
      this.parameterList.map((p) => {
        if (isConstant(p)) {
          return this.convertConstant(p)
        }
        if (isVariable(p)) {
          return this.convertVariable(p)
        }
        if (isUserSelect(p)) {
          return this.convertUserSelect(p)
        }

        return new Left(new Error(`Unexpected parameter type '${p.type}'.`))
      })
    )

    const [lefts, rights] = _.partition(results, r => r.isLeft())
    if (lefts.length > 0) {
      return new Left(lefts.map(left => left.value as Error))
    }

    const keyValueList = rights.map(right => right.value as NullableKeyValue)
    const keyValuePairs = keyValueList.map(keyValue => [keyValue.key, keyValue.value])

    return new Right(_.fromPairs(keyValuePairs))
  }

  private convertConstant(constant: Constant): Either<Error, NullableKeyValue> {
    return new Right({
      key: constant.id,
      value: constant.value,
    })
  }

  private convertVariable(variable: Variable): Either<Error, NullableKeyValue> {
    const resolved = this.variableResolver.resolve(variable)
    if (resolved.isLeft()) {
      return new Left(resolved.value)
    }

    return new Right({
      key: variable.id,
      value: resolved.value.toNullable() || this.defaultValue,
    })
  }

  private  convertUserSelect(userSelect: UserSelect): Either<Error, NullableKeyValue> {
    const resolved = this.userSelectResolver.resolve(userSelect)
    if (resolved.isLeft()) {
      return new Left(resolved.value)
    }

    return new Right({
      key: userSelect.id,
      value: resolved.value.toNullable() || this.defaultValue,
    })
  }
}

interface NullableKeyValue {
  key: string,
  value: string | null,
}
