






import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component
export default class ColorIcon extends Vue {
  @Prop({ required: true })
  color: string

  get iconClass() {
    return {
      'p-goods__color': true,
      [`p-goods__color--${this.color}`]: true,
    }
  }
}
