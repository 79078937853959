










import { CartItem, CartItemList } from '@/models/cart/cart-item'
import _ from 'lodash'
import { Mixins } from 'vue-mixin-decorator'
import { Component } from 'vue-property-decorator'

import { CookieService } from '@/services/cookie'
import {
  cacheUserAttribute,
  getUserAttributeCache,
  hasApiToken as hasWebCustomerServiceApiToken,
  WebCustomerServiceUserAttr,
} from '@/vendor/web-customer-service-tool'
import WebCustomerServiceTool from '@/vendor/WebCustomerServiceTool.vue'
import { log } from '@spa/log'
import { PluginMixin } from '@spa/mixins/plugin'
import { User } from '@spa/store/modules/user/types'

@Component({
  components: {
    WebCustomerServiceTool,
  },
})
export default class ThirdPartyTool extends Mixins<PluginMixin>(PluginMixin) {

  userAttribute: WebCustomerServiceUserAttr = {}
  isUserCurrentCompleted: boolean = false

  async created() {

    const userAttribute: WebCustomerServiceUserAttr | null = await this.getWebCustomerServiceUserAttribute()

    if (userAttribute) {
      this.userAttribute = userAttribute
      cacheUserAttribute(this.userAttribute)
      this.isUserCurrentCompleted = true
    }
  }

  get canExecuteWebCustomerService(): boolean {

    if (!this.isValidWebCustomerService) {

      return false
    }

    if (this.isGuest) {

      return true
    }

    // 仮会員と本会員はカート内金額が必要
    if (!this.isCartLoadCompleted) {

      return false
    }

    // 仮会員
    if (!this.isRegistered) {

      return true
    }

    // 本会員はユーザー情報が必要
    if (this.isUserCurrentCompleted) {

      return true
    }

    return false
  }

  get isValidWebCustomerService(): boolean {

    return hasWebCustomerServiceApiToken()
  }

  get isGuest(): boolean {
    return CookieService.isGuest()
  }

  get isRegistered(): boolean {
    return CookieService.isRegistered()
  }

  get isCartLoadCompleted(): boolean {
    return this.$store.state.cart.isLoadCompleted
  }

  get cartTotalPrice(): number {
    const items: CartItem[] = this.$store.getters['cart/currentItems']

    return new CartItemList(items).totalPrice
  }

  get userCurrent(): User {
    return this.$store.state.user.user
  }

  get errorOnLoadUserCurrent(): Error | undefined {
    return _.get(this.$store.state.user, 'error.load')
  }

  private async getWebCustomerServiceUserAttribute(): Promise<WebCustomerServiceUserAttr | null> {

    // 接客ツール側のログインは会員登録しているユーザーのログイン状態を対象としている。
    // そのため本会員以外はユーザー情報を取得しない
    if (!this.isRegistered) return null

    const userAttributeCache = getUserAttributeCache()
    if (userAttributeCache) {

      return userAttributeCache
    }

    await this.$store.dispatch('user/get')
    if (this.errorOnLoadUserCurrent) {
      log.error({ message: `vue third party tool: get user_current is failed.` })

      return null
    }

    return this.extractWebCustomerServiceUserAttribute(this.userCurrent)
  }

  private extractWebCustomerServiceUserAttribute(user: User): WebCustomerServiceUserAttr {

    return {
      id: user.id || '',
      elapsedDays: user.elapsed_days_last_order || 0,
      orderTimes: user.order_times || 0,
    }
  }
}
