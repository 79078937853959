import { ApiProductclass } from '@/models/api/productclass'

export default class ContactLensRoot {
  static create(root: ApiProductclass): ContactLensRoot {
    return new ContactLensRoot(root)
  }

  private constructor(private _root: ApiProductclass) {}

  get brandId(): string {
    return this._root.brandId
  }

  get brandEnglishName(): string {
    return this._root.belongingBrand.englishName
  }

  /**
   * 1箱あたりのレンズ枚数
   */
  get lensCountPerBox(): number {
    return this._root.get('aux_long_1', 0)
  }
}
