













import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component
export default class ConditionRadio extends Vue {

  @Prop({ required: true })
  item: any

  @Prop()
  checked: any | null

  get status() {
    return this.checked && this.checked.id === this.item.id ? 'checked' : ''
  }
}
