


































































import { Mixins } from 'vue-mixin-decorator'
import { Component } from 'vue-property-decorator'

import SearchSys2Cond from './cond/SearchSys2Cond.vue'
import SearchSys2Item from './item/SearchSys2Item.vue'
import SearchContactLensBase from './SearchContactLensBase.vue'

import SwitchByUa from '@/components/atoms/SwitchByUa.vue'
import { ApiContent } from '@/models/api/content'
import BrandFreeAreaService from '@/services/free-area/brand-free-area'
import SearchContactLens2FreeAreaService from '@/services/free-area/contact-lens/search-contact-lens2-free-area'
import { SearchContactLensCond } from '@/store/modules/searchContactLens/types'

@Component({
  components: {
    SearchSys2Cond,
    SearchSys2Item,
    SwitchByUa,
  },
})
export default class SearchContactLens2 extends Mixins<SearchContactLensBase>(SearchContactLensBase) {
  freeAreas: ApiContent[] = []
  initialSearchCond: SearchContactLensCond = {
    hasSPDrawing: true,
    matchValues: [],
  }

  // ページ読み込み開始時.
  created() {
    this.setInitialSearchCondition(this.initialSearchCond)
    this.$emit('loadingStart')
  }

  // ページ読み込み完了時.
  async mounted() {
    const freeAreaService = BrandFreeAreaService.createFromBrandName(
      'hl',
      SearchContactLens2FreeAreaService
    )

    this.setPageTitle('カラコン着けてスマホで撮ってみました！リアルな装着イメージ・発色を徹底比較！')

    const [freeArea] = await Promise.all([freeAreaService.fetch(), this.onMounted()])
    this.freeAreas = freeArea
    this.$emit('loadingStop')
  }
}
