import Stock from '@/models/item/stock'
import StockIsFew from '../../stock-is-few'
import DisplayRule from '../index'

export default class WebStockIsFew implements DisplayRule {
  constructor(private readonly spec: StockIsFew) { }

  shouldApply(stock: Stock): boolean {
    const display = stock.display

    if (display.isDisable || display.exists) {
      return false
    }

    return !this.spec.verify(stock.web)
  }
  apply(_: Stock): string {
    return `残りわずか`
  }}
