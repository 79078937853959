











import {
  InteractionFactory,
  StartApplyingSubscription
} from '@/components/organisms/subscription/interaction/start-applying-subscription'
import { log } from '@/log'
import { SubscriptionService } from '@/services/api/subscription'
import { callWithAuth } from '@/services/auth/call-with-auth'
import ErrorModalContainer from '@ajax/vue/components/organisms/ErrorModalContainer.vue'
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

type State = 'Normal' | 'Loading'
@Component({
  components: { ErrorModalContainer },
})
export default class ApplySubscriptionButton extends Vue {
  @Prop({ required: true })
  brand: string

  @Prop({ required: true })
  skuId: string

  @Prop({ default: 'button' })
  tag: string

  @Prop({ default: () => new SubscriptionService() })
  subscriptionService: SubscriptionService

  @Prop({ default: () => StartApplyingSubscription.defaultFactory })
  interactionFactory: InteractionFactory

  private state: State = 'Normal'

  async onClick() {
    this.state = 'Loading'

    try {
      await callWithAuth(async () => {
        const r = await this.interaction.start({ brand: this.brand, skuId: this.skuId })

        r.fold(
          (e) => { throw e },
          (result) => {
            location.href = result.nextPath
          }
        )
      })
    } catch (error) {
      log.error({ error })

      // error-modal-container の表示に使用.
      // モーダルを閉じたときのエラー情報クリアはコンテナ側で暗黙的に実行されている(要検討).
      this.$store.commit('error/set', {
        message: '申込手続きの準備に失敗しました。時間をおいて再度お試しください。',
      })
    } finally {
      this.state = 'Normal'
    }
  }

  get isLoading(): boolean {
    return this.state === 'Loading'
  }

  private get interaction(): StartApplyingSubscription {
    return this.interactionFactory(this.subscriptionService)
  }
}
