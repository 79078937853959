















































import _ from 'lodash'
import Vue from 'vue'
import { Component, Emit, Prop } from 'vue-property-decorator'

import BundleDiscountLabelWithStore from '@/components/molecules/discount/BundleDiscountLabelWithStore.vue'
import * as ImageConfig from '@/config/assets/image'
import { ApiProduct } from '@/models/api/product'
import { ApiProductclass } from '@/models/api/productclass'
import { CartItem } from '@spa/store/modules/cart/types'

import { SkuPriceList } from '@/models/item/sku'
import * as SKUFactory from '@/models/item/sku/factory/sku'
import AppId from '@/util/appid'
import Util from '@ajax/modules/util'

import { AddToCartEvent } from '@ajax/vue/components/organisms/addtocart/view-models/event'

@Component({
  components: {
    BundleDiscountLabelWithStore,
  },
})
export default class AddToCartList extends Vue {

  @Prop({ required: true })
  productclass: {
    id: string
    brand_id: string
    tags: any[]
    image_url: string
    images: any[]
    parts: any[]
    publish_at: Date,
    belonging_to: any[],
    product_name: string,
  }

  /**
   * このコンポーネントには複数のカート追加ボタンが（下位コンポーネントとして）含まれるが、
   * それらを一括してdisabledにするためのフラグ。
   * - カート追加ボタンは一度に一つしか押下できないようにユーザに制約したい、という前提がある。
   * カート追加ボタンごとに異なる取り扱いが必要になったら調整すること。
   */
  @Prop({ default: true })
  disabled: boolean = false

  /**
   * 会員状態に関わらず有効なSKU価格を受け取る。
   *
   * TODO: 本来はEngineから有効なSKU価格を持ったSKUオブジェクトを受け取りたい。
   * TODO: EngineからSKU価格込みのSKUオブジェクトが受け取れるか否かに関わらず、このコンポーネントの呼び出し側AddToCartListで事前にSKU価格を込めておくことがのぞましい。
   * しかしAddToCartListでは、Engineからのレスポンス型さえ適用されておらずany型が蔓延っており、多少のリファクタが必要である。
   * 当面はSKU価格の注入が実現したいことであり、リファクタは別途行うこととする。
   *
   */
  @Prop({ default: SkuPriceList.empty() })
  readonly skuPriceList: SkuPriceList

  /**
   * 有料会員機能がONの場合のみ親コンポーネントから渡されることに注意
   * @see /client/ajax/src/vue/components/organisms/addtocart/AddToCartItemList.vue
   */
  @Prop({ required: true })
  cartItems: CartItem[]

  @Prop({ required: true })
  isPaidMember: boolean

  get sortedParts() {
    return _.sortBy(this.productclass.parts, 'display_level').map((part) => {
      const product = new ApiProduct(part)
      const skuPrice = this.skuPriceList.findBySkuId(product.id)
      /**
       * TODO: SKU価格込みのオブジェクトをより上流から受け取れるようになれば、ここで無理やりマージしたり、マージ失敗時のエラー処理は不要になる。
       */
      if (!skuPrice) {
        this.publishInitializationErrorEvent(
          new SkuPriceNotFoundError(`Could not fetch sku price for ${product.id}`)
        )

        return
      }

      return {
        part,
        skuWithPrice: SKUFactory.createWithPrice(
          product,
          skuPrice
        ),
        cartInfo: Util.getCartInfo(part),
      }
    })
  }

  get completeLoad(): boolean {
    return !!this.productclass
  }

  get color() {
    const color = _.find(this.productclass.tags, (n) => {
      return n.pathname.indexOf('company.product.color.') === 0
    })

    return color.label
  }

  get brandId() {
    return this.productclass.brand_id
  }

  // TODO: This component can accept appId etc. from either parent component, or productclass.
  get appId(): string {
    return AppId.getByBrandName(this.brandName)
  }

  get thumbnail() {
    const fku = new ApiProductclass(this.productclass)
    const representativeImage = fku.representativeImage

    if (!representativeImage) {
      return ImageConfig.dummyImagePath
    }

    return representativeImage.thumbnailUrl
  }

  get loadingImage(): string {
    return ImageConfig.itemLoadingImagePath
  }

  get fkuId(): string {
    return this.apiProductclass.id
  }

  private get apiProductclass(): ApiProductclass {
    return new ApiProductclass(this.productclass)
  }

  private get brandName(): string {
    return this.apiProductclass.belongingBrand.englishName
  }

  @Emit('start')
  publishStartEvent(payload: AddToCartEvent): AddToCartEvent {
    return payload
  }

  @Emit('stop')
  publishStopEvent(payload: AddToCartEvent): AddToCartEvent {
    return payload
  }
  @Emit('initialization-error')
  publishInitializationErrorEvent(payload: Error): Error {
    return payload
  }
}

export class SkuPriceNotFoundError extends Error {}
