import { ApiProductclass } from '@/models/api/productclass'

export type FkuDecorator = (fku: ApiProductclass) => ApiProductclass

export const ignoreDiscountState: FkuDecorator = (fku) => {
  const noDiscountFku = Object.create(fku)
  Object.defineProperty(noDiscountFku, 'isOnDiscount', {
    get() {
      return false
    },
  })

  return noDiscountFku
}
