
export interface CatalogDemandErrorModalState {
  readonly show: boolean
  readonly message: string
}

export class NotShowErrorModal implements CatalogDemandErrorModalState {
  readonly show = false
  readonly message = ''
}
export class CatalogLoadError implements CatalogDemandErrorModalState {
  readonly show = true
  readonly message = 'カタログ商品情報の取得に失敗しました。<br/>少し時間をおいてから、ページを更新をお試しください。<br/>※改善されない場合、お問い合わせよりカスタマーセンターまでお問い合わせください。'
}

export class ApiRequestError implements CatalogDemandErrorModalState {
  readonly show = true

  constructor(private readonly error: { response: any }) { }

  get message(): string {
    if (!this.hasMessage) {
      return 'カタログ請求に失敗しました。<br/>※少し時間をおいて再度お試しいただくか、改善されない場合、お問い合わせよりカスタマーセンターまでお問い合わせください。'
    }

    return this.messageList.join('<br/>')
  }

  private get messageList(): string[] {
    const response = this.error.response
    const causes: string[] = response.body.cause || []

    return causes
      .map(cause => this.mapErrorKindToMessage(cause))
      .filter(message => message.length > 0)
  }

  private get hasMessage(): boolean {
    return this.messageList.length > 0
  }

  private mapErrorKindToMessage(cause: string): string {
    const mapList = [
      {
        key: 'UnitCountExceededError',
        message: '請求上限数に達しているため、カタログ請求できません。',
      },
    ]

    const found = mapList.find(map => cause.includes(map.key))

    return found ? found.message : ''
  }
}
