import { BundleDiscount } from '@/services/api/bundle-discount'

export interface BundleDiscountState {
  brands: BrandBundleDiscountState[],
}

export interface BrandBundleDiscountState {
  appId: string,
  bundleDiscounts: BundleDiscount[],
  isLoaded: boolean,
  error: Error | null,
}

export function createInitialBundleDiscount(): BundleDiscountState {
  return {
    brands: [],
  }
}
