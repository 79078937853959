import * as CurrentTime from '@/models/current-time/functions'
import { convertFromCartItemToSubscriptionCartItem } from '@/models/order/subscription/subscription-cart-item'

import * as DateFns from 'date-fns'
import { Either, Left, Right } from 'fp-ts/lib/Either'
import _ from 'lodash'
import { InvalidSalesPeriodError } from './errors'
import { afterSales, beforeSales, CartItem, inSales, SalesPeriodState } from './types'

export function filterValidItem(item: CartItem, whole: CartItem[]): boolean {
  const IN_STORE = 4

  if (!item.reasonForCannotBePurchased.isEmpty) {
    return false
  }

  if (!item.isFkuPublic || item.maxSellCount === undefined || item.stock === undefined) {
    return false
  }

  /* 注文可能である条件は「通常商品」と「予約商品」とで異なる */
  if (!canPurchaseItem(item) && !canPurchasePreOrderItem(item)) {
    return false
  }

  const itemResult = item.maxSellCount > 0  && item.stock > 0 && item.salesStatus === IN_STORE

  if (!item.isParent) {
    return itemResult
  }

  const childId = _.get(item.childItem, 'id')
  const child = whole.find(maybeChild => childId === maybeChild.rootId)

  if (!child) {
    return false
  }

  const childResult = filterValidItem(child, whole)

  return itemResult && childResult
}

export function canPurchaseItem(item: CartItem): boolean {
  const salesPeriod = salesPeriodStateOf(item)
  if (salesPeriod.isLeft()) {
    console.error(salesPeriod.value)

    return false
  }

  return salesPeriod.value.isOnSales
}

export const canPurchasePreOrderItem = (item: CartItem): boolean => {
  const salesPeriod = salesPeriodStateOf(item)
  if (salesPeriod.isLeft()) {
    console.error(salesPeriod.value)

    return false
  }

  return salesPeriod.value.isBeforeStart && item.isPreSales
}

export const salesPeriodStateOf = (
  item: CartItem
): Either<InvalidSalesPeriodError, SalesPeriodState> => {
  const startAt = item.salesStartAt
  const endAt = item.salesEndAt
  const now = CurrentTime.create().now

  if (DateFns.isBefore(endAt, startAt)) {
    return  new Left(
      new InvalidSalesPeriodError(
        'The sales end datetime is set before the start datetime.')
    )
  }

  const currentIsBeforeStart = DateFns.isBefore(now, startAt)
  const currentIsEqualStart = DateFns.isEqual(now, startAt)
  const currentIsAfterStart = DateFns.isAfter(now, startAt)
  const currentIsBeforeEnd = DateFns.isBefore(now, endAt)
  const currentIsEqualEnd = DateFns.isEqual(now, endAt)

  if (currentIsBeforeStart) {
    return new Right(beforeSales)
  }
  if ((currentIsEqualStart || currentIsAfterStart)
      && (currentIsBeforeEnd || currentIsEqualEnd)) {
    return new Right(inSales)
  }

  return new Right(afterSales)
}

/*
 * 2021/09/02 時点ではやむをえず、こうした関数を導入することでadhocに
 * 各ページでフィルタリングする形を取らざるを得ない.
 *
 * 最終的には、単発注文/定期購入それぞれの入口時点を互いに分離して、
 * それぞれで必要なI/Fを用意し、それぞれで必要なデータのみを振り分けるようにすることが
 * 望ましいと思われる.
 *
 * このような対応を取らざるを得ない理由として、そもそも既存の実装が
 * 「入り口では全部受け入れて、各ページで都度絞り込み」というadhocを前提とした形になっているため.
 * このadhocさを取り除こうとすると、つまるところカート内商品を参照する全ページに手が入る.
 */

/**
 * 単発注文として購入可能な商品.
 *
 * 最終的には、そもそもこの関数を都度コンポーネントで使わなくて良いようにすべき.
 *
 * @deprecated 将来的に廃止
 * @param item
 */
export const isSinglePurchasable = (item: CartItem) => {
  return !(
    (item.removed || false) || isSubscriptionItem(item)
  )
}
export const isSubscriptionItem = (item: CartItem): boolean =>
  convertFromCartItemToSubscriptionCartItem(item).isRight()
/**
 * サブスクリプション申し込み可能な商品
 *
 * 本来、この関数でサブスクリプション申し込み不可能な商品を全て弾くべきだが、
 * この関数をadhocに使う(使わざるをえない)pageコンポーネント側で、
 * さらにadhocに変換を行っている(行わざるを得ない)状態であるため、
 * ここではあえて、削除済み以外は全件取得している.
 * 最終的には、そもそもこの関数を都度コンポーネントで使わなくて良いようにすべき.
 *
 * @deprecated 将来的に廃止
 * @see ApplySubscription.displayCartItems
 * @see ApplySubscription.cartItems
 */
export const isSubscriptionApplicable = (item: CartItem) => {
  return !(item.removed || false)
}
