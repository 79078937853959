import { Membership } from '@/services/api/current-user'
import { EveryPageViewEvent } from '../../event'

export function handleEveryPageView(event: EveryPageViewEvent) {
  const { userId, paidMembership } = event

  if (!userId) return

  window.dataLayer.push({
    user_id: userId,
    crm_id: userId,
    /**
     * 登録済みの有料会員プランは配列型であり、GrowthPaletteプラットフォームとしては複数のプランに加入が可能である。
     * しかしここではLedian v1での「プランは高々一つまでしか加入できない」という固有の前提を反映している。
     * この前提が崩れる場合は、適切な基準で一つプランを選択したり、あるいは複数プランを文字列結合して、GTMに送信するように改修する。
     * @see https://github.com/my-color/front/pull/6149#discussion_r811675529
     * @see https://github.com/my-color/front/issues/6135#issuecomment-1047247106
     */
    crm_grp: extractEnabledFirstRegisteredPlan(paidMembership) || '',
  })
}

/**
 * 有料会員状態がONのユーザに対して、その登録済みプランの配列の先頭を返す。
 * 配列の順序が安定しているのかどうか、安定しているとしてどんな基準（e.g. 日付の降順）で決まっているのかは問わない。
 * したがって、一つしかプランが存在しないLedian v1の運用環境下で、配列型を解くための便宜的なメソッドとして使われることを想定している。
 *
 * @see https://github.com/my-color/front/pull/6149#discussion_r811640072
 * @see https://github.com/my-color/front/pull/6149#discussion_r811675529
 *
 * APIスキームを直接見ているが、
 * 本来はこのようなロジックはドメインモデルに変換された後のユーザ情報に対して適用したい。
 */
export function extractEnabledFirstRegisteredPlan(membership: Membership): string | undefined {
  if (!membership.enabled) return

  if (membership.registered_plans.length === 0) return

  return membership.registered_plans[0].external_id
}
