import StockValue from '../value/stock-value'

export default class StockIsFew {
  constructor(
    private readonly threshold: number,
    private readonly rejectEmpty = false
  ) { }

  get rejectWhenEmpty(): StockIsFew {
    return new StockIsFew(
      this.threshold,
      true
    )
  }

  verify(stock: StockValue): boolean {
    if (stock.isEmpty && this.rejectEmpty) {
      return false
    }

    return stock.amount <= this.threshold
  }
}
