
















import Vue from 'vue'
import { Component } from 'vue-property-decorator'

import LockBackground from '@/mixin/LockBackground'

@Component({
  mixins: [
    LockBackground,
  ],
})
export default class TransparentModal extends Vue {
  $refs: {
    scrollable: HTMLElement
  }

  get scrollable(): HTMLElement {
    return this.$refs.scrollable
  }
}
