import { PriceRange, PriceViewState } from '@/components/molecules/item/price/model/item-price-view'
import { ApiProductclass } from '@/models/api/productclass'

export function convertToViewState(fku: ApiProductclass): PriceViewState {
  const basePrice = {
    withTax: fku.price.getPriceAfterTax(),
    withoutTax: fku.price.getPrice(),
    range: fku.price.hasRange ? PriceRange.some() : PriceRange.none(),
    exists: fku.price.exists,
  }

  if (fku.isOnDiscount) {
    return {
      isOnDiscount: true,
      nonPaidMemberPrice: {
        ...basePrice,
        discountRate: fku.price.getDiscountRate(),
      },
      paidMemberPrice: null,
      showYenSymbol: false,
    }
  }

  return {
    isOnDiscount: false,
    nonPaidMemberPrice: basePrice,
    paidMemberPrice: null,
    showYenSymbol: false,
  }
}
