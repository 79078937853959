



















import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component
export default class Button extends Vue {
  @Prop()
  type: string

  @Prop()
  mode: string

  @Prop()
  size: string

  @Prop()
  disabled: boolean

  @Prop()
  htmlClass: any

  get _class() {
    return {
      'c-btn': true,
      'c-btn--disabled': this.disabled,
      [`c-btn--${this.mode}`]: !this.disabled,
      [`c-btn--${this.size}`]: !this.disabled && this.size != null,
      ...this.htmlClass,
    }
  }
}
