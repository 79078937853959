















import { FkuQuery } from '@/services/api/item/fku/fku-query'
import ProductclassService from '@/services/api/productclass'
import _ from 'lodash'
import Vue from 'vue'

import { Component, Prop } from 'vue-property-decorator'

import { EventBusForThirdParty } from '@/usecases/event-bus-for-third-party/event-bus'
import AppId from '@/util/appid'
import { BrandRouteResolver } from '@/util/brandRouteResolver'
import Util from '@ajax/modules/util'

@Component
export default class Favorite extends Vue {

  @Prop()
  id: string

  fkuProductclass: any = {}
  show: boolean = false
  like: boolean = false
  animation: boolean = false

  status: {
    liked: string;
    notLiked: string;
  } = {
    liked: 'c-favorite--register',
    notLiked: 'c-favorite--deregister',
  }

  classes: {
    root: string;
  } = {
    root: 'c-favorite',
  }

  itemService: ProductclassService

  get brandName(): string {
    return BrandRouteResolver.resolveBrandFromPathElement(this.$route.params.brand_english_name)
  }

  async created() {
    const appId = AppId.getByBrandName(this.brandName)
    this.itemService = new ProductclassService(appId)
  }

  /**
   * Productclass取得し、初期ライク状態を取得する。
   */
  async mounted() {
    const selectedColor = this.getSelectedColor()

    const query = FkuQuery.initialize()
      .filterByTag([
        `company.product.color.${this.brandName}.${selectedColor}`,
      ])

    const productclass = await this.itemService.listFkuBelongingToRoot(this.id, query.toObject())

    if (productclass.isLeft()) {
      this.show = false

      return
    }
    this.fkuProductclass = productclass.value[0]

    /**
     * 「商品詳細画面の閲覧イベント」は本来、このお気に入りボタン用のコンポーネントで送信すべきものではない。
     * しかしMPAである商品詳細画面に対してajaxで付属させている各種コンポーネントのうち、商品詳細=FKUの粒度でちょうどよい情報を持ったコンポーネントが見当たらないため、
     * 便宜上、一時的にここで送信している。
     *
     * すくなくともGAで求められているEコマース向けのデータは、ユーザ依存ではないので、PHPテンプレートで送信してもよいのかもしれない。
     * しかし価格については、有効な会員価格をどこかから取得してこなければならない。（価格はいったんMUSTではない）
     * その場合、商品詳細用のajaxで付属させられるVueコンポーネントが、個別に商品情報を取得するのではなく、一括で必要なデータを取得してから各コンポーネントにデータを受け渡すようにし、
     * 一括で取得する箇所で、「商品詳細画面の閲覧イベント」用に必要なデータを取得する、ということが考えれる。
     */
    EventBusForThirdParty.getInstance().publish('item-detail-view', {
      fkuId: this.fkuProductclass.id,
      fkuName: this.fkuProductclass.product_name,
    })

    /*
     * FIXME: 本来サブスクリプション申し込み用商品が表示できないようにするべきだが、 like=true とEngineがエラーを起こす
     *
     * ただし、サブスクリプション申し込み用商品は一覧にも表示せず詳細も表示させないので、
     * 結果的に問題とはならないかもしれない.
     * @see https://github.com/my-color/front/issues/5656#issuecomment-914223746
     * @see client/spa/src/components/templates/FavoriteItems.vue 同様の問題を抱えている
     */
    const likedItems = await this.itemService.listFkuForUser({ like: true })
    this.show = true

    if (likedItems.isLeft()) {
      this.like = false

      return
    }

    this.like = _.filter(likedItems.value, element => element.id === this.fkuProductclass.id).length >= 1
  }

  /**
   * ハートアイコンのON/OFFをするクラスに対応
   * TODO: Rename
   */
  get animate() {
    return {
      animate: this.animation,
    }
  }

  /**
   * 現ライク状態と逆でProductclass.likeを更新しビューもtoggleします。
   */
  async doLike() {

    const response = await this.itemService.putItem(this.fkuProductclass.id, { like: !this.like })

    if (response.isLeft()) {
      const message = 'お気に入りの際にエラーが発生しました。お手数ですがお問い合わせ下さい。'
      this.$store.commit('error/set', { message })

      return
    }

    const likeStatus = response.value['like_status']
    this.like = likeStatus === 'duplicated' || likeStatus === 'new'

    if (this.like) {
      this.animation = true

      EventBusForThirdParty.getInstance().publish('add-to-favorite', {
        fkuId: this.fkuProductclass.id,
        fkuName: this.fkuProductclass.product_name,
      })
    } else {
      this.animation = false
    }
  }

  /**
   * URLパラメータ[color]の値を取得します。
   */
  getSelectedColor() {
    const urlParams = Util.convertItemDetailUrlParamsToAssociativeArray()

    return 'color' in urlParams ? urlParams.color : ''
  }
}
