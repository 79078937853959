import { log } from '@/log'
import { ApiProductclass } from '@/models/api/productclass'
import ProductclassService from '@/services/api/productclass'
import { ApiService } from '@/services/api/service'
import { callWithAuth } from '@/services/auth/call-with-auth'
import AppId from '@/util/appid'
import * as Parallel from 'async-parallel'
import { Either, Left, Right } from 'fp-ts/lib/Either'
import _ from 'lodash'
import { Response } from 'superagent'
import { ApiClient } from './client'

const namespace: string = 'cart'

/**
 * TODO: spa, ajax双方にCartServiceが別れて存在してしまっているので、共通のCartServiceに集約する。
 * @see /client/src/models/api/cart/cart/README.md
 */
export class CartService extends ApiService {
  private readonly productclassService: ProductclassService

  constructor(appId?: string) {
    super(appId)

    this.productclassService = new ProductclassService(appId)
  }

  async list(): Promise<Either<Error, Response>> {
    try {
      const response = await callWithAuth(() => ApiClient.get(`/api/user/cart`))

      log.debug({ service: `${namespace}/list`, response })

      return new Right(response)
    } catch (error) {
      log.error({ error })

      return new Left(error)
    }
  }

  async get(cartId: string, query: any = {}): Promise<Either<Error, Response>> {
    try {
      const response = await ApiClient
        .get(`/api/user-proxy/${this.appId}/commerce_user_cart/${cartId}`)
        .query({
          cart_status: 1,
          ...query,
        })

      log.debug({ service: `${namespace}/get`, response })

      return new Right(response)
    } catch (error) {
      log.error({ error })

      return new Left(error)
    }
  }

  async add(
    productId: string,
    unitCount: number,
    option: any = {}
  ): Promise<Either<Error, Response>> {
    try {
      const response = await ApiClient
        .post(`/api/user-proxy/${this.appId}/commerce_user_cart`)
        .send({
          product_id: productId,
          unitCount,
          ...option,
        })

      log.debug({ service: `${namespace}/add`, response })

      return new Right(response)
    } catch (error) {
      log.error({ error })

      return new Left(error)
    }
  }

  async updateUnitCount(id: string, unitCount: number): Promise<Either<Error, Response>> {
    try {
      const response = await ApiClient
        .put(`/api/user-proxy/${this.appId}/commerce_user_cart/${id}`)
        .send({
          unitCount,
        })

      log.debug({ service: `${namespace}/updateUnitCount`, response })

      return new Right(response)
    } catch (error) {
      log.error({ error })

      return new Left(error)
    }
  }

  async remove(id: string): Promise<Either<Error, Response>> {
    try {
      const response = await ApiClient
        .delete(`/api/user-proxy/${this.appId}/commerce_user_cart/${id}`)

      log.debug({ service: `${namespace}/remove`, response })

      return new Right(response)
    } catch (error) {
      log.error({ error })

      return new Left(error)
    }
  }

  // tslint:disable-next-line
  async restore(
    productId: string,
    unitPrice: number,
    unitCount: number
  ): Promise<Either<Error, Response>> {
    try {
      const response = await ApiClient
        .post(`/api/user-proxy/${this.appId}/commerce_user_cart/`)
        .send({
          product_id: productId,
          unitPrice,
          unitCount,
        })

      log.debug({ service: `${namespace}/restore`, response })

      return new Right(response)
    } catch (error) {
      log.error({ error })

      return new Left(error)
    }
  }

  // TODO: Throttle simultaneous API requests number
  // TODO: Refactoring
  async join(rawItems: any[]): Promise<JoinedProductclassData[]> {
    // Extract id list
    const idList: Array<{ id: string, skuId: string, fkuId: string }> = _.map(rawItems, (item) => {
      return {
        id: item.id,
        skuId: _.get(item, 'product.id'),
        fkuId: _.get(item, 'product.product_class_id'),
      }
    })

    // Get Products
    const skuList = await this.getSkuList(idList)

    // Get FKU
    const fkuList = await this.getFkuList(idList)

    // Join
    const joined = _.map(idList, (entry) => {
      // sku/fkuが取れないこと自体は異常系. 今は作りの都合上optionalで代用してしまっている
      // https://github.com/my-color/front/pull/3072#discussion_r368895161
      const skuEntry = _.find(skuList, item => item.id === entry.id && !item.error)
      const fkuEntry = _.find(fkuList, item => item.id === entry.id && !item.error)

      return {
        id: entry.id,
        ...skuEntry && {
          sku: skuEntry.sku,
        },
        ...fkuEntry && {
          fku: fkuEntry.fku,
          root: fkuEntry.root,
        },
      }
    })

    log.debug({ service: `${namespace}/join`, joined })

    return joined
  }

  // tslint:disable-next-line:max-line-length
  private async getSkuList(idList: Array<{ id: string, skuId: string}>): Promise<Array<{ id: string, sku?: any, error?: boolean }>> {
    // Get Products
    const skuList = await Parallel.map(idList, async (entry) => {
      // TODO: Use better way to filter non-existing SKU
      if (!entry.skuId) {
        return {
          id: entry.id,
        }
      }

      const result = await this.getSku(entry.skuId)

      if (result.isLeft()) {
        return {
          id: entry.id,
          error: true,
        }
      }

      const sku = result.value.body.data

      return {
        id: entry.id,
        sku,
      }
    })

    return skuList as Array<{ id: string, sku: any }>
  }

  // tslint:disable-next-line:max-line-length
  private async getFkuList(idList: Array<{ id: string, fkuId: string}>): Promise<Array<{ id: string, fku?: any, root: ApiProductclass, error?: boolean }>> {
    const fkuList = await Parallel.map(idList, async (entry) => {
      // TODO: Use better way to filter non-existing FKU
      if (!entry.fkuId) {
        return {
          id: entry.id,
        }
      }

      const result = await this.getFku(entry.fkuId)

      if (result.isLeft()) {
        return {
          id: entry.id,
          error: true,
        }
      }

      const fku = result.value.body.data

      const rootInfo = _.get(fku, 'belonging_to.0')
      // https://github.com/my-color/front/pull/3072#discussion_r368895506
      // ベースとなる商品が親商品以外の場合、（今の所は）このリクエストは無くても良い。
      // 一方で、「レコードによって情報量が違う」「レコードによってリクエストの有無が違う」は混乱の元になると判断して、
      // 愚直にデータ取得を行っている.
      const rootResult = await this.productclassService.getRootWithChild(rootInfo.id)

      if (rootResult.isLeft()) {
        return {
          id: entry.id,
          error: true,
        }
      }

      // TODO: Check FKU tag
      return {
        id: entry.id,
        fku,
        root: rootResult.value,
        error: false,
      }
    })

    return fkuList as Array<{ id: string, fku: any, root: any }>
  }

  private async getSku(id: string): Promise<Either<Error, Response>> {
    try {
      const response = await ApiClient
        .get(`/api/common-proxy/${this.appId}/product2/${id}`)
        .query({
          with: 'stock~detail',
        })
        .set('Cache-Control', 'no-cache,no-store,must-revalidate,max-age=-1,private')

      return new Right(response)
    } catch (error) {
      log.error({ error })

      return new Left(error)
    }
  }

  private async getFku(id: string, query: any = {}): Promise<Either<Error, Response>> {
    /*
     * ヘッダ指定の必要性等がわからない点と、カート画面系ではひとまずコンポーネントごとのフィルタリングで間に合っているので
     * ここは https://github.com/my-color/front/issues/5355 の対応では触らないことにした.
     *
     * https://github.com/my-color/front/pull/2764 のように、join処理の最適化で不要になるかもしれない.
     * 最終的に消えるのであれば、なおのこと #5355 の時点でここをどうこうする必要性は薄れる.
     */
    try {
      const response = await ApiClient
        /*
         * productclassないしFKU情報の取得は ProductclassService に集約しているが、
         * この箇所は一時的にやむを得ず、直接 productclass2 を使って良いものとする.
         *
         * 理由は下記.
         * - /item/fku はFKUとしてサービスが公開する情報を提供するAPIである.
         *   - そのため、子商品やサブスクリプション申し込み用商品といったリソースは公開の対象外である
         *   - しかしカート内商品構築には、上記商品の情報も必要である
         *   - そのため、特に制限が設けられることのない proxy を使って、直接取得させている
         *     - ただしこれは「そうせざるを得ない」という消極的な判断である
         * - 本来、これら「カート内商品を構築するためのデータ集め」はclientで行うべきことではない
         *   - 知識の集約的にも、実装の一元化的にも、サーバー内部で実装されてしかるべき
         *   - したがって、ここでの「productclassを直接収集」は、サーバー側実装がclientに流出していると評価
         *   - であるならば、ここの実装は最終的にはサーバー側へ移植されるべきもの
         *   - サーバー側に移植された後なら、任意の形式でEngine APIを利用できる
         *
         * 上記の通り、最終的にはここにある「カート内商品を構築するためのデータ集め」は全てサーバーに移植され、
         * clientからは一掃されるということが前提になっており、それまでの一時しのぎとして
         * proxy経由でproductclass2を使うものである.
         */
      .get(`/api/common-proxy/${this.appId}/productclass2/${id}`)
      .query({
        with: 'belonging_to,indexsummary',
        ...query,
      })
      .set('Cache-Control', 'no-cache,no-store,must-revalidate,max-age=-1,private')

      return new Right(response)
    } catch (error) {
      log.error({ error })

      return new Left(error)
    }
  }
}

// tslint:disable-next-line:max-classes-per-file
export class JoinedProductclassData {
  id: string
  sku?: any
  fku?: any
  root?: ApiProductclass
}

export interface CartServiceStore {
  getServiceForBrand(brand: string): CartService
}
/**
 * カート画面での
 * - 個数変更
 * - 削除 → 元に戻す
 * - 価格変更による再登録
 * 等では、商品のブランド毎に使用するAppIdを変更する必要がある。
 *
 * 一方で、既存のCartServiceは、インスタンス生成時点で使用するAppIdが固定される。
 * そのため、別のappIdを使う都度新しいインスタンスを生成する必要があるが、
 * カンパニーカート等で複数のブランドの商品をまとめて処理する時に、
 * 商品処理の都度新しいオブジェクトを作るのは無駄が多い。
 *
 * そこで、一度使用したAppIdのオブジェクトは保存しておき、
 * 二回目以降は同じオブジェクトを再利用する仕組みを取っている。
 *
 * 将来的にはサーバー側で手当されるべきだが、それまでの一次対応。
 *
 * cf. GoF Flyweightパターン
 *
 * @see https://github.com/my-color/front/pull/3817#issuecomment-635745948
 */
export class CartServiceFlyweightStore implements CartServiceStore {
  private appIdClientMap: Map<string, CartService> = new Map<string, CartService>()

  constructor(
    private readonly factory: (appId: string) => CartService = appId => new CartService(appId)
  ) {}

  getServiceForBrand(brand: string): CartService {
    return this.getServiceFor(AppId.getByBrandName(brand))
  }

  private getServiceFor(appId: string): CartService {
    const service = this.appIdClientMap.get(appId)
    if (service) {
      return service
    }

    const newService = this.factory(appId)
    this.appIdClientMap.set(appId, newService)

    return newService
  }
}
