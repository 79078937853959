import _ from 'lodash'
import { MutationTree } from 'vuex'

import { initialRootState, RootState } from './types'

import { log } from '../log'

export const mutations: MutationTree<RootState> = {
  /**
   * vuexステート全体を初期化する.
   *
   * 主な用途として、未ログイン状態で購入の導線に入った時、
   * ログイン後にカート情報などをログイン後のアカウントで再取得するためなど.
   * @see https://github.com/my-color/front/issues/5719#issuecomment-920216576
   */
  init(state) {
    Object.assign(state, _.cloneDeep(initialRootState))

    log.debug({ commit: `init`, state })
  },
}
