// 将来的に、それぞれもっと適当なネーミングを与えたい + client/src配下に移動したい.
// 一旦は既存の実装を流用しつつ、より柔軟かつテスタブルな実装を作るための一捻り。
import { CookieService } from '@ajax/modules/cookie'
import { RegisterProvisionalResult, UserService } from '@ajax/modules/services/user'
import { Either, Right } from 'fp-ts/lib/Either'

export interface LoginState {
  isGuest(): boolean
}
export interface AuthApiGateway {
  registerProvisional(): Promise<Either<Error, RegisterProvisionalResult>>
}

export type MaybeFailRegisterProvisional = Either<Error, null>
export class AuthenticationService {
  static create(): AuthenticationService {
    return new AuthenticationService(
      CookieService,
      new UserService()
    )
  }

  constructor(
    private readonly loginState: LoginState,
    private readonly authApiGateway: AuthApiGateway
  ) { }

  async registerAsProvisilnalIf(
    condition: (loginState: LoginState) => boolean,
    onIgnored: () => MaybeFailRegisterProvisional = () => new Right(null)
  ): Promise<MaybeFailRegisterProvisional> {
    return condition(this.loginState) ?
      this.authApiGateway.registerProvisional().then(r => r.map(() => null)) :
      onIgnored()
  }
}
