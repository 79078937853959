import { render, staticRenderFns } from "./ShareBase.vue?vue&type=template&id=d06e85ba&scoped=true&"
import script from "./ShareBase.vue?vue&type=script&lang=ts&"
export * from "./ShareBase.vue?vue&type=script&lang=ts&"
import style0 from "./ShareBase.vue?vue&type=style&index=0&id=d06e85ba&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d06e85ba",
  null
  
)

export default component.exports