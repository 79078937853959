import { ActionTree } from 'vuex'

import { BrandService } from '@/services/api/brand'
import { InstanceResolver } from '@/util/instanceResolver'
import { log } from '@/util/log'
import { HistorySource } from '@/util/windowHistory'
import { RootState } from '../../types'
import { getUrlToContinueShopping } from './function/get-url-to-continue-shopping'
import { HistoryState, } from './types'

export const actions: ActionTree<HistoryState, RootState> = {
  async setUrlToContinueShopping({ state, commit }) {
    if (state.urlToContinueShopping !== null) {
      return
    }
    const existBrand = async (maybeBrand: string) => {
      // TODO Cart.vue#createdですでに同じチェックを行なっているので、
      // リクエスト数削減のためにexistBrandコールバックを渡せるようにしたい
      const brandService = new BrandService()

      return (await brandService.existByEnglishName(maybeBrand)).fold(
        (e) => {
          log.error(e)

          return false
        },
        exist => exist
      )
    }
    const historySource: HistorySource = {
      referrer: document.referrer,
      location,
    }
    const getUrl = getUrlToContinueShopping(InstanceResolver)(existBrand)(historySource, { fallback: '/' })

    const urlToContinueShopping = await getUrl()
    commit('setUrlToContinueShopping', { urlToContinueShopping })
  },
}
