import CurrentTime from '@/models/current-time/CurrentTime'
import ShippingFee from '@/models/shipping-fee'
import FixedShippingFee from '@/models/shipping-fee/fixed-shipping-fee'
import FreeShippingByTotalPrice from '@/models/shipping-fee/free-shipping-by-total-price'
import ShippingFeeByTotalPrice from '@/models/shipping-fee/shipping-fee-by-total-price'

export interface RawFixShippingFee {
  when_total_price_more_or?: number
  fee: number
  enabled_between?: {
    startAt: string
    endAt: string
  }
}

type BrandShippingFeeConfig = false | number | RawFixShippingFee

export class BrandShippingFeeBuilder {
  constructor(private raw: BrandShippingFeeConfig, private currentTime: CurrentTime) {}

  build(totalPrice: number): ShippingFee {
    return this.recursiveBuild(this.raw, totalPrice)
  }

  private recursiveBuild(raw: BrandShippingFeeConfig, totalPrice: number): ShippingFee {
    if (typeof raw === 'number') {
      return this.buildFromNumber(raw)
    }

    if (Array.isArray(raw)) {
      return this.buildFromArray(raw, totalPrice)
    }

    if (typeof raw === 'object') {
      return this.buildFromJson(raw, totalPrice)
    }

    return FreeShippingByTotalPrice.createWithDefaultBorder(totalPrice)
  }

  private buildFromNumber(raw: number): ShippingFee {
    return new FixedShippingFee(raw)
  }

  private buildFromArray(raw: BrandShippingFeeConfig[], totalPrice: number): ShippingFee {
    const selected = raw.find(config => this.applicableConfig(config))

    return selected !== undefined ?
      this.recursiveBuild(selected, totalPrice) :
      FreeShippingByTotalPrice.createWithDefaultBorder(totalPrice)
  }

  private buildFromJson(raw: RawFixShippingFee, totalPrice: number): ShippingFee {
    return this.applicableConfig(raw) ?
      ShippingFeeByTotalPrice.create(raw.fee, raw.when_total_price_more_or || 0, totalPrice) :
      FreeShippingByTotalPrice.createWithDefaultBorder(totalPrice)
  }

  /**
   * 有効な送料設定か否かを判定する
   * 判定基準：
   * - 期間指定がある場合、期間内か否か
   *
   * @param config
   * @returns boolean
   */
  private applicableConfig(config: BrandShippingFeeConfig): boolean {
    return config === false || typeof config === 'number' || this.withinEnabledPeriod(config)
  }

  private withinEnabledPeriod(config: RawFixShippingFee): boolean {
    return config.enabled_between === undefined ||
      new Date(config.enabled_between.startAt).getTime() <= this.currentTime.timestamp &&
      new Date(config.enabled_between.endAt).getTime() >= this.currentTime.timestamp
  }
}
