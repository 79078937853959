
















import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component
export default class ConditionMenuSelect extends Vue {
  @Prop({ default: '' })
  selected!: string

  @Prop({ default: 15 })
  maxLabelLength!: number

  @Prop({ default: '...' })
  ellipsisCharacter!: string

  @Prop({ default: '指定なし' })
  labelOnNoSelect!: string

  @Prop({ default: false })
  loading!: boolean

  get excerpted(): string {
    const str = this.selected
    const length = this.maxLabelLength

    if (str.length <= length) {
      return str
    }

    return str.slice(0, length) + this.ellipsisCharacter
  }
}
