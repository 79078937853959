import _ from 'lodash'
import { ActionTree } from 'vuex'

import BundleDiscountService from '@/services/api/bundle-discount'

import { RootState } from '../../types'
import { extractBrandBundleDiscountState } from './functions'
import { BundleDiscountState } from './types'

export const actions: ActionTree<BundleDiscountState, RootState> = {
  async load({ commit, state }, payload: LoadParameter) {
    const { appId, itemIds } = payload
    const brandState = extractBrandBundleDiscountState(state, appId)

    if (brandState && brandState.isLoaded) {
      return
    }

    const bundleDiscountService = new BundleDiscountService(appId)
    const bundleDiscountResult = await bundleDiscountService.listByItemIds(itemIds || [])

    if (bundleDiscountResult.isLeft()) {
      commit('set', {
        appId,
        error: bundleDiscountResult.value,
      })
    }

    commit('set', {
      appId,
      bundleDiscounts: bundleDiscountResult.value,
    })
  },
}

export interface LoadParameter {
  appId: string,
  itemIds?: string [],
}
