
export interface CartScopeEq {
  readonly kind: ScopeKind
  readonly value: ScopeValue

  toString(): string
}

export class CartScope implements CartScopeEq {
  static valueOf({ kind, value, label }: {
    kind: string,
    value: string,
    label: string
  }): CartScope {
    return new this(
      ScopeKind.valueOf(kind),
      ScopeValue.valueOf(value),
      ScopeLabel.valueOf(label)
    )
  }

  private constructor(
    readonly kind: ScopeKind,
    readonly value: ScopeValue,
    readonly label: ScopeLabel
  ) { }

  get id(): string {
    return `${this.kind}.${this.value}`
  }

  equals(other: CartScopeEq): boolean {
    return this.kind.equals(other.kind)
      && this.value.equals(other.value)
  }

  toString(): string {
    return this.id
  }
}

abstract class BaseValue<Tag extends string> {
  abstract readonly _tag: Tag

  protected constructor(protected readonly value: string) {}

  equals(other: this): boolean {
    return this.value === other.value
  }

  toString(): string {
    return this.value
  }
}
export class ScopeKind extends BaseValue<'scope_kind'> {
  static valueOf(value: string): ScopeKind {
    return new this(value)
  }
  readonly _tag: 'scope_kind' = 'scope_kind'

  get isBrand(): boolean {
    return this.value === 'brand'
  }
}
export class ScopeValue extends BaseValue<'scope_value'> {
  static valueOf(value: string): ScopeValue {
    return new this(value)
  }
  readonly _tag: 'scope_value' = 'scope_value'
}
class ScopeLabel extends BaseValue<'scope_label'> {
  static valueOf(value: string): ScopeLabel {
    return new this(value)
  }
  readonly _tag: 'scope_label' = 'scope_label'
}
