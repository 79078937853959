import { BaseConverter } from '@/util/number'

export function convertLongIdToShortId(longId: string): string {
  const positionBeginOfShortId = -5

  return longId.split('-').slice(positionBeginOfShortId).join('-')
}

export function convertShortIdToDisplayId(shortId: string): string {
  return BaseConverter.convertBaseFrom16To62(shortId.replace(/-/g, ''))
}

export function convertLongIdToDisplayId(longId: string): string {
  const shortId = convertLongIdToShortId(longId)

  return convertShortIdToDisplayId(shortId)
}
