







import _ from 'lodash'
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import { ApiProductclass } from '@/models/api/productclass'
import * as RecentlyViewedRepository from '@/models/repository/recently-viewed/recently-viewed-repository'
import RecentlyViewed from './RecentlyViewed.vue'

@Component({
  components: {
    RecentlyViewed,
  },
})
export default class BrandRecentlyViewed extends Vue {
  @Prop({
    default: () => (items => items),
  })
  decorator: (items: any[]) => any[]

  @Prop({ required: true })
  brand: string

  @Prop({ default: () => RecentlyViewedRepository.instance })
  repository: RecentlyViewedRepository.RecentlyViewedRepository

  filterOnCurrentBrand(items: any[]): any[] {
    // TODO: プレーンなオブジェクトでなくApiProduct、最終的にはドメインオブジェクトを使う
    const itemList = items.map(item => new ApiProductclass(item))
    const idsOnCurrentBrand = itemList
      .filter(item => item.belongingBrand.englishNameIs(this.brand))
      .map(item => item.id)

    const filtered = items.filter(
      item => _.includes(idsOnCurrentBrand, item.id)
    )

    return this.decorator(filtered)
  }
}
