









import { Either, Left, Right } from 'fp-ts/lib/Either'
import Vue, { VueConstructor } from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import { log } from '@/log'
import { PaymentType } from '@/models/app-config/behavior/types'
import { ApiClient } from '@ajax/modules/services/client'
import CancelView from '../organisms/external-payment/CancelView.vue'
import ErrorView from '../organisms/external-payment/ErrorView.vue'
import { ViewModel } from '../organisms/external-payment/model/view-model'
import SuccessView from '../organisms/external-payment/SuccessView.vue'

@Component({})
export default class ExternalPaymentCallback extends Vue {

  @Prop({ required: true })
  paymentType: PaymentType

  @Prop({ required: true })
  status: 'success' | 'cancel' | 'error'

  @Prop({ required: true })
  encodedData: string

  @Prop({ required: true })
  apiPathBase: string

  @Prop({ required: true })
  encodedQueries: string

  view: VueConstructor = Vue.extend({
    template: `<div class="loading"></div>`,
  })
  viewModel: ViewModel | null = null
  apiResponse: ApiResponse | null = null

  isLoading: boolean = true

  // 形式の詳細は知る必要がないのでany
  get postData(): any {
    return JSON.parse(this.encodedData)
  }

  // ApiClient.postではクエリを設定できないため、エンドポイントに直接付加する
  get apiPath(): string {
    return `${this.apiPathBase}/${this.status}?${this.encodedQueries}`
  }

  async created() {
    (await this.callApi()).fold(
      (l: Error) => {
        log.error({ error: l })
        this.view = ErrorView
        this.viewModel = {
          nextPath: this.mypagePath,
          messages: ['注文が正常に完了しなかった可能性があります。ご注文履歴をご確認ください。'],
          buttonLabel: '注文履歴を確認する',
          autoRedirect: false,
        }
      },
      (r: ApiResponse) => {
        this.createView(r)
        this.viewModel = this.convertResponseToViewModel(r)
      }
    )

    this.isLoading = false
  }

  private get mypagePath(): string {
    return '/s/mypage'
  }

  private async callApi(): Promise<Either<Error, ApiResponse>> {
    try {
      const response = await ApiClient
        .post(this.apiPath)
        .send(this.postData)

      log.debug({ response })

      return new Right(response.body.data)
    } catch (error) {
      return new Left(error)
    }
  }

  private createView(response: ApiResponse): void {
    if (response.status === 'success') {
      this.view = SuccessView
    }
    if (response.status === 'cancel') {
      this.view = CancelView
    }
    if (response.status === 'error') {
      this.view = ErrorView
    }
  }

  private convertResponseToViewModel(response: ApiResponse): ViewModel {
    return {
      nextPath: response.next_path,
      messages: response.messages,
      buttonLabel: response.button_label,
      autoRedirect: response.auto_redirect,
    }
  }
}

interface ApiResponse {
  status: 'success' | 'cancel' | 'error'
  next_path: string
  messages: string[]
  button_label: string
  auto_redirect: boolean
}
