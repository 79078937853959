import _ from 'lodash'
import superagent, { SuperAgentRequest } from 'superagent'

import { callWithAuth } from '@/services/auth/call-with-auth'

class Api {
  get(endpoint: string, query: object = {}): SuperAgentRequest {
    let request: SuperAgentRequest = superagent
      .get(endpoint)
      .withCredentials()
      .query(query)
      .set('Accept', 'application/json')

    if (this.shouldBasicAuth()) {
      request = this.getRequestWithBasicAuth(request)
    }

    return this.invokeRequest(() => request)
  }

  postPlain(endpoint: string, data: object = {}): SuperAgentRequest {
    let request: SuperAgentRequest = superagent
      .post(endpoint)
      .withCredentials()
      .send(`timestamp:${data['timestamp']}	kind:${data['kind']}	url:${data['url']}`)
      .set('Accept', 'application/json')
      .set('Content-Type', 'text/plain')

    if (this.shouldBasicAuth()) {
      request = this.getRequestWithBasicAuth(request)
    }

    return this.invokeRequest(() => request)
  }

  post(endpoint: string, data: object = {}): SuperAgentRequest {
    let request: SuperAgentRequest = superagent
      .post(endpoint)
      .withCredentials()
      .send(data)
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/x-www-form-urlencoded')

    if (this.shouldBasicAuth()) {
      request = this.getRequestWithBasicAuth(request)
    }

    return this.invokeRequest(() => request)
  }

  put(endpoint: string, data: object = {}): SuperAgentRequest {
    let request: SuperAgentRequest = superagent
      .put(endpoint)
      .withCredentials()
      .send(data)
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/x-www-form-urlencoded')

    if (this.shouldBasicAuth()) {
      request = this.getRequestWithBasicAuth(request)
    }

    return this.invokeRequest(() => request)
  }

  delete(url: string): SuperAgentRequest {
    let request: SuperAgentRequest =  superagent
      .delete(url)
      .withCredentials()
      .set({ Accept: 'application/json' })

    if (this.shouldBasicAuth()) {
      request = this.getRequestWithBasicAuth(request)
    }

    return this.invokeRequest(() => request)
  }

  protected invokeRequest(handler: () => SuperAgentRequest): SuperAgentRequest {
    return handler()
  }

  private shouldBasicAuth(): boolean {
    const id = process.env.BASIC_AUTH_ID
    const password =  process.env.BASIC_AUTH_PASSWORD

    return !_.isEmpty(id) && !_.isEmpty(password)
  }

  private getBasicAuthInfo(): { id: string, password: string} {
    return {
      id: process.env.BASIC_AUTH_ID || '',
      password: process.env.BASIC_AUTH_PASSWORD || '',
    }
  }

  private getRequestWithBasicAuth(request: SuperAgentRequest): SuperAgentRequest {
    const { id, password } = this.getBasicAuthInfo()

    return request.auth(id, password)
  }
}

class ApiWithAuth extends Api {
  protected invokeRequest(handler: () => SuperAgentRequest): SuperAgentRequest {
    return callWithAuth(handler) as SuperAgentRequest
  }
}

const client = new Api()
const clientWithAuth = new ApiWithAuth()

export { client as ApiClient, clientWithAuth as ApiClientWithAuth }
