const CR = '\r'
const LF = '\n'
const CRLF = `${CR}${LF}`

export function split(val: string): string[] {
  const crLfReplacedByLf = val.replace(new RegExp(CRLF, 'g'), LF)
  const unifiedByLf = crLfReplacedByLf.replace(new RegExp(CR, 'g'), LF)

  return unifiedByLf.split(LF)
}
