export const MAX_RATING_VALUE: number = 5
export const MAX_REVIEW_LENGTH: number = 200

export interface UserReview {
  color: Color
  reviewer: Reviewer
  comment: Comment
  rating: Rating
  approval: Approval
}

export interface Color {
  id: string
  label: string
}
export interface Reviewer {
  nickname: string
}
export interface Comment {
  text: string
}
export interface Rating {
  value: number
}
export interface Approval {
  approved: boolean
  pending: boolean
}
