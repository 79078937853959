import _ from 'lodash'

import { UtilService } from '@spa/services/util'
import { Price } from './price'

export class FkuPrice {
  constructor(
    private _prices: Price[],
    private _asDiscount: boolean = false
  ) { }

  get rateOfDiscount(): number {
    // TODO: undefinedが問題になるケースが有るか調査
    // そもそもとしてundefinedが問題になるのは、this._prices = [] の場合.
    // すなわち価格が存在しない場合だが、そんなケースがそもそも有って良いのか疑問。
    // ↑のケースが存在しない & 許容しないことが確定したら、
    // コンストラクタで[]が入ってきたら例外を上げるようにしてしまえば良い
    return _.max(
      this._prices.map(price => price.rateOfDiscount)
    ) || 0
  }

  get minRegularPrice(): number {
    return _.min(this.regularPrices) || 0
  }

  get min(): number {
    const prices = this._asDiscount ? this.discountPrices : this.regularPrices

    return _.min(prices) || 0
  }

  get max(): number {
    const prices = this._asDiscount ? this.discountPrices : this.regularPrices

    return _.max(prices) || 0
  }

  get exists(): boolean {
    return this.prices.length > 0
  }

  get hasRange(): boolean {
    return this.min !== this.max
  }

  get asDiscounted(): FkuPrice {
    return new FkuPrice(
      this._prices,
      true
    )
  }
  get asRegular(): FkuPrice {
    return new FkuPrice(
      this._prices,
      false
    )
  }

  toString(): string {
    if (!this.exists) {
      return '¥ - '
    }
    const beforeTax = UtilService.getPriceBeforeTax(this.min)
    const formatted = beforeTax.toLocaleString('ja-JP', { style: 'currency', currency: 'JPY' })
    const stringPrice = `${formatted}${this.hasRange ? '~' : ''}`

    if (this._asDiscount) {
      return `${stringPrice} (${this.rateOfDiscount}%OFF)`
    }

    return stringPrice
  }

  /**
   * 税抜価格
   *
   * @note getPriceWithoutTax の方が明確？
   */
  getPrice(): string {
    if (!this.exists) {
      return '¥ - '
    }
    const beforeTax = UtilService.getPriceBeforeTax(this.min)

    return beforeTax.toLocaleString('ja-JP', { style: 'currency', currency: 'JPY' })
  }

  /**
   * 税込価格
   *
   * @note getPriceWithTax の方が明確？
   */
  getPriceAfterTax(): string {
    if (!this.exists) {
      return ''
    }

    return this.min.toLocaleString('ja-JP', { style: 'currency', currency: 'JPY' })
  }

  getDiscountRate(): string {
    if (this._asDiscount) {
      return `(${this.rateOfDiscount}%OFF)`
    }

    return ''
  }

  private get prices(): number[] {
    return this._asDiscount ? this.discountPrices : this.regularPrices
  }
  private get regularPrices(): number[] {
    return this._prices.map(p => p.regular).filter(p => p > 0)
  }
  private get discountPrices(): number[] {
    return this._prices.map(p => p.discounted).filter(p => p > 0)
  }
}
