import BehaviorConfig from '@/models/app-config/behavior/behavior'
import ContactLensFKU from '@/models/item/fku/contact-lens'
import ContactLensRoot from '@/models/item/root/contact-lens'
import ContactLensSKU from '@/models/item/sku/contact-lens'
import CanRequestRestockSpec from '@/models/item/sku/sale-status/specification/each/can-request-restock-spec'

export default class ContactLensItemDetailStore {
  private _selectedFkuId: string | null = null
  private _selectedLeftSku: ContactLensSKU | null = null
  private _selectedRightSku: ContactLensSKU | null = null
  private _selectedQuantity: number = 0

  get selectedFku(): ContactLensFKU | null {
    return this._selectedFkuId ?
    this.fkus.find(fku => fku.id === this._selectedFkuId) || null :
    null
  }

  /**
   * 生成済みのskuリストをキャッシュすることでパフォーマンス改善の余地あり。
   * @see https://github.com/my-color/front/pull/5173#discussion_r605332497
   */
  get skus(): ContactLensSKU[] {
    return this.selectedFku ? this.sortSku(this.selectedFku.skus(this._behavior)) : []
  }

  get selectedLeftSku(): ContactLensSKU | null {
    return this._selectedLeftSku
  }

  get selectedRightSku(): ContactLensSKU | null {
    return this._selectedRightSku
  }

  get selectedQuantity(): number {
    return this._selectedQuantity
  }

  /**
   * 数量プルダウンで選択可能な最大数。左と右それぞれのSKUの選択可能上限数の大きい方を取る。
   *
   * 左と右で同じSKU（度数）を選択している場合、Math.floor(当該SKUの選択可能上限数 / 2)を返す。
   * 例えば、左右で同じSKUを選択していて、そのSKUの選択可能上限数が10の場合、数量プルダウンで選択
   * できる範囲は5までとなる。
   */
  get maxSelectableQuantity(): number {
    const NUMBER_OF_EYES_SELECTED = [this._selectedLeftSku, this._selectedRightSku].filter(s => s).length
    if (this._selectedLeftSku && this._selectedRightSku) {
      return this._selectedLeftSku.id.short === this._selectedRightSku.id.short ?
        Math.floor(this._selectedLeftSku.maxSelectableCount / NUMBER_OF_EYES_SELECTED) :
        Math.max(this._selectedLeftSku.maxSelectableCount, this._selectedRightSku.maxSelectableCount)
    }

    if (this._selectedLeftSku) {
      return this._selectedLeftSku.maxSelectableCount
    }

    if (this._selectedRightSku) {
      return this._selectedRightSku.maxSelectableCount
    }

    return 0
  }

  /**
   * 左目の選択箱数。
   * 実際に数量プルダウンで選択された値と選択上限数のいずれか小さい方を返す。
   */
  get selectedLeftBoxCount(): number {
    return this._selectedLeftSku ?
      Math.min(this._selectedQuantity, this._selectedLeftSku.maxSelectableCount) :
      0
  }

  /**
   * 右目の選択箱数。
   * 実際に数量プルダウンで選択された値と選択上限数のいずれか小さい方を返す。
   */
  get selectedRightBoxCount(): number {
    return this._selectedRightSku ?
      Math.min(this._selectedQuantity, this._selectedRightSku.maxSelectableCount) :
      0
  }

  /**
   * 左目と右目を独立でカウントして、トータルで選択された箱数
   */
  get selectedTotalBoxCount(): number {
    return this.selectedLeftBoxCount + this.selectedRightBoxCount
  }

  /**
   * 左目と右目を独立でカウントして、トータルで選択されたレンズ枚数
   */
  get selectedTotalLensCount(): number {
    return this.selectedTotalBoxCount * (this.root ? this.root.lensCountPerBox : 0)
  }

  get totalPrice(): number {
    return this.leftTotalPrice + this.rightTotalPrice
  }

  /**
   * 左目度数・右目度数の両方が選択されている場合にtrue、それ以外の場合にfalseを返す。
   */
  get bothEyesAreSelected(): boolean {
    return this._selectedLeftSku !== null && this._selectedRightSku !== null
  }

  /**
   * 選択数量が、左目または右目の選択可能上限数を上回る場合にtrue、それ以外の場合にfalseを返す。
   */
  get selectedQuantityIsLargerThanMaxSelectableQuantityOfLeftOrRightSku(): boolean {
    const NUMBER_OF_EYES = 2

    return this.bothEyesAreSelected && this.selectedQuantity * NUMBER_OF_EYES > this.selectedTotalBoxCount
  }

  /**
   * 左右いずれかのSKUが新作リクエストの場合にそのSKUを返す。それ以外の場合にはnullを返す。
   */
  get newItemRequest(): ContactLensSKU | null {
    if (this._selectedLeftSku !== null && this._selectedLeftSku.cartInfo.newitem) {
      return this._selectedLeftSku
    }

    if (this._selectedRightSku !== null && this._selectedRightSku.cartInfo.newitem) {
      return this._selectedRightSku
    }

    return null
  }

  /**
   * SKUが一つ以上選択されていてかつ販売開始後の時のみtrue
   */
  get selectedSKUsAreOverRegularSaleStart(): boolean {
    if (this.selectedTotalBoxCount === 0) return false

    return (this._selectedLeftSku === null || this._selectedLeftSku.isOverRegularSaleStart) &&
           (this._selectedRightSku === null || this._selectedRightSku.isOverRegularSaleStart)
  }

  /**
   * 左目の合計金額 = 左目の選択箱数 * 1箱あたりの金額
   */
  private get leftTotalPrice(): number {
    return this._selectedLeftSku ? this._selectedLeftSku.price * this.selectedLeftBoxCount : 0
  }

  /**
   * 右目の合計金額 = 右目の選択箱数 * 1箱あたりの金額
   */
  private get rightTotalPrice(): number {
    return this._selectedRightSku ? this._selectedRightSku.price * this.selectedRightBoxCount : 0
  }

  constructor(
    readonly root: ContactLensRoot,
    readonly fkus: ContactLensFKU[],
    private _behavior: BehaviorConfig
  ) {
    this.init()
  }

  selectFku(selected: ContactLensFKU): void {
    this._selectedFkuId = selected.id
    this.adjustSkuSelection()
  }

  selectSku(leftOrRight: 'left' | 'right', selected: ContactLensSKU | null): void {
    if (leftOrRight === 'left') this._selectedLeftSku = selected
    if (leftOrRight === 'right') this._selectedRightSku = selected
    this.adjustQuantitySelection()
  }

  selectQuantity(count: number): void {
    this._selectedQuantity = count
  }

  /**
   * 左右いずれかのSKUが再入荷リクエストの場合にそのSKUを返す。それ以外の場合はnullを返す
   */
  restockRequest(behavior: BehaviorConfig): ContactLensSKU | null {
    const spec = new CanRequestRestockSpec(behavior)

    if (this._selectedLeftSku !== null && spec.verify(this._selectedLeftSku.product)) {
      return this._selectedLeftSku
    }

    if (this._selectedRightSku !== null && spec.verify(this._selectedRightSku.product)) {
      return this._selectedRightSku
    }

    return null
  }

  private init() {
    this._selectedFkuId = this.fkus.length > 0 ? this.fkus[0].id : null
    this._selectedLeftSku = this.skus.length > 0 ? this.skus[0] : null
    this._selectedRightSku = this.skus.length > 0 ? this.skus[0] : null
    this._selectedQuantity = this.maxSelectableQuantity > 0 ? 1 : 0
  }

  /**
   * SKUを度数タグのdisplayLevelの昇順でソートする
   */
  private sortSku(skus: ContactLensSKU[]): ContactLensSKU[] {
    return skus.sort((a, b) => {
      return a.powerTag == null || b.powerTag == null ? 0 : a.powerTag.displayLevel - b.powerTag.displayLevel
    })
  }

  /**
   * FKUを変更したとき、以下のように新たなSKUを自動選択する。
   * - 既存の選択度数と同じ度数のSKUが新たなFKU下に存在する場合にはその同じ度数のSKUを選択する。
   * - 既存の選択度数と同じ度数のSKUが新たなFKU下に存在しない場合には、nullを選択する。
   */
  private adjustSkuSelection(): void {
    setTimeout(() => {
      const newLeftSkuWithSamePower = this.skus.find((sku) => {
        return this._selectedLeftSku && sku.power === this._selectedLeftSku.power
      })
      this._selectedLeftSku = newLeftSkuWithSamePower || null

      const newRightSkuWithSamePower = this.skus.find((sku) => {
        return this._selectedRightSku && sku.power === this._selectedRightSku.power
      })
      this._selectedRightSku = newRightSkuWithSamePower || null

      this.adjustQuantitySelection()
    })
  }

  /**
   * FKU and/or SKUを変更し、以下の条件に当てはまる場合に選択数量を自動修正する
   * - 新たな選択可能上限数量が既存の選択数量を下回る場合 -> 選択数量を新たな選択可能上限数量に合わせて下方修正する。
   * - 変更前の選択可能上限数量が0で、変更後の選択可能上限数量が1以上の場合 -> 選択数量を0から1に上方修正する
   */
  private adjustQuantitySelection(): void {
    if (this.maxSelectableQuantity < this.selectedQuantity) {
      this.selectQuantity(this.maxSelectableQuantity)
    } else if (this.selectedQuantity === 0 && this.maxSelectableQuantity >= 1) {
      this.selectQuantity(1)
    }
  }
}
