

















import { interaction } from '@/components/organisms/cart/add-item/interaction'
import { log } from '@/log'
import CommandAddItemToCart from '@/models/cart/command/add-item-to-cart'
import { SkuPrice } from '@/models/item/sku'
import { DefaultFetchSkuPrice } from '@/models/item/sku/repository/impl/fetch-sku-price'
import AppId from '@/util/appid'
import { BrandRouteResolver } from '@/util/brandRouteResolver'
import { CartService } from '@ajax/modules/services/cart'
import { ProductService } from '@ajax/modules/services/product'
import ErrorModalContainer from '@ajax/vue/components/organisms/ErrorModalContainer.vue'
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

/**
 * カート追加時のパラメータとして、null等を渡さないことを型レベルで担保するために使用.
 * @see AddItemToCartViaExternalSystem.valueFulfilled
 * @internal AddItemToCartViaExternalSystem で内部的に利用することを想定.
 */
interface FulfilledState {
  readonly skuId: string
  readonly countInt: number
  readonly skuPrice: SkuPrice
}

@Component({
  components: {
    ErrorModalContainer,
  },
})
/**
 * 運用者が任意のhtmlへカート追加ボタンを挿入可能とするためのコンポーネント.
 *
 * カート追加ボタンの挿入は、console等の外部システムによるhtml登録を通じて行われる想定.
 *
 * @see https://github.com/my-color/front/issues/5582
 * @see client/src/components/global/README.md
 */
export default class AddItemToCartViaExternalSystem extends Vue {
  @Prop({ required: true })
  readonly skuId: string

  @Prop({ required: true })
  readonly brand: string

  @Prop({ default: '1' })
  /**
   * 運用者がhtml経由でこのコンポーネントを使う想定なので、文字列形式で受け取る.
   * :count="1" あるいは v-bind:count="1" といったvue固有の文法を
   * 運用者へ露出させない意図.
   */
  readonly count: string

  @Prop({ default: 'div' })
  readonly tag: string

  isLoading: boolean = false

  skuPrice: SkuPrice | null

  showModal: boolean = false

  async created() {
    const maybeSkuPrice = await DefaultFetchSkuPrice.create(this.brand).fetchById(this.skuId)
    maybeSkuPrice.fold(
      (error) => {
        log.error({ error })
      },
      (price) => {
        this.skuPrice = price
      }
    )
  }

  get valueFulfilled(): boolean {
    return this.countInt !== null &&
      this.skuPrice !== null
  }

  get countInt(): number | null {
    const res = parseInt(this.count, 10)

    if (isNaN(res)) {
      log.warn(`${this.count} is not number,  so count is treat as null`)

      return null
    }

    return res
  }
  /**
   * Add a product to cart.
   * When the product is the parent of a hidden set(紐付き商品), also add its child in the background.
   */
  async onClick() {
    if (!this.valueFulfilledInternalForTypePredicate()) {
      log.warn(`some values are not fulfilled, so ignore click event`)

      return
    }

    this.isLoading = true

    const result = await this.addItem({
      item: {
        id: this.skuId,
        count: this.countInt,
        price: this.skuPrice,
      },
    })

    result.mapLeft((errorMsg: string) => {
      this.$store.commit('error/set', { message: errorMsg })
      this.showModal = true
    })

    this.isLoading = false
  }

  onModalClose() {
    this.showModal = false
  }

  /**
   * type guard を型推論に利用する場合、関数経由でなくてはならない.
   *
   * @private
   * @internal
   * @see https://typescript-jp.gitbook.io/deep-dive/type-system/typeguard#riterarunotype-guard
   */
  private valueFulfilledInternalForTypePredicate(): this is FulfilledState {
    return this.valueFulfilled
  }

  private get addItem() {
    const brandName = BrandRouteResolver.resolveBrandFromPathElement(this.brand)

    return interaction.addItem({
      command: new CommandAddItemToCart(
        new CartService(brandName),
        new ProductService(AppId.getByBrandName(brandName))
      ),
      location,
    })
  }
}
