export interface CatalogDemand {
  id: string
  status: CatalogDemandStatus
  price: CatalogDemandPrice
}
export interface CatalogDemandStatus {
  can_demand: boolean
  detail: {
    stock_exist: boolean
    now_on_sale: boolean
    first_demand: boolean
  }
}
export interface CatalogDemandPrice {
  value: number
  consumption_tax: {
    include: number
    exclude: number
  }
}

export class CatalogDemandDto {
  constructor(private readonly raw: CatalogDemand) { }

  get id(): string {
    return this.raw.id
  }

  get canDemand(): boolean {
    return this.raw.status.can_demand
  }
  get price(): number {
    return this.raw.price.value
  }
}
