import { Either, Left, Right } from 'fp-ts/lib/Either'

import { log } from '@/log'
import { ApiClient } from './client'

const namespace: string = 'info'

export interface Version {
  fullText: string
  display: boolean
}

export class InfoService {

  static async versioning(): Promise<Either<Error, Version>> {
    try {
      const response = await ApiClient.get('/api/info/versioning')

      log.debug({ service: `${namespace}/versioning`, response })

      return new Right(response.body.data)
    } catch (error) {
      log.error({ error })

      return new Left(error)
    }
  }
}
