import ShippingFee from './index'

/**
 * 合計金額に応じて決定される送料。
 * 例：「5,000円以上で送料100円」など
 */
export default class ShippingFeeByTotalPrice implements ShippingFee {
  static create(fee: number, border: number, totalPrice: number): ShippingFee {
    return new ShippingFeeByTotalPrice(fee, border, totalPrice)
  }

  private constructor(
    readonly fee: number,
    readonly border: number,
    readonly totalPrice: number
  ) { }

  canApply(): boolean {
    return this.totalPrice >= this.border
  }

  remainingPriceUntilFreeShipping(): number | null {
    if (this.fee !== 0) {
      return null
    }

    const diff = this.border - this.totalPrice

    return diff > 0 ? diff : 0
  }
}
