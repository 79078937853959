
type ValueFinder<T, K> = (i: T) => K
type Eq<C> = (a: C, b: C) => boolean

const defaultFinder = i => i
const defaultEq = <T>(a: T, b: T) => a === b

export const orderByValue =
  <T, K = T>(findVal: ValueFinder<T, K> = defaultFinder) =>
  (eq: Eq<K> = defaultEq) =>
  (src: T[], values: K[]): T[] => values.reduce(
    (ordered: T[], key) => {
      const next = src.find((s) => {
        const value = findVal(s)

        return eq(key, value)
      })

      return next ? [...ordered, next] : ordered
    },
    []
  )
