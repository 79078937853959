import { CreditcardPaymentProviderViewConfig } from '@/services/payment/creditcard-payment-provider/creditcard-payment-provider-service'

export const paygentViewConfig: CreditcardPaymentProviderViewConfig = {
  registerCardForm: {
    requireCardOwner: false,
    requireSecurityCode: true,
  },
  paymentForm: {
    requireSecurityCode: false,
  },
}
