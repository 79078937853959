import { filterValidItem } from '@spa/store/modules/cart/function'
import { CartItem as StoreCartItem } from '@spa/store/modules/cart/types'
import { None, Option, Some } from 'fp-ts/lib/Option'
import _ from 'lodash'
import _get from 'lodash/get'

/**
 * TODO: この型定義はstoreではなくドメイン層での定義としたい
 *
 * 現状
 * - この型定義はあちこちで参照されている
 * - 「カート」における概念整理が無い
 * という状況なので、一旦storeで定義されている型定義をそのまま流用.
 */
export type CartItem = StoreCartItem

export class CartItemList {
  static readonly empty = new CartItemList([])

  constructor(readonly list: CartItem[]) { }

  get isEmpty(): boolean {
    return this.list.length === 0
  }
  get hasItem(): boolean {
    return !this.isEmpty
  }

  get totalPrice(): number {
    return this.purchasableItems.reduce(
      (sum: number, item: CartItem) => sum + item.unitCount * item.unitPrice,
      0
    )
  }
  get totalCount(): number {
    return this.purchasableItems.reduce(
      (sum: number, item: CartItem) => sum + item.unitCount,
      0
    )
  }

  concat(other: CartItemList): CartItemList {
    return new CartItemList([...this.list, ...other.list])
  }

  contain(item: CartItem): boolean {
    return this.find(i => i.id === item.id).isSome()
  }

  find(finder: (item: CartItem) => boolean): Option<CartItem> {
    const found = this.list.find(finder) || null

    return found === null ? None.value : new Some(found)
  }

  findChildOf(item: CartItem): Option<CartItem> {
    return this.find(itemInCart => _get(item.childItem, 'id') === itemInCart.rootId)
  }

  map<T>(callback: (item: CartItem) => T): T[] {
    return this.list.map(callback)
  }
  filter(filter: (item: CartItem) => boolean): CartItemList {
    return new CartItemList(this.list.filter(filter))
  }

  some(filter: (item: CartItem) => boolean): boolean {
    return this.list.some(filter)
  }

  get length(): number {
    return this.list.length
  }

  merge(other: CartItemList): CartItemList {
    return new CartItemList([...this.list, ...other.list])
  }

  partition(filter: (item: CartItem) => boolean): [CartItemList, CartItemList] {
    const [matched, other] = _.partition(this.list, filter)

    return [
      new CartItemList(matched),
      new CartItemList(other),
    ]
  }

  private get purchasableItems(): CartItem[] {
    return this.list.filter(
      item => filterValidItem(item, this.list) && !item.removed
    )
  }
}

export class NewCartItem {
  static createFromCartItem(item: CartItem): NewCartItem {
    return new this(item)
  }

  readonly brandEnglishName: string

  private constructor(
    private readonly item: CartItem
  ) {
    if (this.unitCount <= 0) {
      throw new Error(`unit count must be > 0, but actual is ${this.unitCount}`)
    }
    if (this.unitPrice < 0) {
      throw new Error(`unit price must be >= 0, but actual is ${this.unitPrice}`)
    }
    if (!this.item.brandEnglishName) {
      throw new Error(`item must have brand name, but empty`)
    }

    this.brandEnglishName = this.item.brandEnglishName
  }

  get skuId() {
    return this.item.skuId
  }
  get unitCount(): number {
    return this.item.unitCount
  }
  get unitPrice(): number {
    return this.item.unitPrice
  }
  get isWithPaidMembershipPrice(): boolean {
    return this.item.isWithPaidMembershipPrice
  }
}
