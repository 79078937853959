import { ApiImage } from '../../api/image'

export default interface ImageSorter {
  /**
   * 複数のソートを実行する必要がある場合に、新たなImageSorterインスタンスをthenメソッドで連結する。
   * 例：「display_levelの昇順で画像ソートした後、特定のdisplay_levelの画像を先頭に移動する」など
   *
   * @param ImageSorter $another
   * @return ImageSorter
   */
  then(another: ImageSorter): ImageSorter

  /**
   * 画像のソート処理を実行する。
   *
   * @param ApiImage[] $images
   * @return ApiImage[]
   */
  sort(images: ApiImage[]): ApiImage[]
}

export abstract class BaseImageSorter implements ImageSorter {
  /**
   * @param ImageSorter $another
   * @return ImageSorter
   */
  then(another: ImageSorter): ImageSorter {
    return new ThenImageSorter(this, another)
  }

  /**
   * @param ApiImage[] $images
   * @return ApiImage[]
   */
  abstract sort(images: ApiImage[]): ApiImage[]
}

/**
 * 2つのソート処理の連結を実現するクラス。
 */
class ThenImageSorter extends BaseImageSorter {
  /**
   *
   * @param former 先に実行するソート処理を表現するImageSorterインスタンス
   * @param latter 後に実行するソート処理を表現するImageSorterインスタンス
   */
  constructor(private former: ImageSorter, private latter: ImageSorter) {
    super()
  }

  /**
   * @param ApiImage[] $images
   * @return ApiImage[]
   */
  sort(images: ApiImage[]): ApiImage[] {
    return this.latter.sort(this.former.sort(images))
  }
}
