









import Vue from 'vue'

import { Component, Emit, Prop } from 'vue-property-decorator'

@Component
export default class RatingStar extends Vue {
  @Prop({ required: true })
  selected: boolean

  get classname(): string {
    const suffix = this.selected ? '--selected' : ''

    return `p-user-review-area__list-item__rating-icon${suffix}`
  }

  @Emit()
  click(): void {
    // do nothing
  }
}
