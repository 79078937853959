import { ApiProduct } from '@/models/api/product'
import { ApiTag } from '@/models/api/tags'
import GetStockStatus from '@/usecase/get-stock-status'
import * as _ from 'lodash'
import SKU from '.'
import BehaviorConfig from '../../app-config/behavior/behavior'
import SKUId from './id'

import { CartInfo } from '@ajax/modules/util'

/**
 * 既存のSKUオブジェクトはアパレルの知識をも包含するため、SKUオブジェクトを継承ではなくコンポジションすることで、
 * SKUオブジェクトのアパレルに関するI/FがContactLensSKUから公開されないようにする。
 * @see https://github.com/my-color/front/pull/4819#discussion_r556284662
 */
export default class ContactLensSKU {
  public static wrap(sku: SKU, product: ApiProduct, behavior: BehaviorConfig, cartInfo: CartInfo): ContactLensSKU {
    return new ContactLensSKU(sku, product, behavior, cartInfo)
  }

  private constructor(
    private readonly sku: SKU,
    private readonly _product: ApiProduct,
    private readonly _behavior: BehaviorConfig,
    public readonly cartInfo: CartInfo
  ) {}

  get id(): SKUId {
    return this.sku.id
  }

  get product(): ApiProduct {
    return this._product
  }

  get power(): string {
    return this.powerTag ? this.powerTag.label : ''
  }

  get powerTag(): ApiTag | undefined {
    return _.find(this._product.tags, (tag) => {
      return tag.pathname.indexOf('company.product.size.') === 0
    })
  }

  /**
   * １回で選択できる上限数。コンソールで設定された購入上限数と在庫数のいずれか小さい方の値を返す。
   */
  get maxSelectableCount(): number {
    return Math.min(this._product.maxSellCount, this.getStockStatus.amount(this.sku.stock))
  }

  get getStockStatus(): GetStockStatus {
    return GetStockStatus.createViaConfig(this._behavior)
  }

  get stockStatusText(): string {
    return this.getStockStatus.toText(this.sku.stock)
  }

  get isSoldOut(): boolean {
    return this.getStockStatus.isSoldOut(this.sku.stock)
  }

  get price(): number {
    return this._product.isOnDiscountSale ? this._product.get('sale_price') : this._product.get('sell_price')
  }

  get isOverRegularSaleStart(): boolean {
    return this.product.overRegularSaleStart
  }
}
