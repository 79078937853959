import Router, { Route, RouteConfig } from 'vue-router'

import AutoLoadTrigger from '@ajax/vue/components/molecules/AutoLoginTrigger.vue'

const routes: RouteConfig[] = [
  {
    path: '*',
    component: AutoLoadTrigger,
    props: (route: Route) => ({
      userKey: route.query.o,
      hash: route.query.hash,
    }),
  },
]

export default new Router({
  mode: 'history',
  routes,
})
