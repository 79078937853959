import $ from 'jquery'
import { DirectiveOptions } from 'vue/types/options'

import '~bootstrap/dropdown'

const dropdown: DirectiveOptions = {
  inserted(el: HTMLElement) {
    $(el).dropdown()
  },
}

export default dropdown
