
















































import { Validator } from 'vee-validate'
import Vue from 'vue'
import { Component, Emit, Inject, Prop } from 'vue-property-decorator'

import Mailto from '@/components/atoms/Mailto.vue'
import LockBackground from '@/mixin/LockBackground'
import EnvironmentService from '@/services/environment'
import Modal from '@ajax/vue/components/templates/TransparentModal.vue'

@Component({
  components: {
    Mailto,
    Modal,
  },
  mixins: [
    LockBackground,
  ],
})
export default class RegisterModal extends Vue {
  @Inject()
  $validator: Validator

  @Prop({ required: true })
  value: string

  @Prop({ required: true })
  character: string

  @Prop({ required: true })
  productId: string

  registerMailSubject: string = 'このまま送信してください'

  onInput(event) {
    this.$emit('input', event)
  }

  onSubmit(event) {
    this.$emit('submit', event)
  }

  @Emit('open-mailer')
  openMailer() {
    // nothing to do
  }

  onClose(event) {
    this.$emit('close', event)
  }

  get registerMailRecipient(): string {
    const environmentService = new EnvironmentService()
    const recipientDomain = environmentService.requestMail.recipientDomain

    return `${this.productId}@${recipientDomain}`
  }
}
