
export class Price {
  constructor(private regularPrice: number, private discountPrice: number) { }

  get regular(): number {
    return this.regularPrice
  }

  get discounted(): number {
    return this.discountPrice
  }

  get rateOfDiscount(): number {
    const percent = 100

    return Math.round((1 - (this.discounted / this.regular)) * percent)
  }
}
