/**
 * TODO: 将来的に、INSTANCE_TYPEを一元管理したい
 * 現状(at 20200701)はBeanstalkとCircleCI、それぞれにINSTANCE_TYPEを設定しているが、
 * 単純に二重管理で作業ミスを誘発しかねないので、一元管理したい。
 *
 * サーバー側での一元管理も考えたが、client側でのINSTANCE_TYPEの設定値取得に非同期処理が必須になる（API経由）。
 * 一方で、現状ルーティング設定のような「事前のセットアップ系」の時点で非同期処理が必要になる。
 * ```typescript
 * const main = async (getSetting) => {
 *   const settings = await getSetting()
 *
 *   Vue.use(createRouter(settings))
 * }
 *
 * main(setting.fetch())
 * ```
 * のように、初期化処理全体を非同期関数で囲んでしまうという手も考えられるか。
 *
 * あるいは、behavior.jsonのようにファイルから設定値を読み込む形式にしつつ、
 * CIで各環境に応じた設定値を後追いで書き込むパターンもありそうか。
 * この場合、INSTANCE_TYPEはCIのみでの管理になる。
 * この形式だと既存実装にはほぼ影響を与えずHTTP通信も増えないが、
 * 代わりに「どの設定で動作しているのか」が確認しにくい（CircleCIでは、環境変数は一律マスクされてしまい、後からの確認が不可能）.
 *
 * いずれにせよ、影響範囲が広いので https://github.com/my-color/front/pull/4066 では同期前提とする。
 */
export class InstanceResolver {
  public static isSingle(): boolean {
    return process.env.INSTANCE_TYPE === 'SINGLE'
  }
  public static isMulti(): boolean {
    return process.env.INSTANCE_TYPE === 'MULTI'
  }

  public static getSingleBrand(): string {
    return process.env.SINGLE_BRAND || ''
  }
}

export type InstanceResolverInterface = Pick<typeof InstanceResolver, 'isMulti' | 'isSingle' | 'getSingleBrand'>
