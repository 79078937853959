import { Events } from './event'

export type T = keyof Events

export type Subscriber<EventType extends T> = (e: Events[EventType]) => void

/**
 * イベントのタイプごとに用意されるオブザーバ。pub/subの機能を持つ。
 * 実装内容自体は一般的なEventEmitterと変わるところはないので、必要であれば適宜、専門のライブラリに置き換えてもよい。
 * @see https://github.com/my-color/front/pull/6148#discussion_r811557575
 */
export interface IPublisher<EventType extends T> {
  /**
   * イベントタイプに対して定義されているイベント変数を処理するための、イベントハンドラをこのオブザーバに登録する。
   * @return 登録されたイベントハンドラを購読解除するための関数
   */
  addSubscriber: (subscriber: Subscriber<EventType>) => Unsubscriber
  /**
   * イベントタイプに対して定義されているイベント変数をこのオブザーバに送信する。
   */
  publish: (event: Events[EventType]) => void
}

export class Publisher<EventType extends T> implements IPublisher<EventType> {
  private subscribers: Array<Subscriber<EventType>> = []

  addSubscriber(subscriber: Subscriber<EventType>): () => void {
    this.subscribers = [...this.subscribers, subscriber]

    /** subscribeで渡されたイベントハンドラを購読解除するための関数 */
    return () => {
      this.subscribers = this.subscribers.filter(l => l !== subscriber)
    }
  }

  publish(event: Events[EventType]): void {
    this.subscribers.forEach(subscriber => subscriber(event))
  }
}

type Unsubscriber = () => void

export default Publisher
