import * as DateFns from 'date-fns'

import { ApiProduct } from '@/models/api/product'
import { Sku as SkuInProductclass } from '@/models/api/productclass/sku'
import SKU, { SkuPrice, SkuWithPrice } from '@/models/item/sku'
import SKUId from '@/models/item/sku/id'
import BehaviorConfig from '../../../app-config/behavior/behavior'
import ContactLensSKU from '../contact-lens'
import SalePeriod from '../sale-period'
import * as SaleStatusFactory from './sale-status'
import * as StockFactory from './stock'

import Util from '@ajax/modules/util'

export function create(product: SkuInProductclass): SKU {
  const id = new SKUId(product.id, product.shortId)
  const saleStatus = SaleStatusFactory.create(product.salesStatus)
  const stock = StockFactory.create(product.stockDetail)
  const salePeriod = new SalePeriod(
    DateFns.parse(product.regularSaleStartAt),
    DateFns.parse(product.regularSaleEndAt)
  )

  return new SKU(
    id,
    stock,
    saleStatus,
    salePeriod
  )
}

export function createWithPrice(product: ApiProduct, price: SkuPrice): SkuWithPrice {
  return new SkuWithPrice(
    create(product),
    price
  )
}

export function createFromSkuInProductclass(sku: SkuInProductclass): SKU {
  const id = new SKUId(sku.id, sku.shortId)
  const saleStatus = SaleStatusFactory.create(sku.salesStatus)
  const stock = StockFactory.create(sku.stockDetail)
  const salePeriod = new SalePeriod(
    DateFns.parse(sku.regularSaleStartAt),
    DateFns.parse(sku.regularSaleEndAt)
  )

  return new SKU(
    id,
    stock,
    saleStatus,
    salePeriod
  )
}

export function createContactLens(product: ApiProduct, behavior: BehaviorConfig): ContactLensSKU {
  return ContactLensSKU.wrap(
    create(product),
    product,
    behavior,
    Util.getCartInfoForApiProduct(product)
  )
}
