import { isEmpty } from 'lodash'

/** 購入不可事由の種類が増えるに応じて追加していく。 */
export type ReasonTypeForCannotBePurchased =
  'priceDifferenceNotChecked' |
  'shouldPromoteCountSelection'

export interface ReasonForCannotBePurchased {
  type: ReasonTypeForCannotBePurchased
  errors: string[]
  message: string
}

export interface ShouldPromoteCountSelection extends ReasonForCannotBePurchased {
  type: 'shouldPromoteCountSelection'
  count: {
    min: number,
    max: number,
  }
}

export function isShouldPromoteCountSelection(arg: ReasonForCannotBePurchased): arg is ShouldPromoteCountSelection {
  return arg.type === 'shouldPromoteCountSelection'
}
export class ReasonForCannotBePurchasedList {
  static create(list: ReasonForCannotBePurchased[]): ReasonForCannotBePurchasedList {
    return new ReasonForCannotBePurchasedList(list)
  }
  static asEmpty(): ReasonForCannotBePurchasedList {
    return new ReasonForCannotBePurchasedList([])
  }

  constructor(private readonly _list: ReasonForCannotBePurchased[]) {}

  get isEmpty(): boolean {
    return isEmpty(this._list)
  }

  get list(): ReasonForCannotBePurchased[] {
    return this._list
  }

  add(reason: ReasonForCannotBePurchased): ReasonForCannotBePurchasedList {
    return new ReasonForCannotBePurchasedList([
      ...this._list,
      reason,
    ])
  }
}
