import _ from 'lodash'

import { log } from '@/log'
import ApiBrand from './brand'
import { ApiImage } from './image'
import { FkuPrice } from './price/fku-price'
import { ApiProduct } from './product'
import Resource from './resource'

import { Sku, SkuFromEngine, SkuFromIndexSummary } from './productclass/sku'
import WholeColorFamilyList from './productclass/whole-color-family-list'
import Category from './tag/category'
import ColorFamily from './tag/color-family'

export class ApiProductclass extends Resource {
  static readonly TAG_PATHNAME_ROOT = 'mycolor.product.root'
  static readonly TAG_PATHNAME_FKU = 'mycolor.product.fku'
  static readonly RESTOCK = 'mycolor.product.restock'

  get shortId(): string {
    return this.get('shortid', '')
  }

  get productName() {
    return this.get('product_name')
  }
  get productNumber(): string {
    return this.get('product_number')
  }
  get getAverageRating(): number {
    return this.get('aux_double_2', 0)
  }
  get getReviewCount(): number {
    return this.get('aux_long_6', 0)
  }

  get brands(): ApiBrand[] {
    return this.get('brands', []).map(b => new ApiBrand(b))
  }

  get belongingBrand(): ApiBrand {
    return this.brands.find(b => b.idIs(this.brandId))!
  }

  get brandId(): string {
    // brand.id は文字列だが、productclass.brand_idは数値で返ってきているので、形を揃えている
    return `${this.get('brand_id')}`
  }

  /**
   * 本Productclassに紐づくProduct (SKU) の情報を取得する。
   * with=partで取得可能なpartsフィールドに由来し、Engineの最新の情報が得られる。
   *
   * カートや決済の文脈では、新鮮で多くの情報が取得できる本メソッドが利用される想定。
   *
   * @see https://my-color.esa.io/posts/696
   * @see https://github.com/my-color/front/issues/5588
   */
  get products(): ApiProduct[] {
    return this.get('parts', []).map((part: any) => new ApiProduct(part))
  }

  /**
   * 本Productclassに紐づくProduct (SKU) の情報を取得する。
   * partsまたはskuフィールドから取得可能なSKUに関する情報を返却する。
   * with=partでparts、with=indexsummaryでskuが取得できる。
   *
   * 商品一覧など負荷が高く、必ずしも最新の情報が必要でない場合に利用される想定。
   *
   * - with=partは都度内部的にSKUの情報を集めるのでEngineの負荷が高い
   * - with=indexsummaryはデータの反映にタイムラグが発生する
   *
   * という特徴があるので、partsがあれば優先的に利用し、
   * partsがない場合はskuを利用してオブジェクトを生成する方針をとっている。
   *
   * 本メソッドの利用側はSku[]がEngine/indexsummaryどちらに由来するものかに関わらず透過的に扱える。
   * したがって、古い情報かもしれない（し、そうでないかもしれない）情報であることを受け入れられるケースでしか利用してはならない。
   *
   * @see https://my-color.esa.io/posts/696
   * @see https://github.com/my-color/front/issues/5588
   */
  get materializedSkuList(): Sku[] {
    const parts: any[] = this.get('parts', [])
    if (parts.length !== 0) {
      return parts.map((raw: any) => new SkuFromEngine(raw))
    }

    const decodedJson: any = JSON.parse(this.get('sku'))
    if (Array.isArray(decodedJson)) {
      return decodedJson.map((raw: any) => new SkuFromIndexSummary(raw))
    }

    log.warn({
      message: `Could not create skuList because neither 'parts' nor 'sku' is unexpected format.`,
      object: this,
    })

    return []
  }

  /**
   * @return html string
   */
  get content(): string {
    return this.get('content', '')
  }
  /**
   * @return html string
   */
  get material(): string {
    return this.get('data_clob', '')
  }

  get isRoot(): boolean {
    return this.hasTag(ApiProductclass.TAG_PATHNAME_ROOT)
  }
  get isFku(): boolean {
    return this.hasTag(ApiProductclass.TAG_PATHNAME_FKU)
  }

  get belongingTo(): ApiProductclass[] {
    return this.get('belonging_to', []).map((b: any) => new ApiProductclass(b))
  }

  get belongedBy(): ApiProductclass[] {
    return this.get('belonged_by', []).map((b: any) => new ApiProductclass(b))
  }

  get displayLevel(): number {
    return this.get('display_level', 0)
  }

  get usingTo(): ApiProductclass[] {
    return this.get('using_to', []).map((b: any) => new ApiProductclass(b))
  }

  get parts(): ApiProduct[] {
    return this.get('parts', []).map((b: any) => new ApiProduct(b))
  }

  get root(): ApiProductclass | null {
    if (this.isRoot) {
      return this
    }

    return this.belongingTo.find(pc => pc.isRoot) || null
  }

  get colorFamilies(): ColorFamily[] {
    if (this.isRoot) {
      return []
    }

    return this.tags.filter(t => ColorFamily.match(t))
  }
  get wholeColorFamilies(): WholeColorFamilyList {
    return WholeColorFamilyList.parse(this.get('color_families', '') as string)
  }

  get isNew(): boolean {
    return this.products
      .filter(p => p.isOnRegularSale)
      .some(p => p.isNew)
  }

  get isOnDiscount(): boolean {
    const distance = this._price.rateOfDiscount
    const someSkuIsOnDiscount = this.materializedSkuList.some(sku => sku.isOnDiscountSale)

    return someSkuIsOnDiscount && distance > 1
  }

  get color(): null | { name: string, title: string, label: string } {
    const tagNameSpace = ['company', 'product', 'color']

    const tag = this.findTag(this.tags, tagNameSpace)

    if (!tag) {
      return null
    }

    return {
      name: tag.name,
      title: tag.title,
      label: tag.label,
    }
  }

  get images(): ApiImage[] {
    return this.get('images', []).map((img: any) => new ApiImage(img))
  }
  get hasImages(): boolean {
    return this.images.length > 0
  }

  get representativeImage(): ApiImage | null {
    if (!this.hasImages) {
      return null
    }

    const representativeImages = this.images.filter(img => img.isRepresentativeImage)
    const representative =  _.minBy(representativeImages, i => i.displayLevel)
    if (representative) {
      return representative
    }

    const imageWithMinDisplayLevel = _.minBy(this.images, 'displayLevel')

    if (imageWithMinDisplayLevel) {
      return imageWithMinDisplayLevel
    }

    return this.images[0]
  }

  get price(): FkuPrice {
    return this.isOnDiscount ? this._price.asDiscounted : this._price
  }

  /**
   * isOnDiscount()とprice()との循環参照を避けるため、以下のメソッドを用意した
   * https://github.com/my-color/front/pull/2470#issuecomment-536380242
   */
  private get _price(): FkuPrice {
    return new FkuPrice(this.materializedSkuList.map(sku => sku.price))
  }

  // 単一のカテゴリを返すことになったので、命名と返り値も単一に変えたほうがいいかも
  get categories(): Category[] {
    if (this.root === null) {
      return []
    }

    return this.root.tags.filter(Category.match)
                         .slice(0, 1)
                         .map((t) => {
                           return t.convertTo(d => new Category(d))
                         })
  }

  /**
   * 紐付き商品の親商品
   */
  get isParent(): boolean {
    return this.isRoot && this.hasTag('mycolor.hiddenset.parent')
  }

  /**
   * 紐付き商品の子商品
   */
  get isChild(): boolean {
    return this.isRoot && this.hasTag('mycolor.hiddenset.child')
  }

  get isNovelty(): boolean {
    return this.hasTag('mycolor.product.novelty')
  }

  get purchaseLimit(): number | null {
    return this.get('aux_long_2', null)
  }

  /**
   * :pensive: :pensive: :pensive:
   * FIXME: 不要になり次第速やかに削除する。また、原則として使用しない。
   *
   * データを渡すコンポーネントや関数がDTOに対応しておらず、
   * やむを得ず元データを渡さなくてはならない場合にのみ利用する。
   *
   * 返却される生データはshallow copyであり、かつ読み取り専用のものである。
   */
  extractRawJsonForOnlyReceiverNotCorrespondingToObject(): Readonly<any> {
    return { ...this._data }
  }

  get isRestock(): boolean {
    return this.hasTag(ApiProductclass.RESTOCK)
  }

  get supportCompactDelivery(): boolean {
    return this.hasTag('mycolor.product.delivery.compact')
  }

  get supportNekoposuDelivery(): boolean {
    return this.hasTag('mycolor.product.delivery.nekoposu')
  }
}
