import CurrentTime from './CurrentTime'

export default class TimeTravelTime extends CurrentTime {
  private base: Date

  constructor(base: string) {
    super()
    this.base = new Date(base)
  }

  get now(): Date {
    return this.base
  }
}
