








import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import * as RecentlyViewedRepository from '@/models/repository/recently-viewed/recently-viewed-repository'
import BrandRecentlyViewed from './BrandRecentlyViewed.vue'

@Component({
  components: {
    BrandRecentlyViewed,
  },
})
export default class ItemDetailRecentlyViewed extends Vue {
  private static readonly limit = 8

  @Prop({ required: true })
  brand: string

  @Prop({ required: true })
  currentFkuId: string

  @Prop({
    default: () => RecentlyViewedRepository.instance.listOnly(
      // 現在表示している商品1点は表示から除外されるので、上限+1を取得する必要が有る
      ItemDetailRecentlyViewed.limit + 1
    ),
  })
  repository: RecentlyViewedRepository.RecentlyViewedRepository

  created() {
    this.updateRecentlyCheckedItem()
  }

  excludeCurrentItem(items: any[]): any[] {
    return items.filter(item => item.id !== this.currentFkuId)
  }

  private updateRecentlyCheckedItem() {
    this.repository.updateMostRecent(this.currentFkuId)
  }
}
