
import Vue from 'vue'
import { Mixin } from 'vue-mixin-decorator'

import { log } from '@/log'
import store from '@/store'

import { addCond, removeCond, search } from '../../../store/modules/searchContactLens/functions'
import { SearchContactLensCond } from '../../../store/modules/searchContactLens/types'

@Mixin
export default class SearchContactLensBase extends Vue {
  cond: SearchContactLensCond
  items: string[][] = []

  // 現在の検索条件を適用し, 表示するアイテムをリフレッシュする.
  refreshItems() {
    const csvData = store.state.searchContactLens.csvData
    if (!csvData) {
      return
    }
    this.items = search(csvData, this.cond)
  }

  // ページ読み込み時.
  async onMounted() {
    // JSプラグインをロード.
    require('./js/jquery-countTo.min.js')
    require('./js/remodal.min.js')
    const wow = require('./js/wow.min.js')

    // ページ読み込み時に, CSVをロード.
    const csvUrlEnvId = 'HOTELLOVERS_CONTACT_LIST_CSV_URL'
    const csvUrlElement = document.getElementById(csvUrlEnvId)
    if (csvUrlElement && 'url' in csvUrlElement.attributes) {
      // CSVのURLをページから取得.
      const url = csvUrlElement.attributes['url']['value']
      await store.dispatch('searchContactLens/load', { url })
    } else {
      log.debug(`DOM element not found: id='${csvUrlEnvId}', attribute='url'`)
    }

    // アイテム表示を更新.
    this.refreshItems()
    this.countTo()
    this.setSpCount()

    const WOW = wow['WOW']
    const newWOW = new WOW({ live: false })
    newWOW.init()

    // Stop ViewState loading, which is started from beforeRouteEnter
    // await this.$store.dispatch('view/stopLoading')
  }

  // カウンターを静的に更新.
  setCount() {
    // このIDは, SearchContactLens2, SearchContactLens4, SearchContactLens5の3箇所で使われているので変更注意.
    $('#search_sys_body_result_countarea_count').text(this.items.length)
  }

  // カウンターをアニメーションで更新.
  async countTo() {
    // このIDは, SearchContactLens2, SearchContactLens4, SearchContactLens5の3箇所で使われているので変更注意.
    const countObj: object = $('#search_sys_body_result_countarea_count')
    if ('countTo' in countObj) {
      countObj['countTo'](this.items.length, { duration: 0.5 })
    }
  }

  // カウンターを静的に更新(SP)
  setSpCount() {
    // このIDは, SearchContactLens2, SearchContactLens4, SearchContactLens5の3箇所で使われているので変更注意.
    $('#search_sys_body_menu_sp_modal_count').text(this.items.length)
  }

  // カウンターをアニメーションで更新(SP)
  async spCountTo() {
    // このIDは, SearchContactLens2, SearchContactLens4, SearchContactLens5の3箇所で使われているので変更注意.
    const countObj: object = $('#search_sys_body_menu_sp_modal_count')
    if ('countTo' in countObj) {
      countObj['countTo'](this.items.length, { duration: 0.5 })
    }
  }

  // 検索条件を追加.
  onAddCond(key: number, value: string) {
    // 条件修正.
    addCond(this.cond, key, value)

    // アイテム表示を更新.
    this.refreshItems()
    this.countTo()
    this.spCountTo()
  }

  // 検索条件を削除.
  onRemoveCond(key: number) {
    // 条件修正.
    removeCond(this.cond, key)

    // アイテム表示を更新.
    this.refreshItems()
    this.countTo()
    this.spCountTo()
  }

  // 検索ダイアログを表示(SP)
  onSpModal() {
    const inst = $('[data-remodal-id=modal]')['remodal']()
    inst.open()
  }

  // 検索ダイアログで, 検索条件を確定して閉じる(SP)
  onSpModalConfirm() {
    const inst = $('[data-remodal-id=modal]')['remodal']()
    inst.close()

    this.refreshItems()
    this.countTo()
  }

  // 検索ダイアログで, 検索条件をクリアする(SP)
  onSpModalClear(cond: SearchContactLensCond) {
    this.cond = cond
    // このIDは, SearchContactLens2, SearchContactLens4, SearchContactLens5の3箇所で使われているので変更注意.
    $('#search_sys_body_menu_sp_modal').find('li').each(function () {
      $(this).removeClass('search_sys_body_menu_sec_menu_selected')
    })
    this.refreshItems()
    this.setCount()
    this.setSpCount()
  }

  // 検索ダイアログを閉じる(SP)
  onSpModalCancel() {
    const inst = $('[data-remodal-id=modal]')['remodal']()
    inst.close()
  }

  // 動画ダイアログを開く.
  onSpMovieModal() {
    const inst = $('[data-remodal-id=movie-modal1]')['remodal']()
    inst.open()
  }

  // 動画ダイアログを閉じる.
  onSpMovieModalCancel() {
    const inst = $('[data-remodal-id=movie-modal1]')['remodal']()
    inst.close()
  }

  // ダイアログを削除. Vue routerで画面を切り替える時はこれを呼び出し, remodalをクリーンナップする.
  clearRemodal() {
    const remodalModal = $('[data-remodal-id=modal]')
    if (remodalModal.length) {
      const inst = remodalModal['remodal']()
      inst.destroy()
    }

    const remodalMovieModal1 = $('[data-remodal-id=movie-modal1]')
    if (remodalMovieModal1.length) {
      const inst = remodalMovieModal1['remodal']()
      inst.destroy()
    }
  }

  // ページタイトルを設定.
  setPageTitle(title: string) {
    const oldTitle = document.title
    let newTitle: string = oldTitle

    const titleElements = oldTitle.split('|')
    if (titleElements.length > 1) {
      const tail = titleElements[titleElements.length - 1]
      newTitle = `${title} | ${tail.trim()}`
    } else {
      newTitle = `${title} | ${oldTitle}`
    }

    document.title = newTitle
  }

  setInitialSearchCondition(cond: SearchContactLensCond) {
    this.cond = cond
  }
}
