export class HistoryService {

  public static pushState(data, title, url) {
    if (window.history && window.history.pushState) {
      window.history.pushState(data, title, url)
    }
  }

  public static replaceState(data, title, url) {
    if (window.history && window.history.replaceState) {
      window.history.replaceState(data, title, url)
    }
  }

  public static back() {
    if (window.history && window.history.back) {
      window.history.back()
    }
  }
}
