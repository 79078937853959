import { CartGroupList } from '@/models/cart/group/group'
import { CartScopeEq } from '@/models/cart/group/scope'
import _ from 'lodash'
import { MutationTree } from 'vuex'

import { CartItem, CartState } from './types'

import { log } from '@/log'

const namespace = 'cart'

export const mutations: MutationTree<CartState> = {
  load(state, payload: CartGroupList) {
    state.error = {}
    state.currentScope = null
    state.isLoadCompleted = true
    state.groups = payload

    log.debug({ commit: `${namespace}/load`, state, payload })
  },

  useItemsForScope(state, scope: CartScopeEq) {
    state.currentScope = scope

    log.debug({ commit: `${namespace}/useItemsForScope`, state, scope })
  },

  replace(state, replaced: CartItem) {
    state.error = {}
    state.groups = state.groups.mapItems(item => item.id === replaced.id ? replaced : item)

    log.debug({ commit: `${namespace}/replace`, state })
  },

  update(state, payload: { id: string, unitCount: number }) {
    state.error = {}
    state.groups = state.groups.mapItems((item) => {
      if (item.id === payload.id) {
        item.unitCount = payload.unitCount
      }

      return item
    })

    log.debug({ commit: `${namespace}/update`, state })
  },

  errorOnUpdate(state, payload: { id: string, error: Error }) {
    const { id, error } = payload

    state.groups = state.groups.mapItems((item) => {
      if (item.id === id) {
        _.set(item, 'error.update', error)
      }

      return item
    })
  },

  errorOnLoad(state, payload: { error: Error }) {
    const { error } = payload
    _.set(state, 'error.load', error)
  },

  isReplacedForSale(state, payload: boolean) {
    state.isReplacedForSale = payload
  },

  loadNovelties(state, updatedList: CartGroupList) {
    state.error = {}

    const updateNoveltyItems = updatedList.filterItems(item => item.isNovelty)
    const storedNotNoveltyItems = state.groups.filterItems(item => !item.isNovelty)

    state.groups = storedNotNoveltyItems.merge(updateNoveltyItems)

    state.isLoadCompleted = true

    log.debug({ commit: `${namespace}/loadNovelties`, state })
  },
}
