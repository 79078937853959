







































































































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import LockBackgroundMixin from '../../mixin/LockBackground'
import {
  CloseButtonStyle,
  ContainerStyle,
  ModalStyle
} from './modal/style'

@Component({
  mixins: [
    LockBackgroundMixin,
  ],
})
export default class Modal extends Vue {
  @Prop({ default: true })
  withFooter: boolean

  @Prop({ default: () => ({}) })
  styleSetting: ModalStyle

  get hasFooter() {
    return this.$slots.footer || this.$slots['button-text']
  }

  get containerStyle(): ContainerStyle {
    return this.styleSetting.container || {}
  }

  get closeButtonStyle(): CloseButtonStyle {
    return this.styleSetting.closeButton || {}
  }
}

export interface ModalContent {
  header: string,
  body: string,
  buttonText: string,
}
