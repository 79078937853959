














import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import AppId from '@/util/appid'
import { BrandRouteResolver } from '@/util/brandRouteResolver'
import { InquiryService } from '@ajax/modules/services/inquiry'

@Component
export default class Newstock extends Vue {
  @Prop()
  id: string

  @Prop()
  brandId: string

  loading: boolean = true
  alreadyInquiried: boolean = false

  inquiryService: InquiryService

  async created() {
    const brandName = BrandRouteResolver.resolveBrandFromPathElement(this.$route.params.brand_english_name)
    const appId = AppId.getByBrandName(brandName)
    this.inquiryService = new InquiryService(appId)
  }

  async mounted() {
    const response = await this.inquiryService.fetchUserInquiries({
      is_notified: false,
      inquiry_kind: InquiryService.NEW_REQUEST_ID,
      resource_id: this.id,
      with: 'application_notify',
    })

    this.loading = false

    if (response.isLeft()) {
      return
    }
    const inquiries = response.value

    this.alreadyInquiried = inquiries.length > 0
  }
}
