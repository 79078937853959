import _ from 'lodash'
import { Response } from 'superagent'

import { ApiError, ErrorType } from '@/models/error/api/error'

export class OriginalApiErrorResponse {
  constructor(private response: Response) {}

  get data(): any {
    return this.response.body.data
  }

  get success(): boolean {
    return _.get(this.response.body, 'success', false)
  }

  get status(): string {
    return _.get(this.response.body, 'status', '')
  }

  get statuscode(): number {
    return _.get(this.response.body, 'statuscode', 0)
  }

  get error_at(): string {
    return _.get(this.response.body, 'error_at', '')
  }

  get errors(): ApiError[] {
    const cause = _.get<any[]>(this.response.body, 'cause', [])

    return cause.map(error => this.parseError(error))
  }

  errorsByType(errorType: ErrorType): ApiError[] {
    return this.errors.filter(error => error.type === errorType)
  }

  contains(...errorTypes: ErrorType[]): boolean {
    return this.errors.some(error => _.includes(errorTypes, error.type))
  }

  private parseError(error: string): ApiError {
    // [xxx][yyy] message のような、プラスαの情報を持っているケースに対応するため
    // [^\]] で ] を排除している
    const result = error.match(/^\[([^\]]*)](.*)$/)

    const rawType = _.get(result, 1, null)

    const type: ErrorType | null = rawType ? ErrorType[rawType] : null

    if (!type) {
      return {
        type: ErrorType.ParseErrorInClient,
        text: error,
      }
    }

    return {
      type,
      // tslint:disable-next-line:no-magic-numbers
      text: _.get(result, 2, ''),
    }
  }
}
