



import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import {
  executeWebCustomerMeasurementTag,
  WebCustomerServiceUserAttr,
} from '@/vendor/web-customer-service-tool'

@Component
export default class WebCustomerServiceTool extends Vue {
  @Prop()
  cartTotalPrice: number

  @Prop()
  userAttribute: WebCustomerServiceUserAttr

  async created() {
    // Web接客ツールの計測データをセットしスクリプトをロード
    executeWebCustomerMeasurementTag(
      this.cartTotalPrice,
      this.userAttribute
    )
  }
}
