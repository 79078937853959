import { DefaultSkuPriceRepository, SkuPriceRepository } from '@/models/api/paid-member-price/sku-price/sku-price-repository'
import { SkuPrice, SkuPriceList } from '@/models/item/sku'
import { Either, left, right } from 'fp-ts/lib/Either'
import { FetchSkuPrice } from '../fetch-sku-price'

export class DefaultFetchSkuPrice implements FetchSkuPrice  {
  static create(brandEnglishName: string): DefaultFetchSkuPrice {
    return new DefaultFetchSkuPrice(
      new DefaultSkuPriceRepository(brandEnglishName)
    )
  }

  constructor(private readonly skuPriceRepository: SkuPriceRepository) {}

  async fetch(skuIds: string[]): Promise<Either<Error, SkuPriceList>> {
    return (await this.skuPriceRepository.list(skuIds)).map(
      list => SkuPriceList.create(list)
    )
  }

  async fetchById(skuId: string): Promise<Either<Error, SkuPrice>> {
    const result = await this.fetch([skuId])

    return result.chain((list): Either<Error, SkuPrice> => {
      const price: SkuPrice | undefined = list.findBySkuId(skuId)

      if (price) {
        return right(price)
      }

      return left(new Error(`Could not fetch SKU prices for ${skuId}`))
    })
  }
}
