



























import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import ErrorDialog from '@/components/organisms/dialog/ErrorDialog.vue'
import { CookieService } from '@/services/cookie'
import { UserService } from '@ajax/modules/services/user'
import {
  ApiRequestError,
  CatalogDemandErrorModalState,
  CatalogLoadError,
  NotShowErrorModal,
} from './model/catalog-demand-error-modal'
import {
  AsLoading,
  AsRetry,
  CatalogDemandState,
} from './model/catalog-demand-state'
import CatalogDemandStateSpec from './model/catalog-demand-state-spec'

@Component({
  components: {
    ErrorDialog,
  },
})
export default class CatalogButton extends Vue {
  @Prop({ required: true })
  brand: string

  @Prop({ required: true })
  readonly catalogId: string

  @Prop({
    default: () => new UserService(),
  })
  readonly userService: UserService

  state: CatalogDemandState = new AsLoading()
  errorModalState: CatalogDemandErrorModalState = new NotShowErrorModal()

  get isLoading(): boolean {
    return this.state.isLoading
  }

  async created() {
    if (!CookieService.isLogined()) {
      await this.userService.registerProvisional()
    }

    const catalogDemandStateSpec = CatalogDemandStateSpec.createFromBrand(this.brand)
    this.state = await catalogDemandStateSpec.select(this.catalogId)

    if (this.state instanceof AsRetry) {
      this.errorModalState = new CatalogLoadError()
    }
  }

  async onClick() {
    try {
      await this.waitWithLoading(() => this.state.onClick())
    } catch (error) {
      this.errorModalState = new ApiRequestError(error)
    }
  }

  closeErrorModal() {
    this.errorModalState = new NotShowErrorModal()
  }

  private async waitWithLoading(processWithLoad: () => Promise<void>) {
    const atStart = this.state

    try {
      const promise = processWithLoad()

      this.state = {
        ...atStart,
        isLoading: true,
        async onClick() {
          // nothing to do
        },
      }

      await promise
    } finally {
      this.state = atStart
    }
  }
}
