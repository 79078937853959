






import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import Util from '../../../modules/util'

@Component
export default class Countup extends Vue {
  @Prop()
  finishCount: number

  count: number = 0
  countDisp: string = '0'

  mounted() {
    const countupLimit = 30

    const plus = this.finishCount < countupLimit ? 1 : Math.floor(this.finishCount / countupLimit)

    const timer = setInterval(
      () => {
        this.count += plus
        this.countDisp = Util.numberToMoney(this.count)
        if (this.finishCount <= this.count) {
          this.count = this.finishCount
          this.countDisp = Util.numberToMoney(this.count)
          clearInterval(timer)
        }
      },
      countupLimit
    )
  }
}
