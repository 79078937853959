








































import Vue from 'vue'
import { Component, Emit, Prop } from 'vue-property-decorator'

import AppId from '@/util/appid'
import { BrandRouteResolver } from '@/util/brandRouteResolver'

import { convertLongIdToShortId } from '@/models/api/id'
import { CurrentUser } from '@/services/api/current-user'
import { CookieService } from '@ajax/modules/cookie'
import { InquiryService } from '@ajax/modules/services/inquiry'
import Util from '@ajax/modules/util'
import ArrivalComplete from './arrival/CompleteModal.vue'
import ArrivalRegister from './arrival/RegisterModal.vue'

@Component({
  components: {
    ArrivalRegister,
    ArrivalComplete,
  },
})
export default class ArrivalNotification extends Vue {

  @Prop()
  character: string

  @Prop()
  inqueryKind: string

  @Prop()
  id: string

  @Prop()
  alreadyInquiried: boolean

  @Prop()
  brandId: string

  @Prop({ default: true })
  lock: boolean

  @Prop({ default: false })
  keepLock: boolean

  error: boolean = false
  isOpen: boolean = false
  email: string = ''
  userRegistered: boolean = false
  inquiryRequested: boolean = false

  inquiryService: InquiryService
  userService: CurrentUser

  async created() {
    const brandName = BrandRouteResolver.resolveBrandFromPathElement(this.$route.params.brand_english_name)
    const appId = AppId.getByBrandName(brandName)
    this.inquiryService = new InquiryService(appId)
    this.userService = new CurrentUser(appId)
  }

  get buttonLabel() {
    return `${this.character}${this.canRequestInquiry ? 'をお知らせ' : 'リクエスト済み'}`
  }

  get buttonClass() {
    const always = [
      'c-btn',
      'c-btn--w-middle',
      'c-btn--h-middle',
      'c-btn--arrival-mail',
    ]
    const onlyDisable = [
      'c-btn--disabled--middle',
    ]

    return this.canRequestInquiry ? always : always.concat(onlyDisable)
  }

  get canRequestInquiry() {
    const isGuest: boolean = Util.isGuest()

    return isGuest || !(this.alreadyInquiried || this.inquiryRequested)
  }

  get shortId(): string {
    return convertLongIdToShortId(this.id)
  }

  async mounted() {
    if (!CookieService.isLogined()) {
      return
    }

    if (!this.canRequestInquiry) {
      return
    }

    const user = await this.userService.getCurrentUser()

    if (user.isLeft()) {
      this.error = true

      return
    }

    this.userRegistered = user.value['user_status'] === 'registered'

  }

  @Emit('toggle')
  async open() {
    if (!this.canRequestInquiry) {
      return
    }

    this.isOpen = true
    if (this.userRegistered) await this.notification()
  }

  @Emit('toggle')
  close() {
    this.isOpen = false
  }

  async check() {

    const validated = await this.validateForm()
    if (!validated) return

    this.notification()

  }

  async notification() {

    const response = await this.postIssueInquiry()

    if (response.isLeft()) {
      this.inquiryRequested = false
    }

    this.inquiryRequested = response.value['success']
  }

  async validateForm(): Promise<boolean> {

    return this.$validator.validateAll()
  }

  @Emit('toggle')
  openMailer() {
    this.inquiryRequested = true
    this.isOpen = false
  }

  async postIssueInquiry() {
    const query = this.userRegistered ? {
      inquiry_kind: this.inqueryKind,
      resource_id: this.id,
      brand_id: this.brandId,
    } : {
      inquiry_kind: this.inqueryKind,
      resource_id: this.id,
      email: this.email,
      brand_id: this.brandId,
    }

    const response = await this.inquiryService.register(query)

    return response
  }
}
