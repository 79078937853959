import BehaviorConfig from '@/models/app-config/behavior/behavior'
import ContactLensFKURepository from '@/models/item/fku/repository'
import ContactLensRootRepository from '@/models/item/root/repository'
import ContactLensItemDetailStore from './store'

export default class ContactLensItemDetailStoreBuilder {
  static getInstance(
    rootRepo: ContactLensRootRepository,
    fkuRepo: ContactLensFKURepository
  ): ContactLensItemDetailStoreBuilder {
    if (ContactLensItemDetailStoreBuilder.instance === null) {
      ContactLensItemDetailStoreBuilder.instance = new ContactLensItemDetailStoreBuilder(rootRepo, fkuRepo)
    }

    return ContactLensItemDetailStoreBuilder.instance
  }

  private static instance: ContactLensItemDetailStoreBuilder | null = null

  private callback: Promise<ContactLensItemDetailStore> | null = null

  private constructor(
    private readonly rootRepo: ContactLensRootRepository,
    private readonly fkuRepo: ContactLensFKURepository
  ) {}

  build(rootId: string, behavior: BehaviorConfig): Promise<ContactLensItemDetailStore> {
    if (this.callback === null) {
      this.callback = Promise.all([
        this.rootRepo.fetch(rootId),
        this.fkuRepo.listBelongingToRoot(rootId),
      ]).then(([root, fkus]) => {
        return new ContactLensItemDetailStore(root, fkus, behavior)
      })
    }

    return this.callback
  }
}
