import { Either, Left, Right } from 'fp-ts/lib/Either'
import superagent from 'superagent'

import { log } from '@/log'
import { ApiService } from '@/services/api/service'
import { ApiClient } from './client'

const namespace: string = 'user'

/**
 * TODO: Ajax側にも似た内容のUserServiceが存在しており、両者を統合し/src/services配下のAPIゲートウェイとしたい。
 * @see https://github.com/my-color/front/pull/5961#discussion_r763640704
 */
export default class UserService extends ApiService {
  async follow(userId: string, tags: string[]): Promise<Either<Error, superagent.Response>> {
    return this.changeFollowStatus(userId, tags, [])
  }
  async unfollow(userId: string, tags: string[]): Promise<Either<Error, superagent.Response>> {
    return this.changeFollowStatus(userId, [], tags)
  }

  async changeFollowStatus(
    userId: string,
    followTags: string[],
    unfollowTags: string[]
  ): Promise<Either<Error, superagent.Response>> {
    try {
      /**
       * FIXME: PUT /user-proxy/{appId}/user/:id を使うのではなく、 PUT /api/current_user/mail_magazine にしたい
       * FIXME: ID指定すれば任意のユーザー情報を更新できてしまいかねないAPIは潰しておきたい
       * FIXME: Front側で適切にガードすれば問題は起きなくなるかもしれないが、そもそもガードの必要性自体を排除したい
       */
      const response = await ApiClient.put(`/api/user-proxy/${this.appId}/user/${userId}`).send({
        ...(followTags.length === 0 ? {} : {
          'tag.follow': followTags.join(','),
        }),
        ...(unfollowTags.length === 0 ? {} : {
          'tag.unfollow': unfollowTags.join(','),
        }),
      })

      log.debug({ service: `${namespace}/put/${userId}`, response })

      return new Right(response.body.data)
    } catch (error) {
      log.error({ service: `${namespace}/put/${userId}`, error })

      return new Left(error)
    }
  }

  /**
   * Update user profile (partially of user fields)
   * @param payload
   */
  async update(payload: any): Promise<Either<Error, superagent.Response>> {
    try {
      const response = await ApiClient
        .post(`/api/updateprofile`)
        .send(payload)

      log.debug({ service: `${namespace}/update`, response })

      return new Right(response.body.data)
    } catch (error) {
      log.error({ service: `${namespace}/update`, error })

      return new Left(error)
    }
  }

  /**
   * @deprecated
   *
   * ユーザーリソースを部分的に更新する仕組みができるまでの暫定対応。
   * @see https://github.com/my-color/front/pull/4568
   *
   * サーバー側のUserApiController->updatePaymentAuthTokenメソッドを叩き、Engineユーザーリソースの
   * payment_auth_tokenを更新するためのメソッド。UserApiController->updatePaymentAuthTokenメソッドは、
   * サーバー側でユーザーリソースを部分的に書き換える仕組みができるまでの暫定的なメソッドなので、サーバー側で
   * ユーザーリソースを部分的に書き換える仕組みができたら、それを利用するメソッドをこのUserServiceクラスに
   * 実装し、payment_auth_tokenの更新もそのメソッドを使うようにする。その段階で、こちらのメソッドは削除する。
   */
  async updatePaymentAuthToken(payload: { payment_auth_token: string }): Promise<Either<Error, superagent.Response>> {
    try {
      const response = await ApiClient
        .put(`/api/update-payment-auth-token`)
        .send(payload)

      log.debug({ service: `${namespace}/updatePaymentAuthToken`, response })

      return new Right(response.body.data)
    } catch (error) {
      log.error({ service: `${namespace}/updatePaymentAuthToken`, error })

      return new Left(error)
    }
  }
  /**
   * Change Password
   */
  async changePassword(
    password: string,
    newpassword: string
  ): Promise<Either<Error, superagent.Response>> {
    try {
      const response = await ApiClient
        .post(`/api/user-proxy/${this.appId}/changepassword`)
        .send({
          password,
          newpassword,
        })

      log.debug({ service: `${namespace}/changePassword`, response })

      return new Right(response.body.data)
    } catch (error) {
      log.error({ service: `${namespace}/changePassword`, error })

      return new Left(error)
    }
  }

  /**
   * Reset Password
   */
  async resetPassword(
    token: string,
    password: string,
    passwordConfirm: string
  ): Promise<Either<Error, superagent.Response>> {
    try {
      const response = await ApiClient
        // TODO: This endpoint might not work
        .post(`/api/user-proxy/${this.appId}/resetpassword_complete`)
        .send({
          token,
          'password.main': password,
          'password.confirm': passwordConfirm,
        })

      log.debug({ service: `${namespace}/resetPassword`, response })

      return new Right(response.body.data)
    } catch (error) {
      log.error({ service: `${namespace}/resetPassword`, error })

      return new Left(error)
    }
  }
}
