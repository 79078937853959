import { Either } from 'fp-ts/lib/Either'
import _ from 'lodash'

import * as ImageConfig from '@/config/assets/image'
import { ApiImage } from '@/models/api/image'
import { ApiProductclass } from '@/models/api/productclass'
import { BandImageHelper } from '@/models/api/productclass/band-url'
import * as SKUFactory from '@/models/item/sku/factory/sku'
import RootItemService, { RootItem } from '@/services/item/root'
import {
  BundlePurchaseItem,
  BundlePurchaseRootItem,
  Color,
  FkuPrice,
  ItemImage,
  ItemPrice,
} from '../../bundle-purchase-item'
import { ColorBasedItemList } from '../../color-based-item-list'
import { Command } from '../command'

type Root = RootItem
type Fku = ApiProductclass
type LoadRootResultFromFku = Either<Error, BundlePurchaseRootItem>

export default class LoadRootFromFku implements Command<LoadRootResultFromFku> {
  static with(brand: string, fku: Fku, bandImageHelper: BandImageHelper) {
    return new LoadRootFromFku(
      new RootItemService(brand),
      fku,
      bandImageHelper
    )
  }

  constructor(
    private readonly rootItemApiClient: RootItemService,
    private readonly belongingFku: Fku,
    private readonly bandImageHelper: BandImageHelper
  ) { }

  async run(): Promise<LoadRootResultFromFku> {
    // belonging_toから取得できるrootには、すべての情報は乗っていない.
    // そのため、後続でidからroot本体を取得し直している.
    const minimumRoot = this.belongingFku.root
    const color = this.belongingFku.color

    if (minimumRoot === null) {
      throw new FkuHasNoRootItemError(`fku ${this.belongingFku.id} should have root, but not!`)
    }
    if (color === null) {
      throw new FkuHasNoColorError(`fku ${this.belongingFku.id} should have color, but not!`)
    }

    const rootResult = await this.rootItemApiClient.get(minimumRoot.id)

    return rootResult
      .map(root => this.convertFkuListToRoot(
        color.title, root
      ))
  }

  private convertFkuListToRoot(color: Color, root: Root): BundlePurchaseRootItem {
    const images = this.createImageList(this.belongingFku).filter(image => !image.image.isLayer)
    const bandUrl = this.bandImageHelper.bandUrl
    const smallestDisplayLevel = Math.min(...images.map(image => image.image.displayLevel))

    const mainImages = new ColorBasedItemList<ItemImage>(
      images.map(image => ({
        color: image.color,
        url: image.image.url,
        displayLevel: image.image.displayLevel,
        bandUrl: image.image.displayLevel === smallestDisplayLevel && bandUrl ? bandUrl : null,
      }))
    ).orderBy((a, b) => a.displayLevel - b.displayLevel)
     .moveToTopByColor(color)

    const subImages = new ColorBasedItemList<ItemImage>(
      images.map(image => ({
        color: image.color,
        url: image.image.thumbnailUrl,
        displayLevel: image.image.displayLevel,
        bandUrl: image.image.displayLevel === smallestDisplayLevel && bandUrl ? bandUrl : null,
      }))
    ).orderBy((a, b) => a.displayLevel - b.displayLevel)
     .moveToTopByColor(color)

    const skuList = new ColorBasedItemList<BundlePurchaseItem>(
      this.createItemList(this.belongingFku)
    ).moveToTopByColor(color)

    /**
     * 「まとめ買い」の場合、紐づく商品の金額は全て共通のハズなので、一旦、一覧と同じ金額表示。
     * // TODO: client側で商品価格のドメインオブジェクトが実装されたら、そちらと置き換える。
     * https://www.chatwork.com/#!rid87550748-1228233350251020288
     *
     * また、まとめ買いではなるべく安く見えるよう、常に標準価格基準で表示。セール価格は無視。
     * https://www.chatwork.com/#!rid87550748-1228235482148675584
     */
    const fkuPrice = this.belongingFku.price.asRegular
    const price: ItemPrice<FkuPrice> = {
      regular: {
        textWithoutConsumptionTax: fkuPrice.getPrice(),
        textWithConsumptionTax: fkuPrice.getPriceAfterTax(),
        textDiscountRate: fkuPrice.getDiscountRate(),
      },
    }

    return {
      name: root.name,
      productNumber: root.number,
      price,
      image: {
        mains: mainImages.items,
        subs: subImages.items,
      },
      items: skuList.items,
      description: {
        content: root.description.itemDetail,
        material: root.description.material,
      },
    }
  }

  private createImageList(fku: Fku): Array<{ color: Color, image: ApiImage }> {
    const fkuColor = fku.color

    if (fkuColor === null) {
      return []
    }

    return fku.images.map(image => ({
      color: fkuColor.title,
      image,
    }))
  }

  private createItemList(fku: Fku): BundlePurchaseItem[] {
    const fkuColor = fku.color
    if (fkuColor === null) {
      return []
    }
    const image = fku.representativeImage

    return fku.products.map((skuProduct) => {
      const sku = SKUFactory.create(skuProduct)

      return {
        id: sku.id.long,
        color: fkuColor.title,
        size: skuProduct.getSize()!, // :pensive: skuなので、sizeは持っているはず・・・
        image: {
          url: image ? image.url : ImageConfig.dummyImagePath,
        },
        stock: sku.stock,
        regularSalePeriod: sku.salePeriod,
        price: {
          regular: {
            withConsumptionTax: skuProduct.price.regular,
          },
        },
      }
    })
  }
}

class FkuHasNoRootItemError extends Error { }
class FkuHasNoColorError extends Error { }
