











import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import BasePriceRow from './BasePriceRow.vue'

@Component({
  components: { BasePriceRow },
})
export default class RegularPriceRow extends Vue {
  @Prop({ required: true })
  readonly priceWithTax: string

  @Prop({ required: true })
  readonly isPaidMemberPriceAvailable: boolean

  @Prop({ default: true })
  readonly showYenSymbol: boolean

  get regularPriceClasses() {
    return [{ 'price-row--font-small': this.isPaidMemberPriceAvailable }, 'regular-price']
  }
}
