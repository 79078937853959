import { SkuPrice } from '@/models/api/paid-member-price/sku-price/scheme'
import { ApiProductclass } from '@/models/api/productclass'
import { CartGroupList } from '@/models/cart/group/group'
import { CartScopeEq } from '@/models/cart/group/scope'
import { ReasonForCannotBePurchasedList } from './types/reason-for-cannot-be-purchased'

export const initialCartState: CartState = {
  currentScope: null,
  groups: CartGroupList.empty,
  isReplacedForSale: false,
  isLoadCompleted: false,
  error: null,
}

export interface CartState {
  currentScope: CartScopeEq | null,
  groups: CartGroupList,
  isReplacedForSale: boolean,
  isLoadCompleted: boolean,
  error: null | {
    load?: Error,
    useItemsForScope?: Error
  },
}

export interface CartItem {
  id: string,
  name: string,
  createdAt: Date,
  thumbnail?: string,
  unitPrice: number,
  currentValidPrice?: SkuPrice,
  isWithPaidMembershipPrice: boolean,
  skuSellPrice?: number,
  skuSalePrice?: number,
  unitCount: number,
  brandId: string,
  brandName: string | null,
  brandEnglishName: string | null,
  skuId: string,
  fkuId: string,
  root?: ApiProductclass,
  rootId?: string,
  rootShortId?: string,
  size?: string | null,
  color?: string,
  colorName?: string,
  onSale: boolean,
  salesStatus: number,
  stock?: number,
  salesStartAt: string,
  salesEndAt: string,
  isPreSales: boolean,
  isParent: boolean,
  isChild: boolean,
  isCatalogType?: boolean,
  isNovelty: boolean,
  isFkuPublic: boolean,
  childItem: ApiProductclass | null,
  ineligibleForCoupon: boolean,
  freeShipping: boolean,

  // 購入数上限の判断に必要
  rootPurchaseLimit?: number | null,
  maxSellCount?: number,

  /**
   * TODO: プレゼンテーション層に定義を移動する
   *
   * カート画面で削除されたことを示すフラグ。
   * 一度削除した商品を元に戻す機能のために、
   * Engine上で削除された商品でもclientで保持している。
   *
   * storeの型定義に含まれるべき情報ではないが、
   * 現時点 (2022/03) でこのremovedがドメインモデルでも利用されており、
   * 移動するのが困難だったのでそのまま置いている。
   */
  removed?: boolean,
  beforePrice?: number,

  error?: {
    create?: Error,
    update?: Error,
    remove?: Error,
    restore?: Error,
  }

  /**
   * CartItemの内容について、購入ができない事由があれば格納する。
   * e.g. 価格差異のチェックが済んでいない、データに不整合がある etc.
   * 購入できない場合は、このフィールドを参照してユーザに該当するカートの削除を促すなどのユースケースが考えられる。
   * @see https://github.com/my-color/front/pull/6011#discussion_r777011583
   *
   * ただし、client/spa/src/store/modules/cart/function.ts の filterValidItem 関数が主に整合性をチェックしているため、
   * 将来的にはそちらとの統合が必要。（さらにいえばvuexに置いてあるこのCartItemが持つべきフィールドについて、責務の再検討から必要。）
   */
  reasonForCannotBePurchased: ReasonForCannotBePurchasedList

  // 本来なら、「最大購入数」という知識のまとまりで階層を作って管理したいが
  // 既存実装が maxSellCount という単体に解体してしまっているので、
  // ↓のような階層化は放棄して、一旦(at 2021/02/23)は並列に知識を追加する
  // maxSellCount: {
  //   sku: number,
  //   root: number,
  //   fku: number,
  // },
  // もっと言えば、↓のようにメソッド化したい
  // maxSellCount(): number
}

export interface SalesPeriodState {
  readonly isBeforeStart: boolean
  readonly isOnSales: boolean
}

export const beforeSales: SalesPeriodState = {
  isBeforeStart: true,
  isOnSales: false,
}

export const inSales: SalesPeriodState = {
  isBeforeStart: false,
  isOnSales: true,
}

export const afterSales: SalesPeriodState = {
  isBeforeStart: false,
  isOnSales: false,
}
