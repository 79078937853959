import { ApiImage } from '../../api/image'
import { BaseImageSorter } from './index'

import _ from 'lodash'

/**
 * 特定のdisplay_levelの画像を先頭に移動する。
 * 該当する画像が見つからない場合は何もしない。
 */
export default class PutImageWithSpecificDisplayLevelAtFirst extends BaseImageSorter {
  /**
   * @param displayLevel 先頭に移動する画像のdisplay_level
   */
  constructor(private displayLevel: number) {
    super()
  }

  sort(images: ApiImage[]): ApiImage[] {
    return _.compact( // 指定したdisplay_levelの画像がない場合、partitionが空要素を作成するので、compactで空要素を取り除く。
      _.flatten(
        _.partition(images, (image: ApiImage) => image.displayLevel === this.displayLevel)
      )
    )
  }
}
