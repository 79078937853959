import _ from 'lodash'
import { Module } from 'vuex'

import { RootState } from '@spa/store/types'
import { actions } from './actions'
import { getters } from './getters'
import { mutations } from './mutations'
import { AppSetting, initialAppSetting } from './types'

export const appSetting: Module<AppSetting, RootState> =  {
  namespaced: true,
  state: _.cloneDeep(initialAppSetting),
  actions,
  getters,
  mutations,
}
