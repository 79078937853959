






import { convertToViewState } from '@/components/molecules/item/price/adapter/with-api-dto/convert'
import LoadingPrice from '@/components/molecules/item/price/kind/LoadingPrice.vue'
import { emptyState, PriceViewState } from '@/components/molecules/item/price/model/item-price-view'
import {
  GetPaidMemberPriceForFkuParameter,
  getSingletonPaidMemberPriceLoaderForPath
} from '@/components/molecules/item/price/model/paid-member-item-price-loader'
import { PriceView, selectPriceView } from '@/components/molecules/item/price/model/select-price-view'
import { ApiProductclass } from '@/models/api/productclass'
import BehaviorConfig from '@/models/app-config/behavior/behavior'
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component
export default class ItemPriceWithApiDto extends Vue {
  @Prop({ required: true })
  readonly fku: ApiProductclass

  priceView: PriceView = LoadingPrice
  state: PriceViewState = emptyState()

  created() {
    const brand = this.fku.belongingBrand.englishName
    const loader = getSingletonPaidMemberPriceLoaderForPath(brand, location.pathname)

    const param: GetPaidMemberPriceForFkuParameter = {
      fkuId: this.fku.id,
      skuIds: this.fku.materializedSkuList.map(s => s.id),
    }
    loader.getPriceForFku(param).then((result) => {
      result.foldL(
          () => {
            this.state = convertToViewState(this.fku)
          },
          (paidMemberPrice) => {
            this.state = {
              ...convertToViewState(this.fku),
              paidMemberPrice,
            }
          }
      )

      this.priceView = selectPriceView(this.state, BehaviorConfig.createFromBrand(this.fku.belongingBrand))
    })
  }
}
