import { CreditcardPayment } from '@spa/store/modules/orderConfirm/types'

import { PaymentParamBuilder } from './paymentParamBuilder'

export default class CreditcartdPaymentParamBuilder implements PaymentParamBuilder {
  public constructor(private readonly payment: CreditcardPayment) {}

  build(): string {
    const {
      payment_type,
      token,
      auth_pass_full_name,
      auth_pass_tel,
      auth_pass_email,
      point,
      app_kind,
    } = this.payment  // TODO: Use generics

    const param = {
      payment_type,
      token,
      ...auth_pass_full_name && { auth_pass_full_name },
      ...auth_pass_tel && { auth_pass_tel },
      ...auth_pass_email && { auth_pass_email },
      ...app_kind && { app_kind },
      ...point && { point },
    }

    return JSON.stringify(param)
  }
}
