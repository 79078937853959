import { Either, left, right } from 'fp-ts/lib/Either'

import { log } from '@/log'
import { RootPrice } from '@/models/api/paid-member-price/root-price/scheme'
import { ApiClientWithAuth } from '@/services/api/client'

export interface RootPriceRepository {
  getById(rootId: string): Promise<Either<Error, RootPrice>>
}

export class DefaultRootPriceRepository implements RootPriceRepository {
  constructor(private readonly brandEnglishName: string) {}

  async getById(rootId: string): Promise<Either<Error, RootPrice>> {
    try {
      const res = await ApiClientWithAuth.get(`/api/user/${this.brandEnglishName}/item/root/price/${rootId}`)

      return right(res.body.data)
    } catch (error) {
      log.error({ error })

      return left(error)
    }
  }
}
