import { log } from '@/log'
import { CouponCode, hasCouponCode } from '@/models/api/coupon/coupon-code/coupon-code'

import { isItemsSpecifiedCouponCode, ItemsSpecifiedCouponCode } from './items-specified-coupon-code'

export class CouponCodeFactory {
  /**
   * Abstract Factoryパターンにより異なる種類のクーポンを異なるクラス実装として生成する。
   * それらに共通するI/Fは、「通常のクーポンコード」CouponCodeと区別したかったが、
   * 既存のCouponCodeおよびApiCouponにおいてI/Fと実装の分離が上手く行われていないため、
   * ここではいったんI/Fとしても、また「通常のクーポンコード」実装としても、CouponCodeを使用している。
   */
  create(raw: any): CouponCode {
    if (!hasCouponCode(raw)) {
      /**
       * クーポンコードが入っていない生データが入ってきた場合、どう対応すべきか（スルーするか、エラーを出すか）は現状定まっていない。
       * ひとまず念のため警告メッセージを出しておき、クーポンコードのインスタンス生成は続行する。
       */
      log.warn({
        msg: 'Tried to create a coupon code instance, which has no coupon code number.',
        raw,
      })
    }

    if (isItemsSpecifiedCouponCode(raw)) {
      return new ItemsSpecifiedCouponCode(raw)
    }

    return new CouponCode(raw)
  }
}
