



























import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import * as ImageConfig from '@/config/assets/image'

import ImageWithBadgeBand from '@/components/atoms/image/ImageWithBadgeBand.vue'

import ItemBadgeList from './item/ItemBadgeList.vue'
import ItemDetail from './item/ItemDetail.vue'

import { ApiImage } from '@/models/api/image'
import { ApiProductclass } from '@/models/api/productclass'
import { BandImageHelper, BandImageHelperBuilder } from '@/models/api/productclass/band-url'
import BehaviorConfig from '@/models/app-config/behavior/behavior'
import FKU from '@/models/item/fku'
import * as FKUFactory from '@/models/item/fku/factory/fku'
import { BrandRouteResolver } from '@/util/brandRouteResolver'
import { FkuDecorator } from './item/model/decorator'

import SelectThumbnailImageBuilder from '@/services/product-image/select-thumbnail-image-builder'

@Component({
  components: {
    ImageWithBadgeBand,
    ItemBadgeList,
    ItemDetail,
  },
})
export default class Item extends Vue {
  static readonly BAND_SRC_URL_BASE = '/assets-proxy/images'
  /**
   * true: サムネイルのみ表示
   * false: サムネイル以外の情報(価格、バッジ、カテゴリ、etc...)も表示
   */
  @Prop({ default: false })
  instaview: boolean

  @Prop({ required: true })
  item: {
    id: string
    tags: any[]
    image_url: string
    images: any[]
    parts: any[]
    publish_at: Date,
    belonging_to: any[],
    product_name: string,
  }

  @Prop({ default: () => SelectThumbnailImageBuilder.createDefault() })
  selectThumbnailImageBuilder: SelectThumbnailImageBuilder

  @Prop({
    default: () => (fku: ApiProductclass) => fku,
  })
  fkuDecorator: FkuDecorator

  bandImageHelperBuilder: BandImageHelperBuilder | null = null

  async created() {
    this.bandImageHelperBuilder = await BandImageHelper.builder()
  }

  get fku(): ApiProductclass {
    return this.fkuDecorator(new ApiProductclass(this.item))
  }
  get fkuEntity(): FKU {
    return FKUFactory.create(this.fku)
  }

  get thumbnail(): ApiImage | null {
    return this.selectThumbnailImageBuilder.build()(this.fku.images)
  }

  get thumbnailUrl(): string {
    if (this.thumbnail === null) {
      return ImageConfig.dummyImagePath
    }
    if (this.thumbnail.isGif || this.thumbnail.isInstaView) {
      return this.thumbnail.url
    }

    return this.thumbnail.thumbnailUrl
  }
  get shouldLazy(): boolean {
    if (this.thumbnail === null) {
      return true
    }

    return true
  }

  get loadingImage(): string {
    return ImageConfig.itemLoadingImagePath
  }

  get showBadgeOnTop(): boolean {
    return this.showBadgeOn('showOnTop')
  }
  get showBadgeOnBottom(): boolean {
    return this.showBadgeOn('showOnBottom')
  }

  bandUrl(fku: ApiProductclass): string | null {
    if (this.bandImageHelperBuilder === null) {
      return null
    }

    return this.bandImageHelperBuilder.build(fku, this.brandEnglishName).bandUrl
  }

  get brandEnglishName(): string {
    return BrandRouteResolver.resolveBrandFromPathElement(
        this.$route.params.brand_english_name
      )
  }

  get behavior(): BehaviorConfig {
    return BehaviorConfig.createFromBrand(
      this.brandEnglishName
    )
  }

  private showBadgeOn(selector: 'showOnTop' | 'showOnBottom'): boolean {
    if (this.instaview) {
      return false
    }

    return this.behavior.itemList.item.badge[selector]
  }
}
