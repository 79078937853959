import { Either, Left, Right } from 'fp-ts/lib/Either'
import _ from 'lodash'
import superagent from 'superagent'

import { log } from '@/log'
import * as CurrentTime from '@/models/current-time/functions'
import { ApiService } from '@/services/api/service'
import { ApiClient } from './client'

const namespace: string = 'event'

/**
 * https://everforth.backlog.jp/view/PJ_MYCOLOR-13
 */
export default class EventService extends ApiService {
  async sharePage(data: PageShareEvent): Promise<Either<Error, superagent.Response>> {
    try {
      const response = await ApiClient
        .postPlain(`/api/user-proxy/${this.appId}/user_event`)
        .send(this.formatEventData({
          ...data,
          kind: 'share-page',
          timestamp: CurrentTime.create().timestamp,
        }))

      log.debug({ service: `${namespace}/sharePage`, response })

      return new Right(response)
    } catch (error) {
      log.error({ service: `${namespace}/sharePage`, error })

      return new Left(error)
    }
  }

  async shareItem(data: ItemShareEvent): Promise<Either<Error, superagent.Response>> {
    try {
      const response = await ApiClient
        .postPlain(`/api/user-proxy/${this.appId}/user_event`)
        .send(this.formatEventData({
          ...data,
          kind: 'share-page',
          timestamp: CurrentTime.create().timestamp,
          resource: 'productclass',
        }))

      log.debug({ service: `${namespace}/shareItem`, response })

      return new Right(response)
    } catch (error) {
      log.error({ service: `${namespace}/shareItem`, error })

      return new Left(error)
    }
  }

  /**
   * /user_event payload has a plain string form. See API Usage sheet.
   */
  formatEventData(data: object): string {
    return _.values(
      _.mapValues(data, (value, key) => `${key}:${value}`)
    ).join('	') // NOTE: /user_event requires tab delimiter
  }
}

interface PageShareEvent {
  url: string,
}

interface ItemShareEvent {
  url: string,
  resourceid: string
}
