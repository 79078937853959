
























import _ from 'lodash'

import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import { UserReview } from '@/models/user-review'
import { UserReviewService } from '@/services/user-review.ts'
import { log } from '@spa/log'

import OpenPostReviewModalButton from './OpenPostReviewModalButton.vue'
import UserReviewList from './UserReviewList.vue'

@Component({
  components: {
    OpenPostReviewModalButton,
    UserReviewList,
  },
})
export default class UserReviewArea extends Vue {
  @Prop({ required: true })
  rootId: string

  @Prop({ required: true })
  brand: string

  userReviewService: UserReviewService = UserReviewService.createForBrand(this.brand)

  userReviewList: UserReview[] = []
  userReviewCount: number = 0
  limit: number = 10
  offset: number = 0

  async created() {
    await Promise.all([
      this.countRelatedToRoot(),
      this.listRelatedToRoot(),
    ])
  }

  async countRelatedToRoot(): Promise<void> {
    return this.userReviewService.countRelatedToRoot(this.rootId).then((result) => {
      result.fold(
        error => log.error('error fetching user review count', error),
        value => this.userReviewCount = value
      )
    })
  }

  async listRelatedToRoot(): Promise<void> {
    return this.userReviewService.listRelatedToRoot(this.rootId).then((result) => {
      result.fold(
        error => log.error('error fetching user reviews', error),
        value => this.userReviewList = value
      )
    })
  }

  // TODO ページネーションを実装するまでは10件だけ表示する
  get reviewQuantityLabel(): string {
    if (this.userReviewCount > this.limit) {
      return `最新${this.limit}件`
    }

    return `全${this.userReviewCount}件`
  }

  get userReviewListIsEmpty(): boolean {
    return this.userReviewCount === 0
  }
}
