













import Vue from 'vue'
import { Component, Emit, Prop } from 'vue-property-decorator'

@Component
export default class SuggestList extends Vue {
  @Prop({ required: true })
  keywords: string[]

  @Emit()
  select(_selected: string) {
    // nothing to do
  }
}
