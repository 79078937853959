import { CartService } from '@spa/services/api/cart'
import { RawItemConverter } from '@spa/store/modules/cart/converter'
import { CartItem } from '@spa/store/modules/cart/types'

export interface EnableToListCartItem {
  list(): Promise<CartItem[]>
}

export type CartItemListRepository = EnableToListCartItem

export interface ApiClient {
  list(): Promise<any[]>
}

export class DefaultCartItemRepository implements CartItemListRepository {
  static createViaApiClient(cartService: CartService): CartItemListRepository {
    return new DefaultCartItemRepository(
      cartService,
      RawItemConverter.createViaApiClient(cartService)
    )
  }
  private constructor(
    private readonly apiClient: CartService,
    private readonly converter: RawItemConverter
  ) {}

  async list(): Promise<CartItem[]> {
    const result = await this.apiClient.list()

    return result.fold(
      (error) => { throw error },
      response => this.converter.convert(response.body.data)
    )
  }
}
