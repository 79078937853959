import 'popper.js'

import '~bootstrap/collapse'
import '~bootstrap/modal'
import '~bootstrap/tab'

import _ from 'lodash'
import 'slick-carousel'
import 'webpack-jquery-ui/css'
import 'webpack-jquery-ui/slider'

import { log } from '@/log'
import { CurrentUser } from '@/services/api/current-user'
import { EventBusForThirdParty } from '@/usecases/event-bus-for-third-party/event-bus'
import { adCodeStore } from '@/util/adcode/store'
import { resolveAllWithRandom } from './split-resolver'
import { getSlideColor, trackCartin, trackFacebook, trackFaq, trackFavorite,
  trackLine, trackSlideButton, trackTwitter } from './tracking'
import MyColorUtil from './util'

/**
 * class Initiator
 */
export default class Initiator {
  public static initAdCode() {
    adCodeStore.load()
  }

  public static publishEveryPageViewEvent(adCode: string | undefined) {
    new CurrentUser().getCurrentUser().then(userResult => userResult.fold(
      e => log.error('Failed to fetch current user in the process of initAjax.', e),
      (user) => {
        const { shortid, membership } = user
        EventBusForThirdParty.getInstance().publish('every-page-view', {
          userId: shortid,
          adCode,
          paidMembership: membership,
        })
      }
    ))
  }

  public static initNavTab() {
    $('.js-nav-tabs > .nav-item > .nav-link').on('click', ({ target }) => {
      $(target).tab('show')

      return false
    })
  }
  /**
   * pagetopスクロール
   */
  public static initPageToTop() {
    // all display to right bottom pagetop button
    $('.js-pagetop').on('click', () => {
      const duration = 200
      $('body, html').animate({ scrollTop: 0 }, duration)
    })
  }

  /**
   * SlideShow
   */
  public static initSlideShow() {
    const windowWidth = $(window).width() || 0
    let subSlidesToShow = 0

    // tslint:disable-next-line: no-magic-numbers
    if (windowWidth < 321) {
      // tslint:disable-next-line: no-magic-numbers
      subSlidesToShow = 3
      // tslint:disable-next-line: no-magic-numbers
    } else if (320 < windowWidth && windowWidth < 415) {
      // tslint:disable-next-line: no-magic-numbers
      subSlidesToShow = 5
    } else {
      // tslint:disable-next-line: no-magic-numbers
      subSlidesToShow = 6
    }

    const types = ['pc', 'sp']
    types.forEach((type) => {

      const thumbSub = $(`#js-thumb-${type}-sub`)

      // current pos
      let initialNum = 0
      thumbSub.children('li').each((index, element) => {
        if ($(element).hasClass('active')) {
          initialNum = index

          return false
        }
      })

      const thumbMain = $(`#js-thumb-${type}-main`)

      // item detail for slideshow
      thumbMain.slick({
        infinite: true,
        initialSlide: initialNum,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        nextArrow: $(`#js-thumb-${type}-main-btn-right`),
        prevArrow: $(`#js-thumb-${type}-main-btn-left`),
        fade: false,
        asNavFor: `#js-thumb-${type}-sub`,
      })

      // Register init event before creating slick object
      thumbSub.on('init', (_event, slick) => {
        if (slick.$slides.length > subSlidesToShow) {
          /**
           * 1度の表示範囲外の1枚をチラ見せ
           */
          thumbSub.find('.slick-list').css('padding-right', '10%')
        } else {
          /**
           * 1度に全部表示される場合、位置調整は不要
           */
          slick.positionProp = null
        }

        const color = getSlideColor(slick, slick.currentSlide)

        window.dataLayer.push({
          event: 'detail_event',
          eventCategory: 'detail_imp',
          eventAction: slick.currentSlide,
          eventLabel: color,
        })
      })

      thumbSub.slick({
        infinite: true,
        initialSlide: initialNum,
        asNavFor: `#js-thumb-${type}-main`,
        focusOnSelect: true,
        slidesToShow: subSlidesToShow,
        slidesToScroll: 1,
        swipeToSlide: true,
      })

      // Register event on sub-slide click
      thumbSub.on('afterChange', (_event, slick, currentSlide) => {
        const color = getSlideColor(slick, currentSlide)

        window.dataLayer.push({
          event: 'detail_event',
          eventCategory: 'detail_click',
          eventAction: currentSlide,
          eventLabel: color,
        })
      })
    })
  }

  /**
   * ここに定義されている各種イベントもサードパーティ用イベントフック EventHookForThirdPartyTool に吸収すべきだが、
   * いったんLedian対応で旧来のサードパーティツールと切り替え必須なイベントのみ対応し、これらのイベントは後回しとする。
   */
  public static initEventTracking() {
    trackSlideButton()
    trackFavorite()
    trackCartin()
    trackTwitter()
    trackFacebook()
    trackLine()

    trackFaq()
  }

  /**
   * collapse
   */
  public static initCollapse() {
    // imported partially.
    // Collapse default hide
    $('.js-collapse').collapse('hide')
  }

  /**
   * drawer
   */
  public static initDrawer() {
    const drawer = $('.js-drawer')
    const navs = $('.l-nav')
    const close = $('.l-nav--close')
    const overlay = $('.l-overlay')
    drawer.on('click', () => {
      navs.toggleClass('l-animation--drawer')
      overlay.toggleClass('l-animation--fade')
      close.toggleClass('l-animation--close')
    })
    overlay.on('click', () => {
      navs.toggleClass('l-animation--drawer')
      overlay.toggleClass('l-animation--fade')
      close.toggleClass('l-animation--close')
    })
  }

  /**
   * setting
   */
  public static initSetting() {
    const setting = $('.js-setting')
    const selected = 'p-setting--selected'
    setting.on('click', (e) => {
      setting.each((__, elem) => {
        $(elem).removeClass(selected)
      })
      $(e.target).addClass(selected)
    })

  }

  /**
   * トップ画面の複数画像のスライド処理
   * options: https://blog.mismithportfolio.com/web/20150917slickjs
   */
  public static initTopSlider() {
    $('.js-topSlider').slick({
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 500,
      arrows: true,
      nextArrow: $('.js-mainslider-right'),
      prevArrow: $('.js-mainslider-left'),
    })
  }

  public static initTopBrandList() {
    $('.js-top-brand-list').slick({
      infinite: true,
      slidesToScroll: 1,
      slidesPerRow: 4,
      fade: false,
      arrows: true,
      nextArrow: $('.js-top-brand-btn--right'),
      prevArrow: $('.js-top-brand-btn--left'),
      rows: 2,
    })
  }

  public static initDialogMigration() {
    // @ts-ignore
    $('#dialog-migration').modal({
      show: true,
    })
  }

  // TODO 動作してない可能性大なので、様子見て削除 #319
  public static initSpinner() {

    this.closeSpinner()

    if (MyColorUtil.isSpa()) return

    window.addEventListener('beforeunload pagehide', () => {

      const CLOSING_TIME: number = 7000

      try {
        this.showSpinner()
        setTimeout(() => this.closeSpinner(), CLOSING_TIME)
      } catch (e) {
        this.closeSpinner()
      }

    })
  }

  public static initSplitContent() {
    resolveAllWithRandom()
  }

  private static showSpinner() {

    $('#js-spinner').show()

    const min: number = 1
    const max: number = 8

    const spinnerHTML: string = _.map(_.range(min, max), count =>
      `<div id="js-spinner" class="c-spinner c-spinner-${count}"></div>`
    ).join()

    $('#js-spinner--icon').replaceWith(spinnerHTML)
  }

  private static closeSpinner() {

    $('#js-spinner').hide()
  }
}
