













import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import { URL } from '@/util/url'
import ShareBase from './ShareBase.vue'

@Component({
  components: {
    ShareBase,
  },
})
export default class ShareLine extends Vue {

  @Prop({ required: true })
  url: string

  @Prop({ required: true })
  text: string

  get urlOnShare(): string {
    const merged = URL.merge(this.url).with(this.gaparam)

    return encodeURIComponent(merged)
  }

  get gaparam(): string {
    return '?m=line_share&utm_source=line&utm_medium=social&utm_campaign=item_share'
  }
}
