import _ from 'lodash'
import { ItemDetailViewEvent } from '../../event'

export function handleItemDetailView(event: ItemDetailViewEvent) {
  const { fkuId, fkuName } = event

  /**
   * 商品詳細画面の閲覧イベントをajaxから発火させる想定であるが、ajaxがマウントするVueコンポーネントはpc/spそれぞれのPHPテンプレートにマウントされる。
   * そのため、どのコンポーネントから発火させるにしても、pc/spテンプレート（これはそのときpcかspどちらの表示になっていてもブラウザにロードされる）の2回分、GTMへの送信が発生してしまう。
   * 解決策としては
   * - (1)PHPテンプレート側でpc/spに共通かつ商品詳細画面に固有のテンプレートを用意し、
   *   - そこに商品詳細閲覧イベントを送信するVueコンポーネントを設けるか、
   *   - PHPテンプレートに直書きで商品詳細閲覧イベントを送信する。
   * - (2)pc/sp2回分の（GrowthPaletteとしての）閲覧イベントは許容しつつ、GTMへの送信は1回にする。
   *
   * ここでは簡便な方法として(2)を採用したが、(1)の方向で解決するのが望ましい。
   * - 厳密なことを言えば、dataLayerの中に'view_item'が高々一回しか存在しないようにチェックしている最中に、dataLayerが'view_item'について変更を加えられていないという前提も必要である。
   * - この前提はpc/spの一方で発火したこのイベントハンドラの後に「たまたま直列に」もう一方のイベントハンドラが発火するおかげで、保証されているにすぎない。
   * - この観点からも、(1)の方向での解決が望まれる。
   *
   * イベント発火そのものをonceで食い止めるという手段も要検討。
   * @see https://github.com/my-color/front/pull/6149#discussion_r812558323
   */
  if (alreadyExistInDataLayerByFkuId(fkuId)) {
    return
  }

  window.dataLayer.push({
    event: 'view_item',
    ecommerce: {
      items: [{
        item_name: fkuName,
        item_id: fkuId,
        currency: 'JPY',
        /**
         * 価格はv1ではMUSTではない。
         */
        // price: "5000",
        quantity: 1,
      }],
    },
  })
}

export function alreadyExistInDataLayerByFkuId(
  fkuId: string,
  getDataLayer: () => any[] = () => window.dataLayer
): boolean {
  const dataLayer = getDataLayer()

  return !!dataLayer.find((data) => {
    if (data.event !== 'view_item' || !data.ecommerce) {
      return false
    }

    if (!_.isArray(data.ecommerce.items)) {
      return false
    }

    return !!(data.ecommerce.items || []).find(item => item.item_id === fkuId)
  })
}
