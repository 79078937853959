export class Color {
  static createFromDto(raw: any): Color {
    return new Color(
      raw.id,

      /**
       * Color.nameはカラー絞り込み検索時のクエリとして使う
       * Color.cssClassSuffixはカラー表示時のCSSクラス名生成のために使う
       *
       * 既存実装では、DTOの name フィールドの値が、その両方に使われていたが、
       * ブランドによってはフロント側で表示するカラーだけを書き換える必要があるため、
       * クエリ用とCSSクラス名用とを別のプロパティとして分けて扱う必要がある。
       *
       * 経緯: https://github.com/my-color/front/issues/5462#issuecomment-881763076
       */
      raw.name,
      raw.name,

      raw.pathname,
      raw.title,
      raw.display_level,
      false
    )
  }

  constructor(
    readonly id: string,
    readonly name: string,
    readonly cssClassSuffix: string,
    readonly pathname: string,
    readonly title: string,
    readonly displayLevel: number,
    readonly checked: boolean
  ) {}

  is(id: string): boolean {
    return this.id === id
  }

  changeTo(cssClassSuffix: string, title: string): Color {
    return new Color(
      this.id,
      this.name,
      cssClassSuffix,
      this.pathname,
      title,
      this.displayLevel,
      this.checked
    )
  }

  check(): Color {
    return new Color(
      this.id,
      this.name,
      this.cssClassSuffix,
      this.pathname,
      this.title,
      this.displayLevel,
      true
    )
  }

  unCheck(): Color {
    return new Color(
      this.id,
      this.name,
      this.cssClassSuffix,
      this.pathname,
      this.title,
      this.displayLevel,
      false
    )
  }
}
