import {
  serialize,
  SSymbol,
  SSymbolObject,
} from '../type/s-symbol'

export default class SList implements SSymbolObject {
  static with(firstSymbol: SSymbol) {
    return (...symbols: SSymbol[]) => new SList([firstSymbol, ...symbols])
  }
  static create(...symbols: SSymbol[]): SList {
    return new SList(symbols)
  }
  static nil(): SList {
    return new SList([])
  }

  private constructor(private readonly symbols: SSymbol[]) { }

  add(atom: SSymbol): SList {
    return new SList([...this.symbols, atom])
  }

  concat(...other: SSymbol[]): SList {
    return new SList([...this.symbols, ...other])
  }

  serialize(): string {
    const serialized = this.symbols.map(serialize).join(' ')

    return `(${serialized})`
  }

  toString(): string {
    return this.serialize()
  }
}
