import { Either, Left, Right } from 'fp-ts/lib/Either'

import { log } from '@/log'
import { ApiService } from '@/services/api/service'
import { TagsService } from '../tags'

/**
 * サイズタグのデータ構造
 *
 * TODO:
 * 他のタグやタグデータそのものと合わせて、d.tsとして定義
 */
export interface SizeTag {
  id: string
  display_level: number
  name: string
  pathname: string
  title: string
}

export class SizeTagsService extends ApiService {

  static readonly pathname = 'company.product.size'

  private tagsService: TagsService

  constructor(private brandName: string, appId?: string) {
    super(appId)
    this.tagsService = new TagsService(appId)
  }

  async getOrdered(
    order: ((a: SizeTag, b: SizeTag) => number) | 'asc' | 'desc',
    scopeKind: string | null = null
  ): Promise<Either<Error, SizeTag[]>> { // TODO Right側の型定義実装
    try {
      const either = await this.tagsService.get(
        `${SizeTagsService.pathname}.${this.brandName}`,
        scopeKind
      )

      if (either.isLeft()) {
        return new Left(either.value)
      }
      const response = either.value
      const sizeList: SizeTag[] = response.body.data

      if (typeof order === 'function') {
        return new Right(sizeList.sort(order))
      }

      return new Right(sizeList.sort((before, after) => {
        const beforeDisplay = before.display_level
        const afterDisplay = after.display_level

        if (order === 'asc') {
          return beforeDisplay < afterDisplay ? -1 : 1
        }

        return beforeDisplay > afterDisplay ? -1 : 1
      }))
    } catch (error) {
      log.error(SizeTagsService.name, { error })

      return new Left(error)
    }

  }
}
