import { log } from '@/log'
import { RawTag } from '@/models/api/tags'
import { ApiClientWithAuth } from '@/services/api/client'
import { ApiService } from '@/services/api/service'
import { Either, left, right } from 'fp-ts/lib/Either'

/**
 * TODO: {@see User}と統合. store専用の型定義を廃止して、{@see CurrentUserScheme}に寄せるで良さそう
 */
export interface CurrentUserScheme {
  id: string
  shortid: string
  first_name?: string
  last_name?: string
  phonetic_first_name?: string
  phonetic_last_name?: string
  birthday?: string
  gender: number
  email?: string
  tel?: string
  zipcode?: string
  region?: string
  address1?: string
  address2?: string
  current_point?: number
  aux_text_10: string
  user_status: CurrentUserStatus
  payment_auth_token?: string
  payment_accountid?: string
  elapsed_days_last_order?: number,
  order_times?: number,
  created_at: string
  tags: RawTag[]
  membership: Membership
}
export type CurrentUserStatus = 'registered' | 'provisional'

export interface Membership {
  enabled: boolean
  registered_plans: Array<{ external_id: string, display_label: string }>
  remaining_count: RemainingCount|null
}

export interface RemainingCount {
  purchase_not_considering_current_cart: number,
  add_to_cart: number,
}

const namespace = 'current_user'

export class CurrentUser extends ApiService {
  async getCurrentUser(): Promise<Either<Error, CurrentUserScheme>> {
    return ApiClientWithAuth.get('/api/user/current_user')
      .then((response): Either<Error, CurrentUserScheme> => {
        log.debug({ service: `${namespace}/getCurrentUser`, response })

        return right(insertMockMembership(response.body.data))
      })
      .catch((e): Either<Error, CurrentUserScheme> => {
        log.error({ service: `${namespace}/getCurrentUser` }, e)

        return left(e)
      })
  }
}

/**
 * @deprecated apiがデータを返すようになったら削除
 * @param data
 */
function insertMockMembership(data: any): CurrentUserScheme {
  return {
    ...data,
    membership: data.membership || {
      enabled: true,
      registered_plans: [
        {
          external_id: 'womenz-1m',
          display_label: 'レディース一ヶ月',
        },
        {
          external_id: 'menz-1m',
          display_label: 'メンズ一ヶ月',
        },
      ],
    },
  }
}
