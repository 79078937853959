



























import $ from 'jquery'
import _ from 'lodash'
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import ContentsService from '@/services/api/contents'
import AppId from '@/util/appid'
import { InstanceResolver } from '@/util/instanceResolver'
import SuggestList from './search-keyword/SuggestList.vue'

@Component({
  components: {
    SuggestList,
  },
})
export default class SearchKeyword extends Vue {
  @Prop({ default: null })
  brand: string | null

  contentsService: ContentsService = new ContentsService(this.appId)

  showSuggest: boolean = false
  hasError: boolean = false

  styleOfSuggest: {
    width: string;
  } = {
    width: '0',
  }

  keyword: string = ''
  suggestList: string[] = []

  get appId(): string {
    return AppId.getByBrandName(this.brand)
  }

  get actionUrl(): string {
    const path = _.compact([
      !InstanceResolver.isSingle() ? this.brand : '',
      'itemlist',
      'search',
    ]).join('/')

    return `/${path}`
  }

  get hasSuggestList(): boolean {
    return this.suggestList.length > 0
  }

  async mounted() {
    const result = await this.contentsService.get(`RecommendKeyWords`)

    if (result.isLeft()) {
      this.hasError = true

      return
    }
    const data = result.value as any
    this.suggestList = (data.content as string).split(',').map(k => k.trim()).filter(k => k !== '')
  }

  onFocus() {
    const target = $('.c-searchKeyword')
    // サジェストボックスの幅を検索ボックスの幅にそろえる
    this.styleOfSuggest.width = `${target.outerWidth() || 0}px`
    this.showSuggest = !this.hasError && this.hasSuggestList
  }
  onSelect(selected: string) {
    this.keyword = selected

    this.hideSuggest()
  }

  hideSuggest() {
    this.showSuggest = false
  }
}
