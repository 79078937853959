












import { Mixins } from 'vue-mixin-decorator'
import { Component } from 'vue-property-decorator'

import Config from '@ajax/modules/config'
import Util from '@ajax/modules/util'
import RangeSlider from '@ajax/vue/components/atoms/RangeSlider.vue'
import ApiMixin from '@ajax/vue/mixin/Api'

@Component
export default class SearchPrice extends Mixins<ApiMixin>(ApiMixin) {

  $refs: {
    rangeSlider: RangeSlider
  }

  selectedPrices: any = {
    min: 0,
    max: Config.CONDITION_PRICE_MAX,
  }

  mounted () {
    this.reflectUrlParams()
  }

  onChangePrice (value) {
    this.selectedPrices = value
  }

  reflectUrlParams () {
    const urlParams = Util.convertItemlistUrlParamsToAssociativeArray()
    if (urlParams.price) {
      const prices = urlParams.price.split('-')
      if (prices[0] !== '') {
        this.selectedPrices.min = parseInt(prices[0], 10)
      }
      if (prices[1] !== '') {
        this.selectedPrices.max = parseInt(prices[1], 10)
      }
    }
  }

}
