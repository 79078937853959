import { CurrentUserStatus, Membership } from '@/services/api/current-user'

export const initialUserState: UserState = {
  user: null,
  error: {
    get: null,
    update: null,
    login: null,
    logout: null,
    register: null,
    mailMagazine: null,
  },
}

export interface UserState {
  user: User | null,
  error: {
    get: Error | null,
    update: Error | null,
    login: Error | null,
    logout: Error | null,
    register: Error | null,
    mailMagazine: Error | null,
  },
}

/**
 * TODO: {@see CurrentUserScheme}と統合. store専用の型定義を廃止して、{@see CurrentUserScheme}に寄せるで良さそう
 */
export interface User {
  id: string,
  shortid: string,
  first_name?: string
  last_name?: string
  phonetic_first_name?: string,
  phonetic_last_name?: string,
  birthday?: string,
  gender: number,
  email?: string,
  tel?: string,
  zipcode?: string,
  region: string,
  address1?: string,
  address2?: string,
  current_point?: number,
  /**
   * ユーザーの保持しているカラット
   * - 2022/07/13 実質利用箇所無し
   * - JSON形式想定のようだが、データ形式不明
   *  - client/spa/src/components/pages/mypage/carat/Index.vueの
   *    {@link Index.carats}ではanyにされている
   */
  aux_text_10?: string,
  user_status: CurrentUserStatus,
  payment_auth_token?: string,
  payment_accountid?: string,
  elapsed_days_last_order?: number,
  order_times?: number,
  created_at: Date,
  membership: Membership,
}
