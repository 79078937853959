import { log } from '@/log'
import { ApiProductclass } from '@/models/api/productclass'
import { ListWithPagination } from '@/models/api/response/list'
import { FkuQuery } from '@/services/api/item/fku/fku-query'
import { FkuUrl } from '@/services/api/item/fku/fku-url'
import AppId from '@/util/appid'
import { Either, Left, Right } from 'fp-ts/lib/Either'
import _ from 'lodash'
import { ApiClient } from './client'
import { ApiService } from './service'

const namespace: string = 'productclass'

/**
 * TODO: root取得用のapi clientとfku取得用のapi clientを分ける
 */
export default class ProductclassService extends ApiService {

  /**
   * ユーザー情報を伴うFKU一覧を取得する
   * @note 一律でサブスクリプション除外のtagpを付与したいが、like=true と組み合わせるとEngineがエラーを起こすのでここでは付けていない.
   * @see https://github.com/my-color/front/issues/5656#issuecomment-914223746
   */
  public async listFkuForUser(
    query: object
  ): Promise<Either<Error, any[]>> {
    return this.listFkuForUserWithPagination(query).then((result) => {
      return result.map(listWithPagination => listWithPagination.list)
    })
  }

  /**
   * ユーザー情報を伴うFKU一覧を、ページネーション設定とともに取得する
   * @note 一律でサブスクリプション除外のtagpを付与したいが、like=true と組み合わせるとEngineがエラーを起こすのでここでは付けていない.
   * @see https://github.com/my-color/front/issues/5656#issuecomment-914223746
   */
  public async listFkuForUserWithPagination(
    query: object
  ): Promise<Either<Error, ListWithPagination<any>>> {
    try {
      const response = await ApiClient
        .get(FkuUrl.forUser.withBrand(this.brand).list)
        .query(this.getNewQuery(query))

      log.debug({ service: `${namespace}/userItemList`, response })

      return new Right(
        ListWithPagination.createFrom(response)
      )
    } catch (error) {
      return new Left(error)
    }
  }

  public async listFku(
    query: object
  ): Promise<Either<Error, any[]>> {
    return this.listFkuWithPagination(query).then((result) => {
      return result.map(listWithPagination => listWithPagination.list)
    })
  }

  public async listFkuWithPagination(
    query: object
  ): Promise<Either<Error, ListWithPagination<any>>> {
    try {
      const response = await ApiClient
        .get(FkuUrl.forCommon.withBrand(this.brand).list)
        .query(this.getNewQuery(query))

      log.debug({ service: `${namespace}/commonItemList`, response })

      return new Right(
        ListWithPagination.createFrom(response)
      )
    } catch (error) {
      return new Left(error)
    }
  }

  async getRootItem(id: string, query: any = {}): Promise<Either<Error, any>> {
    try {
      const response = await ApiClient
        .get(`/api/common-proxy/${this.appId}/productclass2/${id}`)
        .query(query)

      const item = response.body.data
      log.debug({ service: `${namespace}/getRootItem`, response })

      return new Right(item)
    } catch (error) {
      log.error({ error })

      return new Left(error)
    }
  }

  public async getRootWithChild(parentRootId: string): Promise<Either<Error, ApiProductclass>> {
    try {
      const response = await ApiClient
        .get(`/api/user-proxy/${this.appId}/productclass2/${parentRootId}`)
        .query({
          with: 'using_to',
        })

      return new Right(new ApiProductclass(response.body.data))
    } catch (error) {
      log.error({ error })

      return new Left(error)
    }
  }

  public async getFkuWithSkuFromRootId(rootId: string): Promise<Either<Error, ApiProductclass[]>> {
    try {
      const response = await ApiClient
      .get(FkuUrl.forCommon.withBrand(this.brand).list)
      .query({
        belonging_to: rootId,
        with: 'part,stock~detail',
        root_id: rootId,
      })

      return new Right(
        _.map(response.body.data, item => new ApiProductclass(item))
      )
    } catch (error) {
      log.error({ error })

      return new Left(error)
    }
  }

  async listFkuBelongingToRoot(rootId: string, query: any = {}): Promise<Either<Error, any[]>> {
    return this.listFku(FkuQuery.initialize({
      ...query,
      belonging_to: rootId,
      root_id: rootId,
    }).toObject())
  }

  async putItem(id: string, data): Promise<Either<Error, object>> {
    try {
      const response = await ApiClient
        .put(`/api/user-proxy/${this.appId}/productclass2/${id}`, data)

      const result = response.body.data

      log.debug({ service: `${namespace}/putItem`, response })

      return new Right(result)
    } catch (error) {
      log.error({ error })

      return new Left(error)
    }
  }

  // TODO: Adohoc PJ_MYCOLOR-1099
  private getNewQuery(query: object) {

    const newQuery: object = query

    if (!('part.status_id' in query)) {
      // adhoc
      query['part.status_id'] = 1
    }

    if ('with' in newQuery) {
      const withs: string[] = newQuery['with'].split(',')
      if (_.indexOf(withs, 'indexsummary') < 0) withs.push('indexsummary')
      newQuery['with'] = withs.join(',')

    } else {
      newQuery['with'] = 'indexsummary'
    }

    return newQuery
  }

  private get brand(): string {
    return AppId.getBrandNameByAppId(this.appId)
  }
}
