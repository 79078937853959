import { createCartButtonInItemListState } from '@ajax/vue/store/itemlist/cart-button'
import Vue from 'vue'
import Vuex from 'vuex'
import * as Root from './types'

import { createError } from '@/store/modules/error'

Vue.use(Vuex)

export default new Vuex.Store<Root.RootState>({
  state: Root.createInitial(),
  modules: {
    error: createError(),
    cartButtonInItemList: createCartButtonInItemListState(),
  },
})
