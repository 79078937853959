import Stock from '@/models/item/stock'
import DisplayRule from '@/models/item/stock/specification/display-rule'
import StockIsFew from '@/models/item/stock/specification/stock-is-few'

export default class WebStockIsSome implements DisplayRule {
  constructor(private readonly spec: StockIsFew) { }

  shouldApply(stock: Stock): boolean {
    const display = stock.display

    if (display.isDisable || display.exists) {
      return false
    }

    return this.spec.verify(stock.web)
  }
  apply(stock: Stock): string {
    return `残り${stock.web.amount}点`
  }
}
