import _ from 'lodash'

import { createFkuStateTemporal } from '@/components/molecules/item/model/fku-state-temporal'
import { ApiProductclass } from '@/models/api/productclass'
import { BaseUrlRepository, CacheAvailableS3BaseUrlRepository } from '@/models/api/resource/base-url-repository'
import BehaviorConfig from '@/models/app-config/behavior/behavior'
import { MyColorPartialBandPolicy } from '@/models/item-band/partial-band-policy'
import FKU from '@/models/item/fku'
import * as FKUFactory from '@/models/item/fku/factory/fku'
import GetStockStatus from '@/usecase/get-stock-status'
import { URL } from '@/util/url'

export interface BandImageHelperBuilder {
  build(
    fku: ApiProductclass,
    brandEnglishName: string
  ): BandImageHelper
}
export class BandImageHelper {
  static async builder(
    repository: BaseUrlRepository = CacheAvailableS3BaseUrlRepository.instance
  ): Promise<BandImageHelperBuilder> {
    const baseUrl = await repository.load()

    return {
      build(
        fku: ApiProductclass,
        brandEnglishName: string
      ): BandImageHelper {
        return new BandImageHelper(
          fku,
          brandEnglishName,
          baseUrl
        )
      },
    }
  }
  private readonly behavior: BehaviorConfig

  private constructor(
    private readonly fku: ApiProductclass,
    brandEnglishName: string,
    private readonly baseUrlPath: string
  ) {
    this.behavior = BehaviorConfig.createFromBrand(brandEnglishName)
  }

  public get bandUrl(): string | null {
    const layerImage = this.fku.images.find(img => img.isLayer)
    if (layerImage) {
      return layerImage.url
    }

    const fkuState = createFkuStateTemporal(this.fku)
    const brand = this.fku.belongingBrand.englishName
    // this.baseUrlPath = https://...の形式を想定
    const imageUrlPath = URL.join(this.baseUrlPath, 'ma', brand, 'band')
    const isSoldOut = this.mycolorSoldOutBandSpec.isSoldOut(this.fku)

    if (!isSoldOut && this.getStockStatus.isFew(this.fkuEntity.stock)) {
      return `${imageUrlPath}/${BandImageUrl.FewStock}`
    }
    // 再入荷リクエストあり
    if (fkuState.acceptRestockRequest && this.getStockStatus.isSoldOut(this.fkuEntity.stock)) {
      return `${imageUrlPath}/${BandImageUrl.AcceptRestockRequest}`
    }
    // SOLD OUT
    if (isSoldOut) {
      return `${imageUrlPath}/${BandImageUrl.SoldOut}`
    }

    return null
  }

  private get fkuEntity(): FKU {
    return FKUFactory.create(this.fku)
  }

  private get getStockStatus(): GetStockStatus {
    return GetStockStatus.createViaConfig(
      this.behavior
    )
  }

  private get mycolorSoldOutBandSpec(): MyColorPartialBandPolicy {
    return new MyColorPartialBandPolicy(this.getStockStatus)
  }

}

enum BandImageUrl {
  FewStock = 'image-band-wazka.png',
  AcceptRestockRequest = 'image-band-request-stock.png',
  SoldOut = 'image-band-no-request-stock.png',
}
