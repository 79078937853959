import _ from 'lodash'

import { BundleDiscountState, createInitialBundleDiscount } from './modules/bundle-discount/types'
import { initialSearchContactLensState, SearchContactLensState } from './modules/searchContactLens/types'

export const initialRootState: RootState = {
  bundleDiscount: createInitialBundleDiscount(),
  searchContactLens: initialSearchContactLensState,
}

export interface RootState {
  bundleDiscount: BundleDiscountState,
  searchContactLens: SearchContactLensState,
}
