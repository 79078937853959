import Vue from 'vue'
import { Mixin } from 'vue-mixin-decorator'
import { Watch } from 'vue-property-decorator'

@Mixin
export default class LoadingMixin extends Vue {
  @Watch('isLoading', { immediate: true })
  async onUpdateStatus() {
    if (this.isLoading) {
      await this.$store.dispatch('view/startLoading')
    } else {
      await this.$store.dispatch('view/stopLoading')
    }
  }

  // hook method
  get isLoading(): boolean {
    return false
  }
}

/**
 * @note 既存実装では使い分けが徹底されていない可能性あり.その場合、↓にかかれている方針で使い分けるように変更して良い.
 */
export enum Status {
  /**
   * ユーザー入力や初期化処理実行前など、何かしらの動作を待機している状態
   */
  Pending,
  /**
   * 処理が失敗して、続行不可能になった状態
   */
  Failed,
  /**
   * 全ての処理が完了して、以降続行する必要があるものが無くなった状態
   * @note ↑のような状態に画面がなったなら、基本的には画面遷移等が発生するだろうから、UI側では不要?
   */
  Completed,
  /**
   * 処理が実行中で、新たに別の処理を受付できない状態.
   */
  Loading,
}
