import { log } from '@/log'
import { ApiClientWithAuth } from '@/services/api/client'
import { Either, left, right } from 'fp-ts/lib/Either'
import { SkuPrice } from './scheme'

export interface SkuPriceRepository {
  list(skuIds: string[]): Promise<Either<Error, SkuPrice[]>>
}

export class DefaultSkuPriceRepository implements SkuPriceRepository {
  constructor(private readonly brandEnglishName: string) {}

  async list(skuIds: string[]): Promise<Either<Error, SkuPrice[]>> {
    try {
      const res = await ApiClientWithAuth.get(`/api/user/${this.brandEnglishName}/item/sku/price`, {
        skuIds: skuIds.join(','),
      })

      return right(res.body.data)
    } catch (error) {
      log.error({ error })

      return left(error)
    }
  }
}
