import { ApiProductclass } from '@/models/api/productclass'
import {
  AcceptNewItemRequestRule,
  AcceptRestockRequestRule,
  ComingSoonRule,
  ComingSoonSoldOutRule,
  IsOnPreSaleRule,
  PreSaleSoldOutRule,
  PurchasableRule,
} from '@/models/badge-list/display-rule'
import { RuleApplicableFku } from '@/models/badge-list/rule-applicable-fku'
import GetStockStatus from '@/usecase/get-stock-status'

/**
 * NOTE: バッジ表示リファクタリングのリリースを優先するため、帯画像については一時的な対応に留める
 * TODO: できるだけ早急にバッジリファクタと似た設計でリファクタリングする
 * @see https://github.com/my-color/front/issues/4592
 */
export class MyColorPartialBandPolicy {
  public constructor(private readonly getStockStatus: GetStockStatus) {}

  public isSoldOut(fkuDto: ApiProductclass) {
    const isOnPreSaleRule = new IsOnPreSaleRule()
    const preSaleSoldOutRule = new PreSaleSoldOutRule()
    const newItemRequestRule = new AcceptNewItemRequestRule()
    const restockRequestRule = new AcceptRestockRequestRule()
    const purchasableRule = new PurchasableRule()
    const comingSoonRule = new ComingSoonRule()
    const comingSoonSoldOutRule = new ComingSoonSoldOutRule()

    const normalItemSoldOutRule = purchasableRule.not()
                                  .and(isOnPreSaleRule.not())
                                  .and(preSaleSoldOutRule.not())
                                  .and(newItemRequestRule.not())
                                  .and(restockRequestRule.not())
                                  .and(comingSoonRule.not())
                                  .and(comingSoonSoldOutRule.not())

    const preSaleSoldOutBandRule = purchasableRule.not()
                                   .and(isOnPreSaleRule.not())
                                   .and(comingSoonRule.not())
                                   .and(newItemRequestRule.not())
                                   .and(restockRequestRule.not())
                                   .and(preSaleSoldOutRule)

    const comingSoonSoldOutBandRule = purchasableRule.not()
                                      .and(isOnPreSaleRule.not())
                                      .and(comingSoonRule.not())
                                      .and(newItemRequestRule.not())
                                      .and(restockRequestRule.not())
                                      .and(preSaleSoldOutRule.not())
                                      .and(comingSoonSoldOutRule)

    /**
     * soldout帯を出す仕様はMyColorの場合、
     * 「通常商品売り切れまたは予約商品売り切れまたは入荷待ち商品売り切れ」であること
     */
    const soldOutBandDisplaySpec = normalItemSoldOutRule
                                   .or(preSaleSoldOutBandRule)
                                   .or(comingSoonSoldOutBandRule)

    return soldOutBandDisplaySpec.isSatisfiedBy(
      new RuleApplicableFku(fkuDto, this.getStockStatus).toInput()
    )
  }
}
