import chunk from 'lodash/chunk'
import flatten from 'lodash/flatten'

export default class ChunkedExecuter {
  constructor(private limit: number) { }

  execute<T>(chunkableItems: T[], executer: (items: T[]) => T[] | T): T[]
  execute<T, R>(chunkableItems: T[], executer: (items: T[]) => R[] | R): R[]
  execute<T, R>(chunkableItems: T[], executer: (items: T[]) => R[] | R): R[] {
    const results = this.runExecuter(chunkableItems, executer)

    return flatten(results)
  }

  private runExecuter<T, R>(chunkableItems: T[], executer: (item: T[]) => R): R[]
  private runExecuter<T, R>(chunkableItems: T[], executer: (item: T[]) => R[]): R[][] {
    if (chunkableItems.length === 0) {
      return []
    }

    return chunk(chunkableItems, this.limit).map(executer)
  }
}
