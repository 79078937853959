import AbstractValue from '../abstract-value'

export enum SlideMenuItem {
  AccountService = 'account-service',
  FreeArea = 'free-area',
  Category = 'category',
  Genre = 'genre',
  Search = 'search',
  About = 'about',
}

export interface RawSlideMenu {
  allowed: 'all' | SlideMenuItem[]
}

export default class SlideMenu extends AbstractValue<RawSlideMenu> {
  get allowed(): 'all' | SlideMenuItem[] {
    return this.raw.allowed
  }

  allow(item: SlideMenuItem): boolean {
    if (this.allowed === 'all') {
      return true
    }

    return !!this.allowed.find(i => i === item)
  }
}
