import { Events } from './event'
import Publisher, { IPublisher, Subscriber } from './publisher'

type T = keyof Events
type PublishersMap = {
  [Type in T]?: IPublisher<Type>
}

/**
 * サードパーティ向けイベントに対して、イベントの送信（pub）やイベントの購読（sub）を仲介するイベントバス。
 * システム全体でシングルトンとして運用さる。pub/subの利用側からはこのイベントバスのシングルトンを呼び出す。
 *
 * pub/subの利用側はこのイベントバス（のシングルトン）をファサードとして直接呼び出すが、
 * 内部的にはイベントバスはイベントタイプごとのオブザーバを保持しているだけであり、各タイプに対するpub/subの処理は各オブザーバが担う。
 * - ただしsub側は、一定の粒度・整合性にしたがって複数のイベントを購読するユースケースが考えられるため、実際のファサードはこのクラスではなくそのように複数のイベントを束ねたものでありうる。
 *
 * このEventBusの仕組み自体は汎用的なものであるが、いまは簡便のため特定のEvents型（このevent-bus-for-third-partyモジュール専用）に依存している。
 * 汎用化したければ、Events型の部分を型パラメータに変更したり、適切なライブラリを使用するなどを検討する。
 */
export class EventBusForThirdParty {
  static getInstance() {
    if (!EventBusForThirdParty.instance) {
      EventBusForThirdParty.instance = new EventBusForThirdParty()
    }

    return EventBusForThirdParty.instance
  }

  private static instance: EventBusForThirdParty
  private publishers: PublishersMap = {}

  private constructor() {}

  public publish<Type extends T>(type: Type, event: Events[Type]): void {
    if (!EventBusForThirdParty.getInstance().publishers[type]) {
      EventBusForThirdParty.getInstance().publishers = {
        ...EventBusForThirdParty.getInstance().publishers,
        [type]: new Publisher<Type>(),
      }
    }

    // @ts-ignore
    EventBusForThirdParty.getInstance().publishers[type].publish(event)
  }

  public subscribe<Type extends T>(
    type: Type,
    subscriber: Subscriber<Type>
  ): () => void {
    if (!EventBusForThirdParty.getInstance().publishers[type]) {
      EventBusForThirdParty.getInstance().publishers = {
        ...EventBusForThirdParty.getInstance().publishers,
        [type]: new Publisher<Type>(),
      }
    }

    // @ts-ignore
    return EventBusForThirdParty.getInstance().publishers[type].addSubscriber(subscriber)
  }
}

export default EventBusForThirdParty.getInstance()
