import { ApiProductclass } from '@/models/api/productclass'
import RootModel from '@/models/item/root'
import RootFactory from '@/models/item/root/factory'
import { DefaultFetchSkuPrice } from '@/models/item/sku/repository/impl/fetch-sku-price'
import { Either, right } from 'fp-ts/lib/Either'
import { ItemListSpec, SkuListInAllFkus } from '../specifications/auto-select-item'
import { AvailabilityState, Root } from '../view-models'

/**
 * 1ROOT-1SKUの場合には、ROOT商品用のビューモデルにカート追加用のSKU情報も含めるが、
 * そのためにSKUに対して有効な会員価格を取得してビューモデルを返す。
 */
export class FetchRootWithSkuPrice {
  static create(
    brandEnglishName: string,
    rootFactory: RootFactory
  ): FetchRootWithSkuPrice {
    return new FetchRootWithSkuPrice(
      brandEnglishName,
      rootFactory
    )
  }

  private constructor(
    private readonly brandEnglishName: string,
    private readonly rootFactory: RootFactory
  ) {}

  /**
   * client側で、1ROOT-1SKUの場合の商品詳細画面におけるROOT商品情報を取得した上で、
   * さらにSKU価格のAPIを別途呼び出して有料会員価格を考慮した価格情報を注入している。
   * TODO: 本来的には、商品詳細画面で必要なROOT商品情報なりより適したモデルを定義して、client側で注入することもなくBFF的にserverから取得するだけで済ましたい。
   *
   * @param fkuList any型で宣言しているのは、Vueコンポーネントにベタ書きされている既存ロジックから外に括り出してきたため。
   * せめてApiProductclassで包んでおきたいが、AddToCartButton.vueの `Util.getItemDetailInfo(this.fkuList)` が
   * ApiProductclassで包むことも難しくしていた。
   * TODO: そもそもFKUリストを取得するのがもっぱらRootビューモデルを取得するためであるなら、FKUリストの取得もこのインタラクションの中に隠蔽すべき。
   * ただしAddToCartButton.vueでは、上述の通りfkuListそのものを参照している箇所も存在するので、
   * いったんはfkuListからRootビューモデルを生成するための副作用をここに切り出すに留めておく。
   */
  async fetch(fkuList: any[]): Promise<Either<Error, Root>> {
    const rootModel: RootModel = this.rootFactory.createFromFkuList(
      fkuList.map(fku => new ApiProductclass(fku)
    ))
    const availabilityState: AvailabilityState = {
      isPurchasable: rootModel.isPurchaseable,
      acceptNewItemRequest: rootModel.acceptNewItemRequest,
      acceptRestockRequest: rootModel.acceptRestockRequest,
      isWaitingArrival: rootModel.isWaitingArrival,
    }

    const skuList = SkuListInAllFkus.createFromRawFkuList(fkuList)
    const skuIdForCartIn = new ItemListSpec().maySelectAuto(skuList).toNullable()

    if (!skuIdForCartIn) {
      return right({
        state: availabilityState,
        itemForCartIn: null,
      })
    }

    const maybeSkuPrice = await DefaultFetchSkuPrice.create(this.brandEnglishName).fetchById(skuIdForCartIn)

    return maybeSkuPrice.map(skuPrice => ({
      state: availabilityState,
      itemForCartIn: {
        id: skuIdForCartIn,
        /**
         * 一度にカートに入れられるのは一つだけ
         * @see: https://github.com/my-color/front/pull/32/files#r645391322
         */
        count: 1,
        price: skuPrice,
      },
    }))
  }
}
