import Stock from '@/models/item/stock'
import DisplayRule from '@/models/item/stock/specification/display-rule'

export default class DisplayNothingAboutStock implements DisplayRule {
  shouldApply(stock: Stock): boolean {
    if (stock.display.isEnable) {
      return stock.display.exists
    }

    return stock.web.exists
  }
  apply(_: Stock): string {
    return ''
  }
}
