import {
  DefaultRootPriceRepository,
  RootPriceRepository
} from '@/models/api/paid-member-price/root-price/root-price-repository'
import { RootPrice } from '@/models/api/paid-member-price/root-price/scheme'
import {
  DefaultDisplayedRootPrice,
  PaidMemberPriceNotExistReason,
} from '@/models/item/paid-membership/price/root/root-price'
import { CurrentUser } from '@/services/api/current-user'
import { Either, right } from 'fp-ts/lib/Either'

export interface PaidMembershipPriceLabel {
  regularPriceWithTax: string
  paidMemberPriceWithTax: Either<PaidMemberPriceNotExistReason[], string>
  wholePaidMemberPriceWithTax: Either<PaidMemberPriceNotExistReason[], string>
}

export function emptyPaidMembershipPriceLabel(): PaidMembershipPriceLabel {
  return {
    regularPriceWithTax: '',
    paidMemberPriceWithTax: right(''),
    wholePaidMemberPriceWithTax: right(''),
  }
}

export class PaidMembershipPriceInteraction {
  constructor(
    readonly brandEnglishName: string,
    private readonly rootPriceRepository: RootPriceRepository = new DefaultRootPriceRepository(brandEnglishName),
    private readonly currentUserService: CurrentUser = new CurrentUser()
  ) {}

  async fetchLabel(rootId: string): Promise<Either<Error, PaidMembershipPriceLabel>> {
    const result = await this.rootPriceRepository.getById(rootId)

    return result.map((rootPrice: RootPrice) => {
      const displayed = DefaultDisplayedRootPrice.create(rootPrice)

      return {
        regularPriceWithTax: displayed.regularPriceWithTax,
        paidMemberPriceWithTax: displayed.paidMemberPriceWithTax,
        wholePaidMemberPriceWithTax: displayed.wholePaidMemberPriceWithTax,
      }
    })
  }

  /**
   * ユーザが何らかの有料会員プランに加入しているかを判定する。
   *
   * ROOT価格のAPIは、プロダクトとしての有料会員機能のON/OFF、およびこのユーザに何らかの会員価格が適用されるかを返すが、
   * ユーザが何らかの会員プランに加入済みであるという事実を返すわけではない。
   */
  async isPaidMember(): Promise<Either<Error, boolean>> {
    const result = await this.currentUserService.getCurrentUser()

    return result.map(u => u.membership.enabled)
  }
}
