import { formatPrice } from '@/util/format'
import { Either, left, right } from 'fp-ts/lib/Either'

/**
 * BFF的な想定のもとで、APIスキーマと事実上同じ型となっているが、あくまでドメイン層のモデルとして定義しておく。
 * @see /models/api/paid-member-price/root-price/scheme
 */
export interface RootPrice {
  root_id: string
  regular_price: RegularPrice
  paid_member_price: PaidMembershipConfig
}

export interface RegularPrice {
  with_tax: PriceWithRange
}

export interface PaidMembershipConfig {
  enabled: boolean
  applicable: PaidMembershipPrice
  whole: PaidMembershipPrice
}
export interface PaidMembershipPrice {
  with_tax: PriceWithRange | null
}

export interface PriceWithRange {
  min: number
  max: number
}

export interface DisplayedRootPrice {
  rootId: string
  regularPriceWithTax: string
  paidMemberPriceWithTax: Either<PaidMemberPriceNotExistReason[], string>
}

/**
 * 商品詳細画面などで表示されるROOT全体の価格としてユーザに呈示される文字列表現を生成する。
 */
export class DefaultDisplayedRootPrice implements DisplayedRootPrice {
  static create(rootPrice: RootPrice): DefaultDisplayedRootPrice {
    return new DefaultDisplayedRootPrice(rootPrice)
  }

  /**
   * コンストラクタでは将来的な拡張に備えてデコレータを注入できるようにしているが、
   * 当面デコレータを可変的にする要求は見えていないので、ファクトリメソッドではデコレータを指定できないように塞いでいる。
   */
  private constructor(
    private readonly rootPrice: RootPrice,
    private readonly priceDecorator: (priceWithRange: PriceWithRange) => string = defaultDecorator
  ) {}

  get rootId(): string {
    return this.rootPrice.root_id
  }

  get regularPriceWithTax(): string {
    return this.priceDecorator(this.rootPrice.regular_price.with_tax)
  }

  get paidMemberPriceWithTax(): Either<PaidMemberPriceNotExistReason[], string> {
    return this.stringifyPaidMemberPrice(this.rootPrice.paid_member_price.applicable)
  }
  get wholePaidMemberPriceWithTax(): Either<PaidMemberPriceNotExistReason[], string> {
    return this.stringifyPaidMemberPrice(this.rootPrice.paid_member_price.whole)
  }

  private stringifyPaidMemberPrice(price: PaidMembershipPrice): Either<PaidMemberPriceNotExistReason[], string> {
    const { enabled } = this.rootPrice.paid_member_price
    const withTax = price.with_tax

    if (enabled && withTax) {
      return right(this.priceDecorator(withTax))
    }

    return left([
      ...enabled ? [] : [new PaidMemberPriceNotExistReason(
        'Paid-membership price is not enabled for this environment.'
      )],
      ...withTax ? [] : [new PaidMemberPriceNotExistReason(
        'Paid-membership price is not enabled for this root item.'
      )],
    ])
  }
}

export class PaidMemberPriceNotExistReason {
  constructor(readonly message: string) {}
}

export function defaultDecorator(priceWithRange: PriceWithRange): string {
  if (priceWithRange.min === priceWithRange.max) {
    return formatPrice(priceWithRange.min)
  }

  return `${formatPrice(priceWithRange.min)} 〜 ${formatPrice(priceWithRange.max)}`
}
