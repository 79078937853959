import Badge from '@/components/molecules/item/model/badge'
import { compact } from 'fp-ts/lib/Array'
import { Option } from 'fp-ts/lib/Option'

interface BadgeList {
  preSale: Option<Badge>
  discountSale: Option<Badge>
  new: Option<Badge>
  restock: Option<Badge>
  few: Option<Badge>
  soldout: Option<Badge>
  commingSoon: Option<Badge>
  newItemRequest: Option<Badge>
  restockRequest: Option<Badge>
}

/**
 * NOTE: バッジリストの並び順を仕様として固定する
 * ref: https://my-color.esa.io/posts/346
 */
export const sortBadgeListInOrder = (badges: BadgeList): Badge[] => {
  return compact(
    [
      badges.preSale,
      badges.discountSale,
      badges.new,
      badges.restock,
      badges.few,
      badges.soldout,
      badges.commingSoon,
      badges.newItemRequest,
      badges.restockRequest,
    ]
  )
}
