import FreeContentService from '@/services/api/free-contents'
import FreeAreaService from '@/services/free-area/free-area'

abstract class BrandFreeAreaService extends FreeAreaService {
  protected static readonly CONTENTS_DIRECTORY = [
    'company',
    'free',
    'brandpage',
    'parts',
  ]

  constructor (
    private readonly brandName: string,
    freeContentService: FreeContentService
  ) {
    super(freeContentService)
  }

  createContentsPath(contentsName: string): string {
    return [
      ...BrandFreeAreaService.CONTENTS_DIRECTORY,
      this.brandName,
      contentsName,
    ].join('.')
  }
}

export default BrandFreeAreaService
