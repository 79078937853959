




















import head from 'lodash/head'
import last from 'lodash/last'
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import VueSlider from 'vue-slider-component'
import Config from '../../../modules/config'
import Util from '../../../modules/util'

@Component({
  components: {
    VueSlider,
  },
})
export default class RangeSlider extends Vue {
  @Prop({ required: true })
  min: number

  @Prop({ required: true })
  max: number

  get priceList() {
    return Config.CONDITION_PRICE
  }

  get maxLimit() {
    return Config.CONDITION_PRICE_MAX
  }

  get minLimit() {
    return Config.CONDITION_PRICE_MIN
  }

  get minPriceLabel() {
    return this.min === this.minLimit ? Config.MESSAGE_PRICE_MIN : `¥${Util.numberToMoney(this.min)}`
  }

  get maxPriceLabel() {
    return this.max === this.maxLimit ? Config.MESSAGE_PRICE_MAX : `¥${Util.numberToMoney(this.max)}`
  }

  onChange(value) {
    const event = {
      min: head(value),
      max: last(value),
    }

    this.$emit('change', event)
  }
}
