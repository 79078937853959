import * as CurrentTime from '@/models/current-time/functions'

import * as DateFns from 'date-fns'
import _ from 'lodash'

import ApiBrand from './brand'
import { Price } from './price/price'
import ApiStock from './product/stock'
import { UseIndexSummary, UseStockDetail } from './product/stock-parse-policy'
import Resource from './resource'

const currentTime = CurrentTime.create()

export class ApiProduct extends Resource {
  /**
   * TODO: 商品状態のドメインオブジェクト化の際に削除予定
   */
  static readonly BORDER_FEW_OR_MAY = 10
  static readonly SALES_STATUS_AVAILABLE: number = 4
  static readonly SALES_STATUS_DELETED: number = 6
  // TODO: ApiProductに紐付けるべき情報かというと疑問. 別途整理場所を検討したほうが良い
  static readonly TAG_PATHNAME_FKU: string = 'mycolor.product.fku'
  static readonly TAG_PATHNAME_RESTOCK: string = 'mycolor.product.request.restock'
  static readonly TAG_PATHNAME_NEWITEM: string = 'mycolor.product.request.newitem'

  /*
   * TODO: ブランド単位で可変にする
   * https://github.com/my-color/front/issues/1086 で対応予定.
   * - 個別のROOT/FKU/SKUが BrandBehavior に依存するのはおかしい
   * - 商品が属するブランド単位で表示を切り替えると、複数ブランドの商品を一覧表示した時メチャクチャになる（アカウントサービスとか）
   *
   * といった理由から、恐らくcontainerオブジェクトを用意して、そちらで処理することになる.
   * ViewModel的なレイヤ.
   */
  static readonly BORDER_DAYS_PROCUT_IS_NEW = 30

  get isNew(): boolean {
    if (!this.overRegularSaleStart) {
      return false
    }

    const borderProductIsNew = DateFns.subDays(currentTime.now, ApiProduct.BORDER_DAYS_PROCUT_IS_NEW)

    if (DateFns.isEqual(this.regularSaleStartAt, borderProductIsNew)) {
      return true
    }

    return DateFns.isAfter(this.regularSaleStartAt, borderProductIsNew)
  }

  get isAvailable(): boolean {
    return this.salesStatus === ApiProduct.SALES_STATUS_AVAILABLE
  }
  get isDeleted(): boolean {
    return this.salesStatus === ApiProduct.SALES_STATUS_DELETED
  }

  get salesStatus(): number {
    return this.get('sales_status')
  }

  get maxSellCount(): number {
    return this.get('max_sell_count')
  }

  get productClassId(): string {
    return this.get('product_class_id')
  }

  get brandId(): string {
    return `${this.get('brand_id')}`
  }
  get brands(): ApiBrand[] {
    return this.get('brands', []).map(b => new ApiBrand(b))
  }
  get belongingBrand(): ApiBrand | null {
    return this.brands.find(b => b.idIs(this.brandId)) || null
  }

  get stockDetail(): ApiStock {
    const stockFieldPolicy = this.existStockField ? new UseStockDetail() : new UseIndexSummary()

    return ApiStock.createFromProduct(this, stockFieldPolicy)
  }

  /**
   * @deprecated
   * @see ApiStock.quantity
   */
  get stock(): number {
    if (!this.isAvailable) {
      return 0
    }

    return this.stockDetail.quantity
  }

  get hasStock(): boolean {
    return this.stock > 0
  }
  get hasManyStocks(): boolean {
    return this.stock >= ApiProduct.BORDER_FEW_OR_MAY
  }
  get hasFewStocks(): boolean {
    const stock = this.stock

    return 0 < stock && this.stock < ApiProduct.BORDER_FEW_OR_MAY
  }
  get isSoldOut(): boolean {
    return this.stock <= 0
  }

  get regularSaleStartAt(): string {
    return this.get('start_at')
  }
  get regularSaleEndAt(): string {
    return this.get('end_at')
  }

  get overRegularSaleStart(): boolean {
    const now = currentTime.now

    if (DateFns.isEqual(now, this.regularSaleStartAt)) {
      return true
    }

    return DateFns.isAfter(now, this.regularSaleStartAt)
  }

  get isOnRegularSale(): boolean {
    const now = currentTime.now
    const startAt = this.regularSaleStartAt
    const endAt = this.regularSaleEndAt

    if (DateFns.isBefore(now, startAt)) {
      return false
    }

    return DateFns.isBefore(now, endAt)
  }

  get discountSaleStartAt(): string | null {
    return this.get('on_sale_start_at', null)
  }
  get discountSaleEndAt(): string | null {
    return this.get('on_sale_end_at', null)
  }

  get isOnDiscountSale(): boolean {
    if (!this.isOnRegularSale) {
      return false
    }
    if (!this.isDiscountSaleEnabled) {
      return false
    }
    if (this.discountSaleStartAt === null || this.discountSaleEndAt === null) {
      return false
    }

    const now = currentTime.now
    const startAt = this.discountSaleStartAt
    const endAt = this.discountSaleEndAt

    return DateFns.isWithinRange(now, startAt, endAt)
  }

  get acceptRestockRequest(): boolean {
    return this.hasTag(ApiProduct.TAG_PATHNAME_RESTOCK)
  }
  get acceptNewItemRequest(): boolean {
    return this.hasTag(ApiProduct.TAG_PATHNAME_NEWITEM)
  }

  /**
   * https://docs.google.com/spreadsheets/d/1ZvWEL9Q9FGgB24JKAjD8roi75KEHoT6uv7xMPhITDZI/edit#gid=2052072130
   */
  get isOnNewItemRequest(): boolean {
    return !this.overRegularSaleStart && this.acceptNewItemRequest
  }
  /**
   * https://docs.google.com/spreadsheets/d/1ZvWEL9Q9FGgB24JKAjD8roi75KEHoT6uv7xMPhITDZI/edit#gid=2052072130
   */
  get isOnRestockRequest(): boolean {
    if (this.isDeleted) {
      return this.isOnRegularSale && this.acceptRestockRequest
    }

    return (
      this.isSoldOut &&
      this.isOnRegularSale &&
      this.acceptRestockRequest
    )
  }

  getSize(): string | null {
    const tagNameSpace = ['company', 'product', 'size']

    const tag = this.findTag(this.tags, tagNameSpace)

    if (!tag) {
      return null
    }

    return tag.title
  }

  get isPreSale(): boolean {
    if (!this.hasStock) {
      return false
    }
    if (this.overRegularSaleStart) {
      return false
    }

    return this.tags.some(t => t.pathname === 'mycolor.product.preorder')
  }

  get hasPreSaleTag(): boolean {
    return this.tags.some(t => t.pathname === 'mycolor.product.preorder')
  }

  /**
   * 予約商品の売り切れ状態:
   * https://my-color.esa.io/posts/341#%E4%BA%88%E7%B4%84%E5%95%86%E5%93%81%E3%81%AE%E7%8A%B6%E6%85%8B%E5%8C%BA%E5%88%86
   * TODO: バッジ表示リファクタリング時に削除する
   */
  get isNotAvailablePreSale(): boolean {
    return this.tags.some(t => t.pathname === 'mycolor.product.preorder') &&
    !this.overRegularSaleStart &&
    !(this.hasStock && this.isAvailable)
  }

  get price(): Price {
    return new Price(
      this.get('sell_price'),
      this.isOnDiscountSale ? this.get('sale_price') : undefined
    )
  }

  private get isDiscountSaleEnabled(): boolean {
    return this.get('on_sale') === 'TRUE'
  }

  private get existStockField(): boolean {
    return this.get('stock', null) !== null
  }
}
