import { sendLog as sendPcpAnalyticsLog } from '@/vendor/pcp-analytics'
import AddToCartButtonInListAdapterForTemplate from '@ajax/vue/components/organisms/addtocart/in-item-list/adapter/AddToCartButtnInListAdapterForTemplate.vue'
import Router, { Route, RouteConfig } from 'vue-router'

import ImageWithBadgeBand from '@/components/atoms/image/ImageWithBadgeBand.vue'
import ImageWithBorder from '@/components/atoms/image/ImageWithBorder.vue'
import Video from '@/components/atoms/video/Video.vue'
import VideoThumbnail from '@/components/atoms/video/VideoThumbnail.vue'
import ItemRating from '@/components/molecules/item/ItemRating.vue'
import Share from '@/components/organisms/Share.vue'
import { InstanceResolver } from '@/util/instanceResolver'

import BundlePurchaseContainer from '@/components/molecules/bundle-purchase/BundlePurchaseContainer.vue'
import BundleDiscountLabelWithStore from '@/components/molecules/discount/BundleDiscountLabelWithStore.vue'
import CatalogButton from '@/components/organisms/catalog/CatalogDemand.vue'
import RecentlyViewed from '@/components/organisms/item-list/recently-viewed/ItemDetailRecentlyViewed.vue'
import PaidMembershipPrice from '@/components/organisms/item/price/paid-membership-price/PaidMembershipPrice.vue'
import SearchContactLens2 from '@/components/organisms/searchContactLens/SearchContactLens2.vue'
import SearchContactLens4 from '@/components/organisms/searchContactLens/SearchContactLens4.vue'
import SearchContactLens5 from '@/components/organisms/searchContactLens/SearchContactLens5.vue'
import SearchContactLensParent from '@/components/organisms/searchContactLens/SearchContactLensParent.vue'
import UserReviewArea from '@/components/organisms/user-review/UserReviewArea.vue'
import { log } from '@/log'
import { BrandRouteResolver } from '@/util/brandRouteResolver'
import Countup from '@ajax/vue/components/atoms/Countup.vue'
import AddToCartButton from '@ajax/vue/components/organisms/addtocart/AddToCartButton.vue'
import AddToCartItemList from '@ajax/vue/components/organisms/addtocart/AddToCartItemList.vue'
import ContactLensCartIn from '@ajax/vue/components/organisms/contact-lens/CartIn.vue'
import ContactLensColorDropdown from '@ajax/vue/components/organisms/contact-lens/ColorDropdown.vue'
import ContactLensColorVariation from '@ajax/vue/components/organisms/contact-lens/ColorVariation.vue'
import ContactLensPowerLeftDropdown from '@ajax/vue/components/organisms/contact-lens/power/LeftDropdown.vue'
import ContactLensPowerRightDropdown from '@ajax/vue/components/organisms/contact-lens/power/RightDropdown.vue'
import ContactLensQuantityDropdown from '@ajax/vue/components/organisms/contact-lens/Quantity.vue'
import ErrorModalContainer from '@ajax/vue/components/organisms/ErrorModalContainer.vue'
import CategoryMenu from '@ajax/vue/components/templates/CategoryMenu.vue'
import ColorStock from '@ajax/vue/components/templates/ColorStock.vue'
import Condition from '@ajax/vue/components/templates/Condition.vue'
import ExternalPaymentCallback from '@ajax/vue/components/templates/ExternalPaymentCallback.vue'
import ExternalPaymentCancel from '@ajax/vue/components/templates/ExternalPaymentCancel.vue'
import Favorite from '@ajax/vue/components/templates/Favorite.vue'
import GenreMenu from '@ajax/vue/components/templates/GenreMenu.vue'
import Itemlist from '@ajax/vue/components/templates/Itemlist.vue'
import Refine from '@ajax/vue/components/templates/Refine.vue'
import Search from '@ajax/vue/components/templates/Search.vue'
import SearchArea from '@ajax/vue/components/templates/SearchArea.vue'
import SearchKeyword from '@ajax/vue/components/templates/SearchKeyword.vue'
import Sort from '@ajax/vue/components/templates/Sort.vue'

const topComponents = {
  category: CategoryMenu,
}
const sideBarComponents = {
  category: CategoryMenu,
  genre: GenreMenu,
  searchArea: SearchArea,
}

const contactLensComponents = {
  'contact-lens-cart-in': ContactLensCartIn,
  'contact-lens-color-dropdown': ContactLensColorDropdown,
  'contact-lens-color-variation': ContactLensColorVariation,
  'contact-lens-power-left-dropdown': ContactLensPowerLeftDropdown,
  'contact-lens-power-right-dropdown': ContactLensPowerRightDropdown,
  'contact-lens-quantity-dropdown': ContactLensQuantityDropdown,
}

const itemDetailComponents = {
  favorite: Favorite,
  stock: ColorStock,
  share: Share,
  recently: RecentlyViewed,
  'add-to-cart-item-list': AddToCartItemList,
  'add-to-cart-button': AddToCartButton,
  image: ImageWithBadgeBand,
  'sub-image': ImageWithBorder,
  video: Video,
  'video-thumbnail': VideoThumbnail,
  'error-modal-container': ErrorModalContainer,
  'user-review-area': UserReviewArea,
  'paid-membership-price': PaidMembershipPrice,
  'item-rating': ItemRating,
  ...sideBarComponents,
  ...contactLensComponents,
}

const itemlistComponents = {
  itemlist: Itemlist,
  condition: Condition,
  sort: Sort,
  countup: Countup,
  refine: Refine,
  image: ImageWithBadgeBand,
  'bundle-discount-label-with-store': BundleDiscountLabelWithStore,
  'item-rating': ItemRating,
  'add-to-cart-button-in-list': AddToCartButtonInListAdapterForTemplate,
  ...sideBarComponents,
}

const itemlistFilterComponents = {
  condition: Condition,
  category: CategoryMenu,
  image: ImageWithBadgeBand,
}

const itemlistSearchComponents = {
  countup: Countup,
  itemlist: Itemlist,
  condition: Condition,
  image: ImageWithBadgeBand,
  sort: Sort,
  'bundle-discount-label-with-store': BundleDiscountLabelWithStore,
  'item-rating': ItemRating,
  'add-to-cart-button-in-list': AddToCartButtonInListAdapterForTemplate,
  ...sideBarComponents,
}

const itemlistRecommendedComponents = {
  image: ImageWithBadgeBand,
  ...sideBarComponents,
}

const bundlePurchaseComponents = {
  image: ImageWithBadgeBand,
  'bundle-purchase-container': BundlePurchaseContainer,
  ...sideBarComponents,
}

const magazineComponents = {
  image: ImageWithBadgeBand,
  ...sideBarComponents,
}

const featureComponents = {
  image: ImageWithBadgeBand,
  ...sideBarComponents,
}

const brandGroupArticleDetailComponents = {
  share: Share,
  image: ImageWithBadgeBand,
  'add-to-cart-button': AddToCartButton,
}

const brandArticleDetailComponents = {
  share: Share,
  image: ImageWithBadgeBand,
  'add-to-cart-button': AddToCartButton,
  ...sideBarComponents,
}

const brandItemlistSearchComponents = {
  searchkeyword: SearchKeyword,
  search: Search,
  'bundle-discount-label-with-store': BundleDiscountLabelWithStore,
}

const freeContentPageComponents = {
  condition: Condition,
  sort: Sort,
  refine: Refine,
  itemlist: Itemlist,
  image: ImageWithBadgeBand,
  'bundle-discount-label-with-store': BundleDiscountLabelWithStore,
  'catalog-demand': CatalogButton,
  'item-rating': ItemRating,
  'add-to-cart-button-in-list': AddToCartButtonInListAdapterForTemplate,
  ...sideBarComponents,
}

const searchContactLensComponents = {
  'search-contact-lens': SearchContactLensParent,
}

const searchContactLensChildren = [
  { path: '2', name: '2', component: SearchContactLens2 },
  { path: '4', name: '4', component: SearchContactLens4 },
  { path: '5', name: '5', component: SearchContactLens5 },
]

const commonRoutes: RouteConfig[] = [
  { path: '/magazine', components: magazineComponents },
  { path: '/feature', components: featureComponents },
  {
    path: '/external_payment/*',
    components: {
      'external-payment-callback': ExternalPaymentCallback,
      'external-payment-cancel': ExternalPaymentCancel,
    },
  },
]

const multiInstanceRoutes: RouteConfig[] = [
  { path: '/', components: topComponents },
  { path: '/article/:shortid', components: brandGroupArticleDetailComponents },
  {
    /**
     * TODO
     * MyColor的な別インスタンスが登場したら、"mycolor"部分切り替え可能にする必要ありそう
     */
    path: '/mycolor/:page_kind',
    components: freeContentPageComponents,
  },
  {
    path: '/:brand_english_name/item/:shortid',
    components: itemDetailComponents,
    props: {
      recently: (route: Route) => ({
        brand: route.params.brand_english_name,
      }),
      'user-review-area': (route: Route) => ({
        brand: route.params.brand_english_name,
      }),
    },
  },
  { path: '/:brand_english_name/search', components: brandItemlistSearchComponents },
  { path: '/:brand_english_name/article/:id', components: brandArticleDetailComponents },
  { path: '/:brand_english_name/itemlist', components: itemlistComponents },
  { path: '/:brand_english_name/itemlist/filter', components: itemlistFilterComponents },
  { path: '/:brand_english_name/itemlist/search', components: itemlistSearchComponents },
  {
    path: '/:brand_english_name/bundle/:campaign_id',
    components: bundlePurchaseComponents,
    props: {
      'bundle-purchase-container': (route: Route) => ({
        brand: route.params.brand_english_name,
      }),
    },
  },
  {
    // Hotellovers限定なので, :brand_english_nameの部分はhlで決め打ちに.
    // path: '/:brand_english_name/search-contact-lens',
    path: '/hl/search-contact-lens',
    components: searchContactLensComponents,
    children: searchContactLensChildren,
  },
  // URLのマッチング範囲が非常に広いので、常に末尾にすること
  {
    path: '/:brand_english_name/:page_kind',
    components: freeContentPageComponents,
    props: {
      'catalog-demand': (route: Route) => ({
        brand: route.params.brand_english_name,
      }),
    },
  },
  // ブランドTOP
  { path: '/:brand_english_name', components: freeContentPageComponents },
]

const singleInstanceRoutes: RouteConfig[] = [
  { path: '/article/:shortid', components: brandArticleDetailComponents },
  { path: '/itemlist', components: itemlistComponents },
  { path: '/itemlist/filter', components: itemlistFilterComponents },
  { path: '/itemlist/search', components: itemlistSearchComponents },
  { path: '/itemlist/recommended', components: itemlistRecommendedComponents },
  {
    path: '/bundle/:campaign_id', components: bundlePurchaseComponents,
  },
  { path: '/', components: freeContentPageComponents },
  {
    path: '/item/:shortid',
    components: itemDetailComponents,
    props: {
      recently: () => ({
        brand: InstanceResolver.getSingleBrand(),
      }),
      'user-review-area': () => ({
        brand: InstanceResolver.getSingleBrand(),
      }),
      'contact-lens-color-dropdown': (route: Route) => ({
        initialSelectedColorName: route.query.color,
      }),
    },
  },
  {
    path: '/search-contact-lens',
    components: searchContactLensComponents,
    children: searchContactLensChildren,
  },
  { path: '*', components: freeContentPageComponents },
]

function makeRoutes(): RouteConfig[] {
  if (InstanceResolver.isSingle()) {
    return [
      ...commonRoutes,
      ...singleInstanceRoutes,
    ]
  }

  return [
    ...commonRoutes,
    ...multiInstanceRoutes,
  ]
}

const router = new Router({
  mode: 'history',
  routes: makeRoutes(),
})

router.beforeEach(async (to, _from, next) => {
  try {
    const brandEnglishName = BrandRouteResolver.resolveBrandFromPathElement(to.params.brand_english_name) || null
    await sendPcpAnalyticsLog(brandEnglishName)
  } catch (err) {
    log.error(err)
  } finally {
    next()
  }
})

export default router
