import AbstractValue from '@/models/app-config/behavior/abstract-value'

export interface RawFooterLink {
  url: string
  label: string
  target?: string
  only?: 'pc' | 'sp' | false
}

export default class FooterLink extends AbstractValue<RawFooterLink> {
  get url(): string {
    return this.raw.url
  }
  get label(): string {
    return this.raw.label
  }
  get target(): string | null {
    return this.raw.target || null
  }
  get isOnlyPc(): boolean {
    return this.isOnly('pc')
  }
  get isOnlySp(): boolean {
    return this.isOnly('sp')
  }

  private isOnly(type: 'sp' | 'pc'): boolean {
    return this.raw.only === type
  }
}
