import { BaseError } from '@/util/base-error'

export abstract class CreditcardRegisterError extends BaseError {
  /**
   * デフォルトのメッセージ.
   * エラーごとに異なるメッセージを提示したい場合は、それぞれでオーバーライドすること.
   */
  get label(): string {
    return [
      'クレジットカードの登録中にエラーが発生しました。時間をおいて、再度お試しください。',
      '※改善されない場合、お問い合わせよりカスタマーセンターまでお問い合わせください。',
    ].join('<br/>')
  }
}

/**
 * クレジットカード登録スクリプトの取得失敗
 */
export class LoadingCreditcardSciptError extends CreditcardRegisterError {}
/**
 * クレジットカード認証の失敗
 */
export class CreditcardAuthenticationError extends CreditcardRegisterError {
  get label(): string {
    return 'クレジットカード情報を認証できませんでした。<br/>入力内容に誤りがないかご確認お願いします。'
  }
}
/**
 * 不正なクレジットカード番号による登録エラー
 */
export class InvalidCreditcardNumberError extends CreditcardRegisterError {
  get label(): string {
    return '無効なカード番号です。お手元のカード番号をご確認の上、正しい番号を入力してください。'
  }
}
/**
 * 不明なクレジットカード登録エラー
 */
export class UnknownCreditcardRegisterError extends CreditcardRegisterError {
  get label(): string {
    return 'クレジットカードを登録できませんでした。<br/>入力内容に誤りがないかご確認お願いします。'
  }
}

export function stringifyErrorForEndUser(e: CreditcardRegisterError | Error): string {
  return e instanceof CreditcardRegisterError
    ? e.label
    : 'エラーが発生しました。<br/>※改善されない場合、お問い合わせよりカスタマーセンターまでお問い合わせください。'
}
