import { Either, Left, Right } from 'fp-ts/lib/Either'
import _ from 'lodash'

import { log } from '@/log'
import { UserReview } from '@/models/user-review/index'
import { ApiClient } from '@/services/api/client'
import { ApiService } from '@/services/api/service'
import AppId from '@/util/appid'

const namespace: string = 'userReview'

interface UserReviewListQuery {
  limit?: number
  offset?: number
}

export class UserReviewService extends ApiService {
  static createForBrand(brand: string) {
    return new UserReviewService(
      AppId.getByBrandName(brand),
      brand
    )
  }

  private constructor(appId: string, private readonly brand: string) {
    super(appId)
  }

  async countRelatedToRoot(rootId): Promise<Either<Error, number>> {
    try {
      const response = await ApiClient
        .get(`/api/${this.brand}/item/root/${rootId}/user-review/count`)

      log.debug({ service: `${namespace}/countRelatedToRoot`, response })

      return new Right(response.body.count)
    } catch (error) {
      log.error({ error })

      return new Left(error)
    }
  }

  async listRelatedToRoot(rootId: string, query: UserReviewListQuery = {
    limit: 10,
    offset: 0,
  }): Promise<Either<Error, UserReview[]>> {
    try {
      const response = await ApiClient
        .get(`/api/${this.brand}/item/root/${rootId}/user-review`)
        .query(query)

      log.debug({ service: `${namespace}/listRelatedToRoot`, response })

      return new Right(
        (response.body as UserReview[])
      )
    } catch (error) {
      log.error({ error })

      return new Left(error)
    }
  }

  async post(review: UserReview, rootId: string): Promise<Either<Error, Response>> {
    try {
      // JSONのままだとbooleanと文字列'true'/'false'の区別がつかなくなるので
      // いったんstringifyして、PHP側でjson_decodeしている
      const response = await ApiClient
        .post(`/api/${this.brand}/item/root/${rootId}/user-review`)
        .send({ review: JSON.stringify(review) })

      log.debug({ service: `${namespace}/postRelatedToRoot`, response })

      return new Right(response.body)
    } catch (error) {
      log.error({ error })

      return new Left(error)
    }
  }
}
