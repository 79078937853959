






import { ApiProductclass } from '@/models/api/productclass'
import AddToCartButtonInList from '@ajax/vue/components/organisms/addtocart/in-item-list/AddToCartButtonInList.vue'
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
    AddToCartButtonInList,
  },
})
/**
 * SSRで利用するテンプレートから、利用するためのアダプタ.
 *
 * 必要なデータをjsonで直接受け取って、内部で適宜変換してから基盤となるvueコンポーネントへ渡す.
 */
export default class AddToCartButtonInListAdapterForTemplate extends Vue {
  @Prop({ required: true })
  readonly fku: object

  get fkuObject(): ApiProductclass {
    return new ApiProductclass(this.fku)
  }
}
