























































import { CartScope } from '@/models/cart/group/scope'
import _ from 'lodash'
import { Mixins } from 'vue-mixin-decorator'
import { Component, Prop } from 'vue-property-decorator'

import AjaxInput from '@/components/atoms/Input.vue'
import PageTransitionLink from '@/components/atoms/PageTransitionLink.vue'
import SearchKeyword from '@/components/organisms/search/SearchKeyword.vue'
import SlideMenu from '@/components/organisms/slide-menu/SlideMenu.vue'
import { handleFaildToEnterShopError } from '@/entry-limit/error-handling'
import { log } from '@/log'
import ApiBrand from '@/models/api/brand'
import BehaviorConfig from '@/models/app-config/behavior/behavior'
import { getInstanceContext } from '@/page/context/instance'
import { hostPageContext } from '@/page/context/page'
import { ContextToCreateUrl } from '@/page/url/factory/url-factory'
import { BrandLogo, BrandService } from '@/services/api/brand'
import EntryService from '@/services/api/entry'
import AppId from '@/util/appid'
import { BrandRouteResolver } from '@/util/brandRouteResolver'
import { InstanceResolver } from '@/util/instanceResolver'
import {
  initializeSlideMenuFreeArea,
  initialSlideMenuState,
  setPosition,
  SlideMenuState,
  toggleSlideMenu,
} from '@spa/components/organisms/models/ec-header/slide-menu-state'
import { PluginMixin } from '@spa/mixins/plugin'
import { InfoService } from '@spa/services/api/info'
import { CookieService } from '@spa/services/cookie'
import { CartItem } from '@spa/store/modules/cart/types'

const spaContext = {
  instance: getInstanceContext(),
  page: hostPageContext,
}

@Component({
  components: {
    PageTransitionLink,
    AjaxInput,
    SearchKeyword,
    SlideMenu,
  },
})
export default class EcHeader extends Mixins<PluginMixin>(PluginMixin) {
  $refs: {
    ecHeader: HTMLElement,
  }

  @Prop({ default: false })
  readonly isMediaPage: boolean

  @Prop({ default: () => spaContext })
  readonly context: ContextToCreateUrl

  versioning: string | null = null

  shouldDisplayVersioning: boolean = false

  brandService = new BrandService()

  // brand情報として、特に「header用の」情報としてまとめたい
  brand: ApiBrand | null = null
  logo: BrandLogo | null = null
  cartScope: CartScope | null = null

  slideMenuState: SlideMenuState = initialSlideMenuState

  loading: boolean = true

  async created() {
    const entryService = new EntryService(AppId.getByBrandName(this.maybeBrandName))
    const resultTryEntry = await entryService.try()
    resultTryEntry.mapLeft((error: any) => {
      handleFaildToEnterShopError(error.response)
    })

    const requests: Array<Promise<any>> = [
      InfoService.versioning().then(result => result.map((version) => {
        this.versioning = version.fullText
        this.shouldDisplayVersioning = version.display
      })),
      this.brandService.findByEnglishName(this.maybeBrandName).then(result => result.map((maybeBrand) => {
        this.brand = maybeBrand.toNullable()
      })),
      this.brandService.findLogoImage(this.maybeBrandName).then(result => result.fold(
              error => log.error(error),
              (brandLogo) => {
                this.logo = brandLogo
              }
      )),
      this.brandService.findCartScope(this.maybeBrandName).then(result => result.map((scope) => {
        this.cartScope = scope

        return CookieService.isGuest()
          ? Promise.resolve()
          : this.$store.dispatch('cart/loadWithScope', { scope })
      })),
    ]

    await Promise.all(requests)

    this.loading = false

    this.slideMenuState = await initializeSlideMenuFreeArea(
      this.slideMenuState,
      this.isBrandPage,
      this.brandName
    )
  }

  get showTransitionLinks(): boolean {
    return InstanceResolver.isSingle() || !this.isMediaPage
  }

  get isBrandPage(): boolean {
    return this.brand !== null
  }

  /**
   * デフォルトでは class="header__my-color-logo" に指定されたbackground-imageを使用.
   *
   * ブランド配下の場合、ブランドに画像が登録されていればその画像を使用する.
   * 画像未登録の場合は、↑のデフォルト値が使用される.
   */
  get logoStyle(): object {
    if (this.loading) {
      return {
        'background-image': 'none',
      }
    }

    if (!this.maybeBrandName) {
      return {}
    }

    if (this.logo) {
      return {
        'background-image': `url(${this.logo.url})`,
      }
    }

    return {}
  }

  get cartItemTotalCountUnderBrand(): number {
    if (!this.brand) {
      return 0
    }

    const items: CartItem[] = this.$store.getters['cart/displayItems']

    return _.sumBy(items, item => _.toNumber(item.unitCount))
  }

  get isLogined() {
    return CookieService.isLogined()
  }

  get topUrl(): string {
    return InstanceResolver.isMulti() && this.isBrandPage ? `/${this.brandName}` : '/'
  }

  get brandStyleClass() {
    return this.isBrandPage ? `header__brand-${this.brandName}` : 'header__no-brand'
  }

  get maybeBrandName(): string {
    const brandName = BrandRouteResolver.resolveBrandFromPathElement(this.$route.params.brand_english_name)

    if (brandName) {
      return brandName
    }

    // 引数が見つからなかった場合、先頭をブランド名候補として扱う.
    return this.$route.path.split('/')[1]
  }

  get brandName(): string {
    if (this.isBrandPage) {
      return this.maybeBrandName
    }

    return ''
  }

  get cartLink(): string {
    if (InstanceResolver.isSingle()) {
      return '/cart'
    }

    if (!this.isBrandPage || !this.cartScope) {
      return '/carts'
    }

    return `/${this.cartScope.kind.toString()}/${this.cartScope.value.toString()}/cart`
  }

  get showSearchKeyword(): boolean {
    return this.isBrandPage && !this.loading
  }

  toggleSlideMenu () {
    this.slideMenuState = setPosition(this.slideMenuState, {
      top: this.$refs.ecHeader.getBoundingClientRect().bottom,
    })
    this.slideMenuState = toggleSlideMenu(this.slideMenuState)
  }

  get hamburgerMenuClass(): string {
    return this.slideMenuState.visibility ?
      'header__toggle-hamburger-menu--close' :
      'header__toggle-hamburger-menu'
  }

  get behaviorConfig(): BehaviorConfig {
    return BehaviorConfig.createFromBrand(this.brandName)
  }
}
