import { ApiCreditcard } from '@/models/api/creditcard'
import { CreditcardService, CreditcardToBuildPaymentPayload } from '@/services/api/creditcard'
import { parametersWhichAreRequiredByEngineForSubscription } from '@/services/api/subscription/payment/parameters-which-are-required-by-engine-for-subscription'
import { Either } from 'fp-ts/lib/Either'

export class CreditcardServiceForSubscription extends CreditcardService {
  /**
   * 型がコンパイラで厳密に区別されるようにするための識別子
   */
  readonly _tag: 'for-subscription' = 'for-subscription'

  /**
   * @override 内部的にサブスクリプション用のパラメータを付与する
   * @see https://my-color.esa.io/posts/707
   * @see https://github.com/my-color/front/issues/5636
   */
  async get(id: string, query: object = {}): Promise<Either<Error, ApiCreditcard>> {
    return super.get(id, {
      ...parametersWhichAreRequiredByEngineForSubscription,
      ...query,
    })
  }

  /**
   * @override 内部的にサブスクリプション用のパラメータを付与する
   * @see https://my-color.esa.io/posts/707
   * @see https://github.com/my-color/front/issues/5636
   */
  async list(query: object = {}): Promise<Either<Error, ApiCreditcard[]>> {
    return super.list({
      ...parametersWhichAreRequiredByEngineForSubscription,
      ...query,
    })
  }

  /**
   * @override 内部的にサブスクリプション用のパラメータを付与する
   * @see https://my-color.esa.io/posts/707
   * @see https://github.com/my-color/front/issues/5636
   */
  buildPaymentPayload(creditcard: CreditcardToBuildPaymentPayload) {
    return {
      ...parametersWhichAreRequiredByEngineForSubscription,
      ...super.buildPaymentPayload(creditcard),
    }
  }

  /**
   * @inheritDoc
   * @override 内部的にサブスクリプション用のパラメータを付与する
   * @see https://my-color.esa.io/posts/707
   * @see https://github.com/my-color/front/issues/5636
   */
  protected buildPaymentPayloadToCreate(token: string): object {
    return {
      ...parametersWhichAreRequiredByEngineForSubscription,
      ...super.buildPaymentPayloadToCreate(token),
    }
  }
}
