
















































































import _ from 'lodash'
import Vue from 'vue'
import { Component, Emit, Prop } from 'vue-property-decorator'

import ToSubscribePaidMemberButton from '@/components/molecules/paid-membership/ToSubscribePaidMemberButton.vue'
import { ApiProduct } from '@/models/api/product'
import { ApiProductclass } from '@/models/api/productclass'
import BehaviorConfig from '@/models/app-config/behavior/behavior'
import CurrentTime from '@/models/current-time/CurrentTime'
import { create as createCurrentTime } from '@/models/current-time/functions'
import SKU, { SkuPrice, SkuWithPrice } from '@/models/item/sku'
import CanRequestRestockSpec from '@/models/item/sku/sale-status/specification/each/can-request-restock-spec'
import GetStockStatus from '@/usecase/get-stock-status'
import { DateFormat } from '@/util/format'
import Util, { CartInfo } from '@ajax/modules/util'
import { AddToCartEvent } from '@ajax/vue/components/organisms/addtocart/view-models/event'
import {
  AddToCartEvent as AddToCartEventFromEachSku
} from '@ajax/vue/components/templates/model/cart/add-to-cart-event'
import { CartItem } from '@spa/store/modules/cart/types'

@Component({
  components: {
    ToSubscribePaidMemberButton,
  },
})
export default class AddToCartListItem extends Vue {
  @Prop({ required: true })
  cartInfo: CartInfo

  @Prop({ required: true })
  part: {
    id: string,
    sale_price: any,
    sell_price: number,
    tags: any[],
    start_at: Date,
  }

  @Prop({ required: true })
  productclass: {
    id: string;
    brand_id: string;
    tags: any[];
    image_url: string;
    images: any[];
    parts: any[];
    publish_at: Date;
    belonging_to: any[];
    product_name: string;
  }

  @Prop({ required: true })
  skuWithPrice: SkuWithPrice

  @Prop({ default: () => createCurrentTime() })
  current: CurrentTime

  /**
   * このコンポーネントにはカート追加ボタンが（下位コンポーネントとして）含まれるが、
   * それをdisabledにするためのフラグ。
   */
  @Prop({ default: true })
  disabled: boolean = false

  /**
   * すでに同一SKUがカートに追加されている場合のみ
   * また、有料会員機能がONの場合のみ親コンポーネントから渡されることに注意
   * @see /client/ajax/src/vue/components/organisms/addtocart/AddToCartItemList.vue
   */
  @Prop({ default: [] })
  cartItems: CartItem[]

  @Prop({ required: true })
  isPaidMember: boolean

  /**
   * FIXME: 実装を統合. コンポーネントではなく、演算対象のオブジェクト自身に持たせたい
   * @see AddItemToCartViaExternalSystem.price
   *
   * 有料会員・非会員に関わらず、SKUに対して適切な価格として透過的に渡されたものを使用する。
   */
  get price(): SkuPrice {
    return this.skuWithPrice.price
  }

  get sku(): SKU {
    return this.skuWithPrice.sku
  }

  get size() {
    const size = _.find(this.part.tags, (tag) => {
      return /^company\.product\.size\./.test(tag.pathname)
    })

    return size.label
  }

  get getStockStatus(): GetStockStatus {
    return GetStockStatus.createViaConfig(this.behavior)
  }

  get behavior(): BehaviorConfig {
    return BehaviorConfig.createFromBrand(this.brandName)
  }

  get paidMembershipFeatureEnabled(): boolean {
    return this.behavior.paidMembership.enabled
  }

  get brandId() {
    return this.productclass.brand_id
  }

  get deliveryStartAt(): string {
    return this.cartInfo.presales && this.part.start_at
      ? DateFormat.partOfMonth(this.part.start_at)
      : ''
  }

  private get apiProductclass(): ApiProductclass {
    return new ApiProductclass(this.productclass)
  }

  private get brandName(): string {
    return this.apiProductclass.belongingBrand.englishName
  }

  formattedPrice() {
    return Util.formatJPYPrice(this.price.prices.selected.price)
  }

  productIsOnRestockRequest(rawProduct: any): boolean {
    const spec = new CanRequestRestockSpec(this.behavior)

    return spec.verify(new ApiProduct(rawProduct))
  }

  skuIsWithinSalePeriod(): boolean {
    return this.sku.salePeriod.include(this.current.now)
  }

  @Emit('start')
  publishStartEvent(payload: AddToCartEventFromEachSku): AddToCartEvent {
    return payload
  }

  @Emit('stop')
  publishStopEvent(payload: AddToCartEventFromEachSku): AddToCartEvent {
    return payload
  }
}
