import { log } from '@/log'
import { CartItem } from '@/models/cart/cart-item'
import { CartGroup, isDisplayItem } from '@/models/cart/group/group'
import _ from 'lodash'
import { GetterTree } from 'vuex'

import { RootState } from '../../types'
import { filterValidItem } from './function'
import { CartState } from './types'

type ItemFilter = (item: CartItem) => boolean

/**
 * FIXME:
 * https://github.com/my-color/front/pull/465#issuecomment-460920398
 * https://github.com/my-color/front/pull/465#issuecomment-460921912
 * https://github.com/my-color/front/pull/465#issuecomment-460932320
 *
 * ブランドごとの商品絞り込みに対応するためにxxxWithFilterというgetterを定義しているが、
 * 外部のコンテキストに従ってデータを取得するという時点で、storeのgetterとして定義するのは不適切かもしれない.
 *
 * p2.2.0では、取り急ぎ動くところまで持っていくことを優先して既存構造に乗っかるような形にしてしまっているが、
 * 抜本的にはstoreの構造自体を現状に合わせて回収する必要が有ると思われる.
 */

export const getters: GetterTree<CartState, RootState> = {
  hasError(state): boolean {
    return _.has(state, 'error.error')
  },

  getError(state): Error {
    return _.get(state, 'error.error')
  },

  getItem(_state, getter): (string) => CartItem | undefined {
    return (id: string) => {
      return _.find(getter.currentItems, ['id', id])
    }
  },

  currentItems(state: CartState): CartItem[] {
    const currentScope = state.currentScope

    if (currentScope === null) {
      log.info(`current scope is not assigned, so return all cart items as currentItems`)

      return state.groups.items().list
    }

    return state.groups.findByScope(currentScope).foldL(
      (): CartItem[] => {
        log.info(`not found group for scope(${currentScope.kind.toString()}.${currentScope.value.toString()})`)

        return []
      },
      (group: CartGroup) => group.items.list
    )
  },
  /**
   * 有効(ステータスや在庫数)なアイテムを返す。
   * 修正前まではchildを含まないものだったが、いまはchildを含むものになっている。
   * childを含まないものは、displayItemsを使うか、自前でフィルタすること。
   */
  items(_state, getter): CartItem[] {
    return getter.currentItems.filter(item => filterValidItem(item, getter.currentItems))
  },

  /**
   * 有効(ステータスや在庫数)なアイテムを返す。
   * 修正前まではchildを含まないものだったが、いまはchildを含むものになっている。
   * childを含まないものは、displayItemsを使うか、自前でフィルタすること。
   *
   * TODO: 絞り込みを一元化
   * this.$store.getters['cart/itemsWithFilter'](this.isPurchasableCartItem) といった絞り込みが
   * 各種 page コンポーネントで都度再実装されてしまっている.
   * 結果、知識の分散化と「同じカート内商品情報に基づいて表示している」ということが担保できなくなっているので、
   * 何らかの形で一元化したい.
   *
   * 候補としては
   * - order-context#filter に取り込む
   * - store の getter に取り込む
   *   - 併せて、重複している getter の削除や統合などが必要になりそう
   * などが考えられそうか.
   */
  itemsWithFilter(_state, getter): (filter: ItemFilter) => CartItem[] {
    return (filter: (item: CartItem) => boolean) =>
      getter.currentItems.filter(item => filterValidItem(item, getter.currentItems)).filter(filter)
  },

  displayItems(_state, getter): CartItem[] {
    return getter.currentItems
      .filter(item => filterValidItem(item, getter.currentItems))
      .filter(item => isDisplayItem(item))
  },

  displayItemsWithFilter(_state, getter): (filter: ItemFilter) => CartItem[] {
    return (filter: ItemFilter) =>
      getter.currentItems
        .filter(item => filterValidItem(item, getter.currentItems))
        .filter(item => isDisplayItem(item)).filter(filter)
  },

  noveltyItems(_state, getter): CartItem[] {
    return getter.currentItems.filter(item => item.isNovelty)
  },

  noveltyItemsWithFilter(_state, getter): (filter: ItemFilter) => CartItem[] {
    return (filter: ItemFilter) => getter.currentItems.filter(item => item.isNovelty).filter(filter)
  },

  isFreeShippingOnCatalogOnly(_state, getter): boolean {
    return _.every(getter.currentItems, 'isCatalogType')
  },
  // :pensive:
  isFreeShippingOnCatalogOnlyWithFilter(_state, getter): (filter: ItemFilter) => boolean {
    return (filter: ItemFilter) => _.every(
      (getter.currentItems.filter(filter)), 'isCatalogType'
    )
  },

  /**
   * Check the number both of parent and child root are the same.
   * This is a simple workaround for incomplete hidden set data.
   * TODO: When a more robust way is implemented, this will be done away with.
   */
  isHiddenSetParityIsValid(_state, getter): boolean {
    const numOfParent = _.countBy(getter.currentItems, item => item.isParent)
    const numOfChild = _.countBy(getter.currentItems, item => item.isChild)

    return numOfParent === numOfChild
  },
}
