import { ApiProductclass } from '@/models/api/productclass'
import BehaviorConfig from '@/models/app-config/behavior/behavior'
import { createBadgeListPolicy } from '@/models/badge-list/factory/badge-list-policy-factory'
import { RuleApplicableFku } from '@/models/badge-list/rule-applicable-fku'
import GetStockStatus from '@/usecase/get-stock-status'
import Badge from '../badge'

export function createBadgeList(
  fku: ApiProductclass,
  behavior: BehaviorConfig
): Badge[] {
  return createBadgeListPolicy(behavior).list(
    new RuleApplicableFku(
      fku,
      GetStockStatus.createViaConfig(behavior)
    )
  )
}
