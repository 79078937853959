import Stock from '@/models/item/stock'
import { CoelFewDependOnWeb } from '@/models/item/stock/specification/display-rule/each-case/coel-few-depend-on-web'
import DisplayNothingAboutStock from './each-case/display-nothing-about-stock'
import DisplayStockIsFew from './each-case/display-stock-is-few'
import DisplayStockIsSome from './each-case/display-stock-is-some'
import WebStockIsFew from './each-case/web-stock-is-few'
import WebStockIsSome from './each-case/web-stock-is-some'

export default interface DisplayRule {
  shouldApply(stock: Stock): boolean
  apply(stock: Stock): string
}

// TODO: 静的ではなく、ルール毎に定義
export function ruleIsOnFew(rule: DisplayRule): boolean {
  return [
    WebStockIsSome,
    WebStockIsFew,
    DisplayStockIsSome,
    DisplayStockIsFew,
    CoelFewDependOnWeb,
  ].some(ruleType => rule instanceof ruleType)
}

// TODO: 静的ではなく、ルール毎に定義
export function ruleUseDisplayStock(rule: DisplayRule): boolean {
  return [
    DisplayNothingAboutStock,
    DisplayStockIsFew,
    DisplayStockIsSome,
  ].some(ruleType => rule instanceof ruleType)
}
