/**
 * 独自例外を定義し、かつ instanceof などで適切に分岐可能とするための基底エラークラス.
 *
 * @see https://future-architect.github.io/typescript-guide/exception.html#id4
 */
export abstract class BaseError extends Error {
  constructor(e?: string) {
    super(e)
    this.name = new.target.name

    Object.setPrototypeOf(this, new.target.prototype)
  }
}
