import {
  DefaultPaidMembershipPlanRepository,
  PaidMembershipPlanRepository,
} from '@/models/api/paid-member-price/member-plan/apply/repository'
import { subscribePaidMembershipPlan } from '@/models/paid-membership/subscribe/subscribe-process'
import { PaidMembershipPlanService } from '@/services/api/paid-member-plan'
import Vue from 'vue'
import { Mixin } from 'vue-mixin-decorator'

@Mixin
export class WithSubscribePaidMembershipPlanProcess extends Vue {
  get subscribePaidMembershipPlan() {
    return subscribePaidMembershipPlan(this.paidMemberPlanRepository)
  }

  protected brandEnglishName(): string {
    throw new Error('not implemented. this method should be override')
  }

  private get paidMemberPlanRepository(): PaidMembershipPlanRepository {
    return new DefaultPaidMembershipPlanRepository(
      new PaidMembershipPlanService(this.brandEnglishName())
    )
  }
}
