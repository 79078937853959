import { ApiTag } from '@/models/api/tags'
import { CartItem } from '@/models/cart/cart-item'
import { Either, left, right } from 'fp-ts/lib/Either'

export interface SubscriptionCartItem extends CartItem {
  subscription: boolean // 現状は直接参照している箇所はなく、実質マーカー程度.
}

/**
 * FIXME: サーバー側に定義を統合
 * @see src/Model/Dto/Engine/Resource/Subscription/SubscriptionConfig.php
 */
const tagPathPrefix = 'mycolor.product.subscription'

/**
 * FIXME: サーバー側にタグからの設定値構築を統合し、clientはAPIの戻り値を直接パースしてオブジェクトを構築する
 * @see src/Model/Dto/Engine/Resource/Subscription/SubscriptionConfig.php
 * @deprecated サーバー側に設定値の構築が統合されたら廃止
 */
export function convertFromCartItemToSubscriptionCartItem(item: CartItem): Either<Error, SubscriptionCartItem> {
  const root = item.root
  if (root === undefined) return left(new Error(`could not get root item of ${item.id}`))

  const configTags = root.tags.filter(t => t.pathname.includes(tagPathPrefix))

  const infiniteTag = configTags.find(t => t.pathname === `${tagPathPrefix}.range.infinite`)
  if (infiniteTag !== undefined) {
    return right({
      ...item,
      subscription: true,
    })
  }

  const unitTag = configTags.find(t => t.pathname.includes(`${tagPathPrefix}.range.unit.`))
  const valueTag = configTags.find(t => t.pathname.includes(`${tagPathPrefix}.range.value.`))

  /**
   * @see https://my-color.esa.io/posts/636 サブスクリプション申込み商品かどうかの判別にタグを利用
   * @see \App\Gateway\Model\Subscription\Repository\EngineSubscriptionContractRepository::findBySkuId
   */
  const config = { unitTag, valueTag }
  if (!subscriptionSupported(config)) {
    return left(new Error(`${item.id} not support subscription because ${root.id} not have enough tags`))
  }

  return right({
    ...item,
    subscription: true,
  })
}

/*
 * 単に説明変数を追加するだけだと ApiTag|undefined → ApiTag と識別する型推論が聞かなくなるので、
 * type guard 用の関数を用意して経由させている.
 */
interface MaybeIncompleteConfig { unitTag: ApiTag|undefined, valueTag: ApiTag|undefined }
interface Config { unitTag: ApiTag, valueTag: ApiTag }
function subscriptionSupported(src: MaybeIncompleteConfig): src is Config {
  return src.unitTag !== undefined && src.valueTag !== undefined
}
