import queryString from 'query-string'

const getTopPath = (path: string): string => {
  const [, topPath] = path.split('/')

  return topPath
}

export type ExistBrand = (maybeBrand: string) => Promise<boolean>
const isBrandPage = (existBrand: ExistBrand) =>
  async (path: string): Promise<boolean> => {
    const topPath = getTopPath(path)

    return !!topPath && existBrand(topPath)
  }
export interface InstanceType {
  isSingle(): boolean
}
const isItemListPage = (instance: InstanceType) => (path: string) => {
  const [, firstPath, secondPath] = path.split('/')
  const target = instance.isSingle() ? firstPath : secondPath

  return target === 'itemlist'
}

export class URL {
  static readonly isBrandPage = isBrandPage
  static readonly isItemListPage = isItemListPage
  static readonly getTopPath = (p: string) => `/${getTopPath(p)}`

  /**
   * @example client/spec/util/url/join.spec.ts
   */
  public static join(...paths: string[]): string {
    const [head, ...rest] = paths
    const slashOnBeginAndEndRemoved = rest.map(
      p => p.replace(/^\//, '').replace(/\/$/, '')
    )
    const headWithoutEndSlash = head.replace(/\/$/, '')

    return [headWithoutEndSlash, ...slashOnBeginAndEndRemoved].join('/')
  }

  public static splitQuery(base: string): { url: string, query: string } {
    const { url, query } = queryString.parseUrl(base)
    const queryStr = queryString.stringify(query)

    return {
      url,
      query: queryStr || '',
    }
  }
  public static getQuery(base: string, name: string): string | null {
    const { query } = queryString.parseUrl(base)
    const value = query[name]

    return typeof value === 'string' ? value : null
  }
  public static merge(base: string) {
    const {
      query: baseQuery,
      url: baseUrl,
    } = queryString.parseUrl(base)

    return {
      with(other: string): string {
        const {
          query: otherQuery,
          url: otherUrl,
        } = queryString.parseUrl(other)

        const query = queryString.stringify({
          ...baseQuery,
          ...otherQuery,
        })
        const url = otherUrl || baseUrl

        if (query === '') {
          return url
        }

        return `${url}?${query}`
      },
    }
  }

  /**
   * @return 引数のurlがSPAページを指す場合にtrueを返す
   */
  public static isSpa(url: string): boolean {
    return /^\/s(\/.*)?$/.test(url)
  }
  /**
   * {@link URL.isSpa}へのalias
   */
  public static readonly isAbsoluteSpaPath = p => URL.isSpa(p)

  public static extractMaybeSpaPath(path: string): string {
    return this.isSpa(path) ? path.replace(/^\/s\//, '/') : path
  }

  public static createUrlForCurrentOrigin(path: string, loc: Location = location): string {
    /**
     * mycolor.jpevil/s/login のような偽サイトを作られた場合、
     * mycolor.jp/s/login?next=evil/s/login のようなクエリパラメータを渡されると、外部ページへ送られてしまう.
     *
     * それを防ぐため、next以降は現在のorigin配下であることを確定させるため、先頭の/を削除してから結合.
     */
    const target = path.replace(/^[\/\\]*/, '')
    const origin = loc.origin

    return `${origin}/${target}`
  }
}
