









import Vue from 'vue'
import { Component, Emit } from 'vue-property-decorator'

@Component
export default class CatalogDemandEnabled extends Vue {
  @Emit()
  click() {
    // nothing to do
  }
}
