
export default class ThumbnailUrl {
  static asSizeS(url: string): ThumbnailUrl {
    return new ThumbnailUrl(url, 's')
  }
  static asSizeXS(url: string): ThumbnailUrl {
    return new ThumbnailUrl(url, 'xs')
  }

  private constructor(
    private readonly url: string,
    private readonly size: string
  ) { }

  get isGif(): boolean {
    return this.extension === 'gif'
  }

  get extension(): string | null {
    const matched = this.removeHash(this.removeQuery(this.url)).match(/([.]+)[^.]+$/)

    return matched ? matched[0].replace('.', '') : null
  }

  get value(): string {
    if (this.isGif) {
      return this.url
    }

    if (!this.extension) {
      return this.url
    }

    return `${this.urlWithoutExtension}_${this.size}.${this.extension}`
  }

  private get urlWithoutExtension(): string {
    if (this.extension === null) {
      return this.url
    }

    return this.url.replace(`.${this.extension}`, '')
  }

  private removeQuery(url: string): string {
    return url.replace(/\?.*$/, '')
  }
  private removeHash(url: string): string {
    return url.replace(/#.*$/, '')
  }
}
