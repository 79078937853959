































import * as Parallel from 'async-parallel'
import { Either, Left } from 'fp-ts/lib/Either'
import _ from 'lodash'
import Vue from 'vue'
import InfiniteLoading, { StateChanger } from 'vue-infinite-loading'
import { Component, Emit, Prop, Watch } from 'vue-property-decorator'

import { ListWithPagination, Pagination } from '@/models/api/response/list'
import { IntentionallyLeftBlankPage, Page, PageCollection } from '@/models/page'
import { AutoLoadComplete, AutoLoadError } from './model'

@Component({
  components: {
    InfiniteLoading,
  },
})
export default class AutoLoadContainer extends Vue {
  @Prop({ required: true })
  currentPage: number

  @Prop({ required: true })
  fetchCallback: (baseQuery: object) => Promise<Either<Error, ListWithPagination<any>>>

  @Prop({ required: true })
  perPage: number

  @Prop({ default: 'waveDots' })
  spinner: string

  @Prop({ default: () => ({}) })
  query: object

  @Prop({ default: 'section' })
  tag: string

  @Prop({ default: 1 })
  basePage: number

  @Prop({ default: true })
  prefetch: boolean

  pagination: Pagination | null = null

  // Make dummy page also for the current page to keep scroll-target place.
  pages: PageCollection<any> = new PageCollection([], this.perPage, this.basePage)

  get canAutoload(): boolean {
    return this.currentPage >= this.basePage
  }

  /**
   * Load page data by specifying a page number.
   */
  async loadPage(number: number): Promise<void> {
    /* Check if the page number exists */
    const target = this.pages.find(number)

    if (target && !(target instanceof IntentionallyLeftBlankPage)) {
      return  // Skip
    }

    const listResult = await this.list(number)

     /* Error handling */
    if (listResult.isLeft()) {
      this.callbackOnError(listResult.value)

      return
    }

    /* Update the view state */
    const { list, pagination } = listResult.value
    this.pagination = pagination
    // FIXME: Current page is supposed to be always there, for being the initial scroll destination.
    // If there is any smart way, fix this clumsy code.
    if (pagination.exceedsLastPage && this.currentPage !== number) {
      return
    }

    if (list.length !== 0) {
      this.pages.add(new Page(number, list))
    }

    /* Execute callback */
    this.callbackOnLoad(list)
  }

  /**
   * Load page data when autoload is triggered.
   */
  async autoloadNextPage($state: StateChanger) {
    const nextPage = this.currentPage + 1
    const listResult = await this.list(nextPage)

    /* Error handling */
    if (listResult.isLeft()) {
      this.callbackOnError(listResult.value, () => {
        $state.loaded()
        $state.complete()
      })

      return
    }

    /* Update the view state */
    const { list, pagination } = listResult.value
    this.pagination = pagination
    if (!pagination.exceedsLastPage) {
      this.pages.add(new Page(nextPage, list))
    }

    /* Execute callback */
    this.callbackOnLoad(list, {
      onNoItem: () => {
        $state.loaded()
        $state.complete()
      },
      onLoaded: () => {
        $state.loaded()
      },
      onEnd: () => {
        $state.complete()
      },
    })
  }

  @Watch('currentPage', { immediate: true })
  async onCurrentPageChange() {
    if (!this.canAutoload) {
      return // Skip
    }

    /* Fill in previous pages */
    this.pages.fillInPreviousPages(this.currentPage)

    /* Try to load current page in case there is no current page data */
    await this.loadPage(this.currentPage)

    /* Prefetch surrounding pages */
    if (this.prefetch) {
      await Parallel.map(
        [
          ... this.basePage <= this.currentPage ? [this.currentPage - 1] : [],
          this.currentPage + 1,
        ],
        this.loadPage
      )
    }
  }

  private async list(page: number): Promise<Either<Error, ListWithPagination<any>>> {
    if (page < this.basePage) {
      return new Left(new Error(`You are calling page ${page}, which is under MPA scope.`))
    }

    const query = {
      limit: this.perPage,
      // Load from the end of the previous page to the end of this page
      start: (page - 1) * this.perPage,
      ...this.query,
    }

    return await this.fetchCallback(query)
  }

  private callbackOnLoad(
    loadedItems: any[],
    callback: {
      onNoItem?: (() => void),
      onLoaded?: (() => void),
      onEnd?: (() => void),
    } = {}
  ) {
    const { onNoItem, onLoaded, onEnd } = callback

     /* No item */
    if (loadedItems.length === 0) {
      this.complete({ pagination: this.pagination, pages: this.pages })

      if (onNoItem) {
        onNoItem()
      }

      return []
    }

    /* Loaded */
    if (onLoaded) {
      onLoaded()
    }

    /* End of page */
    if (loadedItems.length < this.perPage) {
      this.complete({ pagination: this.pagination, pages: this.pages })

      if (onEnd) {
        onEnd()
      }
    }
  }

  private callbackOnError(
    error: Error,
    onError?: (() => void)
  ) {
    if (error) {
      this.error({
        error,
        pagination: this.pagination,
        pages: this.pages,
      })

      if (onError) {
        onError()
      }

      return []
    }
  }

  @Emit()
  private complete(_result: AutoLoadComplete) {
    // nothing to do
  }

  @Emit()
  private error(_error: AutoLoadError) {
    // nothing to do
  }
}
