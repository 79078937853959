import AppId from '@/util/appid'
import { isEmpty } from 'lodash'

/*
 * 商品一覧に表示するFKU一覧を取得するためのAPIとして /api/:brand/item/fku があるが、
 * ページネーション実装の関係で、やむなく proxy api を引き続き利用している.
 *
 * ページネーション昨日のために確保すべき情報が何であり、
 * そのためにAPIとしてどのような表現形式とI/Fが必要であるかを整理・定義した上で
 * それを満たす独自APIが実装できたなら、proxyではなく独自APIを使うように変更する.
 *
 * @see https://github.com/my-color/front/issues/5733
 */

export class FkuUrl {
  public static get forCommon(): FkuUrl {
    return new FkuUrl('common-proxy')
  }

  public static get forUser(): FkuUrl {
    return new FkuUrl('user-proxy')
  }

  private constructor(
    private readonly apiType: 'common-proxy' | 'user-proxy',
    private readonly brand: string | null = null
  ) {
  }

  public withBrand(brand: string): FkuUrl {
    return new FkuUrl(this.apiType, brand)
  }

  public get list(): string {
    const notEmpty = (s: string | null): s is string => !isEmpty(s)

    const path: string[] = ['/api', this.apiType, AppId.getByBrandName(this.brand), 'productclass2'].filter(notEmpty)

    return path.join('/')
  }
}
