import { BrandMaintenanceList } from '@/models/api/brand-maintenance/brand-maintenance-list'
import { BrandMaintenanceService } from '@/services/api/brand-maintenance'

/**
 * ブランドメンテナンス状態を取得、保持する
 * Vuexに置くほどのものでもないので、シングルトンで簡易ストアっぽく実装している
 */
export default class Maintenance {
  static get instance(): Maintenance {
    if (Maintenance._instance === null) {
      Maintenance._instance = new Maintenance()
    }

    return Maintenance._instance
  }
  private static _instance: Maintenance | null = null
  private process: Promise<BrandMaintenanceList> | null = null

  private constructor() {}

  async underMaintenance(brandName: string): Promise<boolean> {
    return (await this.list()).underMaintenance(brandName)
  }

  private async list(): Promise<BrandMaintenanceList> {
    if (this.process === null) {
      const brandMaintenanceService = new BrandMaintenanceService()
      this.process = brandMaintenanceService.list().then((result) => {
        if (result.isLeft()) {
          throw result.value
        }

        return result.value
      })
    }

    return this.process
  }
}
