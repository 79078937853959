import { BundlePurchaseItem } from '../../../bundle-purchase-item'
import { AddToPurchaseListSpec } from '../index'

export default class ItemMustInRegularSalePeriod implements AddToPurchaseListSpec {
  constructor(private readonly baseDate: Date) { }

  isSatisfiedBy(item: BundlePurchaseItem): boolean {
    return item.regularSalePeriod.include(this.baseDate)
  }
}
