import _ from 'lodash'
import superagent from 'superagent'

export class ApiClient {
  static get(endpoint: string, query: object = {}): superagent.SuperAgentRequest {
    const request = superagent
      .get(endpoint)
      .withCredentials()
      .query(this.isProductclass2(endpoint) ? this.getNewQuery(query) : query) // TODO: Adohoc PJ_MYCOLOR-1099
      .set('Accept', 'application/json')

    if (!this.shouldBasicAuth()) {
      return request
    }

    const { id, password } = this.getBasichAuthInfo()

    return request.auth(id, password)
  }

  static postPlain(endpoint: string, data: object = {}): superagent.SuperAgentRequest {
    const request = superagent
      .post(endpoint)
      .withCredentials()
      .send(`timestamp:${data['timestamp']}	kind:${data['kind']}	url:${data['url']}`)
      .set('Accept', 'application/json')
      .set('Content-Type', 'text/plain')

    if (!this.shouldBasicAuth()) {
      return request
    }

    const { id, password } = this.getBasichAuthInfo()

    return request.auth(id, password)
  }

  static post(endpoint: string, data: object = {}): superagent.SuperAgentRequest {
    const request = superagent
      .post(endpoint)
      .withCredentials()
      .send(data)
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/x-www-form-urlencoded')

    if (!this.shouldBasicAuth()) {
      return request
    }

    const { id, password } = this.getBasichAuthInfo()

    return request.auth(id, password)
  }

  static put(endpoint: string, data: object = {}): superagent.SuperAgentRequest {
    const request = superagent
      .put(endpoint)
      .withCredentials()
      .send(data)
      .set('Accept', 'application/json')
      .set('Content-Type', 'application/x-www-form-urlencoded')

    if (!this.shouldBasicAuth()) {
      return request
    }

    const { id, password } = this.getBasichAuthInfo()

    return request.auth(id, password)
  }

  private static shouldBasicAuth(): boolean {
    const id = process.env.BASIC_AUTH_ID
    const password =  process.env.BASIC_AUTH_PASSWORD

    return !_.isEmpty(id) && !_.isEmpty(password)
  }

  private static getBasichAuthInfo(): { id: string, password: string} {
    return {
      id: process.env.BASIC_AUTH_ID || '',
      password: process.env.BASIC_AUTH_PASSWORD || '',
    }
  }

  private static getNewQuery(query: object) {

    const newQuery: object = query

    if ('with' in newQuery) {
      const withs: string[] = newQuery['with'].split(',')
      if (_.indexOf(withs, 'indexsummary') < 0) withs.push('indexsummary')
      newQuery['with'] = withs.join(',')

    } else {
      newQuery['with'] = 'indexsummary'
    }

    return newQuery
  }

  private static isProductclass2(path: string) {
    const pathnames = path.split('/')

    return _.filter(pathnames, pathname => pathname === 'productclass2').length >= 1

  }
}
