import ItemPriceWithPrimitiveValue from '@/components/molecules/item/price/adapter/with-primitive-value/ItemPriceWithPrimitiveValue.vue'
import AddItemToCartViaExternalSystem from '@/components/organisms/cart/add-item/AddItemToCartViaExternalSystem.vue'
import SubscribePaidMembershipPlanForm from '@/components/organisms/paid-membership/subscribe/form/SubscribePaidMembershipPlanForm.vue'
import ApplySubscriptionButton from '@/components/organisms/subscription/ApplySubscriptionButton.vue'
import CircularCarouselView from '@/vendor/circular-carousel/CircularCarouselView.vue'
import ThirdPartyTool from '@/vendor/ThirdPartyTool.vue'
import Vue from 'vue'

/**
 * TODO: こうした「グローバルに登録され、運用含めて任意に使用可能なUIコンポーネント」について、適切な命名が欲しい.
 * @see https://github.com/my-color/front/pull/5348#discussion_r634078139
 */
Vue.component('third-party-tool', ThirdPartyTool)
Vue.component('circular-carousel-view', CircularCarouselView)
Vue.component('apply-subscription-button', ApplySubscriptionButton)
Vue.component('add-item-to-cart', AddItemToCartViaExternalSystem)
Vue.component('item-list-price-mpa', ItemPriceWithPrimitiveValue)
Vue.component('subscribe-paid-membership-plan', SubscribePaidMembershipPlanForm)
