
import { OrderResult, resultIsSuccess, SuccessOrderResult } from '@/models/order/completed/order-result'
import { Delivery } from '@/models/order/order'
import { OrderSummary } from '@/models/order/summary'
import { SubscriptionConfirmState } from '@spa/store/modules/subscriptionConfirm/index'
import { RootState } from '@spa/store/types'
import { GetterTree } from 'vuex'

export const getters: GetterTree<SubscriptionConfirmState, RootState> = {
  summary(state): OrderSummary {
    return state.summary
  },
  allOrderResults(state): OrderResult[] {
    return state.orderResults
  },
  delivery(state): Delivery {
    return state.delivery
  },
  succeedOrderResults(state): SuccessOrderResult[] {
    return state.orderResults.filter(resultIsSuccess)
  },
}
