














import { PriceRange, PriceViewState } from '@/components/molecules/item/price/model/item-price-view'
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component
export default class RegularPrice extends Vue {
  @Prop({ required: true })
  readonly state: PriceViewState

  get displayRange(): PriceRange {
    return this.state.nonPaidMemberPrice.range
  }
}
