import { log } from '@/log'
import {
  CreditcardPaymentProviderServiceForPurchase,
  CreditcardPaymentProviderServiceForSubscription,
  CreditcardPaymentProviderViewConfig,
} from '@/services/payment/creditcard-payment-provider/creditcard-payment-provider-service'
import {
  KuronekoServiceForPurchase,
  KuronekoServiceForSubscription,
} from '@/services/payment/creditcard-payment-provider/provider/kuroneko'
import { kuronekoViewConfig } from '@/services/payment/creditcard-payment-provider/provider/kuroneko/view-config'
import {
  PaygentServiceForPurchase,
  PaygentServiceForSubscription,
} from '@/services/payment/creditcard-payment-provider/provider/paygent'
import { paygentViewConfig } from '@/services/payment/creditcard-payment-provider/provider/paygent/view-config'

export type CreditcardPaymentProvider = 'kuroneko' | 'paygent'
const defaultProvider: CreditcardPaymentProvider = 'kuroneko'

interface CreditcardPaymentProviderServiceConfig {
  readonly provider: 'kuroneko' | 'paygent'

  readonly viewConfig: CreditcardPaymentProviderViewConfig

  readonly forPurchase: CreditcardPaymentProviderServiceForPurchase
  readonly forSubscription: CreditcardPaymentProviderServiceForSubscription
}

export function getCreditcardPaymentProvierServiceConfig(
  provider: string | undefined = process.env.PAYMENT_CREDITCARD_PROVIDER
): CreditcardPaymentProviderServiceConfig {
  switch (provider) {
    case 'kuroneko': return {
      provider,
      viewConfig: kuronekoViewConfig,
      forPurchase: new KuronekoServiceForPurchase(),
      forSubscription: new KuronekoServiceForSubscription(),
    }
    case 'paygent': return {
      provider,
      viewConfig: paygentViewConfig,
      forPurchase: new PaygentServiceForPurchase(),
      forSubscription: new PaygentServiceForSubscription(),
    }
    default:
      // 本番ブラウザで「値が未設定」とかあまり露出させたくないが、この分岐を通ってしまったことは確認可能としたいので、
      // infoで出力しつつ若干曖昧に表現.
      log.info(`use default creditcard payment provider`)

      return getCreditcardPaymentProvierServiceConfig(defaultProvider)
  }
}
