abstract class Id {
  constructor(
    public readonly long: string,
    public readonly short: string
  ) {
    // nothing to do
  }
}

export default Id
