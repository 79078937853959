import { ApplyMembershipPlan } from '@/models/api/paid-member-price/member-plan/apply/scheme'
import { AvailablePaidMembershipPlanGroupScheme } from '@/models/api/paid-member-price/member-plan/group/scheme'
import { AvailablePaidMembershipPlanScheme } from '@/models/api/paid-member-price/member-plan/scheme'
import { ApiClient, ApiClientWithAuth } from '@/services/api/client'
import { log } from '@/util/log'
import { createPaymentParamBuilder } from '@spa/services/order/paymentParamBuilder'
import { Either, left, right } from 'fp-ts/lib/Either'

export class AvailablePaidMemberPlanService {
  constructor (
    private readonly apiClient: typeof ApiClient = ApiClientWithAuth
  ) {}

  async groups(): Promise<Either<Error, AvailablePaidMembershipPlanGroupScheme[]>> {
    const target = `/api/user/paid_membership/plan/group/available`

    try {
      const response = await this.apiClient.get(target)

      log.debug({ service: target, response })

      return right(response.body.data)
    } catch (error) {
      log.error({ service: target, error })

      return left(error)
    }
  }

  async list(): Promise<Either<Error, AvailablePaidMembershipPlanScheme[]>> {
    const target = `/api/user/paid_membership/plan/available`

    try {
      const response = await this.apiClient.get(target)

      log.debug({ service: target, response })

      return right(response.body.data)
    } catch (error) {
      log.error({ service: target, error })

      return left(error)
    }
  }

  async findBy(planId: string, subscribeId: string): Promise<Either<Error, AvailablePaidMembershipPlanScheme>> {
    const target = `/api/user/paid_membership/plan/available/${planId}/${subscribeId}`

    try {
      const response = await this.apiClient.get(target)

      log.debug({ service: target, response })

      return right(response.body.data)
    } catch (error) {
      log.error({ service: target, error })

      return left(error)
    }
  }
}

export class PaidMembershipPlanService {
  constructor (
    private readonly brandEnglishName: string,
    private readonly apiClient: typeof ApiClient = ApiClientWithAuth
  ) {}

  async apply(params: ApplyMembershipPlan): Promise<Either<Error, null>> {
    const target = `/api/user/${this.brandEnglishName}/paid_membership/plan`
    const { payment, ...rest } = params
    const param = {
      payment: createPaymentParamBuilder(payment).build(),
      ...rest,
    }

    try {
      const response = await this.apiClient.post(target, param)

      log.debug({
        service: AvailablePaidMemberPlanService.name,
        target,
        param,
        response,
      })

      return right(null)
    } catch (error) {
      log.error({ error })

      return left(error)
    }
  }
}
