import dateFormat from 'date-fns/format'

export function formatPrice(price: number, defaultValue: string = '￥0'): string {
  if (!price) {
    return defaultValue
  }

  return price.toLocaleString('ja-JP', { style: 'currency', currency: 'JPY' })
}

export class DateFormat {
  static readonly endOfEarlyMonth = 10
  static readonly endOfMidMonth = 20

  /**
   * 日付を yyyy/mm/dd 形式に変換
   * @param date
   */
  static yyyyMmDd(date: Date): string {
    return dateFormat(date, 'YYYY/MM/DD')
  }

  /**
   * 日付に応じてx月上旬/中旬/下旬の文字列形式に置換する
   * @param date 日付
   */
  static partOfMonth(date: string | Date): string {
    const d = new Date(date)
    const year = `${d.getFullYear()}年`
    const month = `${d.getMonth() + 1}月`
    const day = d.getDate()

    if (day <= DateFormat.endOfEarlyMonth) {
      return `${year}${month}上旬`
    }

    if (DateFormat.endOfEarlyMonth < day && day <= DateFormat.endOfMidMonth) {
      return `${year}${month}中旬`
    }

    return `${year}${month}下旬`
  }

  static validate(dateStr: string): boolean {
    const date = new Date(dateStr)

    // When date string is invalid, IE might return NaN, while other browsers return Date type with 'Invalid Date' value
    return date && date.toString() !== 'Invalid Date'
  }
}
