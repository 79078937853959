












import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import AjaxCharacter from '@/components/organisms/Character.vue'
import LockBackground from '@/mixin/LockBackground'

@Component({
  components: {
    AjaxCharacter,
  },
  mixins: [
    LockBackground,
  ],
})
export default class CompleteModal extends Vue {
  @Prop({ required: true })
  character: string

  onClose(event) {
    this.$emit('close', event)
  }
}
