import AbstractValue from '@/models/app-config/behavior/abstract-value'

export interface RawItemListFilter {
  allowed: ItemListFilterItem[] | 'all'
}

export enum ItemListFilterItem {
  Brand = 'brand',
  Category = 'category',
  Stock = 'stock',
  Color = 'color',
  Price = 'price',
  Genre = 'genre',
}

export default class ItemListFilter extends AbstractValue<RawItemListFilter> {
  get allowed(): ItemListFilterItem[] | 'all' {
    return this.raw.allowed
  }

  allow(item: ItemListFilterItem): boolean {
    if (this.allowed === 'all') {
      return true
    }

    return !!this.allowed.find(i => i === item)
  }
}
