












import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component
export default class PaginationCounter extends Vue {
  static readonly formatter: Intl.NumberFormat = new Intl.NumberFormat()

  @Prop({ default: 0 })
  current: number

  @Prop({ default: 0 })
  total: number

  @Prop({ default: '件' })
  unit: string

  numberFormat(value: number): string {
    return PaginationCounter.formatter.format(value)
  }
}
