































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import Maintenance from '@/environment/maintenance'
import { ApiProductclass } from '@/models/api/productclass'
import { ItemKind } from '@/models/item/kind'
import SelectThumbnailImageBuilder from '@/services/product-image/select-thumbnail-image-builder'
import AddToCartButtonInList from '@ajax/vue/components/organisms/addtocart/in-item-list/AddToCartButtonInList.vue'
import Item from './Item.vue'
import ItemLink from './item/ItemLink.vue'
import ItemUnderMaintenance from './item/ItemUnderMaintenance.vue'
import ItemContactLens from './ItemContactLens.vue'

@Component({
  components: {
    AddToCartButtonInList,
    Item,
    ItemContactLens,
    ItemLink,
    ItemUnderMaintenance,
  },
})
export default class ItemWithLink extends Vue {
  /**
   * https://github.com/my-color/front/issues/2402
   * 実装でいくつかItem.vueと重複している箇所が有るが、一旦は↑の要件に対応するためこのまま.
   *
   * TODO: ItemWithLink.vueで吸収できる部分は、ItemWithLink.vue → Item.vue で渡す
   */

  /**
   * true: サムネイルのみ表示
   * false: サムネイル以外の情報(価格、バッジ、カテゴリ、etc...)も表示
   */
  @Prop({ default: false })
  instaview: boolean

  @Prop({ required: true })
  item: {
    id: string
    tags: any[]
    image_url: string
    images: any[]
    parts: any[]
    publish_at: Date,
    belonging_to: any[],
    product_name: string,
  }

  @Prop({ default: 'default' })
  itemKind: ItemKind

  @Prop({ default: () => SelectThumbnailImageBuilder.createDefault() })
  selectThumbnailImageBuilder: SelectThumbnailImageBuilder

  @Prop({ default: false })
  /**
   * お気に入り画面やおすすめ商品など、カート追加ボタンを表示しないケースがある
   */
  showAddToCartButton: boolean

  brandEnglishName: string
  maintenance: Maintenance = Maintenance.instance
  isUnderMaintenance: boolean = false

  async created() {
    this.brandEnglishName = this.fku.belongingBrand.englishName
    this.isUnderMaintenance = await Maintenance.instance.underMaintenance(this.brandEnglishName)
  }

  get fku(): ApiProductclass {
    return new ApiProductclass(this.item)
  }

  get isContactLens(): boolean {
    return this.itemKind === 'contact-lens'
  }
}
