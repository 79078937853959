import { PaymentType } from '@/models/app-config/behavior/types'
import { Either, Left, Right } from 'fp-ts/lib/Either'
import _ from 'lodash'
import request from 'superagent'

import { log } from '@/log'
import { ApiCreditcard, ApiCreditcardWithSecurityCode } from '@/models/api/creditcard'
import { ApiClient } from './client'
import { ApiService } from './service'

const namespace: string = 'creditcard'

export type CreditcardToBuildPaymentPayload = Pick<
  ApiCreditcardWithSecurityCode,
  'id' |
  'securityCode' |
  'lastCreditDate' |
  'numberLast4' |
  'cardType'
>

/**
 * クレジットカードAPI利用用のAPIクライアント.
 *
 * クレジットカードの利用方法について「購入」「サブスクリプション」とバリエーションがあり、
 * かつ、Engine都合でバリエーション毎に異なる利用方法が必要になっている.
 *
 * とはいえ、基本的な利用方法や変わらずパラメータが部分的に変わっているだけなので、
 * 基底となる抽象クラスを定義し、各バリエーションごとにサブクラスを定義、および
 * 各バリエーションで必要なパラメータ構築を各々で再定義する、という形を取っている.
 */
export abstract class CreditcardService extends ApiService {
  async list(query: object = {}): Promise<Either<Error, ApiCreditcard[]>> {
    try {
      const response = await ApiClient
        .get(`/api/user-proxy/${this.appId}/commerce_creditcard/`)
        .query(query)

      log.debug({ service: `${namespace}/list`, response })

      return new Right(
        this.convertToCreditCardList(response.body.data)
      )
    } catch (error) {
      log.error({ error })

      return new Left(error)
    }
  }

  async get(id: string, query: object = {}): Promise<Either<Error, ApiCreditcard>> {
    try {
      const response = await ApiClient
        .get(`/api/user-proxy/${this.appId}/commerce_creditcard/${id}`, query)

      log.debug({ service: `${namespace}/get`, response })

      return new Right(
        this.convertToCreditCard(response.body.data)
      )
    } catch (error) {
      log.error({ error })

      return new Left(error)
    }
  }

  async create(token: string): Promise<Either<Error, request.Response>> {
    try {
      const payload = this.buildPayloadToCreate(token)

      const response = await ApiClient
        .post(`/api/user-proxy/${this.appId}/commerce_creditcard`)
        .send(payload)

      log.debug({ service: `${namespace}/create`, response })

      return new Right(response)
    } catch (error) {
      log.error({ error })

      return new Left(error)
    }
  }

  async remove(id: string): Promise<Either<Error, request.Response>> {
    try {
      const response = await ApiClient
        .delete(`/api/user-proxy/${this.appId}/commerce_creditcard/${id}`)

      log.debug({ service: `${namespace}/remove`, response })

      return new Right(response)
    } catch (error) {
      log.error({ error })

      return new Left(error)
    }
  }

  /**
   * クレジットカード決済時に最低限必須のパラメータを、クレカ情報から構築する
   */
  buildPaymentPayload(creditcard: CreditcardToBuildPaymentPayload) {
    return {
      payment_type: 'creditcard' as PaymentType,
      numberLast4: creditcard.numberLast4,
      cardType: creditcard.cardType,
    }
  }

  /**
   * 新規クレジットカード登録用のpaymentパラメータのオブジェクトを生成する
   */
  protected buildPaymentPayloadToCreate(token: string): object {
    return {
      payment_type: 'creditcard',
      token,
    }
  }

  /**
   * 新規クレジットカード登録用のパラメータを生成する
   */
  private buildPayloadToCreate(token: string) {
    /*
     * 内容は支払パラメータ生成用のものと一部重複しているが、以下の観点から分離している
     * 1. 支払パラメータとカード登録用パラメータが一致・連動する保証は無い
     * 2. 支払パラメータとカード登録用パラメータは、時に内容が重複するとしてもそれぞれ独立したものとして理解されるべき
     */
    return {
      payment: JSON.stringify(this.buildPaymentPayloadToCreate(token)),
    }
  }

  private findMyColorProvider(responseData: any): { provider: string, creditcards: any[] } {
    const providers = _.get(responseData, 'providers')

    // NOTE: This CAN be otherwise in an instance other than MyColor-hosted one, such as COEL.
    return _.find(providers, ['provider', 'mycolor'])
  }

  private convertToCreditCardList(responseData: any): ApiCreditcard[] {
    const provider = this.findMyColorProvider(responseData)

    if (!provider) {
      return []
    }

    return _.map(provider.creditcards, item => new ApiCreditcard(item))
  }

  private convertToCreditCard(responseData: any): ApiCreditcard {
    const rawData = _.get(responseData, 'creditcard')

    return new ApiCreditcard(rawData)
  }
}

export class CreditcardServiceForPurchase extends CreditcardService {
  /**
   * 型がコンパイラで厳密に区別されるようにするための識別子
   */
  readonly _tag: 'for-purchase' = 'for-purchase'
}
