import { MutationTree } from 'vuex'
import { CouponState } from './types'

import { log } from '../../../log'

import { ApiCoupon } from '@/models/api/coupon'

const namespace = 'coupon'

export const mutations: MutationTree<CouponState> = {

  list(state, coupons: ApiCoupon[]) {
    state.coupons = coupons
    log.debug({ commit: `${namespace}/list`, state })
  },

  errorOnLoad(state, error: Error) {
    state.loadError = error
    log.debug({ commit: `${namespace}/errorOnLoad`, state })
  },
}
