













































import { Mixins } from 'vue-mixin-decorator'
import { Component } from 'vue-property-decorator'

import SearchSysItemCommon from './SearchSysItemCommon.vue'

@Component
export default class SearchSys2Item extends Mixins<SearchSysItemCommon>(SearchSysItemCommon) {

}
