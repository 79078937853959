















import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import ShareBase from './ShareBase.vue'

@Component({
  components: {
    ShareBase,
  },
})
export default class ShareFacebook extends Vue {
  @Prop({ required: true })
  url: string
}
