import _ from 'lodash'

import ChunkedExecuter from '@/util/chunked-executer'

export default class ChunkedRequestExecuter {
  static readonly BORDER_QUERY_LENGTH = 1024
  /**
   * https://www.chatwork.com/#!rid87550748-1111213650586107904
   *
   * WebAPIではURI長として最大2048文字、推奨は1024文字以下の設定.
   * shortidの文字列長がおよそ40弱なので、shortid + その他クエリで合計1024以下程度になるよう調整
   */
  static readonly CHUNK_SIZE_WITH_SHORT_ID = 22 // 40 * 22 = 880

  /**
   * https://www.chatwork.com/#!rid87550748-1111213650586107904
   *
   * WebAPIではURI長として最大2048文字、推奨は1024文字以下の設定.
   * longidの文字列長がおよそ80弱で、shortidと同様に合計1024以下程度になるよう調整
   *
   * 念のため設定を用意しておくが、複数ID指定での検索は基本的にshortidを使うべき、という想定
   */
  static readonly CHUNK_SIZE_WITH_LONG_ID = 11 // 80 * 11 = 880

  static withShortId(): ChunkedRequestExecuter {
    return new ChunkedRequestExecuter(
      new ChunkedExecuter(ChunkedRequestExecuter.CHUNK_SIZE_WITH_SHORT_ID)
    )
  }
  static withLongId(): ChunkedRequestExecuter {
    return new ChunkedRequestExecuter(
      new ChunkedExecuter(ChunkedRequestExecuter.CHUNK_SIZE_WITH_LONG_ID)
    )
  }

  constructor (private chunkedExecuter: ChunkedExecuter) {}

  async execute<T, R>(parameters: T[], executer: (chunked: T[]) => Promise<R[] | R>): Promise<R[]> {
    const results = await Promise.all(this.chunkedExecuter.execute(parameters, await executer))

    return _.flatten(results)
  }
}
