import * as Alphabet from '@/util/alphabet'
import BigInt from 'big-integer'
import _ from 'lodash'

// tslint:disable:no-magic-numbers
// https://developer.mozilla.org/ja/docs/Web/JavaScript/Reference/Global_Objects/parseInt
export type NativeSupportedBase =  2 |  3 |  4 |  5 |  6 |  7 |  8 |  9 | 10 | 11 | 12 | 13 | 14 | 15 |
                                  16 | 17 | 18 | 19 | 20 | 21 | 22 | 23 | 24 | 25 | 26 | 27 | 28 | 29 |
                                  30 | 31 | 32 | 33 | 34 | 35 | 36

export type ExtendedSupportedBase = 62
// tslint:enable:no-magic-numbers

export type SupportedBase = NativeSupportedBase | ExtendedSupportedBase

export class BaseConverter {
  static readonly characterListMap: { [key: number]: string[] } = {
    62: [
      // tslint:disable-next-line:no-magic-numbers
      ...(_.range(0, 10).map(v => `${v}`)),
      ...Alphabet.createUpperCases(),
      ...Alphabet.createLowerCases(),
    ],
  }

  static convertBaseFrom16To62(hex: string): string {
    const beforeBase = 16
    const afterBase = 62

    return BaseConverter.convertExtendedBase(
      hex,
      beforeBase,
      afterBase
    )
  }

  static convertBaseFrom62To16(base62: string): string {
    const beforeBase = 62
    const afterBase = 16

    return BaseConverter.convertFromExtendedBase(
      base62,
      beforeBase,
      afterBase
    )
  }

  private static convertFromExtendedBase(val: string, beforeBase: SupportedBase, afterBase: SupportedBase): string {
    const characterList = BaseConverter.characterListMap[beforeBase]
    const valChars = val.split('')

    return valChars.reduce(
      (sum, value, i) => {
        const unit = characterList.findIndex(c => c === value)
        const index = valChars.length - (i + 1)

        const numMultiplied = BigInt(beforeBase).pow(index)
        const num = numMultiplied.multiply(unit)

        return sum.add(num)
      },
      BigInt.zero
    ).toString(afterBase)
  }

  /**
   * javascriptが標準でサポートしていない範囲のx進数(x > 36)への変換に利用する.
   *
   * 事前にx進数表現で使用される文字列の定義が必要.
   */
  private static convertExtendedBase(val: string, beforeBase: SupportedBase, afterBase: SupportedBase): string {
    const resultChars: string[] = []
    const characterList = BaseConverter.characterListMap[afterBase]

    let target = BigInt(val, beforeBase)

    if (target.isZero()) {
      return `${characterList[0]}`
    }

    // 進数変換
    while (target.gt(0)) {
      /**
       * javascriptは末尾再帰をサポートしておらず、再起を使うと文字列長次第でスタックオーバーフロー発生の恐れが有る.
       * そのため、今回はwhileによる実装を選択した.
       */
      const {
        quotient: div,
        remainder: mod,
      } = target.divmod(afterBase)

      /**
       * afterBaseとして使用される値は、SupportedBaseとして用意されている2~36,62の整数のみ.
       * 従って、modが取りうる値は0~61となり、toJSNumberでnumber型に変換しても問題ない.
       */
      const converted = characterList[mod.toJSNumber()]
      resultChars.unshift(converted)

      target = div
    }

    return resultChars.join('')
  }
}
