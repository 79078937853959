import _ from 'lodash'

import * as ImageConfig from '@/config/assets/image'
import ThumbnailUrl from '@/models//item/thumbnail-url'

/**
 * TODO: ThumbnailUrlを部分的に利用しているが、将来的には画像もDTOではなくドメイン層で管理
 * その時には、「商品」のドメインオブジェクトが直接ThumbnailUrlを集約の一部として保持する想定.
 */

export class ApiImage {
  constructor(private data: any) {}

  get id(): string {
    return this.get<string>('id', '')
  }

  get displayLevel(): number {
    return this.get<number>('display_level', 0)
  }

  get url(): string {
    return this.get<string>('url', ImageConfig.dummyImagePath)
  }

  get isGif(): boolean {
    return this.innerThumbnailUrl.isGif
  }

  get thumbnailUrl(): string {
    return this.innerThumbnailUrl.value
  }

  /**
   * TODO: 外部への情報提供はThumbnailUrlで統一し、文字列では返さない
   *
   * 対外的にもThumbnailUrlで統一したいが、それをするとIFの変更となり、
   * 影響範囲が広いので、一旦はIFを維持することを優先
   */
  private get innerThumbnailUrl(): ThumbnailUrl {
    return ThumbnailUrl.asSizeS(this.url)
  }

  get extraSmallUrl(): string {
    return this.get<string>('url_xs', ImageConfig.dummyImagePath)
  }

  get isRepresentativeImage(): boolean {
    return this.imageAppKind === ImageAppKind.Representative
  }
  get isInstaView(): boolean {
    return this.imageAppKind === ImageAppKind.Instagram
  }

  get isThumbnail(): boolean {
    return this.imageKindName === ImageKindName.Thumbnail
  }

  get isLayer(): boolean {
    return this.imageAppKind === ImageAppKind.Layer
  }

  get imageKind(): number {
    return this.get<number>('image_kind', 0)
  }

  get imageKindName(): string {
    return this.get('image_kind_name', '')
  }

  get imageAppKind(): string {
    return this.get<string>('image_app_kind', '0')
  }

  private get<T>(pathname: string, defaultVal: T): T {
    return _.get(this.data, pathname, defaultVal)
  }
}

export enum ImageKindName {
  Thumbnail = 'thumbnail',
}

export enum ImageAppKind {
  /**
   * レイヤー画像
   */
  Layer = '1001',
  /**
   * 代表画像
   */
  Representative = '1010',
  /**
   * モデ撮り
   */
  Model = '1011',
  /**
   * 静物撮影（ブツ撮り）
   */
  StillLife = '1012',
  /**
   * インスタ画像
   */
  Instagram = '1013',
  /**
   * レンズ画像
   */
  Lens = '1020',
  /**
   * 着画
   */
  Worn = '1021',
}
