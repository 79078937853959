import { ApiClient } from '@/services/api/client'

export interface BaseUrlRepository {
  load(): Promise<string>
}

export class S3BaseUrlRepository implements BaseUrlRepository {
  async load(): Promise<string> {
    return ApiClient.get('/api/common/resource/s3').then((res) => {
      const body: ResourceResponse = res.body

      return body.data.url
    })
  }
}

/**
 * 商品一覧等で、商品一件ごとにAPIコールが発火しないよう、
 * 一度リクエストが成功してURLを取得した場合、
 * その結果を保存して（キャッシュして）、二回目以降はAPIコールを省略する.
 */
export class CacheAvailableS3BaseUrlRepository extends S3BaseUrlRepository {
  private static _instance: CacheAvailableS3BaseUrlRepository | null = null
  static get instance(): CacheAvailableS3BaseUrlRepository {
    if (CacheAvailableS3BaseUrlRepository._instance === null) {
      CacheAvailableS3BaseUrlRepository._instance = new this()
    }

    return CacheAvailableS3BaseUrlRepository._instance
  }
  private process: Promise<string> | null = null

  async load(): Promise<string> {
    if (this.process === null) {
      this.process = super.load()
    }

    return this.process
  }
}

interface ResourceResponse {
  data: {
    url: string
  }
}
