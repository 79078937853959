import _ from 'lodash'

import { Page } from '../types'

export function getPage(pages: Page[], pageNumber: number): Page | null {
  return pages.find(page => page.pageNumber === pageNumber) || null
}

export function allItems(pages: Page[]) {
  return _.flatMap(pages, page => page.items)
}
