










import Vue from 'vue'
import { Component, Emit, Prop } from 'vue-property-decorator'
import { BundlePurchaseItem } from './model/bundle-purchase-item'
import { AddToPurchaseListSpec } from './model/specification/add-to-purchase-list'

@Component
export default class BundlePurchaseItemDetailAddToCartButton extends Vue {
  @Prop({ required: true })
  item: BundlePurchaseItem

  @Prop({ required: true })
  addToPurchaseListSpec: AddToPurchaseListSpec

  get canAddToPurchaseList(): boolean {
    return this.addToPurchaseListSpec.isSatisfiedBy(this.item)
  }

  handleClick() {
    if (this.canAddToPurchaseList) {
      this.onClick(this.item)
    }
  }

  get buttonClass(): string {
    return this.canAddToPurchaseList ? '' : 'disable'
  }

  @Emit('click')
  onClick(_item: BundlePurchaseItem) {
    // nothing to do
  }
}
