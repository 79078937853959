














import MemberPriceRow from '@/components/molecules/item/price/kind/paid-member-price/row/MemberPriceRow.vue'
import RegularPriceRow from '@/components/molecules/item/price/kind/paid-member-price/row/RegularPriceRow.vue'
import { PaidMemberPriceView } from '@/components/molecules/item/price/model/item-price-view'
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
    MemberPriceRow,
    RegularPriceRow,
  },
})
export default class PaidMemberPrice extends Vue {
  @Prop({ required: true })
  readonly state: PaidMemberPriceView
}
