









import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import { BrandRouteResolver } from '@/util/brandRouteResolver'

@Component
export default class ItemLink extends Vue {
  @Prop({ required: true })
  id: string

  @Prop({ required: true })
  color: string

  @Prop({ required: true })
  brandEnglishName: string

  get link(): string {
    return BrandRouteResolver.makeResourceEndpoint({
      brand: this.brandEnglishName,
      path: [
        'item',
        this.id,
      ],
      query: {
        color: this.color,
      },
    })
  }
}
