import { Either, Left, Right } from 'fp-ts/lib/Either'
import { Response } from 'superagent'

import { log } from '@/log'
import { ApiService } from '@/services/api/service'
import { ApiClient } from './client'

const namespace: string = 'tags'

export class TagsService extends ApiService {

  async get(
    pathname: string,
    scope: string | null
  ): Promise<Either<Error, Response>> {
    try {
      const response = await ApiClient
        .get(`/api/common-proxy/${this.appId}/tags`)
        .query({
          pathname,
          ...(
            scope ? { scope } : {}
          ),
        })

      log.debug({ service: `${namespace}/get`, response })

      return new Right(response)
    } catch (error) {
      log.error({ error })

      return new Left(error)
    }
  }
}
