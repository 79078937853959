









import Vue from 'vue'
import { Component } from 'vue-property-decorator'

import * as ImageConfig from '@/config/assets/image'

import ImageWithBadgeBand from '@/components/atoms/image/ImageWithBadgeBand.vue'

@Component({
  components: {
    ImageWithBadgeBand,
  },
})
export default class DummyItem extends Vue {
  get loadingImage(): string {
    return ImageConfig.itemLoadingImagePath
  }
}
