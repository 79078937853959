


























import { Either } from 'fp-ts/lib/Either'
import { Response } from 'superagent'
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import ShareFacebook from '@/components/molecules/share/ShareFacebook.vue'
import ShareLine from '@/components/molecules/share/ShareLine.vue'
import ShareTwitter from '@/components/molecules/share/ShareTwitter.vue'
import { CookieService } from '@/services/cookie'
import { InstanceResolver } from '@/util/instanceResolver'
import EventService from '@spa/services/api/event'

interface SnsShareConfig {
  twitter: string
  line: string
  hashtags: string[]
}

@Component({
  components: {
    ShareFacebook,
    ShareLine,
    ShareTwitter,
  },
})
export default class Share extends Vue {

  @Prop({ default: 'default' })
  brandName: string

  @Prop({ required: true })
  shareType: ShareType

  @Prop({ default: '' })
  url: string

  @Prop({ default: '' })
  resourceid: string

  eventService: EventService = new EventService()

  /**
   * TODO: behavior.json に移動. SNS毎にIF（要求パラメータ）が異なるのをどう対応するか（余分に渡す？）
   */
  configuration: { [brand: string]: SnsShareConfig } = {
    amiuuwink: {
      twitter: '吉田朱里（アカリン）がプロデュースするファッションブランド【Amiuu wink ～アミュ ウィンク～】',
      line: '吉田朱里（アカリン）がプロデュースするファッションブランド【Amiuu wink ～アミュ ウィンク～】',
      hashtags: ['amiuuwink', 'アミュウィンク', '吉田朱里', 'MyColor', 'マイカラー'],
    },
    gadgetgirl: {
      twitter: '毎日kawaiiをテーマにしたスマホグッズSTORE【Gadget♡Girl ～ガジェットガール～】スマホライフが楽しくなるアイテムが沢山♡',
      line: '毎日kawaiiをテーマにしたスマホグッズSTORE【Gadget♡Girl ～ガジェットガール～】スマホライフが楽しくなるアイテムが沢山♡',
      hashtags: ['GadgetGirl', 'ガジェットガール', 'MyColor', 'マイカラー'],
    },
    michellmacaron: {
      twitter: '八鍬里美がデザイナーを務めるファッションブランド【michell Macaron ～ミシェルマカロン～】流行りのストリートファッションをガーリーにしたブランドです♡',
      line: '八鍬里美がデザイナーを務めるファッションブランド【michell Macaron ～ミシェルマカロン～】流行りのストリートファッションをガーリーにしたブランドです♡',
      hashtags: ['michellmacaron', 'ミシェルマカロン', '八鍬里美'],
    },
    micoameri: {
      twitter: '舟山久美子（くみっきー）がデザイナーを務めるファッションブランド【MICOAMERI ～ミコアメリ～】少しオトナっぽく、でも気どりすぎないちょっとオトナのイメージで♡',
      line: '舟山久美子（くみっきー）がデザイナーを務めるファッションブランド【MICOAMERI ～ミコアメリ～】少しオトナっぽく、でも気どりすぎないちょっとオトナのイメージで♡',
      hashtags: ['MICOAMERI', 'ミコアメリ', 'MyColor', 'マイカラー'],
    },
    coel: {
      // tslint:disable-next-line:max-line-length
      twitter: 'モデルのヨンアがクリエイティブディレクターを務める【COEL】。「COELを通じて幸せを感じられること」をコンセプトに、心ときめくCOELらしいトレンドを取り入れつつ 着心地やシルエットを重視した長く愛される ベーシックアイテムを提案いたします。',
      // tslint:disable-next-line:max-line-length
      line: 'モデルのヨンアがクリエイティブディレクターを務める【COEL】。「COELを通じて幸せを感じられること」をコンセプトに、心ときめくCOELらしいトレンドを取り入れつつ 着心地やシルエットを重視した長く愛される ベーシックアイテムを提案いたします。',
      hashtags: ['COEL', 'コエル'],
    },
    default: {
      twitter: '',
      line: '',
      hashtags: [],
    },
  }

  get shareConfiguration() {
    const brandKey = InstanceResolver.isSingle() ? InstanceResolver.getSingleBrand() : this.brandName

    return this.configuration[brandKey] || this.configuration.default
  }

  async handleShareButtonClicked() {
    // Skip non-registered user
    if (!CookieService.isRegistered()) {
      return
    }

    if (this.shareType === 'page') {
      await this.sendSharePageEvent()

      return
    }

    if (this.shareType === 'item') {
      await this.sendShareItemEvent()

      return
    }
  }

  async sendSharePageEvent(): Promise<Either<Error, Response>> {
    return await this.eventService.sharePage({
      url: this.url,
    })
  }

  async sendShareItemEvent(): Promise<Either<Error, Response>> {
    return await this.eventService.shareItem({
      url: this.url,
      resourceid: this.resourceid,
    })
  }
}

type ShareType = 'page' | 'item'
