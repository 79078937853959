
















































import { FkuQuery } from '@/services/api/item/fku/fku-query'
import ProductclassService from '@/services/api/productclass'
import _ from 'lodash'
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import { log } from '@/log'
import { ApiProduct } from '@/models/api/product'
import BehaviorConfig from '@/models/app-config/behavior/behavior'
import * as SKUFactory from '@/models/item/sku/factory/sku'
import GetStockStatus from '@/usecase/get-stock-status'
import AppId from '@/util/appid'
import { BrandRouteResolver } from '@/util/brandRouteResolver'
import { SizeTag, SizeTagsService } from '@ajax/modules/services/tags/size'
import Util from '@ajax/modules/util'
import ColorStockItem from '@ajax/vue/components/organisms/ColorStockItem.vue'

@Component({
  components: {
    ColorStockItem,
  },
})
export default class ColorStock extends Vue {
  @Prop() id: string

  stocks: any[] = []
  open: boolean = false

  itemService: ProductclassService
  sizeTagsService: SizeTagsService

  toggle() {
    this.open = !this.open
  }

  get brand(): string {
    return BrandRouteResolver.resolveBrandFromPathElement(this.$route.params.brand_english_name)
  }

  get state(): string {
    return this.open ? 'fa-caret-up' : 'fa-caret-down'
  }

  async created() {
    const appId = AppId.getByBrandName(this.brand)
    this.itemService = new ProductclassService(appId)
    this.sizeTagsService = new SizeTagsService(this.brand, appId)
  }

  async mounted() {
    const productclass = await this.getProductclassSortedColors()

    if (productclass.isLeft()) {
      return
    }
    const sizeList = await this.sizeTagsService.getOrdered('asc')
    if (sizeList.isLeft()) {
      return
    }

    const sortedProductclass = this.sortedColors(productclass.value)
    this.renderFkuStock(sortedProductclass, sizeList.value)
  }

  async getProductclassSortedColors() {
    return await this.itemService.listFkuBelongingToRoot(this.id, FkuQuery.initialize({
      with: 'part,stock~detail',
    }).toObject())
  }

  sortedColors(productclass) {
    try {
      const selectedColor = Util.convertItemDetailUrlParamsToAssociativeArray().color

      return productclass.sort((before, after) => {
        const beforeColor = _.find(before.tags, tag => /^company\.product\.color\./.test(tag.pathname)).name
        const afterColor = _.find(after.tags, tag => /^company\.product\.color\./.test(tag.pathname)).name

        if (beforeColor === selectedColor) return 0
        if (afterColor === selectedColor) return 1

        return -1
      })

    } catch (error) {
      log.error(error)

      return []
    }
  }

  renderFkuStock(productClasses: any[], orderedSizeList: SizeTag[]) {
    try {
      this.stocks = productClasses.map((productclass) => {
        const color = _.find(productclass.tags, (tag) => {
          return /^company\.product\.color\./.test(tag.pathname)
        })

        const stock = productclass.parts
        .filter((rawPart) => {
          const part = new ApiProduct(rawPart)

          if (!part.status.isPublic) {
            return false
          }
          if (part.isPreSale) {
            return true
          }

          return part.isOnRegularSale
        })
        .map((part) => {
          const size = _.find(part.tags, (tag) => {
            return /^company\.product\.size\./.test(tag.pathname)
          })
          const status = this.displayStockLabel(part)

          return { label: size.label, status }
        }).sort((before, after) => {
          const beforeIndex = _.findIndex(orderedSizeList, size => size.title === before.label)
          const afterIndex = _.findIndex(orderedSizeList, size => size.title === after.label)

          return beforeIndex < afterIndex ? -1 : 1
        })

        return { color: color.label, stock }
      }).filter((stock) => {
        // 紐づくSKUが存在しないor全て非公開の時、対応するカラーも非表示にする
        return stock.stock.length > 0
      })

    } catch (error) {
      log.error(error)

      this.stocks = []
    }
  }

  displayStockLabel(raw: any): string {
    const apiProduct = new ApiProduct(raw)
    const sku = SKUFactory.create(apiProduct)
    const behavior = BehaviorConfig.createFromBrand(this.brand)

    const getStockStatus = GetStockStatus.createViaConfig(behavior)

    if (getStockStatus.isSoldOut(sku.stock)) {
      return '×'
    }
    if (getStockStatus.isFew(sku.stock)) {
      return '△'
    }

    return '○'
  }
}
