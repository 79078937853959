










import Vue from 'vue'

import _ from 'lodash'
import { Component, Emit, Prop } from 'vue-property-decorator'
import RatingStar from './RatingStar.vue'

@Component({
  components: {
    RatingStar,
  },
})
export default class RatingSelector extends Vue {
  @Prop({ required: true })
  value: number

  @Prop({ required: true })
  maxValue: number

  get range(): number[] {
    return _.range(1, this.maxValue + 1)
  }

  isSelected(index: number): boolean {
    return index <= this.value
  }

  @Emit()
  onClick(_n: number): void {
    // do nothing
  }
}
