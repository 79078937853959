










import Vue from 'vue'
import { Component } from 'vue-property-decorator'

import SearchKeywordForm from '@/components/organisms/search/SearchKeyword.vue'
import { BrandRouteResolver } from '@/util/brandRouteResolver'

@Component({
  components: {
    SearchKeywordForm,
  },
})
export default class SearchKeyword extends Vue {
  get brandEnglishName(): string {
    return BrandRouteResolver.resolveBrandFromPathElement(this.$route.params.brand_english_name)
  }
}
