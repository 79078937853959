import AppId from '@/util/appid'
import { DefaultEventSubscriptionFacade } from './default'
import { LedianEventSubscriptionFacade } from './ledian'

/**
 * 一定の粒度で、購読するサードパーティ用イベントの一覧を一括登録するためのI/F。
 */
export interface EventSubscriptionFacade {
  subscribeAll(): void
}

/**
 * イベント購読を一括登録するクラスを、一定のコンテキスト（ホスト等）にもとづいて生成するためのファクトリ。
 */
export class EventForThirdPartySubscriptionFacadeFactory {
  createForHost(host: string): EventSubscriptionFacade {
    switch (host) {
      case AppId.getByHostName('ledian'): return new LedianEventSubscriptionFacade()
      default: return new DefaultEventSubscriptionFacade()
    }
  }
}
