import { ApiProductclass } from '@/models/api/productclass'
import ProductclassService from '@/services/api/productclass'
import ContactLensRoot from './contact-lens'

export default class ContactLensRootRepository {
  constructor(private readonly appId: string) {}

  async fetch(id: string): Promise<ContactLensRoot> {
    /**
     * ContactLensRoot作成のために、withパラメータは現状必要ないので付けていない。
     * この前提が変わる場合、適切なwithパラメータを作るか、あるいはBFF的に専用のAPIを設けたい。
     */
    const result = await new ProductclassService(this.appId).getRootItem(id)

    if (result.isLeft()) {
      throw result.value
    }

    return ContactLensRoot.create(new ApiProductclass(result.value))
  }
}
