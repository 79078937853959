




























































import _ from 'lodash'
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import BaseConditionModal from '@ajax/vue/components/organisms/condition/BaseConditionModal.vue'

@Component({
  components: {
    BaseConditionModal,
  },
})
export default class ConditionChild extends Vue {
  @Prop() prefix: string

  @Prop() model: any

  @Prop() data: any

  diffChecked: any[] = []
  radioChecked: any | null = null
  checked: boolean = false
  isLoading: boolean = true

  /**
   * change radio
   * @param {item} radio itself
   */
  changeRadio(item: any): void {

    // if click same radio, remove checked
    if (this.radioChecked && this.radioChecked.id === item.id) {
      this.radioChecked = null
    } else {
      this.radioChecked = item
    }
  }

  isRadioChecked(item: any): boolean {

    if (!this.radioChecked || !item) return false

    return this.radioChecked.id === item.id
  }

  /**
   * change checked or not with children and parent
   * @param {isChecked} check value
   * @param {item} checkbox itself
   */
  changeCheck(isChecked: boolean, item: any): void {
    if (isChecked) {
      this.diffAdd(item)
    } else {
      this.diffRemove(item)
    }
    // 親アイテムがチェックされていたら全子アイテムのチェックを外す
    const changeChildren = (checkbox: any): void => {
      if (checkbox.children) {
        checkbox.children.forEach((child) => {
          if (isChecked) {
            this.diffRemove(child)
          }
          changeChildren(child)
        })
      }
    }
    if (item.children) {
      changeChildren(item)
    }
    // 子アイテムがチェックされていたら親アイテムのチェックを外す
    const changeWithParent = (checkbox) => {
      const parent = this.getParentItem(checkbox)
      if (parent) {
        if (isChecked) {
          this.diffRemove(parent)
        }
        changeWithParent(parent)
      }
    }
    changeWithParent(item)
  }

  /**
   * get nearest parent
   * @param {childItem}
   */
  getParentItem(childItem: any): any {
    // there is no way to get parent item directory so get All item from parent vue
    const allItemList = this.data.list[this.prefix]
    // get nearest parent
    let nearestParent: any
    const getNearestParent = (item, target) => {
      if (target.id === item.id) {
        return true
      }
      if (item.children) {
        item.children.some((child) => {
          if (getNearestParent(child, target)) {
            nearestParent = item

            return true
          }
        })
      }

      return false
    }
    allItemList.some((item) => {
      if (getNearestParent(item, childItem)) {
        return true
      }
    })

    return nearestParent
  }

  /**
   * all children checked or not
   * @param {item}
   * @return {boolean}
   */
  isAllCheckedInChildren(item: any): boolean {
    let isAllChecked: boolean = true
    if (item.children) {
      item.children.some((child) => {
        let checked = false
        this.diffChecked.some((diffItem) => {
          if (child.id === diffItem.id) {
            checked = true

            return true
          }

          return false
        })
        if (!checked) {
          isAllChecked = false

          return true
        }
      })
    }

    return isAllChecked
  }

  /**
   * add checked item for parent vue
   * @param {addItem}
   */
  diffAdd(addItem: any): void {
    if (!_.some(this.diffChecked, { id: addItem.id })) {
      this.diffChecked.push(addItem)
    }
  }

  /**
   * remove checked item for parent vue
   * @param {removeItem}
   */
  diffRemove(removeItem: any): void {
    this.diffChecked.forEach((item, index) => {
      if (removeItem.id === item.id) this.diffChecked.splice(index, 1)
    })
  }

  mounted() {
    this.$nextTick().then(() => {

      if (this.data.isExclusive[this.prefix]) {
        this.radioChecked = this.data.checked[this.prefix]
      } else {
        this.diffChecked = this.data.checked[this.prefix]
        // init checked for children
        this.diffChecked.forEach((item) => {
          this.changeCheck(true, item)
        })
      }

      this.isLoading = false
    })
  }

  close() {
    this.$emit('child-close', this.prefix)
  }

  ok() {

    if (this.data.isExclusive[this.prefix]) {
      this.$emit('child-bind', this.prefix, this.radioChecked)
    } else {
      this.diffChecked = _.uniq(this.diffChecked)
      this.$emit('child-bind', this.prefix, this.diffChecked)
    }

    this.close()
  }
}
