import Vue from 'vue'
import { Mixin } from 'vue-mixin-decorator'

import { InstanceResolver } from '@/util/instanceResolver'

export type HostPagePath = 'about' | 'rule' | 'legal' | 'size' | 'faq'

/**
 * TODO: Mixinではなく、これ自体コンポーネントにして良い気がする
 */
@Mixin
export default class HostPageLink extends Vue {

  /**
   * hostPageリンク用のパスを返却します。
   *
   * @param {string} path
   * @return {string} path
   */
  getHostPagePath(path: HostPagePath) {
    if (InstanceResolver.isSingle()) {
      return `/${path}`
    }

    /**
     * mycolor固定。理由は↓参照
     * @see https://github.com/my-color/front/issues/4361#issuecomment-685607810
     */
    return `/mycolor/${path}`
  }
}
