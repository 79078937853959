
export default class Status {
  static readonly STATUS_PUBLIC = 1

  public constructor(private statusId: number) { }

  get isPublic(): boolean {
    return this.statusId === Status.STATUS_PUBLIC
  }
}
