import { ApiProduct } from '@/models/api/product'
import BehaviorConfig from '@/models/app-config/behavior/behavior'
import * as SKUFactory from '@/models/item/sku/factory/sku'
import GetStockStatus from '@/usecase/get-stock-status'
import EachStateSpec from './each-state-spec'

/**
 * https://docs.google.com/spreadsheets/d/1ZvWEL9Q9FGgB24JKAjD8roi75KEHoT6uv7xMPhITDZI/edit#gid=2052072130
 */
export default class CanRequestRestockSpec implements EachStateSpec {
  constructor(private behavior: BehaviorConfig) { }

  verify(product: ApiProduct): boolean {
    if (product.isDeleted) {
      return product.isOnRegularSale && product.acceptRestockRequest
    }

    const sku = SKUFactory.create(product)
    const skuIsSoldOut = this.getStockStatus.isSoldOut(sku.stock)

    return skuIsSoldOut && product.isOnRegularSale && product.acceptRestockRequest
  }

  /**
   * TODO: UseCaseではなく、在庫のマッピング仕様オブジェクトを利用する
   */
  private get getStockStatus(): GetStockStatus {
    return GetStockStatus.createViaConfig(this.behavior)
  }
}
