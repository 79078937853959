import _ from 'lodash'

export class ApiCreditcard {
  protected _data: any

  public constructor(apiData: any) {
    this._data = apiData
  }

  get id(): string {
    return this.get('id')
  }

  get numberLast4(): string {
    return `${this.get('number_last4')}`
  }

  get cardType(): string {
    return this.get('cardtype')
  }

  get name(): string | null {
    return this.get('name', null)
  }

  get lastCreditDate(): string | undefined {
    return this.get('extension.lastCreditDate')
  }

  clone(): ApiCreditcard {
    return new ApiCreditcard(this._data)
  }

  withSecurityCode(securityCode: string): ApiCreditcardWithSecurityCode {
    return Object.assign(this.clone(), {
      securityCode,
    })
  }

  private get(path: string, defaultValue?: any) {
    return _.get(this._data, path, defaultValue)
  }
}

export interface ApiCreditcardWithSecurityCode extends ApiCreditcard {
  readonly securityCode: string
}
