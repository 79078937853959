
/**
 * TODO: 商品一般で使えるかも？一旦は「まとめ買い」専用の前提で実装.
 */
export class ColorBasedItemList<T extends WithColor> {
  constructor(readonly items: T[]) { }

  orderBy(comparator: (a: T, b: T) => number) {
    return new ColorBasedItemList<T>(
      this.items
        .concat() // shallow copy
        .sort(comparator)
    )
  }
  moveToTopByColor(color: Color): ColorBasedItemList<T> {
    const matched = this.items.filter(i => i.color === color)
    const other = this.items.filter(i => i.color !== color)

    return new ColorBasedItemList<T>([
      ...matched,
      ...other,
    ])
  }
}

export type Color = string
export interface WithColor {
  readonly color: Color
}
