import { Either, Left, Right } from 'fp-ts/lib/Either'
import { None, Option, Some } from 'fp-ts/lib/Option'

import Variable from '@/models/api/payment/parameter/type/variable'
import { getFullName, getPhoneticFullName } from '@spa/store/modules/user/functions'
import { User } from '@spa/store/modules/user/types'

/**
 * @see https://my-color.esa.io/posts/534
 */
export default interface VariableResolver {
  resolve(variable: Variable): Either<Error, Option<string>>
}

export interface ResolverResource {
  user?: User,
  orderDisplayId?: string,
}

export class DefaultResolver implements VariableResolver {

  constructor(
    private readonly resource: ResolverResource
  ) {}

  resolve(variable: Variable): Either<Error, Option<string>> {
    const value = this.value(variable.variableId)

    if (variable.required && value.isNone()) {
      return new Left(new RequiredValueNotFoundError(`Value of '${variable.variableId}' is not found.`))
    }

    return new Right(value)
  }

  private value(variableId: string): Option<string> {
    switch (variableId) {
      case 'order_display_id':
        return this.orderDisplayId
      case 'user_email':
        return this.userEmail
      case 'user_full_name':
        return this.userFullName
      case 'user_phonetic_full_name':
        return this.userPhoneticFullName
      case 'user_tel':
        return this.userTel
      default:
        return None.value
    }
  }

  private get orderDisplayId(): Option<string> {
    return this.resource.orderDisplayId ?
      new Some(this.resource.orderDisplayId) :
      None.value
  }

  private get user(): User | null {
    return this.resource.user || null
  }

  private get userEmail(): Option<string> {
    if (!this.user) {
      return None.value
    }

    return this.user.email ?
      new Some(this.user.email) :
      None.value
  }

  private get userFullName(): Option<string> {
    if (!this.user) {
      return None.value
    }

    return new Some(getFullName(this.user))
  }

  private get userPhoneticFullName(): Option<string> {
    if (!this.user) {
      return None.value
    }

    return new Some(getPhoneticFullName(this.user))
  }

  private get userTel(): Option<string> {
    if (!this.user) {
      return None.value
    }

    return this.user.tel ?
      new Some(this.user.tel) :
      None.value
  }
}

export class RequiredValueNotFoundError extends Error {}
