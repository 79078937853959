










import Vue from 'vue'
import { Component, Emit, Prop } from 'vue-property-decorator'

@Component
export default class Mailto extends Vue {
  @Prop({ required: true })
  recipient: string

  @Prop({ default: '' })
  subject: string

  @Emit('click')
  onClick() {
    // nothing to do
  }

  get mailtoUrl(): string {
    return `mailto:${this.recipient}?subject=${this.subject}`
  }
}
