import * as Parallel from 'async-parallel'
import { Either, Left, Right } from 'fp-ts/lib/Either'
import _ from 'lodash'
import { Response } from 'superagent'

import { log } from '@/log'
import { ApiService } from '@/services/api/service'
import { ApiClient } from './client'
import { Estimate } from './purchase'

const namespace: string = 'orderHistory'

export interface OrderHistory {
  id: string
  shortid: string
  item: SalesOrderItem[]
  tax: number
  totalPrice: number
  charge_sheet: Estimate
}

export interface SalesOrderItem {
  id: string
  cart_id: string
}

/**
 * TODO: FIXME: remove "any"
 */
export interface OrderHistoryJoinedWithProductclass {
  id: string
  skuId: string
  sku: any
  fkuId: string
  fku: any
  root: any
}

export class OrderHistoryApiService extends ApiService {

  /**
   * get commerce_sales_order list
   */
  async list(query?: any): Promise<Either<Error, Response>> {
    try {
      const response = await ApiClient
        .get(`/api/user-proxy/${this.appId}/commerce_sales_order`)
        .query({
          ...query,
          with: 'sales_status,part,salesorder_detail,salesorder_extension,commerce_event_history,aux',
          // 有料会員費徴収の履歴を除外する
          // @see https://my-color.esa.io/posts/745#%E6%B3%A8%E6%96%87%E5%B1%A5%E6%AD%B4
          sales_subscription_id__query: 'is-null',
        })

      log.debug({ service: `${namespace}/commerce_sales_order`, response })

      return new Right(response)
    } catch (error) {
      return new Left(error)
    }
  }

  /**
   * get commerce_sales_order detail
   */
  async get(
    salesOrderId: string
  ): Promise<Either<Error, Response>> {
    try {
      const response = await ApiClient
        .get(`/api/user-proxy/${this.appId}/commerce_sales_order/${salesOrderId}`)
        .query({
          with: 'sales_status,part,salesorder_detail,salesorder_extension,commerce_event_history',
          // 有料会員費徴収の履歴を除外する
          // @see https://my-color.esa.io/posts/745#%E6%B3%A8%E6%96%87%E5%B1%A5%E6%AD%B4
          sales_subscription_id__query: 'is-null',
        })

      log.debug({
        service: `${namespace}/commerce_sales_order/${salesOrderId}`,
        response,
      })

      return new Right(response)
    } catch (error) {
      return new Left(error)
    }
  }

  // TODO: Throttle simultaneous API requests number
  // TODO: Refactoring
  async join(
    items: any[]
  ): Promise<OrderHistoryJoinedWithProductclass[]> {
    const list: any[] = _.map(items, (item) => {
      return {
        id: item.id,
        skuId: item.product_id,
        fkuId: item.product_class_id,
      }
    })

    // Get Products
    const skuList: any[] = _.map(list, (entry) => {
      return {
        id: entry.id,
        sku: items,
      }
    })

    // Get FKU
    const fkuList: any[] = await Parallel.map(list, async (entry) => {

      try {
        const response = await ApiClient
          .get(`/api/user-proxy/${this.appId}/product2/${entry.skuId}`)
          .query({
            with: 'whole,belonging_to',
          })

        const data = response.body.data

        const rootInfo = data.whole.belonging_to[0]

        // TODO: Check FKU tag
        return {
          id: entry.id,
          sku: data,
          fku: data.whole,
          root: rootInfo,
        }

      } catch (error) {
        log.debug(error)
      }

    })

    return _.merge(list, skuList, fkuList)
  }

  async cancel(id: string, code: CancelCode, note: string, noteOption?: string)
  : Promise<Either<Error, Response>> {
    try {
      const content = {
        cancel_code: code,
        note,
        ...((noteOption && code === CancelCode.OTHER) &&  { note_option: noteOption }),
      }

      const params = {
        sales_order_id: id,
        command: 'cancel',
        content: JSON.stringify(content),
      }

      const response = await ApiClient
        .post(`/api/user-proxy/${this.appId}/seller_change_status`)
        .send(params)

      log.debug({ service: `${namespace}/seller_change_status`, response })

      return new Right(response)
    } catch (error) {
      return new Left(error)
    }
  }

  checkCancelCodeRequiresNoteOption(code: CancelCode): boolean {
    return code === CancelCode.OTHER
  }
}

export enum CancelCode {
  DUPLICATE = 'duplicate',
  RETRY = 'retry',
  OPERATION_ERROR = 'operation-error',
  ORDER_MISTAKEN = 'order-mistaken',
  OTHER = 'other',
}
