import Cookies from 'js-cookie'
import _ from 'lodash'

export class CookieService {

  /**
   * ログイン状態かどうかを判定します
   * @return {boolean}
   */
  public static isLogined(): boolean {
    const accessToken = Cookies.get('a')

    return !(typeof accessToken === 'undefined' || accessToken === null)
  }

  /**
   * 会員種別が本登録かどうかを返します
   * @return {boolean}
   */
  public static isRegistered(): boolean {
    const userStatus = Cookies.get('s')

    return userStatus === 'registered'
  }

  /**
   * 会員種別が仮登録かどうかを返します
   * @return {boolean}
   */
  public static isProvisional(): boolean {
    const userStatus = Cookies.get('s')

    return !this.isGuest() && (userStatus === 'provisional' || !userStatus)
  }

  /**
   * 非会員かどうかを返します
   * @return {boolean}
   */
  public static isGuest(): boolean {
    const accessToken = Cookies.get('a')

    return typeof accessToken === 'undefined' || accessToken === null
  }

  /**
   * アクセストークンの有無を返します。
   * @return boolean
   */
  public static hasAccessToken(): boolean {
    return  !_.isEmpty(Cookies.get('a'))
  }

  public static hasUserId(): boolean {
    return !!this.getUserId()
  }
  public static getUserId(): string | undefined {
    return Cookies.get('u')
  }

  public static setWebCustomerServiceUserAttribute(value: string, expire: number): void {
    Cookies.set('w', value, { expires: expire })
  }

  public static getWebCustomerServiceUserAttribute(): string | undefined {
    return Cookies.get('w')
  }

  /**
   * @return Preview timetravel time, as uri decoded value
   */
  public static get timeTravelTime(): string {
    const cookie = Cookies.get('t')

    if (!cookie) {
      return ''
    }

    return decodeURIComponent(cookie)
  }
}
