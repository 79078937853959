import { render, staticRenderFns } from "./ErrorModal.vue?vue&type=template&id=606d70b6&scoped=true&"
import script from "./ErrorModal.vue?vue&type=script&lang=ts&"
export * from "./ErrorModal.vue?vue&type=script&lang=ts&"
import style0 from "./ErrorModal.vue?vue&type=style&index=0&id=606d70b6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "606d70b6",
  null
  
)

export default component.exports