import Stock from '@/models/item/stock'
import DisplayRule from '@/models/item/stock/specification/display-rule'
import StockIsFew from '@/models/item/stock/specification/stock-is-few'

export default class DisplayStockIsFew implements DisplayRule {
  constructor(private readonly spec: StockIsFew) { }

  shouldApply(stock: Stock): boolean {
    if (stock.display.isDisable) {
      return false
    }

    return this.spec.verify(stock.display)
  }
  apply(_: Stock): string {
    return `残りわずか`
  }
}
