import { Either, Left, Right } from 'fp-ts/lib/Either'
import _ from 'lodash'
import Parameter, { CreateParameterError, ParameterType } from '../parameter'

/**
 * @see https://my-color.esa.io/posts/534
 */
export default class Constant implements Parameter {

  static createFromApiResponse(data: any): Either<Error, Constant> {
    const required: string[] = ['id', 'value']
    const missedKeys = _.compact(required.map((key) => {
      return data[key] === undefined ? key : null
    }))

    if (missedKeys.length > 0) {
      return new Left(
        new CreateParameterError(`Error on creating Constant: ${missedKeys.join(', ')} is required.`)
      )
    }

    return new Right(new Constant(data.id, data.value))
  }

  private constructor(
    public readonly id: string,
    public readonly value: string
  ) {}

  get type(): ParameterType {
    return 'constant'
  }
}

export const isConstant = (parameter: Parameter): parameter is Constant => {
  return parameter.type === 'constant'
}
