import { CreditcardPaymentProviderViewConfig } from '@/services/payment/creditcard-payment-provider/creditcard-payment-provider-service'
import { AuthDiv, authDivOnPayment, authDivOnRegisterCard } from '@/services/payment/creditcard-payment-provider/provider/kuroneko'

// 現状NotUseSecureかUseSecurityCodeのみ利用可能な実装となっている
// いちおう可変だが、滅多に変わらない想定
// 頻繁に変わるようならconfigから読み取るように修正したほうがよい

const requireSecurityCode = (authDiv: AuthDiv): boolean =>
  [
    AuthDiv.UseSecurityCode,
    AuthDiv.Use3DSecureAndSecurityCode,
  ].some(c => c === authDiv)

export const kuronekoViewConfig: CreditcardPaymentProviderViewConfig = {
  registerCardForm: {
    requireCardOwner: true,
    requireSecurityCode: requireSecurityCode(authDivOnRegisterCard),
  },
  paymentForm: {
    requireSecurityCode: requireSecurityCode(authDivOnPayment),
  },
}
