
























import BehaviorConfig from '@/models/app-config/behavior/behavior'
import ContactLensFKURepository from '@/models/item/fku/repository'
import ContactLensRootRepository from '@/models/item/root/repository'
import AppId from '@/util/appid'
import { BrandRouteResolver } from '@/util/brandRouteResolver'
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import ContactLensItemDetailStore from './store'
import ContactLensItemDetailStoreBuilder from './store-builder'

@Component
export default class ContactLensQuantityDropdown extends Vue {
  @Prop({ required: true })
  id: string

  store: ContactLensItemDetailStore | null = null

  async created() {
    try {
      const brandName = BrandRouteResolver.resolveBrandFromPathElement(this.$route.params.brand_english_name)
      const appId = AppId.getByBrandName(brandName)
      const storeBuilder = ContactLensItemDetailStoreBuilder.getInstance(
        new ContactLensRootRepository(appId),
        new ContactLensFKURepository(appId)
      )
      this.store = await storeBuilder.build(
        this.id,
        BehaviorConfig.createFromBrand(brandName)
      )
    } catch (error) {
      console.error(error)
    }
  }

  get selectedQuantity(): number {
    return this.store ? this.store.selectedQuantity : 0
  }

  get maxSelectableQuantity(): number {
    return this.store ? this.store.maxSelectableQuantity : 0
  }

  onChange($event): void {
    if (this.store) {
      this.store.selectQuantity($event.target.value)
    }
  }
}
