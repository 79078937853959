





























import _ from 'lodash'
import scrollToElement from 'scroll-to-element'
import Vue from 'vue'
import { ObserveVisibility } from 'vue-observe-visibility'
import { Component, Emit, Prop } from 'vue-property-decorator'

import { PageCollection } from '@/models/page'
import ItemListContainer from './Container.vue'

Vue.directive('observe-visibility', ObserveVisibility)

@Component({
  components: {
    ItemListContainer,
  },
})
export default class PaginatedItemListContainer<T> extends Vue {
  @Prop({ default: 'div' })
  tag: string

  @Prop({ required: true })
  pages: PageCollection<T>

  @Prop({ required: true })
  currentPage: number

  @Prop({ required: true })
  pageToJump: number

  visiblePages: VisiblePages = new VisiblePages([])

  makePageRef(page: number): string {
    return `page-${page}`
  }

  mounted() {
    this.jump()
  }

  jump() {
    if (this.pageToJump === 1) {
      return
    }

    const ref = this.$refs[this.makePageRef(this.pageToJump)]

    const pageElement = _.isArray(ref) ? ref[0] : ref

    if (!pageElement) {
      return
    }

    const el = (pageElement as Vue).$el

    scrollToElement(el, {
      offset: 100,
    })

    this.afterJump()
  }

  onPageVisibilityChanged(number: number) {
    const callback = (isVisible: boolean, _entry: IntersectionObserverEntry) => {
      if (isVisible) {
        this.visiblePages.add(number)
      } else {
        this.visiblePages.remove(number)
      }

      const latest = this.visiblePages.latest

      if (this.currentPage === latest) {
        return
      }

      this.visiblePageChange(latest)
    }

    return {
      callback,
    }
  }

  @Emit()
  visiblePageChange(_number: number) {
    // Do nothing
  }

  @Emit()
  afterJump() {
    // Do nothing
  }
}

class VisiblePages {
  constructor(private pages: number[]) {}

  get value(): number[] {
    return this.pages
  }

  get latest(): number {
    const latest = Math.max(...this.pages)

    // TODO: Rather delete page query from url, instead of setting 1
    return latest > 0 ? latest : 1
  }

  add(page: number): void {
    if (this.pages.find(p => p === page)) {
      return
    }

    this.pages = [...this.pages, page]
  }

  remove(page: number): void {
    if (!this.pages.find(p => p === page)) {
      return
    }

    this.pages = _.without(this.pages, page)
  }

}
