



















import BehaviorConfig from '@/models/app-config/behavior/behavior'
import ContactLensFKU from '@/models/item/fku/contact-lens'
import ContactLensFKURepository from '@/models/item/fku/repository'
import ContactLensRootRepository from '@/models/item/root/repository'
import AppId from '@/util/appid'
import { BrandRouteResolver } from '@/util/brandRouteResolver'
import { log } from '@spa/log'
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import ContactLensItemDetailStore from './store'
import ContactLensItemDetailStoreBuilder from './store-builder'

@Component
export default class ContactLensColorDropdown extends Vue {
  @Prop({ required: true })
  id: string

  @Prop({ default: null })
  initialSelectedColorName: string | null

  store: ContactLensItemDetailStore | null = null

  async created() {
    try {
      const brandName = BrandRouteResolver.resolveBrandFromPathElement(this.$route.params.brand_english_name)
      const appId = AppId.getByBrandName(brandName)
      const storeBuilder = ContactLensItemDetailStoreBuilder.getInstance(
        new ContactLensRootRepository(appId),
        new ContactLensFKURepository(appId)
      )
      this.store = await storeBuilder.build(
        this.id,
        BehaviorConfig.createFromBrand(brandName)
      )
      this.selectInitialFku()
    } catch (error) {
      console.error(error)
    }
  }

  get selectedFkuShortId(): string {
    if (this.store && this.store.selectedFku) {
      return this.store.selectedFku.shortId
    }

    return this.fkus[0] ? this.fkus[0].shortId : ''
  }

  get fkus(): ContactLensFKU[] {
    return this.store ? this.store.fkus : []
  }

  onChange($event): void {
    if (!this.store) {
      log.error('this.store is null')

      return
    }

    const selectedFku = this.fkus.find(fku => fku.shortId === $event.target.value)

    if (!selectedFku) {
      log.error(`fku with shortId ${$event.target.value} not found from this.fkus`)

      return
    }

    this.select(selectedFku)
  }

  /**
   * URLクエリで指定された色のFKUが最初から選択された状態にするために実装された。
   * 本来ではこういったstoreの初期化に関わる知識が各々のコンポーネントに記述されるのは望ましくない。
   * storeの生成手続きとstoreに依存するコンポーネント全体の再設計が必要になるため、
   * ひとまずFKUを選択するコンポーネントにFKUの初期化の処理が実装されている、といった立て付けである。
   * @see https://github.com/my-color/front/pull/5439#pullrequestreview-697812779
   */
  private selectInitialFku() {
    if (!this.initialSelectedColorName) {
      return
    }

    const selected = this.fkus.find(fku => fku.colorName === this.initialSelectedColorName)
    if (!selected) {
      log.warn(`The query string specifies color ${this.initialSelectedColorName}, but such FKU is not found.`)

      return
    }

    this.select(selected)
  }

  private select(selected: ContactLensFKU): void {
    if (this.store) {
      this.store.selectFku(selected)
    }
  }
}
