import { DeliveryContact, Payment } from '@/models/api/commerce-sales-order'

// tslint:disable:max-line-length
/**
 * 定期購入履歴のDTO.
 *
 * 初期実装時点(at 2021/06/09)ではEngineリソースそのまま.
 * 今後、proxy経由ではなく独自APIへ置換されたら、それに応じて変更.
 *
 * @see https://my-color.esa.io/posts/589#%E5%AE%9A%E6%9C%9F%E8%B3%BC%E5%85%A5%E3%81%AE%E6%83%85%E5%A0%B1%E3%82%92%E5%8F%96%E5%BE%97
 */
// tslint:enable:max-line-length
export class LegacyEngineSubscriptionHistory {
  static buildFromObject(raw: object): LegacyEngineSubscriptionHistory {
    return new LegacyEngineSubscriptionHistory(raw)
  }

  private constructor(private readonly raw: any) {}

  get id(): string {
    return this.raw.id
  }
  get shortId(): string {
    return this.raw.shortid
  }

  get payment(): Pick<Payment, 'method'> {
    return JSON.parse(this.raw.payment)
  }

  get delivery(): Delivery {
    return JSON.parse(this.raw.delivery)
  }

  get totalPrice(): number {
    return this.raw.totalPrice
  }

  get tax(): number {
    return this.raw.tax
  }
}

export interface Delivery {
  kind: string
  contact: Required<DeliveryContact>
  contact_id: string
  deliveryMethod: string
}
