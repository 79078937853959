import { ApiProduct } from '@/models/api/product'
import BehaviorConfig from '@/models/app-config/behavior/behavior'
import RootState from '@/models/item/root/state'
import CanRequestNewItemSpec from '@/models/item/root/state/specification/each/can-request-new-item-spec'
import EachStateSpec from '@/models/item/root/state/specification/each/each-state-spec'
import CanPurchaseSpec from './each/can-purchase-spec'
import CanRequestRestockSpec from './each/can-request-restock-spec'
import WaitingArrivalSpec from './each/waiting-arrival-spec'

export default class RootStateSpec {
  static createViaConfig(behavior: BehaviorConfig): RootStateSpec {
    return new RootStateSpec([
      new CanPurchaseSpec(behavior),
      new CanRequestNewItemSpec(),
      new CanRequestRestockSpec(behavior),
      new WaitingArrivalSpec(),
    ])
  }

  constructor(
    private readonly specList: EachStateSpec[]
  ) { }

  select(products: ApiProduct[]): RootState {
    const spec = this.specList.find(s => s.verify(products)) || null

    if (spec === null) {
      return RootState.SoldOut
    }

    return spec.state
  }
}
