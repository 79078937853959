import { LoDashStatic } from 'lodash'
import { Validator } from 'vee-validate'
import Vue from 'vue'
import { Mixin } from 'vue-mixin-decorator'
import Router from 'vue-router'
import { Store } from 'vuex'

import { RootState } from '../store/types'

@Mixin
export class PluginMixin extends Vue {
  $router: Router
  $store: Store<RootState>
  $validator: Validator
  _: LoDashStatic
}
