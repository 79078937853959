import { AuthErrorEvent, nop } from './events'

import Vue, { VueConstructor } from 'vue'
import { Mixin } from 'vue-mixin-decorator'

const emptyErrorModalContent = Vue.extend({
  name: 'EmptyErrorModalContent',
  template: '<div></div>',
})

interface ErrorModalState {
  shown: boolean
  content: VueConstructor
  onClose: () => void
}
const initialState: ErrorModalState = {
  shown: false,
  content: emptyErrorModalContent,
  onClose: nop,
}

@Mixin
export class WithErrorModal extends Vue {
  errorModalState: ErrorModalState = initialState

  closeModal() {
    this.errorModalState.onClose()

    this.errorModalState = initialState
  }

  onError({ content, onCloseModal }: AuthErrorEvent) {
    this.errorModalState = {
      shown: true,
      onClose: onCloseModal || nop,
      content: typeof content === 'string'
               ? Vue.extend({
                 name: 'StringErrorContent',
                 template: `<div>${content}</div>`,
               })
               : content,
    }
  }
}
