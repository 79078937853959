


















import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component({
  model: {
    prop: 'checked',
    event: 'change',
  },
})
export default class Checkbox extends Vue {
  @Prop({ required: true })
  id: string

  @Prop() value

  @Prop({ default: false }) checked: boolean

  @Prop() disabled: boolean

  updateValue() {
    this.$emit('change', this.value)
    this.$emit('checked', this.value)
  }
}
