import _ from 'lodash'
import { Module } from 'vuex'

import { RootState } from '../../types'
import { actions } from './actions'
import { getters } from './getters'
import { mutations } from './mutations'
import { initialOrderHistoryState, OrderHistoryState } from './types'

export const orderHistory: Module<OrderHistoryState, RootState> =  {
  namespaced: true,
  state: _.cloneDeep(initialOrderHistoryState),
  actions,
  getters,
  mutations,
}
