














import { Mixins } from 'vue-mixin-decorator'
import { Component } from 'vue-property-decorator'

import BehaviorConfig from '@/models/app-config/behavior/behavior'
import AppId from '@/util/appid'
import { BrandRouteResolver } from '@/util/brandRouteResolver'
import { InstanceResolver } from '@/util/instanceResolver'
import ApiMixin from '@ajax/vue/mixin/Api'
import { TagsService } from '@spa/services/api/tags'

/**
 * TODO:
 * spa/ と ajax/ が統合されたら、
 * 正しい型定義を参照させる
 */
interface Genre {
  id: string
  name: string
  status_id: number
}

interface GenreItem extends Genre {
  icon?: string
}

@Component
export default class GenreMenu extends Mixins<ApiMixin>(ApiMixin) {
  genres: GenreItem[] = []

  tagsService = new TagsService()

  mounted() {
    Promise.resolve().then(() => {
      return this.fetchFirstGenres()
    }).then((response) => {
      if (response.isLeft()) {
        return []
      }

      return this.onlyPublic(response.value)
    }).then((genres) => {
      this.genres = genres
    })
  }

  get isBrandPage(): boolean {
    return !!this.brandEnglishName
  }

  get linkToItemlist(): string {
    if (InstanceResolver.isSingle()) {
      return '/itemlist'
    }

    return `/${this.brandEnglishName}/itemlist`
  }

  get brandEnglishName(): string {
    return BrandRouteResolver.resolveBrandFromPathElement(this.$route.params.brand_english_name)
  }

  get appId(): string {
    return AppId.getByBrandName(this.brandEnglishName)
  }

  linkUrl(genre: Genre): string {
    if (!this.brandEnglishName) {
      return `/itemlist?genre=${genre.name}`
    }

    return `/${this.brandEnglishName}/itemlist?genre=${genre.name}`
  }

  get genrePathname(): string {
    return 'mycolor.genre'
  }

  get behaviorConfig(): BehaviorConfig {
    return BehaviorConfig.createFromBrand(this.brandEnglishName)
  }

  showSideBar(): boolean {
    return this.behaviorConfig.isGenreShown
  }

  private async fetchFirstGenres() {
    return this.tagsService.get('mycolor.genre')
  }

  private onlyPublic(genres: Genre[]) {
    return genres.filter(genre => genre.status_id === 1)
  }
}
