
interface ScriptLoaderParam {
  /**
   * Script path
   */
  src: string,
  /**
   * Variable name in global level into which the script is loaded
   */
  global: string,
}

/**
 * This class aims to load a script by specified path.
 */
export class ScriptLoader<T> {
  public static getInstanceFor<T>(param: ScriptLoaderParam): ScriptLoader<T> {
    const created = this.objectMap.get(param.src)
    if (created) {
      return created as ScriptLoader<T>
    }

    const newObj = new ScriptLoader(param)
    this.objectMap.set(param.src, newObj)

    return newObj as ScriptLoader<T>
  }
  private static readonly objectMap: Map<string, ScriptLoader<unknown>> = new Map()

  readonly src: string = ''
  readonly global: string = ''
  private loadingProcess: Promise<T> | null = null

  /**
   * @deprecated 同じsrcに対しては同一のオブジェクトを再利用するようにして、多重ロードが発生しないようにする. 将来的にprivate化.
   */
  constructor ({ src, global }: ScriptLoaderParam) {
    this.src = src
    this.global = global
  }

  /**
   * This serves as the main method of ScriptLoader.
   * You can wait for the script loading is completed.
   * @return Loaded global varible with the name specified in the constructor
   */
  load(): Promise<T> {
    // 最初にメソッドコールされたタイミングで処理状態を保持し、二回目以降のメソッドコールには保存した処理状態を再利用する.
    // それにより、複数回メソッドコールが行われても実際のスクリプト取得処理は一度しか実行されないようにする = 二重取得を起こさせない意図.
    return this.loadingProcess || (this.loadingProcess = new Promise(async (resolve, reject) => {
      try {
        await this.loadScript()

        resolve(window[this.global])
      } catch (e) {
        reject(e)
      }
    }))
  }

  private loadScript(): Promise<HTMLScriptElement> {
    return new Promise((resolve, reject) => {
      // Create script element and set attributes
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.async = true
      script.src = this.src

      // Append the script to the DOM
      document.body.appendChild(script)

      // Resolve the promise once the script is loaded
      script.addEventListener('load', () => {
        resolve(script)
      })

      // Catch any errors while loading the script
      script.addEventListener('error', () => {
        reject(new Error(`${this.src} failed to load.`))
      })
    })
  }
}
