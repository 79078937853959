import { CurrentUser } from '@/services/api/current-user'
import { EventBusForThirdParty } from '@/usecases/event-bus-for-third-party/event-bus'
import { MailMagazineService } from '@spa/services/mailMagazine'
import { differenceInDays } from 'date-fns'
import { Either, left, right } from 'fp-ts/lib/Either'
import _ from 'lodash'

import { RawTag } from '@/models/api/tags'
import * as CurrentTime from '@/models/current-time/functions'
import Auth, { UserRegistrationPayload } from '@spa/services/api/auth'
import { convertToRegionName } from '@spa/services/region'

import {
  RegistrationPayload,
  SubscribeMailMagazinePayload,
} from './actions'
import { User } from './types'

const currentTime = CurrentTime.create()

export function createRegistrationPayload(
  user: UserRegistrationPayload,
  mailMagazines: RawTag[]
): RegistrationPayload {
  const mailMagazine: SubscribeMailMagazinePayload = {
    pathnames: mailMagazines.filter(magazine => magazine.follow)
                            .map(magazine => magazine.pathname),
  }

  return {
    user,
    mailMagazine,
  }
}

export function getFullName(user: User, divider: string = ''): string {
  return `${user.last_name}${divider}${user.first_name}`
}

export function getPhoneticFullName(user: User, divider: string = ''): string {
  return `${user.phonetic_last_name}${divider}${user.phonetic_first_name}`
}

export function getFullAddress(user: User): string {
  const regionName = convertToRegionName(user.region) || ''

  return `${regionName}${user.address1}${user.address2}`
}

export function calculateDaysAfterCreated(user: User, now: Date = currentTime.now): number {
  const { created_at: createdAt } = user

  return differenceInDays(now, createdAt)
}

/**
 * 実質、{@link CurrentUser.getCurrentUser}の結果に若干の加工を加えているだけになっている.
 * TODO: 加工も含めて{@link CurrentUser.getCurrentUser}に引き込んで、store用の関数は廃止する.
 */
export async function fetchCurrentUser(currentUserGateway: CurrentUser): Promise<Either<Error, User>> {
  const result = await currentUserGateway.getCurrentUser()

  return result.fold(
    e => left(e),
    user => right({
      id: user.id,
      shortid: user.shortid,
      first_name: user.first_name,
      last_name: user.last_name,
      phonetic_first_name: user.phonetic_first_name,
      phonetic_last_name: user.phonetic_last_name,
      birthday: user.birthday,
      gender: user.gender,
      email: user.email,
      tel: user.tel,
      zipcode: user.zipcode,
      region: _.toString(user.region),
      address1: user.address1,
      address2: user.address2,
      current_point: user.current_point,
      /**
       * ユーザーが保持しているカラット数.
       * 2022/07/14 時点で実質未使用.
       * 削除して良いかもしれないが、一応要確認.
       */
      aux_text_10: user.aux_text_10,
      user_status: user.user_status,
      payment_auth_token: user.payment_auth_token,
      payment_accountid: user.payment_accountid,
      created_at: new Date(user.created_at),
      membership: user.membership,
    })
  )
}

interface RegisterNewUserError {
  error: Error
  cause: 'register' | 'mail-magazine'
}
export function registerNewUser(gateway: {
  authGateway: Auth,
  mailMagazineGateway: MailMagazineService,
  currentUserGateway: CurrentUser,
}) {
  return async (
    user: UserRegistrationPayload,
    mailMagazine: SubscribeMailMagazinePayload
  ): Promise<Either<RegisterNewUserError, User>> => {
    const resultRegister = await gateway.authGateway.register(user)
    if (resultRegister.isLeft()) {
      return left({ error: resultRegister.value, cause: 'register' })
    }

    EventBusForThirdParty.getInstance().publish('signup', {})

    const resultRegisteredUser = await fetchCurrentUser(gateway.currentUserGateway)
    if (resultRegisteredUser.isLeft()) {
      return left({ error: resultRegisteredUser.value, cause: 'register' })
    }

    const registeredUser = resultRegisteredUser.value

    if (mailMagazine.pathnames.length === 0) {
      return right(registeredUser)
    }

    const resultSubscribeMailMagazine = await gateway.mailMagazineGateway.subscribe(
      registeredUser.id,
      mailMagazine.pathnames
    )

    return resultSubscribeMailMagazine.fold(
      error => left({ error, cause: 'mail-magazine' }),
      () => right(registeredUser)
    )
  }
}
