
















import Vue from 'vue'
import { Component } from 'vue-property-decorator'

import { HistoryService } from '@ajax/modules/history'

@Component
export default class ConditionHeader extends Vue {
  back() {
    HistoryService.back()
  }
}
