import _ from 'lodash'
import { ActionTree } from 'vuex'

import { ListWithPagination } from '@/models/api/response/list'
import { OrderHistoryApiService } from '../../../services/api/orderHistory'
import { OrderHistoryService } from '../../../services/orderHistory'
import { RootState } from '../../types'
import { getPage } from './functions/page'
import { convertToOrder, convertToOrders, convertToProducts, OrderHistoryState, Page } from './types'

import { log } from '../../../log'

export const actions: ActionTree<OrderHistoryState, RootState> = {

  async addPage({ commit, state }, payload: {
    force?: boolean,
    pageNumber: number,
  }) {
    const { force, pageNumber } = payload

    const existingPage = getPage(state.pages, pageNumber)

    if (!force && existingPage) {
      return  // Skip
    }

    const { perPage } = state
    const orderHistoryApiService = new OrderHistoryApiService()
    const orderHistoryService = new OrderHistoryService()

    /* Call API to list order histories */
    const result = (await orderHistoryApiService.list({
      start: (pageNumber - 1) * perPage,
      limit: perPage,
    }))

    if (result.isLeft()) {
      const error = result.value
      log.error(error)

      commit('addPage', {
        pageNumber,
        items: [],
        isLastPage: false,
        error,
      })

      return
    }

    /* Map to models with incomplete data */
    const listWithPagination = ListWithPagination.createFrom(result.value)
    const orders = convertToOrders(listWithPagination.list)

    /* Inject additional data to the models */
    // FIXME: Refactor for performance
    orders.forEach(async (order) => {
      try {
        const rawOrder = _.find(result.value.body.data, ['id', order.id])

        const joined = await orderHistoryApiService.join(rawOrder.item)

        const products = convertToProducts(rawOrder.item)

        order.products = await orderHistoryService.injectWithJoinedProductInfo(products, joined)
      } catch (error) {
        log.error(error)

        commit('addPage', {
          pageNumber,
          items: [],
          isLastPage: false,
          error,
        })
      }
    })

    /* Add a page */
    const page: Page = {
      pageNumber,
      items: orders,
      isLastPage: listWithPagination.pagination.isLastPage,
    }

    commit('addPage', page)
  },

  async get({ commit }, id: string) {
    const orderHistoryApiService = new OrderHistoryApiService()
    const result = (await orderHistoryApiService.get(id))

    if (result.isLeft()) {
      const error = result.value
      log.error(error)

      commit('errorOnLoad', {
        load: error,
      })

      return
    }

    const order = convertToOrder(result.value.body.data)

    try {
      const rawOrder = result.value.body.data

      // TODO: Refactoring
      const joined = await orderHistoryApiService.join(rawOrder.item)

      const products = convertToProducts(rawOrder.item)

      order.products = await new OrderHistoryService().injectWithJoinedProductInfo(products, joined)

      order.isFreeShippingOnCatalogOnly = _.every(order.products, 'isCatalogType')

    } catch (error) {
      log.error(error)
      commit('errorOnJoin', {
        id: order.id,
        error,
      })
    }

    commit('get', order)
  },

}
