import QuestionMarkHover from '@/components/atoms/QuestionMarkHover.vue'
import { PaidMembershipPriceLabel } from '@/components/organisms/item/price/paid-membership-price/interaction'
import { identity } from 'fp-ts/lib/function'
import Vue, { VueConstructor } from 'vue'

type View = VueConstructor
export interface ViewState {
  regularPrice: {
    classes: string[]
  }
  paidMemberPrice: PaidMemberPriceState
}
interface PaidMemberPriceState {
  exist: boolean
  classes: string[]
  label: string
  componentToApply: (pathAfterApply: string) => View
}

export abstract class PaidMemberPriceViewState {
  static create(label: PaidMembershipPriceLabel, isPaidMember: boolean): ViewState {
    return label.paidMemberPriceWithTax.fold(
      () => new ViewStateOnPaidMemberPriceNotApplicable(label, isPaidMember),
      () => new ViewStateOnPaidMemberPriceApplicable(label, isPaidMember)
    )
  }

  protected constructor(
    private readonly label: PaidMembershipPriceLabel,
    protected readonly isPaidMember: boolean
  ) {}

  protected get paidMemberPriceExist(): boolean {
    return this.appliableOrWholePaidMemberPrice.isRight()
  }
  protected get paidMemberPriceLabel(): string {
    return this.appliableOrWholePaidMemberPrice.fold(() => '', identity)
  }

  protected get appliableOrWholePaidMemberPrice() {
    return this.label
      .paidMemberPriceWithTax.orElse(() => this.label.wholePaidMemberPriceWithTax)
  }
}

class ViewStateOnPaidMemberPriceNotApplicable extends PaidMemberPriceViewState implements ViewState {
  readonly regularPrice = {
    classes: [],
  }

  get paidMemberPrice(): PaidMemberPriceState {
    const componentToApply = (): View => QuestionMarkHover
    const emptyView = (_pathAfterApply: string): View => Vue.extend({
      name: 'EmptyView',
      template: '<span></span>',
    })

    return {
      exist: this.paidMemberPriceExist,
      label: this.paidMemberPriceLabel,
      classes: ['c-item-price--not-applicable'],
      componentToApply: this.isPaidMember ? emptyView : componentToApply,
    }
  }
}
class ViewStateOnPaidMemberPriceApplicable extends PaidMemberPriceViewState implements ViewState {
  readonly regularPrice = {
    classes: ['c-item-price--not-applicable'],
  }

  get paidMemberPrice(): PaidMemberPriceState {
    const componentToApply = (_: string): View => Vue.extend({
      name: 'AlreadyAppliedAsPaidMember',
      template: `<div name="already-applied-as-paid-member"></div>`,
    })

    return {
      exist: this.paidMemberPriceExist,
      label: this.paidMemberPriceLabel,
      classes: [],
      componentToApply,
    }
  }
}
