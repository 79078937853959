import StockValue from './stock-value'

abstract class AbstractStockValue implements StockValue {
  public constructor(private readonly value: number) { }

  public get amount(): number {
    return this.value
  }

  public get isEmpty(): boolean {
    return this.amount <= 0
  }

  public get exists(): boolean {
    return !this.isEmpty
  }
}

export default AbstractStockValue
