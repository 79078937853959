import { MutationTree } from 'vuex'
import { ViewState } from './types'

import { log } from '../../../log'

const namespace = 'view'

export const mutations: MutationTree<ViewState> = {

  startLoading(state) {
    state.isLoading = true
    log.debug({ commit: `${namespace}/startLoading`, state })
  },
  stopLoading(state) {
    state.isLoading = false
    log.debug({ commit: `${namespace}/stopLoading`, state })
  },
}
