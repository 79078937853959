import { ApiProductclass } from '@/models/api/productclass'
import { FkuQuery } from '@/services/api/item/fku/fku-query'
import ProductclassService from '@/services/api/productclass'
import ContactLensFKU from './contact-lens'

export default class ContactLensFKURepository {
  constructor(private readonly appId: string) {}

  async listBelongingToRoot(rootId: string): Promise<ContactLensFKU[]> {
    const productclassResult = await new ProductclassService(this.appId).listFkuBelongingToRoot(
      rootId,
      FkuQuery.initialize({
        with: 'part,stock~detail',
      }).toObject()
    )

    if (productclassResult.isLeft()) {
      throw productclassResult.value
    }

    const products = productclassResult.value.map(dto => new ApiProductclass(dto))

    return this.sortByDisplayLevel(products).map(product => ContactLensFKU.create(product))
  }

  /**
   * displayLevelの昇順にソート
   * @param products
   */
  private sortByDisplayLevel(products: ApiProductclass[]): ApiProductclass[] {
    return products.sort((a, b) => a.displayLevel - b.displayLevel)
  }
}
