import _ from 'lodash'

import Constant, { isConstant } from '@/models/api/payment/parameter/type/constant'
import UserSelect, { isUserSelect } from '@/models/api/payment/parameter/type/user-select'
import Variable, { isVariable } from '@/models/api/payment/parameter/type/variable'

/**
 * @see https://my-color.esa.io/posts/534
 */
export default interface Parameter {
  id: string
  type: ParameterType
}

export type ParameterType = 'constant' | 'variable' | 'user_select'

export class ParameterList<T extends Parameter> {

  static empty(): ParameterList<Parameter> {
    return new ParameterList([])
  }

  static valueOf<T extends Parameter>(parameters: T[]): ParameterList<T> {
    return new ParameterList(parameters)
  }

  private constructor(
    private parameters: T[]
  ) {}

  get onlyConstant(): ParameterList<Constant> {
    return new ParameterList(filterType(this.parameters, isConstant))
  }

  get onlyVariable(): ParameterList<Variable> {
    return new ParameterList(filterType(this.parameters, isVariable))
  }

  get onlyUserSelect(): ParameterList<UserSelect> {
    return new ParameterList(filterType(this.parameters, isUserSelect))
  }

  get list(): T[] {
    return this.parameters
  }

  map<U>(iteratee: (p: T) => U): U[] {
    return this.parameters.map(iteratee)
  }

  filter(predicate: (p: T) => boolean): ParameterList<T> {
    return new ParameterList(this.parameters.filter(predicate))
  }

  find(predicate: (p: T) => boolean): T | null {
    return _.find(this.parameters, predicate) || null
  }
}

// Array<Parameter>.filterがp => isT(p)の結果もT[]ではなくParameter[]と認識するので、
// 半ば無理やりT[]であることを宣言したバージョンのfilter
const filterType = <T extends Parameter>(
  parameters: any[],
  isT: (parameter: Parameter) => parameter is T
): T[] => parameters.filter(p => isT(p))

export class CreateParameterError extends Error {}
