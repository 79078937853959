import { SubscriptionService } from '@/services/api/subscription'
import { Either } from 'fp-ts/lib/Either'

export type InteractionFactory = (subscriptionService: SubscriptionService) => StartApplyingSubscription

/**
 * 詳細画面等から、サブスクリプション契約を開始する操作を行ったときのインタラクションを表現する.
 */
export class StartApplyingSubscription {
  static readonly defaultFactory: InteractionFactory = service => new StartApplyingSubscription(service)

  constructor(private readonly subscriptionService: SubscriptionService) {}

  /**
   * 契約開始手続き
   */
  async start({ brand, skuId }: { brand: string, skuId: string }): Promise<Either<Error, ResultToStartApplying>> {
    const res = await this.subscriptionService.preset({
      brandEnglishName: brand,
      skuId,
    })

    return res.map(
      () => ({
        nextPath: `/s/order/subscription/${skuId}?scope=brand.${brand}`,
      })
    )
  }
}

/**
 * 契約開始手続きの結果
 */
export interface ResultToStartApplying {
  /**
   * 次に遷移すべきパス
   */
  nextPath: string
}
