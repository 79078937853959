import { CartButtonInItemListState } from '@ajax/vue/store/itemlist/cart-button/types'
import { none, some } from 'fp-ts/lib/Option'
import { MutationTree } from 'vuex'

export const mutations: MutationTree<CartButtonInItemListState> = {
  startProcess(state, payload: { buttonId: string }): void {
    state.processingButtonId = some(payload.buttonId)
  },
  stopProcess(state): void {
    state.processingButtonId = none
  },
}
