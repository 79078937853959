

















































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component
export default class Dropdown extends Vue {

  @Prop({ default: undefined })
  href: string

  @Prop()
  children: any[]

  open: boolean = false

  toggle() {
    if (this.isChildren) {
      this.open = !this.open
    }
  }

  enter(el) {
    el.style.height = 'auto'
    const height = getComputedStyle(el).height
    el.style.height = 0
    setTimeout(() => {
      el.style.height = height
    })
  }

  afterEnter(el) {
    el.style.height = 'auto'
  }

  leave(el) {
    const height = getComputedStyle(el).height
    el.style.height = height
    setTimeout(() => {
      el.style.height = 0
    })
  }

  get isChildren() {
    return this.children && this.children.length
  }

  get isHref() {
    return this.href !== undefined
  }

  get status() {
    if (!this.isChildren) {
      return ''
    }

    return this.open ? 'fa-chevron-down show' : 'fa-chevron-down'
  }
}
