import { EveryPageViewEvent } from '../../event'

export function handleEveryPageView(event: EveryPageViewEvent) {
  const { userId, adCode } = event

  if (!userId) return

  window.dataLayer.push({
    ...{
      user_id: userId,
    },
    ...adCode ? {
      ad_code: adCode,
    } : {},
  })
}
