import {
  isAtone,
  isCreditCard,
  isPaidy,
  Payment,
} from '@spa/store/modules/orderConfirm/types'

import AtonePaymentParamBuilder from './atonePaymentParamBuilder'
import CreditPaymentParamBuilder from './creditcardPaymentParamBuilder'
import PaidyPaymentParamBuilder from './paidyPaymentParamBuilder'
import SimplePaymentParamBuilder from './simplePaymentParamBuilder'

export interface PaymentParamBuilder {
  /**
   * Engine APIのI/Fに直接渡せるように、支払い方法設定を文字列に変換する
   *
   * Front側ではバリデーション等での取り扱いの観点から、基本的に文字列ではなくJSONで扱って、
   * Engineリクエストの時だけ文字列化する形にしたい.
   *
   * まずは buildAsJson といったメソッドを追加して、独自APIでは文字列を使わずJSONを使うようにして、
   * 最終的には全て buildAsJson を使って `build(): string` を削除したい.
   */
  build(): string
}

export function createPaymentParamBuilder(payment: Payment): PaymentParamBuilder {
  if (isCreditCard(payment)) {
    return new CreditPaymentParamBuilder(payment)
  }
  if (isAtone(payment)) {
    return new AtonePaymentParamBuilder(payment)
  }
  if (isPaidy(payment)) {
    return new PaidyPaymentParamBuilder(payment)
  }

  return new SimplePaymentParamBuilder(payment)
}
