import { Either, Left, Right } from 'fp-ts/lib/Either'
import _ from 'lodash'
import Parameter, { CreateParameterError, ParameterType } from '../parameter'

/**
 * @see https://my-color.esa.io/posts/534
 */
export default class UserSelect implements Parameter {

  static createFromApiResponse(data: any): Either<Error, UserSelect> {
    const required: string[] = ['id', 'options', 'select']
    const missedKeys = _.compact(required.map((key) => {
      return data[key] === undefined ? key : null
    }))

    if (missedKeys.length > 0) {
      return new Left(
        new CreateParameterError(`Error on creating UserSelect: ${missedKeys.join(', ')} is required.`)
      )
    }

    if (!isSelectType(data.select)) {
      return new Left(
        new CreateParameterError(`Error on creating UserSelect: select type ${data.select} is unexpected.`)
      )
    }

    if (!Array.isArray(data.options)) {
      return new Left(
        new CreateParameterError(`Error on creating UserSelect: options must be an array.`)
      )
    }
    const options = SelectOptionList.createFromApiResponse(data)
    if (options.isLeft()) {
      return new Left(options.value)
    }

    return new Right(
      // APIのレスポンスに特定の値が含まれなかったとしても無害なデフォルト値を設定できるものはここで設定している
      // @see https://github.com/my-color/front/pull/5127#discussion_r603346010
      new UserSelect(
        data.id,
        data.label || '',
        data.description || '',
        data.select,
        data.required || false,
        options.value
      )
    )
  }

  private constructor(
    public readonly id: string,
    public readonly label: string,
    public readonly description: string,
    public readonly select: SelectType,
    public readonly required: boolean,
    public readonly options: SelectOptionList
  ) {}

  get type(): ParameterType {
    return 'user_select'
  }

  get isSingle(): boolean {
    return this.select === 'single'
  }

  get isMulti(): boolean {
    return this.select === 'multi'
  }
}

export const isUserSelect = (parameter: Parameter): parameter is UserSelect => {
  return parameter.type === 'user_select'
}

export class SelectOption {

  static createFromApiResponse(parameterId: string, data: any): Either<Error, SelectOption> {
    const required: string[] = ['id', 'label']
    const missedKeys = _.compact(required.map((key) => {
      return data[key] === undefined ? key : null
    }))

    if (missedKeys.length > 0) {
      return new Left(
        new CreateParameterError(`Error on creating SelectOption: ${missedKeys.join(', ')} is required.`)
      )
    }

    return new Right(
      new SelectOption(parameterId, data.id, data.label)
    )
  }

  private constructor(
    public readonly parameterId: string,
    public readonly id: string,
    public readonly label: string
  ) {}

  /**
   * 異なるUserSelectに同一のIDのSelectOptionが含まれうるので、
   * 自身のIDと自身を選択肢として保有するUserSelectのIDの両方の一致をもって等しいと定義する。
   */
  equalTo(another: SelectOption): boolean {
    return this.id === another.id && this.parameterId === another.parameterId
  }
}

export class SelectOptionList {

  static createFromApiResponse(data: any): Either<Error, SelectOptionList> {
    const createResult: Array<Either<Error, SelectOption>> = data.options.map(
      (raw: any) => SelectOption.createFromApiResponse(data.id, raw)
    )
    const [errors, options] = _.partition(createResult, r => r.isLeft())

    if (errors.length > 0) {
      const messages = errors.map(e => (e.value as Error).message)

      return new Left(
        new CreateParameterError(`Error on creating SelectOptionList: ${messages.join(', ')}`)
      )
    }

    return new Right(
      SelectOptionList.valueOf(options.map(o => o.value as SelectOption))
    )
  }

  static valueOf(options: SelectOption[]): SelectOptionList {
    return new SelectOptionList(options)
  }

  static empty(): SelectOptionList {
    return new SelectOptionList([])
  }

  private constructor(
    private readonly options: SelectOption[]
  ) {}

  get list(): SelectOption[] {
    return this.options
  }

  get isEmpty(): boolean {
    return this.options.length === 0
  }

  get length(): number {
    return this.options.length
  }

  add(option: SelectOption): SelectOptionList {
    return SelectOptionList.valueOf([
      ...this.list,
      option,
    ])
  }

  remove(option: SelectOption): SelectOptionList {
    return this.filter(o => !(o.equalTo(option)))
  }

  map<T>(iteratee: (option: SelectOption) => T): T[] {
    return this.options.map(iteratee)
  }

  find(predicate: (option: SelectOption) => boolean): SelectOption | null {
    return _.find(this.options, predicate) || null
  }

  filter(predicate: (option: SelectOption) => boolean): SelectOptionList {
    return new SelectOptionList(this.options.filter(predicate))
  }
}

export type SelectType = 'single' | 'multi'
export const isSelectType = (value: any): value is SelectType => {
  return value === 'single' || value === 'multi'
}
