import { Either, left, right } from 'fp-ts/lib/Either'

type ErrorMessage = string
type MaybeEndPoint = string | null
type AddResult = Either<ErrorMessage, MaybeEndPoint>

interface FoldResult {
  errorMessages: ErrorMessage[],
  maybeEndPoint: MaybeEndPoint
}

function fold(e: Either<ErrorMessage, MaybeEndPoint>): FoldResult {
  return e
    .map(maybeEndPoint => ({
      errorMessages: [], maybeEndPoint,
    }))
    .mapLeft(errorMessage => ({
      errorMessages: [errorMessage],
      maybeEndPoint: null,
    })).value
}

export interface AddResultSet {
  rightEye: AddResult
  leftEye: AddResult
}
export type AddFoldResult = Either<ErrorMessage[], MaybeEndPoint>
export function foldAddResult({
                                rightEye: rightResult,
                                leftEye: leftResult,
}: AddResultSet): AddFoldResult {
  const r = fold(rightResult)
  const l = fold(leftResult)

  const errorMessages = [
    ...r.errorMessages,
    ...l.errorMessages,
  ]

  return errorMessages.length === 0
    ? right(r.maybeEndPoint || l.maybeEndPoint)
    : left(errorMessages)
}
