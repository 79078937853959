




















import Vue from 'vue'
import { Component, Emit, Prop } from 'vue-property-decorator'

import Item from '@/components/molecules/Item.vue'
import { ignoreDiscountState } from '@/components/molecules/item/model/decorator'
import ItemList from '@/components/organisms//item-list/Container.vue'
import { ApiProductclass } from '@/models/api/productclass'

@Component({
  components: {
    Item,
    ItemList,
  },
})
export default class BundlePurchaseFkuList extends Vue {
  @Prop({ required: true })
  fkuList: ApiProductclass[]

  @Emit('click')
  onClickItem(rawFkuItem: any) {
    return new ApiProductclass(rawFkuItem)
  }

  get fkuDecorator() {
    return ignoreDiscountState
  }

  /**
   * :pensive:
   * 既存のItemListContainerの方で、まだApiProductclass（もっと言えばドメインオブジェクト）に対応しきれていないので、
   * 一度生データに戻している
   *
   * TODO: ItemListContainerがDTO or ドメインオブジェクトに対応するようになったら追随する
   */
  get itemList() {
    return this.fkuList.map(fku => fku.extractRawJsonForOnlyReceiverNotCorrespondingToObject())
  }
}
