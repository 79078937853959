



























































import Vue from 'vue'
import { Component, Emit, Prop } from 'vue-property-decorator'

import PageTransitionLink from '@/components/atoms/PageTransitionLink.vue'
import BundlePurchaseAddToCartButton from './BundlePurchaseAddToCartButton.vue'
import { BundlePurchaseItem } from './model/bundle-purchase-item'
import { AddItemsToCartSpec } from './model/specification/add-items-to-cart/index'

@Component({
  components: {
    BundlePurchaseAddToCartButton,
    PageTransitionLink,
  },
})
export default class BundlePurchaseNavigation extends Vue {
  @Prop({ required: true })
  brand: string

  @Prop({ required: true })
  requiredItemCount: number

  @Prop({ required: true })
  beforeDiscount: number

  @Prop({ required: true })
  priceOnBundled: number

  @Prop({ required: true })
  purchaseList: BundlePurchaseItem[]

  @Prop({ required: true })
  addItemsToCartSpec: AddItemsToCartSpec

  isSliderOverflowing = false

  get emptySlotNumber(): number {
    return Math.max(this.requiredItemCount - this.purchaseList.length, 0)
  }

  @Emit('removeItem')
  onClickItem(_listIndex: number) {
    // Do nothing
  }

  mounted() {
    this.attachWindowEventHandlers()
  }

  updated() {
    this.updateIsSliderOverflowing()
  }

  attachWindowEventHandlers() {
    window.onorientationchange = this.updateIsSliderOverflowing
    window.onresize = this.updateIsSliderOverflowing
  }

  updateIsSliderOverflowing() {
    this.isSliderOverflowing = this.ifSliderIsOverflowing(this.$refs.slider as Element)
  }

  ifSliderIsOverflowing(sliderElem: Element): boolean {
    if (!sliderElem) {
      return false
    }

    return sliderElem.scrollWidth > sliderElem.clientWidth
  }
}
