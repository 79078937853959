import * as DateFns from 'date-fns'

export default class SalePeriod {
  public constructor(
    private readonly _start: Date,
    private readonly _end: Date
  ) { }

  include(date: Date): boolean {
    if (DateFns.isEqual(this._start, date)) {
      return true
    }
    if (DateFns.isEqual(this._end, date)) {
      return true
    }

    return DateFns.isBefore(this._start, date) && DateFns.isBefore(date, this._end)
  }
}
