/**
 * WARNING: This method changes window history URL forcefully.
 * https://github.com/my-color/front/pull/2794#pullrequestreview-316145049
 */
export const forceUpdateWindowHistoryUrl = (url: string): void => {
  const state = window.history.state
  if (state) {
    window.history.replaceState(
      state.data ? state.data : {},
      state.title ? state.title : '',
      url)
  } else {
    window.history.replaceState({}, '', url)
  }
}

export interface HistorySource {
  referrer: string
  location: {
    host: string
  }
}
export interface HistoryOption {
  fallback: string
}
/**
 * MyColor内部の回遊における「直前のURL」のパスを取得する。
 * ここでいう「パス」は、ホスト以下のパスを指す。
 * 「直前のURL」が外部ページである場合、option.fallbackを返す。
 *
 * ex. https://mycolor.jp/hoge/fuga から移動してきた場合 → /hoge/fuga を返す
 * ex. https://external.jp/hoge/fuga から移動してきた場合 → option.fallback を返す
 */
export const getPreviousPathInSite =
  ({ location, referrer }: HistorySource) =>
  (option: HistoryOption): string => {
    const regexp = new RegExp(`${location.host}(.*)`)

    const match = referrer.replace(/[?#].*/, '').match(regexp)

    if (match === null) {
      return option.fallback
    }

    const matched = match[1]

    return matched.startsWith('/') ? matched : `/${matched}`
  }
export const getPreviousPathInSiteViaGlobal = getPreviousPathInSite({
  referrer: document.referrer,
  location,
})
