








import _ from 'lodash'
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import LockBackgroundMixin from '@/mixin/LockBackground'

@Component({
  mixins: [
    LockBackgroundMixin,
  ],
})
export default class Spinner extends Vue {
  @Prop({ default: true })
  overlay: boolean

  get _class() {
    return {
      'c-spinner__overlay': this.overlay,
      'c-spinner__overlay--not': !this.overlay,
    }
  }

  get range() {
    const begin = 1
    const end = 8

    return _.range(begin, end + 1) // endに指定された値は配列に含まれないので+1
  }

}
