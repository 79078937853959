






















import { none, Option } from 'fp-ts/lib/Option'
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import SwitchByUa from '@/components/atoms/SwitchByUa.vue'
import HostPageLinkMixin from '@/mixin/HostPageLink'
import ApiBrand from '@/models/api/brand'
import { ApiContent } from '@/models/api/content'
import BehaviorConfig from '@/models/app-config/behavior/behavior'
import FooterLinkObject from '@/models/app-config/behavior/config/footer/link'
import { BrandService } from '@/services/api/brand'
import FreeAreaService from '@/services/free-area/free-area'
import FooterFreeAreaService from '@/services/free-area/global/footer-free-area'
import { BrandRouteResolver } from '@/util/brandRouteResolver'
import { InstanceResolver } from '@/util/instanceResolver'
import { CookieService } from '@ajax/modules/cookie'
import CopyRight from './CopyRight.vue'
import FooterLink from './FooterLink.vue'

interface FreeArea {
  inside: ApiContent | null
}

@Component({
  components: {
    CopyRight,
    FooterLink,
    SwitchByUa,
  },
  mixins: [
    HostPageLinkMixin,
  ],
})
export default class Footer extends Vue {
  @Prop({ default: '' })
  brand: string | null

  isRegistered: boolean = false

  footerType: 'ec' | 'signup' | 'plain' = 'ec'

  maybeBrand: Option<ApiBrand> = none

  freeArea: FreeArea = {
    inside: null,
  }

  get footerClass() {
    return {
      'l-footer--signup': this.footerType === 'signup',
    }
  }

  get behaviorConfig(): BehaviorConfig {
    return BehaviorConfig.createFromBrand(this.brandEnglishName)
  }

  get brandLabel(): string {
    return this.maybeBrand.fold('', brand => brand.name)
  }

  async created() {
    this.isRegistered = CookieService.isRegistered()

    const handleHistory = this.handleHistory
    // for reload
    window.addEventListener('load', (event) => {
      if (event !== null) {
        handleHistory(location.pathname)
      }
    })
    // for browser back and forward
    window.addEventListener('popstate', (event) => {
      if (!event.state) return
      if (event !== null) {
        handleHistory(location.pathname)
      }
    });
    // for forward from application
    ((origin) => {
      history.pushState = function (data, title, url) {
        handleHistory(url)

        return origin.apply(this, [data, title, url])
      }
    })(history.pushState)

    if (!this.shouldResolveBrand) {
      return
    }
    this.maybeBrand = await this.loadBrand()

    this.maybeBrand.map(this.loadFreeArea)
  }

  handleHistory(url) {
    // "/s/order" or "/s/order/" or "/s/order/edit/*"
    if (/^\/s\/(order\/?|order\/edit\/.*)$/i.test(url)) {
      this.footerType = 'plain'

      return
    }

    // /s/signup* or /s/login* or /s/paid-membership*
    if (/^\/s\/(signup|login|paid-membership).*$/i.test(url)) {
      this.footerType = 'signup'

      return
    }

    this.footerType = 'ec'
  }

  destroyed () {
    window.removeEventListener(
      'load', this.handleHistory
    )
    window.removeEventListener(
      'popstate', this.handleHistory
    )
  }

  get footerLinks(): FooterLinkObject[] {
    return this.behaviorConfig.footer.links
  }

  private async loadBrand(): Promise<Option<ApiBrand>> {
    const eitherFindBrand = await this.brandService.findByEnglishName(this.brandEnglishName)

    return eitherFindBrand.fold(
      _ => none,
      success => success
    )
  }
  private async loadFreeArea(brand: ApiBrand): Promise<void> {
    const freeAreaService = FreeAreaService.createFromBrandName(
      brand.englishName,
      FooterFreeAreaService
    )

    const [inside] = await freeAreaService.fetch()

    this.freeArea = {
      inside,
    }
  }

  private get shouldResolveBrand(): boolean {
    if (InstanceResolver.isSingle()) {
      return true
    }

    return !!this.brandEnglishName
  }

  private get brandService() {
    return new BrandService()
  }

  private get brandEnglishName(): string | null {
    return BrandRouteResolver.resolveBrandFromPathElement(this.brand || '')
  }
}
