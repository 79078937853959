import { Either, Left, Right } from 'fp-ts/lib/Either'

import { log } from '@/log'
import { ApiClient } from './client'
import { ApiService } from './service'

const namespace: string = 'contents'

export default class ContentsService extends ApiService {

  /**
   * Get current user info with cookie
   */
  async get(pathname: string): Promise<Either<Error, object>> {
    try {
      const response = await ApiClient
        .get(`/api/common-proxy/${this.appId}/contents/${pathname}`)

      log.debug({ service: `${namespace}/get`, response })

      return new Right(response.body.data)
    } catch (error) {
      /**
       * フリーコンテンツは運用上登録されていないことが多く、
       * 登録されていない場合は404となるため、404はエラー扱いとしない。
       */
      // tslint:disable-next-line:no-magic-numbers
      if (error.status === 404) {
        log.debug({ service: `${namespace}/get`, error })
      } else {
        log.error({ service: `${namespace}/get`, error })
      }

      return new Left(error)
    }
  }

}
