import AbstractStockValue from './abstract-stock-value'
import StockValue from './stock-value'

export interface DisplayStockValue extends StockValue {
  readonly isEnable: boolean
  readonly isDisable: boolean
}

export class EnableDisplayStockValue extends AbstractStockValue implements DisplayStockValue {
  static get empty(): DisplayStockValue {
    return new EnableDisplayStockValue(0)
  }

  get isEnable(): boolean {
    return true
  }
  get isDisable(): boolean {
    return false
  }
}
export class DisableDisplayStockValue extends AbstractStockValue implements DisplayStockValue {
  static get value(): DisplayStockValue {
    return new DisableDisplayStockValue()
  }

  private constructor() {
    super(0)
  }

  get isEnable(): boolean {
    return false
  }
  get isDisable(): boolean {
    return true
  }
}
