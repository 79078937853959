import Badge from '@/components/molecules/item/model/badge'
import { none, Option, some } from 'fp-ts/lib/Option'
import { BadgeListDisplayRule } from './display-rule'
import { RuleInput } from './rule-applicable'

export class BadgeSpec {
  constructor(
    private readonly label: string,
    private readonly commonStyle: string,
    private readonly badgeStyle: string,
    private readonly optionStyles: string[],
    public readonly rule: BadgeListDisplayRule
  ) {}

  getBadgeFor(item: RuleInput): Option<Badge> {
    if (this.rule.isSatisfiedBy(item)) {
      return some(
        new Badge(
          this.label,
          this.commonStyle,
          this.badgeStyle,
          ...this.optionStyles
        )
      )
    }

    return none
  }
}

export class BadgeSpecBuilder {
  static create(
    label: string,
    badgeStyle: string,
    optionStyles: string[] = []
  ): BadgeSpecBuilder {

    const COMMON_BADGE_CLASS = 'p-goods__badge'

    return new BadgeSpecBuilder(
      label,
      COMMON_BADGE_CLASS,
      badgeStyle,
      optionStyles
    )
  }

  private constructor(
    private readonly label: string,
    private readonly commonStyle: string,
    private readonly badgeStyle: string,
    private readonly optionStyles: string[]
  ) {}

  ruleIs(rule: BadgeListDisplayRule): BadgeSpec {
    return new BadgeSpec(
      this.label,
      this.commonStyle,
      this.badgeStyle,
      this.optionStyles,
      rule
    )
  }
}
