import _ from 'lodash'
import { GetterTree } from 'vuex'

import { RootState } from '../../types'
import { Contact, ContactState } from './types'

export const getters: GetterTree<ContactState, RootState> = {
  get(state): (string) => Contact | undefined {
    return (id: string) => {
      return _.find(state.items, ['id', id])
    }
  },

  first(state): Contact | undefined {
    const displayLevel0 = _.find(state.items, ['display_level', 0])

    if (displayLevel0) {
      return displayLevel0
    }

    // Fallback
    return _.first(state.items)
  },
}
