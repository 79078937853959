


































import { log } from '@/log'
import { CouponCode } from '@/models/api/coupon/coupon-code/coupon-code'
import { ApiClientWithAuth } from '@/services/api/client'
import { fromNullable } from 'fp-ts/lib/Option'
import Vue from 'vue'
import { Component, Emit } from 'vue-property-decorator'

@Component
/**
 * LPから利用可能な有料会員契約フォームのクーポンコードフォーム.
 *
 * 注文確認画面({@link Order}.vue)にも「クーポンコード」フォームは存在する.
 * しかし、主として期待されるユースケースや前提が異なるので、別コンポーネント定義にしている.
 *
 * 「クーポンコード取得手続き」や「取得したクーポンコードの管理」などが一致するようであれば、
 * 今後mixinを利用する、あるいはそれぞれを独立した関数にして再利用するなどしても良いかもしれない.
 *
 * ---
 *
 * # 具体的なユースケースの違い(2022/07/27時点)
 * ## 共通
 * - クーポンコードを入力・適用すると、該当するクーポンコードデータが取得される
 *
 * ## 注文確認画面
 * - ユーザーは適用したクーポンコードを「キャンセル」できる
 * - クーポンを適用している時は、クーポンコードを適用はできない
 *     - {@link InputCouponCode.onIsCouponSelectedChange}
 * - システムビルトインのフォームで使用するので、運用ユーザーによる加工の余地は不要
 *
 * ## LP
 * - ユーザーはクーポンコードを必ず指定する必要があるので、変更はできても「キャンセル」はできない
 * - クーポンとの併用可能性は考慮不要
 * - 運用ユーザーが定義するLP上で使われるので、デザイン拡張の余地が必要になるかもしれない
 */
export default class CouponCodeForm extends Vue {
  inputCouponCode: string = ''
  couponCode: CouponCode | null = null
  errorMessages: string[] = []

  loading: boolean = false

  async applyCoupon() {
    if (!this.canApply) return

    this.loading = true

    try {
      const res = await ApiClientWithAuth.get(`/api/user/coupon/coupon_code/${this.inputCouponCode}`)

      this.couponCode = new CouponCode(res.body.data)
      this.errorMessages = []

      this.emitCouponCode(this.couponCode)

    } catch (error) {
      log.error(error)

      this.errorMessages = [
        'クーポンコードを適用できませんでした。',
        'ご利用可能なクーポンコードかどうか、ご確認ください。',
      ]

    } finally {
      this.loading = false
    }
  }

  get couponTitle(): string | null {
    return fromNullable(this.couponCode).map<string | null>(c => c.title).getOrElse(null)
  }

  get canApply(): boolean {
    return !this.loading && this.inputCouponCode !== ''
  }

  get applyButtonClass() {
    return [
      'coupon-code__button',
      'c-btn',
      'c-btn--w-small',
      {
        'c-btn--disabled': !this.canApply,
      },
    ]
  }

  @Emit('apply')
  emitCouponCode(_couponCode: CouponCode) {
    // nothing to do
  }
}
