











import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component
export default class RankingLabel extends Vue {
  @Prop({ required: true })
  rank: number
}
