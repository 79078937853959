import { URL } from '@/util/url'
import { compact } from 'lodash'

interface PagePathToSubscribePaidMembershipInterface {
  readonly pathAfterApply: string

  readonly asSPA: PagePathToSubscribePaidMembershipInterface

  toString(): string
}

/**
 * 有料会員登録画面へアクセスするためのパスを取り扱うモジュール.
 *
 * 有料会員登録画面を行き来する場合、 **URLエンコードされたパス** を利用することでページを行き来することになる.
 * このとき、個別の画面でURLエンコード/デコードを行うと、I/Fとして**URLエンコードされたパス**を用いるという知識が散在することになる.
 * また、I/Fとして**URLエンコードされたパス**を用いるという知識が各コンポーネント内部に非明示的に埋め込まれることとなる.
 *
 * 知識の散在と非明示化を防ぐ意味で、有料会員登録画面のパスに関する知識を専門に扱うモジュールを配置した.
 * @see https://github.com/my-color/front/pull/5837
 */
export class PagePathToSubscribePaidMembership implements PagePathToSubscribePaidMembershipInterface {
  public static returnToTopAfterApply(): PagePathToSubscribePaidMembershipInterface {
    return returnToTop
  }
  public static plain(): PagePathToSubscribePaidMembershipInterface {
    return this.returnToTopAfterApply() // 特にパラメータが何も指定されなければ、標準ではトップ画面へ遷移
  }

  /**
   * 有料会員登録後、引数で指定されたパスへリダイレクトされるような有料会員登録画面パスを構築する
   * @param pathAfterApply
   */
  public static returnAfterApply(pathAfterApply: string): PagePathToSubscribePaidMembershipInterface {
    return new PagePathToSubscribePaidMembership(pathAfterApply)
  }

  /**
   * 文字列化されたパスから、有料会員登録画面パスオブジェクトを再構築する
   * @param convertedPath
   */
  public static parseStringified(convertedPath: string): PagePathToSubscribePaidMembershipInterface {
    const pathAfterApply = URL.getQuery(convertedPath, 'return_to') || '/'

    return this.returnAfterApply(pathAfterApply)
  }

  private constructor(
    /**
     * 有料会員登録後にリダイレクトするパス
     */
    readonly pathAfterApply: string,
    private readonly prefix: string | null = null
  ) {
  }

  /**
   * SPA用のパスとして構築する
   */
  get asSPA(): PagePathToSubscribePaidMembership {
    return new PagePathToSubscribePaidMembership(
      this.pathAfterApply,
      '/s'
    )
  }

  public toString(): string {
    const path = URL.join(...compact([this.prefix, '/paid-membership/subscribe']))
    const query = `return_to=${encodeURIComponent(this.pathAfterApply)}`

    return `${path}?${query}`
  }
}

const returnToTop: PagePathToSubscribePaidMembershipInterface = {
  pathAfterApply: '/',
  get asSPA() {
    return this
  },
  toString: () => '/paid-membership/subscribe',
}
