import { ApiImage } from '@/models/api/image'
import { ItemKind } from '@/models/item/kind'
import ImageCollection from '../../models/product-image/image-collection'

export default class SelectThumbnailImageBuilder {
  static createDefault(): SelectThumbnailImageBuilder {
    return new SelectThumbnailImageBuilder(
      'default',
      false,
      null,
      null
    )
  }

  static createForApparel(instapage: boolean): SelectThumbnailImageBuilder {
    return new SelectThumbnailImageBuilder(
      'default',
      instapage,
      null,
      null
    )
  }

  static createForContactLens(
    instapage: boolean,
    displayLevelOfImageToDisplay: number | null,
    imageAppKindOfImageToDisplay: string | null
  ): SelectThumbnailImageBuilder {
    return new SelectThumbnailImageBuilder(
      'contact-lens',
      instapage,
      displayLevelOfImageToDisplay,
      imageAppKindOfImageToDisplay
    )
  }

  private constructor(
    private itemKind: ItemKind,
    private instapage: boolean,
    private displayLevelOfImageToDisplay: number | null,
    private imageAppKindOfImageToDisplay: string | null
  ) {}

  build(): (images: ApiImage[]) => ApiImage | null {
    return ((images: ApiImage[]) => {
      const imageCollection = ImageCollection.create(images.filter(img => !img.isLayer))

      if (this.instapage) {
        return imageCollection.findInstaImage()
      }

      return this.itemKind === 'contact-lens' ?
        imageCollection.sortForContactLensItemList(
          this.displayLevelOfImageToDisplay,
          this.imageAppKindOfImageToDisplay
        )[0] || null :
        imageCollection.sortByDisplayLevel()[0] || null
    })
  }
}
