











import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import FooterLinkObject from '@/models/app-config/behavior/config/footer/link'

@Component
export default class FooterLink extends Vue {
  @Prop({ required: true })
  link: FooterLinkObject

  get linkClass(): string[] {
    if (this.link.isOnlyPc) {
      return ['u-only--pc']
    }
    if (this.link.isOnlySp) {
      return ['u-only--sp']
    }

    return []
  }
}
