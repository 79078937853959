import { CartItem } from '@spa/store/modules/cart/types'
import { none, Option, some } from 'fp-ts/lib/Option'
import _ from 'lodash'
import { CartItemList } from '../cart-item'
import { CartScope, CartScopeEq } from './scope'

export class CartGroupList {
  static readonly empty = new CartGroupList([])

  static create(list: readonly CartGroup[]): CartGroupList {
    return new CartGroupList(list)
  }

  private constructor(readonly list: readonly CartGroup[]) { }

  findByScope(scope: CartScopeEq): Option<CartGroup> {
    const found = this.list.find(g => g.scope.equals(scope))

    return found ? some(found) : none
  }

  filter(filter: (group: CartGroup) => boolean): CartGroupList {
    return new CartGroupList(this.list.filter(filter))
  }

  items(): CartItemList {
    return this.list.reduce(
      (prev: CartItemList, group: CartGroup) => prev.concat(group.items),
      CartItemList.empty
    )
  }

  mapItems(map: (item: CartItem) => CartItem): CartGroupList {
    return new CartGroupList(
      this.list.map(
        group => group.mapItems(map)
      )
    )
  }

  merge(other: CartGroupList): CartGroupList {
    const cartGroupByScope: CartGroup[][] = Object.values(
      this.concat(other.list).groupByScope()
    )

    const mergedGroupArray: CartGroup[] = cartGroupByScope.map(groups => groups.reduce(
      (prev: CartGroup, next: CartGroup) => prev.merge(next)
    ))

    return new CartGroupList(mergedGroupArray).sortByScope(this.scopeList)
  }

  filterItems(filter: (i: CartItem) => boolean) {
    return new CartGroupList(
      this.list.map(group => CartGroup.create({
        scope: group.scope,
        items: group.items.filter(filter),
      }))
    )
  }

  private concat(groups: readonly CartGroup[]): CartGroupList {
    return new CartGroupList(this.list.concat(groups))
  }
  private groupByScope(): { [scope: string]: CartGroup[] } {
    return _.groupBy(this.list, group => group.scope.toString())
  }
  private get scopeList(): CartScopeEq[] {
    return this.list.map(g => g.scope)
  }
  private sortByScope(scopes: CartScopeEq[]): CartGroupList {
    return new CartGroupList(
      _.sortBy(this.list, group => scopes.findIndex(scope => group.scope.equals(scope)))
    )
  }
}

export function isDisplayItem(item: CartItem): boolean {
  return !item.isChild && !item.isNovelty
}

export class CartGroup {
  static create(input: { scope: CartScope, items: CartItemList }): CartGroup {
    return new CartGroup(input.scope, input.items)
  }

  readonly id = CartGroupId.valueOf(this.scope.id)

  private constructor(
    readonly scope: CartScope,
    readonly items: CartItemList
  ) { }

  get displayableItems(): CartItemList {
    return this.items.filter(isDisplayItem)
  }

  mapItems(map: (item: CartItem) => CartItem): CartGroup {
    return new CartGroup(this.scope, new CartItemList(this.items.map(map)))
  }

  merge(otherGroup: CartGroup): CartGroup {
    if (!this.scope.equals(otherGroup.scope)) {
      throw new Error(
        `only accept same scope, but not. receiver scope is ${this.scope.id}, other is ${otherGroup.scope.id}`
      )
    }

    return new CartGroup(
      this.scope,
      this.items.merge(otherGroup.items)
    )
  }
}

class CartGroupId {
  static valueOf(value: string): CartGroupId {
    return new this(value)
  }

  private constructor(private readonly value: string) { }

  toString() {
    return this.value
  }
}
