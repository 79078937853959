import AbstractValue from '@/models/app-config/behavior/abstract-value'

export interface RawSearch {
  allowed: SearchItem[] | 'all'
}

export enum SearchItem {
  Brand = 'brand',
  Category = 'category',
  Color = 'color',
  Price = 'price',
  Genre = 'genre',
}

export default class Search extends AbstractValue<RawSearch> {
  allow(item: SearchItem): boolean {
    if (this.raw.allowed === 'all') {
      return true
    }

    return !!this.raw.allowed.find(i => i === item)
  }
}
