import LabelMaker from './labelMaker'

export default class RateLabelMaker implements LabelMaker {
  public constructor(public percentage: number) {
  }

  public makeDiscountContentLabel(): string {
    return `${this.percentage}% OFF`
  }
}
