import { AddToCartEvent } from '../../event'

export function handleAddToCart(event: AddToCartEvent) {
  const { skuId, unitPrice, unitCount } = event

  window.dataLayer.push({
    event: 'add_to_cart',
    ecommerce: {
      items: [{
        /**
         * 商品名はv1ではMUSTではないかは要確認。
         * @see https://github.com/my-color/front/pull/6149#discussion_r811650043
         *
         * またそもそも商品名をいったん保留にしているのは、カート追加イベントをpublishする箇所を、以下の考慮から、カートAPIサービスにしており、
         * カートAPIサービスのカート追加メソッドには商品名が渡ってきていないからである。
         * publishする箇所としては次の二つの方針があり、(1)を採用している。
         * - (1)カートAPIサービスでイベントpublish: どのような経路からカート追加してもここを通るので、漏れがない。
         * - (2)カートAPIサービスのユースケースとなる各種の画面側でpublish: よりきめ細かな「ユーザイベント」ぽいが、各所に散在している。
         * @see https://github.com/my-color/front/pull/6149#discussion_r811689811
         */
        // item_name: '',
        item_id: skuId,
        currency: 'JPY',
        price: unitPrice,
        quantity: unitCount,
      }],
    },
  })
}
