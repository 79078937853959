import AbstractValue from '@/models/app-config/behavior/abstract-value'
import FooterLink, { RawFooterLink } from './footer/link'

export interface RawFooter {
  links: RawFooterLink[]
}

export default class Footer extends AbstractValue<RawFooter> {
  get links(): FooterLink[] {
    return this.raw.links.map(l => new FooterLink(l))
  }
}
