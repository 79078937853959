import { ApiCoupon } from '@/models/api/coupon'

export const initialCouponState: CouponState = {
  coupons: [],
}

export interface CouponState {
  coupons: ApiCoupon[]
  loadError?: Error,
}
