import isAfter from 'date-fns/is_after'
import Resource from './resource'

export class ApiContent extends Resource {
  static get empty(): ApiContent {
    return new EmptyContents()
  }

  get contentForPc(): string {
    return this.content || this.contentMobile
  }
  get contentForMobile(): string {
    return this.contentMobile || this.content
  }

  private get content(): string {
    return this.get('content', '')
  }
  private get contentMobile(): string {
    return this.get('content_mobile', '')
  }

  isLaterThan(other: ApiContent): boolean {
    return isAfter(this.publishAt, other.publishAt)
  }
}

/**
 * 空のApiContentを表現するためのオブジェクト.
 * 実装を簡単にしつつ、空オブジェクトで特異な振る舞いを強調するため、
 * 実装クラスを継承しつつ特異に振る舞う箇所をオーバーライドしている.
 *
 * あくまで内部実装用のクラスであり、このクラスを外部が直接使うことは想定していない.
 * したがって、このクラスを安易に export するべきではない.
 */
class EmptyContents extends ApiContent {
  constructor() {
    super({})
  }

  get contentForPc(): string {
    return ''
  }
  get contentForMobile(): string {
    return ''
  }

  isLaterThan(_: ApiContent): boolean {
    return false
  }
}
