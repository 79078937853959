import * as Error from '@/store/modules/error/types'

export interface RootState {
  error: Error.State,
}

export function createInitial(): RootState {
  return {
    error: Error.createInitial(),
  }
}
