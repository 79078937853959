import S from '@/lib/s-expression'
import { ApiProduct } from '@/models/api/product'

export class FkuQuery {
  static initialize(query: object = {}): FkuQuery {
    return new FkuQuery(
      query,
      [
        ApiProduct.TAG_PATHNAME_FKU,
      ],
      [
        'mycolor.product.subscription',
        'mycolor.hiddenset.child',
        'mycolor.product.novelty',
      ]
    )
  }

  private constructor(
    private readonly query: object,
    private readonly tagsForFilter: string[],
    private readonly tagsForWithout: string[]
  ) {}

  filterByTag(tags: string[]): FkuQuery {
    return new FkuQuery(
      this.query,
      [...this.tagsForFilter, ...tags],
      this.tagsForWithout
    )
  }
  withoutByTag(tags: string[]): FkuQuery {
    return new FkuQuery(
      this.query,
      this.tagsForFilter,
      [...this.tagsForWithout, ...tags]
    )
  }

  addQuery(q: object): FkuQuery {
    return new FkuQuery(
      { ...this.query, ...q },
      this.tagsForFilter,
      this.tagsForWithout
    )
  }

  toObject(): object {
    return {
      ...this.query,
      tagp: S.ls(
        'and',
        S.ls('and', ...this.tagsForFilter),
        S.ls('not', ...this.tagsForWithout)
      ).serialize(),
    }
  }
}
