import { log } from '@/log'
import { CookieService } from '@/services/cookie'
import { RegisterProvisionalResult, UserService } from '@ajax/modules/services/user'
import { Either } from 'fp-ts/lib/Either'

interface AuthLoadConfig {
  readonly name: string
  shouldLoad(): boolean
  load(): Promise<void>
}

// 指定された「確保されるべき認証情報」の設定に従い、認証情報不足なら取得してからコールバック実行.
// 取得の際に外部依存が発生し得るため、設定をパラメータとして受け取りそのIFにのみ依存することで
// 関数の参照透過性の維持を試みている。
const callAfterAuthDataLoad = (configs: AuthLoadConfig[]) =>
  async <T>(callback: () => Promise<T>): Promise<T> => {
    const promises = configs.map(
      config => config.shouldLoad() ? config.load() : Promise.resolve().then(() => {
        log.info(`${config.name} is not necessary, so skipped`)
      })
    )

    await Promise.all(promises)

    return callback()
  }

export interface AuthStore {
  hasAccessToken(): boolean
  hasUserId(): boolean
}
export interface UserResourceGateway {
  registerProvisional(): Promise<Either<Error, RegisterProvisionalResult>>
  rehash(): Promise<Either<Error, null>>
}
export const buildCallWithAuth = (
  store: AuthStore,
  gateway: UserResourceGateway
) => callAfterAuthDataLoad([
  {
    name: 'register provisional',
    shouldLoad: () => !store.hasAccessToken(),
    load: () => gateway.registerProvisional().then(result => result.fold(
      throwOnFail('fail register provisional'),
      onSuccess('success to reload a code')
    )),
  },
  {
    name: 'rehash auth',
    shouldLoad: () => store.hasAccessToken() && !store.hasUserId(),
    load: () => gateway.rehash().then(result => result.fold(
      throwOnFail('fail rehash current user'),
      onSuccess('success to rehash')
    )),
  },
])

/**
 * 事前に確保されるべき認証情報をすべてそろえてからコールバックを実行する.
 * ユーザー依存の情報を参照/更新するAPIは、基本的にこれを使って
 * 認証情報が揃っていることを確保した上で実行する.
 */
export const callWithAuth = buildCallWithAuth(CookieService, new UserService())

const onSuccess = (message: string) => () => {
  log.info(message)
}
const throwOnFail = (message: string) => (error: Error) => {
  log.error({ message, error })

  throw error
}
