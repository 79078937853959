import { Either, Left, Right } from 'fp-ts/lib/Either'

import { ApiContent } from '@/models/api/content'
import { ApiClient } from '@/services/api/client'
import { log } from '@/util/log'

const namespace = 'free-contents'

export default class FreeContentsService {
  static createForBrand(brand: string): FreeContentsService {
    return new FreeContentsService(brand)
  }
  static createForHost(): FreeContentsService {
    return new FreeContentsService(null)
  }

  private constructor(private brand: string | null) { }

  async list(paths: string[]): Promise<Either<Error, ApiContent[]>> {
    try {
      const response = await ApiClient
        .get(this.requestUrl, {
          pathnames: paths.join(','),
        })

      log.debug({ service: `${namespace}/list`, response })

      return new Right(
        (response.body as any[]).map(d => new ApiContent(d))
      )
    } catch (error) {
      log.error({ service: `${namespace}/list`, error })

      return new Left(error)
    }
  }

  private get requestUrl(): string {
    if (this.brand === null) {
      return '/api/free-contents'
    }

    return `/api/${this.brand}/free-contents`
  }
}
