







import Vue from 'vue'
import { Component } from 'vue-property-decorator'

import { log } from '@spa/log'

@Component
export default class Video extends Vue {
  $refs: {
    video: HTMLVideoElement,
  }

  /*
   * videoエレメントの状態＝非リアクティブな値を使用しているため、
   * computedではなくメソッドで状態判定処理を実装している.
   */

  isPlaying(): boolean {
    if (!this.canPlay()) {
      return false
    }
    if (this.$refs.video.paused) {
      return false
    }
    if (this.$refs.video.ended) {
      return false
    }

    return true
  }

  canPlay(): boolean {
    // https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/readyState
    // readyState === 0 || readyState === 1 ならまだ読み込み中
    const minPlayableState = 2
    const video = this.$refs.video

    if (video.readyState < minPlayableState) {
      return false
    }

    const error = video.error
    if (error) {
      log.error({ error })

      return false
    }

    return true
  }

  switchPlayMode(): void {
    const video = this.$refs.video

    if (this.isPlaying()) {
      video.pause()

      return
    }
    if (this.canPlay()) {
      video.play()

      return
    }
  }
}
