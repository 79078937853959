import { MutationTree } from 'vuex'

import { PurchaseState } from './types'

import { log } from '../../../log'

const namespace = 'orderHistory'

export const mutations: MutationTree<PurchaseState> = {

  list(state, payload: any[]) {
    state.error.load = null
    state.items = payload

    log.debug({ commit: `${namespace}/list`, state })
  },

  errorOnLoad(state, payload: Error) {
    const error = payload
    state.error.load =  error
  },

}
