import {
  CreditcardAuthenticationError,
  InvalidCreditcardNumberError,
  LoadingCreditcardSciptError,
  UnknownCreditcardRegisterError,
} from '@/models/payment/creditcard/repository/errors'
import { CreditcardService } from '@/services/api/creditcard'
import { CreditcardPaymentProviderService } from '@/services/payment/creditcard-payment-provider/creditcard-payment-provider-service'
import { CreditcardFormState } from '@spa/components/organisms/payment/creditcard/creditcardFormState'
import { User } from '@spa/store/modules/user/types'
import { identity } from 'fp-ts/lib/function'

export interface CreditcardRepository {
  register(creditcard: CreditcardFormState, user: User): Promise<void>
  remove(creditcardId: string): Promise<void>
}

export type CreditcardRegisterService<T extends CreditcardPaymentProviderService> =
  Pick<T, 'loadScript' | 'createRegisterCardPayload'>
export abstract class FrontAPICreditCardRepository<
  Cs extends CreditcardService,
  Cps extends CreditcardPaymentProviderService
> implements CreditcardRepository {
  private scriptLoading: Promise<void>

  public constructor(
    protected readonly creditCardService: Cs,
    protected readonly cardPaymentProvider: CreditcardRegisterService<Cps>
    ) {
    /**
     * 当初、#register内部でスクリプトロードを行うようにする予定だったが、
     * そのかたちで実装すると以下のようなエラーが発生して、処理が進まない(catchにも引っかからない模様. 別プロセスで発生している？)
     * ```
     * Failed to execute 'postMessage' on 'DOMWindow':
     * The target origin provided ('https://ptwebcollect.jp')
     * does not match the recipient window's origin ('{実行時のドメイン}').
     * ```
     * 既存実装では問題なく動いていたことを考えると、おそらく、スクリプトを取得してからある程度の時間(程度は不明)が経過した後なら大丈夫？
     * ということで、一旦、既存実装と同様に初期化タイミングでのスクリプトロードに変更した.
     * これで確実に大丈夫なわけではないが、人間の操作速度の範囲内なら、これまで動作してきたことから回避可能かと思われる.
     *
     * @see https://github.com/my-color/front/pull/6312 このワークアラウンドを入れたPR
     */
    this.scriptLoading = this.cardPaymentProvider.loadScript()
  }

  async register(creditcard: CreditcardFormState, user: User): Promise<void> {
    await this.scriptLoading.catch((e: Error) => {
      throw new LoadingCreditcardSciptError(e.message)
    })

    // スクリプトの取得が終わったことを確定させてから、後続処理を実行
    const paymentResult = await this.cardPaymentProvider.createRegisterCardPayload(
      creditcard,
      user
    )

    const token = paymentResult.fold(
      (e) => {
        throw new CreditcardAuthenticationError(e.message)
      },
      identity
    )

    const result = await this.creditCardService.create(token)

    return result.fold(
      (r: any) => {
        const message = r.response.body.message

        switch (message) {
          case 'invalid_card':
            throw new InvalidCreditcardNumberError()
          default:
            throw new UnknownCreditcardRegisterError()
        }
      },
      () => { /* nothing to do */ }
    )
  }

  async remove(creditcardId: string): Promise<void> {
    const result = await this.creditCardService.remove(creditcardId)
    result.fold(
      (error) => {
        throw error
      },
      () => undefined
    )
  }
}
