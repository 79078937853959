








































































































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import LockBackgroundMixin from '@/mixin/LockBackground'

import { ErrorContent } from '@/store/modules/error/types'

@Component({
  mixins: [
    LockBackgroundMixin,
  ],
})
export default class ErrorModal extends Vue {
  @Prop()
  message: string

  @Prop({ default: null })
  error: ErrorContent | null

  close() {
    this.$store.commit('error/clear')
    this.$emit('close')
  }
}
