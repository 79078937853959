/**
 * 定期購入の文脈でクレジットカードを参照・追加する際に付与する必要のあるパラメータ.
 *
 * Engine依存なので、本来サーバー側に配置したいが、
 * 現状この手のパラメータは大半がclientに配置されているので、やむなくclientで実装.
 *
 * @internal
 * @see https://my-color.esa.io/posts/707
 * @see https://github.com/my-color/front/issues/5636
 */
export const parametersWhichAreRequiredByEngineForSubscription: object = {
  app_kind: 'subscription',
}
