import AbstractValue from '@/models/app-config/behavior/abstract-value'

export interface RawBadgeConfig {
  label: string
  class: string[]
}

export default class BadgeConfig extends AbstractValue<RawBadgeConfig> {
  get label(): string {
    return this.raw.label
  }
  get class(): string {
    return this.raw.class.join(' ')
  }
}
