import { ApiTag } from '../tags'

export default class Category extends ApiTag {
  static readonly REGEX_CATEGORY_PATHNAME = /company\.product\.category\.([^.]+)/

  static match(tag: ApiTag): boolean {
    return Category.REGEX_CATEGORY_PATHNAME.test(tag.pathname)
  }

  get topCategoryName(): string {
    const matchIndex = 2
    const topCategory = this.pathname.match(Category.REGEX_CATEGORY_PATHNAME)

    return topCategory ? topCategory[matchIndex] : ''
  }
}
