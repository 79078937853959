import { CartScopeEq } from '@/models/cart/group/scope'
import { brandAppIdMap, hostAppIdMap, HostName } from '@/util/appid/defenitions'
import * as _ from 'lodash'

const getHostId = () => process.env.APPID_HOST || ''

export const HOST_ID = getHostId()

/**
 * AppIdの定義を参照/利用するためのFacade.
 *
 * AppIdの正引き/逆引きや、特定データ構造からappIdの引当を行いたい場合に使用する.
 */
export default class AppId {
  static readonly getHostId = getHostId

  static isHostId(id: string): boolean {
    return AppId.getHostId() === id
  }

  public static getHostName(): HostName {
    const inverted = _.invert(hostAppIdMap) as _.Dictionary<HostName>

    return inverted[getHostId()]
  }
  public static getByHostName(hostName: HostName) {
    if (!hostName) {
      return getHostId()
    }

    return hostAppIdMap[hostName]
  }

  public static getByBrandName(brandName: string | null): string {
    if (!brandName) {
      return getHostId()
    }

    return brandAppIdMap[brandName] || getHostId()
  }
  public static getBrandNameByAppId(targetAppId: string): string {
    const { brand } = Object.keys(brandAppIdMap)
      .map(b => ({ brand: b, appId: brandAppIdMap[b] }))
      .find(({ appId }) => appId === targetAppId) || { brand: 'mycolor' }

    return brand
  }
  public static getByCartScope(cartScope: CartScopeEq): string {
    if (cartScope.kind.isBrand) {
      return this.getByBrandName(cartScope.value.toString())
    }

    return getHostId()
  }
}
