import _ from 'lodash'

import { ApiProduct } from '@/models/api/product'
import SaleStatus, { noSelling, nowOnSale } from '../sale-status'

const map = {
  [ApiProduct.SALES_STATUS_AVAILABLE]: nowOnSale,
  [ApiProduct.SALES_STATUS_DELETED]: noSelling,
}

export function create(statusId: number): SaleStatus {
  return _.get(map, statusId, nowOnSale)
}
