




















import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import { UserReview as UserReviewModel } from '@/models/user-review'
import UserReview from './UserReview.vue'

@Component({
  components: {
    UserReview,
  },
})
export default class UserReviewList extends Vue {
  @Prop({ required: true })
  reviewList: UserReviewModel[]

  @Prop({ required: true })
  brand: string
}
