import { None, Option, Some } from 'fp-ts/lib/Option'
import Cookies from 'js-cookie'

export class AdCodeStore {
  static initialize(loader: AdCodeLoader, recorder: AdCodeRecorder) {
    return new AdCodeStore(loader, recorder)
  }

  private constructor(
    private readonly loader: AdCodeLoader,
    private readonly recorder: AdCodeRecorder
  ) { }

  load(): void {
    const adCode = this.loader.load()

    if (isString(adCode)) {
      this.recorder.write(adCode)
    }
  }

  read(): Option<string> {
    const adCode = this.recorder.read()

    return isString(adCode) ? new Some(adCode) : None.value
  }
}

type StringMaybeEmpty = string | null | undefined
export interface AdCodeLoader {
  load(): StringMaybeEmpty
}

export interface AdCodeRecorder {
  write(adCode: string): void
  read(): StringMaybeEmpty
}

const urlAdCodeLoader: AdCodeLoader = {
  load: () => {
    const urlParams = new URLSearchParams(window.location.search)
    const adCode = urlParams.get('m')

    return adCode || null
  },
}
const cookieAdCodeRecorder: AdCodeRecorder = {
  write(adCode: string): void {
    Cookies.set('c', adCode, { expires: 3650 })
  },
  read(): StringMaybeEmpty {
    return Cookies.get('c')
  },
}

const isString = (str: StringMaybeEmpty): str is string => str !== null && str !== undefined

export const adCodeStore: AdCodeStore = AdCodeStore.initialize(
  urlAdCodeLoader,
  cookieAdCodeRecorder
)
