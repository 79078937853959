import { RootState } from '@ajax/vue/store/types'
import { none } from 'fp-ts/lib/Option'
import { Module } from 'vuex'
import { actions } from './actions'
import { getters } from './getters'
import { mutations } from './mutations'
import { CartButtonInItemListState } from './types'

export function createCartButtonInItemListState(): Module<CartButtonInItemListState, RootState> {
  return {
    namespaced: true,
    state: {
      processingButtonId: none,
    },
    actions,
    mutations,
    getters,
  }
}
