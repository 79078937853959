import { ApiProductclass } from '@/models/api/productclass'
import FKU from '@/models/item/fku'
import * as SKUFactory from '@/models/item/sku/factory/sku'
import FKUId from '../id'

export function create(productclass: ApiProductclass): FKU {
  if (!productclass.isFku) {
    throw new InvalidResourceError(
      `resource must be fku productclass object, but not fku passed: ${JSON.stringify(productclass)}`
    )
  }

  const id = new FKUId(productclass.id, productclass.shortId)
  const skuList = productclass.materializedSkuList.map(SKUFactory.createFromSkuInProductclass)

  return new FKU(
    id,
    skuList
  )
}

export class InvalidResourceError {
  constructor(readonly message: string) { }
}
