








import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

type CharacterKind = 'unicorn-running' | 'unicorn-sitting' | 'fairy-left' | 'fairy-right' // 適宜追加
type Size = 'normal' | 'large' // 適宜追加

@Component
export default class Character extends Vue {
  @Prop({ required: true })
  kind: CharacterKind

  @Prop({ default: 'normal' })
  size: Size

  get _class() {
    const base = 'p-character'

    return {
      comment: {
        [`${base}__comment`]: true,
        [`${base}__comment--${this.size}`]: true,
      },
      root: {
        'p-character': true,
        [`p-${this.kind}`]: true,
      },
    }
  }
}
