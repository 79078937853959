import { Price } from '@/components/molecules/item/price/model/item-price-view'
import {
  loaderOnPaidMemberPriceDisabled
} from '@/components/molecules/item/price/model/loader/loader-on-paid-member-price-disabled'
import { SimplePaidMemberItemPriceLoader } from '@/components/molecules/item/price/model/loader/simple-item-price-loader'
import { DefaultSkuPriceRepository } from '@/models/api/paid-member-price/sku-price/sku-price-repository'
import BehaviorConfig from '@/models/app-config/behavior/behavior'
import { Option } from 'fp-ts/lib/Option'

/**
 * 指定されたfkuに対応する有料会員価格情報を取得するためのI/F.
 *
 * v1実装時点では「既存に大手術を加えなくても良いかたち」を優先して実装しているが、
 * v1時点での商品一覧表示に関する実装や仕様は不確定な部分が多いことから、
 * v2以降で実行効率や保守の観点から全く異なる実装へ交換する必要がでたときに備えて、I/Fを分離しておく.
 *
 */
export interface PaidMemberItemPriceLoader {
  getPriceForFku(param: GetPaidMemberPriceForFkuParameter): Promise<Option<Price>>
}
export interface GetPaidMemberPriceForFkuParameter {
  fkuId: string
  skuIds: string[]
}

const loaderPathMap = new Map<string, PaidMemberItemPriceLoader>()
/**
 * 指定された画面パスごとに、単一の ItemPriceLoader を生成する.
 *
 * 既に指定された画面パスを再度指定した場合、以前に生成されたインスタンスを再利用し、新規生成はしない.
 *
 * #5842 のv1対応時点では、MPA対応などを踏まえて考えた場合、価格コンポーネントに実装を追加するのがもっとも影響範囲が小さかった.
 * しかし、コンポーネントごとにデータローダ――のオブジェクトを生成するのはリソース効率が悪く、対して、一度生成されたオブジェクトを再利用するのは
 * 大した手間ではないため、factory関数を経由して、ブランド + 画面パスごとに共通のオブジェクトを再利用する形にした.
 * ブランド + パス単位での再利用としたのは、SPAでページ遷移しながらレンダリングしたり、複数ブランドの商品をレンダリングする場面などで、
 * 不用意に再利用が発生しないようにするため.
 *
 * @param path
 * @see https://github.com/my-color/front/issues/5842
 */
export function getSingletonPaidMemberPriceLoaderForPath(brand: string, path: string): PaidMemberItemPriceLoader {
  const key = `brand_is_${brand}-path_is_${path}`
  const loader = loaderPathMap.get(key)

  if (loader !== undefined) return loader

  const behaviorConfig = BehaviorConfig.createFromBrand(brand)
  const newLoader = behaviorConfig.paidMembership.enabled
    /*
     * 以下の観点から、実装を交換する可能性アリ
     * - 実行効率(FKU単位でなく、ある程度SKUをまとめてリクエストできると好ましいかも)
     * - 実装のserver側集約(SKU APIから取ったデータで解決するのではなく、専用のAPIを作って、そこに変換処理を集約する)
     *   - FKU用価格のAPIを実装しようとしたが、DTO → ドメインオブジェクトの変換などで手間がかかりそうだったので、
     *     一旦clientでの実装で簡便に済ませるにとどめた.
     *   - FKU用価格のAPIを実装した場合、そのAPIを叩いた結果をデータオブジェクトを構築し、返す実装とすげ替える想定.
     *   - 手間がかかる部分について、具体例として↓など
     *     - FKU 構築用のProductclass を取得するとき、 with=aux,parts を付ければ有料会員価格設定付きのSKUを同時に取得できる
     *     - しかし、Productclass#getMaterializedSku で取得したSKUオブジェクトを
     *       直接 src/Gateway/Model/PaidMembership/SKU/EngineSkuRepository.php で使えない
     *     - 型定義的に継承関係になく、PHPのtype hintはnominal typeなので、そのまま渡すと Type Error になる
     *     - 型定義を揃えるためだけにほとんど同じ内容で継承関係だけ異なるオブジェクトに詰替えるか、継承ツリーを調整する必要がある
     *       - このあたりで手間がかかりそうだったので、APIの実装は一旦あとに回した.
     */
    ? new SimplePaidMemberItemPriceLoader(new DefaultSkuPriceRepository(brand))
    : loaderOnPaidMemberPriceDisabled

  loaderPathMap.set(key, newLoader)

  return newLoader
}
