import { AddToFavoriteEvent } from '../../event'

export function handleAddToFavorite(event: AddToFavoriteEvent) {
  const { fkuId, fkuName } = event

  window.dataLayer.push({
    event: 'add_to_wishlist',
    ecommerce: {
      items: [{
        item_name: fkuName,
        item_id: fkuId,
        currency: 'JPY',
        /**
         * 価格はv1ではMUSTではない。
         */
        // price: "5000",
        quantity: 1,
      }],
    },
  })
}
