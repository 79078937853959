import { CookieService } from '@/services/cookie'

export interface HtmlMeta {
  name: string
  content?: string
}

export const getZealsMeta = (): HtmlMeta[] => {
  return [
    { name: 'zeals-member-id', content: CookieService.getUserId() },
  ]
}
