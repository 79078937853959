import { Either, Left, Right } from 'fp-ts/lib/Either'
import _ from 'lodash'
import Parameter, { CreateParameterError, ParameterType } from '../parameter'

/**
 * @see https://my-color.esa.io/posts/534
 */
export default class Variable implements Parameter {

  static createFromApiResponse(data: any): Either<Error, Variable> {
    const required: string[] = ['id', 'variable_id']
    const missedKeys = _.compact(required.map((key) => {
      return data[key] === undefined ? key : null
    }))

    if (missedKeys.length > 0) {
      return new Left(
        new CreateParameterError(`Error on creating Variable: ${missedKeys.join(', ')} is required.`)
      )
    }

    return new Right(
      new Variable(
        data.id,
        data.variable_id,
        data.required || false
      )
    )
  }

  constructor(
    public readonly id: string,
    public readonly variableId: string,
    public readonly required: boolean
  ) {}

  get type(): ParameterType {
    return 'variable'
  }
}

export const isVariable = (parameter: Parameter): parameter is Variable => {
  return parameter.type === 'variable'
}
