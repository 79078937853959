import { ApiProduct } from '@/models/api/product'
import RootState from '@/models/item/root/state'
import EachStateSpec from '@/models/item/root/state/specification/each/each-state-spec'
/**
 * https://docs.google.com/spreadsheets/d/1ZvWEL9Q9FGgB24JKAjD8roi75KEHoT6uv7xMPhITDZI/edit#gid=2052072130
 */
export default class CanRequestNewItemSpec implements EachStateSpec {
  /**
   * TODO: SKUオブジェクトを受け取って処理するようにする
   *
   * 現時点ではSKUのドメインオブジェクトに商品リクエストの知識が入っていないため
   */
  verify(products: ApiProduct[]): boolean {
    return products.some(prod => prod.isOnNewItemRequest)
  }

  get state(): RootState {
    return RootState.CanRequestNewItem
  }
}
