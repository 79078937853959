import { MutationTree } from 'vuex'

import { log } from '@/log'
import { BundleDiscount } from '@/services/api/bundle-discount'
import { appendBrand } from './functions'
import { BundleDiscountState } from './types'

const namespace = 'bundle-discount'

export const mutations: MutationTree<BundleDiscountState> = {
  set(state, payload: SetParameter) {
    const { appId, bundleDiscounts } = payload

    state.brands = appendBrand(state, {
      appId,
      bundleDiscounts,
      isLoaded: true,
      error: null,
    })

    log.debug({ commit: `${namespace}/set`, state })
  },

  setError(state, payload: SetErrorParameter) {
    const { appId, error } = payload

    state.brands = appendBrand(state, {
      appId,
      bundleDiscounts: [],
      isLoaded: true,
      error,
    })

    log.debug({ commit: `${namespace}/set`, state })
  },

  clearAll(state) {
    state.brands = []

    log.debug({ commit: `${namespace}/clear`, state })
  },
}

export interface SetParameter {
  appId: string,
  bundleDiscounts: BundleDiscount[]
}

export interface SetErrorParameter {
  appId: string,
  error: Error,
}
