
































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import AjaxModal from '../../templates/TransparentModal.vue'

@Component({
  components: {
    AjaxModal,
  },
})
export default class AddToCartModal extends Vue {

  @Prop({ required: true, default: false })
  showModal: boolean

  @Prop({ required: true })
  id: string

  private openSubModal: boolean = false

  /**
   * iOSでサブモーダル （商品リクエストモーダル ）の表示が崩れるのを防ぐための暫定対応.
   * 将来的には
   * - スタイル定義の修正により、サブモーダル が親モーダル に依存しない様にする
   * あるいは
   * - モーダルをネストするのではなく、複数のモーダルを並列に表示し、親子関係そのものを無くす
   *   - z-indexの取り扱い注意必要かも
   * - サブモーダルのDOM位置を変更して、親モーダルに依存しないようにする
   * 等の対策を検討する.
   */
  get adhocCartModalClass() {
    return {
      'p-add-to-cart-modal--with-sub-modal': this.openSubModal,
    }
  }

  closeModal() {
    this.$emit('close')
  }

  onToggleSubModal() {
    this.openSubModal = !this.openSubModal
  }
}
