import PreviewNotification from '@/components/organisms/PreviewNotification.vue'
import Router from 'vue-router'

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '*',
      component: PreviewNotification,
    },
  ],
})
