import { BundlePurchaseItem } from '../../bundle-purchase-item'

export interface AddToPurchaseListSpec {
  isSatisfiedBy(newItem: BundlePurchaseItem): boolean
}

export class AddToPurchaseListSpecFactory {
  constructor(private readonly specs: AddToPurchaseListSpec[]) { }

  create(): AddToPurchaseListSpec {
    return {
      isSatisfiedBy: (newItem) => {
        return this.specs.every(spec => spec.isSatisfiedBy(newItem))
      },
    }
  }
}
