import { OrderResult } from '@/models/order/completed/order-result'
import { Contact } from '@/models/order/order'
import { OrderSummary } from '@/models/order/summary'
import { actions } from '@spa/store/modules/subscriptionConfirm/action'
import { getters } from '@spa/store/modules/subscriptionConfirm/getters'
import { mutations } from '@spa/store/modules/subscriptionConfirm/mutations'
import { RootState } from '@spa/store/types'
import { cloneDeep } from 'lodash'
import { Module } from 'vuex'

export interface SubscriptionConfirmState {
  /**
   * @deprecated 将来的にはstateから取り除き、getter経由で取得可能にする
   *
   * 本来、summaryはorderResultsなどから算出可能としておきたいが、
   * 現時点(at 2021/06/15)ではsummaryの算出が個別のUIコンポーネントにハードコードされており、
   * かつその算出に使われるデータソースはデータソースのみがstoreへ分離されているという状態で、
   * すぐの分離が難しい.
   *
   * そのため、いったんstoreにsummary全体を保存する形を取っているが、
   * 本来なら order or orderResult -> summary の算出を独立したモジュールとして一元化し、
   * UIコンポーネントとstoreはその独立モジュールを経由してsummaryを導出する形を取りたい.
   */
  summary: OrderSummary
  orderResults: OrderResult[]
  delivery: StoredDelivery
}
export interface StoredDelivery {
  contact: Contact
}
const subscriptionConfirmState: SubscriptionConfirmState = {
  summary: {
    paymentPrice: {
      total: 0,
      subtotal: 0,
      commission: {
        total: 0,
        count: 0,
      },
      shipping: {
        total: 0,
        count: 0,
      },
    },
  },
  orderResults: [],
  delivery: {
    contact: {
      name: {
        first_name: '',
        last_name: '',
      },
      address: {
        country: '',
        code: '',
        locality: '',
        street: '',
      },
      tel: '',
    },
  },
}

export const subscriptionConfirm: Module<SubscriptionConfirmState, RootState> = {
  namespaced: true,
  state: cloneDeep(subscriptionConfirmState),
  actions,
  mutations,
  getters,
}
