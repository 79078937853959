
/**
 * EverforthObjectのUniversal IDからユニークIDを抽出
 * Universal IDフォーマット：{major}-{minor}-{timestamp}-{class}-{uuid}
 * @param {string} universalId
 * @returns {string}
 */
export function extractUniqueIdFromUniversalId(universalId: string): string | null {
  const uuidMatcher = /[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i
  const matchResult = universalId.match(uuidMatcher)

  if (matchResult !== null) {
    return matchResult[0]
  }

  return null
}
