import _ from 'lodash'

export function createLowerCases(): string[] {
  return createAlphabetList('a', 'z')
}

export function createUpperCases(): string[] {
  return createAlphabetList('A', 'Z')
}

function createAlphabetList(start: string, end: string): string[] {
  const startCode = start.charCodeAt(0)
  const endCode = end.charCodeAt(0)

  return _.range(startCode, endCode + 1).map(charCode => String.fromCharCode(charCode))
}
