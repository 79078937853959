
















import { Mixins } from 'vue-mixin-decorator'
import { Component } from 'vue-property-decorator'

import SearchSysCondCommon from './SearchSysCondCommon.vue'

@Component
export default class SearchSys5Cond extends Mixins<SearchSysCondCommon>(SearchSysCondCommon) {

}
