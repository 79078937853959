export interface State {
  error: ErrorContent | null
}

export interface ErrorContent {
  message: string
}

export function createInitial(): State {
  return {
    error: null,
  }
}
