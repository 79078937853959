import AbstractValue from '@/models/app-config/behavior/abstract-value'
import FewBadge, { RawFewBadge } from '@/models/app-config/behavior/config/item-list/item/badge/few'
import BadgeConfig, { RawBadgeConfig } from './badge/badge-config'

export interface RawItemBadge {
  position: BadgePosition
  few: RawFewBadge
  newItemRequest: RawBadgeConfig
  restockRequest: RawBadgeConfig
  restock: RawBadgeConfig
  newItem: RawBadgeConfig
  presale: RawBadgeConfig
  discount: RawBadgeConfig
  soldOut: RawBadgeConfig
  comingSoon: RawBadgeConfig
}

export enum BadgePosition {
  Top = 'top',
  Bottom = 'bottom',
}

export default class ItemBadge extends AbstractValue<RawItemBadge> {
  get showOnTop(): boolean {
    return this.showOn(BadgePosition.Top)
  }
  get showOnBottom(): boolean {
    return this.showOn(BadgePosition.Bottom)
  }

  get few(): FewBadge {
    return new FewBadge(this.raw.few)
  }

  get newItemRequest(): BadgeConfig {
    return new BadgeConfig(this.raw.newItemRequest)
  }
  get restockRequest(): BadgeConfig {
    return new BadgeConfig(this.raw.restockRequest)
  }
  get restock(): BadgeConfig {
    return new BadgeConfig(this.raw.restock)
  }
  get newItem(): BadgeConfig {
    return new BadgeConfig(this.raw.newItem)
  }
  get presale(): BadgeConfig {
    return new BadgeConfig(this.raw.presale)
  }
  get discount(): BadgeConfig {
    return new BadgeConfig(this.raw.discount)
  }
  get soldOut(): BadgeConfig {
    return new BadgeConfig(this.raw.soldOut)
  }
  get comingSoon(): BadgeConfig {
    return new BadgeConfig(this.raw.comingSoon)
  }

  private showOn(position: BadgePosition): boolean {
    return this.raw.position === position
  }
}
