import { OrderResult } from '@/models/order/completed/order-result'
import { OrderSummary } from '@/models/order/summary'
import { StoredDelivery, SubscriptionConfirmState } from '@spa/store/modules/subscriptionConfirm/index'
import { RootState } from '@spa/store/types'
import { ActionTree } from 'vuex'

export interface SetCompletedState {
  /**
   * @deprecated 将来的にはstoreに直接保存せず、storeが独力で算出できるようにする
   * @see SubscriptionConfirmState.summary
   * @see client/spa/src/store/modules/orderConfirm/subscription/index.ts
   */
  summary: OrderSummary
  orderResults: OrderResult[]
  delivery: StoredDelivery
}

export const actions: ActionTree<SubscriptionConfirmState, RootState> = {
  setCompletedState({ commit }, { summary, orderResults, delivery }: SetCompletedState) {
    commit('setOrderResults', orderResults)
    commit('setDelivery', delivery)
    commit('setSummary', summary)
  },
}
