

































































































import { SearchContactLensCond } from '@/store/modules/searchContactLens/types'
import { Mixins } from 'vue-mixin-decorator'
import { Component } from 'vue-property-decorator'

import SearchSys4Cond from './cond/SearchSys4Cond.vue'
import SearchSys4Item from './item/SearchSys4Item.vue'
import SearchContactLensBase from './SearchContactLensBase.vue'

@Component({
  components: {
    SearchSys4Cond,
    SearchSys4Item,
  },
})
export default class SearchContactLens4 extends Mixins<SearchContactLensBase>(SearchContactLensBase) {
  initialSearchCond: SearchContactLensCond = {
    hasSPDrawing: false,
    matchValues: [],
  }

  // ページ読み込み開始時.
  created() {
    this.setInitialSearchCondition(this.initialSearchCond)
    this.$emit('loadingStart')
  }

  // ページ読み込み完了時.
  async mounted() {
    this.setPageTitle('DIA、着色直径、BCなど簡単カラコン絞込検索')
    $('#v1')['controls'] = false
    await this.onMounted()
    this.$emit('loadingStop')
  }

  onSwith() {
    this.clearRemodal()
    this.$emit('loadingStart')
    this.$router.push({ name: '5' })
  }
}
