import { BrandService } from '@/services/api/brand'
import { CookieService } from '@/services/cookie'
import AppId from '@/util/appid'

export async function sendLog(brandEnglishName: string | null): Promise<void> {
  /**
   * NOTICE: pcpa ライブラリが見つからない場合、グローバルスコープに pcpa という変数が宣言されていないので、
   * if (!pcpa) などと書くと、条件文を評価するタイミングでpcpaという変数が見つからず、スクリプトエラーになる
   */
  if (typeof pcpa === 'undefined') {
    return Promise.reject('pcpa is not defined')
  }

  const appid = AppId.getByBrandName(brandEnglishName)
  const brandId = await new BrandService().resolveBrandIdFromEnglishName(brandEnglishName)
  const userId = CookieService.getUserId()

  const config: PCPAActionConfig = {
    appid,
    campaignKeys: {
      source: 'm',
    },
  }
  if (brandId !== null) config.brandId = brandId
  if (userId !== undefined) config.userId = userId

  const client = new pcpa.PCPAnalyticsClient(config)
  client.sendAction({ kind: 'pageview' })
}
