
































import _ from 'lodash'
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import DummyItem from '@/components/molecules/DummyItem.vue'
import { log } from '@/log'
import { ApiProductclass } from '@/models/api/productclass'

/**
 * 商品一覧の表示を共通化するために使用.
 *
 * TODO:
 * keyをこのコンポーネント内で解決可能にしたい.
 * 一部スタイルがDOM構造に依存しているので、一旦、各自利用コンポーネント側でkey指定.
 */
@Component({
  components: {
    DummyItem,
  },
})
export default class ItemList extends Vue {
  @Prop({ default: 'div' })
  tag: string

  @Prop({ required: true })
  itemList: any[]

  // FIXME: This is an pensive workaround for displaying dummy item in autoload.
  // Usually 'viewList' filters out data without Root, in spite of that dummy data should be displayed.
  // In future, refactor the whole structure of the relavent Vue components and an Item or Page model.
  get itemListIsAllDummy(): boolean {
    return _.every(this.itemList, item => item === null)
  }

  get viewList(): any[] {
    if (this.itemListIsAllDummy) {
      // SKip because dummy data does not need to be checked if FKU has ROOT.
      return []
    }

    return this.itemList.filter((item) => {
      const fku = new ApiProductclass(item)

      if (fku.root === null) {
        log.warn(`fku ${item.id} not has root, so skipped`)

        return false
      }

      return true
    })
  }
}
