import { Either, Left, Right } from 'fp-ts/lib/Either'
import superagent from 'superagent'

import { log } from '@/log'
import { ApiService } from '@/services/api/service'
import { ApiClient } from './client'

const namespace: string = 'auth'

export interface UserRegistrationPayload {
  username: string
  password: string
  email: string
  first_name?: string
  last_name?: string
  phonetic_first_name?: string
  phonetic_last_name?: string
  zipcode?: string
  region_id?: string | null
  address1?: string
  address2?: string
  tel?: string
  is_delivery?: boolean
  display_level?: number
  // TODO: 必要？
  registered_at: string
}

export default class Auth extends ApiService {

  /**
   * Register a new user
   * As as side-effect, authentication-related cookie is set
   */
  async register(payload: UserRegistrationPayload): Promise<Either<Error, superagent.Response>> {
    try {
      const response = await ApiClient
        .post('/api/register')
        .send(payload)

      log.debug({ service: `${namespace}/register`, response })

      return new Right(response.body.data)
    } catch (error) {
      log.error({ service: `${namespace}/register`, error })

      return new Left(error)
    }
  }

  /**
   * Login to server
   * As a side-effect, authentication-related cookie is set
   * @param username
   * @param password
   */
  async login(
    username: string,
    password: string
  ): Promise<Either<Error, superagent.Response>> {
    try {
      const response = await ApiClient
        .post('/api/login')
        .send({
          username,
          password,
        })

      log.debug({ service: `${namespace}/login`, response })

      return new Right(response.body.data)
    } catch (error) {
      log.error({ service: `${namespace}/login`, error })

      return new Left(error)
    }
  }

  async sendPasswordResetEmail(
    email: string
  ): Promise<Either<Error, superagent.Response>> {
    // NOTE: /resetpassword really needs to be called under /user-proxy?

    try {
      const response = await ApiClient
        .post(`/api/user-proxy/${this.appId}/resetpassword`)
        .send({ email })

      log.debug({ service: `${namespace}/sendPasswordResetEmail`, response })

      return new Right(response.body.data)
    } catch (error) {
      log.error({ service: `${namespace}/sendPasswordResetEmail`, error })

      return new Left(error)
    }
  }

  /**
   * Logout
   * As a side-effect, authentication-related cookie is unset
   */
  async logout(): Promise<Either<Error, superagent.Response>> {
    try {
      const response = await ApiClient
        .post('/api/logout')

      log.debug({ service: `${namespace}/logout`, response })

      return new Right(response.body.data)
    } catch (error) {
      log.error({ service: `${namespace}/logout`, error })

      return new Left(error)
    }
  }
}
