import Badge from '@/components/molecules/item/model/badge'
import BehaviorConfig from '@/models/app-config/behavior/behavior'
import GetStockStatus from '@/usecase/get-stock-status'
import { none } from 'fp-ts/lib/Option'
import { BadgeSpecBuilder } from '../badge-spec'
import { BadgeListDisplayPolicy } from '../display-policy'
import {
  AcceptNewItemRequestRule,
  AcceptRestockRequestRule,
  ComingSoonRule,
  ComingSoonSoldOutRule,
  IsFewRule,
  IsNewRule,
  IsOnPreSaleRule,
  IsOnSaleRule,
  IsRestockRule,
  PreSaleSoldOutRule,
  PurchasableRule,
} from '../display-rule'
import { RuleApplicable } from '../rule-applicable'
import { sortBadgeListInOrder } from '../sort-spec'

export class MyColorBadgeListDisplayPolicy implements BadgeListDisplayPolicy {
  private getStockStatus: GetStockStatus

  constructor(private readonly behavior: BehaviorConfig) {
    this.getStockStatus = GetStockStatus.createViaConfig(this.behavior)
  }

  list(policyInput: RuleApplicable): Badge[] {
    const item = policyInput.toInput()
    const badgeBehavior = this.behavior.itemList.item.badge

    const isOnPreSaleRule = new IsOnPreSaleRule()
    const preSaleSoldOutRule = new PreSaleSoldOutRule()
    const isOnSaleRule = new IsOnSaleRule()
    const isNewRule = new IsNewRule()
    const isRestockRule = new IsRestockRule()
    const newItemRequestRule = new AcceptNewItemRequestRule()
    const restockRequestRule = new AcceptRestockRequestRule()
    const isFewRule = new IsFewRule(this.getStockStatus)
    const purchasableRule = new PurchasableRule()
    const comingSoonRule = new ComingSoonRule()
    const comingSoonSoldOutRule = new ComingSoonSoldOutRule()

    const classWhenFewStock =
      this.behavior.itemList.item.badge.few.optionClass || ''
    const fewBadgeSpec = BadgeSpecBuilder.create(
      this.getStockStatus.toText(item.stock),
      'p-goods__badge--few',
      [classWhenFewStock]
    ).ruleIs(purchasableRule.and(isFewRule))

    const newItemRequestBadgeSpec = BadgeSpecBuilder.create(
      badgeBehavior.newItemRequest.label,
      'p-goods__badge--request-new'
    ).ruleIs(
      purchasableRule.not()
        .and(isOnPreSaleRule.not())
        .and(comingSoonRule.not())
        .and(newItemRequestRule)
    )

    const restockRequestBadgeSpec = BadgeSpecBuilder.create(
      badgeBehavior.restockRequest.label,
      'p-goods__badge--request-restock'
    ).ruleIs(
      purchasableRule.not()
        .and(isOnPreSaleRule.not())
        .and(comingSoonRule.not())
        .and(newItemRequestBadgeSpec.rule.not())
        .and(restockRequestRule)
    )

    const preSaleSoldOutBadgeRule = purchasableRule.not()
                                      .and(isOnPreSaleRule.not())
                                      .and(preSaleSoldOutRule)
                                      .and(comingSoonRule.not())
                                      .and(newItemRequestBadgeSpec.rule.not())
                                      .and(restockRequestBadgeSpec.rule.not())
    const preSaleRule = isOnPreSaleRule.or(preSaleSoldOutBadgeRule)
    const preSaleBadgeSpec = BadgeSpecBuilder.create(
      badgeBehavior.presale.label,
      'p-goods__badge--presale'
    ).ruleIs(purchasableRule.not().and(preSaleRule))

    /**
     * NOTE: COEL以外では入荷待ちバッジを表示しないが、「入荷待ち状態」自体は利用する
     * ref: https://my-color.esa.io/posts/342
     */
    const comingSoonState = purchasableRule.not()
                              .and(preSaleBadgeSpec.rule.not())
                              .and(comingSoonRule)
    const comingSoonSoldOutState = purchasableRule.not()
                                     .and(preSaleBadgeSpec.rule.not())
                                     .and(comingSoonRule.not())
                                     .and(newItemRequestBadgeSpec.rule.not())
                                     .and(restockRequestBadgeSpec.rule.not())
                                     .and(preSaleSoldOutBadgeRule.not())
                                     .and(comingSoonSoldOutRule)
    const hasComingSoonItem = comingSoonState.or(comingSoonSoldOutState)

    const normalItemSoldOutRule = purchasableRule.not()
      .and(preSaleBadgeSpec.rule.not())
      .and(hasComingSoonItem.not())
      .and(newItemRequestBadgeSpec.rule.not())
      .and(restockRequestBadgeSpec.rule.not())
    const soldOutBadgeSpec = BadgeSpecBuilder.create(
      badgeBehavior.soldOut.label,
      'p-goods__badge--soldout'
    ).ruleIs(
      normalItemSoldOutRule
        .or(preSaleSoldOutBadgeRule)
        .or(comingSoonSoldOutState))

    const normalItemRule = purchasableRule.or(normalItemSoldOutRule)

    const restockBadgeSpec = BadgeSpecBuilder.create(
      badgeBehavior.restock.label,
      'p-goods__badge--rearrival'
    ).ruleIs(normalItemRule.and(isRestockRule))

    const saleBadgeSpec = BadgeSpecBuilder.create(
      badgeBehavior.discount.label,
      'p-goods__badge--discount'
    ).ruleIs(normalItemRule.and(isOnSaleRule))

    const newBadgeSpec = BadgeSpecBuilder.create(
      badgeBehavior.newItem.label,
      'p-goods__badge--new'
    ).ruleIs(normalItemRule.and(isNewRule))

    return sortBadgeListInOrder({
      preSale: preSaleBadgeSpec.getBadgeFor(item),
      discountSale: saleBadgeSpec.getBadgeFor(item),
      new: newBadgeSpec.getBadgeFor(item),
      restock: restockBadgeSpec.getBadgeFor(item),
      few: fewBadgeSpec.getBadgeFor(item),
      soldout: soldOutBadgeSpec.getBadgeFor(item),
      commingSoon: none,
      newItemRequest: newItemRequestBadgeSpec.getBadgeFor(item),
      restockRequest: restockRequestBadgeSpec.getBadgeFor(item),
    })
  }
}
