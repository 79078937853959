import { SimplePayment } from '@spa/store/modules/orderConfirm/types'

import { PaymentParamBuilder } from './paymentParamBuilder'

export default class SimplePaymentParamBuilder implements PaymentParamBuilder {
  public constructor(private readonly payment: SimplePayment) {}

  build(): string {
    const {
      payment_type,
      point,
    } = this.payment  // TODO: Use generics

    const param = {
      payment_type,
      point,
    }

    return JSON.stringify(param)
  }
}
