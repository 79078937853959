import { log } from '@/log'
import { BrandMaintenanceList } from '@/models/api/brand-maintenance/brand-maintenance-list'
import { ApiClient } from '@/services/api/client'
import { ApiService } from '@/services/api/service'
import { Either, left, right } from 'fp-ts/lib/Either'

const namespace: string = 'brand-maintenance'

export class BrandMaintenanceService extends ApiService {
  async list(): Promise<Either<Error, BrandMaintenanceList>> {
    try {
      const response = await ApiClient.get(`/api/common/brand_maintenance/list`)

      log.debug({ service: `${namespace}/list`, response })

      return right(BrandMaintenanceList.createFromBrandEnglishNameList(response.body.data))
    } catch (error) {
      return left(error)
    }
  }
}
