import { executeWebCustomerConversionTag } from '@/vendor/web-customer-service-tool'
import { executeConversionTag as executeCribnotesConversionTag } from '@spa/vendor/cribnotes'
import _ from 'lodash'
import { PurchaseEvent } from '../../event'

export function handlePurchase(event: PurchaseEvent): void {
  sendToGA(event)
  sendToWebCustomerTool(event)
  sendToCribnotes(event)
}

function sendToGA(event: PurchaseEvent): void {
  const payload = convertToGAFormat(event)

  payload.forEach((p) => {
    window.dataLayer.push(p)
  })
}

function sendToWebCustomerTool(event: PurchaseEvent): void {
  const payload = convertToGAFormat(event)

  /**
   * Web接客ツールは（たまたま）、GA（UA）に送信されるのと同じe-commerceデータ型を前提として調整されている。
   */
  executeWebCustomerConversionTag(payload)
}

function sendToCribnotes(event: PurchaseEvent): void {
  const { orderResultSuccessList } = event

  orderResultSuccessList.forEach((result) => {
    const items = _.map(result.order.cartItems, item => ({
      item_id: item.skuId,
      price: item.unitPrice,
      quantity: item.unitCount,
    }))

    executeCribnotesConversionTag(result.commerceSalesOrder.compressedShortId, items)
  })
}

interface PurchaseToGA {
  event: 'e-commerce'
  transactionId: string
  transactionTotal: number
  transactionTax: number
  transactionProducts: Array<{
    sku: string
    name: string
    price: number
    quantity: number
  }>
}

function convertToGAFormat(event: PurchaseEvent): PurchaseToGA[] {
  const { orderResultSuccessList } = event

  return orderResultSuccessList.map((result) => {
    const subtotal = result.commerceSalesOrder.subtotalPrice
    const tax = result.commerceSalesOrder.tax
    const products = _.map(result.order.cartItems, cartItem => ({
      sku: cartItem.skuId,
      name: cartItem.name,
      price: cartItem.unitPrice,
      quantity: cartItem.unitCount,
    }))

    return {
      event: 'e-commerce',
      transactionId: result.commerceSalesOrder.compressedShortId,
      transactionTotal: subtotal,
      transactionTax: tax,
      transactionProducts: products,
    }
  })
}
