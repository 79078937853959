import AbstractValue from '@/models/app-config/behavior/abstract-value'

export interface RawItemUnderMaintenance {
  useImage?: boolean
  imageSource?: ImageSource
}

export interface ImageSource {
  kind: ImageSourceKind
}

export type ImageSourceKind = 's3'

export interface S3ImageSource extends ImageSource {
  kind: 's3'
  path: string
}

export function isS3ImageSource(imageSource: ImageSource): imageSource is S3ImageSource {
  return imageSource.kind === 's3'
}

export class ItemUnderMaintenance extends AbstractValue<RawItemUnderMaintenance> {
  get useImage(): boolean {
    return this.raw.useImage || false
  }

  get imageSource(): ImageSource | null {
    if (!this.raw.imageSource) {
      return null
    }

    return this.raw.imageSource
  }
}
