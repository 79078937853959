










import _ from 'lodash'
import { Mixins } from 'vue-mixin-decorator'
import { Component } from 'vue-property-decorator'

import { HOST_ID } from '@/util/appid'
import Util from '../../../modules/util'
import ApiMixin from '../../mixin/Api'

@Component
export default class Refine extends Mixins<ApiMixin>(ApiMixin) {

  // 表示用データ
  data: any = {
    brand: [],
    category: [],
    color: [],
  }

  // FIXME: 強引に一つのオブジェクトで全処理を回そうとして、全体の見通しが非常に悪い
  // TODO: 一つのオブジェクトをループさせて強引に共通化するのではなく、用途ごとに分割する
  model: any = {
    brand: {
      display: 'name',
      condition: {
        isRecursion: false,
        key: 'id',
        endpoint: `/api/common/brand`,
        query: {},
      },
    },
    category: {
      display: 'title',
      condition: {
        isRecursion: true,
        key: 'name',
        endpoint: `/api/common-proxy/${HOST_ID}/tags`,
        query: {
          pathname: 'company.product.category',
          scope: 'tree',
        },
      },
    },
    color: {
      display: 'title',
      condition: {
        isRecursion: true,
        key: 'name',
        endpoint: `/api/common-proxy/${HOST_ID}/tags`,
        query: {
          pathname: 'company.product.color_family',
          scope: 'tree',
        },
      },
    },
  }

  mounted() {
    this.search()
  }

  search(): void {
    const urlParams = Util.convertItemlistUrlParamsToAssociativeArray()

    _.map(this.model, (model, prefix) => {
      this.$get(model.condition.endpoint, model.condition.query)
        .then((response) => {
          let datas = []
          if (model.condition.isRecursion) {
            datas = response.body.data.children
          } else {
            datas = response.body.data
          }

          return datas
        })
        .then((datas) => {
          this.filter(datas, prefix, urlParams)
        })
    })
  }

  filter(datas, prefix, urlParams): void {
    if (!urlParams || urlParams[prefix] === undefined) return

    const params = urlParams[prefix].split(',')
    const filterDatas = _.filter(datas, (item) => {
      switch (prefix) {
        case 'brand':
          return _.indexOf(params, item[this.model[prefix].condition.key]) !== -1
        case 'category':
        case 'color':
          const firstPath = this.model[prefix].condition.query.pathname
          const pathnames = _.forEach(params, (param) => {
            return `${firstPath}.${param}`
          })

          return _.indexOf(pathnames, item[this.model[prefix].condition.key]) !== -1
      }
    })
    this.$set(this.data, prefix, filterDatas)
  }

  display(prefix, item): string {

    const key = this.model[prefix].display
    const display = key in item ? item[key] : ''
    let html = `<div class="ml-4">${display}</div>`

    _.forEach(item.children, (child) => {
      html += this.display(prefix, child)
    })

    return html
  }

}
