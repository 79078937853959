import BadgeConfig from '@/models/app-config/behavior/config/item-list/item/badge/badge-config'

export default class Badge {
  static createWithBadgeConfig(
    config: BadgeConfig,
    primaryClass: string,
    ...optionClasses: string[]
  ) {
    return new Badge(
      config.label,
      primaryClass,
      ...optionClasses
    )
  }

  public readonly optionClasses: string[]

  constructor(
    public readonly label: string,
    public readonly primaryClass: string,
    ..._optionClasses: string[]
  ) {
    this.optionClasses = _optionClasses
  }

  get class(): string {
    return this.classes.join(' ')
  }

  get classes(): string[] {
    return [this.primaryClass, ...this.optionClasses]
  }
}
