import { Order } from '@/models/order/order'

export interface PaymentAmount {
  total: number
  tax: number
}

export type OrderResult = SuccessOrderResult | FailedOrderResult
export interface SuccessOrderResult {
  success: true
  order: Order
  paymentAmount: PaymentAmount
  error: null
}
export interface FailedOrderResult {
  success: false
  order: Order
  error: Error
}

export const resultIsSuccess = (r: OrderResult): r is SuccessOrderResult => r.success
