














































import { log } from '@/log'
import { normalErrorMessage } from '@/message/error'
import { DefaultReplacePriceRepository } from '@/models/api/cart/latest-price/replace-price-reposiory'
import { ApiProductclass } from '@/models/api/productclass'
import BehaviorConfig from '@/models/app-config/behavior/behavior'
import { SkuPriceList } from '@/models/item/sku'
import { DefaultFetchSkuPrice } from '@/models/item/sku/repository/impl/fetch-sku-price'
import { FkuQuery } from '@/services/api/item/fku/fku-query'
import ProductclassService from '@/services/api/productclass'
import AppId from '@/util/appid'
import { BrandRouteResolver } from '@/util/brandRouteResolver'
import { SizeTagsService } from '@ajax/modules/services/tags/size'
import { AddToCartEvent } from '@ajax/vue/components/organisms/addtocart/view-models/event'
import { ProcessStatus } from '@ajax/vue/components/organisms/addtocart/view-models/process-status'
import { PaidMembershipInteraction } from '@ajax/vue/components/templates/interaction/paid-membership/paid-membership-interaction'
import { CartService } from '@spa/services/api/cart'
import { CartItemListRepository, DefaultCartItemRepository } from '@spa/store/modules/cart/repository'
import { CartItem } from '@spa/store/modules/cart/types'
import _ from 'lodash'
import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'

@Component
export default class AddToCartItemList extends Vue {
  /**
   * 初期化時のローディング状態
   */
  isLoading: boolean = false

  addToCartStatus: ProcessStatus = ProcessStatus.asNotProcessing()
  fkuInitializationError: Error[] = []

  productclasses: any[] = []
  skuPriceList: SkuPriceList = SkuPriceList.empty()
  cartItems: CartItem[] = []

  isPaidMember: boolean = false

  @Prop({ required: true })
  id: string

  get brandName(): string {
    return BrandRouteResolver.resolveBrandFromPathElement(this.$route.params.brand_english_name)
  }

  get appId(): string {
    return AppId.getByBrandName(this.brandName)
  }

  get behavior(): BehaviorConfig {
    return BehaviorConfig.createFromBrand(this.brandName)
  }

  get itemService(): ProductclassService {
    return new ProductclassService(this.appId)
  }

  get paidMembershipInteraction(): PaidMembershipInteraction {
    return new PaidMembershipInteraction(this.brandName)
  }

  get cartItemListRepository(): CartItemListRepository {
    return DefaultCartItemRepository.createViaApiClient(
      new CartService(this.appId)
    )
  }

  get sizeTagsService(): SizeTagsService {
    return new SizeTagsService(this.brandName, this.appId)
  }

  get paidMembershipFeatureEnabled(): boolean {
    return this.behavior.paidMembership.enabled
  }

  async created() {
    await this.initialize()
  }

  async initialize(): Promise<void> {
    this.isLoading = true

    const [
      productclassResult,
      isPaidMemberResult,
    ] = await Promise.all([
      this.itemService.listFkuBelongingToRoot(this.id, FkuQuery.initialize({
        with: 'part,stock~detail',
      }).toObject()),
      this.paidMembershipInteraction.isPaidMember(),
    ])

    isPaidMemberResult.fold(
      (error: Error) => {
        log.error({
          message: 'Failed to determine if the user is a paid-member.',
          error,
        })
      },
      (value: boolean) => {
        this.isPaidMember = value
      }
    )

    if (productclassResult.isLeft()) {
      this.showError(productclassResult.value)

      return
    }

    const productclasses = productclassResult.value as any[]
    // productclasses must have brand
    const isNoBrand = (fkuList: any[]): boolean => {
      return fkuList.some((fku) => {
        const brandId: number = _.get(fku, 'brand_id', null)

        return !brandId || brandId.toString().length === 0
      })
    }

    if (isNoBrand(productclasses)) {
      const error = new Error('ブランドを登録してください')
      this.showError(error)

      return
    }

    const sizeTagList = await this.sizeTagsService.getOrdered('asc')
    if (sizeTagList.isLeft()) {
      this.showError(sizeTagList.value)

      return
    }

    const addedProductclasses = this.addDisplayLevel(productclasses, sizeTagList.value)

    if (!addedProductclasses) {
      return
    }

    this.productclasses = addedProductclasses

    /**
     * 以下では、会員状態に関わらず有効なSKU価格を取得し、下位コンポーネントに渡す準備をしている。
     * TODO: 本来は有効なSKU価格を持ったSKUオブジェクトを受け渡すだけにしたい。
     * 既存がコンポーネントで直接Engineレスポンス型を触っているため、SKUとは別々に有効なSKU価格を受け渡す羽目になっている。
     */
    const skuList = _.flatten(
      this.productclasses
      /** 既存ロジックが productclasses: any[]であった。なるべく既存に手を入れず、新設部分でApiProductclassで包んでいる。 */
      .map(p => new ApiProductclass(p))
      .map(p => p.parts)
    )
    const skuIdList = skuList.map(product => product.id)
    const priceResult = await DefaultFetchSkuPrice.create(this.brandName).fetch(skuIdList)
    priceResult.fold(
      (error) => {
        log.error({ error })
        this.showError(error)
      },
      (skuPriceList) => {
        this.skuPriceList = skuPriceList
      }
    )

    // 有料会員機能がONの場合はすでに異なる価格の種類（有料会員価格/非有料会員価格）でカートに入っているか検証する必要がある
    // OFFの場合もカートの取得を行ってもよいが、単に不要なのでパフォーマンスの観点でスキップする
    if (this.paidMembershipFeatureEnabled) {
      try {
        /**
         * 現在のカートを最新の有効価格に更新する
         * 将来的には単にAPIにカートをリクエストしたら最新の有効価格に更新して返却されるほうが望ましい？
         */
        const replaceRepository = DefaultReplacePriceRepository.create(this.appId)
        await replaceRepository.updateCurrentValidPrice()

        this.cartItems = await this.cartItemListRepository.list()
      } catch (error) {
        log.error({
          message: 'get cart list failed.',
          error,
        })
        this.showError(error)
      }
    }

    this.isLoading = false
  }

  addDisplayLevel(productclasses: any , sizeTagList: any[]): any[] {
    // add display_level for sort
    _.each(productclasses, (productclass) => {
      _.each(productclass.parts, (part) => {
        const size = _.find(part.tags, (tag) => {
          return /^company\.product\.size\./.test(tag.pathname)
        })
        if (size) {
          const sizeTag = _.find(sizeTagList, o => o.title === size.label)
          if (sizeTag) {
            _.assign(part, { display_level: sizeTag.display_level })
          }
        }
      })
    })

    return productclasses
  }

  showError(error) {
    this.isLoading = false
    const message = normalErrorMessage
    this.$store.commit('error/set', { message })
    this.$emit('err', error)
    log.error(error)
  }

  get sortedProductclasses() {
    return _.sortBy(this.productclasses, 'display_level')
  }

  /**
   * 現時点では、カート追加処理状に関して、一度に一種類のSKUの追加のみが行われる前提を置いている。
   * そのためpayloadに含まれるSKU IDなどの情報は利用していないが、将来的な拡張可能性を想定して受け取っている。
   * @see ProcessStatus
   */
  onStartEvent(_payload: AddToCartEvent): void {
    this.addToCartStatus = ProcessStatus.asProcessing()
  }

  /**
   * 現時点では、カート追加処理状態に関して、一度に一種類のSKUの追加のみが行われる前提を置いている。
   * そのためpayloadに含まれるSKU IDなどの情報は利用していないが、将来的な拡張可能性を想定して受け取っている。
   * @see ProcessStatus
   */
  onStopEvent(_payload: AddToCartEvent): void {
    this.addToCartStatus = ProcessStatus.asNotProcessing()
  }

  onFkuInitializationError(payload: Error): void {
    this.fkuInitializationError.push(payload)
  }

  @Watch('fkuInitializationError', { deep: true })
  showFkuInitializationError() {
    this.showError(
      new Error(
        this.fkuInitializationError.map(error => `[${error.name}]${error.message}`).join(', ')
      )
    )
  }
}
