import Stock from '../../../index'
import DisplayRule from '../index'

export class CoelFewDependOnWeb implements DisplayRule {
  constructor(private readonly border: number) {}

  shouldApply(stock: Stock): boolean {
    return stock.display.isDisable
      && 0 < stock.web.amount
      && stock.web.amount <= this.border
  }
  apply(): string {
    return '残りわずか'
  }
}
