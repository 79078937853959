









import Vue from 'vue'
import { Component } from 'vue-property-decorator'

import PageTransitionLink from '@/components/atoms/PageTransitionLink.vue'
import { CookieService } from '@spa/services/cookie'

@Component({
  components: {
    PageTransitionLink,
  },
})
export default class AccountLink extends Vue {
  get url(): string {
    return this.isLogined ? '/s/mypage' : '/s/signup'
  }

  private get isLogined () {
    return CookieService.isLogined()
  }
}
