



















import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component
export default class AbstractShare extends Vue {
  @Prop({ required: true })
  href: string
}
