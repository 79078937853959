










import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component
export default class BasePriceRow extends Vue {
  @Prop({ required: true })
  readonly label: string

  @Prop({ required: true })
  readonly priceWithTax: string

  /*
  The `showYenSymbol` prop is introduced as a workaround based on the following comment:
  https://github.com/my-color/front/pull/6677#pullrequestreview-1053623159
  */
  @Prop({ default: true })
  readonly showYenSymbol: boolean

  get priceWithTaxNonEmptyString(): string {
    if (this.priceWithTax === '') {

      return this.showYenSymbol ? '0' : '￥0'
    }

    return this.priceWithTax
  }
}
