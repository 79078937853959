var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('auto-load-container',{attrs:{"current-page":_vm.currentPage,"fetch-callback":_vm.fetchCallback,"per-page":_vm.loadRange,"query":_vm.query,"base-page":_vm.basePage,"prefetch":_vm.prefetch},on:{"complete":_vm.complete,"error":_vm.error},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var pages = ref.pages;
var pagination = ref.pagination;
var perPage = ref.perPage;
return [(_vm.shouldShowPages(pages))?_c('paginated-item-list-container',{attrs:{"pages":pages,"current-page":_vm.currentPage,"tag":_vm.listTag,"page-to-jump":_vm.initialPageQuery},on:{"visible-page-change":_vm.onVisiblePageChange,"after-jump":_vm.onAfterJump},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var page = ref.page;
var item = ref.item;
var index = ref.index;
return _c(_vm.itemTag,{key:index,tag:"component",class:_vm.itemClass},[_vm._t("item-top",[(_vm.isRanking)?_c('ranking-label',{attrs:{"rank":_vm.makeRankingNumber(index, page)}}):_vm._e()],{"page":page}),_vm._v(" "),_c('item',{attrs:{"item":item,"instaview":_vm.instaview,"select-thumbnail-image-builder":_vm.selectThumbnailImageBuilder,"item-kind":_vm.itemKind,"show-add-to-cart-button":_vm.showAddToCartButton}})],2)}}],null,true)}):(!_vm.isAfterJump)?_c('spinner'):_vm._e(),_vm._v(" "),(_vm.shouldShowPaginationCounter(_vm.counterMode, pagination))?_c('pagination-counter',{attrs:{"current":_vm.paginationCounterCurrent(perPage, pagination),"total":pagination.total}}):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }