export const initialPurchaseState: PurchaseState = {
  items: [],
  error: {},
}

export interface PurchaseState {
  items: any[],
  error: {
    load?: Error | null,
  },
}
