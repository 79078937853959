import { SearchContactLensCond } from './types'

export const columnNameToIndexMap = {
  simpleProductName: 1,
  colorName: 2,
  price: 5,
  fkuUrl: 6,
  imageUrl: 7,
  lensUrl: 8,
  period: 10,
  dia: 11,
  coloringdia: 12,
  bc: 13,
  corrective: 14,
  color: 15,
  border: 16,
  hardness: 18,
  spDrawing: 19,
  movieUrl: 22,
}

// 生データから, UI表示用のデータへ変換.
export function formatRow(row: string[]): string[] {
  for (let i = 0; i < row.length; i += 1) {
    if (i === columnNameToIndexMap['period']) {
      if (row[i] === '') {
        row[i] = '非公開'
      }
    } else if (i === columnNameToIndexMap['bc']) {
      if (row[i] === '') {
        row[i] = '非公開'
      }
    } else if (i === columnNameToIndexMap['dia']) {
      if (row[i] === '') {
        row[i] = '非公開'
      }
    } else if (i === columnNameToIndexMap['coloringdia']) {
      if (row[i] === '') {
        row[i] = '非公開'
      }
    }
  }

  return row
}

// 検索.
export function *searchIterator(csvData: string[][], cond: SearchContactLensCond): Generator {
  if (csvData) {
    const spDrawingIndex = columnNameToIndexMap['spDrawing']

    for (const item of csvData) {
      // 先頭のカラム(商品名)が空白だった場合、もしくは「CSVData[i][0] == ''」だった場合に処理を終了する
      if (item[0] === '' || item[0] === '商品名') {
        break
      }

      // スマホ着画必須かどうか?
      if (cond.hasSPDrawing) {
        if (item[spDrawingIndex] === '' || item[spDrawingIndex] === '0') {
          // 着画データがないので
          continue
        }
      }

      // 条件が合う商品を選択する.
      let failed = false
      for (const matchValue of cond.matchValues) {
        // 全て合致するもののみ.
        if (!item[matchValue.columnIndex].match(matchValue.columnValue)) {
          // 高度数が8の場合, 10も検索対象にする.
          if (matchValue.columnIndex === columnNameToIndexMap['hardness']) {
            // 8を検索条件にしている, 対象行の値は10である.
            if (matchValue.columnValue === '8' && item[matchValue.columnIndex] === '10') {
              // failedにはせず続行.
              continue
            }
          }

          // 一致しない条件を見つけたので検索終了.
          failed = true
          break
        }
      }

      if (failed) {
        continue
      }

      // 成功したら返す.
      yield item
    }
  }
}

export function search(csvData: string[][], cond: SearchContactLensCond): string[][] {
  const itemIterator = searchIterator(csvData, cond)
  const items: string[][] = []
  while (true) {
    const item = itemIterator.next()
    if (item.done) {
      break
    }

    if (item.value) {
      items.push(formatRow(item.value))
    }
  }

  return items
}

export function addCond(cond: SearchContactLensCond, index: number, value: string): void {
  // 削除対象以外のキーをもつもので再作成.
  cond.matchValues = cond.matchValues.filter(item => item.columnIndex !== index)

  // 新しい条件を追加.
  cond.matchValues.push({ columnIndex: index, columnValue: value })
}

export function removeCond(cond: SearchContactLensCond, index: number): void {
  // 削除対象以外のキーをもつもので再作成.
  cond.matchValues = cond.matchValues.filter(item => item.columnIndex !== index)
}
