import { MutationTree } from 'vuex'

import { ApiAppSetting } from '@/models/api/app_setting'
import { AppSetting } from './types'

import { log } from '@/log'

const namespace = 'appSetting'

export const mutations: MutationTree<AppSetting> = {

  appSetting(state, payload: ApiAppSetting) {
    state.error.load = null
    state.appSetting = payload

    log.debug({ commit: `${namespace}/appSetting`, state })
  },

  errorOnLoad(state, payload: Error) {
    const error = payload
    state.error.load =  error
  },
}
