import { Module } from 'vuex'
import { mutations } from './mutations'
import * as Error from './types'

export function createError<RootState>(): Module<Error.State, RootState> {
  return {
    namespaced: true,
    state: Error.createInitial(),
    mutations,
  }
}
