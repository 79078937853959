
















import _get from 'lodash/get'
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import CircularCarousel from './CircularCarousel.vue'

interface CircularItem {
  src: string
  href: string
}

@Component({
  components: {
    CircularCarousel: (CircularCarousel as any),
  },
})
export default class CircularCarouselView extends Vue {
  @Prop({ default: 345 })
  width: number

  @Prop({ default: 284 })
  height: number

  @Prop({ default: 140 })
  ovalWidth: number

  @Prop({ default: 75 })
  ovalHeight: number

  @Prop({ default: 32 })
  offsetX: number

  @Prop({ default: 118 })
  offsetY: number

  @Prop({ default: 350 })
  duration: number

  @Prop({ default: 0.28 })
  minDepthScale: number

  activeIndex: number = 0

  /**
   * consoleからの登録時は、<img src='hoge'>の形で画像が指定される
   */
  get items(): CircularItem[] {
    return (this.$slots.default || [])
      .filter(vNode => vNode.tag === 'img')
      .map(vNode => ({
        src: _get(vNode, 'data.attrs.src', null),
        href: _get(vNode, 'data.attrs.data-href', '#'),
      }))
      .filter(item => item.src !== null)
  }

  get circularItems() {
    return this.items.map(({ src, href }, idx) => ({
      id: `${idx}`,
      src,
      width: 120,
      href,
    }))
  }

  mounted() {
    this.$nextTick(() => {
      setInterval(() => {
        this.activeIndex += 1
        // tslint:disable-next-line
      }, 3000)
    })
  }
  auto() {
    const delay = 3000
    const carousel = this.$refs.carousel as any | null

    if (!carousel) return

    setInterval(carousel.next.bind(this), delay)
  }
}
