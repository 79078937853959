import { CartItem } from '@/models/cart/cart-item'
import { isAfter, isBefore, isEqual } from 'date-fns'

import * as CurrentTime from '@/models/current-time/functions'
import Calculator from './coupon/calculator/calculator'
import NoDiscountCalculator from './coupon/calculator/noDiscountCalculator'
import RateCalculator from './coupon/calculator/rateCalculator'
import SubtractionCalculator from './coupon/calculator/subtractionCalculator'
import LabelMaker from './coupon/label/labelMaker'
import NoDiscountLabelMaker from './coupon/label/noDiscountLabelMaker'
import RateLabelMaker from './coupon/label/rateLabelMaker'
import SubtractionLabelMaker from './coupon/label/subtractionLabelMaker'
import Resource from './resource'

export class ApiCoupon extends Resource {
  isApplicableFor(cartItem: CartItem): boolean {
    return this.applicableToAny || this.applicableBrands.indexOf(cartItem.brandEnglishName || '') !== -1
  }

  get calculator(): Calculator {
    switch (this.discountType) {
      case 'rate': return new RateCalculator(this.discountValue)
      case 'subtraction': return new SubtractionCalculator(this.discountValue)
      default: return new NoDiscountCalculator()
    }
  }

  get labelMaker(): LabelMaker {
    switch (this.discountType) {
      case 'rate': return new RateLabelMaker(this.discountValue)
      case 'subtraction': return new SubtractionLabelMaker(this.discountValue)
      default: return new NoDiscountLabelMaker()
    }
  }

  get title(): string {
    return this.get('title')
  }

  get imageId(): string {
    return this.get('aux_string_1')
  }

  get imageUrl(): string {
    return this.get('aux_string_2')
  }

  get hasCouponCode(): boolean {
    const auxString5 = this.get('aux_string_5')

    return (auxString5 !== undefined) && (auxString5.length > 0)
  }

  get campaignId(): string {
    return this.get('coupon_context_id')
  }

  get isAvailable(): boolean {
    return !this.isUsed && this.isWithinValidPeriod && this.isVerified
  }

  get isUsed(): boolean {
    return this.get('shown')
  }

  get startAt(): Date {
    return new Date(this.get('start_at'))
  }

  get endAt(): Date {
    return new Date(this.get('end_at'))
  }

  get isWithinValidPeriod(): boolean {
    // TODO: memoize?
    const currentTime = CurrentTime.create()
    const now = currentTime.now

    return isAfterOrEqualTo(now, this.startAt) && isBeforeOrEqualTo(now, this.endAt)
  }

  get discountType(): DiscountType {
    return this.get('content.discount_type')
  }

  get discountValue(): number {
    return parseInt(this.get('content.discount_value'), 10)
  }

  // Because discount type/value need to be parsed from json string, the results are not reliable.
  get isVerified(): boolean {
    return !!this.discountType && !!this.discountValue && this.discountValueIsValid()
  }

  get statusLabel(): string {
    if (this.isUsed) {
      return '使用済み'
    }
    if (this.isWithinValidPeriod) {
      return '未使用'
    }

    return '期限切れ'
  }

  calculateDiscountPrice(originalPrice: number): number {
    return this.calculator.calculateDiscountPrice(originalPrice)
  }

  /**
   * @deprecated
   * このメソッドで生データを公開する目的
   * - いままで、このApiCoupon内部のgetCouponCodeメソッドで、生データからクーポンコード型にキャストした内容を生成する仕組みになっていた。
   * - getCouponCodeメソッドでは、このApiCoupon型を最終的に参照しつつ、適切な型のクーポンコードを生成していた。
   * - そのため、循環参照が発生してしまう。循環参照を回避するため、生データからクーポンコードを生成する部分を、このApiCouponクラスの外に追い出したい。
   * - このメソッドで、生データを公開することで、このクラスの外側でクーポンコードを生成する。
   * - このような経緯から、あくまで暫定的なワークアラウンドであり、deprecatedである。
   * - クーポンコードの生成方法を整理（生データから直接ドメインモデルに変換）しおわったら、このような生データの公開は廃止すること。
   *
   * このメソッドで生データを公開しても致命的ではないと判断した理由
   * - 現状、Resourceクラスの生データはとくにprivateになっていない。
   * - 現状、利用箇所としてはgetCouponCodeメソッド、およびそれを呼び出すストア側のcoupon/availableCouponCodesのみで、影響範囲が閉じている。
   */
  get rawData(): object {
    return this._data
  }

  private discountValueIsValid(): boolean {
    if (this.discountType === 'rate') {
      const ZERO = 0
      const HUNDRED = 100

      return this.discountValue >= ZERO && this.discountValue <= HUNDRED
    }

    return true
  }

  private get applicableBrands(): string[] {
    return this.get('applicable_brands', [])
  }
  private get applicableToAny(): boolean {
    return this.get('applicable_to_any', false)
  }
}

type DiscountType = '' | 'rate' | 'subtraction'

function isAfterOrEqualTo(test: Date, start: Date): boolean {
  return isEqual(test, start) || isAfter(test, start)
}

function isBeforeOrEqualTo(test: Date, start: Date): boolean {
  return isEqual(test, start) || isBefore(test, start)
}
