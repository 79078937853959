import { BundlePurchaseItem } from '../../../bundle-purchase-item'
import { AddToPurchaseListSpec } from '../index'

interface LimitPurchaseListSizeConfig {
  limit: number
  currentList: BundlePurchaseItem[]
}

export default class LimitPurchaseListSize implements AddToPurchaseListSpec {
  constructor(private readonly config: LimitPurchaseListSizeConfig) { }

  isSatisfiedBy(): boolean {
    return this.config.currentList.length < this.config.limit
  }
}
