import _ from 'lodash'

export const initialContactState: ContactState = {
  items: [],
  error: {},
}

export interface ContactState {
  items: Contact[],
  error: {
    load?: Error,
  },
}

export interface Contact {
  id: string
  location_kind?: number
  country?: string
  postal_code?: string
  region?: string
  locality?: string
  street_address?: string
  extended_address?: string
  pobox?: string
  supplement?: string
  first_name?: string
  last_name?: string
  phonetic_first_name?: string
  phonetic_last_name?: string
  tel?: string
  display_level: number
  is_delivery?: boolean
  is_billing?: boolean
  country_code?: string
  region_code?: string
  locality_code?: string

  error?: {
    update?: Error,
    remove?: Error,
  }
}

// TODO: Rename more appropriate name
export function convertToContacts(responseData): Contact[] {
  return _.map(responseData, item => convertToContact(item))
}

export function convertToContact(item): Contact {
  // TODO: Explict key mapping
  return item  as Contact
}
