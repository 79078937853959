import { BaseUrlRepository, CacheAvailableS3BaseUrlRepository } from '@/models/api/resource/base-url-repository'
import BehaviorConfig from '@/models/app-config/behavior/behavior'
import { ImageSource, isS3ImageSource } from '@/models/app-config/behavior/config/item-list/item/under-maintenance'
import { URL } from '@/util/url'

export interface MaintenanceImageUrl {
  value: string | null
}
export interface MaintenanceImageUrlBuilder<T extends MaintenanceImageUrl> {
  build(brandEnglishName: string): T
}

export class S3MaintenanceImageUrl implements MaintenanceImageUrl {
  static async builder(
    repository: BaseUrlRepository = CacheAvailableS3BaseUrlRepository.instance,
    behavior?: BehaviorConfig
  ): Promise<MaintenanceImageUrlBuilder<S3MaintenanceImageUrl>> {
    const baseUrl = await repository.load()

    return {
      build(
        brandEnglishName: string
      ): S3MaintenanceImageUrl {
        return new S3MaintenanceImageUrl(
          brandEnglishName,
          baseUrl,
          behavior || BehaviorConfig.createFromBrand(brandEnglishName)
        )
      },
    }
  }

  private constructor(
    private readonly brandEnglishName: string,
    private readonly baseUrl: string,
    private readonly behavior: BehaviorConfig
  ) {}

  get value(): string | null {
    if (!this.useImage) {
      return null
    }

    if (!this.imageSource) {
      return this.createS3Url(`/ma/${this.brandEnglishName}/maintenance/image-maintenance-item.png`)
    }

    if (isS3ImageSource(this.imageSource)) {
      return this.createS3Url(this.imageSource.path)
    }

    return null
  }

  private get useImage(): boolean {
    return this.behavior.itemList.item.underMaintenance.useImage
  }

  private get imageSource(): ImageSource | null {
    return this.behavior.itemList.item.underMaintenance.imageSource
  }

  private createS3Url(path: string): string {
    // this.baseUrlPath = https://...の形式を想定
    return URL.join(this.baseUrl, path)
  }
}
