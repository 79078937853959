import { EventBusForThirdParty } from '../event-bus'
import { handleEveryPageView } from './default/handle-every-page-view'
import { handlePurchase } from './default/handle-purchase'
import { handlePurchaseSubscription } from './default/handle-purchase-subscription'
import { EventSubscriptionFacade } from './facade'

/**
 * MyColor系のブランドに対してサードパーティツール（アナリティクス・Web接客ツール）がコードの各所に散在していたものを、一括してイベント購読するためのクラス。
 * そのとき既存実装だった内容をDefaultとしてここに定義している。
 *
 * ゆくゆくは、使用するサードパーティツールの切り分けを、MyColor, Ledianなどの各ホストないしブランドや、Growthpalette全体といった観点で明確に定義し、
 * "Default"という名称から、より具体的な名称を持つクラスに変更・解体していくことが望ましい。
 */
export class DefaultEventSubscriptionFacade implements EventSubscriptionFacade {
  constructor() {
    /** GAで使用するdataLayerがundefinedでないことを保証する。 */
    window.dataLayer = window.dataLayer || []
  }

  subscribeAll(): void {
    const eventBus = EventBusForThirdParty.getInstance()
    eventBus.subscribe('every-page-view', handleEveryPageView)
    eventBus.subscribe('purchase', handlePurchase)
    eventBus.subscribe('purchase-subscription', handlePurchaseSubscription)

    /**
     * 以下は、Ledianでは使用しないためこのDefaultに組み入れるべきだが、いったんノイズが存在していてもよい（イベント送信していてもよい）ため、
     * まだ登録・定義していないイベント。
     * - 商品詳細画面のスライド系のイベント送信
     * - 商品詳細画面のSNS登録ボタン
     */
  }
}
