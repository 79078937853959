



























































































































































import _ from 'lodash'
import { Validator } from 'vee-validate'
import Vue from 'vue'
import { Component, Emit, Inject, Prop } from 'vue-property-decorator'

import SpaErrorDialog from '@/components/organisms/dialog/ErrorDialog.vue'
import BehaviorConfig from '@/models/app-config/behavior/behavior'
import { REVIEW_SECTION_ITEM, ReviewSectionItem } from '@/models/app-config/behavior/config/review-section'
import {
  Approval, Color, Comment,
  MAX_RATING_VALUE, MAX_REVIEW_LENGTH,
  Rating, Reviewer, UserReview } from '@/models/user-review'
import ProductclassService from '@/services/api/productclass'
import { UserReviewService } from '@/services/user-review'
import AppId from '@/util/appid'
import { UserService } from '@ajax/modules/services/user'
import AjaxModal from '@ajax/vue/components/templates/TransparentModal.vue'
import { CookieService } from '@spa/services/cookie'

import ColorSelector from './ColorSelector.vue'
import RatingSelector from './RatingSelector.vue'

@Component({
  components: {
    AjaxModal,
    ColorSelector,
    RatingSelector,
    SpaErrorDialog,
  },
})
export default class PostReviewModal extends Vue {

  @Inject()
  $validator: Validator

  @Prop({ required: true })
  brand: string

  @Prop({ required: true })
  id: string

  colorList: Color[] = []
  selectedColor: Color = this.loadingColor
  nickname: string = ''
  commentText: string = ''
  ratingValue: number = 5

  posted: boolean = false
  isPostButtonEnabled: boolean = false
  postButtonText: string
  showErrorModal: boolean = false
  errorModalMessage: string = ''
  loading: boolean = true

  async created() {
    this.initializing()
    this.colorList = await this.loadColorList()
    this.selectedColor = this.defaultColor
    if (this.colorList.length > 0) {
      this.loading = false
      this.readyToPost()
    }
  }

  initializing(): void {
    this.isPostButtonEnabled = false
    this.postButtonText = '読み込み中…'
  }

  readyToPost(): void {
    this.isPostButtonEnabled = true
    this.postButtonText = 'レビューを投稿する'
  }

  waitingForPost(): void {
    this.isPostButtonEnabled = false
    this.postButtonText = '投稿中…'
  }

  async loadColorList(): Promise<Color[]> {
    const fkus = await this.itemService.getFkuWithSkuFromRootId(this.id)
    if (fkus.isLeft()) {
      this.showErrorModal = true
      this.errorModalMessage = 'データ取得に失敗しました。ページを再度読み込んでください。'

      return []
    }

    /**
     * 同一ROOT内の複数FKUで同じカラー系統（color family）が使われる場合、
     * カラー系統リストに重複が発生するため、ユニークリストに変換する
     * @see: https://github.com/my-color/front/issues/5478#issuecomment-882454778
     */
    const colorFamilies = _.uniqBy(
      fkus.value.map(fku => fku.colorFamilies[0]),
      colorFamily => colorFamily.pathname
    )

    return colorFamilies.map(colorFamily => ({
      id: colorFamily.pathname,
      label: colorFamily.label,
    }))
  }

  get postButtonClass(): string[] {
    return [
      'c-btn',
      'c-btn--h-middle',
      'c-btn--center',
      this.isPostButtonEnabled ? 'c-btn--user-review' : 'c-btn--disabled',
    ]
  }

  private get defaultColor(): Color {
    return this.colorList[0] || this.loadingColor
  }

  private get itemService(): ProductclassService {
    return new ProductclassService(this.appId)
  }

  private get userReviewService(): UserReviewService {
    return UserReviewService.createForBrand(this.brand)
  }

  private get appId(): string {
    return AppId.getByBrandName(this.brand)
  }

  private get behaviorConfig(): BehaviorConfig {
    return BehaviorConfig.createFromBrand(this.brand)
  }

  private get reviewer(): Reviewer {
    return { nickname: this.nickname }
  }

  private get comment(): Comment {
    return { text: this.commentText }
  }

  get maxRatingValue(): number {
    return MAX_RATING_VALUE
  }

  get maxReviewLength(): number {
    return MAX_REVIEW_LENGTH
  }

  private get rating(): Rating {
    return { value: this.ratingValue }
  }

  private get approval(): Approval {
    return {
      approved: false,
      pending: true,
    }
  }

  private get userReview(): UserReview {
    return {
      color: this.selectedColor,
      reviewer: this.reviewer,
      comment: this.comment,
      rating: this.rating,
      approval: this.approval,
    }
  }

  get reviewSectionItem(): typeof REVIEW_SECTION_ITEM {
    return REVIEW_SECTION_ITEM
  }

  get loadingColor(): Color {
    return { id: '', label: '' }
  }

  updateColor(colorId) {
    this.selectedColor = _.find(this.colorList, colorOption => colorOption.id === colorId) || this.defaultColor
  }

  updateRatingValue(n: number): void {
    this.ratingValue = n
  }

  showReviewSection(item: ReviewSectionItem): boolean {
    return this.behaviorConfig.reviewSection.allow(item)
  }

  async validateForm(): Promise<boolean> {
    this.$validator.fields.map((field) => {
      this.$validator.flag(field.name, {
        touched: true,
      })
    })

    return this.$validator.validateAll()
  }

  async post() {
    const validated = await this.validateForm()
    if (!validated) {
      return
    }

    this.waitingForPost()

    if (CookieService.isGuest) {
      const userService = new UserService()

      await userService.registerProvisional()
    }

    const result = await this.userReviewService.post(this.userReview, this.id)

    if (result.isLeft()) {
      this.showErrorModal = true
      this.errorModalMessage = 'レビューの投稿に失敗しました。時間をおいて再度お試しください。'
      this.readyToPost()

      return
    }

    this.posted = true
  }

  onCloseErrorModal() {
    this.showErrorModal = false
  }

  @Emit('close')
  closeModal() {
    // do nothing
  }
}
