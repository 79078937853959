


























































import 'slick-carousel'
import Vue from 'vue'
import { Component, Emit, Prop } from 'vue-property-decorator'

import ImageWithBadgeBand from '@/components/atoms/image/ImageWithBadgeBand.vue'
import BundlePurchaseItemDetailAddToCartButton from './BundlePurchaseItemDetailAddToCartButton.vue'
import { BundlePurchaseItem, BundlePurchaseRootItem, } from './model/bundle-purchase-item'
import { AddToPurchaseListSpec } from './model/specification/add-to-purchase-list'

@Component({
  components: {
    BundlePurchaseItemDetailAddToCartButton,
    ImageWithBadgeBand,
  },
})
export default class BundlePurchaseItemDetail extends Vue {
  @Prop({ required: true })
  root: BundlePurchaseRootItem

  @Prop({ required: true })
  addToPurchaseListSpec: AddToPurchaseListSpec

  @Emit('addItem')
  onClickAddToCart(_skuItem: BundlePurchaseItem) {
    // Do nothing
  }

  mounted() {
    this.setupSlider()
  }

  setupSlider() {
    const sliderCommonConfig = {
      slidesToScroll: 1,
      swipeToSlide: true,
      variableWidth: true,
    }
    $('.js-bundle_purchase--main_slider').slick({
      ...sliderCommonConfig,
      asNavFor: '.js-bundle_purchase--sub_slider',
      slidesToShow: 1,
    })
    $('.js-bundle_purchase--sub_slider').slick({
      ...sliderCommonConfig,
      arrows: false,
      asNavFor: '.js-bundle_purchase--main_slider',
      focusOnSelect: true,
      rows: 0,
    })

    // main_slider 配下の画像にスライダーと同等の幅を設定
    // TODO: CSS と Slick の機能だけで画像幅を制御できるようにする
    // ※メイン画像幅はモーダル幅に合わせて100％にしなければならないが、
    // 　親要素がモーダルではなくスライダーであるため、メイン画像幅にスライダー幅が適用されてしまい、
    // 　結果非常に大きく表示されてしまう。その対策としてJSでメイン画像幅を設定しているが、
    // 　Slickの機能に解決手段があるかもしれない、しかし調査不十分のため、TODOを残した
    const sliderWidth: number = $('.js-bundle_purchase--main_slider').width() || 0
    $('.js-bundle_purchase--main_slider img').each(function () {
      $(this).width(sliderWidth)
    })
  }
}
