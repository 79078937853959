import { formatPrice } from '@/util/format'
import LabelMaker from './labelMaker'

export default class SubtractionLabelMaker implements LabelMaker {
  public constructor(public discountPrice: number) {
  }

  public makeDiscountContentLabel(): string {
    return `${formatPrice(this.discountPrice)} OFF`
  }
}
