import BrandFreeAreaService from '@/services/free-area/brand-free-area'

export default class SlideMenuFreeArea extends BrandFreeAreaService {
  protected get freeAreaPaths(): string[] {
    return [
      'SlidemenuFreeArea',
      'SlideMenuTopFreeArea',
    ]
  }
}
