import '@/components/global'
import VeeValidate, { Validator } from 'vee-validate'
import ja from 'vee-validate/dist/locale/ja'
import Vue from 'vue'
import VueHead from 'vue-head'
import VueLazyload from 'vue-lazyload'
import Notifications from 'vue-notification'

import 'url-search-params-polyfill'

import Banner from '@/components/atoms/Banner.vue'
import Button from '@/components/atoms/Button.vue'
import Checkbox from '@/components/atoms/Checkbox.vue'
import Input from '@/components/atoms/Input.vue'
import Spinner from '@/components/atoms/Spinner.vue'
import {
  EventForThirdPartySubscriptionFacadeFactory
} from '@/usecases/event-bus-for-third-party/subscriber/facade'

import { adCodeStore } from '@/util/adcode/store'
import AppId from '@/util/appid'
import { getZealsMeta } from '@/util/html-meta'

import BrandPanel from '@ajax/vue/components/atoms/BrandPanel.vue'
import Dropdown from '@ajax/vue/components/atoms/Dropdown.vue'
import LinkPanel from '@ajax/vue/components/atoms/LinkPanel.vue'
import MoreLink from '@ajax/vue/components/atoms/MoreLink.vue'
import Overlay from '@ajax/vue/components/atoms/Overlay.vue'
import RangeSlider from '@ajax/vue/components/atoms/RangeSlider.vue'

import AddItemToCart from '@/components/organisms/cart/add-item/AddItemToCart.vue'
import AddToCartItemList from '@ajax/vue/components/organisms/addtocart/AddToCartItemList.vue'
import AddToCartList from '@ajax/vue/components/organisms/addtocart/AddToCartList.vue'
import AddToCartListItem from '@ajax/vue/components/organisms/addtocart/AddToCartListItem.vue'
import AddToCartModal from '@ajax/vue/components/organisms/addtocart/AddToCartModal.vue'
import ArrivalNotification from '@ajax/vue/components/organisms/ArrivalNotification.vue'
import ConditionDropdown from '@ajax/vue/components/organisms/ConditionDropdown.vue'
import ConditionRadio from '@ajax/vue/components/organisms/ConditionRadio.vue'
import ContactLensCartIn from '@ajax/vue/components/organisms/contact-lens/CartIn.vue'
import ContactLensColorDropdown from '@ajax/vue/components/organisms/contact-lens/ColorDropdown.vue'
import ContactLensColorVariation from '@ajax/vue/components/organisms/contact-lens/ColorVariation.vue'
import ContactLensPowerLeftDropdown from '@ajax/vue/components/organisms/contact-lens/power/LeftDropdown.vue'
import ContactLensPowerRightDropdown from '@ajax/vue/components/organisms/contact-lens/power/RightDropdown.vue'
import ContactLensQuantityDropdown from '@ajax/vue/components/organisms/contact-lens/Quantity.vue'
import ErrorModalContainer from '@ajax/vue/components/organisms/ErrorModalContainer.vue'
import ModalFull from '@ajax/vue/components/organisms/ModalFull.vue'
import SearchDropdown from '@ajax/vue/components/organisms/SearchDropdown.vue'
import ConditionChild from '@ajax/vue/components/templates/ConditionChild.vue'
import Newstock from '@ajax/vue/components/templates/Newstock.vue'
import Restock from '@ajax/vue/components/templates/Restock.vue'
import spaStore from '@spa/store'
import VueRouter from 'vue-router'
import Initiator from './modules/initiator'

import router from './router'
import autoLoadRouter from './router/auto-load'
import footerRouter from './router/footer'
import headerRouter from './router/header'
import previewNotificationRouter from './router/previewNotification'

import objectFitImages from 'object-fit-images'
import autoRedirect from './vue/directives/auto-redirect'

import store from './vue/store'

import * as ImageConfig from '@/config/assets/image'
import sticky from '@/directives/sticky'

import loadBundleDiscount from '@/directives/load-bundle-discount'
import '@/validation/email'

/**
 * AjaxとSPAに関して、
 * Webpackで出力されるエンドポイント（バンドルされたファイル）は、ajax用とspa用に分かれている。
 * ただしajax用のエンドポイントにはajaxフォルダ（およびその依存）のみが含まれるが、spa用のエンドポイントにはajaxフォルダとspaフォルダ（およびそれらの依存）が含まれる。
 * そのため、SPA実行時にも、同じ一つのjsバンドルファイル内でこのinitAjaxメソッドは実行される。
 * - とくにたとえば、ここで生成されたシングルトンインスタンスは、ajaxフォルダのコードでもspaフォルダのコードからでも、同じものにアクセスできる。
 * このWebpackの設定自体は容易には変更しないものとして前提してよいため、ajaxエンドポイント（MPAページ）とspa共エンドポイント（SPAページ）に共通する初期処理をこのinitAjaxメソッドに書くことは構わない。
 * しかしやはりinitAjaxというajax側のフォルダにあるコードに、ajax/spaの共通処理が存在しうることは非直感的であるため、コメントしておく。
 * 将来的には、共通の初期化処理をより中立的な名前空間に移すことを検討したい。
 */

function initAjax() {
  new EventForThirdPartySubscriptionFacadeFactory()
    .createForHost(AppId.getHostId())
    .subscribeAll()

  Initiator.initAdCode()

  // なるべく早めに発火させるため先頭に持ってきている。
  /**
   * 事前にAdCodeを読み込んでおく必要がある。
   * この依存関係が暗黙になってしまっているため、pub/sub方式などで明示的に呼び出し順序を保証してもよいが、それほど激しく変更が入る箇所でもないのでそこまでのケアをしていない。
   * もしくはAdCode#loadがコストのない操作（idempotentで、かつ何度呼び出しても性能的に問題ない）と信じられるならば、あえてここで改めてloadしてもよいかもしれない。
   * - AdCodeの実装はそうなっていてその内容はおそらく容易に変わらないと想像できるが、I/Fとしてそこまで信じられるかといえば微妙なので、コメントに留める。
   */
  Initiator.publishEveryPageViewEvent(adCodeStore.read().toUndefined())

  Vue.directive('auto-redirect', autoRedirect)
  Vue.directive('object-fit-images', {
    inserted (el) {
      objectFitImages(el)
    },
  })
  Vue.directive('sticky', sticky)
  Vue.directive('loadBundleDiscount', loadBundleDiscount)
  Vue.use(VueHead)
  Vue.use(VueRouter)

  // TODO: グローバルに登録するべきコンポーネントと、不要なコンポーネントを整理
  // グローバルでは不要なコンポーネントを登録しているコードはすべて削除する.
  Vue.component('ajax-input', Input)
  Vue.component('ajax-spinner', Spinner)
  Vue.component('ajax-brand-panel', BrandPanel)
  Vue.component('ajax-button', Button)
  Vue.component('ajax-checkbox', Checkbox)
  Vue.component('ajax-dropdown', Dropdown)
  Vue.component('ajax-condition-dropdown', ConditionDropdown)
  Vue.component('ajax-condition-radio', ConditionRadio)
  Vue.component('ajax-search-dropdown', SearchDropdown)
  Vue.component('ajax-link-panel', LinkPanel)
  Vue.component('ajax-morelink', MoreLink)
  Vue.component('ajax-add-to-cart-item-list', AddToCartItemList)
  Vue.component('ajax-add-to-cart-list', AddToCartList)
  Vue.component('ajax-add-to-cart-list-item', AddToCartListItem)
  Vue.component('ajax-add-to-cart-modal', AddToCartModal)
  Vue.component('ajax-contact-lens-cart-in', ContactLensCartIn)
  Vue.component('ajax-contact-lens-color-dropdown', ContactLensColorDropdown)
  Vue.component('ajax-contact-lens-color-variation', ContactLensColorVariation)
  Vue.component('ajax-contact-lens-power-left-dropdown', ContactLensPowerLeftDropdown)
  Vue.component('ajax-contact-lens-power-right-dropdown', ContactLensPowerRightDropdown)
  Vue.component('ajax-contact-lens-quantity-dropdown', ContactLensQuantityDropdown)
  Vue.component('ajax-overlay', Overlay)
  Vue.component('ajax-rangeslider', RangeSlider)
  Vue.component('ajax-modalfull', ModalFull)
  Vue.component('ajax-conditionchild', ConditionChild)
  Vue.component('ajax-error-modal-container', ErrorModalContainer)
  Vue.component('ajax-arrival-notification', ArrivalNotification)
  Vue.component('ajax-incart', AddItemToCart)
  Vue.component('ajax-newstock', Newstock)
  Vue.component('ajax-restock', Restock)
  Vue.component('ajax-banner', Banner)

  Vue.use(Notifications)

  Vue.use(VeeValidate)
  Validator.localize('ja', ja)

  Vue.use(VueLazyload, {
    preLoad: 1.1,
    error: ImageConfig.itemLoadingImagePath,
    loading: ImageConfig.itemLoadingImagePath,
    attempt: 1,
    throttleWait: 100,
  })

  // This is workaround before SPA/Ajax are integrated
  if (isOnSpa()) {
    // tslint:disable-next-line:no-unused-expression
    new Vue({
      el: '#vue-footer-frame',
      router: footerRouter,
    })
  } else {
    // tslint:disable-next-line:no-unused-expression
    new Vue({
      el: '#ajax-app',
      head: {
        meta: [
          ...getZealsMeta(),
        ],
      },
      router,
      store,
    })

    // tslint:disable-next-line:no-unused-expression
    new Vue({
      el: '#vue-footer-frame',
      router: footerRouter,
    })

    // tslint:disable-next-line:no-unused-expression
    new Vue({
      el: '#vue-ec-header',
      store: spaStore,
      router: headerRouter,
    })

    // tslint:disable-next-line:no-unused-expression
    new Vue({
      el: '#vue-third-party-tool',
      store: spaStore,
    })

    // In SPA preview-notification component is mounted within spa App.vue, not in ctp template.
    // tslint:disable-next-line:no-unused-expression
    new Vue({
      el: '#vue-preview-notification',
      store: spaStore,
      router: previewNotificationRouter,
    })

    // tslint:disable-next-line:no-unused-expression
    new Vue({
      el: '#vue-auto-load-trigger',
      router: autoLoadRouter,
    })

    Initiator.initCollapse()
    Initiator.initDrawer()
    Initiator.initSetting()
    Initiator.initPageToTop()
    Initiator.initNavTab()

    Initiator.initTopSlider()
    Initiator.initSlideShow()
    Initiator.initEventTracking()
    Initiator.initTopBrandList()
    Initiator.initSpinner()
    Initiator.initSplitContent()
  }

  Initiator.initDialogMigration()

  function isOnSpa(): boolean {
    return /^\/s((\/?$)|\/[^\/]*)/.test(location.pathname)
  }
}

initAjax()
