import { InstanceResolver, InstanceResolverInterface } from '@/util/instanceResolver'
import _ from 'lodash'

import { ApiContent } from '@/models/api/content'
import ChunkedRequestExecuter from '@/services/api/async/chunked-request-executer'
import FreeContentsService from '@/services/api/free-contents'
import BrandFreeAreaService from '@/services/free-area/brand-free-area'
import HostFreeAreaService from '@/services/free-area/host-free-area'
import { BrandRouteResolver } from '@/util/brandRouteResolver'
import ChunkExecuter from '@/util/chunked-executer'

abstract class FreeAreaService {
  static createFromBrandName(
    brandName: string,
    klass: new (brandName: string, freeContentService: FreeContentsService) => BrandFreeAreaService
  ): BrandFreeAreaService {
    const brand = BrandRouteResolver.resolveBrandFromPathElement(brandName)
    const freeContentsService = FreeContentsService.createForBrand(brand)

    return new klass(brand, freeContentsService)
  }

  static createForHost (
    klass: new (instance: InstanceResolverInterface, freeContentService: FreeContentsService) => HostFreeAreaService,
    instance: InstanceResolverInterface = InstanceResolver
  ): HostFreeAreaService {
    /**
     * SINGLEインスタンスの場合、フリーエリアの取得はブランドパーツとして取得する.
     *
     * NOTE:
     * 一部組み込みのMPAページについては、 ブランド管轄ではなくホスト管轄として実装されている箇所がある.
     * そちらもブランド管理のものとしていくか、引き続きホスト管理のままとしていくかは 2021/07/20 時点で未検討.
     * 詳細は添付の Pull Request コメント参照.
     *
     * @see https://github.com/my-color/front/pull/5484#discussion_r672730617 一部ホスト管轄として実装されている箇所について
     * @see https://github.com/my-color/front/issues/5468#issuecomment-879962688 SINGLEインスタンスにおける扱い決定の経緯
     * @see HostFreeAreaService.contentsDirectory SINGLEかどうかでパーツ取得時のパス指定が変わる
     */
    const freeContentsService = instance.isMulti() ?
      FreeContentsService.createForHost() :
      FreeContentsService.createForBrand(instance.getSingleBrand())

    return new klass(instance, freeContentsService)
  }

  protected constructor (
    private readonly freeContentService: FreeContentsService
  ) { }

  async fetch(): Promise<ApiContent[]> {
    const pathNames = this.freeAreaPaths.map(path => this.createContentsPath(path))
    const results = await this.createChunkedRequest().execute(
      pathNames,
      async (paths: string[]) => this.freeContentService.list(paths)
    )

    return _.flatten(
      results.map(
        either => either.fold(
          () => [],
          res => res
        )
      )
    )
  }

  protected abstract createContentsPath(contentsName: string): string

  private createChunkedRequest(): ChunkedRequestExecuter {
    const averageOfPathLength = _.sum(
      this.freeAreaPaths.map(path => this.createContentsPath(path).length)
    ) / this.freeAreaPaths.length
    const pathCountsOnOneRequest = Math.floor(ChunkedRequestExecuter.BORDER_QUERY_LENGTH / averageOfPathLength)

    return new ChunkedRequestExecuter(
      new ChunkExecuter(pathCountsOnOneRequest)
    )
  }

  protected abstract get freeAreaPaths(): string[]
}

export default FreeAreaService
