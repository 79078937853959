import { ActionTree } from 'vuex'

import { ApiAppSetting } from '@/models/api/app_setting'
import AppId from '@/util/appid'
import { AppSettingService } from '@spa/services/api/appSetting'
import { RootState } from '@spa/store/types'
import { AppSetting } from './types'

export interface LoadAppSettingParameter {
  brand: string | null
}

export const actions: ActionTree<AppSetting, RootState> = {
  async load({ commit }, { brand }: LoadAppSettingParameter) {
    /**
     * FIXME: storeには保存しない. https://github.com/my-color/front/pull/2027
     */
    const appId = AppId.getByBrandName(brand || null)
    const result = await new AppSettingService(appId).get()

    if (result.isLeft()) {
      const error = result.value

      commit('errorOnLoad', error)

      return
    }

    const appSetting: ApiAppSetting = result.value

    commit('appSetting', appSetting)
  },
}
