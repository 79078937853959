/**
 * 現在表示しているROOTに紐づくFKU/SKUがたった一つしかない時は、
 * ユーザー側で色/サイズ選択の余地がないのでシステム側でその一つを選択する
 */

import { ApiProduct } from '@/models/api/product'
import { ApiProductclass } from '@/models/api/productclass'
import { none, Option, some } from 'fp-ts/lib/Option'
import _ from 'lodash'

export interface ItemList {
  isItemSelectionRequired: boolean
  head: string
}

interface AutoSelectItem {
  maySelectAuto: (itemList: ItemList) => Option<string>
}

export class SkuListInAllFkus implements ItemList {
  public static createFromRawFkuList(fkuList: any[]) {
    return new this(
      _.flatten(fkuList.map(raw => new ApiProductclass(raw).parts))
    )
  }

  constructor(private skuList: ApiProduct[]) {
    if (!this.skuList.length) {
      throw new Error('No item found in the skuList, at least one element is required.')
    }
  }

  get isItemSelectionRequired(): boolean {
    return this.skuList.length !== 1
  }

  get head(): string {
    return this.skuList.map(sku => sku.id)[0]
  }
}

export class ItemListSpec implements AutoSelectItem {
  maySelectAuto(itemList: ItemList): Option<string> {
    if (!itemList.isItemSelectionRequired) {
      return some(itemList.head)
    }

    return none
  }
}
