











import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component
export default class ConditionModalItem extends Vue {
  @Prop({ default: null })
  topStatus: string | null

  get itemContainerClass() {
    const base = {
      'd-flex': true,
      'justify-content-between': true,
      'flex-nowrap': true,
      container: true,
    }

    return this.topStatus === null ? base : {
      ...base,
      [this.topStatus]: true,
    }
  }
}
