import Footer from '@ajax/vue/components/organisms/footer/Footer.vue'
import Router from 'vue-router'

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/:brand/*',
      component: Footer,
      /*
       * ex. /micoameri/testpage の場合
       * paramsの中身は { 0: testpage, brand: micoameri } となる
       * 0がpropsとして渡されると不正な値としてvueでエラーになるので、必要なparamsのみ抽出.
       */
      props: route => ({ brand: route.params.brand }),
    },
    {
      path: '/:brand',
      component: Footer,
      props: true,
    },
    {
      path: '*',
      component: Footer,
    },
  ],
})
