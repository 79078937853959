/**
 * カート追加の処理状態を表すクラス。
 * 処理状態に応じて何らかの仕方でローディング中のUIを表現するユースケースが想定される。
 * 現時点では、単純にON/OFFの切り替えのみとする。
 * つまり、どのSKUが処理中になったかなどの経緯は無視して、ON/OFFの要求のみを受け付けている。
 *
 * 今後、たとえば一度に複数のSKUが並行でカート追加されるケースなどが出てくれば、処理中のSKUを配列で識別し、
 * 処理中のSKUが空になった時点ですべての処理が完了したとみなす、などの改修をこのクラスに加えることができる。
 */
export class ProcessStatus {
  static asProcessing(): ProcessStatus {
    return new ProcessStatus(true)
  }

  static asNotProcessing(): ProcessStatus {
    return new ProcessStatus(false)
  }

  private constructor(readonly inProcessing: boolean) {}
}
