import Calculator from './calculator'

export default class RateCalculator implements Calculator {
  /**
   * @param number percentage Integer of two digits, like 30%
   */
  public constructor(public percentage: number) {
  }

  get rate(): number {
    return this.percentage / PERCENTAGE_HUNDRED
  }

  calculateDiscountPrice(originalPrice: number): number {
    return Math.min(Math.floor(originalPrice * this.rate), originalPrice)
  }
}

const PERCENTAGE_HUNDRED = 100
