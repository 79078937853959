





























import _ from 'lodash'
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component
export default class SearchDropdown extends Vue {
  @Prop({ default: null })
  parent: any

  @Prop()
  prefix: string

  @Prop()
  model: any[]

  @Prop()
  list: any[]

  get displayKey() {
    return this.model[this.prefix].display
  }

  get listItems() {
    // ROOT要素でない場合、親の分類全てでの検索もできるようにコンポーネントを追加する
    return this.parent === null ? this.list : [
      {
        ...this.parent,
        ...{
          [this.displayKey]: `全ての${this.parent[this.displayKey]}`,
          $forTotal: true,
          children: [],
        },
      },
    ].concat(this.list)
  }

  dropdownClass(item) {
    return {
      'w-100': true,
      'd-inline-block': true,
      'is-child': !this.isRoot(item),
    }
  }

  iconClass(item) {
    return {
      fa: true,
      'fa-caret-right': true,
      'icon-right': true,
      'd-none': this.isChildren(item.children),
    }
  }

  isRoot(item) {
    if (item.$forTotal) {
      // 再帰的コンポーネント内で後付けしたプロパティ.
      // 「全てのxxx」用.
      return false
    }
    if ('pathname' in item) {
      const rootPathname = 'company.product.category'
      const slicePathname = _.replace(item.pathname, rootPathname, '')

      return slicePathname.split('.').length - 1 === 1
    }

    return true
  }

  isChildren(children) {
    return children && children.length
  }

  makeLink(item) {
    const value = (() => {
      if ('pathname' in item) {
        const rootPathname = this.model[this.prefix].condition.query.pathname

        return _.replace(item.pathname, rootPathname, '').slice(1)
      }

      return item[this.model[this.prefix].condition.key]
    })()

    return `/itemlist?${this.prefix}=${value}`
  }
}
