import AbstractValue from '@/models/app-config/behavior/abstract-value'

export interface RawBundleRecommendation {
  show: boolean
}

export default class BundleRecommendation extends AbstractValue<RawBundleRecommendation> {
  get show(): boolean {
    return this.raw.show
  }
}
