import { None, Option, Some } from 'fp-ts/lib/Option'

import ApiStock from '@/models/api/product/stock'
import Stock from '@/models/item/sku/stock'
import {
  DisplayStockValue,
  EnableDisplayStockValue
} from '@/models/item/stock/value/display-stock-value'
import WebStockValue from '@/models/item/stock/value/web-stock'

export function create(stock: ApiStock): Stock {
  return new Stock(
    new WebStockValue(stock.salesStock + stock.prospectStock),
    createDisplayStockMaybeNull(stock.intentionStock)
  )
}

function createDisplayStockMaybeNull(value: number | null): Option<DisplayStockValue> {
  if (value === null) {
    return None.value
  }

  return new Some(
    new EnableDisplayStockValue(value)
  )
}
