import { Some } from 'fp-ts/lib/Option'

import {
  DisableDisplayStockValue,
  EnableDisplayStockValue,
} from '../stock/value/display-stock-value'
import WebStockValue from '../stock/value/web-stock'
import SKUId from './id'
import SalePeriod from './sale-period'
import SaleStatus from './sale-status'
import Stock from './stock'

export default class SKU {
  constructor(
    public readonly id: SKUId,
    private readonly _stock: Stock,
    public readonly saleStatus: SaleStatus,
    public readonly salePeriod: SalePeriod
  ) { }

  get stock(): Stock {
    /**
     * TODO: SKUのスコープ内で、販売停止に応じて在庫の挿げ替えをしているのが筋悪かもしれない。
     * 在庫の取扱に関する知識は、在庫の仕様オブジェクトなり在庫オブジェクト自信なりに任せた方が良いかも。
     * https://github.com/my-color/front/pull/2559#discussion_r332054237
     */
    if (this.isOnSale) {
      return this._stock
    }

    const displayStockIsEnable = this._stock.display.isEnable
    const emptyDisplayStock = displayStockIsEnable ? new EnableDisplayStockValue(0) : DisableDisplayStockValue.value

    return new Stock(
      WebStockValue.empty,
      new Some(emptyDisplayStock)
    )
  }

  get isOnSale(): boolean {
    return this.saleStatus.isOnSale
  }
}

/**
 * ユーザの会員プランや商品の商品の設定に基づいて、適切な価格を透過的に受け取るために設けられたクラス。
 * 有料会員機能のON/OFF、会員プランと商品の組み合わせによる会員価格の適用不適用適用に関わらず、いかなる場合も透過的な価格を受け取る。
 *
 * SKUモデルそのものに価格フィールドを追加することが望ましいと思われるが、既存のSKUモデルが価格を持たないまま利用されているので、
 * 一旦影響範囲を範囲を制限するために、SKUのラッパー的なクラスとしてして設けている。
 * @see https://github.com/my-color/front/issues/5963
 * TODO: SKUモデルに関わる知識を蒸留して、（サーバにロジックを移譲することも含めて）価格に関する知識をSKUに統合したい。
 */
export class SkuWithPrice {
  static create(
    sku: SKU,
    price: SkuPrice
  ): SkuWithPrice {
    return new SkuWithPrice(sku, price)
  }

  constructor(
    readonly sku: SKU,
    readonly price: SkuPrice
  ) {}
}

export interface SkuPrice {
  sku_id: string
  prices: {
    selected: SkuPriceCandidate
    unselected: SkuPriceCandidate[]
  }
}

export interface SkuPriceCandidate {
  price: number
  rest_amount: RestAmount
  reasons: ReasonLimitOfPurchasableAmount[]
  is_paid_membership_price: boolean
}

export interface RestAmount {
  add_to_cart: number
}

export interface ReasonLimitOfPurchasableAmount {
  id: string
  reason: string
}

export function isPaidMembershipPrice(skuPrice: SkuPrice): boolean {
  return skuPrice.prices.selected.is_paid_membership_price
}

export class SkuPriceList {
  static create(list: SkuPrice[]): SkuPriceList {
    return new SkuPriceList(list)
  }

  static empty(): SkuPriceList {
    return new SkuPriceList([])
  }

  private constructor(readonly list: SkuPrice[]) {}

  findBySkuId(skuId: string): SkuPrice | undefined {
    return this.list.find(p => p.sku_id === skuId)
  }
}
