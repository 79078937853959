
export interface ErrorModalState {
  readonly shown: boolean
  readonly message: string
}

export class NoError implements ErrorModalState {
  readonly shown = false
  readonly message = ''
}
export class FailedToFetchFkuListError implements ErrorModalState {
  readonly shown = true
  readonly message = '商品情報の取得に失敗しました。<br/>少し時間をおいてから、ページを更新をお試しください。<br/>※改善されない場合、お問い合わせよりカスタマーセンターまでお問い合わせください。'
}
export class FailedToFetchRootItemError implements ErrorModalState {
  readonly shown = true
  readonly message = '商品詳細の取得に失敗しました。<br/>少し時間をおいてから、ページを更新をお試しください。<br/>※改善されない場合、お問い合わせよりカスタマーセンターまでお問い合わせください。'
}
