


















































































import $ from 'jquery'
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import Util from '../../../modules/util'
import BootstrapDropdown from '../../directives/bootstrap/dropdown'

import SortItem from '@ajax/vue/components/molecules/sort/SortItem.vue'

@Component({
  directives: {
    dropdown: BootstrapDropdown,
  },
  components: {
    SortItem,
  },
})
export default class Sort extends Vue {
  static readonly DEFALUT_SORT_KIND = 'new'

  queries: any = {}
  open: boolean = false

  mounted() {
    this.queries = Util.convertItemlistUrlParamsToAssociativeArray()
  }

  get actionUrl(): string {
    return this.$route.path
  }

  isChecked(kind: string): boolean {
    const currentKind = this.queries.sort || Sort.DEFALUT_SORT_KIND

    return currentKind === kind
  }

  toggle() {
    this.open = !this.open
  }

  get state(): string {
    return this.open ? 'fa-chevron-up' : 'fa-chevron-down'
  }

  sort(value: string) {
    this.$set(this.queries, 'sort', value)
    this.$nextTick(() => {
      $('.form-sort').submit()
    })
  }
}
