import _ from 'lodash'
import { Response } from 'superagent'

export class ListWithPagination<T> {
  static createFrom<T>(response: Response, mapper?: (item: any) => T): ListWithPagination<T> {
    const { data } = response.body

    if (!_.isArray(data)) {
      throw new Error('This response is not an array type.')
    }

    return new ListWithPagination({
      list: mapper ? data.map(mapper) : data,
      pagination: Pagination.createFrom(response),
    })
  }
  constructor(private params: {
    list: T[],
    pagination: Pagination
  }) {}

  get list(): T[] {
    return this.params.list
  }

  get pagination(): Pagination {
    return this.params.pagination
  }
}

/**
 * TODO: This pagination structure is specific to Engine response.
 * Refactor this class to more general structure, if needed.
 */
export class Pagination {
  static createFrom(response: Response): Pagination {
    return new Pagination(response.body)
  }

  constructor(private params: {
    offset: number,
    limit: number,
    maxlimit: number,
    readsize: number,
    length: number,
    total: number,
    remainder: number,
    total_over: boolean
  }) {}

  get offset(): number {
    return this.params.offset
  }

  get limit(): number {
    return this.params.limit
  }

  get maxlimit(): number {
    return this.params.maxlimit
  }

  get readsize(): number {
    return this.params.readsize
  }

  get length(): number {
    return this.params.length
  }

  get total(): number {
    return this.params.total
  }

  get remainder(): number {
    return this.params.remainder
  }

  get total_over(): boolean {
    return this.params.total_over
  }

  get hasNextPage(): boolean {
    return this.remainder > 0
  }

  get isLastPage(): boolean {
    return !this.hasNextPage
  }

  get exceedsLastPage(): boolean {
    return !this.hasNextPage && !this.isLastPage
  }
}
