








import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import Badge from './model/badge'

@Component
export default class ItemBadge extends Vue {
  @Prop({ required: true })
  badge: Badge
}
