import { ApiImage } from '../api/image'
import PutImageWithSpecificDisplayLevelAtFirst from './image-sorter/put-image-with-specific-display-level-at-first'
import PutImageWithSpecificImageAppKindAtFirst from './image-sorter/put-image-with-specific-image-app-kind-at-first'
import SortImagesByDisplayLevel from './image-sorter/sort-images-by-display-level'
export default class ImageCollection {
  static create(images: ApiImage[]): ImageCollection {
    return new ImageCollection(images)
  }

  private constructor(private images: ApiImage[]) {}

  sortByDisplayLevel(): ApiImage[] {
    return new SortImagesByDisplayLevel().sort(this.images)
  }

  /**
   * コンタクトレンズ商品リストに表示する画像を決定するためのソートメソッド
   *
   * @param integer|null displayLevelOfImageToDisplay
   * @param string|null imageAppKindOfImageToDisplay
   * @return ApiImage[]
   */
  sortForContactLensItemList(
    displayLevelOfImageToDisplay: number | null,
    imageAppKindOfImageToDisplay: string | null
  ): ApiImage[] {
    const images = this.sortByDisplayLevel()

    if (displayLevelOfImageToDisplay !== null) {
      return new PutImageWithSpecificDisplayLevelAtFirst(displayLevelOfImageToDisplay).sort(images)
    }

    if (imageAppKindOfImageToDisplay !== null) {
      return new PutImageWithSpecificImageAppKindAtFirst(imageAppKindOfImageToDisplay).sort(images)
    }

    return images
  }

  findInstaImage(): ApiImage | null {
    return this.images.find(img => img.isInstaView) || null
  }
}
