import Stock from '@/models/item/stock'
import DisplayRule from '@/models/item/stock/specification/display-rule'
import DisplayNothing from '@/models/item/stock/specification/display-rule/each-case/display-nothing'

export default class DisplayRuleSet {
  constructor(private readonly rules: DisplayRule[])  { }

  find(stock: Stock): DisplayRule {
    const found = this.rules.find(rule => rule.shouldApply(stock))

    return found || new DisplayNothing()
  }
}
