import { OrderResult } from '@/models/order/completed/order-result'
import { Delivery } from '@/models/order/order'
import { OrderSummary } from '@/models/order/summary'
import { SubscriptionConfirmState } from '@spa/store/modules/subscriptionConfirm/index'
import { MutationTree } from 'vuex'

export const mutations: MutationTree<SubscriptionConfirmState> = {
  setOrderResults(state, orderResults: OrderResult[]) {
    state.orderResults = orderResults
  },
  setDelivery(state, delivery: Delivery) {
    state.delivery = delivery
  },
  setSummary(state, summary: OrderSummary) {
    state.summary = summary
  },
}
