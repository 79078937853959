

































import ItemPriceWithApiDto from '@/components/molecules/item/price/adapter/with-api-dto/ItemPriceWithApiDto.vue'
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import BundleDiscountLabelWithStore from '@/components/molecules/discount/BundleDiscountLabelWithStore.vue'

import ApiBrand from '@/models/api/brand'
import BehaviorConfig from '@/models/app-config/behavior/behavior'
import { ItemDetailField } from '@/models/app-config/behavior/config/item-list/item/detail'
import AppId from '@/util/appid'

import ContactLensFKU from '@/models/item/fku/contact-lens'

@Component({
  components: {
    ItemPrice: ItemPriceWithApiDto,
    BundleDiscountLabelWithStore,
  },
})
export default class ItemDetail extends Vue {
  @Prop({ required: true })
  fku: ContactLensFKU

  shouldShow(field: ItemDetailField): boolean {
    return this.behaviorConfig.itemList.item.detail.allow(field)
  }

  get behaviorConfig() {
    return BehaviorConfig.createFromBrand(this.brandName)
  }

  get field(): typeof ItemDetailField {
    return ItemDetailField
  }

  get fkuId(): string {
    return this.fku.id
  }

  get brand(): ApiBrand {
    return this.fku.belongingBrand
  }

  get brandName(): string {
    return this.brand ? this.brand.name : ''
  }

  get brandEnglishName(): string {
    return this.brand ? this.brand.englishName : ''
  }

  get appId(): string {
    return AppId.getByBrandName(this.brandEnglishName)
  }

  get productName(): string {
    return this.fku.productName
  }

  get colorName(): string {
    return this.fku.color ? this.fku.color.label : ''
  }

  get disposalSchedule(): string {
    return this.fku.disposalSchedule
  }

  get lensCountPerBox(): number {
    return this.fku.lensCountPerBox
  }

  get bc(): string {
    return this.fku.bc
  }

  get colorDia(): string {
    return this.fku.colorDia
  }

  get dia(): string {
    return this.fku.dia
  }

  get power(): string | null {
    return this.fku.power
  }

  get supportCompactDelivery(): boolean {
    return this.fku.supportCompactDelivery
  }

  get supportNekoposuDelivery(): boolean {
    return this.fku.supportNekoposuDelivery
  }
}
