import { log } from '@/log'
import { CookieService } from '@/services/cookie'

export interface WebCustomerServiceUserAttr {
  id?: string
  elapsedDays?: number
  orderTimes?: number
}

interface MeasurementProperty {
  cart_total_money: number
  user_id: string
  last_buyday: string
  buy_fequency: number
}

export const executeWebCustomerMeasurementTag = async (
  totalPrice: number,
  userAttribute: WebCustomerServiceUserAttr
): Promise<void> => {
  log.info('execute web customer measurement')
  log.debug(totalPrice, userAttribute)

  const measurementProperty: MeasurementProperty = await makeMeasurementProperty(totalPrice, userAttribute)
  window.measurementTag = window.measurementTag || []
  window.measurementTag.push(measurementProperty)
  outputScriptTagToBodyBottom()
}

export const executeWebCustomerConversionTag = (gtmECommerceData) => {
  window.conversionTag = gtmECommerceData || []
  outputScriptTagToBodyBottom()
}

export const cacheUserAttribute = (userAttribute: WebCustomerServiceUserAttr): void => {
  const expire: number = 0.020833333333333 // 30分指定 1/48
  const value: string = JSON.stringify(userAttribute)
  CookieService.setWebCustomerServiceUserAttribute(value, expire)
}

export const getUserAttributeCache = (): WebCustomerServiceUserAttr | undefined => {
  const jsonString: string | undefined = CookieService.getWebCustomerServiceUserAttribute()

  return (!!jsonString) ? JSON.parse(jsonString) : jsonString
}

export const hasApiToken = (): boolean => {

  return !!getApiToken()
}

const makeMeasurementProperty = (
  cartTotalPrice: number,
  userAttribute: WebCustomerServiceUserAttr
): MeasurementProperty => {

  return {
    cart_total_money: cartTotalPrice,
    user_id:          userAttribute.id || '',
    last_buyday:      userAttribute.elapsedDays ? userAttribute.elapsedDays.toString() : '',
    buy_fequency:     userAttribute.orderTimes || 0,
  }
}

const outputScriptTagToBodyBottom = (): void => {
  const e: HTMLScriptElement = document.createElement('script')
  e.src = `${scriptUrl()}?api_token=${getApiToken()}`
  e.async = true
  document.body.appendChild(e)
}

const getApiToken = (): string => {

  return process.env.WEB_CUSTOMER_SERVICE_API_TOKEN || ''
}

const scriptUrl = (): string => {

  return process.env.WEB_CUSTOMER_SERVICE_SCRIPT_URL || ''
}
