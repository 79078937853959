import { Either, Left, Right } from 'fp-ts/lib/Either'
import { None, Option, Some } from 'fp-ts/lib/Option'

import UserSelect, { SelectOptionList } from '@/models/api/payment/parameter/type/user-select'

/**
 * @see https://my-color.esa.io/posts/534
 */
export default interface UserSelectResolver {
  resolve(userSelect: UserSelect): Either<Error, Option<string>>
}

export class DefaultResolver implements UserSelectResolver {

  constructor(
    private readonly selectedOptions: SelectOptionList
  ) {}

  resolve(userSelect: UserSelect): Either<Error, Option<string>> {
    return userSelect.isSingle ?
      this.resolveSingle(userSelect) :
      this.resolveMulti(userSelect)
  }

  private resolveSingle(userSelect: UserSelect): Either<Error, Option<string>> {
    const options = this.selectedOptions.filter(o => o.parameterId === userSelect.id)

    if (options.isEmpty) {
      return userSelect.required ?
        new Left(new RequiredUserSelectNotSelectedError(`UserSelect '${userSelect.id}' is not selected.`)) :
        new Right(None.value)
    }
    if (options.length === 1) {
      return new Right(new Some(options.list[0].id))
    }

    return new Left(new TooManyOptionsSelectedError(`UserSelect '${userSelect.id}' is single type.`))
  }

  /**
   * 複数選択のケースはまだ出てきていないので要求が未確定であるものの、
   * ひとまず選択されたSelectOptionのidを,で結合した文字列を返却している
   */
  private resolveMulti(userSelect: UserSelect): Either<Error, Option<string>> {
    const required = userSelect.required
    const options = this.selectedOptions.filter(o => o.parameterId === userSelect.id)

    if (required && options.isEmpty) {
      return new Left(new RequiredUserSelectNotSelectedError(`UserSelect '${userSelect.id}' is not selected.`))
    }
    if (options.isEmpty) {
      return new Right(None.value)
    }

    return new Right(new Some(
      options.map(o => o.id).join(',')
    ))
  }
}

export class RequiredUserSelectNotSelectedError extends Error {}
export class TooManyOptionsSelectedError extends Error {}
