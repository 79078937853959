




















import _ from 'lodash'
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import ContentsService from '@/services/api/contents'
import AppId from '@/util/appid'
import { BrandRouteResolver } from '@/util/brandRouteResolver'
import * as NewLineCode from '@/util/new-line-code'

@Component
export default class Banner extends Vue {

  @Prop()
  pathname: string

  banners: BannerProperty[] = []

  contentsService: ContentsService

  async created() {
    const brandName = BrandRouteResolver.resolveBrandFromPathElement(this.$route.params.brand_english_name)
    const appId = AppId.getByBrandName(brandName)
    this.contentsService = new ContentsService(appId)
    this.banners = await this.getBanner()
  }

  async getBanner() {
    const result =  await this.contentsService.get(this.pathname)
    if (result.isLeft()) {

      return []
    }

    const content = result.value['content']
    if (!content || content.length === 0) {

      return []
    }

    const replaceAssetsImage = (imageUrl: string): string => {
      return imageUrl.replace('/assets/', '/assets-proxy/')
    }

    return _.map(NewLineCode.split(content), (element) => {
      const [
        image,
        url,
        target,
      ] = element.split(',').map(str => str.trim())

      return {
        target: target || '',
        url: url || '',
        image: image ? replaceAssetsImage(image) : '',
      }
    })
  }
}

interface BannerProperty {
  target: string,
  url: string,
  image: string
}
