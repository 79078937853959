import { CartButtonInItemListState } from '@ajax/vue/store/itemlist/cart-button/types'
import { RootState } from '@ajax/vue/store/types'
import { GetterTree } from 'vuex'

export const getters: GetterTree<CartButtonInItemListState, RootState> = {
  isLocked: state =>
    (buttonId: string): boolean =>
      state.processingButtonId.fold(false, processingId => processingId !== buttonId),
  isProcessing: state =>
    (buttonId: string): boolean =>
      state.processingButtonId.fold(false, processingId => processingId === buttonId),
}
