













import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component
export default class ImageWithBorder extends Vue {
  @Prop({ required: true })
  src: string

  @Prop({ required: true })
  imageClass: string

  @Prop({ default: 'div' })
  tag: string

  @Prop({ default: false })
  active: boolean

  get _class() {
    return {
      active: this.active,
    }
  }
}
