import _ from 'lodash'
import { GetterTree } from 'vuex'

import { RootState } from '../../types'
import { CreditcardModel, CreditcardState } from './types'

export const getters: GetterTree<CreditcardState, RootState> = {
  get(state): (string) => CreditcardModel | undefined {
    return (id: string) => {
      return _.find(state.items, ['id', id])
    }
  },

  first(state): CreditcardModel | undefined {
    return _.first(state.items)
  },
}
