<template>
    <div
        :style="wrapStyles">
        <ul class="circular-carousel">
            <carousel-item ref="items"
                v-for="(item, i) in items"
                :key="item.id"
                :class="'circular-carousel__rank-' + ranks[i]"
                :item="item"
                :index="i"
                :active-index="activeIndex"
                :sinbeta="sinbeta"
                :cosbeta="cosbeta"
                :z-index="zIndexs[i]"
                :scale="scales[i]"
                :opacity="opacities[i]"
                :oval-width="ovalWidth"
                :oval-height="ovalHeight"
                :offset-x="offsetX"
                :offset-y="offsetY"
                :duration="duration"
                :cycle-max="cycleMax"
                :mounted="mounted_"
                @click="onClick"
                @change-x="onChangeX"></carousel-item>
        </ul>
    </div>
</template>

<script>
import Hammer from 'hammerjs'
import _findIndex from 'lodash/findIndex'
import _uniq from 'lodash/uniq'

// tslint:disable

var CircularCarouselItem = {
    template: `
                <li class="circular-carousel__item"
                    :class="itemClass"
                    :style="itemStyle">
                    <a v-if="hasHref"
                        :href="item.href"
                        @dragstart.prevent.stop>
                        <img
                            :style="imgStyle"
                            :src="item.src"
                            :alt="item.alt"
                            :width="item.width"
                            :height="item.height"
                            @click="$emit('click', index)"
                            @dragstart.prevent>
                    </a>
                    <img v-else
                        :style="imgStyle"
                        :src="item.src"
                        :alt="item.alt"
                        :width="item.width"
                        :height="item.height"
                        @click="$emit('click', index)"
                        @dragstart.prevent>
                </li>
            `,

    props: {
        item: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            required: true
        },
        activeIndex: {
            type: Number,
            required: true
        },
        sinbeta: {
            type: Number
        },
        cosbeta: {
            type: Number
        },
        zIndex: {
            type: Number
        },
        scale: {
            type: Number
        },
        opacity: {
            type: Number
        },
        ovalWidth: {
            type: Number
        },
        ovalHeight: {
            type: Number
        },
        offsetX: {
            type: Number,
            default: 0
        },
        offsetY: {
            type: Number,
            default: 0
        },
        duration: {
            type: Number
        },
        cycleMax: {
            type: Number
        },
        mounted: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        isActive: function() {
            return this.activeIndex === this.index
        },

        hasHref: function() {
            return !!this.item.href
        },

        // @type  {string}  active|left|right
        position: function() {
            if (this.isActive) {
                return

            } else if ((this.y - this.offsetY) < 0) {
                return 'left'

            } else {
                return 'right'
            }
        },

        itemClass: function() {
            var c = {
                'is-active': this.isActive,
                'transition': this.mounted
            }

            if (this.position) {
                c['is-position-' + this.position] = true
            }

            return c
        },

        itemStyle: function() {
            return {
                'top': this.x + 'px',
                'left': this.y + 'px',
                'transition-duration': this.mounted ? ((this.duration / 1000) + 's') : 0,
                'z-index': this.zIndex,
                'transform': 'scale(' + this.scale + ')',
            }
        },

        imgStyle: function() {
            return {
                'opacity': this.opacity
            }
        },

        alpha: function() {
            var a = this.cycleMax - this.activeIndex
            var i = (360 / this.cycleMax) * (this.index + a)
            return i * (Math.PI / 180)
        },

        sinalpha: function() {
            return Math.sin(this.alpha)
        },

        cosalpha: function() {
            return Math.cos(this.alpha)
        },

        x: function() {
            var X = this.offsetX + (this.ovalHeight * this.cosalpha * this.cosbeta - this.ovalWidth * this.sinalpha * this.sinbeta)
            return Math.floor(X)
        },

        y: function() {
            var Y = this.offsetY + (this.ovalHeight * this.cosalpha * this.sinbeta + this.ovalWidth * this.sinalpha * this.cosbeta)
            return Math.floor(Y)
        }
    },

    watch: {
        x: function() {
            this.$emit('change-x')
        }
    }

}



export default {
    model: {
        prop: 'activeIndexValue',
        event: 'change'
    },

    components: {
        'carousel-item': CircularCarouselItem
    },

    props: {
        items: {
            type: Array,
            required: true
        },
        activeIndexValue: {
            type: Number,
            default: 0
        },
        width: {
            type: Number
        },
        height: {
            type: Number
        },
        ovalWidth: {
            type: Number
        },
        ovalHeight: {
            type: Number
        },
        offsetX: {
            type: Number,
            default: 0
        },
        offsetY: {
            type: Number,
            default: 0
        },
        // the angle of the ellipse
        angle: {
            type: Number,
            default: 0
        },
        duration: {
            type: Number
        },
        minDepthScale: {
            type: Number,
            default: 0.35
        },
        minDepthOpacity: {
            type: Number,
            default: 0.1
        }
    },

    data: function() {
        return {
            hammerIns: null,
            mounted_: false,
            changeTimerIds_: [],
            changePromises_: [],
            itemsX_: [],
            // autoTimerId_: null,
        }
    },

    computed: {
        wrapStyles: function() {
            return {
                width : Number.isNaN(this.width - 0) ? this.width : (this.width + 'px'),
                height: Number.isNaN(this.height - 0) ? this.height : (this.height + 'px'),
            }
        },

        /**
         * used to influence which element is considered active
         * @type  {number}
         */
        activeIndex: {
            get: function() {
                if (this.activeIndexValue < 0) {
                    this.$emit('change', this.cycleMax - 1)
                    return this.cycleMax - 1; // loop. start to end

                } else if (this.activeIndexValue >= this.cycleMax) {
                    this.$emit('change', 0)
                    return 0; //loop. end to start

                } else {
                    return this.activeIndexValue
                }
            },

            set: function(value) {
                value = value - 0

                if (this.activeIndexValue === value) {
                    return; // same value
                } else if (value < 0) {
                    value = this.cycleMax - 1; // loop. start to end
                } else if (value >= this.cycleMax) {
                    value = 0; //loop. end to start
                }

                this.$emit('change', value)
            }
        },

        // @type {number}
        cycleMax: function() {
            return this.items.length
        },

        // @type {number}
        beta: function() {
            return -this.angle * (Math.PI / 180)
        },

        // @type {number}
        sinbeta: function() {
            return Math.sin(this.beta)
        },

        // @type {number}
        cosbeta: function() {
            return Math.cos(this.beta)
        },

        // @type {array<number>}
        sortedItemsX: function() {
            var items = _uniq(this.itemsX_)
            return items.sort(function(a, b) {return a - b})
        },

        // @type {array<number>}
        ranks: function() {
            var ranks = []
            var itemX, rank

            for (var i = 0, len = this.itemsX_.length; i < len; ++i) {
                itemX = this.itemsX_[i]
                rank = _findIndex(this.sortedItemsX, function(x) {
                    return x === itemX
                })
                ranks.push(rank)
            }

            return ranks
        },

        // @type {number}
        rankMax: function() {
            return this.sortedItemsX.length
        },

        // @type  {array<number>}
        zIndexs: function() {
            var zIndexs = []

            for (var i = 0, len = this.ranks.length; i < len; ++i ) {
                zIndexs.push(this.ranks[i] + 1)
            }

            return zIndexs
        },

        // @type  {array<number>}
        scales: function() {
            var scales = []

            for (var i = 0, len = this.ranks.length; i < len; ++i ) {
                scales.push( this.getScale(this.ranks[i] + 1) )
            }

            return scales
        },

        // @type  {array<number>}
        opacities: function() {
            var opacities = []

            for (var i = 0, len = this.ranks.length; i < len; ++i ) {
                opacities.push( this.getOpacity(this.ranks[i] + 1) )
            }

            return opacities
        }
    },

    mounted: function() {
        this.$nextTick(function() {
            this.mounted_ = true
            this.setItemsX()
            this.initHammer()
        })
    },

    methods: {
        /**
         * @param  {number}  index
         */
        goTo: function(index) {
            if (this.activeIndex === index) {
                return
            }

            var diff = this.getDiff(index)

            if (Math.abs(diff) > 1) {
                this.doSteppedCycle(index)

            } else if (diff < 0) {
                this.prev()

            } else {
                this.next()
            }
        },

        /**
         * @param  {boolean}  byStepped
         */
        prev: function(byStepped) {
            byStepped = (typeof byStepped === 'undefined') ? false : byStepped

            if (!byStepped) {
                this.clearChangeTimers()
            }

            this.activeIndex--

            if (!byStepped) {
                this.changeTimerIds_.push(
                    setTimeout(function() {
                        this.emitChangedActive('prev')
                    }.bind(this), this.duration)
                )
            }
        },

        /**
         * @param  {boolean}  byStepped
         */
        next: function(byStepped) {
            byStepped = (typeof byStepped === 'undefined') ? false : byStepped

            if (!byStepped) {
                this.clearChangeTimers()
            }

            this.activeIndex++

            if (!byStepped) {
                this.changeTimerIds_.push(
                    setTimeout(function() {
                        this.emitChangedActive('next')
                    }.bind(this), this.duration)
                )
            }
        },

        /**
         * Cycles through items 1 by 1, doing a redraw of positions each time.
         * @param  {number}  index
         */
        doSteppedCycle: function(index) {
            var route = this.findBestRoute(this.activeIndex, index)
            var duration = this.getStepDuration(this.getDiff(index))

            if (route.direction === 'right') {
                this.doSteppedCycleRight_(route.steps, duration)
            } else {
                this.doSteppedCycleLeft_(route.steps, duration)
            }
        },

        onChangeX: function() {
            this.setItemsX()
        },

        // @param  {number}  index
        onClick: function(index) {
            this.goTo(index)
        },

        initHammer: function() {
            this.hammerIns = new Hammer(this.$el, {
                // スクロール効かせるために付けたいけど、swipeの感度が悪くなる。。
                // touchAction: 'pan-y'
            })

            if (('ontouchstart' in window) || window.DocumentTouch && document instanceof window.DocumentTouch) {
                // mobile
                this.hammerIns.get('swipe').set({
                    direction: Hammer.DIRECTION_ALL,
                    threshold: 0
                })
                this.hammerIns.on('swipeleft', this.next.bind(this))
                this.hammerIns.on('swiperight', this.prev.bind(this))

            } else {
                // pc
                this.hammerIns.get('pan').set({direction: Hammer.DIRECTION_ALL})
                this.hammerIns.on('panstart', function(e) {
                    switch (e.additionalEvent) {
                        case 'panleft':
                            this.next()
                            break
                        case 'panright':
                            this.prev()
                            break
                    }
                }.bind(this))
            }
        },

        /**
         * @param  {number}  index
         * @return {number}
         */
        getDiff: function(index) {
            var min = 0
            var max = this.cycleMax - 1

            if ((this.activeIndex === min) && (index === max)) {
                return -1
            } else if ((this.activeIndex === max) && (index === min)) {
                return 1
            } else {
                return index - this.activeIndex
            }
        },

        /**
         * @param  {string}  mode - prev|next|stepped
         */
        emitChangedActive: function(mode) {
            var payload = {
                active: this.activeIndex,
                mode: mode
            }

            this.$emit('changed-active', payload)
            payload = null
        },

        /**
         * ycles through items 1 by 1, doing a redraw of positions each time.
         * @param   {number}  steps
         * @param   {number}  stepDuration
         * @return  {Promise}
         */
        doSteppedCycleRight_: function(steps, stepDuration) {
            var promise, delay

            this.changePromises_.splice(0)
            this.clearChangeTimers()

            for (var i = 0; i < steps; i++) {
                delay = i * stepDuration
                promise = new Promise(function(resolve) {
                    var timerId = setTimeout(function() {
                        this.next(true)
                        resolve()
                    }.bind(this), delay)
                    this.changeTimerIds_.push(timerId)
                }.bind(this))
                this.changePromises_.push(promise)
            }

            // last item(next) animation delay
            promise = new Promise(function(resolve) {
                var timerId = setTimeout(resolve.bind(this), delay + this.duration)
                this.changeTimerIds_.push(timerId)
            }.bind(this))
            this.changePromises_.push(promise)

            return Promise.all(this.changePromises_)
                    .then(function() {
                        this.emitChangedActive('stepped')
                    }.bind(this))
        },

        /**
         * ycles through items 1 by 1, doing a redraw of positions each time.
         * @param   {number}  steps
         * @param   {number}  stepDuration
         * @return  {Promise}
         */
        doSteppedCycleLeft_: function(steps, stepDuration) {
            var promise, delay

            this.changePromises_.splice(0)
            this.clearChangeTimers()

            for (var i = steps, k = 0; i > 0; i--, k++) {
                delay = k * stepDuration
                promise = new Promise(function(resolve) {
                    var timerId = setTimeout(function() {
                        this.prev(true)
                        resolve()
                    }.bind(this), delay)
                    this.changeTimerIds_.push(timerId)
                }.bind(this))
                this.changePromises_.push(promise)
            }

            // last item(prev) animation delay
            promise = new Promise(function(resolve) {
                var timerId = setTimeout(resolve.bind(this), delay + this.duration)
                this.changeTimerIds_.push(timerId)
            }.bind(this))
            this.changePromises_.push(promise)

            return Promise.all(this.changePromises_)
                    .then(function() {
                        this.emitChangedActive('stepped')
                    }.bind(this))
        },

        clearChangeTimers: function() {
            for (var i = 0, len = this.changeTimerIds_.length; i < len; ++i) {
                clearTimeout(this.changeTimerIds_[i])
            }
            this.changeTimerIds_.splice(0)
        },

        /**
         * Calculates the shorted route through the items array (forwards OR backwards)
         * Credit @marcusehaslam for help here!
         *
         * @params  {number}  start: route start position
         * @params  {number}  end: route end position
         * @return  {object} - { direction (string), steps (number) }
         */
        findBestRoute: function(start, end) {
            var left = 0,
                right = 0
            var index = start

            while (index !== end) {
                right++
                index = (index === this.cycleMax - 1) ? 0 : index + 1
            }

            index = start

            while (index !== end) {
                left++
                index = (index === 0) ? this.cycleMax - 1 : index - 1
            }

            return (left > right)
                        ? { direction: 'right', steps: right }
                        : { direction: 'left', steps: left  }
        },

        /**
         * @param   {number}  diff
         * @return  {number}
         */
        getStepDuration: function(diff) {
            var d = Math.floor(this.duration / diff)
            return (d < 100) ? 100 : d
        },

        /**
         * @param  {number}  rank
         * @return {number}
         */
        getScale: function(rank) {
            var sep = Math.floor(this.rankMax / 4 * 3)

            var max = 1
            var min = this.minDepthScale
            var value = (max - min) / this.rankMax

            return (rank > sep) ? max : value * rank + min
        },

        /**
         * @param  {number}  rank
         * @return {number}
         */
        getOpacity: function(rank) {
            var sep = Math.floor(this.rankMax / 3 * 1)

            var max = 1
            var min = this.minDepthOpacity
            var value = (max - min) / this.rankMax

            return (rank > sep) ? max : value * rank + min
        },

        setItemsX: function() {
            var items = this.$refs.items ? this.$refs.items : null
            if (!items || !items.length) return

            this.itemsX_.splice(0)

            for (var i = 0, len = items.length; i < len; ++i) {
                this.itemsX_.push(items[i].x)
            }
        },

    }
}
</script>

<style lang="stylus" scoped>
    .circular-carousel {
        position: relative
    }

    .circular-carousel__item {
        overflow: hidden
        display: block
        position: absolute
        line-height: 1
    }

    .circular-carousel__item.transition {
        -webkit-transition: all 0.35s ease
        -moz-transition: all 0.35s ease
        -o-transition: all 0.35s ease
        transition: all 0.35s ease
    }

    .circular-carousel__item img {
        -webkit-transition: all 0.35s ease
        -moz-transition: all 0.35s ease
        -o-transition: all 0.35s ease
        transition: all 0.35s ease
        vertical-align: top
    }
</style>
