import AbstractValue from '../abstract-value'

export const REVIEW_SECTION_ITEM = {
  NickName: 'nickname',
  Color: 'color',
  Rating: 'rating',
  Text: 'text',
} as const
export type ReviewSectionItem = typeof REVIEW_SECTION_ITEM[keyof typeof REVIEW_SECTION_ITEM]

export interface RawReviewSection {
  allowed: 'all' | ReviewSectionItem[]
}

export default class ReviewSection extends AbstractValue<RawReviewSection> {
  get allowed(): 'all' | ReviewSectionItem[] {
    return this.raw.allowed
  }

  allow(item: ReviewSectionItem): boolean {
    if (this.allowed === 'all') {
      return true
    }

    return !!this.allowed.find(i => i === item)
  }
}
