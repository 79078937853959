import AbstractValue from '@/models/app-config/behavior/abstract-value'
import Search, { RawSearch } from './side-bar/search'

export enum SideBarItem {
  Category = 'category',
  Search = 'search',
}

export interface RawSideBar {
  search: RawSearch
  allowed: 'all' | SideBarItem[]
}

export default class SideBar extends AbstractValue<RawSideBar> {
  get search(): Search {
    return new Search(this.raw.search)
  }
  get allowed(): 'all' | SideBarItem[] {
    return this.raw.allowed
  }
  allow(item: SideBarItem): boolean {
    if (this.allowed === 'all') {
      return true
    }

    return !!this.allowed.find(i => i === item)
  }
}
