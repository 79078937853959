import { ApiCreditcard } from '@/models/api/creditcard'
import {
  CreditcardRepository,
  FrontAPICreditCardRepository,
} from '@/models/payment/creditcard/repository/creditcard-repository'
import {
  CreditcardServiceForSubscription,
} from '@/services/api/subscription/payment/creditcard-service-for-subscription'
import { CreditcardPaymentProviderServiceForSubscription } from '@/services/payment/creditcard-payment-provider/creditcard-payment-provider-service'
import { none, Option, some } from 'fp-ts/lib/Option'

export interface CreditcardForSubscriptionRepository extends CreditcardRepository {
  /**
   * サブスク機能では、一度に一つのクレジットカードしか登録できない使用である前提.
   * @see https://github.com/my-color/front/issues/5817
   */
  findRegistered(): Promise<Option<ApiCreditcard>>
}

export class FrontApiRepositoryForSubscription
  extends FrontAPICreditCardRepository<
      CreditcardServiceForSubscription,
      CreditcardPaymentProviderServiceForSubscription
    >
  implements CreditcardForSubscriptionRepository {

  async findRegistered(): Promise<Option<ApiCreditcard>> {
    const result = await this.creditCardService.list()

    return result.fold(
      (error) => {
        throw error
      },
      (creditcardList: ApiCreditcard[]) => {

        // 有料会員登録機能ではクレカは一つしか登録できない仕様のため、
        // 一覧取得の要素数は 0 か 1 という前提
        return creditcardList.length > 0
               ? some(creditcardList[0])
               : none
      }
    )
  }
}
