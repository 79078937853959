import { CreditcardServiceForSubscription } from '@/services/api/subscription/payment/creditcard-service-for-subscription'
import { KuronekoConfig, kuronekoConfigForPurchase, kuronekoConfigForSubscription } from '@/services/payment/creditcard-payment-provider/provider/kuroneko/config'
import crypto from 'crypto'
import { Either, Left, Right } from 'fp-ts/lib/Either'
import _ from 'lodash'

import { log } from '@/log'

import Urn from '@/models/api/resource/urn'
import {
  CreditcardService,
  CreditcardServiceForPurchase,
  CreditcardToBuildPaymentPayload,
} from '@/services/api/creditcard'
import {
  CreditcardPaymentProviderService,
  CreditcardPaymentProviderServiceForPurchase, CreditcardPaymentProviderServiceForSubscription,
} from '@/services/payment/creditcard-payment-provider/creditcard-payment-provider-service'
import { KuronekoCreditcardPayment } from '@/services/payment/types'
import { User } from '@spa/store/modules/user/types'

const namespace: string = 'kuroneko'

// クロネコ決済の認証区分
export enum AuthDiv {
  NotUseSecure = '0',
  Use3DSecure = '1',
  UseSecurityCode = '2',
  Use3DSecureAndSecurityCode = '3',
}

// クロネコ決済のオプションサービス区分
enum OptServDiv {
  Default = '00',
  UseOpt = '01',
}

const scriptSrc = process.env.PAYMENT_KURONEKO_SCRIPT || ''
const traderCode = process.env.PAYMENT_KURONEKO_TRADER_CODE || ''
const accessKey = process.env.PAYMENT_KURONEKO_ACCESS_KEY || ''

// 現状NotUseSecureかUseSecurityCodeのみ利用可能な実装となっている
// いちおう可変だが、滅多に変わらない想定
// 頻繁に変わるようならconfigから読み取るように修正したほうがよい
export const authDivOnRegisterCard = AuthDiv.UseSecurityCode
export const authDivOnPayment = AuthDiv.NotUseSecure

// 現状UseOptのみ利用可能な実装となっている
const optServDiv = OptServDiv.UseOpt

export interface RegisterPayload {
  cardNumber: string
  expireYear: string
  expireMonth: string
  securityCode: string
  cardOwner: string
}

abstract class KuronekoService extends CreditcardPaymentProviderService {
  readonly scriptSrc = scriptSrc
  readonly namespace = namespace

  readonly registerCardLimit = 3

  protected abstract readonly config: KuronekoConfig
  protected abstract readonly creditcardService: CreditcardService

  get isScriptLoaded(): boolean {
    return !!WebcollectTokenLib
  }

  async createRegisterCardPayload(
    creditcard: RegisterPayload,
    user: User
  ): Promise<Either<Error, string>> {
    const auth = this.convertToAuth(user.payment_accountid!)
    const payloadBase = this.createPayloadToGetToken(auth, authDivOnRegisterCard)
    const result = await this.createToken({
      ...payloadBase,
      ...{
        cardNo: creditcard.cardNumber,
        cardOwner: creditcard.cardOwner,
        cardExp: creditcard.expireMonth + creditcard.expireYear,
        securityCode: creditcard.securityCode,
      },
    })

    if (result.isLeft()) {
      return new Left(result.value)
    }

    return new Right(result.value)
  }

  async createPaymentPayload(
    creditcard: CreditcardToBuildPaymentPayload,
    user: User
  ): Promise<Either<Error, KuronekoCreditcardPayment>> {
    // lastCreditDateは決済が発生すると変更されるので、決済直前に新しい値を取得する
    const reloadResult = await this.creditcardService.get(creditcard.id)
    if (reloadResult.isLeft()) {
      return new Left(reloadResult.value)
    }

    /**
     * ここから
     */
    const realLastCreditDate = reloadResult.value.lastCreditDate!
    const auth = this.convertToAuth(user.payment_accountid!)
    const payloadBase = this.createPayloadToGetToken(auth, authDivOnPayment)
    const result = await this.createToken({
      ...payloadBase,
      ...{
        cardKey: new Urn(creditcard.id).name,
        lastCreditDate: realLastCreditDate,
        securityCode: creditcard.securityCode,
      },
    })

    if (result.isLeft()) {
      return new Left(result.value)
    }
    /**
     * ここまで、サブスクリプションでは不要な処理の可能性が高い.
     * なので、このあたりの「トークンを取得する」という手続きも、クレジットカード一般の処理としてではなく、
     * あくまで単発注文なりで使われる「デフォルトのクレジットカード登録処理」限定のものとしてくくりだしたい.
     *
     * ただ、このあたりが明確になった https://github.com/my-color/front/issues/5818 では時間的制限の都合上、
     * 一旦このメモを残すにとどめている.
     */

    return new Right({
      ...this.creditcardService.buildPaymentPayload(creditcard),
      token: this.config.cardIdToToken(creditcard.id, result.value),
      /**
       * クレジットカード決済におけるユーザー関連のパラメータを、ユーザー情報から構築するが、
       * ユーザー情報が未登録の場合はダミー値で補填.
       *
       * これらはkuroneko側でレコード特定のために使っているものであって、与信には影響しない.
       * @see https://github.com/my-color/front/issues/6515#issuecomment-1143181705
       * @see https://github.com/my-color/front/issues/6170#issuecomment-1076566838
       * @see https://github.com/my-color/front/issues/6515#issuecomment-1143197225
       */
      auth_pass_full_name: `${user.last_name || '-'}${user.first_name || '-'}`,
      // tslint:disable-next-line:no-magic-numbers
      auth_pass_tel: user.tel || '0'.repeat(11),
      auth_pass_email: user.email || '-',
    })
  }

  private async createToken(
    payload: KuronekoPayload
  ): Promise<Either<Error, string>> {
    try {
      const result = await this._createToken(payload)

      log.debug({ service: `${namespace}/createToken`, result })

      return new Right(_.get(result, 'token'))
    } catch (error) {
      log.error({ service: `${namespace}/createToken`, error })

      return new Left(error)
    }
  }

  // tslint:disable-next-line:function-name
  private async _createToken(
    payload: KuronekoPayload
  ): Promise<any> {
    if (!this.isScriptLoaded) {
      return Promise.reject(new Error('script is not loaded.'))
    }

    return new Promise((resolve, reject) => {
      try {
        WebcollectTokenLib.createToken(
          payload,
          (response) => {
            resolve(response)
          },
          (response) => {
            reject(response)
          }
        )
      } catch (err) {
        reject(err)
      }
    })
  }

  private createCheckSum(
    auth: KuronekoCardAuth,
    authDiv: AuthDiv
  ): string {
    return crypto.createHash('sha256')
      .update(auth.memberId + auth.authKey + accessKey + authDiv)
      .digest('hex')
  }

  private createPayloadToGetToken(
    auth: KuronekoCardAuth,
    authDiv: AuthDiv
  ): KuronekoPayloadToCreateToken {
    return {
      traderCode,
      authDiv,
      optServDiv,
      checkSum: this.createCheckSum(auth, authDiv),
      ...auth,
    }
  }

  private convertToAuth(paymentAccount: string): KuronekoCardAuth {
    const part = paymentAccount.split(':')
    const partLengthWithSeparator = 2
    const partLengthWithNoSeparator = 1
    const authKeyLengthOnNoSeparator = 8

    const memberId = `${part[0]}${this.config.memberIdSuffix}`

    if (part.length === partLengthWithSeparator) {
      return {
        memberId,
        authKey: part[1],
      }
    }
    if (part.length === partLengthWithNoSeparator) {
      return {
        memberId,
        authKey: part[0].slice(-1 * authKeyLengthOnNoSeparator),
      }
    }

    throw new Error('invalid paymentAccount')
  }
}

export class KuronekoServiceForPurchase
  extends KuronekoService
  implements CreditcardPaymentProviderServiceForPurchase {

  readonly _context = 'for-purchase'

  protected readonly creditcardService = new CreditcardServiceForPurchase()
  protected readonly config: KuronekoConfig = kuronekoConfigForPurchase
}
export class KuronekoServiceForSubscription
  extends KuronekoService
  implements CreditcardPaymentProviderServiceForSubscription {

  readonly _context = 'for-subscription'

  protected readonly creditcardService = new CreditcardServiceForSubscription()
  protected readonly config: KuronekoConfig = kuronekoConfigForSubscription
}

type KuronekoPayload = KuronekoPayloadToCreateToken & (KuronekoPayloadWithCardNumber | KuronekoPayloadWithCardKey)

export interface KuronekoPayloadToCreateToken extends KuronekoCardAuth {
  traderCode: string,
  authDiv: string,
  optServDiv: string,
  checkSum: string,
}

// クロネコ決済ドキュメント状はauthなしのケースもあるようなので
// KuronekoPayloadBaseとは一応分けてあるが、MCではauthなしのケースは現状実装なし
export interface KuronekoCardAuth {
  memberId: string,
  authKey: string,
}

export interface KuronekoPayloadWithCardNumber {
  cardNo: string,
  cardOwner: string,
  cardExp: string,
  securityCode: string,
}

export interface KuronekoPayloadWithCardKey {
  cardKey: string,
  lastCreditDate: string,
  securityCode: string
}
