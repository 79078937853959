import _ from 'lodash'
import Vue from 'vue'
import { MutationTree } from 'vuex'

import { Contact, ContactState } from './types'

import { log } from '../../../log'

const namespace = 'contact'

export const mutations: MutationTree<ContactState> = {
  load(state, payload: Contact[]) {
    _.set(state, 'error.load', null)

    state.items = sortItems(payload)

    log.debug({ commit: `${namespace}/load`, state })
  },

  errorOnLoad(state, payload: { error: Error }) {
    const { error } = payload
    _.set(state, 'error.load', error)
  },

  update(state, payload: Contact) {
    const index = this.findItemIndex(state, payload)

    Vue.set(state.items, index, payload)

    log.debug({ commit: `${namespace}/update`, state })
  },

  errorOnUpdate(state, payload: { id: string, error: Error }) {
    const { id, error } = payload

    state.items =  _.map(state.items, (item) => {
      if (item.id === id) {
        _.set(item, 'error.update', error)
      }

      return item
    })
  },

  remove(state, id: string) {
    state.items = _.filter(state.items, item => item.id !== id)

    log.debug({ commit: `${namespace}/remove`, state })
  },

  errorOnRemove(state, payload: { id: string, error: Error }) {
    const { id, error } = payload

    state.items = _.map(state.items, (item) => {
      if (item.id === id) {
        _.set(item, 'error.remove', error)
      }

      return item
    })
  },

  findItemIndex(state: ContactState, payload: any): number {
    const { id } = payload

    return _.findIndex(state.items, ['id', id])
  },
}

// TODO: Use immutable way
function sortItems(items: Contact[]) {
  return items.sort((a, b) => {
    return a.display_level - b.display_level
  })
}
