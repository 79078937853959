





















import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

interface Stock {
  label: string
  status: string
}

@Component
export default class ColorStockItem extends Vue {
  @Prop({ required: true })
  color: string

  @Prop({ required: true })
  stockList: Stock[]

  @Prop({ required: true })
  state: string

  @Prop({ default: true })
  visible: boolean

  get iconClass() {
    return {
      fa: true,
      [this.state]: true,
      invisible: !this.visible,
    }
  }
}
