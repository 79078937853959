import loglevel from 'loglevel'

if (process.env.FRONT_ENV === 'production') {
  loglevel.setLevel('warn')
} else if (process.env.FRONT_ENV === 'test') {
  loglevel.disableAll()
} else {
  loglevel.setLevel('debug')
}

export const log = loglevel
