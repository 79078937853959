









import _ from 'lodash'
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import { fromNullable, none, Option } from 'fp-ts/lib/Option'

import { BundleDiscount, findBundleDiscountByFkuId } from '@/services/api/bundle-discount'
import store from '@/store'
import { extractBrandBundleDiscountState } from '@/store/modules/bundle-discount/functions'
import BundleDiscountLabel from './BundleDiscountLabel.vue'

@Component({
  components: {
    BundleDiscountLabel,
  },
})
export default class BundleDiscountLabelWithStore extends Vue {
  @Prop({ required: true })
  appId: string

  @Prop({ required: true })
  fkuId: string

  get bundleDiscount(): Option<BundleDiscount> {
    return fromNullable(
      extractBrandBundleDiscountState(store.state.bundleDiscount, this.appId)
    ).fold(
      none,
      state => findBundleDiscountByFkuId(state.bundleDiscounts, this.fkuId)
    )
  }

  get shouldShow(): boolean {
    return this.bundleDiscount.isSome()
  }

  get labelColor(): string {
    return this.bundleDiscount.fold(
      // TODO より上流でデフォルト値の設定を行う。
      '',
      d => d.label_color
    )
  }
}
