









import { Either, Left, Right } from 'fp-ts/lib/Either'
import Vue, { VueConstructor } from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import { log } from '@/log'
import { InstanceResolver } from '@/util/instanceResolver'
import { ApiClient } from '@ajax/modules/services/client'
import CancelView from '../organisms/external-payment/CancelView.vue'
import ErrorView from '../organisms/external-payment/ErrorView.vue'
import { ViewModel } from '../organisms/external-payment/model/view-model'

@Component({})
export default class ExternalPaymentCancel extends Vue {
  @Prop({ required: true })
  apiPathBase: string

  @Prop({ required: true })
  encodedQueries: string

  view: VueConstructor = Vue.extend({
    template: `<div class="loading"></div>`,
  })
  viewModel: ViewModel | null = null
  apiResponse: ApiResponse | null = null

  isLoading: boolean = true

  // エンコード済みのクエリ文字列を受け取っているためエンドポイントに直接付加する
  get apiPath(): string {
    return `${this.apiPathBase}?${this.encodedQueries}`
  }

  async created() {
    (await this.callApi()).fold(
      (l: Error) => {
        log.error({ error: l })
        this.view = ErrorView
        this.viewModel = {
          nextPath: this.cartPath,
          messages: [
            '注文がキャンセルされましたがカートの復元に失敗した可能性があります。',
            'ご確認いただき、もし商品が揃っていない場合は再度カートへ商品を追加してください。',
          ],
          buttonLabel: 'カートへ戻る',
          autoRedirect: false,
        }
      },
      (r: ApiResponse) => {
        this.view = CancelView
        this.viewModel = this.convertResponseToViewModel(r)
      }
    )

    this.isLoading = false
  }

  private get cartPath(): string {
    if (InstanceResolver.isSingle()) {
      return '/s/cart'
    }

    return '/s/carts'
  }

  private async callApi(): Promise<Either<Error, ApiResponse>> {
    try {
      const response = await ApiClient.get(this.apiPath)

      log.debug({ response })

      return new Right(response.body.data)
    } catch (error) {
      return new Left(error)
    }
  }

  private convertResponseToViewModel(response: ApiResponse): ViewModel {
    return {
      nextPath: response.next_path,
      messages: response.messages,
      buttonLabel: response.button_label,
      autoRedirect: response.auto_redirect,
    }
  }
}

interface ApiResponse {
  next_path: string
  messages: string[]
  button_label: string
  auto_redirect: boolean
}
