import { ListCouponForOrder } from '@spa/components/pages/order/model/order/list-coupon-for-order'
import _ from 'lodash'
import { ActionTree } from 'vuex'

import { RootState } from '../../types'
import { CouponState } from './types'

export interface LoadCouponPayload {
  listCoupon: ListCouponForOrder
  force?: boolean
}

export const actions: ActionTree<CouponState, RootState> = {
  async load({ commit, state }, payload: LoadCouponPayload) {
    if (!_.get(payload, 'force') && !_.isEmpty(state.coupons)) {
      return
    }

    const result = await payload.listCoupon()

    if (result.isLeft()) {
      const error = result.value
      commit('errorOnLoad', error)

      return
    }

    state.coupons = result.value
  },
}
