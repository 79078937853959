import { Either, Left, Right } from 'fp-ts/lib/Either'
import { fromNullable, Option } from 'fp-ts/lib/Option'
import _ from 'lodash'

import { log } from '@/log'
import { ApiContent } from '@/models/api/content'
import { CartItem } from '@spa/store/modules/cart/types'
import { ApiClient } from './client'
import { ApiService } from './service'

const namespace: string = 'bundle_discount'

export default class BundleDiscountService extends ApiService {
  public async listByItemIds(itemIds: string[] = []): Promise<Either<Error, BundleDiscount[]>> {
    try {
      const response = await ApiClient
        .get(`/api/${this.appId}/bundle_discount`)
        .query({
          item_ids: itemIds,
        })

      log.debug({ service: `${namespace}/listByItemIds`, response })

      return new Right(response.body.data)
    } catch (error) {
      log.error({ error })

      return new Left(error)
    }
  }

  async apply(params: ApplyParameter): Promise<Either<FailedFetchBundleDiscountResource, ApplyResponse>> {
    try {
      const response = await ApiClient
        .post(`/api/${this.appId}/applying_bundle_discount`)
        .send({
          items: JSON.stringify(params.items),
        })

      log.debug({ service: `${namespace}/apply`, response })

      return new Right(
        new ApplyResponse(response.body.data)
      )
    } catch (error) {
      log.error({ error })

      return new Left(new FailedFetchBundleDiscountResource(error))
    }
  }
}

export interface AppliedBundleDiscount {
  bundle_discount_id: string,
  applied_to: {
    root_id: string,
    fku_id: string,
    sku_id: string,
    count: number,
    unit_price: number
  }
}

export interface BundleDiscount {
  id: string,
  campaign_id: string,
  title: string,
  label_color: string,
  fku_ids: string[],
}

export interface BundleDiscountWithBrand extends BundleDiscount {
  brand: {
    brand_english_name: string
  }
}

export interface ApplyParameter {
  items: ApplyParameterItem[]
}

export interface ApplyParameterItem {
  root_id: string,
  fku_id: string,
  sku_id: string,
  count: number,
  unit_price: number,
}

/**
 * FIXME: Move into domain model
 */
export function convertToApplyBundleDiscountParamter(cartItems: CartItem[]): ApplyParameter {
  return {
    items: cartItems.map((cartItem) => {
      return {
        root_id:  cartItem.rootId!,
        fku_id:  cartItem.fkuId,
        sku_id:  cartItem.skuId,
        unit_price: cartItem.unitPrice,
        count: cartItem.unitCount,
      }
    }),
  }
}

export class ApplyResponse {
  public constructor(private raw: any) {}

  get discount(): number {
    return _.get(this.raw, 'discount', 0)
  }

  get appliedBundleDiscounts(): AppliedBundleDiscount[] {
    return _.get(this.raw, 'appliedDiscounts', [])
  }

  get bundleDiscounts(): BundleDiscountWithBrand[] {
    return _.get(this.raw, 'bundleDiscounts', [])
  }

  get contents(): ApiContent[] {
    return _.get(this.raw, 'contents', []).map((d: any) => new ApiContent(d))
  }
}

export class FailedFetchBundleDiscountResource {
  public constructor(readonly error: any) {}

  /**
   * 404で取得できなかった場合を、まとめ買い機能が提供されていないためとみなす。
   * https://github.com/my-color/front/issues/3069#issuecomment-577014897
   */
  get isDueToBundleDiscountUnsupported(): boolean {
    // tslint:disable-next-line: no-magic-numbers
    return this.error.status === 404
  }
}

/**
 * BundleDiscountのファーストクラスコレクションを作ってもよいかもしれない。
 */
export function findBundleDiscountByFkuId(bundleDiscounts: BundleDiscount[], fkuId: string): Option<BundleDiscount> {
  return fromNullable(
    bundleDiscounts.find((bundleDiscount) => {
      return _.includes(bundleDiscount.fku_ids, fkuId)
    })
  )
}
