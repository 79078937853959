import { InstanceResolver } from '@/util/instanceResolver'

const path = '/assets-proxy/images'
const dummyImage = 'item-dummy.png'

export const dummyImagePath = `${path}/${dummyImage}`

export function getImageUrl(fileName: string): string  {
  return `${path}/${fileName}`
}

const itemLoadingImagePrefix = InstanceResolver.isSingle() ? `${InstanceResolver.getSingleBrand()}/` : ''
export const itemLoadingImagePath = `${path}/${itemLoadingImagePrefix}item-loading.gif`
