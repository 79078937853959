import { ActionTree } from 'vuex'
import { RootState } from '../../types'
import { ViewState } from './types'

export const actions: ActionTree<ViewState, RootState> = {

  startLoading({ commit }) {
    commit('startLoading')
  },
  stopLoading({ commit }) {
    commit('stopLoading')
  },
}
