import _ from 'lodash'
import { ActionTree } from 'vuex'

import { ContactService } from '../../../services/api/contact'
import { RootState } from '../../types'
import { Contact, ContactState, convertToContact, convertToContacts, } from './types'

export const actions: ActionTree<ContactState, RootState> = {
  async load({ commit, state }, payload?: { force?: boolean }) {
    if (!_.get(payload, 'force') && !_.isEmpty(state.items)) {
      return
    }

    const result = await new ContactService().list()

    if (result.isLeft()) {
      const error = result.value
      commit('errorOnLoad', {
        load: error,
      })

      return
    }

    // TODO: Refactoring
    const items: Contact[] = convertToContacts(result.value.body.data)

    commit('load', items)
  },

  async get({ commit, state }, payload: { id: string, force?: boolean }) {
    if (!_.get(payload, 'force') && !_.isEmpty(state.items)) {
      return
    }

    const { id } = payload

    const result = await new ContactService().get(id)

    if (result.isLeft()) {
      const error = result.value
      commit('errorOnLoad', {
        load: error,
      })

      return
    }

    const item: Contact = convertToContact(result.value.body.data)

    commit('load', [item])
  },

  async update({ commit }, payload: Contact) {
    const { id } = payload

    const result = await new ContactService().update(id, payload)

    if (result.isLeft()) {
      const error: Error = result.value

      commit('errorOnUpdate', {
        id,
        error,
      })

      return
    }

    commit('update', payload)
  },

  async remove({ commit }, id: string) {
    const result = await new ContactService().remove(id)

    if (result.isLeft()) {
      const error: Error = result.value

      commit('errorOnDelete', {
        id,
        error,
      })

      return
    }

    commit('remove', id)
  },
}
