import _ from 'lodash'

export default class Urn {
  private SEPARATOR = ':'
  private PARTS_MIN_LENGTH = 2

  // urn example: 'urn:everforth:commerce:mycolor:card:kuroneko:123'
  constructor(private urn: string) {}

  get toString(): string {
    return this.urn
  }

  get parts(): string[] {
    const parts = this.urn.split(this.SEPARATOR)
    if (parts.length < this.PARTS_MIN_LENGTH) {
      throw new Error('urn name is not defined')
    }

    return parts
  }

  private get withoutName(): string {
    return this.urn.slice(0, -1 * this.name.length)
  }

  get name(): string {
    const name = _.last(this.parts)
    if (!name) {
      throw new Error('urn name is not defined')
    }

    return name
  }

  setName(name: string) {
    return new Urn(this.withoutName + name)
  }
}
