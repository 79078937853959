import _ from 'lodash'
import { GetterTree } from 'vuex'

import { RootState } from '../../types'
import { allItems } from './functions/page'
import { Order, OrderHistoryState } from './types'

export const getters: GetterTree<OrderHistoryState, RootState> = {
  hasError(state): boolean {
    return _.has(state, 'error.error')
  },

  getError(state): Error {
    return _.get(state, 'error.error')
  },

  allItems(state): Order[] {
    return allItems(state.pages)
  },

  getItem(state): (string) => Order | undefined {
    return (id: string) => {
      return _.find(allItems(state.pages), ['id', id])
    }
  },
}
