import Cookies from 'js-cookie'

export class CookieService {

  /**
   * ログイン状態かどうかを判定します
   * @return {boolean}
   */
  public static isLogined(): boolean {
    const accessToken = Cookies.get('a')

    return !(typeof accessToken === 'undefined' || accessToken === null)
  }

  /**
   * 会員種別が本登録かどうかを返します
   * @return {boolean}
   */
  public static isRegistered(): boolean {
    const userStatus = Cookies.get('s')

    return userStatus === 'registered'
  }

  /**
   * 会員種別が仮登録かどうかを返します
   * @return {boolean}
   */
  public static isProvisional(): boolean {
    const userStatus = Cookies.get('s')

    return !this.isGuest() && (userStatus === 'provisional' || !userStatus)
  }

  /**
   * ゲストユーザー（アクセストークンがない）かどうかを返します
   * @return {boolean}
   */
  public static isGuest(): boolean {
    const accessToken = Cookies.get('a')

    return typeof accessToken === 'undefined' || accessToken === null
  }
}
