









import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import { UtilService } from '@spa/services/util'

@Component
export default class PageTransitionLink extends Vue {
  @Prop({ required: true })
  url: string

  get linkTag() {
    return UtilService.isSpa() ? 'router-link' : 'a'
  }

  get linkAttr() {
    return UtilService.isSpa() ? { to: this.url } : { href: `/s${this.url}` }
  }
}
