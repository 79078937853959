































import ItemLink from '@/components/molecules/item/ItemLink.vue'
import { log } from '@/log'
import { ApiProductclass } from '@/models/api/productclass'
import { Sku } from '@/models/api/productclass/sku'
import BehaviorConfig from '@/models/app-config/behavior/behavior'
import * as SKUFactory from '@/models/item/sku/factory/sku'
import GetStockStatus from '@/usecase/get-stock-status'
import { BrandRouteResolver } from '@/util/brandRouteResolver'
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import AddToCartButtonBySku from './AddToCartButtonBySku.vue'

@Component({
  components: {
    AddToCartButtonBySku,
    ItemLink,
  },
})
export default class AddToCartButtonInList extends Vue {
  @Prop({ required: true })
  readonly fku: ApiProductclass

  created() {
    if (!this.fkuIsValid) {
      log.warn(`${this.fku.id} is invalid status, so not render ${AddToCartButtonInList.name} for the fku`)
    }
  }

  get uniqueSkuStatus(): { id: string, isSoldOut: boolean, nowOnSale: boolean } {
    const stock = SKUFactory.create(this.firstSku).stock

    return {
      id: this.firstSku.id,
      isSoldOut: this.stockStatus.isSoldOut(stock),
      nowOnSale: this.firstSku.isOnRegularSale || this.firstSku.isOnDiscountSale,
    }
  }

  get root(): ApiProductclass {
    const maybeRoot = this.fku.root
    if (maybeRoot === null) {
      throw new Error(`${this.fku.id} not belonging to any root item, this item maybe illegal`)
    }

    return maybeRoot
  }

  /**
   * SKUが一意であるなら、カート追加すべき商品もFKUレベルで特定可能
   */
  get purchasableSkuIsUnique(): boolean {
    return this.fku.materializedSkuList.length === 1
  }
  get isEnabled(): boolean {
    return this.behavior.itemList.item.showAddToCartButton
  }

  private get firstSku(): Sku {
    return this.fku.materializedSkuList[0]
  }

  private get stockStatus(): GetStockStatus {
    return GetStockStatus.createViaConfig(this.behavior)
  }
  private get brandEnglishName(): string {
    return this.$route.params.brand_english_name
  }
  private get brandName(): string {
    return BrandRouteResolver.resolveBrandFromPathElement(this.brandEnglishName)
  }
  private get behavior(): BehaviorConfig {
    return BehaviorConfig.createFromBrand(this.brandName)
  }

  private get fkuIsValid(): boolean {
    return this.fku.isFku
      && this.fku.materializedSkuList.length > 0
      && this.fku.root !== null
      && this.fku.color !== null
  }
}
