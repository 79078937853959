import _ from 'lodash'
import { GetterTree } from 'vuex'

import { ApiCoupon } from '@/models/api/coupon'
import { CouponCode } from '@/models/api/coupon/coupon-code/coupon-code'
import { CouponCodeFactory } from '@/models/api/coupon/coupon-code/coupon-code-factory'
import { RootState } from '../../types'
import { CouponState } from './types'

export const getters: GetterTree<CouponState, RootState> = {
  // If only available coupons are loaded, this filtering may have partly a duplicate effect.
  availableCoupons(state): ApiCoupon[] {
    return _.filter(state.coupons, (coupon: ApiCoupon) => coupon.isAvailable)
  },
  allCoupons(state): ApiCoupon[] {
    return state.coupons
  },
  // クーポンコード付き以外の利用可能なクーポンを取得.
  availableCouponsWithoutCouponCode(state): ApiCoupon[] {
    return _.filter(state.coupons, (coupon: ApiCoupon) => coupon.isAvailable && !coupon.hasCouponCode)
  },
  // 利用可能なクーポンコードを取得.
  availableCouponCodes(state): CouponCode[] {
    /**
     * CouponCodeFactoryの導入に伴い、
     * ApiCoupon#hasCouponCodeによるチェックは、本来であればCouponCodeFactoryの責務として委譲したい。
     * ただし現状は、上でクーポンコード以外のクーポンをフィルタするときにも、ApiCoupon#hasCouponCodeを利用しているため、
     * 平仄を合わせるため、ここでもApiCoupon#hasCouponCodeを使用したコードを既存のまま置いておく。
     */
    return _.filter(state.coupons, (coupon: ApiCoupon) => coupon.isAvailable && coupon.hasCouponCode)
      .map((coupon) => {
        /**
         * クーポンコードを生データから生成しているのは暫定的なもの。
         * couponというApiCoupon型が、ドメインモデルというよりは単なるEngineレスポンスのラッパにすぎないので、
         * - クーポンのドメインモデルをストアで受け取っておくようにする。
         * - 同様にクーポンコードのドメインモデルをストアで受け取っておくようにする。
         */
        return new CouponCodeFactory().create(coupon.rawData)
      })
  },
}
