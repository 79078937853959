import _ from 'lodash'

/**
 * TODO:
 * 現状の形だと、jsonもバンドルに含まれることで、ユーザはjsを調べることで他のブランド/タウンの設定値も知ることができる.
 * 現時点では特に機微情報も含まないため一旦はこのままにしておくが、将来的にはビルド時に必要なタウンの設定だけを取り込む形に修正する.
 *
 * 機微情報あるいはそれに準ずる内容が必要になった場合は、直ちに↑の対応が必要.
 *
 * https://github.com/my-color/front/pull/612#pullrequestreview-206263182
 */
import RawBehaviorConfig from '@/config/app/behavior.json'
import ApiBrand from '@/models/api/brand'
import ShippingFee from '@/models/shipping-fee'
import AppId, { HOST_ID } from '@/util/appid'
import Company from './attributes/company'
import { BrandShippingFeeBuilder } from './config/fix-shipping-fee'
import Footer from './config/footer'
import ItemList from './config/item-list'
import Layer from './config/layer'
import Mypage from './config/mypage'
import ReviewSection from './config/review-section'
import SideBar from './config/side-bar'
import SlideMenu from './config/slide-menu'
import {
  BrandEntity,
  BrandGroupEntity,
  Config,
  FilterIsExclusive,
  RawConfig,
} from './types'

import ActualCurrentTime from '@/models/current-time/ActualCurrentTime'
import BundleRecommendation from './config/bundle-recommendation'
import { PaidMembership } from './config/paid-membership'

export default class BehaviorConfig {
  static createFromBrand(brand: ApiBrand | string | null): BehaviorConfig {
    const brandIdentifier = brand instanceof ApiBrand ? brand.englishName : brand
    const brandAppId = AppId.getByBrandName(brandIdentifier)

    return new BehaviorConfig(
      brandAppId,
      RawBehaviorConfig as any,
      brandIdentifier
    )
  }
  static get forHost(): BehaviorConfig {
    return new BehaviorConfig(
      AppId.getHostId(),
      RawBehaviorConfig as any,
      null
    )
  }

  constructor(
    private readonly brandAppId: string | null = null,
    private readonly json: RawConfig = RawBehaviorConfig as any,
    private readonly brandIdentifier: string | null = null
  ) { }

  get groupId(): string {
    return this.getGroupAttribute('id')
  }

  get groupLabel(): string {
    return this.getGroupAttribute('label')
  }

  get company(): Company {
    return new Company(this.getGroupAttribute('company'))
  }

  /* is{ホスト名}系は、利用側が直接AppIdモジュールを参照すれば、一通り不要？ */

  get isMyColor(): boolean {
    return this.hostId === AppId.getByHostName('mycolor')
  }

  /**
   * @deprecated DMIは脱退済みなので、削除予定
   */
  get isDMI(): boolean {
    return this.hostId === 'DMIWeb'
  }

  get isHl(): boolean {
    return this.hostId === AppId.getByHostName('hl')
  }

  get isMyPalette(): boolean {
    return this.hostId === AppId.getByHostName('mypalette')
  }

  get isRily(): boolean {
    return this.hostId === AppId.getByHostName('rily')
  }

  get isFitowa(): boolean {
    return this.hostId === AppId.getByHostName('fitowa')
  }

  get isLedian(): boolean {
    return this.hostId === AppId.getByHostName('ledian')
  }

  get slideMenu(): SlideMenu {
    return new SlideMenu(this.get('slideMenu'))
  }

  get reviewSection(): ReviewSection {
    return new ReviewSection(this.get('reviewSection'))
  }

  get itemList(): ItemList {
    return new ItemList(this.get('itemlist'))
  }
  get footer(): Footer {
    return new Footer(this.get('footer'))
  }

  get sideBar(): SideBar {
    return new SideBar(this.get('sidebar'))
  }

  get mypage(): Mypage {
    return new Mypage(this.get('mypage'))
  }

  get useCarat(): boolean {
    return this.get('useCarat')
  }

  get isGenreShown(): boolean {
    return this.get('isGenreShown')
  }

  get hostId(): string {
    return HOST_ID
  }

  get brandGroup(): BrandGroupEntity | null {
    const group = this.json.brandGroup[this.hostId]

    return group || null
  }
  get brand(): BrandEntity | null {
    if (this.brandAppId === null) {
      return null
    }

    return _.get(
      this.brandGroup,
      `brand.${this.brandAppId}`,
      null
    )
  }
  get brandName(): string | null {
    if (this.brandIdentifier === null) {
      return null
    }

    return this.brandIdentifier.length > 0 ? this.brandIdentifier : null
  }

  get default(): BrandGroupEntity {
    return this.json.default
  }
  get layer(): Layer {
    return new Layer(this.get('layer'))
  }

  get authentication() {
    return {
      sms: this.get('authentication').sms,
    }
  }

  get filterIsExclusive(): FilterIsExclusive {
    return this.get('filterIsExclusive')
  }

  get mailDomain(): string {
    return this.get('mailDomain')
  }

  get paidMembership(): PaidMembership {
    return this.get('paidMembership')
  }

  get bundleRecommendation(): BundleRecommendation {
    return new BundleRecommendation(this.get('bundleRecommendation'))
  }

  fixShippingFee(totalPrice: number): ShippingFee {
    const raw = this.get('fixShippingFee')

    return new BrandShippingFeeBuilder(raw, new ActualCurrentTime()).build(totalPrice)
  }

  private get<T extends keyof Config>(path: T): Config[T] {
    /*
     * 優先度はブランドの設定 > タウンの設定 > デフォルトの設定
     */
    const brandConfig = _.get(
      this.brand,
      `config.${path}`,
      null
    )

    if (brandConfig !== null) {
      return brandConfig
    }

    const brandGroupConfig = _.get(
      this.brandGroup,
      `config.${path}`,
      null
    )
    if (brandGroupConfig !== null) {
      return brandGroupConfig
    }

    return this.default.config[path]
  }

  private getGroupAttribute<T extends keyof BrandGroupEntity>(key: T): BrandGroupEntity[T] {
    return _.get<BrandGroupEntity, T, BrandGroupEntity[T]>(
      this.brandGroup,
      key,
      this.default[key]
    )
  }
}
