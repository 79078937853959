export function getSlideColor(slick: any, slideIndex: number): string | undefined {
  const slide =  slick.$slides[slideIndex]
  const $li = $(slide).find('.p-item--imageArea__sub__item').first()
  const color = $li.attr('data-color-name')

  return color
}

export function trackSlideButton() {
  $('.js-thumb-main-btn-right').on('click', () => {
    window.dataLayer.push({
      event: 'detail_event',
      eventCategory: 'detail_click',
      eventAction: 'slider',
      eventLabel: 'right',
    })
  })

  $('.js-thumb-main-btn-left').on('click', () => {
    window.dataLayer.push({
      event: 'detail_event',
      eventCategory: 'detail_click',
      eventAction: 'slider',
      eventLabel: 'left',
    })
  })
}

export function trackFavorite() {
  trackFavoriteByBrowserType('sp')
  trackFavoriteByBrowserType('pc')
}

function trackFavoriteByBrowserType(type: string) {
  $(document).on('click', `.js-favorite--${type} .c-favorite`, function () {
    const slick = $(`#js-thumb-${type}-sub`).slick('getSlick')
    const state = $(this).hasClass('c-favorite--register') ? 'unfav' : 'fav'
    const color = getSlideColor(slick, slick.currentSlide)
    window.dataLayer.push({
      event: 'detail_event',
      eventCategory: 'detail_click',
      eventAction: state,
      eventLabel: color,
    })
  })
}

export function trackCartin() {
  const cartInSelector = '.p-item__addItem'
  $(document).on('click', cartInSelector, () => {
    const slick = getSlick()
    const color = getSlideColor(slick, slick.currentSlide)

    window.dataLayer.push({
      event: 'detail_event',
      eventCategory: 'detail_click',
      eventAction: 'cart_in',
      eventLabel: color,
    })
  })
}

export function trackTwitter() {
  const twitterSelector = '.fa-twitter' // TODO: More robust way to identify the target
  $(document).on('click', twitterSelector, () => {
    const slick = getSlick()
    const color = getSlideColor(slick, slick.currentSlide)

    window.dataLayer.push({
      event: 'detail_event',
      eventCategory: 'detail_click',
      eventAction: 'tw',
      eventLabel: color,
    })
  })
}

export function trackFacebook() {
  const facebookSelector = '.fa-facebook' // TODO: More robust way to identify the target
  $(document).on('click', facebookSelector, () => {
    const slick = getSlick()
    const color = getSlideColor(slick, slick.currentSlide)

    window.dataLayer.push({
      event: 'detail_event',
      eventCategory: 'detail_click',
      eventAction: 'fb',
      eventLabel: color,
    })
  })
}

export function trackLine() {
  const lineSelector = '.c-share--light__link__line' // TODO: More robust way to identify the target
  $(document).on('click', lineSelector, () => {
    const slick = getSlick()
    const color = getSlideColor(slick, slick.currentSlide)

    window.dataLayer.push({
      event: 'detail_event',
      eventCategory: 'detail_click',
      eventAction: 'line',
      eventLabel: color,
    })
  })
}

export function trackFaq() {
  const collapsedSelector = '.p-pages--faq__title.collapsed'

  $(document).on('click', collapsedSelector, (event) => {
    const title = $(event.target).text()

    const payload = {
      event: 'faq',
      eventCategory: 'faq',
      eventAction: 'show',
      eventLabel: title,
    }

    window.dataLayer.push(payload)
  })

  const expandedSelector = '.p-pages--faq__title:not(.collapsed)'

  $(document).on('click', expandedSelector, (event) => {
    const title = $(event.target).text()

    const payload = {
      event: 'faq',
      eventCategory: 'faq',
      eventAction: 'hide',
      eventLabel: title,
    }

    window.dataLayer.push(payload)
  })
}

function getSlick(): any {
  return $('.js-thumb-sub').slick('getSlick')
}
