import { EventBusForThirdParty } from '../event-bus'
import { EventSubscriptionFacade } from './facade'
import { handleAddToCart } from './ledian/handle-add-to-cart'
import { handleAddToFavorite } from './ledian/handle-add-to-favorite'
import { handleEveryPageView } from './ledian/handle-every-page-view'
import { handleItemDetailView } from './ledian/handle-item-detail-view'
import { handleLogIn } from './ledian/handle-login'
import { handlePurchase } from './ledian/handle-purchase'
import { handleSignUp } from './ledian/handle-signup'
import { handleSubscribeMembershipPlan } from './ledian/handle-subscribe-membership-plan'

export class LedianEventSubscriptionFacade implements EventSubscriptionFacade {
  constructor() {
    /** GAで使用するdataLayerがundefinedでないことを保証する。 */
    window.dataLayer = window.dataLayer || []
  }

  subscribeAll(): void {
    const eventBus = EventBusForThirdParty.getInstance()
    eventBus.subscribe('signup', handleSignUp)
    eventBus.subscribe('every-page-view', handleEveryPageView)
    eventBus.subscribe('item-detail-view', handleItemDetailView)
    eventBus.subscribe('login', handleLogIn)
    eventBus.subscribe('add-to-favorite', handleAddToFavorite)
    eventBus.subscribe('add-to-cart', handleAddToCart)
    eventBus.subscribe('purchase', handlePurchase)
    eventBus.subscribe('subscribe-membership-plan', handleSubscribeMembershipPlan)
  }
}
