

































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import { Color } from '@/models/tag/color'

@Component
export default class ColorPicker extends Vue {

  @Prop({ required: true })
  colorList: Color[]

  @Prop({ required: true })
  handleOnChange: (item: any) => void

  @Prop({ required: true })
  handleClear: () => void

  colorPickerItemClass(suffix: string) {
    return {
      'c-form--checkbox--color__check': true,
      [`c-color-picker--item__color--${suffix}`]: true,
    }
  }
}
