import _ from 'lodash'
import Vue from 'vue'
import { MutationTree } from 'vuex'

import { CreditcardModel, CreditcardState } from './types'

import { log } from '../../../log'

const namespace = 'creditcard'

export const mutations: MutationTree<CreditcardState> = {
  load(state, payload: CreditcardModel[]) {
    _.set(state, 'error.load', null)
    state.items = payload

    log.debug({ commit: `${namespace}/load`, state })
  },

  errorOnLoad(state, payload: { error: Error }) {
    const { error } = payload

    Vue.set(state.error, 'load', error)

    log.debug({ commit: `${namespace}/errorOnLoad`, state })
  },

  remove(state, id: string) {
    state.items = _.filter(state.items, item => item.id !== id)

    log.debug({ commit: `${namespace}/remove`, state })
  },

  errorOnRemove(state, payload: { id: string, error: Error }) {
    const { id, error } = payload

    state.items = _.map(state.items, (item) => {
      if (item.id === id) {
        _.set(item, 'error.remove', error)
      }

      return item
    })
  },
}
