import _ from 'lodash'
import RJSON from 'relaxed-json'
import { GetterTree } from 'vuex'

import { RemainingCount } from '@/services/api/current-user'
import { RootState } from '../../types'
import { UserState } from './types'

export const getters: GetterTree<UserState, RootState> = {
  // TODO: Refactor
  hasError(state): boolean {
    return _.has(state, 'error.error')
  },

  // TODO: Refactor
  getError(state): Error {
    return _.get(state, 'error.error')
  },

  getCarat(state): string {
    if (state.user === null) {
      return '0'
    }

    return !_.isUndefined(state.user.current_point) ? Number(state.user.current_point).toLocaleString('ja-JP') : '0'
  },

  hasErrorThatuserAlreadyExists(state): boolean {
    const error = _.get(state, 'error.register')
    const cause = _.get(error, 'response.body.cause', '')

    return !_.isEmpty(cause.match(/^\[IllegalArgumentException\]username\(.+\) is already exists\.$/))
  },

  getCaratHistory(state): any {
    return getCaratHistory(state)
  },

  getRegisterMember(state): boolean {
    const history = getCaratHistory(state)

    return _.get(history['initial-carats'], 'register-member')
  },

  getFillMemberInfo(state): boolean {
    const history = getCaratHistory(state)

    return _.get(history['initial-carats'], 'fill-member-info')
  },

  getFirstLikeItem(state): boolean {
    const history = getCaratHistory(state)

    return _.get(history['initial-carats'], 'first-like-item')
  },

  getFirstSharePage(state): boolean {
    const history = getCaratHistory(state)

    return _.get(history['initial-carats'], 'first-share-page')
  },

  getFirstPurchase(state): boolean {
    const history = getCaratHistory(state)

    return _.get(history['initial-carats'], 'first-purchase')
  },

  getFirstShareItem(state): boolean {
    const history = getCaratHistory(state)

    return _.get(history['initial-carats'], 'first-share-item')
  },

  getFirstIntroduceFriend(state): boolean {
    const history = getCaratHistory(state)

    return _.get(history['initial-carats'], 'first-introduce-friend')
  },

  getFirstJoinEvent(state): boolean {
    const history = getCaratHistory(state)

    return _.get(history['initial-carats'], 'first-join-event')
  },

  getInitialCaratsCompletion(state): boolean {
    const history = getCaratHistory(state)

    return _.get(history['initial-carats'], 'initial-carats-completion')
  },

  hasMinimumNecessaryProfileForPurchase(state): boolean {
    const user = state.user

    const required = [
      'first_name',
      'last_name',
      'phonetic_first_name',
      'phonetic_last_name',
      'email',
      'tel',
      'zipcode',
      'region',
      'address1',
      'address2',
    ]

    // requiredの内、1項目でも未設定だったらfalse
    return required.every(prop => !!_.get(user, prop))
  },

  /**
   * 会員情報コンプリートかどうかの判定
   * ポイントシステムの反映（current_point や aux_text_10）タイムラグがあるためユーザー情報を直接参照する
   * @param state
   */
  isFillMemberInfo(state): boolean {
    const user = state.user

    const required = [
      'email',
      'first_name',
      'last_name',
      'tel',
      'phonetic_first_name',
      'phonetic_last_name',
      'gender',
      'birthday',
      'zipcode',
      'region',
      'address1',
      'address2',
    ]

    // requiredの内、1項目でも未設定だったらfalse
    return required.reduce((prev: boolean, prop: string) => prev && !!_.get(user, prop), true)
  },

  isPaidMember(state): boolean {
    if (state.user === null) return false

    const { enabled, registered_plans } = state.user.membership

    return enabled && registered_plans.length > 0
  },

  remainingCountForPaidMembershipPrice(state): RemainingCount|null {
    if (state.user === null) {
      return null
    }

    return state.user.membership.remaining_count
  },
}

function getCaratHistory(state): any {
  const auxText10 = _.get(state, 'user.aux_text_10')

  if (!auxText10) {
    return {}
  }

  return RJSON.parse(auxText10)
}
