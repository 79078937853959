

















import { Either } from 'fp-ts/lib/Either'
import _ from 'lodash'
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import AppId from '@/util/appid'
import { BrandRouteResolver } from '@/util/brandRouteResolver'

import ItemListAutoLoadable from '@/components/organisms/item-list/AutoLoadable.vue'
import { AutoLoadError } from '@/components/organisms/item-list/model/auto-loadable'
import { ListWithPagination } from '@/models/api/response/list'
import { ItemKind } from '@/models/item/kind'
import ItemService from '@/services/api/productclass'
import SelectThumbnailImageBuilder from '@/services/product-image/select-thumbnail-image-builder'

import { log } from '@/log'

@Component({
  components: {
    ItemListAutoLoadable,
  },
})
/**
 * MPAの商品一覧画面 or フリーコンテンツの商品リストから使用される商品一覧表示コンポーネント
 */
export default class Itemlist extends Vue {

  @Prop({ default: () => ({}) })
  query: { [key: string]: any }

  @Prop({ required: true })
  displayType: string

  @Prop({ default: 24 })
  defaultCount: number

  @Prop({ default: false })
  instaview: boolean

  @Prop({ default: 3 })
  column: number

  @Prop({ default: false })
  instapage: boolean

  @Prop({ default: false })
  dynamicMode: boolean

  @Prop({ default: false })
  counterMode: boolean

  @Prop({ default: 'default' })
  itemKind: ItemKind

  @Prop({ default: null })
  displayLevelOfImageToDisplay: number | null

  @Prop({ default: null })
  imageAppKindOfImageToDisplay: string | null

  get basePage(): number {
    const ajaxBasePage = 2

    return ajaxBasePage
  }

  get brandName(): string {
    return BrandRouteResolver.resolveBrandFromPathElement(this.$route.params.brand_english_name)
  }

  get appId(): string {
    return AppId.getByBrandName(this.brandName)
  }

  get itemService(): ItemService {
    return new ItemService(this.appId)
  }

  // TODO: queryにページング用パラメータを渡せないようにする
  get queryWithoutPaging(): { [key: string]: any } {
    const pagingKeys = ['limit', 'start']

    return _.omit(this.query, pagingKeys)
  }

  get selectThumbnailImageBuilder(): SelectThumbnailImageBuilder {
    return this.itemKind === 'contact-lens' ?
      SelectThumbnailImageBuilder.createForContactLens(
        this.instapage,
        this.displayLevelOfImageToDisplay,
        this.imageAppKindOfImageToDisplay
      ) :
      SelectThumbnailImageBuilder.createForApparel(this.instapage)
  }

  fetchItemList(baseQuery: object): Promise<Either<Error, ListWithPagination<any>>> {
    return this.itemService.listFkuWithPagination({
      ...baseQuery,
      ...this.queryWithoutPaging,
    })
  }

  onError(error: AutoLoadError) {
    log.error(error)
  }
}
