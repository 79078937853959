import { BundlePurchaseItem } from '../../bundle-purchase-item'

export interface AddItemsToCartSpec {
  isSatisfiedBy(purchaseList: BundlePurchaseItem[]): boolean
}

export class AddItemsToCartSpecFactory {
  constructor(private readonly specs: AddItemsToCartSpec[]) { }

  create(): AddItemsToCartSpec {
    return {
      isSatisfiedBy: (purchaseList) => {
        return this.specs.every(spec => spec.isSatisfiedBy(purchaseList))
      },
    }
  }
}
