import Stock from '@/models/item/stock'
import DisplayRule from '@/models/item/stock/specification/display-rule'

export default class DisplayNothing implements DisplayRule {
  shouldApply(_stock: Stock): boolean {
    return true
  }
  apply(_: Stock): string {
    return ''
  }
}
