
import BehaviorConfig from '@/models/app-config/behavior/behavior'
import DisplayRuleSet from '@/models/item/stock/specification/display-rule/display-rule-set'
import { CoelFewDependOnWeb } from '@/models/item/stock/specification/display-rule/each-case/coel-few-depend-on-web'
import {
  CoelNoDisplayDependOnWebStock
} from '@/models/item/stock/specification/display-rule/each-case/coel-no-display-depend-on-web-stock'
import DisplayNothing from '@/models/item/stock/specification/display-rule/each-case/display-nothing'
import NoDisplayAboutStock from '@/models/item/stock/specification/display-rule/each-case/display-nothing-about-stock'
import DisplayStockIsFew from '@/models/item/stock/specification/display-rule/each-case/display-stock-is-few'
import DisplayStockIsSome from '@/models/item/stock/specification/display-rule/each-case/display-stock-is-some'
import SoldOut from '@/models/item/stock/specification/display-rule/each-case/soldout'
import WebStockIsFew from '@/models/item/stock/specification/display-rule/each-case/web-stock-is-few'
import WebStockIsSome from '@/models/item/stock/specification/display-rule/each-case/web-stock-is-some'
import StockIsFew from '@/models/item/stock/specification/stock-is-few'
import GetStockStatus from '../get-stock-status'

export interface Policy {
  create(): GetStockStatus
}

export class MyColorDefaultPolicy implements Policy {
  static createViaConfig(behaviorConfig: BehaviorConfig): Policy {
    const badgeBehavior = behaviorConfig.itemList.item.badge
    const border = badgeBehavior.few.border

    return new MyColorDefaultPolicy(
      border.onDisplayFew,
      border.onDisplayAmount,
      border.onActual,
      badgeBehavior.soldOut.label
    )
  }

  constructor(
    private readonly borderOfFew: number,
    private readonly borderOfAmount: number,
    private readonly borderOfActual: number,
    private readonly labelOfSoldOut: string
  ) { }

  create() {
    const ruleSet = new DisplayRuleSet([
      new DisplayStockIsFew(
        new StockIsFew(this.borderOfFew).rejectWhenEmpty
      ),
      new DisplayStockIsSome(
        new StockIsFew(this.borderOfAmount).rejectWhenEmpty
      ),
      new WebStockIsFew(
        new StockIsFew(this.borderOfActual)
      ),
      new WebStockIsSome(
        new StockIsFew(this.borderOfActual).rejectWhenEmpty
      ),
      new NoDisplayAboutStock(),
      new SoldOut(this.labelOfSoldOut),
      new DisplayNothing(),
    ])

    return new GetStockStatus(ruleSet)
  }
}
export class CoelPolicy implements Policy {
  static createViaConfig(behaviorConfig: BehaviorConfig): Policy {
    const badgeBehavior = behaviorConfig.itemList.item.badge
    const border = badgeBehavior.few.border

    return new this(
      border.onDisplayFew,
      border.onDisplayAmount,
      border.onActual,
      badgeBehavior.soldOut.label
    )
  }
  constructor(
    private readonly borderOfFew: number,
    private readonly borderOfAmount: number,
    private readonly borderOfActual: number,
    private readonly labelOfSoldOut: string
  ) { }

  create() {
    const ruleSet = new DisplayRuleSet([
      new DisplayStockIsFew(
        new StockIsFew(this.borderOfFew).rejectWhenEmpty
      ),
      new DisplayStockIsSome(
        new StockIsFew(this.borderOfAmount).rejectWhenEmpty
      ),
      new WebStockIsFew(
        new StockIsFew(this.borderOfActual)
      ),
      new CoelNoDisplayDependOnWebStock(this.borderOfActual),
      new CoelFewDependOnWeb(this.borderOfActual),
      new NoDisplayAboutStock(),
      new SoldOut(this.labelOfSoldOut),
      new DisplayNothing(),
    ])

    return new GetStockStatus(ruleSet)
  }
}
