import { ApiCreditcard } from '@/models/api/creditcard'
import { ActionTree } from 'vuex'

import { RootState } from '../../types'
import { AppliedPoint, OrderConfirmState, } from './types'

export type PayloadSetPaymentToCreditcard =
  Pick<ApiCreditcard, 'numberLast4' | 'cardType' | 'lastCreditDate'>
  & {
    token: string
  }

export const actions: ActionTree<OrderConfirmState, RootState> = {
  setPaymentToCreditcard(
    { commit },
    payload: PayloadSetPaymentToCreditcard
  ) {
    commit('setPaymentToCreditcard', payload)
  },

  setPaymentToCashOnDelivery({ commit }) {
    commit('setPaymentToCashOnDelivery')
  },

  setPaymentToNpPostpay({ commit }) {
    commit('setPaymentToNpPostpay')
  },

  setPaymentToAtone({ commit }) {
    commit('setPaymentToAtone')
  },

  setPaymentToPaidy({ commit }) {
    commit('setPaymentToPaidy')
  },

  setPayment({ commit }, payload: { payment: any }) {
    commit('setPayment', payload)
  },

  unsetPayment({ commit }) {
    commit('unsetPayment')
  },

  initializeDelivery({ commit }, payload: { id: string }) {
    commit('initializeDelivery', payload)
  },

  setDeliveryMethod({ commit }, payload: { method: string }) {
    commit('setDeliveryMethod', payload)
  },

  unsetDelivery({ commit }) {
    commit('unsetDelivery')
  },

  setDeliveryDate({ commit }, payload: { deliveryPlanDate: string, deliveryTimeSlot: string }) {
    commit('setDeliveryDate', payload)
  },

  applyPoint({ commit }, payload: AppliedPoint) {
    commit('applyPoint', payload)
  },

  setComplete({ commit }, payload) {
    commit('setComplete', payload)
  },
}
