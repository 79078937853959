
























































































import { FkuQuery } from '@/services/api/item/fku/fku-query'
import ProductclassService from '@/services/api/productclass'
import _ from 'lodash'
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import ErrorDialog from '@/components/organisms/dialog/ErrorDialog.vue'
import { log } from '@/log'
import { DefaultReplacePriceRepository } from '@/models/api/cart/latest-price/replace-price-reposiory'
import { ApiProductclass } from '@/models/api/productclass'
import BehaviorConfig from '@/models/app-config/behavior/behavior'
import RootFactory from '@/models/item/root/factory'

import { normalErrorMessage } from '@/message/error'
import AppId from '@/util/appid'
import { BrandRouteResolver } from '@/util/brandRouteResolver'

import Util from '@ajax/modules/util'
import { Root } from '@ajax/vue/components/organisms/addtocart/view-models'
import { AddToCartEvent } from '@ajax/vue/components/organisms/addtocart/view-models/event'
import { ProcessStatus } from '@ajax/vue/components/organisms/addtocart/view-models/process-status'
import { PaidMembershipInteraction } from '@ajax/vue/components/templates/interaction/paid-membership/paid-membership-interaction'
import { CartService } from '@spa/services/api/cart'
import { CartItemListRepository, DefaultCartItemRepository } from '@spa/store/modules/cart/repository'
import { CartItem } from '@spa/store/modules/cart/types'
import { FetchRootWithSkuPrice } from './gateway/fetch-root-with-sku-price'

@Component({
  components: {
    ErrorDialog,
  },
})
export default class AddToCartButton extends Vue {

  isLoading: boolean = false

  isError: boolean = false

  showModal: boolean = false

  message: string

  userNotification: UserNotification = null
  initializationError: Error | null = null

  itemDetailInfo: {
    cart: boolean,
    request: boolean,
    'soldout.restock': boolean,
    commingsoon: boolean,
    presales: boolean
  }

  fkuList: any[] = []

  itemService: ProductclassService
  cartItemListRepository: CartItemListRepository

  root: Root
  cartItems: CartItem[] = []

  isPaidMember: boolean = false

  addToCartStatus: ProcessStatus = ProcessStatus.asNotProcessing()

  @Prop({ required: true })
  id: string

  get brandName(): string {
    return BrandRouteResolver.resolveBrandFromPathElement(this.$route.params.brand_english_name)
  }

  get behavior(): BehaviorConfig {
    return BehaviorConfig.createFromBrand(this.brandName)
  }

  get paidMembershipInteraction(): PaidMembershipInteraction {
    return new PaidMembershipInteraction(this.brandName)
  }

  toggle() {
    this.showModal = !this.showModal
  }

  async created() {
    this.isLoading = true

    const appId = AppId.getByBrandName(this.brandName)
    this.itemService = new ProductclassService(appId)
    this.cartItemListRepository = DefaultCartItemRepository.createViaApiClient(new CartService(appId))

    const [
      productclassResult,
      isPaidMemberResult,
    ] = await Promise.all([
      this.itemService.listFkuBelongingToRoot(this.id, FkuQuery.initialize({
        with: 'part,stock~detail',
      }).toObject()),
      this.paidMembershipInteraction.isPaidMember(),
    ])

    isPaidMemberResult.fold(
      (error: Error) => {
        log.error({
          message: 'Failed to determine if the user is a paid-member.',
          error,
        })
      },
      (value: boolean) => {
        this.isPaidMember = value
      }
    )

    if (productclassResult.isLeft()) {
      const error = productclassResult.value
      this.userNotification = {
        message: normalErrorMessage,
      }
      this.initializationError = error
      log.error(`Could not fetch FKUs belonging to Root: ${this.id}`, error)

      return
    }

    const productclasses = productclassResult.value
    this.fkuList = _.filter(productclasses, (productclass) => {
      const published = productclass.parts.filter(part => part.status_id === 1)

      return published.length > 0
    })

    /**
     * TODO: fkuListを取得する部分も、外部との相互作用としてVueコンポーネントから切り離したい。
     * しかし他のメソッドでもfkuListを参照しており、ただちに切り離すのは影響範囲が大きくなるため、
     * 一旦はRoot取得の部分のみを切り離している。
     */
    const maybeRoot = await FetchRootWithSkuPrice.create(
      this.brandName,
      RootFactory.createViaConfig(this.behavior)
    ).fetch(this.fkuList)
    if (maybeRoot.isLeft()) {
      const error = maybeRoot.value
      this.userNotification = {
        message: normalErrorMessage,
      }
      this.initializationError = error
      log.error(`Could not fetch Root: ${this.id}`, error)

      return
    }

    this.root = maybeRoot.value
    this.itemDetailInfo = Util.getItemDetailInfo(this.fkuList)

    // 有料会員機能がONの場合はすでに異なる価格の種類（有料会員価格/非有料会員価格）でカートに入っているか検証する必要がある
    // OFFの場合もカートの取得を行ってもよいが、単に不要なのでパフォーマンスの観点でスキップする
    if (this.paidMembershipFeatureEnabled) {
      try {
        /**
         * 現在のカートを最新の有効価格に更新する
         * 将来的には単にAPIにカートをリクエストしたら最新の有効価格に更新して返却されるほうが望ましい？
         */
        const replaceRepository = DefaultReplacePriceRepository.create(appId)
        await replaceRepository.updateCurrentValidPrice()

        this.cartItems = await this.cartItemListRepository.list()
      } catch (error) {
        this.userNotification = {
          message: normalErrorMessage,
        }
        this.initializationError = error as Error
        log.error('Could not fetch cart list', error)

        return
      }
    }

    this.isLoading = false
  }

  /**
   * 1ROOT-1SKUの場合に、カート追加モーダルを経由せずに直接にそのSKUをカートに追加するボタンの有効・無効
   */
  get directAddToCartEnabled(): boolean {
    return !this.addToCartStatus.inProcessing && !this.initializationError
  }

  buttonInfo(type: string): boolean {
    return this.itemDetailInfo[type]
  }

  notAvailablePreSales(): boolean {
    const apiProductclass = this.fkuList.map(fku => new ApiProductclass(fku))
    const products = _.flatten(apiProductclass.map(r => r.parts))

    return  products.some(p => Util.getCartInfo(p).notAvailablePreSales)
  }

  /**
   * 現時点では、カート追加処理状態に関して、一度に一種類のSKUの追加のみが行われる前提を置いている。
   * そのためpayloadに含まれるSKU IDなどの情報は利用していないが、将来的な拡張可能性を想定して受け取っている。
   * @see ProcessStatus
   */
  onStartEvent(_payload: AddToCartEvent): void {
    this.addToCartStatus = ProcessStatus.asProcessing()
  }

  /**
   * 現時点では、カート追加処理状態に関して、一度に一種類のSKUの追加のみが行われる前提を置いている。
   * そのためpayloadに含まれるSKU IDなどの情報は利用していないが、将来的な拡張可能性を想定して受け取っている。
   * @see ProcessStatus
   */
  onStopEvent(_payload: AddToCartEvent): void {
    this.addToCartStatus = ProcessStatus.asNotProcessing()
  }

  initUserNotification(): void {
    this.userNotification = null
  }

  get paidMembershipFeatureEnabled(): boolean {
    return this.behavior.paidMembership.enabled
  }
}

type UserNotification = {
  message: string
} | null
