













import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import AppId from '@/util/appid'
import { BrandRouteResolver } from '@/util/brandRouteResolver'
import { TagsService } from '@spa/services/api/tags'

/**
 * TODO:
 * spa/ と ajax/ が統合されたら、
 * 正しい型定義を参照させる
 */
interface Genre {
  id: string
  name: string
  status_id: number
}

@Component
export default class GenreList extends Vue {
  genres: Genre[] = []

  tagsService = new TagsService()

  @Prop({ required: true })
  brandName: string

  @Prop({ required: true })
  basePath: string

  get appId(): string {
    return AppId.getByBrandName(this.brandName)
  }

  linkUrl(genre: Genre): string {
    const query = `genre=${genre.name}`

    return `${this.basePath}?${query}`
  }

  get brandEnglishName(): string {
    return BrandRouteResolver.resolveBrandFromPathElement(this.brandName)
  }

  async mounted () {
    Promise.resolve().then(() => {
      return this.fetchFirstGenres()
    }).then((response) => {
      if (response.isLeft()) {
        return []
      }

      return this.onlyPublic(response.value)
    }).then((genres) => {
      this.genres = genres
    })
  }

  private async fetchFirstGenres () {
    return this.tagsService.get('mycolor.genre')
  }

  private onlyPublic (genres: Genre[]) {
    return genres.filter(genre => genre.status_id === 1)
  }

}
