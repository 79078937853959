export const initialSearchContactLensState: SearchContactLensState = {
  csvData: null,
}

export interface SearchContactLensState {
  csvData: string[][] | null,
}

export interface SearchContactLensCond {
  hasSPDrawing: boolean,
  matchValues: SearchContactLensMatchValue[],
}

export interface SearchContactLensMatchValue {
  columnIndex: number,
  columnValue: string,
}
