import GetStockStatus from '@/usecase/get-stock-status'
import { RuleInput, SKU } from './rule-applicable'

export interface BadgeListDisplayRule {
  isSatisfiedBy: (fku: RuleInput) => boolean
  and: (rule: BadgeListDisplayRule) => BadgeListDisplayRule
  not: () => BadgeListDisplayRule
  or: (rule: BadgeListDisplayRule) => BadgeListDisplayRule
}

abstract class BaseBadgeListDisplayRule implements BadgeListDisplayRule {
  abstract isSatisfiedBy(fku: RuleInput): boolean

  and(rule: BadgeListDisplayRule): BadgeListDisplayRule {
    return new AndRule(this, rule)
  }

  not(): BadgeListDisplayRule {
    return new NotRule(this)
  }

  or(rule: BadgeListDisplayRule): BadgeListDisplayRule {
    return new OrRule(this, rule)
  }
}

export class AndRule extends BaseBadgeListDisplayRule {
  constructor(
    private readonly ruleA: BadgeListDisplayRule,
    private readonly ruleB: BadgeListDisplayRule
  ) {
    super()
  }
  isSatisfiedBy(fku: RuleInput): boolean {
    return [this.ruleA, this.ruleB].every(rule => rule.isSatisfiedBy(fku))
  }
}

export class NotRule extends BaseBadgeListDisplayRule {
  constructor(private readonly rule: BadgeListDisplayRule) {
    super()
  }
  isSatisfiedBy(fku: RuleInput): boolean {
    return !this.rule.isSatisfiedBy(fku)
  }
}

export class OrRule extends BaseBadgeListDisplayRule {
  constructor(
    private readonly ruleA: BadgeListDisplayRule,
    private readonly ruleB: BadgeListDisplayRule
  ) {
    super()
  }
  isSatisfiedBy(fku: RuleInput): boolean {
    return [this.ruleA, this.ruleB].some(rule => rule.isSatisfiedBy(fku))
  }
}

export class IsOnPreSaleRule extends BaseBadgeListDisplayRule {
  isSatisfiedBy(fku: RuleInput): boolean {
    return fku.skus.some((sku: SKU) => sku.isOnPreSale)
  }
}

export class PreSaleSoldOutRule extends BaseBadgeListDisplayRule {
  isSatisfiedBy(fku: RuleInput): boolean {
    return fku.skus.some((sku: SKU) => sku.preSaleSoldOut)
  }
}

export class IsOnSaleRule extends BaseBadgeListDisplayRule {
  isSatisfiedBy(fku: RuleInput): boolean {
    return fku.skus.some((sku: SKU) => sku.isNormal && sku.isOnSale)
  }
}

export class IsNewRule extends BaseBadgeListDisplayRule {
  isSatisfiedBy(fku: RuleInput): boolean {
    return fku.skus.some((sku: SKU) => sku.isNormal && sku.isNew)
  }
}

export class IsRestockRule extends BaseBadgeListDisplayRule {
  isSatisfiedBy(fku: RuleInput): boolean {
    return fku.isRestock
  }
}

export class AcceptNewItemRequestRule extends BaseBadgeListDisplayRule {
  isSatisfiedBy(fku: RuleInput): boolean {
    return fku.skus.some((sku: SKU) => sku.acceptNewItemRequest)
  }
}

export class AcceptRestockRequestRule extends BaseBadgeListDisplayRule {
  isSatisfiedBy(fku: RuleInput): boolean {
    return fku.skus.some((sku: SKU) => sku.acceptRestockRequest)
  }
}

export class IsFewRule extends BaseBadgeListDisplayRule {
  constructor(private readonly getStockStatus: GetStockStatus) {
    super()
  }
  isSatisfiedBy(fku: RuleInput): boolean {
    return this.getStockStatus.isFew(fku.stock)
  }
}

export class PurchasableRule extends BaseBadgeListDisplayRule {
  isSatisfiedBy(fku: RuleInput): boolean {
    return fku.skus.some((sku: SKU) => sku.isPurchasable)
  }
}

export class ComingSoonRule extends BaseBadgeListDisplayRule {
  isSatisfiedBy(fku: RuleInput): boolean {
    return fku.skus.some((sku: SKU) => sku.isComingSoon)
  }
}

export class ComingSoonSoldOutRule extends BaseBadgeListDisplayRule {
  isSatisfiedBy(fku: RuleInput): boolean {
    return fku.skus.some((sku: SKU) => sku.comingSoonSoldOut)
  }
}
