import _ from 'lodash'
import { MutationTree } from 'vuex'

import { initialRootState, RootState } from './types'

import { log } from '../log'

export const mutations: MutationTree<RootState> = {
  init(state) {
    Object.assign(state, _.cloneDeep(initialRootState))

    log.debug({ commit: `init`, state })
  },
}
