import { Either, Left, Right } from 'fp-ts/lib/Either'

import { log } from '@/log'
import { ApiClient } from '@/services/api/client'
import { BrandRouteResolver } from '@/util/brandRouteResolver'

/**
 * 後々、まとめ買い専用のAPIとして作り直すかも
 * @see config/routes.php
 */
export default class RootItemService {
  constructor(private readonly brand: string) { }
  async get(rootId: string): Promise<Either<Error, RootItem>> {
    try {
      const result = await ApiClient.get(`/api/${this.brandName}/item/root/${rootId}`)
      const data: RawRootItem = result.body.data

      return new Right({
        id: data.id,
        shortId: data.short_id,
        name: data.name,
        number: data.number,
        description: {
          itemDetail: data.description.item_detail,
          material: data.description.material,
        },
      })
    } catch (error) {
      log.error({ error })

      return new Left(error)
    }
  }

  private get brandName(): string {
    return BrandRouteResolver.resolveBrandFromPathElement(this.brand)
  }
}

/**
 * APIレスポンスをそのまま使わず、後でラッパーオブジェクトを使う形にしやすくするためにIFを経由.
 *
 * 振る舞いの実装が必要になったら、IFではなくラッパーオブジェクトを用意する.
 */
export interface RootItem {
  id: string
  shortId: string
  name: string
  number: string
  description: {
    itemDetail: string
    material: string
  }
}
interface RawRootItem {
  id: string
  short_id: string
  name: string
  number: string
  description: {
    item_detail: string
    material: string
  }
}
