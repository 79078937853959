import _ from 'lodash'
import { MutationTree } from 'vuex'
import { User, UserState, } from './types'

import { log } from '@/log'

const namespace = 'user'

export const mutations: MutationTree<UserState> = {
  get(state, payload: User) {
    _.set(state, 'error.load', null)
    state.user = payload

    log.debug({ commit: `${namespace}/get`, state })
  },

  errorOnGet(state, payload: { error: Error }) {
    const { error } = payload
    state.error.get = error

    log.debug({ commit: `${namespace}/errorOnLoad`, state })
  },

  update(state, payload: any) {
    state.error.update = null

    state.user = {
      ...state.user,
      ...payload,
    }

    log.debug({ commit: `${namespace}/update`, state })
  },

  errorOnUpdate(state, payload: { error: Error }) {
    const { error } = payload
    state.error.update = error

    log.debug({ commit: `${namespace}/errorOnUpdate`, state })
  },

  /**
   * @deprecated
   *
   * ユーザーリソースを部分的に更新する仕組みができるまでの暫定対応。
   * @see https://github.com/my-color/front/pull/4568
   */
  errorOnUpdatePaymentAuthToken(state, payload: { error: Error }) {
    const { error } = payload
    state.error.update = error

    log.debug({ commit: `${namespace}/errorOnUpdatePaymentAuthToken`, state })
  },

  unload(state) {
    state.user = null

    log.debug({ commit: `${namespace}/unload`, state })
  },

  errorOnLogin(state, payload: { error: Error }) {
    const { error } = payload
    state.error.login = error

    log.debug({ commit: `${namespace}/errorOnLogin`, state })
  },

  errorOnLogout(state, payload: { error: Error }) {
    const { error } = payload
    state.error.logout = error

    log.debug({ commit: `${namespace}/errorOnLogout`, state })
  },

  errorOnRegister(state, payload: { error: Error }) {
    const { error } = payload
    state.error.register = error

    log.debug({ commit: `${namespace}/errorOnRegister`, state })
  },

  errorOnMailMagazine(state, { error }) {
    state.error.mailMagazine = error

    log.debug({ commit: `${namespace}/errorOnMailMagazine`, state })
  },
}
