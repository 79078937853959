






























import BehaviorConfig from '@/models/app-config/behavior/behavior'
import ContactLensFKURepository from '@/models/item/fku/repository'
import ContactLensRootRepository from '@/models/item/root/repository'
import ContactLensSKU from '@/models/item/sku/contact-lens'
import AppId from '@/util/appid'
import { BrandRouteResolver } from '@/util/brandRouteResolver'
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import ContactLensItemDetailStore from '../store'
import ContactLensItemDetailStoreBuilder from '../store-builder'

@Component
export default class ContactLensPowerLeftDropdown extends Vue {
  @Prop({ required: true })
  id: string

  store: ContactLensItemDetailStore | null = null

  async created() {
    try {
      const brandName = BrandRouteResolver.resolveBrandFromPathElement(this.$route.params.brand_english_name)
      const appId = AppId.getByBrandName(brandName)
      const storeBuilder = ContactLensItemDetailStoreBuilder.getInstance(
        new ContactLensRootRepository(appId),
        new ContactLensFKURepository(appId)
      )
      this.store = await storeBuilder.build(
        this.id,
        BehaviorConfig.createFromBrand(brandName)
      )
    } catch (error) {
      console.error(error)
    }
  }

  get selectedId(): string {
    return this.store && this.store.selectedLeftSku ? this.store.selectedLeftSku.id.short : 'not_selected'
  }

  get skus(): ContactLensSKU[] {
    return this.store ? this.store.skus : []
  }

  generateOptionText(sku: ContactLensSKU): string {
    /**
     * 新作リクエスト商品については、度数プルダウンのテキストにSOLDOUTをつけない
     * @see https://my-color.esa.io/posts/588
     */
    return sku.cartInfo.newitem && sku.isSoldOut ? sku.power : `${sku.power} ${sku.stockStatusText}`
  }

  onChange($event): void {
    if (this.store) {
      this.store.selectSku('left', this.skus.find(sku => sku.id.short === $event.target.value) || null)
    }
  }
}
