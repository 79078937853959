













import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import { ApiContent } from '@/models/api/content'

@Component
export default class SwitchByUa extends Vue {
  @Prop({ required: true })
  content: ApiContent
}
