import { Either, Left, Right } from 'fp-ts/lib/Either'

import { log } from '@/log'
import { ApiAppSetting } from '@/models/api/app_setting'
import { ApiService } from '@/services/api/service'
import { ApiClient } from './client'

const namespace: string = 'appSetting'

export class AppSettingService extends ApiService {
  async get(): Promise<Either<Error, ApiAppSetting>> {
    try {
      const response = await ApiClient
        .get(`/api/common-proxy/${this.appId}/appsettings`)
        .query({
          app_resource_kind: `${this.appId}.appsettings`,
          with: 'content',
          without: 'content_summary',
        })

      log.debug({ service: `${namespace}/get`, response })

      // 返ってくるdataは単一のオブジェクトを含む配列
      const result = new ApiAppSetting(response.body.data[0])

      return new Right(result)
    } catch (error) {
      log.error({ error })

      return new Left(error)
    }
  }
}
