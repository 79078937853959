

























import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import LockBackgroundMixin from '@/mixin/LockBackground'

@Component({
  mixins: [
    LockBackgroundMixin,
  ],
})
export default class ModalFull extends Vue {
  @Prop()
  direction: string

  @Prop()
  level: number

  get zlevel() {
    // tslint:disable-next-line:no-magic-numbers
    if (this.level === 2) {
      // tslint:disable-next-line:no-magic-numbers
      return 2
    }

    return 1
  }

  get height() {
    return $(window).height()
  }

}
