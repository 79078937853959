import { ApiProductclass } from '@/models/api/productclass'
import { UtilService } from '@spa/services/util'
import _ from 'lodash'

/**
 * TODO: FKU取扱状態がモデル化されたらそれに置き換えてこのモジュールを削除する。
 */

export interface FkuStateTemporal {
  acceptRestockRequest: boolean
}

/**
 * もともと @spa/services/util に実装されていたgetBadgeInfoがベースだが、
 * あくまで「FKU取扱状態」という位置づけ。表示仕様では無い。
 *
 * 2021/08/19の対応 (https://github.com/my-color/front/issues/5588) で、
 * 商品リストに利用しているSKUに関する情報の洗い出しを行った。
 * その際に、下記を削除した。
 * - このメソッドが返却しているものの利用されていない値
 * - 上記の値を算出するための処理
 *
 * @deprecated
 */
export function createFkuStateTemporal(fku: ApiProductclass): FkuStateTemporal {
  const STOCK_MIN: number = 1

  const cartsInfo: any[] = _.map(fku.materializedSkuList, sku =>
    UtilService.getCartInfo(sku)
  )

  const minimumStockWithoutEmpty = cartsInfo.reduce(
    (prev, cartInfo) => {
      if (cartInfo.stock === 0) {
        return prev
      }

      return cartInfo.stock < prev ? cartInfo.stock : prev
    },
    Infinity
  )

  const fkuStockMore = minimumStockWithoutEmpty >= STOCK_MIN
  const fkuRequestNewItem = _.some(cartsInfo, cartInfo => cartInfo['newitem'])
  const fkuRequestRestock = !fkuRequestNewItem && _.some(cartsInfo, cartInfo => cartInfo['restock'])
  const fkuOnSale = _.some(cartsInfo, cartInfo => cartInfo['on_sale'])

  const onSale: boolean = fkuOnSale && fkuStockMore

  const resultRequestNewItem =
    fkuRequestNewItem && !fku.isNew && !onSale
  const resultRequestRestock =
    fkuRequestRestock && !resultRequestNewItem

  return {
    acceptRestockRequest: resultRequestRestock,
  }
}
