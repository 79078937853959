import { DirectiveBinding, DirectiveOptions } from 'vue/types/options'

import store from '@/store'

const loadBundleDiscount: DirectiveOptions = {
  async inserted(_el: HTMLElement, binding: DirectiveBinding) {
    const { appId } = binding.value

    await store.dispatch('bundleDiscount/load', { appId })
  },
}

export default loadBundleDiscount
