



























import { FkuQuery } from '@/services/api/item/fku/fku-query'
import _ from 'lodash'
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import Item from '@/components/molecules/ItemWithLink.vue'
import ItemList from '@/components/organisms//item-list/Container.vue'
import * as RecentlyViewedRepository from '@/models/repository/recently-viewed/recently-viewed-repository'
import ChunkedRequestExecuter from '@/services/api/async/chunked-request-executer'
import ItemService from '@/services/api/productclass'

import { log } from '@/log'

@Component({
  components: {
    Item,
    ItemList,
  },
})
export default class RecentlyViewed extends Vue {
  @Prop({
    default: () => (items => items),
  })
  decorator: (items: any[]) => any[]

  @Prop({ default: () => RecentlyViewedRepository.instance })
  repository: RecentlyViewedRepository.RecentlyViewedRepository

  itemlist: any[] = []

  ids: string[]

  idsSliced: string[]

  message: string = ''

  readonly DISPLAY_COUNT = 5

  get query() {
    return {
      with: 'belonging_to,part,stock~detail,indexsummary',
    }
  }

  itemService = new ItemService()

  get checkedIds(): string[] {
    return this.repository.list()
  }

  async created() {
    if (_.isEmpty(this.checkedIds)) {
      this.message = '最近チェックしたアイテムはありません。'

      return
    }
    await this.getItemList()
  }

  async getItemList() {
    try {
      const chunkedRequest = ChunkedRequestExecuter.withLongId()
      // const result =  await this.itemService.userItemList(this.query)
      const result =  await chunkedRequest.execute(
        this.checkedIds,
        async (ids: string[]) => await this.itemService.listFku(FkuQuery.initialize({
          ids: ids.join(','),
          ...this.query,
        }).toObject())
      )
      const [
        successes,
        errors,
      ] = _.partition(result, r => r.isRight())

      if (errors.length > 0) {
        log.error({ errors })
      }

      if (_.isEmpty(successes)) {
        this.message = 'データを取得できませんでした'

        return
      }

      const itemList = _.flatten(successes.map(s => s.value))

      this.itemlist = this.decorator(
        this.sortProductsByRecentlyViewed(itemList, this.checkedIds)
      )

    } catch (err) {
      log.error(err)
      this.message = 'データを取得できませんでした'
    }
  }

  /**
   * idsで取得したproductclassを、localstorageのchecked_products順にソートする
   * @param {Array} productClass
   * @param {Array} ids
   * @return {Array} result
   */
  sortProductsByRecentlyViewed(itemlist, ids): any[] {

    const result: any[] = []
    ids.forEach((id) => {
      itemlist.forEach((item) => {
        if (item.id === id) {
          result.push(item)
        }
      })
    })

    return result
  }

}
