

























import _ from 'lodash'
import { Mixins } from 'vue-mixin-decorator'
import { Component } from 'vue-property-decorator'

import { HOST_ID } from '@/util/appid'
import Util from '@ajax/modules/util'
import ApiMixin from '@ajax/vue/mixin/Api'

@Component
export default class SearchColor extends Mixins<ApiMixin>(ApiMixin) {

  colors: any[] = []

  selectedColors: any[] = []

  isSelectedColorsMaxNumber: boolean = false

  maxColorNumber: number = 10

  async created () {
    const path = this.colorPathname
    const response = await this.$get(`/api/common-proxy/${HOST_ID}/tags`, {
      pathname: path,
      scope: 'tree',
    })
    if (response.body.success) {
      this.colors = response.body.data.children
      this.reflectUrlParams()
    }
  }

  get colorPathname(): string {
    return 'mycolor.product.color_family'
  }

  isColorSelected (color) {
    return _.find(this.selectedColors, { id: color.id }) !== undefined
  }

  onClickColor (color) {
    const index = _.map(this.selectedColors, 'id').indexOf(color.id)
    if (index !== - 1) {
      this.selectedColors.splice(index, 1)
    } else {
      this.selectedColors.push(color)
    }
    this.limitSelectedColors()
  }

  limitSelectedColors () {
    this.isSelectedColorsMaxNumber = this.selectedColors.length >= this.maxColorNumber
    if (this.isSelectedColorsMaxNumber) {
      this.selectedColors.splice(this.maxColorNumber)
    }
  }

  onClickResetColors () {
    this.selectedColors.splice(0, this.selectedColors.length)
    this.limitSelectedColors()
  }

  reflectUrlParams () {
    const urlParams = Util.convertItemlistUrlParamsToAssociativeArray()
    if (urlParams.color_family) {
      const colors = urlParams.color_family.split(',') as string[]
      this.addSelectedColorsByNames(colors)
    }
  }

  addSelectedColorsByNames (colorNames) {
    this.selectedColors = _.compact(
      colorNames.map(colorName => _.find(this.colors, { name: colorName }))
    )
    this.limitSelectedColors()
  }

}
