import { FkuQuery } from '@/services/api/item/fku/fku-query'
import ProductclassService from '@/services/api/productclass'
import EventEmitter from 'eventemitter3'
import { Either, Left, Right } from 'fp-ts/lib/Either'

import { log } from '@/log'
import { ApiProductclass } from '@/models/api/productclass'
import ChunkedRequestExecuter from '@/services/api/async/chunked-request-executer'
import AppId from '@/util/appid'
import { Command } from '../command'

type LoadFkuResult = Either<Error, ApiProductclass[]>

interface IntermidiateResult {
  success: IntermidiateSuccess[],
  error: IntermidiateFail[]
}
type IntermidiateSuccess = Right<Error, ApiProductclass[]>
type IntermidiateFail = Left<Error, ApiProductclass[]>

export default class LoadFkuList implements Command<LoadFkuResult> {
  static withBrand(brand: string) {
    return {
      withFkuIds(ids: string[]): LoadFkuList {
        return new LoadFkuList(
          new ProductclassService(
            AppId.getByBrandName(brand)
          ),
          ids
        )
      },
    }
  }

  private eventEmitter: EventEmitter = new EventEmitter()

  private constructor(
    private readonly apiClient: ProductclassService,
    private readonly fkuIds: string[]
  ) {}

  /**
   * 多数の商品が登録されている時、全てが揃うのを待つのでなく、
   * 段階的にロードするためのイベントリスナ.
   *
   * 今後、オートロード対応によって置き換えられるかも？
   *
   * @see https://github.com/my-color/front/issues/3938
   */
  onLoad(cb: (items: ApiProductclass[]) => void): this {
    this.eventEmitter.on('load', cb)

    return this
  }

  async run(): Promise<LoadFkuResult> {
    const chunkRequest = ChunkedRequestExecuter.withLongId()
    const chunkedResult: LoadFkuResult[] = await chunkRequest.execute(
      this.fkuIds,
      async (ids: string[]) => this.apiClient.listFku(FkuQuery.initialize({
        ids: ids.join(','),
        with: 'part,stock~detail,belonging_to,indexsummary',
      }).toObject()).then(result =>
        result.map((rawItems) => {
          const items = rawItems.map(item => new ApiProductclass(item))

          this.eventEmitter.emit('load', items)

          return items
        })
      )
    )

    const interResult = chunkedResult.reduce<IntermidiateResult>(
      (prev: IntermidiateResult, result: LoadFkuResult): IntermidiateResult => result.isRight() ?
      {
        ...prev,
        success: prev.success.concat(result),
      } :
      {
        ...prev,
        error: prev.error.concat(result),
      },
      {
        error: [],
        success: [],
      }
    )
    if (interResult.error.length > 0) {
      log.error(interResult.error)
    }
    if (interResult.success.length === 0) {
      return new Left(new FailedToLoadFkuListError(interResult))
    }

    const resultItemList = interResult.success.reduce<ApiProductclass[]>(
      (prev: ApiProductclass[], result: IntermidiateSuccess) => prev.concat(result.value),
      []
    )

    return new Right(resultItemList)
  }
}

class FailedToLoadFkuListError extends Error {
  constructor(readonly interResult: IntermidiateResult) {
    super()
  }
}
