import { Price } from '@/models/api/price/price'
import { ApiProduct } from '@/models/api/product'
import ApiStock from '@/models/api/product/stock'
import { ApiTag } from '@/models/api/tags'

/**
 * /productclass2 から取得可能なSKUに関する情報
 * with=partが指定された場合はparts以下から、with=indexsummaryが指定された場合はsku以下から取得可能
 *
 * @see https://my-color.esa.io/posts/696
 */
export interface Sku {
  id: string
  shortId: string
  salesStatus: number
  isAvailable: boolean
  isDeleted: boolean
  isNew: boolean
  regularSaleStartAt: string
  regularSaleEndAt: string
  overRegularSaleStart: boolean
  isOnRegularSale: boolean
  isOnDiscountSale: boolean
  price: Price
  acceptNewItemRequest: boolean
  acceptRestockRequest: boolean
  /**
   * @deprecated
   * @see ApiProduct.stock
   */
  stock: number
  stockDetail: ApiStock
  isSoldOut: boolean
  hasPreSaleTag: boolean
  tags: ApiTag[]
}

export class SkuFromEngine extends ApiProduct implements Sku {}

export class SkuFromIndexSummary implements Sku {
  /**
   * I/FとしてはApiProductは露出させず、あくまでSkuの実装例として振る舞うが、
   * 実装の重複を避けるために内部的にはApiProductを利用する。
   *
   * 根本的には、場合によって取得できないフィールドと、そのフィールドに依存するメソッドを明確に区別し、
   * 型レベルで区別するような方針をとらないと安全とは言い切れない。
   *
   * 例えば、本クラスで利用しているApiProductのメソッドは、現時点 (2021/08/21) では
   * indexsumamryから取得できるskuフィールドから取得可能な値のみに依存しているが、
   * 今後、Productの内部実装が変更された際にskuフィールドから算出できなくなる可能性もある。
   */
  private product: ApiProduct

  constructor(raw: any) {
    this.product = new ApiProduct(raw)
  }

  get id(): string {
    return this.product.id
  }
  get shortId(): string {
    return this.product.shortId
  }
  get salesStatus(): number {
    return this.product.salesStatus
  }
  get isAvailable(): boolean {
    return this.product.isAvailable
  }
  get isDeleted(): boolean {
    return this.product.isDeleted
  }
  get isNew(): boolean {
    return this.product.isNew
  }
  get regularSaleStartAt(): string {
    return this.product.regularSaleStartAt
  }
  get regularSaleEndAt(): string {
    return this.product.regularSaleEndAt
  }
  get overRegularSaleStart(): boolean {
    return this.product.overRegularSaleStart
  }
  get isOnRegularSale(): boolean {
    return this.product.isOnRegularSale
  }
  get isOnDiscountSale(): boolean {
    return this.product.isOnDiscountSale
  }
  get price(): Price {
    return this.product.price
  }
  get acceptNewItemRequest(): boolean {
    return this.product.acceptNewItemRequest
  }
  get acceptRestockRequest(): boolean {
    return this.product.acceptRestockRequest
  }
  /**
   * @inheritdoc
   */
  get stock(): number {
    return this.product.stock
  }
  get stockDetail(): ApiStock {
    return this.product.stockDetail
  }
  get isSoldOut(): boolean {
    return this.product.isSoldOut
  }
  get hasPreSaleTag(): boolean {
    return this.product.hasPreSaleTag
  }
  get tags(): ApiTag[] {
    return this.product.tags
  }
}
