import { Either, Left, Right } from 'fp-ts/lib/Either'
import _ from 'lodash'

import { log } from '@/log'
import { ApiService } from '@/services/api/service'
import { ApiClient } from './client'

const namespace: string = 'user'

export interface RegisterProvisionalResult {
  access_token: string
  expires_in: number
  id_token: string
  refresh_token: string
  token_type: string
}

/**
 * TODO: SPA側にも似た内容のUserServiceが存在しており、両者を統合し/src/services配下のAPIゲートウェイとしたい。
 * @see https://github.com/my-color/front/pull/5961#discussion_r763640704
 */
export class UserService extends ApiService {
  async registerProvisional(): Promise<Either<Error, RegisterProvisionalResult>> {
    try {
      const response = await ApiClient.post(`/api/user-proxy/${this.appId}/register_provisional`, { ostype: 'web' })
      const user = response.body.data

      log.debug({ service: `${namespace}/registerProvisional`, response })

      return new Right(user)
    } catch (error) {
      log.error({ error })

      return new Left(error)
    }
  }

  async rehash(): Promise<Either<Error, null>> {
    try {
      const response = await ApiClient.post(`/api/user/current_user/rehash`)

      log.debug({ service: `${namespace}/rehash`, response })

      return new Right(null)
    } catch (error) {
      log.error({ error })

      return new Left(error)
    }
  }
}
