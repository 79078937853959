
























































import { MaintenanceImageUrl, S3MaintenanceImageUrl } from '@/models/item/maintenance-image-url'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class ItemUnderMaintenance extends Vue {
  @Prop({ required: true })
  brand: string

  private maintenanceImageUrl: MaintenanceImageUrl | null = null

  async created() {
    const builder = await S3MaintenanceImageUrl.builder()
    this.maintenanceImageUrl = builder.build(this.brand)
  }

  get ready(): boolean {
    return this.maintenanceImageUrl !== null
  }

  get imageUrl(): string | null {
    if (!this.maintenanceImageUrl) {
      return null
    }

    return this.maintenanceImageUrl.value
  }

  get showImage(): boolean {
    return this.imageUrl !== null
  }
}
