
export default class WholeColorFamilyList {
  static parse(colorFamilies: string): WholeColorFamilyList {
    return this.fromStringList(colorFamilies.split(','))
  }
  static fromStringList(colorFamilies: string[]): WholeColorFamilyList {
    return new WholeColorFamilyList(
      colorFamilies.map(c => ({ value: c }))
    )
  }
  constructor(readonly list: ColorFamily[]) { }
}

export interface ColorFamily {
  value: string
}
