









import Vue from 'vue'

import { Color } from '@/models/user-review'
import { Component, Emit, Prop } from 'vue-property-decorator'

import DropdownSelect from '@spa/components/atoms/DropdownSelect.vue'
import { SelectItem } from '@spa/components/atoms/model/SelectItem'

@Component({
  components: {
    DropdownSelect,
  },
})
export default class ColorSelector extends Vue {
  @Prop({ required: true })
  loading: boolean

  @Prop({ required: true })
  selected: Color

  @Prop({ required: true })
  items: Color[]

  get disabled(): boolean {
    return this.loading
  }

  get selectorItems(): SelectItem[] {
    if (this.loading) {
      return [{
        text: '読み込み中…',
        value: '',
        selected: true,
        disabled: false,
      }]
    }

    return this.items.map((item: Color) => {
      return {
        text: item.label,
        value: item.id,
        selected: item.id === this.selected.id,
        disabled: false,
      }
    })
  }

  @Emit('updateColor')
  onInput(_selected: Color) {
    // do nothing
  }
}
