















import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component
export default class BrandPanel extends Vue {
  @Prop({ default: '#' })
  url: string

  @Prop()
  image: string

  @Prop()
  target: string

  get rootStyle() {
    return {
      'c-panel-brand': true,
      'd-flex': true,
      'align-items-center': true,
      'text-center': true,
      'u-square': true,
    }
  }
}
