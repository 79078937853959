
import CatalogDemandService from '@/services/api/catalog/catalog-demand'
import {
  AsDisable,
  AsEnable,
  AsRetry,
  CatalogDemandState,
} from './catalog-demand-state'

export default class CatalogDemandStateSpec {
  static createFromBrand(brand: string): CatalogDemandStateSpec {
    return new CatalogDemandStateSpec(
      brand,
      new CatalogDemandService(brand)
    )
  }
  constructor(
    private readonly brand: string,
    private readonly catalogDemandService: CatalogDemandService
  ) { }

  async select(catalogId: string): Promise<CatalogDemandState> {
    const resultGetCatalog = await this.catalogDemandService.get(catalogId)

    const result = resultGetCatalog.map((catalog) => {
      return catalog.canDemand ? AsEnable.create(this.brand, catalog) : new AsDisable()
    })

    return result.getOrElse(new AsRetry())
  }
}
