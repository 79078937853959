import { Sku as SkuInProductclass } from '@/models/api/productclass/sku'
import MyColorConfig from '@ajax/modules/config'
import _ from 'lodash'

/**
 * class UtilService
 */
export class UtilService {
  /**
   * 商品価格（税抜）を取得する　※小数点以下は切り上げ
   * @param {number} price
   * @return {number}
   */
  public static getPriceBeforeTax(price: number): number {
    return Math.ceil(price / (1 + MyColorConfig.TAX_RATE))
  }

  public static isSpa() {
    return /^\/s(\/.*)?$/.test(location.pathname)
  }

  /**
   * カートへ入れる画面の表示仕様に則った連想配列を返却します。
   *
   * 2021/08/19の対応 (https://github.com/my-color/front/issues/5588) で、
   * 商品リストに利用しているSKUに関する情報の洗い出しを行った。
   * その際に、下記を削除した。
   * - このメソッドが返却しているものの利用されていない値
   * - 上記の値を算出するための処理
   *
   * @deprecated
   *
   * @param product
   */
  public static getCartInfo(sku: SkuInProductclass) {
    // 新作リクエスト
    const isNewitem: boolean = (
      sku.isAvailable &&
      !sku.overRegularSaleStart &&
      sku.acceptNewItemRequest
    ) || (
      sku.isDeleted &&
      !sku.overRegularSaleStart &&
      sku.acceptNewItemRequest
    )

    // 再入荷リクエスト
    const isRestock: boolean = (
      sku.isAvailable &&
      sku.isOnRegularSale &&
      sku.isSoldOut &&
      sku.acceptRestockRequest
    ) || (
      sku.isDeleted &&
      sku.isOnRegularSale &&
      sku.acceptRestockRequest
    )

    const cartInfo: any = {
      newitem: isNewitem,
      restock: isRestock,
      stock: sku.stock,
      on_sale: sku.isOnDiscountSale,
    }

    return cartInfo
  }
}
