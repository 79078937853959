import { Either, Left, Right } from 'fp-ts/lib/Either'
import { Response } from 'superagent'

import { log } from '@/log'
import { ApiService } from '@/services/api/service'
import { ApiClient } from './client'

const namespace: string = 'product'

export class ProductService  extends ApiService {

  async userItem(id: string, query: any = {}): Promise<Either<Error, Response>> {
    try {
      const response = await ApiClient
        .get(`/api/user-proxy/${this.appId}/product2/${id}`)
        .query(query)

      log.debug({ service: `${namespace}/userItem`, response })

      return new Right(response)
    } catch (error) {
      log.error({ error })

      return new Left(error)
    }
  }

  async commonItem(id: string, query: any = {}): Promise<Either<Error, Response>> {
    try {
      const response = await ApiClient
        .get(`/api/common-proxy/${this.appId}/product2/${id}`)
        .query(query)

      log.debug({ service: `${namespace}/commonItem`, response })

      return new Right(response)
    } catch (error) {
      log.error({ error })

      return new Left(error)
    }
  }
}
