




















import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component
export default class ImageWithBadgeBand extends Vue {
  @Prop({ required: true })
  imageSrc: string

  @Prop({ default: 'c-badge-band' })
  rootClass: string

  @Prop({ default: '' })
  imageClass: string

  @Prop({ default: false })
  lazy: boolean

  @Prop({ default: null })
  badgeBandSrc: string | null

  get withBand(): boolean {
    return !!this.badgeBandSrc
  }
}
