








import _ from 'lodash'
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component
export default class BundleDiscountLabel extends Vue {
  @Prop({ required: true })
  labelColor: string

  get labelStyle(): object {
    return {
      'background-color': this.labelColor,
      // 文字色は可変にされる可能性がある。
      // https://github.com/my-color/applogic/pull/50#issuecomment-606602051
      color: 'white',
    }
  }
}
