
enum RootState {
  CanPurchase,
  CanRequestNewItem,
  CanRequestRestock,
  WaitingArrival,
  SoldOut,
}

export default RootState
