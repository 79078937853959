import { InstanceResolver } from '@/util/instanceResolver'

interface SingleInstanceContext {
  readonly isMulti: false
  readonly isSingle: true
}
interface  MultiInstanceContext {
  readonly isMulti: true
  readonly isSingle: false
}
type InstanceContext = SingleInstanceContext | MultiInstanceContext

export interface WithInstanceContext {
  readonly instance: InstanceContext
}

export const multiInstanceContext: MultiInstanceContext = {
  isMulti: true,
  isSingle: false,
}
export const singleInstanceContext: SingleInstanceContext = {
  isMulti: false,
  isSingle: true,
}

export const getInstanceContext = (): InstanceContext =>
  (InstanceResolver.isSingle() ? singleInstanceContext : multiInstanceContext)
