import { getInstanceContext } from '@/page/context/instance'
import { createBrandPageContext } from '@/page/context/page'
import FilterHeader from '@ajax/vue/components/organisms/condition/ConditionHeader.vue'
import EcHeader from '@spa/components/organisms/EcHeader.vue'
import Router from 'vue-router'

const instanceContext = getInstanceContext()

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: EcHeader,
      props: {
        isMediaPage: true,
      },
    },
    {
      path: '/article/:id',
      component: EcHeader,
      props: {
        isMediaPage: true,
      },
    },
    {
      /**
       * SINGLE環境における/itemlist/filterはFilterHeaderにする必要はない…？
       * 下記確認する必要があるかもしれないが、ブランド側からの要求はないので今のままでもいいのかもしれない……
       *
       * 以下のようなパターンが想像される
       * - MCしかないころに作られて、SINGLEのケースを想定していない（SINGLE実装時の実装漏れ）
       * - ヘッダーを変更しているのはMC独自の要求なので、実質的に今の挙動で問題がない
       */
      path: '/:brand_english_name/itemlist/filter',
      component: FilterHeader,
    },
    {
      path: '/:brand_english_name',
      component: EcHeader,
      props: route => ({
        context: {
          instance: instanceContext,
          page: createBrandPageContext(route.params.brand_english_name),
        },
      }),
    },
    {
      path: '/:brand_english_name/*',
      component: EcHeader,
      props: route => ({
        context: {
          instance: instanceContext,
          page: createBrandPageContext(route.params.brand_english_name),
        },
      }),
    },
    {
      path: '*',
      component: EcHeader,
    },
  ],
})
