import { AtonePayment } from '@spa/store/modules/orderConfirm/types'

import { PaymentParamBuilder } from './paymentParamBuilder'

export default class AtonePaymentParamBuilder implements PaymentParamBuilder {
  public constructor(private readonly payment: AtonePayment) {}

  build(): string {
    const {
      payment_type,
      paymentid,
      auth_token,
      point,
    } = this.payment  // TODO: Use generics

    const param = {
      payment_type,
      paymentid,
      auth_token,
      point,
    }

    return JSON.stringify(param)
  }
}
