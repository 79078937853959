














import { Mixins } from 'vue-mixin-decorator'
import { Component } from 'vue-property-decorator'

import BehaviorConfig from '@/models/app-config/behavior/behavior'
import { SideBarItem } from '@/models/app-config/behavior/config/side-bar'
import AppId from '@/util/appid'
import { BrandRouteResolver } from '@/util/brandRouteResolver'
import { InstanceResolver } from '@/util/instanceResolver'
import ApiMixin from '@ajax/vue/mixin/Api'

/**
 * TODO:
 * spa/ と ajax/ が統合されたら、
 * 正しい型定義を参照させる
 */
interface Category {
  id: number
  name: string
  status_id: number
  title: string
}

interface CategoryItem extends Category {
  icon?: string
}

@Component
export default class CategoryMenu extends Mixins<ApiMixin>(ApiMixin) {
  categories: CategoryItem[] = []

  mounted() {
    Promise.resolve().then(() => {
      return this.fetchFirstCategories()
    }).then((response) => {
      return this.onlyPublic(response.body.data)
    }).then((categories) => {
      this.categories = categories
    })
  }

  get isBrandPage(): boolean {
    return !!this.brandEnglishName
  }

  get linkToItemlist(): string {
    if (InstanceResolver.isSingle()) {
      return '/itemlist'
    }

    return `/${this.brandEnglishName}/itemlist`
  }

  get brandEnglishName(): string {
    return BrandRouteResolver.resolveBrandFromPathElement(this.$route.params.brand_english_name)
  }

  get appId(): string {
    return AppId.getByBrandName(this.brandEnglishName)
  }

  get categoryPathname(): string {
    if (this.isBrandPage) {
      return `company.product.category.${this.brandEnglishName}`
    }

    return 'mycolor.product.category'
  }

  get behaviorConfig(): BehaviorConfig {
    return BehaviorConfig.createFromBrand(this.brandEnglishName)
  }

  get sideBarItem(): typeof SideBarItem {
    return SideBarItem
  }

  showSideBar(item: SideBarItem): boolean {
    return this.behaviorConfig.sideBar.allow(item)
  }

  private async fetchFirstCategories() {
    const endpoint = `/api/common-proxy/${this.appId}/tags`
    const query = {
      pathname: this.categoryPathname,
    }

    return this.$get(endpoint, query)
  }

  private onlyPublic(categories: Category[]) {
    return categories.filter(category => category.status_id === 1)
  }

}
