


























































































































import _ from 'lodash'
import { Mixins } from 'vue-mixin-decorator'
import { Component } from 'vue-property-decorator'

import AjaxErrorDialog from '@/components/organisms/dialog/ErrorDialog.vue'
import BehaviorConfig from '@/models/app-config/behavior/behavior'
import { ItemListFilterItem } from '@/models/app-config/behavior/config/item-list/filter'
import { FilterIsExclusive } from '@/models/app-config/behavior/types'
import { Color } from '@/models/tag/color'
import { ColorList } from '@/models/tag/color-list'
import { ColorListTranslator } from '@/models/tag/specification/color-list-translator'

import Config from '@ajax/modules/config'
import { HistoryService } from '@ajax/modules/history'
import Util from '@ajax/modules/util'
import ApiMixin from '@ajax/vue/mixin/Api'

import RangeSlider from '@ajax/vue/components/atoms/RangeSlider.vue'
import ColorPicker from '@ajax/vue/components/organisms/ColorPicker.vue'
import BaseConditionModal from '@ajax/vue/components/organisms/condition/BaseConditionModal.vue'
import ConditionMenuItem from '@ajax/vue/components/organisms/condition/ConditionMenuItem.vue'
import ConditionMenuSelect from '@ajax/vue/components/organisms/condition/ConditionMenuSelect.vue'
import AjaxConditionChild from '@ajax/vue/components/templates/ConditionChild.vue'

import { log } from '@/log'
import AppId, { HOST_ID } from '@/util/appid'
import { BrandRouteResolver } from '@/util/brandRouteResolver'
import { InstanceResolver } from '@/util/instanceResolver'
import { RadioModalItem } from '@ajax/vue/components/organisms/condition/model/ConditionRadioModal'

@Component({
  components: {
    AjaxErrorDialog,
    AjaxConditionChild,
    ConditionMenuItem,
    ConditionMenuSelect,
    BaseConditionModal,
    ColorPicker,
  },
})
export default class Condition extends Mixins<ApiMixin>(ApiMixin) {

  $refs: {
    rangeslider: RangeSlider
  }

  onLoading: boolean = true

  disabledFilter: string[] = []
  from: string = '/itemlist'
  action?: string = ''
  backTo: string = ''
  hasError: boolean = false
  errorMessage: string[] = []

  data: {
    list: {
      brand: any[]
      category: any[]
      stock: any[]
      genre: any[]
    }
    checked: {
      brand: any[]
      category: any | null
      stock: any | null
      genre: any | null
    }
    /**
     * キャメルケースで定義するとURLパラメータとしての'color_family'との間で混乱を招くため、
     * この変数についてはスネークケースで定義する
     */
    color_family: {
      list: ColorList
    }
    isExclusive: FilterIsExclusive
    price: {
      min: number
      max: number
    }
    parameter: {
      brand: string
      category: string
      color_family: string
      price: string
      stock: string
      genre: string
    }
  } = {
    list: {
      brand: [],
      category: [],
      stock: this.createStockFilters(),
      genre: [],
    },
    checked: {
      brand: [],
      category: [],
      stock: null,
      genre: null,
    },
    color_family: {
      list: ColorList.empty(),
    },
    isExclusive: {
      brand: false,
      category: true,
      color_family: false,
      stock: true,
      genre: true,
    },
    price: {
      min: _.head(Config.CONDITION_PRICE)!,
      max: _.last(Config.CONDITION_PRICE)!,
    },
    parameter: {
      brand: '',
      category: '',
      color_family: '',
      price: '',
      stock: '',
      genre: '',
    },
  }

  get model(): any {
    return {
      title: {
        brand: 'ブランド',
        category: 'カテゴリー',
        stock: '在庫',
        genre: 'ジャンル',
      },
      api: {
        brand: {
          endpoint: `/api/common/brand`,
          query: {},
        },
        category: {
          endpoint: `/api/common-proxy/${this.appId}/tags`,
          query: {
            pathname: this.categoryPathname,
            scope: 'tree',
          },
        },
        color_family: {
          endpoint: `/api/common-proxy/${HOST_ID}/tags`,
          query: {
            pathname: 'mycolor.product.color_family',
            scope: 'tree',
          },
        },
        genre: {
          endpoint: `/api/common-proxy/${HOST_ID}/tags`,
          query: {
            pathname: 'mycolor.genre',
            scope: 'tree',
          },
        },
      },
      display: {
        brand: {
          key: 'id',
          value: 'name',
        },
        category: {
          key: 'name',
          value: 'title',
          pathname: 'pathname',
        },
        color_family: {
          key: 'name',
          value: 'title',
        },
        stock: {
          value: 'label',
        },
        genre: {
          key: 'name',
          value: 'title',
          pathname: 'pathname',
        },
      },
    }
  }

  show: {
    brand: boolean
    category: boolean
    color_family: boolean
    stock: boolean
    genre: boolean
  } = {
    brand: false,
    category: false,
    color_family: false,
    stock: false,
    genre: false,
  }

  get enableMenuItemList(): object {
    return _.reduce(
      this.data.list, (prev: object, value, key: any) => {
        if (!this.isFilterEnabled(key)) {
          return prev
        }
        if (!this.showElement(key)) {
          return prev
        }

        return {
          ...prev,
          [key]: value,
        }
      },
      {}
    )
  }

  get appId(): string {
    return AppId.getByBrandName(this.brandEnglishName)
  }

  get brandEnglishName(): string {
    return BrandRouteResolver.resolveBrandFromPathElement(this.$route.params.brand_english_name)
  }

  get categoryPathname(): string {
    if (this.isBrandPage) {
      return `company.product.category.${this.brandEnglishName}`
    }

    return 'mycolor.product.category'
  }

  get isBrandPage(): boolean {
    return !!this.brandEnglishName
  }

  get keywords() {
    return this.$route.query.keywords
  }

  async mounted() {
    // ブランドごとに各項目の形式（単一選択か複数選択か）を変更する
    this.data.isExclusive = this.behavior.filterIsExclusive

    try {
      this.onLoading = true

      this.getFrom()
      this.getDisabledFilter()
      await Promise.all([
        await this.list(true),
        await this.initializeColor(),
      ])
    } catch (e) {
      this.errorMessage = [
        'エラーが発生しました。',
        '※改善されない場合、お問い合わせよりカスタマーセンターまでお問い合わせください。',
      ]
      this.hasError = true

      log.error('[Condition.vue] fail load on mounted', e)
    } finally {
      this.onLoading = false
    }
  }

  showElement(elementName: string) {
    return elementName !== 'brand' || !this.isBrandPage
  }

  getFrom() {
    const urlParams = Util.convertItemlistUrlParamsToAssociativeArray()
    if (urlParams.from) {
      this.from = decodeURIComponent(urlParams.from)

      return
    }
    if (InstanceResolver.isSingle()) {
      this.from = '/itemlist'

      return
    }

    this.from = `/${this.brandEnglishName}/itemlist`
  }

  getDisabledFilter() {
    const urlParams = Util.convertItemlistUrlParamsToAssociativeArray()
    if (urlParams.disabled) {
      const disabledFilters = urlParams.disabled.split(',')
      this.disabledFilter = disabledFilters
    }
  }

  open(target: string): void {
    this.show[target] = true
  }

  close(target: string): void {
    this.show[target] = false
  }

  bind(target: string, checked: any): void {
    this.data.checked[target] = checked
  }

  clearAll() {
    Object.keys(this.data.checked).map((key) => {
      this.clear(key)
    })
    this.clearColor()
    this.data.price = {
      min: _.head(Config.CONDITION_PRICE)!,
      max: _.last(Config.CONDITION_PRICE)!,
    }
  }

  clear(target: string) {
    if (this.data.isExclusive[target]) {
      this.data.checked[target] = null
    } else {
      this.data.checked[target] = []
    }
  }

  clearColor() {
    this.data.color_family.list = this.data.color_family.list.uncheckAll()
  }

  ok() {
    const maxSelected: number = 10

    this.hasError = false
    this.errorMessage = []
    const checkedColorNum: number = _.filter(this.data.color_family.list, 'checked').length
    if (checkedColorNum > maxSelected) {
      this.hasError = true
      this.errorMessage.push('選択できるカラーの数は最大で10個です。')
    }
    if (this.hasError) return false

    this.$nextTick()
      .then(() => {
        this.action = this.makeLink(this.data.checked, false)
      })
      .then(() => {
        $('.form').submit()
      })
  }

  back() {
    HistoryService.back()
  }

  list(initialized: boolean): Promise<void[]> {
    const promises = Object.keys(this.enableMenuItemList).map(async (prefix) => {
      const api = this.model.api[prefix]

      if (api) {
        const { endpoint, query } = api
        const response = await this.$get(endpoint, query)

        if (response.body.success) {
          const dataList = response.body.data.children ? response.body.data.children : response.body.data
          this.data.list[prefix] = dataList
        }
      }
      if (initialized) {
        this.checked(prefix, this.data.list)
        this.data.list[prefix] = this.sort(this.data.list[prefix])
      }
    })

    return Promise.all(promises)
  }

  initializeColor(): Promise<void> {
    if (!this.allowedByBehavior(ItemListFilterItem.Color)) {
      return Promise.resolve()
    }
    const prefix = 'color_family'
    const endpoint = this.model.api[prefix].endpoint
    const query = this.model.api[prefix].query

    return this.$get(endpoint, query)
      .then((response) => {
        if (response.body.success) {
          const dataList = response.body.data.children
          const colorList = _.map(dataList, (data) => {
            return Color.createFromDto(data)
          })

          return colorList
        }

        return []
      })
      .then((list: Color[]) => {
        this.checked('color_family', { color_family: list })
        const colorListTranslator = ColorListTranslator.createWith(this.behavior)
        this.data.color_family.list = colorListTranslator.translate(
          ColorList.valuOf(list).sortAscByDisplayLevel()
        )
      })
  }

  sort(list: any): any {
    const current = _.sortBy(list, 'display_level')
    current.forEach((data) => {
      if ('children' in data) {
        data.children = this.sort(data.children)
      }
    })

    return current
  }

  checked(prefix, list) {

    const urlParams = Util.convertItemlistUrlParamsToAssociativeArray()
    const params = urlParams[prefix] ? urlParams[prefix].split(',') : []
    params.forEach((param) => {
      this.pushRecursion(list[prefix], param, prefix)
    })

    if (urlParams.price) {
      const priceSplit = _.filter(urlParams.price.split('-'), (item) => {
        return item !== ''
      })
      const isMax = urlParams.price.indexOf('-') === 0
      // tslint:disable-next-line:no-magic-numbers
      const isMulti = priceSplit.length === 2

      if (isMax) {
        this.data.price.max = Number(priceSplit[0])
      } else {
        if (!isMulti) {
          this.data.price.min = Number(priceSplit[0])
        } else {
          this.data.price.min = Number(priceSplit[0])
          this.data.price.max = Number(priceSplit[1])
        }
      }
    }
    if (urlParams.stock) {
      this.data.list.stock = this.createStockFilters(item => ({
        ...item,
        ...{
          selected: item.value === urlParams.stock,
        },
      }))
    }
    this.data.checked.stock = this.data.list.stock.find(item => item.selected)
  }

  pushRecursion(list, param, prefix) {
    _.forEach(list, (data) => {
      this.pushSwitchInitialChecked(data, param, prefix)
      this.pushRecursion(data.children, param, prefix)
    })
  }

  pushSwitchInitialChecked(data, param, prefix) {
    switch (prefix) {
      case 'brand':
        if (data.id === param) this.data.checked[prefix].push(data)
        break
      case 'color_family':
        if (data.pathname === `${this.model.api[prefix].query.pathname}.${param}`) {
          data.checked = true
        }
        break
      case 'category':
      case 'genre':
        if (data && data.pathname === `${this.model.api[prefix].query.pathname}.${param}`) {
          if (this.data.isExclusive[prefix]) {
            this.data.checked[prefix] = data
          } else {
            this.data.checked[prefix].push(data)
          }
        }
        break
    }
  }

  makeLink(associativeArray: any, isDirect: boolean): string | undefined {

    if (isDirect) return

    Object.keys(this.data.parameter).forEach((key) => {
      switch (key) {
        case 'brand':
          const arrayKeys = _.map(associativeArray[key], (item) => {
            if ('pathname' in this.model.display[key]) {
              return item.pathname.slice(this.model.api[key].query.pathname.length + 1, item.pathname.length)
            }

            return item[this.model.display[key].key]
          })
          this.$set(this.data.parameter, key, arrayKeys.join(','))
          break
        case 'category':
        case 'genre':
          const categoryItem = associativeArray[key]

          if (!categoryItem) {
            break
          }
          if ('pathname' in this.model.display[key]) {
            if (categoryItem.pathname) {
              // tslint:disable-next-line:max-line-length
              const pathKey = categoryItem.pathname.slice(this.model.api[key].query.pathname.length + 1, categoryItem.pathname.length)
              this.$set(this.data.parameter, key, pathKey)
            } else {
              // チェックボックスのパラメータ作成
              const pathKey: string = categoryItem.map((item) => {
                return item.pathname.slice(
                  this.model.api[key].query.pathname.length + 1,
                  item.pathname.length
                )
              }).join(',')
              this.$set(this.data.parameter, key, pathKey)
            }
          } else {
            this.$set(this.data.parameter, key, categoryItem[this.model.display[key].key])
          }
          break
        case 'color_family':
          const arrayColorKeys = this.data.color_family.list.filter(c => c.checked).map((item) => {
            if ('pathname' in this.model.display[key]) {
              const pathLength = this.model.api[key].query.pathname.length + 1
              const itemLength = item.pathname.length

              return item.pathname.slice(pathLength, itemLength)
            }

            return item[this.model.display[key].key]
          })
          this.$set(this.data.parameter, 'color_family', arrayColorKeys.join(','))
          break
        case 'price':
          const rangeMinValue = this.data.price.min
          const rangeMaxValue = this.data.price.max

          this.$set(this.data.parameter, 'price', this.convertPriceForDisplay(rangeMinValue, rangeMaxValue))
          break
        case 'stock':
          const checkedStockQuery = associativeArray.stock

          if (checkedStockQuery === null) {
            break
          }

          const stockQuery = checkedStockQuery.value

          if (!stockQuery) {
            break
          }

          this.$set(this.data.parameter, 'stock', stockQuery)

          const optionQueries = checkedStockQuery.optionQuery

          // TODO refactor
          _.forEach(optionQueries, (query, queryKey) => {
            this.$set(this.data.parameter, queryKey, query)
          })

          break
      }
    })

  }

  /**
   * FIXME
   * 実際には単なる文字列として受け取っているが、実装上これで辻褄があっているので一旦これで.
   * 丸ごと修正でもしないととてもスケールしようのない実装なので、一刻も早いリファクタ（もとい再実装）が必要
   */
  isFilterEnabled(kind: ItemListFilterItem): boolean {
    if (!this.allowedByBehavior(kind)) {
      return false
    }

    return this.disabledFilter.indexOf(kind) === -1
  }
  allowedByBehavior(kind: ItemListFilterItem): boolean {
    return this.behavior.itemList.filter.allow(kind)
  }
  get filterItemOnBehavior(): typeof ItemListFilterItem {
    return ItemListFilterItem
  }

  getCheckedStr(prefix: string): string {
    if (this.data.isExclusive[prefix]) {
      return _.get(this.data.checked[prefix], this.model.display[prefix].value)
    }

    return _.map(this.data.checked[prefix], this.model.display[prefix].value).join(', ')
  }

  convertPriceForDisplay(minValue: number = Config.CONDITION_PRICE_MIN, maxValue: number = Config.CONDITION_PRICE_MAX) {
    const min = Config.CONDITION_PRICE_MIN
    const max = Config.CONDITION_PRICE_MAX

    if (minValue === min && maxValue === max) return ''

    const minForDisplay = minValue === min ? '' : minValue
    const maxForDisplay = maxValue === max ? '' : maxValue

    return `${minForDisplay}-${maxForDisplay}`
  }

  onChangePrice(value) {
    this.data.price = value
  }

  createStockFilters(decorator?: (item: RadioModalItem) => RadioModalItem) {
    const init = [
      {
        id: 'only-nothing',
        label: '指定なし',
        value: '',
        selected: true,
      },
      {
        id: 'only-left',
        label: '在庫あり',
        value: '1~',
        selected: false,
        optionQuery: {
          sales_status: 4,
        },
      },
    ]

    return init.map(item => decorator ? decorator(item) : item)
  }

  onChangeColor(item: { id: string, checked: boolean }) {
    this.data.color_family.list = this.data.color_family.list.toggleCheckedOf(item.id, item.checked)
  }

  hasChecked(key: string) {
    return this.data.checked[key] !== null && this.data.checked[key].length !== 0
  }

  private get behavior(): BehaviorConfig {
    return this.isBrandPage ? BehaviorConfig.createFromBrand(this.brandEnglishName) : new BehaviorConfig()
  }
}
