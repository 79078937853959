import SList from './node/s-list'
import { SSymbol } from './type/s-symbol'

export default class SExpression {
  // alias
  static readonly ls = SExpression.list
  static readonly wt = SExpression.with

  static list(...values: SSymbol[]): SList {
    return SList.create(...values)
  }
  static with(first: SSymbol) {
    return SList.with(first)
  }
  static nil(): SList {
    return SList.nil()
  }
}
