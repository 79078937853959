import _ from 'lodash'

import Vue from 'vue'
import Vuex from 'vuex'

import { mutations } from './mutations'
import { initialRootState, RootState } from './types'

import { createBundleDiscount } from './modules/bundle-discount'
import { searchContactLens } from './modules/searchContactLens'

Vue.use(Vuex)

export default new Vuex.Store<RootState>({
  state: _.cloneDeep(initialRootState),

  mutations,

  modules: {
    bundleDiscount: createBundleDiscount(),
    searchContactLens,
  },
})
