import { Either, Left, Right } from 'fp-ts/lib/Either'

import { log } from '@/log'
import { ApiService } from '@/services/api/service'
import { ApiClient } from './client'

const namespace: string = 'auto-load'

export class AutoLoginService extends ApiService {

  async login(userKey: string, hash: string, site: string): Promise<Either<Error, any>> {
    try {
      const response = await ApiClient
        .get('/api/auto-login')
        .query({
          o: userKey,
          hash,
          site,
        })

      log.debug({ service: `${namespace}/login`, response })

      return new Right(response)
    } catch (error) {
      log.error({ error })

      return new Left(error)
    }
  }
}
