import { Either, Left, Right } from 'fp-ts/lib/Either'

import { log } from '@/log'
import { ApiClient } from './client'
import { ApiService } from './service'

export default class EntryService extends ApiService {
  public async try(): Promise<Either<Error, {}>> {
    try {
      await ApiClient.get(`/api/${this.appId}/entry`)

      return new Right({})
    } catch (error) {
      log.error({ error })

      return new Left(error)
    }
  }
}
