import BehaviorConfig from '@/models/app-config/behavior/behavior'
import { BadgeListDisplayPolicy } from '@/models/badge-list/display-policy'
import { MyColorBadgeListDisplayPolicy } from '@/models/badge-list/each-policy/mycolor-policy'
import { CoelBadgeListDisplayPolicy } from '../each-policy/coel-policy'

export const createBadgeListPolicy = (
  behavior: BehaviorConfig
): BadgeListDisplayPolicy => {
  return behavior.isDMI ? new CoelBadgeListDisplayPolicy(behavior) : new MyColorBadgeListDisplayPolicy(behavior)
}
