

























import _ from 'lodash'
import Vue from 'vue'
import { Component } from 'vue-property-decorator'

import BehaviorConfig from '@/models/app-config/behavior/behavior'
import { SearchItem } from '@/models/app-config/behavior/config/side-bar/search'
import { BrandRouteResolver } from '@/util/brandRouteResolver'
import { InstanceResolver } from '@/util/instanceResolver'
import Config from '@ajax/modules/config'
import SearchBrand from '../organisms/search-area/SearchBrand.vue'
import SearchCategory from '../organisms/search-area/SearchCategory.vue'
import SearchColor from '../organisms/search-area/SearchColor.vue'
import SearchPrice from '../organisms/search-area/SearchPrice.vue'

import Util from '../../../modules/util'

@Component({
  components: {
    SearchBrand,
    SearchCategory,
    SearchColor,
    SearchPrice,
  },
})
export default class SearchArea extends Vue {
  queries: any = {}

  mounted() {
    this.queries = Util.convertItemlistUrlParamsToAssociativeArray()
  }

  get isBrandPage(): boolean {
    return !!this.brandEnglishName
  }

  get brandEnglishName(): string {
    return BrandRouteResolver.resolveBrandFromPathElement(this.$route.params.brand_english_name)
  }

  get showBrand(): boolean {
    if (!this.configForSearch.allow(SearchItem.Brand)) {
      return false
    }

    return !this.isBrandPage
  }

  get behavior(): BehaviorConfig {
    return BehaviorConfig.createFromBrand(this.brandEnglishName)
  }

  get configForSearch() {
    return this.behavior.sideBar.search
  }

  get itemForSearch(): typeof SearchItem {
    return SearchItem
  }

  submit () {
    /**
     * FIXME: リファクタ. $refs経由の参照を廃止
     */
    const brands = this.$refs.searchBrand ? (this.$refs.searchBrand as SearchBrand).selectedBrands : []
    const categories = this.$refs.searchCategory ? (this.$refs.searchCategory as SearchCategory).selectedCategories : []
    const colors = this.$refs.searchColor ? (this.$refs.searchColor as SearchColor).selectedColors : []
    const prices = this.$refs.searchPrice ? (this.$refs.searchPrice as SearchPrice).selectedPrices : []
    const { keywords } = this.queries

    const queries: string[] = [] // TODO push を使わずに実装する
    if (brands.length > 0) {
      const terms = _.map(brands, 'id').join(',')
      queries.push(`brand=${terms}`)
    }
    if (categories.length > 0) {
      const terms = _.map(categories, 'pathName').join(',')
      queries.push(`category=${terms}`)
    }
    if (colors.length > 0) {
      const terms = _.map(colors, 'name').join(',')
      queries.push(`color_family=${terms}`)
    }
    const priceTerms: string[] = []
    if (prices.min > 0) {
      priceTerms.push(prices.min)
    }
    priceTerms.push('-')
    if (prices.max < Config.CONDITION_PRICE_MAX) {
      priceTerms.push(prices.max)
    }
    if (priceTerms.length > 1) {
      queries.push(`price=${priceTerms.join('')}`)
    }

    if (keywords) {
      queries.push(`keywords=${keywords}`)
    }

    location.href =  `${this.itemlistUrl}?${queries.join('&')}`
  }

  private get itemlistUrl(): string {
    if (InstanceResolver.isSingle()) {
      return '/itemlist/search'
    }

    return `/${this.brandEnglishName}/itemlist/search`
  }
}
