import CommandAddItemToCart from '@/models/cart/command/add-item-to-cart'
import { SkuPrice } from '@/models/item/sku'
import { BrandRouteResolver } from '@/util/brandRouteResolver'
import { Either } from 'fp-ts/lib/Either'

export interface AddItemDeps {
  command: CommandAddItemToCart
  location: Location
}
export interface AddItemParam {
  item: {
    id: string
    count: number
    price: SkuPrice
  }
}

type AddItem = (deps: AddItemDeps) => (param: AddItemParam) => Promise<Either<string, void>>
const addItem: AddItem = ({ command, location }) => async ({ item }: AddItemParam) => {
  const result = await command.execute(item.id, item.count, item.price)

  return result.map(
    (productBelongingBrandEnglishName: string) => {
      const query = {
        isCartIn: true,
        productId: item.id,
        /**
         * TODO: unitPriceクエリはコードを見る限りでは利用されていないので、
         * 影響範囲を調査した上で問題なければ削除する。
         * なお、たぶんproductIdも使われていない。
         * @see https://github.com/my-color/front/pull/6049
         */
        unitPrice: item.price.prices.selected.price,
      }

      // Navigate
      // TODO: When 'ajax' and 'spa' are integrated, use vue-router
      location.href = BrandRouteResolver.makeResourceEndpoint({
        brand: productBelongingBrandEnglishName,
        path: ['cart'],
        query,
        withSpaPrefix: true,
      })
    }
  )
}

export const interaction = {
  addItem,
}
