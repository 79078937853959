import { ApiCreditcard } from '@/models/api/creditcard'

export const initialCreditcardState: CreditcardState = {
  items: [],
  error: {},
}

export interface CreditcardState {
  items: CreditcardModel[],
  error: {
    load?: Error,
  },
}

// store固有の拡張が必要になった時に備えてaliasを経由させているが、
// 不要だと判断できれば削除して良い
export type CreditcardModel = ApiCreditcard
