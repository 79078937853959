import { Color } from './color'

export class ColorList {
  static empty(): ColorList {
    return new ColorList([])
  }

  static valuOf(list: Color[]) {
    return new ColorList(list)
  }

  constructor(private readonly list: Color[]) {}

  sortAscByDisplayLevel(): ColorList {
    return ColorList.valuOf(
      this.list.sort((a, b) => a.displayLevel - b.displayLevel)
    )
  }

  filter(f: (c: Color) => boolean): ColorList {
    return ColorList.valuOf(this.list.filter(f))
  }

  map<T>(f: (c: Color) => T): T[] {
    return this.list.map(f)
  }

  find(id: string): Color | undefined {
    return this.list.find(c => c.id === id)
  }

  toggleCheckedOf(id: string, checked: boolean): ColorList {
    return ColorList.valuOf(
      this.list.map((c) => {
        if (c.id === id) {
          return checked ? c.check() : c.unCheck()
        }

        return c
      })
    )
  }

  uncheckAll(): ColorList {
    return ColorList.valuOf(this.list.map(c => c.unCheck()))
  }
}
