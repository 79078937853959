import * as ImageConfig from '@/config/assets/image'
import { log } from '@/log'
import ApiBrand from '@/models/api/brand'
import { ReplaceCartPriceParameter, ReplacePriceRepository } from '@/models/api/cart/latest-price/replace-price-reposiory'
import { ValidPrice } from '@/models/api/cart/latest-price/scheme'
import { ApiProduct } from '@/models/api/product'
import { ApiProductclass } from '@/models/api/productclass'
import BehaviorConfig from '@/models/app-config/behavior/behavior'
import { isPaidMembershipPrice } from '@/models/item/sku'
import * as SKUFactory from '@/models/item/sku/factory/sku'
import GetStockStatus from '@/usecase/get-stock-status'
import { CartService, JoinedProductclassData } from '@spa/services/api/cart'
import { EnableToListCartItem } from '@spa/store/modules/cart/repository'
import { CartItem } from '@spa/store/modules/cart/types'
import _ from 'lodash'
import { ReasonForCannotBePurchasedList, ShouldPromoteCountSelection } from './types/reason-for-cannot-be-purchased'

export interface CanConvertRawItem<T = any> {
  convert(rawDataList: T[]): T extends CartItem ? never : Promise<CartItem[]>
}

export class RawItemConverter implements CanConvertRawItem {
  static createViaApiClient(cartApiClient: CartService): RawItemConverter {
    return new RawItemConverter(
      (rawItems: any[]) => cartApiClient.join(rawItems)
    )
  }
  private static convertToCartItem(rawItem): CartItem {
    const apiProduct = new ApiProduct(_.get(rawItem, 'product'))
    const belongingBrand = new ApiBrand(rawItem.brands[0])

    return {
      id: rawItem.id,
      /**
       * この時点ではROOT商品情報が取得できていないので、一旦デフォルト値として""で埋める.
       * ROOT商品情報の取得に成功すれば、 #injectProdcutclassData 内部で置換される.
       * デフォルト値だった場合にどう表現するかは、UIコンポーネントに任せる.
       *
       * @see https://github.com/my-color/front/issues/4811#issuecomment-813854920
       * @see RawItemConverter.injectProductclassData
       */
      name: '',
      createdAt: _.get(rawItem, 'created_at'),
      unitPrice: _.get(rawItem, 'unitPrice'),
      /**
       * TODO: 一元管理する
       * 現状で @/models/cart/repository/impl/cart-item/engine-cart-item-repository にもベタ書きされている
       */
      isWithPaidMembershipPrice: _.get(rawItem, 'aux_string_1') === 'with-paid-member-price',
      skuSellPrice: apiProduct.price.regular,
      skuSalePrice: apiProduct.price.discounted,
      unitCount: _.get(rawItem, 'unitCount') as number,
      brandId: belongingBrand.id,
      brandName: belongingBrand ? belongingBrand.name : null,
      brandEnglishName: belongingBrand ? belongingBrand.englishName : null,
      skuId: apiProduct.id,
      fkuId: apiProduct.productClassId,
      size: apiProduct.getSize(),
      onSale: apiProduct.isOnDiscountSale,
      salesStatus: _.get(rawItem, 'product.sales_status') as number,
      salesStartAt: apiProduct.regularSaleStartAt,
      salesEndAt: apiProduct.regularSaleEndAt,
      /**
       * cartItem.product には在庫情報が乗ってこない.
       *
       * なので、#injectProductclassData で対応するProductの情報を取得し、
       * そちらで予約商品かの情報を取得/上書きしている.
       */
      isPreSales: false,
      /**
       * cartItem.product には在庫情報が乗ってこない.
       *
       * なので、#injectProductclassData で対応するRootの情報を取得し、
       * そちらで紐付き商品かの情報を取得/上書きしている.
       */
      isParent: false,
      isChild: false,
      isNovelty: false,
      /**
       * 現状、FKUが取得できなかったなら、ROOT,FKU非公開、という判定をしている。
       * したがってこの情報は #injectProductclassData で決まる。
       * https://github.com/my-color/front/pull/2638#issuecomment-542615347
       */
      isFkuPublic: false,
      childItem: null,
      ineligibleForCoupon: false,
      freeShipping: false,
      reasonForCannotBePurchased: ReasonForCannotBePurchasedList.asEmpty(),
    }
  }

  private constructor(
    /**
     * join item metadata from SKU/FKU/ROOT
     * Filter if FKU does not exists because SKU is somehow invalid
     */
    private readonly join: (rawItems: any[]) => Promise<JoinedProductclassData[]>
  ) {}

  async convert(rawDataList: any[]): Promise<CartItem[]> {
    const converted = this.convertToCartItems(rawDataList)
    const joined = await this.join(rawDataList)

    return this.injectProductclassData(converted, joined.filter(i => i.fku))
  }

  convertToCartItems(responseData: any[]): CartItem[] {
    return _.map(responseData, rawItem => RawItemConverter.convertToCartItem(rawItem))
  }

  injectProductclassData(items: CartItem[], joined: JoinedProductclassData[]) {
    // Inject with joined product/productclass info
    _.map(items, (item) => {
      const entry = _.find(joined, ['id', item.id])
      if (entry === undefined) {
        log.warn('ROOT or FKU is not public, so there is no other way to assume detail of cart. treat it as unpublic.')

        item.isFkuPublic = false

        return item
      }
      item.isFkuPublic = true

      /**
       * FIXME:
       * 非常に場当たり的な対応だが、整理しようとするとおよそカート内のデータ構造を全て刷新する勢いの対応が必要そう.
       * 「カート内商品」という概念が、「ROOT/FKU/SKU」といった通常の「商品」とどういった関連に有るのか不明瞭.
       *
       * SKUが最小単位である以上、「カート内商品」の骨子となるのはSKUのはず.
       * ROOT/FKUの情報は、表示の都合のための付帯情報に過ぎないと思われる.
       * が、現状↑を表現できるだけの知識も表現力も現行のドメインオブジェクトは持ち合わせていないので、
       * 一旦「在庫薄取得」という機能のみを、ピンポイントに応急処置的に挿入している.
       *
       * まず「カート内商品」という概念を整理する必要がある.
       * 「カート内商品とはなんぞや」が定義できないと、どこに何を定義するべきかも定まらず、
       * ロジックが再び各層に散逸することも阻止できない.
       */
      const root = entry.root || new ApiProductclass(undefined) // :pensive: 一先ず、既存の動きから変えない・・・
      const fku = new ApiProductclass(entry.fku)
      const sku = new ApiProduct(entry.sku)
      const skuEntity = SKUFactory.create(sku)
      const getStockStatus = GetStockStatus.createViaConfig(
        BehaviorConfig.createFromBrand(sku.belongingBrand)
      )

      item.salesStartAt = sku.regularSaleStartAt
      item.salesEndAt = sku.regularSaleEndAt

      item.name = root.productName

      item.root = root
      item.rootId = root.id
      item.rootShortId = root.shortId // used for link to item page

      const colorTag = fku.color
      // FIXME: potential null error!
      if (colorTag) {
        item.color = colorTag.title
        item.colorName = colorTag.name
      }
      // TODO: Move this dummy image logic into a domain model
      const thumbnail = fku.representativeImage ? fku.representativeImage.url : ImageConfig.dummyImagePath

      if (thumbnail) {
        item.thumbnail = thumbnail
      }

      const stock = getStockStatus.amount(skuEntity.stock)
      item.maxSellCount = Math.min(
        sku.maxSellCount,
        stock
      )
      item.rootPurchaseLimit = root.purchaseLimit
      item.stock = stock
      /*
       * TODO: purchase-count-limit-specification を使う形にすると同時に、↓の形に置き換える
       *  item.maxSellCount = sku.maxSellCount
       *  item.stock = getStockStatus.amount(skuEntity.stock)
       *  item.rootPurchaseLimit = root.purchaseLimit
       *
       * item.maxSellCount に予めすべてを詰め込むのではなく、フラットに配置して、
       * 評価は後から実行される仕様オブジェクトに任せる.
       */

      item.isPreSales = sku.isPreSale

      item.isParent = root.isParent
      item.isChild = root.isChild
      item.isCatalogType = root.tags.some(t => t.pathname === 'mycolor.product.catalog')
      item.isNovelty = root.isNovelty
      item.childItem = root.usingTo.find(relatedItem => relatedItem.isChild) || null
      item.ineligibleForCoupon = root.tags.some(t => t.pathname === 'mycolor.product.ineligible.coupon')
      item.freeShipping = root.tags.some(t => t.pathname === 'mycolor.product.delivery.free_shipping')
    })

    return items
  }
}

export class ConvertWithBeforePrice {
  private replaceListeners: Array<() => void> = []

  constructor(
    private readonly repository: EnableToListCartItem,
    private readonly replacePriceRepository: ReplacePriceRepository
  ) {}

  onReplaced(listener: () => void): this {
    this.replaceListeners.push(listener)

    return this
  }

  async convert(baseItems: CartItem[]): Promise<CartItem[]> {
    const maybeValidPriceList = await this.replacePriceRepository.updateCurrentValidPrice()
    if (maybeValidPriceList.isLeft()) {
      const error = maybeValidPriceList.value
      log.error('Could not fetch latest cart item price', maybeValidPriceList.value)
      throw error
    }

    const validPriceList = maybeValidPriceList.value

    validPriceList.failure.forEach((failure) => {
      const failedCart = baseItems.find(i => i.id === failure.cart_id)

      if (!failedCart) return

      failedCart.reasonForCannotBePurchased = failedCart.reasonForCannotBePurchased.add(
        {
          type: 'priceDifferenceNotChecked',
          errors: failure.errors,
          message: failure.message || '価格の取得に失敗したため現在ご購入いただけません。',
        }
      )
    })

    /**
     * まだ会員価格での購入残数があるにも関わらず非会員価格が選択されている、
     * すなわち上限を超えてカートに入れられている場合、非有料会員価格を適用するのではなく、
     * 購入上限数を超過しているため会員価格を適用できないことをエラーとして表示する。
     * Ledian v1としては削除してから再度カート追加してもらうが、
     * 本来は有料会員価格が適用できる範囲で個数を選択できるようなセレクトボックスを表示することが表示されているべき。
     *
     * また、価格に関して要求とコードが乖離してきているのでユースケースを整理することが必要そう。
     * このままだと/api/user/cart/current_valid_priceが謎の巨大便利APIになってしまう。
     */
    validPriceList.success.forEach((success) => {
      const selectedPriceIsNonPaidMemberPrice = !success.current_valid_price.prices.selected.is_paid_membership_price
      const unselectedPricesRemainingCountIsExist = _.filter(
        success.current_valid_price.prices.unselected,
        price => price.rest_amount.add_to_cart > 0
      )

      if (selectedPriceIsNonPaidMemberPrice && unselectedPricesRemainingCountIsExist.length > 0) {
        const cart = baseItems.find(i => i.id === success.cart_id)
        if (!cart) return

        cart.reasonForCannotBePurchased = cart.reasonForCannotBePurchased.add({
          type: 'shouldPromoteCountSelection',
          errors: ['non-paid-member price was applied despite the number of remaining amount of paid-member price exists.'],
          message: '購入上限数を超えています。数量を変更してください。',
          count: {
            min: 1,
            max: _.max(unselectedPricesRemainingCountIsExist.map(p => p.rest_amount.add_to_cart)) || 1,
          },
        } as ShouldPromoteCountSelection)
      }
    })

    // 現在のセール状態や会員状態に応じた最新の有効価格と比較して、カート内価格が違っていれば強制変更する。
    const replaceCartPriceParameterList = _.compact(
      baseItems.map((cartItem): ReplaceCartPriceParameter | null => {
        const validPrice = _.find(validPriceList.success, d => d.cart_id === cartItem.id)

        /**
         * 対応するSKU最新有効価格が取得できなかった場合、カート取得処理そのものを止めるよりは、
         * ユーザが購買プロセスを続行できることを優先し、warningに留めている。
         *
         * ただし最終的には以下の議論から、SKU最新有効価格が取得できなかった部分に対してのみ、何らかの非整合性フラグを立てて、
         * 非整合なデータにふさわしい処理を別途行うようにする予定である。
         * @see https://github.com/my-color/front/pull/6011#discussion_r783369523
         */
        if (!validPrice) {
          log.warn(`Could not fetch latest price for cart: ${cartItem.id})`)

          return null
        }

        if (!validPrice.difference) {
          return null
        }

        return {
          cartId: cartItem.id,
          skuId: cartItem.skuId,
          unitPrice: validPrice.current_valid_price.prices.selected.price,
          isPaidMembershipPrice: isPaidMembershipPrice(validPrice.current_valid_price),
          unitCount: cartItem.unitCount,
        }
      })
    )

    if (_.isEmpty(replaceCartPriceParameterList)) {
      return this.joinCurrentValidPrice(baseItems, validPriceList)
    }

    // valid_price取得時にカートの更新がされているので再度取得する
    const updated = await this.repository.list()
    const latestPriceJoined = this.joinCurrentValidPrice(updated, validPriceList)

    this.replaceListeners.forEach(l => l())

    const incorrectPriceItems = baseItems.filter((item) => {
      return !!_.find(replaceCartPriceParameterList, param => param.cartId === item.id)
    })

    return this.setBeforePrice(incorrectPriceItems, latestPriceJoined)
  }

  setBeforePrice(staleItems: CartItem[], items: CartItem[]): CartItem[] {
    // check change before price in cart
    _.forEach(staleItems, (staleItem) => {

      const item = _.find(items, ['skuId', staleItem.skuId])

      if (!item) {
        return
      }

      if (staleItem.unitPrice === item.unitPrice || item.removed) {
        return
      }

      item.beforePrice = staleItem.unitPrice
    })

    return items
  }

  private joinCurrentValidPrice(cartItems: CartItem[], validPriceList: ValidPrice): CartItem[] {
    return cartItems.map((cartItem: CartItem): CartItem => {
      const validPrice = _.find(validPriceList.success, d => d.cart_id === cartItem.id)

      /**
       * 対応するSKU最新有効価格が取得できなかった場合、カート取得処理そのものを止めるよりは、
       * ユーザが購買プロセスを続行できることを優先し、warningに留めている。
       */
      if (!validPrice) {
        log.warn(`Could not fetch latest price for cart: ${cartItem.id})`)

        return cartItem
      }

      return {
        ...cartItem,
        currentValidPrice: validPrice.current_valid_price,
      }
    })
  }
}
