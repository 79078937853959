

































































































import { SearchContactLensCond } from '@/store/modules/searchContactLens/types'
import { Mixins } from 'vue-mixin-decorator'
import { Component } from 'vue-property-decorator'

import SearchSys5Cond from './cond/SearchSys5Cond.vue'
import SearchSys5Item from './item/SearchSys5Item.vue'
import SearchContactLensBase from './SearchContactLensBase.vue'

@Component({
  components: {
    SearchSys5Cond,
    SearchSys5Item,
  },
})
export default class SearchContactLens5 extends Mixins<SearchContactLensBase>(SearchContactLensBase) {
  initialSearchCond: SearchContactLensCond = {
    hasSPDrawing: false,
    matchValues: [],
  }

  // ページ読み込み開始時.
  created() {
    this.setInitialSearchCondition(this.initialSearchCond)
    this.$emit('loadingStart')
  }

  // ページ読み込み完了時.
  async mounted() {
    this.setPageTitle('レンズデザインからカラコンを探す')
    $('#v1')['controls'] = false
    await this.onMounted()
    this.$emit('loadingStop')
  }

  onSwith() {
    this.clearRemodal()
    this.$emit('loadingStart')
    this.$router.push({ name: '4' })
  }
}
