export type SSymbol = string | SSymbolObject | SSymbolFunction

export interface SSymbolObject {
  serialize(): SSymbol
}

export type SSymbolFunction = () => SSymbol

export function serialize(sData: SSymbol): string {
  if (isSSymbolObject(sData)) {
    return serialize(sData.serialize())
  }
  if (isSSymbolFunction(sData)) {
    return serialize(sData())
  }

  return sData.toString()
}

export function isSSymbolObject(value: SSymbol): value is SSymbolObject {
  const v: any = value
  if (typeof v !== 'object') {
    return false
  }

  return v.serialize !== undefined
}
export function isSSymbolFunction(value: SSymbol): value is SSymbolFunction {
  return typeof value === 'function'
}
