import _ from 'lodash'
import { Module } from 'vuex'

import { RootState } from '../../types'
import { actions } from './actions'
import { getters } from './getters'
import { mutations } from './mutations'
import { initialOrderConfirmState, OrderConfirmState } from './types'

export const orderConfirm: Module<OrderConfirmState, RootState> =  {
  namespaced: true,
  state: _.cloneDeep(initialOrderConfirmState),
  actions,
  getters,
  mutations,
}
