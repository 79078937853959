import Vue from 'vue'
import { Mixin } from 'vue-mixin-decorator'

import { ApiClient } from '../../modules/services/client'

@Mixin
export default class Api extends Vue {

  public async $get(endpoint, query = {}) {
    return await ApiClient.get(endpoint, query)
  }

  public async $post(endpoint, data = {}) {
    return await ApiClient.post(endpoint, data)
  }

  public async $put(endpoint, data = {}) {
    return await ApiClient.put(endpoint, data)
  }
}
