import _ from 'lodash'

import { BrandBundleDiscountState, BundleDiscountState } from './types'

export function appendBrand(
  state: BundleDiscountState,
  appended: BrandBundleDiscountState
): BrandBundleDiscountState[] {
  return [
    ...state.brands.filter(brand => brand.appId !== appended.appId),
    appended,
  ]
}

export function extractBrandBundleDiscountState(
  state: BundleDiscountState,
  appId: string
): BrandBundleDiscountState | null {
  return _.find(state.brands, brandState => brandState.appId === appId) || null
}
