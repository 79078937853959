






















































import BehaviorConfig from '@/models/app-config/behavior/behavior'
import ContactLensFKU from '@/models/item/fku/contact-lens'
import ContactLensFKURepository from '@/models/item/fku/repository'
import ContactLensRootRepository from '@/models/item/root/repository'
import AppId from '@/util/appid'
import { BrandRouteResolver } from '@/util/brandRouteResolver'
import { log } from '@spa/log'
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import ContactLensItemDetailStore from './store'
import ContactLensItemDetailStoreBuilder from './store-builder'

@Component
export default class ContactLensColorVariation extends Vue {
  @Prop({ required: true })
  id: string

  store: ContactLensItemDetailStore | null = null

  async created() {
    try {
      const brandName = BrandRouteResolver.resolveBrandFromPathElement(this.$route.params.brand_english_name)
      const appId = AppId.getByBrandName(brandName)
      const storeBuilder = ContactLensItemDetailStoreBuilder.getInstance(
        new ContactLensRootRepository(appId),
        new ContactLensFKURepository(appId)
      )
      this.store = await storeBuilder.build(
        this.id,
        BehaviorConfig.createFromBrand(brandName)
      )
    } catch (error) {
      console.error(error)
    }
  }

  get fkus(): ContactLensFKU[] {
    return this.store ? this.store.fkus : []
  }

  /**
   * レンズカラー画像をクリックすると、
   * - 当該カラーのFKUの最初の画像にカルーセルのフォーカスを移動する。
   * - カラー選択のドロップダウンにおいて当該カラーを自動で選択する。
   *
   * @see src/Template/Element/item/image_area.ctp
   */
  onClick(fku: ContactLensFKU): void {
    this.moveCarousel(fku)

    if (this.store) {
      this.store.selectFku(fku)
    }
  }

  private moveCarousel(fku: ContactLensFKU): void {
    const index = this.getIndexOfFirstMediumInFku(fku.lensImageMediaId)
    if (!index) {
      log.error('クリックしたFKUの最初の画像のインデックスの取得に失敗しました。')

      return
    }

    const types = ['pc', 'sp']
    types.forEach((type) => {
      $(`#js-thumb-${type}-main`).slick('slickGoTo', index)
    })
  }

  private getIndexOfFirstMediumInFku(mediaId: string): string | undefined {
    const elem = document.querySelector(`div[data-media-id="${mediaId}"]`)

    return elem ? (elem as HTMLElement).dataset.indexOfFirstMediumInFku : undefined
  }
}
