import _ from 'lodash'
import { MutationTree } from 'vuex'

import { Order, OrderHistoryState, Page } from './types'

import { log } from '../../../log'

const namespace = 'orderHistory'

export const mutations: MutationTree<OrderHistoryState> = {

  addPage(state, payload: Page) {
    state.error = {}
    state.pages = [
      ...state.pages.filter(page => page.pageNumber !== payload.pageNumber),
      payload,
    ]

    log.debug({ commit: `${namespace}/addPage`, state })
  },

  get(state, payload: Order) {
    state.error = {}
    state.item = payload

    log.debug({ commit: `${namespace}/get`, state })
  },

  errorOnLoad(state, payload: Error) {
    const error = payload
    _.set(state, 'error.load', error)
  },
}
