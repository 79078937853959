import { GetterTree } from 'vuex'

import { RootState } from '@spa/store/types'
import { AppSetting } from './types'

export const getters: GetterTree<AppSetting, RootState> = {
  errorOnLoad(state) {
    return state.error.load
  },

  scheduledDelivery(state) {
    return state.appSetting.scheduledDelivery
  },
}
