import { ApiProduct } from '@/models/api/product'
import { PaymentType } from '@/models/app-config/behavior/types'
import ThumbnailUrl from '@/models/item/thumbnail-url'
import { CarrierProvider } from '@/models/purhase/carrier_provider'
import { CvsType } from '@/models/purhase/cvs_type'
import { ApplyingBundleDiscount } from '@spa/services/api/purchase'
import _ from 'lodash'

export const initialOrderHistoryState: OrderHistoryState = {
  pages: [],
  perPage: 5,  // By default
  item: null,
}

export interface OrderHistoryState {
  pages: Page[],
  perPage: number,
  item: Order | null,
  error?: {
    load?: Error,
  },
}

/**
 * FIXME: Use or Create more better pagination library/model.
 */
export interface Page {
  pageNumber: number,
  items: Order[],
  isLastPage: boolean,
  error?: Error,
}

export interface Order {
  id: string,
  shortid: string,
  aux_string_3: string,
  name: string,
  brands: any[],
  chargeSheet: {
    delivery: any,
    order: any,
    payment: any,
    serviceFee: {
      priceIncludingTax: {
        price: number
      }
    }
    tax: number,
    total: number,
  },
  commerceEvent: any[],
  delivery: {
    contactId: string,
    kind: string,
    contact: {
      address: {
        code: string,
        country: string,
        locality: string,
        region: string,
        street: string,
      },
      name: {
        firstName: string,
        lastName: string,
      },
      tel: string,
    },
    date: string,
    timeSlot: string,
    timeSlotLabel: string,
    deliveryMethod: string,
  },
  /**
   * 事前の注文リクエスト作成時に { discount: { amount: 0 } } が指定されていないと
   * Engineレスポンスに含まれなくなってしまう点に注意.
   *
   * 単発注文のルートでは、サーバー側で常に埋め直している模様.
   *
   * @see https://www.chatwork.com/#!rid87550748-1495266408857116672
   * @see \App\Controller\Api\User\Subscription\ApplyRequest::toArray
   * @see \App\Parameter\CommercePurchase\Post::insertDiscountToRequest
   */
  discount: {
    amount: number,
  },
  payment: {
    method: PaymentMethod,
    point?: PaymentPoint,
  },
  salesOrderStatus: SalesOrderStatus,
  salesOrderStatusDetail: any,
  statusId: number,
  shipmentOrder?: any,
  tags: any[],
  createdAt: Date,
  publishAt: Date,
  updatedAt: Date,
  products: Product[],
  isPreOrder: boolean
  error?: {
    join: Error,
  },
  isFreeShippingOnCatalogOnly: boolean,
  attachment?: {
    applyingBundleDiscount?: ApplyingBundleDiscount
  },
  item?: Item[],
  bundleDiscountedPrice?: number,
  couponDiscountedPrice?: number,
  estimatedPoints?: number,
}

export interface PaymentMethod {
  kind: PaymentType | 'none'
  token?: string // クレジットカード利用時
  cvs_type?: CvsType // コンビニ決済利用時
  carrier_provider?: CarrierProvider // キャリア決済利用時
}

export interface PaymentPoint {
  kind: string,
  amount: number,
}

export type SalesOrderStatus =
  'payment-requested' |
  'payment-done' |
  'shipment-preparation' |
  'shipment-started' |
  'shipment-delivering' |
  'cancel' |
  'payment-error' |
  'payment-fatal-error'

/**
 *  Order's 'part' data along with joined ROOT/FKU/SKU data
 */
export interface Product {
  id: string,
  rootShortId: string,
  itemName?: string,
  thumbnail?: string,
  unitPrice: number,
  unitCount: number,
  totalPrice: number,
  tax: number,
  brandName?: string,
  brandEnglishName: string | null,
  skuId: string,
  fkuId: string,
  rootId?: string,
  size?: string,
  color?: string,
  productTag?: string,
  productclassTag?: string,
  alphabeticColor?: string,
  startAt?: string,
  isChild: boolean,
  isNovelty: boolean,
  isCatalogType: boolean,
}

/**
 * Orderのdata.itemの要素
 * 必要になったキーだけ記述している
 */
interface Item {
  cart_id?: string,
}

export function convertToOrders(data: any[]): Order[] {
  return _.map(data, convertToOrder)
}

export function convertToOrder(data: any): Order {
  return {
    id: _.get(data, 'id'),
    shortid: _.get(data, 'shortid'),
    aux_string_3: _.get(data, 'aux_string_3'),
    name: _.get(data, 'name'),
    brands: _.get(data, 'brands'),
    chargeSheet: {
      delivery: _.get(data, 'charge_sheet.delivery'),
      order: _.get(data, 'charge_sheet.order'),
      payment: _.get(data, 'charge_sheet.payment'),
      tax: _.get(data, 'charge_sheet.tax'),
      total: _.get(data, 'charge_sheet.total'),
      serviceFee: _.get(data, 'charge_sheet.service_fee'),
    },
    commerceEvent: _.get(data, 'commerce_event'),
    delivery: {
      contactId: _.get(data, 'delivery.contact_id'),
      kind: _.get(data, 'delivery.kind'),
      contact: {
        address: {
          code: _.get(data, 'delivery.contact.address.code'),
          country: _.get(data, 'delivery.contact.address.country'),
          locality: _.get(data, 'delivery.contact.address.locality'),
          region: _.get(data, 'delivery.contact.address.region'),
          street: _.get(data, 'delivery.contact.address.street'),
        },
        name: {
          firstName: _.get(data, 'delivery.contact.name.first_name'),
          lastName: _.get(data, 'delivery.contact.name.last_name'),
        },
        tel: _.get(data, 'delivery.contact.tel'),
      },
      date: _.get(data, 'delivery.date'),

      timeSlot: _.get(data, 'delivery.timeSlot'),
      timeSlotLabel: _.get(data, 'delivery.timeSlotLabel'),

      /**
       * 複数配送方法対応以前は、このパラメータが存在しないため、同対応以前の注文履歴からは配送方法を取得できない。
       * しかし、同対応後は注文時に必ずこのパラメータを付与しなければならない。
       * したがって、ここでデフォルト値として「ヤマト宅急便」を設定する。
       *
       * 現在（2021.03.16）、利用可能な配送方法は全てクロネコヤマトだから、固定のデフォルト値で問題ないが、
       * 今後もし他の配送業者が追加された場合は、固定値では対処出来ない可能性があるため、プリセットAPIを使って、
       * 選択可能な配送方法を取得するなどの対応が必要。
       * @see: https://my-color.esa.io/posts/593
       */
      deliveryMethod: _.get(data, 'delivery.delivery_method', 'ヤマト宅急便'),
    },
    discount: _.get(data, 'discount'),
    payment: {
      method: {
        kind: _.get(data, 'payment.method.kind'),
        token: _.get(data, 'payment.method.token'),
        cvs_type: _.get(data, 'payment.method.cvs_type'),
        carrier_provider: _.get(data, 'payment.method.carrier_provider'),
      },
      point: data.payment.point ?
        {
          kind: _.get(data, 'payment.point.kind'),
          amount: _.get(data, 'payment.point.amount'),
        } :
        undefined,
    },
    salesOrderStatus: _.get(data, 'sales_order_status'),
    salesOrderStatusDetail: _.get(data, 'sales_order_status_detail'),
    shipmentOrder: _.get(data, 'shipment_order'),
    statusId: _.get(data, 'status_id'),
    tags: _.get(data, 'tags'),
    createdAt: _.get(data, 'created_at'),
    publishAt: _.get(data, 'publish_at'),
    updatedAt: _.get(data, 'update_at'),
    products: [],
    isPreOrder: _.get(data, 'tags', []).some(t => t.pathname === 'mycolor.salesorder.preorder'),
    isFreeShippingOnCatalogOnly: false,
    attachment: _.get(data, 'attachment', {}),
    item: convertToItem(_.get(data, 'item')),
    bundleDiscountedPrice: _.get(data, 'aux_long_2'),
    couponDiscountedPrice: _.get(data, 'aux_long_3'),
    estimatedPoints: _.get(data, 'aux_long_4'),
  }
}

export function convertToProducts(data: any[]): Product[] {
  return _.map(data, convertToProduct)
}

/**
 * Convert 'part' data of raw cart response to Product type
 */
export function convertToProduct(rawPart: any): Product {
  const product = new ApiProduct(rawPart)
  const belongingBrand = product.belongingBrand
  // FIXME 型定義に合わせるため、やむなくundefinedを利用
  const thumbnail = rawPart.thumbnail ? ThumbnailUrl.asSizeXS(rawPart.thumbnail).value : undefined

  return {
    id: rawPart.id,
    rootShortId: rawPart.rootShortId,
    thumbnail,
    unitPrice: _.get(rawPart, 'unitPrice'),
    totalPrice: _.get(rawPart, 'totalPrice'),
    tax: _.get(rawPart, 'tax'),
    unitCount: _.get(rawPart, 'unitCount') as number,
    skuId: _.get(rawPart, 'product_id'),
    fkuId: _.get(rawPart, 'product_class_id'),
    itemName: _.get(rawPart, 'product_name', 'NO NAME'),
    brandName: belongingBrand ? belongingBrand.name : 'NO BRAND',
    brandEnglishName: belongingBrand ? belongingBrand.englishName : null,
    productTag: _.get(rawPart, 'product_tag'),
    productclassTag: _.get(rawPart, 'productclass_tag'),
    isChild: false,
    isNovelty: false,
    isCatalogType: false,
  }
}

function convertToItem(raw: any): Item[]|undefined {
  if (raw === undefined) {
    return undefined
  }

  if (!Array.isArray(raw)) {
    return undefined
  }

  return raw.map((rawItem) => {
    return {
      cart_id: _.get(rawItem, 'cart_id'),
    }
  })
}
