import { log } from '@/log'
import { LegacyEngineSubscriptionHistory as LegacyHistoryDto } from '@/models/api/subscription/legacy-engine-subscription-history'
import { ApiClientWithAuth } from '@/services/api/client'
import { SubscriptionConfigResponse } from '@/services/api/subscription/config'
import { SubscriptionHistoryWhichIsEngineResourceViaLegacyProxy } from '@/services/api/subscription/legacy-history'
import { ApplyParams, Frequency, SubscriptionApply } from '@/services/api/subscription/subscription-apply'
import { SubscriptionPreset } from '@/services/api/subscription/subscription-preset'
import AppId from '@/util/appid'
import { toQuery } from '@spa/components/pages/order/scope-transpiler'
import { OrderPurchaseResult } from '@spa/services/order'
import { createPaymentParamBuilder } from '@spa/services/order/paymentParamBuilder'
import { Either, left, right } from 'fp-ts/lib/Either'

export class SubscriptionService
  implements SubscriptionPreset, SubscriptionApply, SubscriptionHistoryWhichIsEngineResourceViaLegacyProxy {
  constructor(
    private readonly apiClient: typeof ApiClientWithAuth = ApiClientWithAuth
  ) {}

  /**
   * 指定されたサブスクリプション契約の申込準備を行う.
   */
  async preset(params: { brandEnglishName: string, skuId: string }): Promise<Either<Error, null>> {
    const target = `/api/user/${params.brandEnglishName}/subscription/${params.skuId}`

    try {
      await this.apiClient.post(target)

      log.debug({
        service: SubscriptionService.name,
        target,
        error: null,
      })

      return right(null)
    } catch (error) {
      log.error({
        service: SubscriptionService.name,
        target,
        error,
      })

      return left(error)
    }
  }

  async apply(params: ApplyParams): Promise<OrderPurchaseResult> {
    try {
      // FIXME: OrderService.makeDeliveryParamと統合 or 上位モデル定義
      // 放置すると、単発注文とサブスクリプションを統合することや
      // 互いの関係性を示すことはおろか、単なる重複の排除すらも困難になりかねない
      const { deliveryType, id, deliveryMethod } = params.delivery
      const target = `/api/user/subscription`
      const param = {
        delivery_type: deliveryType,
        id,
        delivery_method: deliveryMethod,
      }

      await this.apiClient.post(`/api/user/subscription`, {
        cart_ids: params.order.cartItems.map(c => c.id).join(','),
        payment: createPaymentParamBuilder(params.payment).build(),
        delivery: JSON.stringify(param),
        frequency: params.frequency,
        scope: toQuery(params.scope),
      })

      log.debug({
        service: SubscriptionService.name,
        target,
        param,
      })

      return {
        success: true,
        order: params.order,
        error: null,
      }
    } catch (error) {
      log.error({ error })

      return {
        success: false,
        order: params.order,
        error,
      }
    }
  }

  // tslint:disable:max-line-length
  /**
   * 初期実装時点(at 2021/06/09)ではEngineリソースそのまま使うAPIを残す.
   * TODO: /api/user/subscription/contracted が実装されたので、可能ならそちらに統合.
   * 統合が難しい or 統合しない方が良いとなっても、それはそれとして、Engineリソースへの強依存は分離したい.
   *
   * @see https://my-color.esa.io/posts/589#%E5%AE%9A%E6%9C%9F%E8%B3%BC%E5%85%A5%E3%81%AE%E6%83%85%E5%A0%B1%E3%82%92%E5%8F%96%E5%BE%97
   * @deprecated
   */
  async historyWhichIsEngineResourceViaLegacyProxy(query: object): Promise<Either<Error, LegacyHistoryDto[]>> {
    try {
      const hostAppId = AppId.getHostId()
      const target = `/api/user-proxy/${hostAppId}/commerce_sales_subscription`
      const result = await this.apiClient.get(target, query)
      const data: any[] = result.body.data

      log.debug({ target, query })

      return right(data.map(LegacyHistoryDto.buildFromObject))
    } catch (error) {
      log.error({ error })

      return left(error)
    }
  }

  async edit(id: string, data: object): Promise<Either<Error, null>> {
    try {
      const target = `/api/user/subscription/contracted/${id}`
      await this.apiClient.put(target, data)

      log.debug({ target, data })

      return right(null)
    } catch (error) {
      log.error({ error })

      return left(error)
    }
  }

  async config(): Promise<Either<Error, SubscriptionConfigResponse>> {
    try {
      const target = '/api/common/subscription/config'
      const res = await this.apiClient.get(target)

      return right(res.body.data)
    } catch (error) {
      log.error({ error })

      return left(error)
    }
  }
}

// tslint:disable:max-line-length
/**
 * 現在日を毎月の配送日に、現在日時からある程度遅らせた時刻を時分に指定した、新しい配送頻度オブジェクトを生成する.
 * 「毎月の配送日」という表現からわかるように、 2021/08/30 および 定期購入v1 リリース時点では、配送頻度は月1固定である.
 * @deprecated v1での一時的な実装であり、最終的には廃止されるべき
 * @param now 現在日時
 *
 * @see https://my-color.esa.io/posts/638#%E3%82%B5%E3%83%96%E3%82%B9%E3%82%AF%E3%83%AA%E3%83%97%E3%82%B7%E3%83%A7%E3%83%B3%E5%A5%91%E7%B4%84%E6%A7%8B%E7%AF%89
 * @see https://github.com/my-color/front/issues/5573#issuecomment-908006154
 * @see https://my-color.esa.io/posts/613#%E9%85%8D%E9%80%81%E9%A0%BB%E5%BA%A6
 */
// tslint:enable:max-line-length
export function buildFrequencyWithSomeDelayedHourAndMinutesFrom(now: Date): Frequency {
  /*
   * docs記載のドキュメントを参照.
   * この「15分遅らせる」は特に確定したものでも強い根拠を伴うものでもなく、
   * 「このぐらい遅らせれば多分大丈夫"だろう"」という程度のものでしかない点に注意.
   */
  const delayMinutes = 15
  const delayed = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    now.getHours(),
    now.getMinutes() + delayMinutes
  )

  return {
    day: delayed.getDate(),
    hour: delayed.getHours(),
    minute: delayed.getMinutes(),
  }
}
