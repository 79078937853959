import BehaviorConfig from '@/models/app-config/behavior/behavior'
import { getImageUrl } from './image'

/**
 * ホテラバ環境のみ異なるパスから画像を取得する。
 * 複数の環境やブランドにおいて同様の要求が発生した場合、ここに書き足していくのでなく適切な設定方法に移行する。
 *  - 各々のブランドごとに画像の配置を必須にする
 *  - behavior.jsonで指定できるようにする
 *  - etc.
 */
const behavior = new BehaviorConfig()
const path = behavior.isHl ? 'hl/' : '/'

export const videoStartImagePath = getImageUrl(`${path}video-start.png`)
