import { ApiProduct } from '@/models/api/product'
import BehaviorConfig from '@/models/app-config/behavior/behavior'
import RootState from '@/models/item/root/state'
import SkuCanRequestRestockSpec from '@/models/item/sku/sale-status/specification/each/can-request-restock-spec'
import EachStateSpec from './each-state-spec'

/**
 * https://docs.google.com/spreadsheets/d/1ZvWEL9Q9FGgB24JKAjD8roi75KEHoT6uv7xMPhITDZI/edit#gid=2052072130
 */
export default class CanRequestRestockSpec implements EachStateSpec {
  constructor(private behavior: BehaviorConfig) { }
  /**
   * TODO: SKUオブジェクトを受け取って処理するようにする
   *
   * 現時点ではSKUのドメインオブジェクトに商品リクエストの知識が入っていないため
   */
  verify(products: ApiProduct[]): boolean {
    return products.some(prod => this.productIsOnRestockRequest(prod))
  }

  get state(): RootState {
    return RootState.CanRequestRestock
  }

  /**
   * FIXME: SKUオブジェクト自身に状態判定を持たせる
   */
  private productIsOnRestockRequest(product: ApiProduct): boolean {
    const skuIsOnRestockRequestSpec = new SkuCanRequestRestockSpec(this.behavior)

    return skuIsOnRestockRequestSpec.verify(product)
  }
}
