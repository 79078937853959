import BehaviorConfig from '@/models/app-config/behavior/behavior'
import Stock from '@/models/item/stock'
import DisplayRule, { ruleIsOnFew, ruleUseDisplayStock } from '@/models/item/stock/specification/display-rule'
import DisplayRuleSet from '@/models/item/stock/specification/display-rule/display-rule-set'
import {
  CoelNoDisplayDependOnWebStock
} from '@/models/item/stock/specification/display-rule/each-case/coel-no-display-depend-on-web-stock'
import DisplayNothing from '@/models/item/stock/specification/display-rule/each-case/display-nothing'
import NoDisplayAboutStock from '@/models/item/stock/specification/display-rule/each-case/display-nothing-about-stock'
import SoldOut from '@/models/item/stock/specification/display-rule/each-case/soldout'
import { CoelPolicy, MyColorDefaultPolicy } from './get-stock-status/policy'

export default class GetStockStatus {
  static createViaConfig(
    behaviorConfig: BehaviorConfig
  ): GetStockStatus {
    const policy = behaviorConfig.isDMI
      ? CoelPolicy.createViaConfig(behaviorConfig)
      : MyColorDefaultPolicy.createViaConfig(behaviorConfig)

    return policy.create()
  }

  constructor(private readonly displayRuleSet: DisplayRuleSet) { }

  toText(stock: Stock): string {
    return this.findApplicativeRule(stock).apply(stock)
  }

  isFew(stock: Stock): boolean {
    const rule = this.findApplicativeRule(stock)

    return ruleIsOnFew(rule)
  }
  isSoldOut(stock: Stock): boolean {
    const rule = this.findApplicativeRule(stock)

    return rule instanceof SoldOut
  }
  isNormal(stock: Stock): boolean {
    const rule = this.findApplicativeRule(stock)

    return rule instanceof DisplayNothing
      || rule instanceof NoDisplayAboutStock
      || rule instanceof CoelNoDisplayDependOnWebStock
  }
  exists(stock: Stock): boolean {
    return !this.isSoldOut(stock)
  }

  amount(stock: Stock): number {
    const rule = this.findApplicativeRule(stock)
    const shouldUseDisplayStock = stock.display.isEnable && ruleUseDisplayStock(rule)

    if (shouldUseDisplayStock) {
      return stock.display.amount
    }

    return stock.web.amount
  }

  private findApplicativeRule(stock: Stock): DisplayRule {
    return this.displayRuleSet.find(stock)
  }
}
