import _ from 'lodash'

import { ApiProductclass } from '@/models/api/productclass'
import ThumbnailUrl from '@/models/item/thumbnail-url'
import { log } from '../log'
import { TagsService } from './api/tags'
import { findTag } from './tag'

export class OrderHistoryService {

  tagsService: TagsService

  constructor(appId?: string) {
    this.tagsService = new TagsService(appId)
  }

  // Inject with joined product/productclass info
  async injectWithJoinedProductInfo(items, joined) {

    await Promise.all(_.map(items, async (item) => {
      try {
        const entry = _.find(joined, ['id', item.id])

        // thumbnail // TODO: この商品は取り扱っていません画像
        item.thumbnail = this.getThumnail(entry.fku ? entry.fku.images : [])

        item.startAt = entry.sku.start_at

        // size
        if (entry.sku.tags) {
          item.size = this.getSize(entry.sku)
        } else {
          const productTag = _.map(item.productTag.split(','), (tag) => {
            return { pathname: tag }
          })
          const sizeTag = findTag(productTag, ['company', 'product', 'size'])
          item.size = await this.getTagTitle(sizeTag.pathname, 'company.product.size')
        }

        // color
        if (entry.fku) {
          item.color = this.getColor(entry.fku)
        } else {
          const productclassTag = _.map(item.productclassTag.split(','), (tag) => {
            return { pathname: tag }
          })
          const colorTag = findTag(productclassTag, ['company', 'product', 'color'])
          item.color = await this.getTagTitle(colorTag.pathname, 'company.product.color')
        }

        // brand name
        if (entry.root) {
          const root = new ApiProductclass(entry.root)

          item.rootId = root.shortId
          item.brandEnglishName = root.belongingBrand ? root.belongingBrand.englishName : null
          item.brandName = root.belongingBrand ? root.belongingBrand.name : null
          item.alphabeticColor = this.getColor(entry.fku, 'name')

          item.isChild = root.isChild
        } else {
          item.brandName = '-'
        }

        item.isCatalogType =  entry.root.tags.some(t => t.pathname === 'mycolor.product.catalog')
        item.isNovelty =  entry.root.tags.some(t => t.pathname === 'mycolor.product.novelty')
        item.ineligibleForCoupon = entry.root.tags.some(t => t.pathname === 'mycolor.product.ineligible.coupon')

      } catch (error) {
        log.error(error)
      }

    }))

    return items
  }

  getSize(item: any): string {
    const tagNameSpace = ['company', 'product', 'size']

    const tag = findTag(item.tags, tagNameSpace)

    if (!tag) {
      return ''
    }

    return tag.title
  }

  getColor(item: any, type: string = 'title'): string {
    const tagNameSpace = ['company', 'product', 'color']

    const tag = findTag(item.tags, tagNameSpace)

    if (!tag) {
      return ''
    }

    switch (type) {
      case 'title':
        return tag.title
      case 'name':
        return tag.name
      default:
        return tag.title
    }

  }

  getThumnail(images: any[]): string | null {
    const noImage = '/assets-proxy/images/non-publish.jpg'

    const url = _.get(_.minBy(images, 'display_level'), 'url')
    if (!url) {
      const firstUrl = images.length > 0  ? _.get(images[0], 'url') : null

      return firstUrl ? ThumbnailUrl.asSizeXS(firstUrl).value : null
    }

    return ThumbnailUrl.asSizeXS(url).value || noImage
  }

  async getTagTitle(nameTag: string, pathname: string) {

    const result = await this.tagsService.get(pathname)

    if (result.isLeft()) {
      log.error(result)

      return ''
    }
    const taglist = result.value

    const targetTag = _.find(taglist, (tag) => {
      return tag.pathname === nameTag
    })

    if (!targetTag) {
      log.error(`not found tag with following pathname: ${pathname}`)

      return ''
    }

    return targetTag.title
  }
}
