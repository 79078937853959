import superagent from 'superagent'
import { ActionTree } from 'vuex'

import { log } from '@/log'
import { RootState } from '../../types'

import { SearchContactLensState } from './types'

export const actions: ActionTree<SearchContactLensState, RootState> = {
  // CSVデータをロードする.
  async load({ commit, state }, payload) {
    try {
      // すでに読み込まれている場合はそのまま.
      if (state.csvData !== null) {
        return
      }

      // CSVデータをHTTPで取得.
      const contentType = 'text/csv'
      const url = payload['url']
      log.debug(`searchContactLens/load: ${url}`)
      const res = await superagent.get(url).accept(contentType)
      if (!res.ok) {
        throw new Error(`connection failed: ${res}`)
      } else if (res.type !== contentType) {
        throw new Error(`expected content-type is '${contentType}', but received '${res.type}'`)
      }

      // テキストデータを行ごとに分割.
      const rows = res.text.split('\n')

      // 各行を列ごとに分割.
      let csvData: string[][] = new Array<string[]>()
      for (const row of rows) {
        const columns = row.split(/,(?=(?:[^"]*"[^"]*")*[^"]*$)/g, -1)
        csvData.push(columns)
      }

      // 行末は削除.
      csvData.pop()

      // 並び順を逆順にする(新しいのが上に来るように?)
      csvData = csvData.reverse()

      // ヘッダ行は除く.
      const header = csvData.pop()
      log.debug(`header: ${header}`)

      // 保存.
      commit('setCsvData', csvData)
      log.debug(csvData)

      return
    } catch (err) {
      // CSVデータ取得失敗.
      log.error(`load csv failed: ${err}`)

      return
    }
  },
}
