




import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import { log } from '@/log'
import { CookieService } from '@ajax/modules/cookie'
import { AutoLoginService } from '@ajax/modules/services/auto-login'

@Component
export default class AutoLoginTrigger extends Vue {
  /* Supposed to be injected from router */
  @Prop({ required: true })
  userKey: string

  /* Supposed to be injected from router */
  @Prop({ required: true })
  hash: string

  /* Supposed to be injected from php template */
  @Prop({ required: true })
  site: string

  get autoLoginService(): AutoLoginService {
    return new AutoLoginService()
  }

  async created() {
    if (!this.userKey || !this.hash || !this.site) {
      return
    }

    if (CookieService.isRegistered()) {
      log.debug('auto-login skipped because user already logged in.')

      return
    }

    const autoLoginResult = await this.autoLoginService.login(this.userKey, this.hash, this.site)

    if (autoLoginResult.isLeft()) {
      log.debug('auto-login failed.')

      return
    }

    log.debug('auto-login succeeded.')
  }
}
