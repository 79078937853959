import _ from 'lodash'
import queryString from 'query-string'

import { InstanceResolver } from './instanceResolver'

export class BrandRouteResolver {
  public static resolveBrandFromPathElement(brandPathElement: string): string {
    if (InstanceResolver.isSingle()) {
      return InstanceResolver.getSingleBrand()
    }

    return brandPathElement
  }

  public static makeResourceEndpoint({
    brand,
    path,
    query,
    withSpaPrefix = false,
  }: ResourceEndpointInfo): string {
    const resolvedBrand = BrandRouteResolver.resolveBrandToPathElement(brand)

    const pathStr = _.compact([
      withSpaPrefix ? 's' : '',
      resolvedBrand,
      ...path,
    ]).join('/')

    if (!query) {
      return `/${pathStr}`
    }

    const queryStr = queryString.stringify(query)

    return `/${pathStr}?${queryStr}`
  }

  private static resolveBrandToPathElement(brand: string): string {
    if (InstanceResolver.isMulti()) {
      return brand
    }

    if (brand === InstanceResolver.getSingleBrand()) {
      return ''
    }

    return brand
  }
}

interface ResourceEndpointInfo {
  brand: string,
  path: string[],
  query?: object,
  withSpaPrefix?: boolean
}
