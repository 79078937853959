



























































































































































































































import Vue from 'vue'
import { Mixin } from 'vue-mixin-decorator'
import { Prop } from 'vue-property-decorator'

import { columnNameToIndexMap } from '../../../../store/modules/searchContactLens/functions'

@Mixin
export default class SearchSysCondCommon extends Vue {
  @Prop()
  title: string

  @Prop({ default: '/' })
  topUrl: string

  mounted() {
    const callAddCond = (key, value) => {
      this.addCond(key, value)
    }

    const callRemoveCond = (key) => {
      this.removeCond(key)
    }

    const id = this.$el.id

		// 各ボタンを押した時の処理
    $(`#${id} .search_sys_body_menu_sec_menu ul li`).on('click', function () {
      // 同じボタンか違うボタンか.
      const isSameButton = $(this).attr('class') === 'search_sys_body_menu_sec_menu_selected'

      // カテゴリ取得.
      const category = $(this).parent().attr('id')
      const key = columnNameToIndexMap[category!]

      // ボタンのトグル.
      if (isSameButton) {
        // 同じボタンの場合, 条件を外す.
        callRemoveCond(key)
        $(this).removeClass('search_sys_body_menu_sec_menu_selected')
      } else {
        // 違う場合.
        const value = $(this).attr('id')
        callAddCond(key, value)
        const parent = $(this).parent()
        parent.children('li').each(function () { $(this).removeClass('search_sys_body_menu_sec_menu_selected') })
        $(this).addClass('search_sys_body_menu_sec_menu_selected')
      }
    })

		// さらに詳細を検索するボタンを押した時の処理
    $(`#${id} .search_sys_body_menu_sec_detail_button`).on('click', function () {
      $(this).next().slideToggle()
      $(this).toggleClass('active')

      if ($(this).hasClass('active')) {
        $(`#${id} .search_sys_body_menu_sec_detail_button img`)
          .attr('src', '/assets-proxy/images/hotellovers/afcd60f8dad5002c01b3ec6dfd7864ca.png')
      } else {
        $(`#${id} .search_sys_body_menu_sec_detail_button img`)
          .attr('src', '/assets-proxy/images/hotellovers/a073a806042b70c54f47bfd53552ff2a.png')
      }
    })
  }

  addCond(key: string, value: string) {
    this.$emit('addCond', key, value)
  }

  removeCond(key: string) {
    this.$emit('removeCond', key)
  }
}
