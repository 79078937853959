



















import _ from 'lodash'
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import PostReviewModal from './PostReviewModal.vue'

import { log } from '@/log'

@Component({
  components: {
    PostReviewModal,
  },
})
export default class OpenPostReviewModalButton extends Vue {

  isError: boolean = false

  showModal: boolean = false

  message: string

  @Prop({ required: true })
  id: string

  @Prop({ required: true })
  brand: string

  toggle() {
    this.showModal = !this.showModal
  }

  showError(error) {
    this.isError = true
    this.message = 'エラーが発生しました。※改善されない場合、お問い合わせよりカスタマーセンターまでお問い合わせください。'
    log.error(error)
  }
}
