import _ from 'lodash'

import { ApiProductclass } from '@/models/api/productclass'
import BehaviorConfig from '@/models/app-config/behavior/behavior'
import Root from '@/models/item/root'
import RootStateSpec from './state/specification/root-state-spec'

export default class RootFactory {
  static createViaConfig(behavior: BehaviorConfig): RootFactory {
    return new RootFactory(
      RootStateSpec.createViaConfig(behavior)
    )
  }

  constructor(private readonly stateSpec: RootStateSpec) {}

  createFromFkuList(records: ApiProductclass[]): Root {
    const products = _.flatten(records.map(r => r.parts))
    const state = this.stateSpec.select(products)

    return new Root(state)
  }
}
