






















import AddItemToCart, { GetSkuPriceOnClick } from '@/components/organisms/cart/add-item/AddItemToCart.vue'
import { log } from '@/log'
import { SkuPrice } from '@/models/item/sku'
import { DefaultFetchSkuPrice } from '@/models/item/sku/repository/impl/fetch-sku-price'
import { BaseError } from '@/util/base-error'
import { AddToCartErrorEvent } from '@ajax/vue/components/organisms/addtocart/view-models/event'
import { identity } from 'fp-ts/lib/function'
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import ErrorDialog from '@/components/organisms/dialog/ErrorDialog.vue'
import { BrandRouteResolver } from '@/util/brandRouteResolver'

interface UserNotification {
  message: string
}

class NotifiableError extends BaseError {
  constructor(
    message: string,
    readonly notification: UserNotification = notifyUnrecoverable
  ) {
    super(message)
  }
}
const notifyUnrecoverable: UserNotification = {
  message: `
  エラーが発生したため、カート追加できませんでした。<br/>
  ※少し時間をおいてお試しいただくか、改善されない場合、お問い合わせよりカスタマーセンターまでお問い合わせください。
  `,
}

@Component({
  components: {
    ErrorDialog,
    /**
     * <add-item-to-cart>で登録するとフリーコンテンツ用に提供しているglobalタグと衝突するので、
     * ローカルでは名前を調節している.
     * @see client/src/components/global/index.ts
     * @see AddItemToCartViaExternalSystem
     */
    AddItemToCartFromList: AddItemToCart,
  },
})
export default class AddToCartButtonBySku extends Vue {
  @Prop({ required: true })
  readonly skuId: string

  buttonId: string | null = null
  userNotification: UserNotification | null = null

  async created() {
    this.buttonId = await this.$store.dispatch('cartButtonInItemList/publishNewButtonId')
  }

  get price(): GetSkuPriceOnClick {
    return async () => {
      try {
        return this.fetchSkuPriceForTheSKU()
      } catch (e) {
        log.error(`failed to get price to add ${this.skuId} to cart because ${e.message}`, e)

        if (e instanceof NotifiableError) {
          this.userNotification = e.notification
        } else {
          this.userNotification = notifyUnrecoverable
        }

        throw e
      }
    }
  }

  onStartCartProcess() {
    this.$store.dispatch('cartButtonInItemList/startProcess', { buttonId: this.buttonId })
  }
  onStopCartProcess() {
    this.$store.dispatch('cartButtonInItemList/stopProcess')
  }
  onErrorCartProcess(ev: AddToCartErrorEvent) {
    log.error(`could not add ${this.skuId} to cart`, ev)

    this.userNotification = {
      message: ev.message,
    }
  }
  initUserNotification() {
    this.userNotification = null
  }

  get isDisabled(): boolean {
    return this.$store.getters['cartButtonInItemList/isLocked'](this.buttonId)
  }

  private async fetchSkuPriceForTheSKU(): Promise<SkuPrice> {
    const fetchSkuPrice = DefaultFetchSkuPrice.create(this.brandName)

    return fetchSkuPrice.fetchById(this.skuId).then(result => result.fold(
      (error: Error) => {
        log.error(`failed to fetch sku price for ${this.skuId}`, error)

        throw new NotifiableError(error.message)
      },
      identity
    ))
  }

  private get brandName(): string {
    const brandEnglishName = this.$route.params.brand_english_name

    return BrandRouteResolver.resolveBrandFromPathElement(brandEnglishName)
  }
}
