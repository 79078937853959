import AbstractValue from '@/models/app-config/behavior/abstract-value'
import ItemBadge, { RawItemBadge } from '@/models/app-config/behavior/config/item-list/item/badge'
import ItemDetail, { RawItemDetail } from '@/models/app-config/behavior/config/item-list/item/detail'
import {
  ItemUnderMaintenance,
  RawItemUnderMaintenance
} from '@/models/app-config/behavior/config/item-list/item/under-maintenance'

export interface RawItemOnItemList {
  badge: RawItemBadge
  detail: RawItemDetail
  showAddToCartButton?: boolean
  underMaintenance?: RawItemUnderMaintenance
}

export default class ItemOnItemList extends AbstractValue<RawItemOnItemList> {
  get badge(): ItemBadge {
    return new ItemBadge(this.raw.badge)
  }

  get detail(): ItemDetail {
    return new ItemDetail(this.raw.detail)
  }

  get showAddToCartButton(): boolean {
    return this.raw.showAddToCartButton || false
  }

  get underMaintenance(): ItemUnderMaintenance {
    return new ItemUnderMaintenance(this.raw.underMaintenance || {})
  }
}
