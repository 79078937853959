import _ from 'lodash'

import { ApiCoupon } from '@/models/api/coupon'

const couponCodeKey = 'aux_string_5'

/**
 * クーポンの中でも、クーポンコードを持つものを表すクラス。
 * ドメインモデルというよりは、serverからの生データを元にビジネスロジックが結合したものになっている。
 * ApiCouponと同様、過渡期の存在として、適宜リファクタしていく。
 */
export class CouponCode extends ApiCoupon {
  get couponCode(): string {
    return this.get(couponCodeKey)
  }
}

export function hasCouponCode(raw: any): boolean {
  return !!_.get(raw, couponCodeKey)
}
