



























































































































import { ContextToCreateUrl, createItemListUrl, createPageUrl } from '@/page/url/factory/url-factory'
import { Component, Mixins, Prop } from 'vue-property-decorator'

import SwitchByUa from '@/components/atoms/SwitchByUa.vue'
import HostPageLinkMixin from '@/mixin/HostPageLink'
import BehaviorConfig from '@/models/app-config/behavior/behavior'
import { SlideMenuItem } from '@/models/app-config/behavior/config/slide-menu'
import AccountLink from '@ajax/vue/components/organisms/slide-menu/AccountLink.vue'
import CategoryList from '@ajax/vue/components/organisms/slide-menu/CategoryList.vue'
import GenreList from '@ajax/vue/components/organisms/slide-menu/GenreList.vue'
import { SlideMenuState } from '@spa/components/organisms/models/ec-header/slide-menu-state'

@Component({
  components: {
    AccountLink,
    CategoryList,
    GenreList,
    SwitchByUa,
  },
  mixins: [
    HostPageLinkMixin,
  ],
})
export default class SlideMenu extends Mixins<HostPageLinkMixin>(HostPageLinkMixin) {
  @Prop({ required: true })
  readonly context: ContextToCreateUrl

  @Prop({ required: true })
  readonly state: SlideMenuState

  @Prop({ required: true })
  readonly isBrandPage: boolean

  @Prop({ required: true })
  readonly brandName: string

  @Prop({ required: true })
  readonly behaviorConfig: BehaviorConfig

  get slideMenuItem(): typeof SlideMenuItem {
    return SlideMenuItem
  }

  slideEnter () {
    (this.$refs['content'] as Element).scrollTop = 0
  }

  showSlideMenu(item: SlideMenuItem): boolean {
    return this.behaviorConfig.slideMenu.allow(item)
  }

  getBrandPagePath(page: string): string {
    return createPageUrl(this.context, page)
  }

  get itemListPath(): string {
    return createItemListUrl(this.context)
  }

  get slideMenuStyle(): { top: string, height: string } {
    return {
      top: `${this.state.position.top}px`,
      height: `calc(100vh - ${this.state.position.top}px)`,
    }
  }
}
