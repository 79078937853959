import { Either, Left, Right } from 'fp-ts/lib/Either'
import { Response } from 'superagent'

import { log } from '@/log'
import { ApiClient } from './client'
import { ApiService } from './service'

const namespace: string = 'preview'

export default class PreviewService extends ApiService {
  async disable(): Promise<Either<Error, Response>> {
    try {
      const response = await ApiClient.delete('/api/preview')

      log.debug({ service: `${namespace}/disable`, response })

      return new Right(response)
    } catch (error) {
      log.error({ error })

      return new Left(error)
    }
  }
}
