import {
  CreditcardService,
  CreditcardServiceForPurchase,
  CreditcardToBuildPaymentPayload,
} from '@/services/api/creditcard'
import {
  CreditcardServiceForSubscription
} from '@/services/api/subscription/payment/creditcard-service-for-subscription'
import { Either, Left, Right } from 'fp-ts/lib/Either'
import _ from 'lodash'

import { log } from '@/log'

import {
  CreditcardPaymentProviderService,
  CreditcardPaymentProviderServiceForPurchase, CreditcardPaymentProviderServiceForSubscription,
} from '@/services/payment/creditcard-payment-provider/creditcard-payment-provider-service'
import { DefaultCreditcardPayment } from '@/services/payment/types'

const namespace: string = 'paygent'

const merchantId = process.env.PAYGENT_MERCHANT_ID
const tokenGenerateKey = process.env.PAYGENT_TOKEN_GENERATE_KEY
const scriptSrc = process.env.PAYGENT_SCRIPT || ''

abstract class PaygentService extends CreditcardPaymentProviderService {
  readonly scriptSrc = scriptSrc
  readonly namespace = namespace

  readonly registerCardLimit = null

  protected abstract readonly creditcardService: CreditcardService

  get isScriptLoaded(): boolean {
    return !!PaygentToken
  }

  async createRegisterCardPayload(
    creditcard: {
      cardNumber: string
      expireYear: string
      expireMonth: string
      securityCode: string
    }
  ): Promise<Either<Error, string>> {
    const result = await this.createToken(creditcard)

    if (result.isLeft()) {
      return new Left(result.value)
    }

    return new Right(result.value)
  }

  async createPaymentPayload(
    creditcard: CreditcardToBuildPaymentPayload
  ): Promise<Either<Error, DefaultCreditcardPayment>> {
    return new Right({
      ...this.creditcardService.buildPaymentPayload(creditcard),
      token: creditcard.id,
    })
  }

  private async createToken(payload: {
    cardNumber: string,
    expireYear: string,
    expireMonth: string,
    securityCode: string,
    name?: string,
  }): Promise<Either<Error, string>> {
    try {
      const result = await this._createToken(payload)

      log.debug({ service: `${namespace}/createToken`, result })

      return new Right(_.get(result, 'tokenizedCardObject.token'))
    } catch (error) {
      log.error({ service: `${namespace}/createTokens`, error })

      return new Left(error)
    }
  }

  // tslint:disable-next-line:function-name
  private async _createToken(payload: {
    cardNumber: string,
    expireYear: string,
    expireMonth: string,
    securityCode: string,
    name?: string,
  }): Promise<any> {
    if (!this.isScriptLoaded) {
      return Promise.reject(new Error('script is not loaded.'))
    }

    const paygentToken = new PaygentToken()

    const { cardNumber, securityCode, expireMonth, expireYear, name } = payload

    return new Promise((resolve, reject) => {
      try {
        paygentToken.createToken(
          merchantId,
          tokenGenerateKey,
          {
            card_number: cardNumber,
            expire_year: expireYear,
            expire_month: expireMonth,
            cvc: securityCode,
            name,
          },
          (response) => {
            if (response.result !== '0000') {
              reject(response)
            }
            resolve(response)
          }
        )
      } catch (err) {
        reject(err)
      }
    })
  }
}

export class PaygentServiceForPurchase
  extends PaygentService
  implements CreditcardPaymentProviderServiceForPurchase {

  readonly _context = 'for-purchase'

  protected creditcardService = new CreditcardServiceForPurchase()
}

export class PaygentServiceForSubscription
  extends PaygentService
  implements CreditcardPaymentProviderServiceForSubscription {

  readonly _context = 'for-subscription'

  protected creditcardService = new CreditcardServiceForSubscription()
}
