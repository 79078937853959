import { CurrentUser } from '@/services/api/current-user'
import { Either } from 'fp-ts/lib/Either'

/**
 * TODO: リリースを優先し、PRでの議論をいったん保留にしマージしている
 *
 * インタラクションではなく現在のユーザーのクラスとして実装し、
 * オブジェクト自身に有料会員であるかを判定するメソッドをもたせたほうが良いか
 * @see https://github.com/my-color/front/pull/6253#discussion_r835813826
 */
export class PaidMembershipInteraction {
  constructor(
    readonly brandEnglishName: string,
    private readonly currentUserService: CurrentUser = new CurrentUser()
  ) {}

  async isPaidMember(): Promise<Either<Error, boolean>> {
    const result = await this.currentUserService.getCurrentUser()

    return result.map(u => u.membership.enabled && u.membership.registered_plans.length > 0)
  }
}
