import { ApiImage } from '../../api/image'
import { BaseImageSorter } from './index'

import _ from 'lodash'

/**
 * 画像をdisplay_levelの昇順にソートする。
 * 将来的にもし必要であれば、昇順と降順を選択できるようにする。
 */
export default class SortImagesByDisplayLevel extends BaseImageSorter {
  sort(images: ApiImage[]): ApiImage[] {
    return _.sortBy(images, (image: ApiImage) => image.displayLevel)
  }
}
