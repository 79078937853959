export enum ErrorType {
  ParseErrorInClient = 'ParseErrorInClient',
  UnitCountExceededError = 'UnitCountExceededError',
  NotInStoreError = 'NotInStoreError',
  NotOnSaleError = 'NotOnSaleError',
  MustWithinSKUPurchaseLimit = 'MustWithinSKUPurchaseLimit',
  MustWithinRootPurchaseLimit = 'MustWithinRootPurchaseLimit',
  MustWithinLimitTimesOfPurchase = 'MustWithinLimitTimesOfPurchase',
  MustWithoutMixedPrice = 'MustWithoutMixedPrice',
}

export interface ApiError {
  type: ErrorType,
  text: string,
}

export interface ParseErrorInClient extends ApiError {
  type: ErrorType.ParseErrorInClient,
}

export interface UnitCountExceededError extends ApiError {
  type: ErrorType.UnitCountExceededError
}
