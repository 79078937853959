



























import Vue from 'vue'
import { Component, Emit, Prop } from 'vue-property-decorator'

import BundlePurchaseItemDetail from '@/components/molecules/bundle-purchase/BundlePurchaseItemDetail.vue'
import { log } from '@/log'
import LockBackground from '@/mixin/LockBackground'
import { ApiProductclass } from '@/models/api/productclass'
import { BandImageHelper, BandImageHelperBuilder } from '@/models/api/productclass/band-url'
import {
  BundlePurchaseItem,
  BundlePurchaseRootItem,
} from './model/bundle-purchase-item'
import LoadRootFromFku from './model/command/initialize/load-root-from-fku'
import { AddToPurchaseListSpec } from './model/specification/add-to-purchase-list'

@Component({
  components: {
    BundlePurchaseItemDetail,
  },
  mixins: [
    LockBackground,
  ],
})
export default class BundlePurchaseModal extends Vue {
  @Prop({ required: true })
  fkuItem: ApiProductclass

  @Prop({ required: true })
  brand: string

  @Prop({ required: true })
  addToPurchaseListSpec: AddToPurchaseListSpec

  root: BundlePurchaseRootItem | null = null

  bandImageHelperBuilder: BandImageHelperBuilder | null

  async created() {
    try {
      const bandImageHelperBuilder = await BandImageHelper.builder()
      const helper = bandImageHelperBuilder.build(this.fkuItem, this.brand)

      const loadRootFromFku = LoadRootFromFku
        .with(this.brand, this.fkuItem, helper)

      await loadRootFromFku.run().then(result => result.fold(
        error => Promise.reject(error),
        root => Promise.resolve(this.root = root)
      ))
    } catch (error) {
      log.error({ error })

      this.onClose()
      this.onError()
    }
  }

  get isLoading(): boolean {
    return this.root === null || this.bandImageHelperBuilder === null
  }

  /**
   * スクロール禁止範囲を指定するため
   * @see client/src/mixin/LockBackground.ts
   */
  get scrollable(): HTMLElement {
    return this.$refs.scrollable as HTMLInputElement
  }

  @Emit('error')
  onError() {
    // Do nothing
  }

  @Emit('addItem')
  onAddItem(_skuItem: BundlePurchaseItem) {
    // Do nothing
  }

  @Emit('close')
  onClose() {
    // Do nothing
  }
}
