import { assertAppIdMustUnique } from './assertions'

/**
 * @internal この定義を直接使うのではなく、AppIdモジュールを経由する想定
 */
export const hostAppIdMap = assertAppIdMustUnique({
  mycolor: 'MyColorWeb',
  ledian: 'MallLedianWeb',
  mypalette: 'MyPaletteWeb',
  hl: 'MallHOTELLOVERSWeb',
  rily: 'MallRilyWeb',
  fitowa: 'MallfitowaWeb',
})
export type HostAppIdMap = typeof hostAppIdMap
export type HostName = keyof HostAppIdMap

/**
 * @internal この定義を直接使うのではなく、AppIdモジュールを経由する想定
 */
export const brandAppIdMap = assertAppIdMustUnique({
  micoameri: 'MICOAMERIWeb',
  gadgetgirl: 'GadgetGirlWeb',
  michellmacaron: 'michellMacaronWeb',
  coel: 'COELWeb',
  amiuuwink: 'AmiuuwinkWeb',
  rady: 'RadyWeb',
  regalect: 'RegalectWeb',
  beigebeige: 'BeigeBeigeWeb',
  missmine: 'missmineWeb',
  inframince: 'inframinceWeb',
  tiarecloset: 'tiareclosetWeb',
  chouettelatte: 'chouettelatteWeb',
  edna: 'ednaWeb',
  cherimi: 'cherimiWeb',
  hl: 'HOTELLOVERSWeb',
  'off-tone': 'off-toneWeb',
  lacharme: 'lacharmeWeb',
  okashiya: 'okashiyaWeb',
  rily: 'RilyWeb',
  fitowa: 'fitowaWeb',
  ledian: 'LedianWeb',
  mezame: 'mezameWeb',
})
export type BrandAppIdMap = typeof brandAppIdMap
export type BrandName = keyof BrandAppIdMap

export type AppIdMap = HostAppIdMap | BrandAppIdMap
