









































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import { ApiProductclass } from '@/models/api/productclass'
import BehaviorConfig from '@/models/app-config/behavior/behavior'
import ItemBadge from './ItemBadge.vue'
import Badge from './model/badge'
import { createBadgeList } from './model/badge-list/factory'

@Component({
  components: {
    ItemBadge,
  },
})
export default class ItemBadgeList extends Vue {
  static readonly COMMON_BADGE_CLASS = 'p-goods__badge'

  @Prop({ required: true })
  fku: any // TODO: ApiProductclassを受け取るようにする

  @Prop({ required: true })
  behavior: BehaviorConfig

  get coelBadgeClass() {
    if (this.badgeConfigs.length === 0) {
      return ['p-goods__badge-list--empty']
    }
    const common = ['p-goods__badge-item']

    return common
  }

  get listClass() {
    if (this.badgeConfigs.length === 0) {
      return ['p-goods__badge-list--empty']
    }

    const common = ['p-goods__badge-list']
    if (this.badgeConfigs.length !== 1) {
      return common
    }
    /*
     * 「特定のバッジしか表示されない時、特殊なスタイルを適用する」という要件に対応するため、
     * 表示するバッジ内容が1件の時は、その状態を表現するためのクラス(${singleClass}--only)を付与.
     *
     * ex. COELのsoldout → COELで売り切れバッジのみの場合、グレー背景に白抜き文字にする
     */
    const badgeConfig = this.badgeConfigs[0]
    const singlePrimaryClass = badgeConfig.primaryClass
    const classForSpecialStyleWhenSingleBadgeShown = `${singlePrimaryClass}--only`

    return [
      ...common,
      classForSpecialStyleWhenSingleBadgeShown,
      ...badgeConfig.optionClasses,
    ]
  }

  get badgeConfigs(): Badge[] {
    return createBadgeList(new ApiProductclass(this.fku), this.behavior)
  }
}
