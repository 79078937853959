




































import Vue from 'vue'
import { Component } from 'vue-property-decorator'

import LockBackground from '@/mixin/LockBackground'

@Component({
  mixins: [
    LockBackground,
  ],
})
export default class BaseConditionModal extends Vue {
  $refs: {
    scrollable: HTMLElement
  }

  get scrollable(): HTMLElement {
    return this.$refs.scrollable
  }

  close() {
    this.$emit('close')
  }
  ok() {
    this.$emit('ok')
  }
}
