


































import _ from 'lodash'
import { Mixins } from 'vue-mixin-decorator'
import { Component } from 'vue-property-decorator'

import AppId from '@/util/appid'
import { BrandRouteResolver } from '@/util/brandRouteResolver'
import Util from '@ajax/modules/util'
import ApiMixin from '@ajax/vue/mixin/Api'

@Component
export default class SearchCategory extends Mixins<ApiMixin>(ApiMixin) {

  categories: any[] = []

  selectedCategories: any[] = []

  async mounted () {
    const path = this.categoryPathname
    const response = await this.$get(`/api/common-proxy/${this.appId}/tags`, {
      pathname: path,
      scope: 'tree',
    })
    if (response.body.success) {
      this.categories = response.body.data.children
      this.injectPathName()
      this.reflectUrlParams()
    }
  }

  get categoryPathname(): string {
    if (this.isBrandPage) {
      return `company.product.category.${this.brandEnglishName}`
    }

    return 'mycolor.product.category'
  }

  get isBrandPage(): boolean {
    return !!this.brandEnglishName
  }

  get brandEnglishName(): string {
    return BrandRouteResolver.resolveBrandFromPathElement(this.$route.params.brand_english_name)
  }

  get appId(): string {
    return AppId.getByBrandName(this.brandEnglishName)
  }

  get selectedCategoriesString (): string {
    return this.selectedCategories.length === 0 ? '指定なし' : _.map(this.selectedCategories, 'title').reverse().join('／')
  }

  isCategorySelected (category) {
    return _.find(this.selectedCategories, { id: category.id }) !== undefined
  }

  onClickCategory (category, parentCategory) {
    this.toggleCategory(category)

    // 中カテゴリ検索時には大カテゴリの検索をしない
    if (parentCategory) {
      this.deselectCategory(parentCategory)
    } else {
      this.deselectSubCategories(category)
    }

  }

  toggleCategory (category) {
    const index = this.getSelectedCategoryIndex(category)
    if (index !== - 1) {
      this.selectedCategories.splice(index, 1)
    } else {
      this.selectedCategories.push(category)
    }
  }

  deselectCategory (category) {
    const index = this.getSelectedCategoryIndex(category)
    if (index !== - 1) {
      this.selectedCategories.splice(index, 1)
    }
  }

  deselectSubCategories (category) {
    category.children.forEach(this.deselectCategory)
  }

  getSelectedCategoryIndex (category) {
    return _.map(this.selectedCategories, 'id').indexOf(category.id)
  }

  injectPathName () {
    this.categories.forEach((category) => {
      category.pathName = category.name
      category.children.forEach((child) => {
        child.pathName = `${category.name}.${child.name}`
      })
    })
  }

  reflectUrlParams () {
    const urlParams = Util.convertItemlistUrlParamsToAssociativeArray()
    if (urlParams.category) {
      const categories = urlParams.category.split(',') as string[]
      this.addSelectedCategoriesByPathNames(categories)
    }
  }

  addSelectedCategoriesByPathNames (categoryPathNames) {
    this.selectedCategories = _.compact(
      categoryPathNames.map(categoryPathName => this.findCategoryByPathName(categoryPathName))
    )
  }

  findCategoryByPathName (pathName) {
    let result // TODO できれば const にする
    this.categories.some((category) => {
      if (category.pathName === pathName) {
        result = category

        return true
      }

      return category.children.some((child) => {
        if (child.pathName === pathName) {
          result = child

          return true
        }
      })
    })

    return result
  }

}
