import {
  CreditcardForSubscriptionRepository,
  FrontApiRepositoryForSubscription,
} from '@/models/payment/creditcard/context/for-subscription/repository'
import {
  CreditcardServiceForSubscription,
} from '@/services/api/subscription/payment/creditcard-service-for-subscription'
import { getCreditcardPaymentProvierServiceConfig } from '@/services/payment'
import Vue from 'vue'
import { Mixin } from 'vue-mixin-decorator'

@Mixin
export class WithCreditcardForSubscriptionRepository extends Vue {
  get creditCardForSubscriptionRepository(): CreditcardForSubscriptionRepository {
    return new FrontApiRepositoryForSubscription(
      // 有料会員登録のクレカ利用でも、定期購入用の仕組みをそのまま流用する
      // @see https://www.chatwork.com/#!rid87550748-1510149340864204800
      new CreditcardServiceForSubscription(),
      getCreditcardPaymentProvierServiceConfig().forSubscription
    )
  }
}
