import Stickyfill from 'stickyfilljs'
import { DirectiveOptions } from 'vue/types/options'

const sticky: DirectiveOptions = {
  inserted(el: HTMLElement) {
    Stickyfill.add(el)
  },
}

export default sticky
