import Stock from '../../../index'
import DisplayRule from '../index'

export class CoelNoDisplayDependOnWebStock implements DisplayRule {
  constructor(private readonly border: number) { }

  shouldApply(stock: Stock): boolean {
    return stock.display.isDisable && this.border < stock.web.amount
  }
  apply(): string {
    return ''
  }
}
