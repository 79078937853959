













import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component
export default class LinkPanel extends Vue {
  @Prop()
  url: string

  @Prop()
  image: string

  get imageUrl(): string {
    return `/assets-proxy/images/${this.image}.png`
  }
}
