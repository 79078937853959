import { Module } from 'vuex'

import { RootState } from '../../types'
import { actions } from './actions'
import { mutations } from './mutations'
import { BundleDiscountState, createInitialBundleDiscount } from './types'

export function createBundleDiscount(): Module<BundleDiscountState, RootState> {
  return {
    namespaced: true,
    actions,
    state: createInitialBundleDiscount(),
    mutations,
  }
}
