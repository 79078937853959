import _ from 'lodash'

import Vue from 'vue'
import Vuex from 'vuex'

import { appSetting } from './modules/appSetting'
import { cart } from './modules/cart'
import { contact } from './modules/contact'
import { coupon } from './modules/coupon'
import { creditcard } from './modules/creditcard'
import { history } from './modules/history'
import { orderConfirm } from './modules/orderConfirm'
import { orderHistory } from './modules/orderHistory'
import { purchase } from './modules/purchase'
import { subscriptionConfirm } from './modules/subscriptionConfirm'
import { user } from './modules/user'
import { view } from './modules/view'
import { mutations } from './mutations'
import { initialRootState, RootState } from './types'

Vue.use(Vuex)

export default new Vuex.Store<RootState>({
  state: _.cloneDeep(initialRootState),

  mutations,

  modules: {
    user,
    cart,
    contact,
    coupon,
    creditcard,
    orderConfirm,
    subscriptionConfirm,
    history,
    orderHistory,
    purchase,
    view,
    appSetting,
  },
})
