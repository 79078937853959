import { PaidMembershipPlanRepository } from '@/models/api/paid-member-price/member-plan/apply/repository'
import { ApplyMembershipPlan } from '@/models/api/paid-member-price/member-plan/apply/scheme'
import { EventBusForThirdParty } from '@/usecases/event-bus-for-third-party/event-bus'

export function subscribePaidMembershipPlan(repository: PaidMembershipPlanRepository) {
  return async (param: ApplyMembershipPlan): Promise<void> => {
    await repository.apply(param)

    EventBusForThirdParty.getInstance().publish('subscribe-membership-plan', {
      planId: param.plan.external_id,
    })
  }
}
