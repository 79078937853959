import { Either, Left, Right } from 'fp-ts/lib/Either'

import { log } from '@/log'
import { CatalogDemand, CatalogDemandDto } from '@/models/api/catalog/catalog-demand'
import { ApiClient } from '@/services/api/client'

export default class CatalogDemandService {
  public constructor(
    private readonly brand: string
  ) { }

  async get(id: string): Promise<Either<Error, CatalogDemandDto>> {
    try {
      const response = await ApiClient.get(`${this.requestUrl}/${id}`)

      return new Right(new CatalogDemandDto(response.body.data))
    } catch (error) {
      log.error({ error })

      return new Left(error)
    }
  }

  private get requestUrl(): string {
    return `/api/${this.brand}/catalog`
  }
}

export interface CatalogDemandResponse {
  success: boolean
  data: CatalogDemand
}
