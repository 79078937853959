import SKU from '@/models/item/sku/index'
import FKUId from './id'
import Stock from './stock'

export default class FKU {
  constructor(
    readonly id: FKUId,
    private readonly skuList: SKU[]
  ) { }

  get stock(): Stock {
    const skuStocks = this.skuList.map(sku => sku.stock)

    return new Stock(skuStocks)
  }
}
