

























import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import McModal from '@/components/molecules/Modal.vue'
import {
  CloseButtonTextStyle,
  ErrorDialogStyle,
} from './style'

@Component({
  components: {
    McModal,
  },
})
export default class ErrorDialog extends Vue {
  @Prop({ default: () => ({}) })
  styleSetting: ErrorDialogStyle

  onClose(event) {
    this.$emit('close', event)
  }

  get closeButtonTextStyle(): CloseButtonTextStyle {
    return this.styleSetting.closeButtonText || {}
  }
}
