import { ApiProduct } from '@/models/api/product'

export interface StockParsePolicy {
  parseQuantity(product: ApiProduct): number
  parseSalesQuantity(product: ApiProduct): number
  parseProspectQuantity(product: ApiProduct): number
  parseIntentionQuantity(product: ApiProduct): number|null
}

export class UseIndexSummary implements StockParsePolicy {
  /**
   * 本来この値はEngineで算出されているもので、indexsummaryは取得することができない。
   * indexsummary側にEngineと同等の処理を移植してindexsummaryのテーブルに入れてもらっている。
   * @see https://www.chatwork.com/#!rid87550748-1475011361581867008
   */
  parseQuantity(product: ApiProduct): number {
    return product.get('stock_quantity', 0)
  }

  parseSalesQuantity(product: ApiProduct): number {
    return product.get('web_stock_quantity', 0)
  }

  parseProspectQuantity(product: ApiProduct): number {
    return product.get('prospect_stock_quantity', 0)
  }

  parseIntentionQuantity(product: ApiProduct): number|null {
    return product.get('intention_stock_quantity', null)
  }
}

export class UseStockDetail implements StockParsePolicy {
  parseQuantity(product: ApiProduct): number {
    return product.get('stock.stock_quantity', 0)
  }
  parseSalesQuantity(product: ApiProduct): number {
    return product.get('stock.sales.stock_quantity', 0)
  }
  parseProspectQuantity(product: ApiProduct): number {
    return product.get('stock.sales.prospect_stock_quantity', 0)
  }
  parseIntentionQuantity(product: ApiProduct): number|null {
    return product.get('stock.sales.intention_stock_quantity', null)
  }
}
