import DiscountPrice from '@/components/molecules/item/price/kind/DiscountPrice.vue'
import PaidMemberPrice from '@/components/molecules/item/price/kind/PaidMemberPrice.vue'
import RegularPrice from '@/components/molecules/item/price/kind/RegularPrice.vue'
import { PriceViewState } from '@/components/molecules/item/price/model/item-price-view'
import BehaviorConfig from '@/models/app-config/behavior/behavior'
import { Vue, VueConstructor } from 'vue/types/vue'

export type PriceView = VueConstructor<Vue & { $props: Record<'state', PriceViewState> }>
export function selectPriceView(state: PriceViewState, behaviorConfig: BehaviorConfig): PriceView {
  if (behaviorConfig.paidMembership.enabled) {
    return PaidMemberPrice
  }
  if (state.isOnDiscount) {
    return DiscountPrice
  }

  return RegularPrice
}
