import { MutationTree } from 'vuex'

import * as Error from './types'

import { log } from '@/log'

const namespace = 'error'

export const mutations: MutationTree<Error.State> = {
  set(state, payload: Error.ErrorContent) {
    state.error = payload

    log.debug({ commit: `${namespace}/set`, state })
  },
  clear(state) {
    state.error = null

    log.debug({ commit: `${namespace}/clear`, state })
  },
}
