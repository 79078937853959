import BaseNavigation from '@/components/organisms/paid-membership/subscribe/form/navigation/BaseNavigation.vue'
import Vue, { VueConstructor } from 'vue'

export type Navigation = VueConstructor<Vue>

export const emptyNavigation = (): Navigation => Vue.extend({
  name: 'EmptyNavigation',
  template: `<div class="empty-navigation"></div>`,
})

export const authNavigation = (currentPath: string): Navigation => {
  const creditCardPath = `/s/paid-membership/new-creditcard?next=${currentPath}`
  const next = `${encodeURI(creditCardPath)}`
  const href = `/s/signup?next=${next}`

  return Vue.extend({
    name: 'AuthNavigation',
    components: {
      BaseNavigation,
    },
    template: `
    <base-navigation href="${href}" label="会員登録/ログイン">
      <template v-slot:message>
        <p>お申し込みには会員登録が必要です。</p>
        <p>すでに登録済みの方はログインしてください。</p>
      </template>
    </base-navigation>
  `,
  })
}

export const creditcardNavigation = (currentPath: string): Navigation => {
  const href = `/s/paid-membership/new-creditcard?next=${currentPath}`

  return Vue.extend({
    name: 'CreditcardNavigation',
    components: {
      BaseNavigation,
    },
    template: `
    <base-navigation href="${href}" label="クレジットカード登録">
      <template v-slot:message>
        <p>お申し込みにはクレジットカードの登録が必要です。</p>
      </template>
    </base-navigation>
  `,
  })
}
