import AbstractValue from '@/models/app-config/behavior/abstract-value'

export interface RawItemDetail {
  allowed: ItemDetailField[]
}

export enum ItemDetailField {
  Brand = 'brand',
  Category = 'category',
  ColorTip = 'colortip',
  Name = 'name',
  Price = 'price',
}

export default class ItemDetail extends AbstractValue<RawItemDetail> {
  allow(kind: ItemDetailField): boolean {
    return this.raw.allowed.indexOf(kind) >= 0
  }
}
