import { WithInstanceContext } from '@/page/context/instance'
import { WithPageContext } from '@/page/context/page'

export type ContextToCreateUrl = WithInstanceContext & WithPageContext

/**
 * コンテクストに応じて、サイト内部へのリンク文字列を構築する.
 *
 * * インスタンスタイプ
 * * ページのタイプ（ブランド配下か、ホスト配下か、ブランド横断か、etc...）
 *
 * などによって、最終的に構築される内容は変わる.
 * 詳細はテストケースを参照.
 *
 * @param ctx パス文字列を構築する際に考慮すべきコンテクスト.
 * @param path リンク先パス. "/" 始まりが前提. "/" 始まりでない場合は先頭に "/" を補う.
 */
export function createPageUrl(ctx: ContextToCreateUrl, path: string): string {
  if (path.indexOf('/') !== 0) {
    return createPageUrl(ctx, `/${path}`)
  }

  if (
    ctx.instance.isSingle ||
    ctx.page.onHost ||
    ctx.page.onCrossBrand
  ) {
    return path
  }

  const prefix = `/${ctx.page.brand}`

  return `${prefix}${path}`
}

/**
 * `createPageUrl(ctx, "/itemlist")` へのalias関数.
 *
 * @param ctx パス文字列を構築する際に考慮すべきコンテクスト.
 */
export function createItemListUrl(ctx: ContextToCreateUrl): string {
  return createPageUrl(ctx, '/itemlist')
}
