
















import { BrandService } from '@/services/api/brand'
import _ from 'lodash'
import { Mixins } from 'vue-mixin-decorator'
import { Component } from 'vue-property-decorator'

import Util from '@ajax/modules/util'
import ApiMixin from '@ajax/vue/mixin/Api'

@Component
export default class SearchBrand extends Mixins<ApiMixin>(ApiMixin) {

  brands: any[] = []

  selectedBrands: any[] = []

  async mounted () {
    const brandService = new BrandService()

    const result = await brandService.list()

    result.map((response) => {
      this.brands = response.body.data
      this.reflectUrlParams()
    })
  }

  get selectedBrandsString () {
    return this.selectedBrands.length === 0 ? '指定なし' : _.map(this.selectedBrands, 'name').reverse().join('／')
  }

  isBrandSelected (brand) {
    return _.find(this.selectedBrands, { id: brand.id }) !== undefined
  }

  onClickBrand (brand) {
    const index = _.map(this.selectedBrands, 'id').indexOf(brand.id)
    if (index !== - 1) {
      this.selectedBrands.splice(index, 1)
    } else {
      this.selectedBrands.push(brand)
    }
  }

  reflectUrlParams () {
    const urlParams = Util.convertItemlistUrlParamsToAssociativeArray()
    if (urlParams.brand) {
      const brands = urlParams.brand.split(',') as string[]
      this.addSelectedBrandsByIds(brands)
    }
  }

  addSelectedBrandsByIds (brandIds) {
    this.selectedBrands = _.compact(
      brandIds.map(brandId => _.find(this.brands, { id: brandId }))
    )
  }

}
