import { ApplyMembershipPlan } from '@/models/api/paid-member-price/member-plan/apply/scheme'
import { PaidMembershipPlanService } from '@/services/api/paid-member-plan'

export interface PaidMembershipPlanRepository {
  apply(param: ApplyMembershipPlan): Promise<void>
}

export class DefaultPaidMembershipPlanRepository implements PaidMembershipPlanRepository {
  constructor(private readonly planService: PaidMembershipPlanService) {}

  async apply(params: ApplyMembershipPlan): Promise<void> {
    const response = await this.planService.apply(params)

    return response.fold(
      (error) => {
        throw new Error(error.message)
      },
      () => undefined
    )
  }
}
