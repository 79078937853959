import _ from 'lodash'

export class Page<T> {
  constructor (
    readonly number: number,
    readonly list: Array<T | null>
  ) {}

  get count(): number {
    return this.list.length
  }
}

export class IntentionallyLeftBlankPage<T> extends Page<T> {
  constructor(
    readonly number: number,
    readonly numberOfItems: number
  ) {
    super(
      number,
      _.fill(Array(numberOfItems), null)
    )
  }
}

export class PageCollection<T> {
  constructor (
    private pages: Array<Page<T>>,
    private perPage: number,
    private startPageNumber: number = 1
  ) {}

  get list(): Array<Page<T>> {
    return this.pages
  }

  get isEmpty(): boolean {
    return this.list.length === 0
  }

  find(number: number): Page<T> | null {
    return this.list.find(page => page.number === number) || null
  }

  add(page: Page<T>): void {
    this.pages = _.sortBy(
      this.makeMergedPages(this.pages, page),
      p => p.number
    )
  }

  fillInPreviousPages(beforeThis: number): void {
    const previousPages = _.range(this.startPageNumber, beforeThis).map((number) => {
      const target = this.find(number)

      if (!target) {
        return new IntentionallyLeftBlankPage<T>(number, this.perPage)
      }

      return target
    })

    this.pages = [
      ...previousPages,
      ..._.filter(this.list, page => page.number >= beforeThis),
    ]
  }

  /**
   * Merge (add or update with the page number) a page into the list of pages.
   */
  private makeMergedPages(pages: Array<Page<T>>, page: Page<T>): Array<Page<T>> {
    const target = this.find(page.number)

    if (!target) {
      return [
        ...pages,
        page,
      ]
    }

    return pages.map((p) => {
      if (p.number !== page.number) {
        return p
      }

      return page
    })
  }
}
