import { Sku as SkuInProductclass } from '@/models/api/productclass/sku'
import * as FKUFactory from '@/models/item/fku/factory/fku'
import * as SKUFactory from '@/models/item/sku/factory/sku'
import GetStockStatus from '@/usecase/get-stock-status'
import { ApiProductclass } from '../api/productclass'
import { RuleApplicable, RuleInput, SKU } from './rule-applicable'

export class RuleApplicableFku implements RuleApplicable {
  constructor(
    private readonly fkuDto: ApiProductclass,
    private readonly stockStatus: GetStockStatus
  ) {
    if (!fkuDto.isFku) {
      throw new InvalidResourceError(
        `resource must be fku productclass object, but not fku passed: ${JSON.stringify(fkuDto)}`
      )
    }
  }

  toInput(): RuleInput {
    const fku = FKUFactory.create(this.fkuDto)
    const skus = this.fkuDto.materializedSkuList.map((sku: SkuInProductclass) => {
      return new ToSkuDisplayState(this.stockStatus).convert(sku)
    })

    return {
      skus,
      stock: fku.stock,
      isRestock: this.fkuDto.isRestock,
    }
  }
}

class ToSkuDisplayState {
  constructor(private readonly stockStatus: GetStockStatus) {}

  convert(skuInProductclass: SkuInProductclass): SKU {
    const sku = SKUFactory.createFromSkuInProductclass(skuInProductclass)

    const isPreSaleItem = skuInProductclass.hasPreSaleTag && !skuInProductclass.overRegularSaleStart
    const isOnPreSale = isPreSaleItem &&
                        skuInProductclass.isAvailable &&
                        this.stockStatus.exists(sku.stock)

    const acceptNewItemRequest = skuInProductclass.acceptNewItemRequest &&
                                 !skuInProductclass.overRegularSaleStart &&
                                 !isOnPreSale

    const preSaleSoldOut = isPreSaleItem &&
                           !isOnPreSale &&
                           !acceptNewItemRequest

    const comingSoon = !skuInProductclass.hasPreSaleTag &&
                       !skuInProductclass.acceptNewItemRequest &&
                       !skuInProductclass.overRegularSaleStart
    const isComingSoon = comingSoon &&
                         skuInProductclass.isAvailable &&
                        this.stockStatus.exists(sku.stock)
    const comingSoonSoldOut = comingSoon && !isComingSoon

    const acceptRestockRequest = skuInProductclass.acceptRestockRequest &&
                                 skuInProductclass.isOnRegularSale &&
                                 this.stockStatus.isSoldOut(sku.stock)

    const isNormal = !isOnPreSale &&
                     !preSaleSoldOut &&
                     !isComingSoon &&
                     !comingSoonSoldOut &&
                     !acceptNewItemRequest &&
                     !acceptRestockRequest

    const isPurchasable = skuInProductclass.isAvailable &&
                          skuInProductclass.isOnRegularSale &&
                          this.stockStatus.exists(sku.stock)

    return {
      isNormal,
      isOnPreSale,
      preSaleSoldOut,
      acceptNewItemRequest,
      acceptRestockRequest,
      isOnSale: skuInProductclass.isOnDiscountSale,
      isNew: skuInProductclass.isNew,
      isPurchasable,
      isComingSoon,
      comingSoonSoldOut,
    }

  }
}

/**
 * Jest利用時のTSの制限により、extends Errorをしない
 * https://github.com/facebook/jest/issues/8279
 */
export class InvalidResourceError {
  constructor(readonly message: string) { }
}
