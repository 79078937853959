import { convertShortIdToDisplayId } from '@/models/api/id'
import { executeWebCustomerConversionTag } from '@/vendor/web-customer-service-tool'
import { executeConversionTag as executeCribnotesConversionTag } from '@spa/vendor/cribnotes'
import _ from 'lodash'
import { PurchaseSubscriptionEvent } from '../../event'

export function handlePurchaseSubscription(event: PurchaseSubscriptionEvent): void {
  sendToGA(event)
  sendToWebCustomerTool(event)
  sendToCribnotes(event)
}

interface PurchaseSubscriptionToGA {
  event: 'e-commerce'
  transactionId: string
  transactionTotal: number
  transactionTax: number
  transactionProducts: Array<{
    sku: string
    name: string
    price: number
    quantity: number
  }>
}

function sendToGA(event: PurchaseSubscriptionEvent): void {
  const payload = convertToGAFormat(event)

  payload.forEach((p) => {
    window.dataLayer.push(p)
  })
}

function sendToWebCustomerTool(event: PurchaseSubscriptionEvent): void {
  const payload = convertToGAFormat(event)

  /**
   * Web接客ツールは（たまたま）、GA（UA）に送信されるのと同じe-commerceデータ型を前提として調整されている。
   */
  executeWebCustomerConversionTag(payload)
}

function sendToCribnotes(event: PurchaseSubscriptionEvent): void {
  const { successOrderResultList } = event

  successOrderResultList.forEach((result) => {
    const items = _.map(result.order.cartItems, item => ({
      item_id: item.skuId,
      price: item.unitPrice,
      quantity: item.unitCount,
    }))

    executeCribnotesConversionTag(convertShortIdToDisplayId(result.order.shortId), items)
  })
}

function convertToGAFormat(event: PurchaseSubscriptionEvent): PurchaseSubscriptionToGA[] {
  const { successOrderResultList } = event

  return successOrderResultList.map((result) => {
    const products = _.map(result.order.cartItems, cartItem => ({
      sku: cartItem.skuId,
      name: cartItem.name,
      price: cartItem.unitPrice,
      quantity: cartItem.unitCount,
    }))

    return {
      event: 'e-commerce',
      transactionId: convertShortIdToDisplayId(result.order.shortId),
      transactionTotal: result.paymentAmount.total,
      transactionTax: result.paymentAmount.tax,
      transactionProducts: products,
    }
  })
}
