import AbstractValue from '@/models/app-config/behavior/abstract-value'

export interface RawBorder {
  showDisplayAmount: number
  showDisplayFew: number
  showActual: number
}

export default class Border extends AbstractValue<RawBorder> {
  get onDisplayAmount(): number {
    return this.raw.showDisplayAmount
  }
  get onDisplayFew(): number {
    return this.raw.showDisplayFew
  }
  get onActual(): number {
    return this.raw.showActual
  }
}
