









import { DiscountPriceView, PriceRange } from '@/components/molecules/item/price/model/item-price-view'
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component
export default class DiscountPrice extends Vue {
  @Prop({ required: true })
  readonly state: DiscountPriceView

  get displayRange(): PriceRange {
    return this.state.nonPaidMemberPrice.range
  }
}
