





































import { PaidMemberPriceViewState, ViewState } from '@/components/organisms/item/price/paid-membership-price/view-state'
import { PagePathToSubscribePaidMembership } from '@/models/paid-membership/subscribe/page-path'
import _ from 'lodash'
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import { log } from '@/log'
import { BrandRouteResolver } from '@/util/brandRouteResolver'
import { PaidMembershipInteraction } from '@ajax/vue/components/templates/interaction/paid-membership/paid-membership-interaction'
import {
  emptyPaidMembershipPriceLabel,
  PaidMembershipPriceInteraction,
  PaidMembershipPriceLabel
} from './interaction'

/**
 * このコンポーネントは、商品詳細画面における、有料会員向けのROOT価格（画面全体で表示される価格であり、SKUごとの価格ではない）を表示する。
 * このコンポーネントを呼び出す環境（ブランド等）で、有料会員機能がONであれば、ROOT価格にはこのコンポーネントが表示され、
 * 機能がOFFであれば通常のROOT価格がPHPテンプレートで表示される。
 * @see src/Template/Element/item/pc.ctp および sp.ctp
 *
 * 有料会員機能がプロダクトとしてONになっていても、このコンポーネントは有料会員・非会員によってアクセスされうる。
 * 有料会員・非会員によってコンポーネント自体を切り替えてしまうことも考えられるが、ひとまずこのコンポーネントで両方の会員種別に対応する。
 */
@Component
export default class PaidMembershipPrice extends Vue {
  @Prop({ required: true })
  rootId: string

  isLoading: boolean = false
  label: PaidMembershipPriceLabel = emptyPaidMembershipPriceLabel()
  isPaidMember: boolean = false
  error: ErrorStatus | null = null

  async created() {
    this.isLoading = true

    const interaction = new PaidMembershipPriceInteraction(this.brandName)
    const paidMembershipInteraction = new PaidMembershipInteraction(this.brandName)

    const [
      labelResult,
      isPaidMemberResult,
    ] = await Promise.all([
      interaction.fetchLabel(this.rootId),
      paidMembershipInteraction.isPaidMember(),
    ])

    isPaidMemberResult.fold(
      (error: Error) => {
        log.error({
          message: 'Failed to determine if the user is a paid-member.',
          error,
        })
      },
      (value: boolean) => {
        this.isPaidMember = value
      }
    )

    /**
     * TODO: プロダクト全体でエラーメッセージを管理・集約したい。
     * @see https://github.com/my-color/front/pull/5962#discussion_r763668722
     */
    const errorMessage = 'エラーが発生しました。※改善されない場合、お問い合わせよりカスタマーセンターまでお問い合わせください。'

    labelResult.fold(
      (error: Error) => {
        log.error({ error })
        this.error = {
          message: errorMessage,
        }
      },
      (label: PaidMembershipPriceLabel) => {
        this.label = label
      }
    )

    this.isLoading = false
  }

  get brandName(): string {
    return BrandRouteResolver.resolveBrandFromPathElement(this.$route.params.brand_english_name)
  }

  get viewState(): ViewState {
    return PaidMemberPriceViewState.create(this.label, this.isPaidMember)
  }

  get componentToApplyPaidMember() {
    return this.viewState.paidMemberPrice.componentToApply(
      PagePathToSubscribePaidMembership.returnAfterApply(this.$route.path).asSPA.toString()
    )
  }

  initErrorStatus(): void {
    this.error = null
  }
}

interface ErrorStatus {
  message: string
}
