
































import * as _ from 'lodash'
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import ErrorDialog from '@/components/organisms/dialog/ErrorDialog.vue'
import { handleFaildToEnterShopError } from '@/entry-limit/error-handling'
import { log } from '@/log'
import { ApiProduct } from '@/models/api/product'
import { OriginalApiErrorResponse } from '@/models/api/response/original-api-error-response'
import { ErrorType } from '@/models/error/api/error'
import { AuthenticationService } from '@/services/auth/authentication'
import AppId from '@/util/appid'
import { BrandRouteResolver } from '@/util/brandRouteResolver'
import { CartService } from '@ajax/modules/services/cart'
import { ProductService as AjaxProductService } from '@ajax/modules/services/product'
import { BundlePurchaseItem } from './model/bundle-purchase-item'
import { AddItemsToCartSpec } from './model/specification/add-items-to-cart/index'

@Component({
  components: {
    ErrorDialog,
  },
})
export default class BundlePurchaseAddToCartButton extends Vue {
  @Prop({ required: true })
  brand: string

  @Prop({ required: true })
  purchaseList: BundlePurchaseItem[]

  @Prop({ required: true })
  addItemsToCartSpec: AddItemsToCartSpec

  isLoading: boolean = false

  cartService: CartService
  ajaxProductService: AjaxProductService

  errorMessages: string[] = []

  get canAddItemsToCart(): boolean {
    return this.addItemsToCartSpec.isSatisfiedBy(this.purchaseList)
  }
  get buttonClass(): string[] {
    const base = 'p-bundle_purchase--navigation--add_to_cart'

    return this.canAddItemsToCart ? [base] : [base, 'disable']
  }

  get hasErrorMessage(): boolean {
    return this.errorMessages.length > 0
  }
  get errorMessage(): string {
    return _.uniq(this.errorMessages).join('<br />')
  }

  async created() {
    const appId = AppId.getByBrandName(this.brand)
    this.cartService = new CartService(this.brand)
    this.ajaxProductService = new AjaxProductService(appId)
  }

  onCloseErrorModal() {
    this.errorMessages = []
  }

  async addItemsToCart() {
    if (!this.canAddItemsToCart) {
      return
    }

    this.isLoading = true
    const authService = AuthenticationService.create()
    const resultRegistrerProvisional = await authService.registerAsProvisilnalIf(loginState => loginState.isGuest())
    const shouldSuspendDueToRegister = resultRegistrerProvisional.fold(
      (e) => {
        log.error(e)
        this.errorMessages = [
          'カート追加に失敗しました。少し時間を置いてから、もう一度お試しください。',
          '※改善されない場合、お問い合わせよりカスタマーセンターまでお問い合わせください。',
        ]
        this.isLoading = false

        return true
      },
      () => false
    )

    if (shouldSuspendDueToRegister) {
      return
    }

    await Promise.all(this.purchaseList.map(item => this.addToCart(
      item.id,
      item.price.regular.withConsumptionTax,
      1
    )))
    this.isLoading = false

    if (this.errorMessages.length > 0) {
      return
    }
    const query = {
      isCartIn: true,
      productId: '',
      unitPrice: 0,
    }

    const endpoint = BrandRouteResolver.makeResourceEndpoint({
      brand: this.brand,
      path: ['cart'],
      query,
      withSpaPrefix: true,
    })

    location.href = endpoint
  }

  /**
   * Add a product to cart.
   * When the product is the parent of a hidden set(紐付き商品), also add its child in the background.
   * TODO: The part of handling child product will be refactored and integrated into server-side or somwhere.
   */
  async addToCart(id: string, price: number, count: number) {

    // Fetch product information
    const result = await this.ajaxProductService.commonItem(id, { with: 'stock~detail' })
    if (result.isLeft()) {
      this.errorMessages = this.errorMessages.concat('エラーが発生しました。※改善されない場合、お問い合わせよりカスタマーセンターまでお問い合わせください。')

      return
    }

    const product = new ApiProduct(result.value.body.data)

    if (product.belongingBrand === null) {
      this.errorMessages = this.errorMessages.concat('商品情報が取得できなかったため、カートに追加できません。')

      return
    }

    const requestList = [
      this.cartService.post({
        productId: id,
        unitPrice: price,
        /**
         * バンドル割引には有料会員価格は想定されていない。
         * またバンドル（商品群の粒度）と有料会員価格（SKUの粒度）の併用が考えられるためには、粒度に関する仕様の調整も必要である。
         */
        isPaidMembershipPrice: false,
        unitCount: count,
      }),  // Main request
    ]

    // Add an item to cart
    const requestListResult = await Promise.all(requestList)

    const mainResponseResult = _.get(requestListResult, 0)

    if (mainResponseResult.isLeft()) {
      const response = (mainResponseResult.value as any).response

      handleFaildToEnterShopError(response)

      this.errorMessages = this.errorMessages.concat(this.parserErrorResponse(
        new OriginalApiErrorResponse(response)
      ))

      return
    }
  }

  private parserErrorResponse(error: OriginalApiErrorResponse): string {
    if (error.contains(ErrorType.NotInStoreError)) {
      return '在庫切れのため、カートに追加できません。'
    }
    const messages = _.compact([
      error.contains(ErrorType.UnitCountExceededError) &&
        '購入上限数に達しているため、カートに追加できません。',
    ])

    if (messages.length === 0) {
      return 'エラーが発生しました。※改善されない場合、お問い合わせよりカスタマーセンターまでお問い合わせください。'
    }

    return messages.join('<br>')
  }
}
