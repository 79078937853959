import { ApiCreditcardWithSecurityCode } from '@/models/api/creditcard'
import { CreditcardToBuildPaymentPayload } from '@/services/api/creditcard'
import { Either } from 'fp-ts/lib/Either'

import { PaymentType } from '@/models/app-config/behavior/types'
import { CreditcardPayment } from '@/services/payment/types'
import { ScriptLoader } from '@/util/scriptLoader'
import { User } from '@spa/store/modules/user/types'

export interface CreditcardPaymentProviderViewConfig {
  readonly registerCardForm: {
    readonly requireCardOwner: boolean
    readonly requireSecurityCode: boolean
  }
  readonly paymentForm: {
    readonly requireSecurityCode: boolean
  }
}

export type CreditcardPayment = Required<Pick<
  ApiCreditcardWithSecurityCode,
  'id' | 'securityCode' | 'lastCreditDate' | 'numberLast4' | 'cardType'
>>

/**
 * クレジットカード決済を提供するプロバイダとのやり取りを行うモジュール
 */
export abstract class CreditcardPaymentProviderService {

  readonly paymentType: PaymentType = 'creditcard'
  readonly abstract scriptSrc: string
  readonly abstract namespace: string

  readonly abstract registerCardLimit: number | null

  async loadScript() {
    const scriptLoader = ScriptLoader.getInstanceFor({
      src: this.scriptSrc,
      global: this.namespace,
    })

    await scriptLoader.load()

    if (!this.isScriptLoaded) {
      Promise.reject(new Error('loaded script is invalid.'))
    }
  }

  abstract get isScriptLoaded(): boolean

  abstract async createRegisterCardPayload(
    creditcard: {
      cardNumber: string
      expireYear: string
      expireMonth: string
      securityCode: string
      cardOwner: string
    },
    user: User
  ): Promise<Either<Error, string>>

  abstract async createPaymentPayload(
    creditcard: CreditcardToBuildPaymentPayload,
    user: User
  ): Promise<Either<Error, CreditcardPayment>>
}

export interface CreditcardPaymentProviderServiceForPurchase extends CreditcardPaymentProviderService {
  readonly _context: 'for-purchase'
}
export interface CreditcardPaymentProviderServiceForSubscription extends CreditcardPaymentProviderService {
  readonly _context: 'for-subscription'
}
