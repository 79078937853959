import { ApiProduct } from '@/models/api/product'
import BehaviorConfig from '@/models/app-config/behavior/behavior'
import RootState from '@/models/item/root/state'
import * as SKUFactory from '@/models/item/sku/factory/sku'
import SKU from '@/models/item/sku/index'
import GetStockStatus from '@/usecase/get-stock-status'
import EachStateSpec from './each-state-spec'

export default class CanPurchaseSpec implements EachStateSpec {
  constructor(private behavior: BehaviorConfig) { }

  verify(products: ApiProduct[]): boolean {
    const items = products.filter(p => p.isOnRegularSale)

    if (items.length === 0) {
      return false
    }

    return items
      .map(SKUFactory.create)
      .some(this.stockExist)
  }

  get state(): RootState {
    return RootState.CanPurchase
  }

  private get stockExist(): (sku: SKU) => boolean {
    return (sku: SKU) => this.getStockStatus.exists(sku.stock)
  }
  /**
   * TODO: UseCaseではなく、Entity経由で参照するようにする
   */
  private get getStockStatus(): GetStockStatus {
    return GetStockStatus.createViaConfig(this.behavior)
  }
}
