import { Either, Left, Right } from 'fp-ts/lib/Either'

import { log } from '@/log'
import { RawTag } from '@/models/api/tags'
import { ApiService } from '@/services/api/service'
import { ApiClient } from './client'

const namespace: string = 'tags'

/**
 * TODO d.tsとして定義ファイル別切り
 */
export interface Tag {
  pathname: string
  follow?: boolean
}

export class TagsService extends ApiService {
  async list(query: object = {}): Promise<Either<Error, RawTag[]>> {
    return this.requestGet(`/api/common-proxy/${this.appId}/tags`, query)
  }
  async listAsUser(query: object = {}): Promise<Either<Error, RawTag[]>> {
    return this.requestGet(`/api/user-proxy/${this.appId}/tags`, query)
  }

  async get(pathname: string, query: object = {}): Promise<Either<Error, RawTag[]>> {
    return this.list({
      pathname,
      ...query,
    })
  }
  async getAsUser(pathname: string, query: object = {}): Promise<Either<Error, RawTag[]>> {
    return this.listAsUser({
      pathname,
      ...query,
    })
  }

  async put(pathnameOrId: string, parameter: object): Promise<Either<Error, any>> {
    try {
      const response = await ApiClient
        .put(`/api/user-proxy/${this.appId}/tags/${pathnameOrId}`)
        .send(parameter)

      log.debug({ service: `${namespace}/put`, response })

      return new Right(response.body.data)
    } catch (error) {
      log.error({ service: `${namespace}/put`, error })

      return new Left(error)
    }
  }

  private async requestGet(
    dist: string,
    query: object = {}
  ): Promise<Either<Error, RawTag[]>> {
    try {
      const response = await ApiClient
        .get(dist)
        .query({
          scope: 'list',
          ...query,
        })

      log.debug({ service: `${namespace}/get`, response })

      return new Right(response.body.data as RawTag[])
    } catch (error) {
      log.error({ service: `${namespace}/get`, error })

      return new Left(error)
    }
  }
}
