
























































import Spinner from '@/components/atoms/Spinner.vue'
import { WithErrorModal } from '@/components/organisms/dialog/error-dialog/with-error-modal'
import ErrorDialog from '@/components/organisms/dialog/ErrorDialog.vue'
import CouponCodeForm from '@/components/organisms/paid-membership/subscribe/form/coupon-code/CouponCodeForm.vue'
import {
  authNavigation, creditcardNavigation,
  emptyNavigation,
  Navigation,
} from '@/components/organisms/paid-membership/subscribe/form/navigation/navigation'
import { log } from '@/log'
import { CouponCode } from '@/models/api/coupon/coupon-code/coupon-code'
import { ApiCreditcard } from '@/models/api/creditcard'
import {
  WithSubscribePaidMembershipPlanProcess
} from '@/models/paid-membership/subscribe/with-subscribe-paid-membership-plan-process'
import { WithCreditcardForSubscriptionRepository } from '@/models/payment/creditcard/context/for-subscription/mixin'
import { CurrentUser } from '@/services/api/current-user'
import { getCreditcardPaymentProvierServiceConfig } from '@/services/payment'
import { CreditcardPaymentProviderService } from '@/services/payment/creditcard-payment-provider/creditcard-payment-provider-service'
import { Payment } from '@/services/payment/types'
import { fetchCurrentUser } from '@spa/store/modules/user/functions'
import { User } from '@spa/store/modules/user/types'
import { identity } from 'fp-ts/lib/function'
import { none, Option, some, Some } from 'fp-ts/lib/Option'
import { Mixins } from 'vue-mixin-decorator'
import { Component, Prop } from 'vue-property-decorator'

type MixedIn = WithCreditcardForSubscriptionRepository
  & WithSubscribePaidMembershipPlanProcess
  & WithErrorModal

interface Submittable {
  user: Some<User>
  creditCard: Some<ApiCreditcard>
  couponCode: Some<CouponCode>
}

type Status = 'loading' | 'pending' | 'complete'

@Component({
  components: {
    CouponCodeForm,
    Spinner,
    ErrorDialog,
  },
})
export default class SubscribePaidMembershipPlanForm
  extends Mixins<MixedIn>(
    WithCreditcardForSubscriptionRepository,
    WithSubscribePaidMembershipPlanProcess,
    WithErrorModal
  ) {
  @Prop({ required: true })
  readonly planId: string

  @Prop({ required: true })
  readonly brand: string

  @Prop({ default: () => null })
  readonly completePage: string | null

  /**
   * statusによって分岐させるのではなく、
   * statusに応じたコンポーネントそのものを入れ替える実装にするかも
   * 例えば{@see ChangePlan}と{@see ChangePlanInteraction.initialize} など
   */
  status: Status = 'loading'
  navigation: Navigation = emptyNavigation()

  user: Option<User> = none
  creditCard: Option<ApiCreditcard> = none
  couponCode: Option<CouponCode> = none

  async created() {
    try {
      const handleError = (nav: Navigation) => (error: Error) => {
        this.navigation = nav

        throw error
      }

      this.user = some(
        await this.requireAlreadyRegistered().catch(
          handleError(authNavigation(this.currentPath))
        )
      )
      this.creditCard = some(
        await this.requireCreditCardAlreadyRegistered().catch(
          handleError(creditcardNavigation(this.currentPath))
        )
      )

    } catch (error) {
      log.warn(
        `some requirement of this form not satisfied. user should be redirected in order to satisfy the requirement`,
        error.message
      )
    } finally {
      this.status = 'pending'
    }
  }

  canSubmit(): this is Submittable {
    const isTrue = _ => _ === true

    return [
      this.user.isSome(),
      this.creditCard.isSome(),
      this.couponCode.isSome(),
    ].every(isTrue)
  }

  async onSubmit() {
    if (!this.canSubmit()) return

    try {
      this.status = 'loading'

      const [user, card, coupon] = [this.user.value, this.creditCard.value, this.couponCode.value]
      const paymentResult = await this.cardPaymentProvider.createPaymentPayload(
        card.withSecurityCode(''),
        user
      )

      await this.subscribePaidMembershipPlan({
        payment: paymentResult.fold<Payment>(
          (error) => { throw error },
          identity
        ),
        plan: {
          external_id: this.planId,
        },
        coupon_id: coupon.id,
      })

      if (this.completePage) {
        location.href = this.completePage

        return
      }

      this.status = 'complete'
    } catch (error) {
      log.error(error)

      this.onError({
        content: `
          <p>
            プラン契約の手続きが失敗しました。
            契約の手続きは完了していません。
            入力内容をご確認いただくか、時間をおいて再度お試しください。
          </p>
          <p>
            改善されない場合、お問い合わせよりカスタマーセンターまでお問い合わせください。
          </p>
        `,
      })
      this.status = 'pending'
    }
  }

  get creditcardLast4Number(): string {
    return this.creditCard
      .map(card => card.numberLast4)
      .getOrElse('')
  }

  get fulfilled(): boolean {
    return this.user.isSome()
      && this.creditCard.isSome()
      // グローバルで使われる可能性があるので、
      // 運用ミスに備えて一応propsのガードを追加している.
      // Vueのvalidatorはdevモードでログ出力するだけなので、こちらでガードを実施.
      // https://github.com/vuejs/vue/issues/9584#issuecomment-467799693
      && (this.planId || '').length > 0
      && (this.brand || '').length > 0
  }

  onCouponApplied(couponCode: CouponCode) {
    this.couponCode = some(couponCode)
  }

  get submitButtonClass() {
    return [
      'c-btn',
      'c-btn--w-large',
      'c-btn--h-large',
      'c-btn--center',
      'c-btn--effect__pop',
      this.canSubmit ? 'c-btn--primary' : 'c-btn--disabled',
    ]
  }

  /**
   * @override
   * @protected
   * @see WithSubscribePaidMembershipPlanProcess.brandEnglishName
   */
  protected brandEnglishName(): string {
    return this.brand
  }

  /*
   * ↓以下 require** 系について、このフォームでは最終的に
   * - 会員登録
   * - クレジットカード登録
   * などもこのフォームで同時に実行できるように対応することになる可能性が高い.
   *
   * ただし、 https://github.com/my-color/front/issues/6334 のv1リリース時点では
   * 必須項目扱いして、未登録の場合は登録用画面へ飛ばす方針を取ることにした。
   *
   * @see https://github.com/my-color/front/issues/6682
   */

  private async requireAlreadyRegistered(): Promise<User> {
    const result = await fetchCurrentUser(new CurrentUser())

    return result.fold(
      (error) => {
        throw new Error(`user not registered yet, so redirect to auth page (${error.message})`)
      },
      (user) => {
        if (user.user_status !== 'registered') {
          throw new Error(`user not registered yet, so redirect to auth page (user status is ${user.user_status})`)
        }

        return user
      }
    )
  }
  private async requireCreditCardAlreadyRegistered(): Promise<ApiCreditcard> {
    const result = await this.creditCardForSubscriptionRepository.findRegistered()

    return result.foldL(
      () => {
        throw new Error('credit-card not registered yet, so redirect to registration page')
      },
      identity
    )
  }

  private get cardPaymentProvider(): CreditcardPaymentProviderService {
    return getCreditcardPaymentProvierServiceConfig().forSubscription
  }

  private get currentPath(): string {
    return location.pathname
  }
}
