import RootState from '@/models/item/root/state'

export default class Root {
  constructor(
    private readonly state: RootState
  ) { }

  get isPurchaseable(): boolean {
    return this.state === RootState.CanPurchase
  }
  get acceptNewItemRequest(): boolean {
    return this.state === RootState.CanRequestNewItem
  }
  get acceptRestockRequest(): boolean {
    return this.state === RootState.CanRequestRestock
  }
  get isWaitingArrival(): boolean {
    return this.state === RootState.WaitingArrival
  }
  get isSoldOut(): boolean {
    return this.state === RootState.SoldOut
  }
}
