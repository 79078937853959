













import { PagePathToSubscribePaidMembership } from '@/models/paid-membership/subscribe/page-path'
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component
export default class ToSubscribePaidMemberButton extends Vue {

  @Prop({ default: 'c-btn c-btn--primary c-btn--effect__pop c-btn--w-middle c-btn--h-small' })
  buttonClass: string

  @Prop({ required: true })
  redirectUrl: string

  toSubscriptionPage() {
    location.href = PagePathToSubscribePaidMembership.returnAfterApply(this.redirectUrl).asSPA.toString()
  }
}
