import { MutationTree } from 'vuex'

import { HistoryState } from './types'

import { log } from '../../../log'

const namespace = 'history'

export const mutations: MutationTree<HistoryState> = {
  setUrlToContinueShopping(state, payload: { urlToContinueShopping: string }) {
    const { urlToContinueShopping } = payload

    state.urlToContinueShopping = urlToContinueShopping

    log.debug({ commit: `${namespace}/setUrlToContinueShopping`, state })
  },
}
