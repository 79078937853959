import VeeValidate from 'vee-validate'

VeeValidate.Validator.extend('email', {
  /**
   * :pensive: https://github.com/my-color/front/issues/2128
   *
   * ピリオド連続等、RFC違反のメールアドレスも利用できてしまうが、
   * SmartShopがそうしたメールアドレスが許容されてしまう正規表現を使っており、
   * 実際にRFC違反のメールアドレスも登録できてしまっている.
   *
   * 既存ユーザー救済のため、↓の正規表現を使い、RFC違反については見て見ぬ振り.
   */
  validate: async (value: any) => {
    return validateEmailForMyColor(value)
  },
})

export const validateEmailForMyColor = (input: string): boolean => [
  // https://tools.ietf.org/html/rfc5322#section-3.2.3
  // https://github.com/my-color/front/issues/2128
  // smart shopの正規表現に、RFCでローカル部に許可されている記号を追加
  /^[_a-zA-Z0-9-.()!#$%&'*+/=?^_`{|}~]+@[a-zA-Z0-9-.]+(\.[a-z]+)$/,
].every(rule => rule.test(input))
