import { Either, Left, Right } from 'fp-ts/lib/Either'
import { Response } from 'superagent'

import { log } from '@/log'
import { ApiService } from '@/services/api/service'
import { EventBusForThirdParty } from '@/usecases/event-bus-for-third-party/event-bus'
import AppId from '@/util/appid'
import { ApiClient } from './client'

const namespace: string = 'cart'

/**
 * TODO: spa, ajax双方にCartServiceが別れて存在してしまっているので、共通のCartServiceに集約する。
 * @see /client/src/models/api/cart/cart/README.md
 */
export class CartService extends ApiService {
  static createFromBrandEnglishName(brandEnglishName: string): CartService {
    return new CartService(AppId.getByBrandName(brandEnglishName))
  }

  constructor(protected readonly appId: string) {
    super(appId)
  }

  get brandEnglishName(): string {
    return AppId.getBrandNameByAppId(this.appId)
  }

  async list(query: any = {}): Promise<Either<Error, object[]>> {
    try {
      const response = await ApiClient
        .get(`/api/user-proxy/${this.appId}/commerce_user_cart`)
        .query({
          cart_status: 1,
          ...query,
        })

      log.debug({ service: `${namespace}/list`, response })

      return new Right(response.body.data)
    } catch (error) {
      log.error({ error })

      return new Left(error)
    }
  }

  /**
   * TODO: product_id MIGHT be unique key, so return single element
   * @param productId
   * @param option
   */
  async get(
    productId: string,
    option: { cart_status?: number } = {} // TODO define interface when amount of options is many.
  ): Promise<Either<Error, Response>> {
    try {
      const response = await ApiClient
        .get(`/api/user-proxy/${this.appId}/commerce_user_cart`)
        .query({
          product_id: productId,
          ...option,
        })

      log.debug({ service: `${namespace}/get`, response })

      return new Right(response)
    } catch (error) {
      log.error({ error })

      return new Left(error)
    }
  }

  // tslint:disable-next-line
  async post(param: PostParam): Promise<Either<Error, Response>> {
    try {
      const {
        productId,
        unitPrice,
        isPaidMembershipPrice,
        unitCount,
      } = param

      const response = await ApiClient
        .post(`/api/user/cart/${this.brandEnglishName}`)
        .send({
          product_id: productId,
          unitPrice,
          isPaidMembershipPrice,
          unitCount,
        })

      log.debug({ service: `${namespace}/post`, response })

      EventBusForThirdParty.getInstance().publish('add-to-cart', {
        skuId: productId,
        unitPrice,
        unitCount,
      })

      return new Right(response)
    } catch (error) {
      log.error({ error })

      return new Left(error)
    }
  }
}

interface PostParam {
  productId: string,
  unitPrice: number,
  isPaidMembershipPrice: boolean,
  unitCount: number,
}
