import * as RecentlyViewedRepository from '@/models/repository/recently-viewed/recently-viewed-repository'
import { CurrentUser } from '@/services/api/current-user'
import { fetchCurrentUser, registerNewUser } from '@spa/store/modules/user/functions'
import { Either } from 'fp-ts/lib/Either'
import _ from 'lodash'
import { ActionTree } from 'vuex'

import AuthService, { UserRegistrationPayload } from '@spa/services/api/auth'
import UserService from '@spa/services/api/user'
import { MailMagazineService } from '@spa/services/mailMagazine'
import { RootState } from '@spa/store/types'
import { UserState } from './types'

/**
 * TODO: 「会員登録時はメルマガ設定も必須」といった、会員登録に関するロジックを実現する services/auth を実装する(services/api/authのラッパー)
 *
 * ↑の対応に併せて、型定義もAPI側に移動する.
 * 経緯は https://github.com/my-color/front/pull/1170#discussion_r275174225
 */
export interface RegistrationPayload {
  user: UserRegistrationPayload
  mailMagazine: SubscribeMailMagazinePayload
}
export interface SubscribeMailMagazinePayload {
  pathnames: string[]
}

export const actions: ActionTree<UserState, RootState> = {
  async login({ commit, dispatch, state }, payload: { username: string, password: string }) {
    const { username, password } = payload

    const result = await new AuthService().login(username, password)

    if (result.isLeft()) {
      commit('errorOnLogin', { error: result.value })

      return
    }

    state.error.login = null

    await dispatch('get', { force: true })
  },

  async logout({ commit, dispatch, state }) {
    const result = await new AuthService().logout()

    if (result.isLeft()) {
      commit('errorOnLogout', { error: result.value })

      return
    }

    RecentlyViewedRepository.instance.clearAll()

    state.error.logout = null

    await dispatch('unload')
  },

  async register({ commit, state }, { user, mailMagazine }: RegistrationPayload) {
    const process = registerNewUser({
      authGateway: new AuthService(),
      mailMagazineGateway: new MailMagazineService(),
      currentUserGateway: new CurrentUser(),
    })

    const result = await process(user, mailMagazine)

    result.fold(
      errorResult => errorResult.cause === 'register'
        // 若干冗長だが、IDEなどでmutationへジャンプしやすいように個別にcommitを呼び出している
        ? commit('errorOnRegister', { error: errorResult.error })
        : commit('errorOnMailMagazine', { error: errorResult.error }),
      (registeredUser) => {
        state.error.get = null
        state.error.register = null

        commit('get', registeredUser)
      }
    )
  },

  async get({ commit, state }, payload?: { force?: boolean }) {

    if (!_.get(payload, 'force') && !_.isEmpty(state.user)) {
      return state.user
    }

    const result = await fetchCurrentUser(new CurrentUser())
    result.fold(
      (error) => {
        commit('errorOnGet', { error })
      },
      (user) => {
        state.error.get = null

        commit('get', user)
      }
    )
  },

  async update({ commit, state }, payload: any) {
    const result: Either<Error, any> = await new UserService().update(payload)

    if (result.isLeft()) {
      commit('errorOnUpdate', { error: result.value })

      return
    }

    state.error.update = null

    // TODO: Better to reload current user?
    commit('update', payload)
  },

  /**
   * @deprecated
   *
   * ユーザーリソースを部分的に更新する仕組みができるまでの暫定対応。
   * @see https://github.com/my-color/front/pull/4568
   */
  async updatePaymentAuthToken({ commit, state }, payload: { payment_auth_token: string }) {
    const result: Either<Error, any> = await new UserService().updatePaymentAuthToken(payload)

    if (result.isLeft()) {
      commit('errorOnUpdatePaymentAuthToken', { error: result.value })

      return
    }

    state.error.update = null

    // TODO: Better to reload current user?
    commit('update', payload)
  },

  // Unload user from store, without deleting it from the server
  async unload({ commit }) {
    commit('unload')
  },
}
