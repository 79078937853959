import { ApiProduct } from '@/models/api/product'
import RootState from '@/models/item/root/state'
import EachStateSpec from './each-state-spec'

export default class WaitingArrivalSpec implements EachStateSpec {
  /**
   * TODO: SKUオブジェクトを受け取って処理するようにする
   *
   * 現時点ではSKUのドメインオブジェクトに十分な知識が入っていないため
   */
  verify(products: ApiProduct[]): boolean {
    return products.some(this.isWaitingArrival)
  }

  get state(): RootState {
    return RootState.WaitingArrival
  }

  private isWaitingArrival(product: ApiProduct) {
    return !product.isOnNewItemRequest && !product.overRegularSaleStart
  }
}
