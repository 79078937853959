












































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import BehaviorConfig from '@/models/app-config/behavior/behavior'
import { REVIEW_SECTION_ITEM, ReviewSectionItem } from '@/models/app-config/behavior/config/review-section'
import { MAX_RATING_VALUE, UserReview as UserReviewModel } from '@/models/user-review'
import RatingSelector from './RatingSelector.vue'

@Component({
  components: {
    RatingSelector,
  },
})
export default class UserReview extends Vue {
  @Prop({ required: true })
  review: UserReviewModel

  @Prop({ required: true })
  brand: string

  get maxRatingValue() {
    return MAX_RATING_VALUE
  }

  get reviewSectionItem(): typeof REVIEW_SECTION_ITEM {
    return REVIEW_SECTION_ITEM
  }

  private get behaviorConfig(): BehaviorConfig {
    return BehaviorConfig.createFromBrand(this.brand)
  }

  showReviewSection(item: ReviewSectionItem): boolean {
    return this.behaviorConfig.reviewSection.allow(item)
  }
}
