import { CookieService } from '@/services/cookie'

import { DateFormat } from '@/util/format'

export default class CurrentTimeState {
  get timeTravelTo(): string {
    const timeTravel = CookieService.timeTravelTime

    if (!timeTravel) {
      return ''
    }

    return timeTravel
  }

  get timeTravelIsEnabled(): boolean {
    if (!this.timeTravelTo) {
      return false
    }

    return DateFormat.validate(this.timeTravelTo)
  }
}
