








import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import BasePriceRow from './BasePriceRow.vue'

@Component({
  components: { BasePriceRow },
})
export default class MemberPriceRow extends Vue {
  @Prop({ required: true })
  readonly priceWithTax: string
}
