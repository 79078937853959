import _ from 'lodash'
import { PurchaseEvent } from '../../event'

export function handlePurchase(event: PurchaseEvent): void {
  sendToGA(event)
}

function sendToGA(event: PurchaseEvent): void {
  const payload = convertToGAFormat(event)

  payload.forEach((p) => {
    window.dataLayer.push(p)
  })
}

interface PurchaseToGA {
  event: 'purchase'
  ecommerce: {
    transaction_id: string
    value: number
    shipping: number,
    currency: 'JPY',
    items: Array<{
      item_name: string
      item_id: string
      price: number
      quantity: number
    }>,
  }
}

function convertToGAFormat(event: PurchaseEvent): PurchaseToGA[] {
  const { orderResultSuccessList } = event

  return orderResultSuccessList.map((result) => {
    const subtotal = result.commerceSalesOrder.subtotalPrice
    const shipping = result.commerceSalesOrder.shipping
    const items = _.map(result.order.cartItems, cartItem => ({
      item_name: cartItem.name,
      item_id: cartItem.skuId,
      price: cartItem.unitPrice,
      quantity: cartItem.unitCount,
    }))

    return {
      event: 'purchase',
      ecommerce: {
        transaction_id: result.commerceSalesOrder.compressedShortId,
        /**
         * 税込みでよい。
         * @see https://www.chatwork.com/#!rid243092078-1547435818639949824
         */
        value: subtotal,
        shipping,
        currency: 'JPY',
        items,
      },
    }
  })
}
