
import { Price } from '@/components/molecules/item/price/model/item-price-view'
import {
  GetPaidMemberPriceForFkuParameter,
  PaidMemberItemPriceLoader
} from '@/components/molecules/item/price/model/paid-member-item-price-loader'
import { none, Option } from 'fp-ts/lib/Option'

export const loaderOnPaidMemberPriceDisabled: PaidMemberItemPriceLoader = {
  getPriceForFku: async (_: GetPaidMemberPriceForFkuParameter): Promise<Option<Price>> => none,
}
