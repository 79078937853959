

























































import ItemRating from '@/components/molecules/item/ItemRating.vue'
import ItemPriceWithApiDto from '@/components/molecules/item/price/adapter/with-api-dto/ItemPriceWithApiDto.vue'
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import ColorIcon from '@/components/atoms/ColorIcon.vue'
import BundleDiscountLabelWithStore from '@/components/molecules/discount/BundleDiscountLabelWithStore.vue'

import ApiBrand from '@/models/api/brand'
import { ApiProductclass } from '@/models/api/productclass'
import Category from '@/models/api/tag/category'
import BehaviorConfig from '@/models/app-config/behavior/behavior'
import { ItemDetailField } from '@/models/app-config/behavior/config/item-list/item/detail'
import AppId from '@/util/appid'
import { InstanceResolver } from '@/util/instanceResolver'

@Component({
  components: {
    ItemPrice: ItemPriceWithApiDto,
    BundleDiscountLabelWithStore,
    ColorIcon,
    ItemRating,
  },
})
export default class ItemDetail extends Vue {
  @Prop({ required: true })
  fku: ApiProductclass

  shouldShow(field: ItemDetailField): boolean {
    return this.behaviorConfig.itemList.item.detail.allow(field)
  }

  get behaviorConfig() {
    return BehaviorConfig.createFromBrand(this.brandName)
  }

  get field(): typeof ItemDetailField {
    return ItemDetailField
  }

  get wholeColorFamilies() {
    // this field depend on indexsummary
    return this.fku.wholeColorFamilies.list
  }

  get onMultiInstance(): boolean {
    return InstanceResolver.isMulti()
  }

  get fkuId(): string {
    return this.fku.id
  }

  get fkuAverageRating(): number {
    return this.fku.getAverageRating
  }

  get fkuReviewCount(): number {
    return this.fku.getReviewCount
  }

  get brand(): ApiBrand {
    return this.fku.belongingBrand
  }

  get brandName(): string {
    return this.brand ? this.brand.name : ''
  }

  get brandEnglishName(): string {
    return this.brand ? this.brand.englishName : ''
  }

  get appId(): string {
    return AppId.getByBrandName(this.brandEnglishName)
  }

  get productName(): string {
    return this.fku.productName
  }

  get categories(): Category[] {
    return this.fku.categories
  }
}
