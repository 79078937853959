
import Vue from 'vue'
import { Mixin } from 'vue-mixin-decorator'
import { Prop } from 'vue-property-decorator'

import { log } from '@/log'

import { columnNameToIndexMap } from '../../../../store/modules/searchContactLens/functions'

@Mixin
export default class SearchSysItemCommon extends Vue {
  @Prop()
  row: string[]

  get isMP4() {
    return this.movieUrl.match('mp4')
  }

  get movieUrl() {
    return this.row[columnNameToIndexMap['movieUrl']]
  }

  get fkuUrl() {
    return this.row[columnNameToIndexMap['fkuUrl']]
  }

  get imageUrl() {
    return this.row[columnNameToIndexMap['imageUrl']]
  }

  get lensUrl() {
    return this.row[columnNameToIndexMap['lensUrl']]
  }

  get simpleProductName() {
    return this.row[columnNameToIndexMap['simpleProductName']]
  }

  get colorName() {
    return this.row[columnNameToIndexMap['colorName']]
  }

  get period() {
    return this.row[columnNameToIndexMap['period']]
  }

  get bc() {
    return this.row[columnNameToIndexMap['bc']]
  }

  get dia() {
    return this.row[columnNameToIndexMap['dia']]
  }

  get coloringdia() {
    return this.row[columnNameToIndexMap['coloringdia']]
  }

  get price() {
    const num = Number(this.row[columnNameToIndexMap['price']])

    return `${(num).toLocaleString()}円(税込)`
  }

  get spDrawing() {
    return this.row[columnNameToIndexMap['spDrawing']]
  }

  playMovie() {
    this.$emit('playMovie')

    log.debug('playMovie')
    log.debug(this.movieUrl)
    const video = $('#search_sys_body_menu_sp_modal2_movie')
    video.attr('src', this.movieUrl)
    const videolink = $('#search_sys_body_menu_sp_modal2_movie_link')
    videolink.attr('href', this.fkuUrl)

    video.get(0)['load']()
    video.get(0)['play']()

    $('#logo-animation__loader').show()
    video.get(0).addEventListener('loadeddata', () => {
      $('#logo-animation__loader').hide()
      log.debug('load finish')
    })
  }
}
