import Stock from '@/models/item/stock'
import DisplayRule from '@/models/item/stock/specification/display-rule'

export default class SoldOut implements DisplayRule {
  constructor(private readonly label: string) { }

  shouldApply(stock: Stock): boolean {
    if (stock.display.isDisable) {
      return stock.web.isEmpty
    }

    return stock.display.isEmpty && stock.web.isEmpty
  }
  apply(_: Stock): string {
    return this.label
  }
}
