
import GetStockStatus from '@/usecase/get-stock-status'
import { BundlePurchaseItem } from '../../../bundle-purchase-item'
import { AddToPurchaseListSpec } from '../index'

export default class ItemMustHaveStock implements AddToPurchaseListSpec {
  constructor(private readonly getStockStatus: GetStockStatus) { }

  isSatisfiedBy(item: BundlePurchaseItem): boolean {
    return this.getStockStatus.exists(item.stock)
  }
}
