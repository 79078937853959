






import LoadingPrice from '@/components/molecules/item/price/kind/LoadingPrice.vue'
import { emptyState, PriceRange, PriceViewState } from '@/components/molecules/item/price/model/item-price-view'
import { getSingletonPaidMemberPriceLoaderForPath } from '@/components/molecules/item/price/model/paid-member-item-price-loader'
import BehaviorConfig from '@/models/app-config/behavior/behavior'
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { PriceView, selectPriceView } from '../../model/select-price-view'

@Component
export default class ItemPriceWithPrimitiveValue extends Vue {
  @Prop({ required: true })
  readonly fkuId: string

  @Prop({ required: true })
  readonly priceExists: boolean

  @Prop({ required: true })
  readonly priceWithTax: string

  @Prop({ required: true })
  readonly priceWithoutTax: string

  @Prop({ required: true })
  readonly priceWithRange: boolean

  @Prop({ required: true })
  readonly brand: string

  @Prop({ default: null })
  readonly discountRate: string | null

  @Prop({ required: true })
  readonly skuIds: string

  priceView: PriceView = LoadingPrice
  state: PriceViewState = emptyState()

  created() {
    const brand = this.brand
    const loader = getSingletonPaidMemberPriceLoaderForPath(brand, location.pathname)

    loader.getPriceForFku({ fkuId: this.fkuId, skuIds: this.skuIds.split(',') }).then((maybePaidMemberPrice) => {
      const nonPaidMemberPrice = {
        exists: this.priceExists,
        withTax: this.priceWithTax,
        withoutTax: this.priceWithoutTax,
        range: this.priceWithRange ? PriceRange.some() : PriceRange.none(),
      }

      this.state = maybePaidMemberPrice.foldL<PriceViewState>(
        () => {
          if (this.discountRate !== null) {
            return {
              isOnDiscount: true,
              nonPaidMemberPrice: {
                ...nonPaidMemberPrice,
                discountRate: this.discountRate,
              },
              paidMemberPrice: null,
              showYenSymbol: true,
            }
          }

          return {
            isOnDiscount: false,
            nonPaidMemberPrice,
            paidMemberPrice: null,
            showYenSymbol: true,
          }
        },
        paidMemberPrice => ({
          isOnDiscount: false,
          nonPaidMemberPrice,
          paidMemberPrice,
          showYenSymbol: true,
        })
      )

      this.priceView = selectPriceView(this.state, BehaviorConfig.createFromBrand(this.brand))
    })
  }

  get _state(): PriceViewState {
    const basePrice = {
      exists: this.priceExists,
      withTax: this.priceWithTax,
      withoutTax: this.priceWithoutTax,
      range: this.priceWithRange ? PriceRange.some() : PriceRange.none(),
    }

    if (this.discountRate !== null) {
      return {
        isOnDiscount: true,
        nonPaidMemberPrice: {
          ...basePrice,
          discountRate: this.discountRate,
        },
        paidMemberPrice: null,
        showYenSymbol: true,
      }
    }

    return {
      isOnDiscount: false,
      nonPaidMemberPrice: basePrice,
      paidMemberPrice: null,
      showYenSymbol: true,
    }
  }
}
