import Resource from '@/models/api/resource'

export default class ApiBrand extends Resource {
  static readonly TAG_MAIL_MAGAZINE_ENABLE = 'mycolor.mailmagazine.enable'

  get name(): string {
    return this.get('name', '')
  }

  get englishName(): string {
    return this.get('english_name')
  }

  get companyId(): string {
    return this.get('company_id')
  }

  englishNameIs(englishName: string): boolean {
    return this.englishName === englishName
  }

  idIs(brandId: string | number): boolean {
    return this.id === `${brandId}`
  }

  get isEnabledMailMagazine(): boolean {
    return this.hasTag(ApiBrand.TAG_MAIL_MAGAZINE_ENABLE)
  }

  get isSMBrand(): boolean {
    // company_idが環境によって違うので、 SMb以外のブランドのリストと突合することで判定する
    const notSMBrandList: string[] = [
      'gadgetgirl',
      'beigebeige',
    ]

    return notSMBrandList.indexOf(this.englishName) === -1
  }
}
