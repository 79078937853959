import { URL } from '@/util/url'
import { GetterTree } from 'vuex'
import { RootState } from '../../types'
import { HistoryState } from './types'

export const getters: GetterTree<HistoryState, RootState> = {
  urlToContinueShopping(state): string {
    const { urlToContinueShopping } = state

    return urlToContinueShopping || '/'
  },
  topUrl({ urlToContinueShopping }): string {
    /**
     * MyPaletteで一時的に「TOPページ」の意味を遷移ルートによって変更する必要が生じたため、
     * 環境変数で切替可能にしている.
     *
     * 問題となっているブランドの独自ドメイン移管が済んだら、環境変数ごと削除の想定.
     * このgetterは、今後また別の理由で「TOPページ」の意味を変更しなくてはならなくなった場合
     * （「遷移ルートによって」とは限らない）に備えて、抽象層として残しておく想定.
     * @see https://github.com/my-color/front/issues/4436
     */
    const shouldSwitchTopByBrand = (process.env.SWITCH_TOP_BY_BRAND || 'false') === 'true'

    if (shouldSwitchTopByBrand) {
      return URL.getTopPath(urlToContinueShopping || '/')
    }

    return '/'
  },
}
