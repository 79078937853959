
export type PriceViewState = RegularPriceView | DiscountPriceView | PaidMemberPriceView
export const emptyState: () => PriceViewState = () => ({
  isOnDiscount: false,
  nonPaidMemberPrice: {
    exists: false,
    withTax: '',
    withoutTax: '',
    range: PriceRange.none(),
    discountRate: null,
  },
  paidMemberPrice: null,
  showYenSymbol: true,
})

export interface RegularPriceView {
  isOnDiscount: false
  nonPaidMemberPrice: Price
  paidMemberPrice: null
  showYenSymbol: boolean
}
export interface DiscountPriceView {
  isOnDiscount: true
  nonPaidMemberPrice: DiscountPrice
  paidMemberPrice: null
  showYenSymbol: boolean
}
export interface PaidMemberPriceView {
  isOnDiscount: boolean
  nonPaidMemberPrice: Price
  paidMemberPrice?: Price
  showYenSymbol: boolean
}

export interface Price {
  exists: boolean
  withTax: string
  withoutTax: string
  range: PriceRange
}
interface DiscountPrice extends Price {
  discountRate: string
}

export class PriceRange {
  static some(): PriceRange {
    return new PriceRange('〜')
  }
  static none(): PriceRange {
    return new PriceRange('')
  }

  private constructor(private readonly label: string) {
  }

  public toString(): string {
    return this.label
  }
}
