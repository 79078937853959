import _ from 'lodash'
import { GetterTree } from 'vuex'

import { RootState } from '../../types'
import { PurchaseState } from './types'

export const getters: GetterTree<PurchaseState, RootState> = {
  errorOnLoad(state) {
    return state.error.load
  },

  latest(state) {
    return _.first(state.items)
  },
}
