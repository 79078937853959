import { CartScopeEq, ScopeKind, ScopeValue } from '@/models/cart/group/scope'

export const toScope = (query: string): CartScopeEq => {
  const [kind, value] = query.split('.')

  return {
    kind: ScopeKind.valueOf(kind),
    value: ScopeValue.valueOf(value),
  }
}
export const toQuery = (scope: CartScopeEq): string =>
  `${scope.kind.toString()}.${scope.value.toString()}`
