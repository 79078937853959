














import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component
export default class BaseNavigation extends Vue {
  @Prop({ required: true })
  readonly href: string

  @Prop({ required: true })
  readonly label: string
}
