import { handleFaildToEnterShopError } from '@/entry-limit/error-handling'
import { log } from '@/log'
import { ApiProduct } from '@/models/api/product'
import { OriginalApiErrorResponse } from '@/models/api/response/original-api-error-response'
import { ErrorType } from '@/models/error/api/error'
import { isPaidMembershipPrice, SkuPrice } from '@/models/item/sku'
import { AuthenticationService } from '@/services/auth/authentication'
import { CartService } from '@ajax/modules/services/cart'
import { ProductService as AjaxProductService } from '@ajax/modules/services/product'
import { Either, Left, Right } from 'fp-ts/lib/Either'
import _ from 'lodash'

export default class AddItemToCart {
  constructor(
    private cartService: CartService,
    private ajaxProductService: AjaxProductService
  ) {}

  /**
   * カート追加に成功した場合には ApiProduct.belongingBrand.englishName を返し、それ以外の場合にはエラーメッセージを返す。
   *
   * @return Either<string, string>
   */
  async execute(productId: string, count: number, price: SkuPrice): Promise<Either<string, string>> {
    const authService = AuthenticationService.create()
    const resultRegistrerProvisional = await authService.registerAsProvisilnalIf(loginState => loginState.isGuest())
    if (resultRegistrerProvisional.isLeft()) {
      log.error(resultRegistrerProvisional.value)

      return new Left('カート追加に失敗しました。少し時間を置いてから、もう一度お試しください。<br/>※改善されない場合、お問い合わせよりカスタマーセンターまでお問い合わせください。')
    }

    // Fetch product information
    const result = await this.ajaxProductService.commonItem(productId, { with: 'stock~detail' })
    if (result.isLeft()) {
      return new Left('エラーが発生しました。※改善されない場合、お問い合わせよりカスタマーセンターまでお問い合わせください。')
    }

    const product = new ApiProduct(result.value.body.data)

    if (product.belongingBrand === null) {
      return new Left('商品情報が取得できなかったため、カートに追加できません。')
    }

    const postResult = await this.cartService.post({
      productId,
      unitPrice: price.prices.selected.price,
      isPaidMembershipPrice: isPaidMembershipPrice(price),
      unitCount: count,
    })

    if (postResult.isLeft()) {
      const response = (postResult.value as any).response

      log.error({ response })

      handleFaildToEnterShopError(response)

      return new Left(this.parserErrorResponse(new OriginalApiErrorResponse(response)))
    }

    return new Right(product.belongingBrand.englishName)
  }
  private parserErrorResponse(error: OriginalApiErrorResponse): string {
    if (
      error.contains(ErrorType.NotInStoreError) ||
      error.contains(ErrorType.NotOnSaleError)
    ) {
      return '在庫切れのため、カートに追加できません。'
    }

    /**
     * @see https://github.com/my-color/front/issues/3897
     */
    const messages = _.compact([
      (
        error.contains(ErrorType.UnitCountExceededError) ||
        error.contains(ErrorType.MustWithinRootPurchaseLimit) ||
        error.contains(ErrorType.MustWithinSKUPurchaseLimit)
      ) && '購入上限数に達しているため、カートに追加できません。',
      error.contains(ErrorType.MustWithinLimitTimesOfPurchase)
      && '購入回数上限に達しているため、カートに追加できません。',
      error.contains(ErrorType.MustWithoutMixedPrice)
      && 'ゲスト価格の商品とメンバー価格の商品は同時にご購入いただけません。',
    ])

    log.error({ messages })

    if (messages.length === 0) {
      return 'エラーが発生しました。※改善されない場合、お問い合わせよりカスタマーセンターまでお問い合わせください。'
    }

    return messages.join('<br>')
  }
}
