import ActualCurrentTime from './ActualCurrentTime'
import CurrentTime from './CurrentTime'
import CurrentTimeState from './CurrentTimeState'
import TimeTravelTime from './TimeTravelTime'

export function create(state: CurrentTimeState = new CurrentTimeState()): CurrentTime {
  if (state.timeTravelIsEnabled) {
    return new TimeTravelTime(state.timeTravelTo)
  }

  return new ActualCurrentTime()
}
