import { CartItemList } from '@/models/cart/cart-item'
import { CartGroup, CartGroupList } from '@/models/cart/group/group'
import { CartScope } from '@/models/cart/group/scope'
import { CartGroupRepository } from '@/models/cart/repository/cart-group-repository'
import { ApiClient } from '@/services/api/client'
import { CanConvertRawItem } from '@spa/store/modules/cart/converter'
import _ from 'lodash'

/**
 * TODO: MC Front APIで定義したReadModel準拠
 * 一旦、既存実装への影響を避けるため、APIからはEngineリソースをそのまま返している.
 * 段階的にClient側に記述されている諸々の実装をAPIへ移動させ、
 * 最終的には、Client側は原則、APIが提供するデータ型に基づいて表示する.
 */
type RawCartItem = object & {
  id: string
}
interface CartGroupResponse {
  body: {
    data: CartGroupRecord[]
  }
}
interface CartGroupRecord {
  scope: Scope
  items: {
    data: RawCartItem[]
  }
}

interface Scope {
  kind: string
  value: string
  label: string
}

interface ScopeCartIdTuple {
  scope: Scope
  cartIds: string[]
}
const buildTuple = (records: CartGroupRecord[]): ScopeCartIdTuple[] =>
  records.map(r => ({
    scope: r.scope,
    cartIds: r.items.data.map(i => i.id),
  }))

export class DefaultCartGroupRepository implements CartGroupRepository {
  constructor(
    private readonly apiClient: typeof ApiClient,
    private readonly converter: CanConvertRawItem
  ) {
  }
  async list(): Promise<CartGroupList> {
    const response: CartGroupResponse = await this.apiClient.get('/api/user/cart/scope')
    const records = response.body.data

    const rawItems: RawCartItem[] = records.reduce(
      (prev: RawCartItem[], r: CartGroupRecord) => [...prev, ...r.items.data],
      []
    )
    const tuples = buildTuple(records)
    const cartItems = await this.converter.convert(rawItems)

    const groups = tuples.map(t => CartGroup.create({
      scope: CartScope.valueOf(t.scope),
      items: new CartItemList(
        cartItems.filter(
          i => _.includes(t.cartIds, i.id)
        )
      ),
    }))

    return CartGroupList.create(groups)
  }
}
