import LocalStorageRepository from './local-storage-repository'

export interface RecentlyViewedRepository {
  list(): string[]
  listOnly(limit: number): RecentlyViewedRepository
  updateMostRecent(id: string): void
  overwrite(ids: string[]): void
  clearAll(): void
}

export const instance: RecentlyViewedRepository = new LocalStorageRepository()
