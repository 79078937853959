import ShippingFee from './index'

/**
 * 合計金額に応じて無料となる送料
 */
export default class FreeShippingByTotalPrice implements ShippingFee {
  static create(border: number, totalPrice: number): ShippingFee {
    return new FreeShippingByTotalPrice(border, totalPrice)
  }

  static createWithDefaultBorder(totalPrice: number): ShippingFee {
    const defaultBorder = 10000

    return new FreeShippingByTotalPrice(defaultBorder, totalPrice)
  }

  readonly fee = 0

  private constructor(
    readonly border: number,
    readonly totalPrice: number
  ) { }

  canApply(): boolean {
    return this.totalPrice >= this.border
  }

  remainingPriceUntilFreeShipping(): number | null {
    const diff = this.border - this.totalPrice

    return diff > 0 ? diff : 0
  }
}
