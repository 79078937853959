import { Option, Some } from 'fp-ts/lib/Option'

import IStock from '@/models/item/stock'
import {
  DisableDisplayStockValue,
  DisplayStockValue,
  EnableDisplayStockValue,
} from '@/models/item/stock/value/display-stock-value'
import WebStockValue from '@/models/item/stock/value/web-stock'

export default class Stock implements IStock {
  static get empty(): Stock {
    return new Stock(
      WebStockValue.empty,
      new Some(EnableDisplayStockValue.empty)
    )
  }
  constructor(
    readonly web: WebStockValue,
    private readonly _display: Option<DisplayStockValue>
  ) { }

  get display(): DisplayStockValue {
    return this._display.getOrElse(DisableDisplayStockValue.value)
  }
}
