import Urn from '@/models/api/resource/urn'

export interface KuronekoConfig {
  memberIdSuffix: string
  cardIdToToken(cardId: string, cardToken: string): string
}

export const kuronekoConfigForPurchase: KuronekoConfig = {
  memberIdSuffix: '',
  cardIdToToken(cardId: string, cardToken: string): string {
    return new Urn(cardId).setName(cardToken).toString
  },
}

export const kuronekoConfigForSubscription: KuronekoConfig = {
  /**
   * サブスクリプションの場合、特殊なsuffixが必要
   * @see https://my-color.esa.io/posts/707#kuroneko%20%E3%81%AE%E5%A0%B4%E5%90%88
   */
  memberIdSuffix: '-subscription',
  /**
   * サブスクリプションの場合、トークン化はEngineドライバ側で実施するので、Frontではむしろトークン化してはいけない.
   * Engineから取得できたカードのIDをそのまま利用する.
   *
   * @param cardId
   * @param _cardToken
   * @see https://github.com/my-color/front/issues/5818
   */
  cardIdToToken(cardId: string, _cardToken: string): string {
    return cardId
  },
}
