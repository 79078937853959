import { ApiProduct } from '@/models/api/product'
import { StockParsePolicy } from '@/models/api/product/stock-parse-policy'
import _ from 'lodash'

export default class ApiStock {
  static createFromProduct(
    product: ApiProduct,
    policy: StockParsePolicy
  ): ApiStock {
    return new ApiStock(product, policy)
  }

  /**
   * with=stock~detail指定時のstock.stock_quantityの値。
   * with=indexsummary指定時はこれに該当する値が取得できないので、Frontで計算している。
   * @see UseIndexSummary.parseQuantity
   *
   * ただし、そもそもこの値が使われるべきであるかは要確認
   * - 古いコード (Util) でしか使われていない
   * - 在庫に関してはドメインモデルとして別途整理されている
   *
   * @deprecated
   */
  public readonly quantity: number
  public readonly salesStock: number
  public readonly prospectStock: number
  public readonly intentionStock: number | null

  constructor(
    product: ApiProduct,
    policy: StockParsePolicy
  ) {
    this.quantity = policy.parseQuantity(product)
    this.salesStock = policy.parseSalesQuantity(product)
    this.prospectStock = policy.parseProspectQuantity(product)
    this.intentionStock = policy.parseIntentionQuantity(product)
  }
}
