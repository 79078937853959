import SHA256 from "crypto-js/sha256";
import { extractUniqueIdFromUniversalId } from "./util";

// tslint:disable

export function executeUniversalTag() {
  (function(b, f, d, a, c) {
    var e = b.createElement(f);
    e.src = c + "/" + a + "/atm.js";
    e.id = d;
    e.async = true;
    b.getElementsByTagName(f)[0].parentElement.appendChild(e);
  })(document, "script", "__cribnotesTagMgrCmd", "d2ed4332-a612-485c-9f1e-00535ff019f4", "https://tag.cribnotes.jp/container_manager");
}

export function executeConversionTag(transactionId, items) {
  // ショートIDが取得できなかった場合、調査用途のために元のIDを計上する
  const encryptTargetId = extractUniqueIdFromUniversalId(transactionId) || transactionId

  const encryptedTransactionId = cribnotesInstructedEncrypt(encryptTargetId)

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    '__cribnotes': {
      'transaction_id': encryptedTransactionId,
      'thanks_id': '1000555000',
      items: items
    }
  });

  executeUniversalTag();
}

/**
 * window.dataLayer.__cribnotes.transaction_idに設定するIDをクリブノーツの指示に従って暗号化する
 * sha256と返却する文字列は指示通りアンダーバーで連結する
 * @param transactionId
 * @returns {*}
 */
function cribnotesInstructedEncrypt(transactionId) {
  const salt = '8gzVOw6qkdy$,oogkf,u5jZq5drzc{Xf';

  const hash = SHA256(`${transactionId}_${salt}`)

  return encodeURIComponent(`${transactionId}_${hash}`)
}
