import AppId from '@/util/appid'
import { Either, Left, Right } from 'fp-ts/lib/Either'
import _ from 'lodash'
import { Response } from 'superagent'
import uuidv4 from 'uuid/v4'

import { log } from '@/log'
import { CommercePurchaseAssign } from '@/models/api/commerce_purchase'
import { SelectOptionList } from '@/models/api/payment/parameter/type/user-select'
import { ApplyParameter as ApplyBundleParameter, BundleDiscount } from '@/services/api/bundle-discount'
import { ApiService } from '@/services/api/service'
import ParametersBuilder from '@/services/payment/parameters-builder'
import { Payment } from '@spa/store/modules/orderConfirm/types'
import { ApiClient } from './client'

const namespace: string = 'purchase'

export interface PurchaseCreateResult {
  salesOrders: SalesOrder[]
}
export interface SalesOrder {
  sales_order_id: string
}

// This interface is also used from OrderHistory charge_sheet.
// FIXME: This is rather 'Price' section in estimate response.
export interface Estimate {
  delivery: PriceIncludingTax | PriceExcludingTax
  order: PriceIncludingTax
  payment: PriceNoTax
  tax: number
  total: number
}

export interface PurchaseEstimate {
  price: FrontPriceSection
  deliveryPlan: any,
  applying_bundle_discount: ApplyingBundleDiscount
  point: {
    amount: number
  }
}

export interface ApplyingBundleDiscount {
  applied_discounts: AppliedBundleDiscount[]
  bundle_discounts: BundleDiscount[]
}

export interface AppliedBundleDiscount {
  bundle_discount_id: string
  applied_to: BundleDiscountedItem[]
  discount: {
    value: number
    value_type: string  // TODO: Use Enum
    condition: {
      name: string
      value: number
      operator: string  // TODO: Use Enum
    }
  }
  discounted_price: number
  discount_amount: number
}

export interface BundleDiscountedItem {
  root_id: string
  fku_id: string
  sku_id: string
  count: number
  unit_price: number
}

export type FrontPriceSection = Estimate & {
  discount?: Discount
  service_fee: PriceIncludingTax
}

interface PriceExcludingTax {
  priceExcludingTax: EstimatePrice
}
interface PriceIncludingTax {
  priceIncludingTax: EstimatePrice
}
interface PriceNoTax {
  priceNoTax: EstimatePrice
}
interface EstimatePrice {
  price: number
  tax: number
}

interface Discount {
  amount: number
  coupon: number
  bundle: number
}

export function extractAppliedBundleDiscounts(purchaseEstimates: PurchaseEstimate[]): AppliedBundleDiscount[] {
  return _.flatMap(purchaseEstimates, estimate =>
    estimate.applying_bundle_discount.applied_discounts
  )
}

export class PurchaseService extends ApiService {

  async list(): Promise<Either<Error, Response>> {
    try {
      const response = await ApiClient
        .get(`/api/user-proxy/${this.appId}/commerce_purchase`)

      log.debug({ service: `${namespace}/list`, response })

      return new Right(response)
    } catch (error) {
      log.error({ error })

      return new Left(error)
    }
  }

  async create(payload: any): Promise<Either<ErrorWithResponse, Response>> {
    try {
      const brand = AppId.getBrandNameByAppId(this.appId)
      const response = await ApiClient
        .post(`/api/${brand}/commerce_purchase`)
        .send(payload)

      // CloudFrontを通すと500系エラーはJSONでなくエラー画像に差し替えられるためAPIはエラーでも200で返すよう実装している。
      // successフィールドを確認し、falseであればresponseを返しつつ失敗扱いにする。
      // @see https://www.chatwork.com/#!rid87550748-1430792847103139840
      if (!response.body.success) {
        log.error({ service: `${namespace}/create`, response })

        return new Left({
          ...(new Error('commerce_purchase failed')),
          response,
        })
      }

      log.debug({ service: `${namespace}/create`, response })

      return new Right(response)
    } catch (error) {
      log.error({ error })

      return new Left(error)
    }
  }

  async createProvisionalOrderId(): Promise<Either<Error, string>> {
    try {
      const response = await ApiClient
        .post(`/api/user-proxy/${this.appId}/commerce_purchase_assign`)
        .query({ uuid: uuidv4() })  // Workaround for avoiding iOS Safari cached POST response

      const { sales_order_id: id } = response.body.data as CommercePurchaseAssign

      if (!id) {
        throw new Error('commerce_purchase_assign returns invalid data')
      }

      log.debug({ service: `${namespace}/create`, response })

      return new Right(id)
    } catch (error) {
      log.error({ error })

      return new Left(error)
    }
  }

  async supercede(payload: any): Promise<Either<Error, Response>> {
    try {
      const response = await ApiClient
        .post(`/api/user-proxy/${this.appId}/commerce_purchase_supercede`)
        .send(payload)

      log.debug({ service: `${namespace}/supercede`, response })

      return new Right(response)
    } catch (error) {
      log.error({ error })

      return new Left(error)
    }
  }

  // tslint:disable-next-line:max-line-length
  async getEstimate(estimate: GetEstimateParameter, bundle: ApplyBundleParameter): Promise<Either<Error, Response>> {
    try {
      const { cartIds, payment, deliveryId, deliveryMethod, discount } = estimate

      const makePaymentParamResult = this.makeEstimatePaymentParam(payment)
      if (makePaymentParamResult.isLeft()) {
        return new Left(makePaymentParamResult.value)
      }

      const response = await ApiClient
        .post(this.estimatePath)
        .query({
          cart_ids: cartIds,
          payment: makePaymentParamResult.value,
          delivery: JSON.stringify({
            delivery_type: 'registered',
            id: deliveryId,
            delivery_method: deliveryMethod,
          }),
          ...discount && {discount:
            JSON.stringify(discount),
          },
        })
        .send({
          // This key mapping is not more than conventional.
          bundle_discount: {
            items: JSON.stringify(bundle.items),
          },
        })

      log.debug({ service: `${namespace}/getEstimate`, response })

      return new Right(response)
    } catch (error) {
      log.error({ error })

      return new Left(error)
    }
  }
  get estimatePath(): string {
    /*
     * 今の所、estimate は ホストレベルかブランドスコープの二択のみ
     * @see https://github.com/my-color/front/pull/4905#discussion_r569286967
     */
    return AppId.isHostId(this.appId) ?
      '/api/user/order/estimate' :
      `/api/user/order/brand/${AppId.getBrandNameByAppId(this.appId)}/estimate`
  }

  makeEstimatePaymentParam(payment: Payment): Either<Error, string> {
    if (!payment.apiPayment) {
      return new Right(
        JSON.stringify(this.makeEstimatePaymentParamOld(payment))
      )
    }

    const result = ParametersBuilder.create(
      payment.apiPayment.parametersForEstimate,
      payment.selectedOptionList || SelectOptionList.empty(),
      payment.variableResource || {}
    ).build()
    if (result.isLeft()) {
      const message = 'Cannot build parameters for commerce_purchase_estimate.'
      log.error({
        message,
        errors: result.value,
      })

      return new Left(new Error(message))
    }

    // ポイントは決済方法に関わらずPaymentペイロードに入れてよい値なので、
    // 例外的に最後に追加する実装を取っている。
    const { point } = payment

    return new Right(JSON.stringify({
      ...result.value,
      ...point && { point },
    }))
  }

  /**
   * 廃止し、makeEstimatePaymentParamの方法に寄せる予定
   */
  makeEstimatePaymentParamOld(payment: Payment): any {
    const { payment_type, token, paymentid, auth_token, point } = payment

    return {
      payment_type,
      ...token && { token },
      ...(paymentid || paymentid === '') && { paymentid },
      ...(auth_token || auth_token === '') && { auth_token },
      ...point && { point },
    }
  }

  async getMenu(): Promise<Either<Error, Response>> {
    try {
      const response = await ApiClient
        .get(`/api/common-proxy/${this.appId}/commerce_purchase_menu`)

      log.debug({ service: `${namespace}/getMenu`, response })

      return new Right(response)
    } catch (error) {
      log.error({ error })

      return new Left(error)
    }
  }

  calcFeeIncludingTax(paymentMenu: object, paymentMethod: string, cartPrice: number): number | undefined {
    if (paymentMethod === 'creditcard') {
      return 0
    }

    const rules = _.get(paymentMenu, `${paymentMethod}.price`)

    const validRule = _.find(rules, (rule) => {
      return isLower(cartPrice, _.get(rule, 'price.lower.type'), _.get(rule, 'price.lower.value')) &&
      isHigher(cartPrice, _.get(rule, 'price.higher.type'), _.get(rule, 'price.higher.value'))
    })

    if (!validRule) {
      log.error('/commerce_purchase_menu defines invalid range')

      return
    }

    return _.get(validRule, 'fee.priceIncludingTax')
  }
}

function isLower(price: number, type: 'open'|'closed'|'unbound', lower?: number): boolean | undefined {
  if (type === 'unbound') {
    return true
  }

  if (lower === undefined) {
    return false
  }

  if (type === 'closed') {
    return price >= lower
  }

  if (type === 'open') {
    return price > lower
  }
}

function isHigher(price: number, type: 'open'|'closed'|'unbound', higher?: number): boolean | undefined {
  if (type === 'unbound') {
    return true
  }

  if (higher === undefined) {
    return false
  }

  if (type === 'closed') {
    return price <= higher
  }

  if (type === 'open') {
    return price < higher
  }
}

export interface GetEstimateParameter {
  cartIds: string,
  payment: Payment,
  deliveryId: string,
  deliveryMethod: string,
  discount?: {
    coupon?: CouponEstimateParameter,
  },
}

/**
 * 本来であればclientからserver側にクーポンIDのみを渡して、クーポンの適用可否（商品指定の設定含む）や割引額をserver側で算出したい。
 * 現状そうなっていないため、client側ですでに取得済みのそうした値を送信する必要があり、この型はそのためのデータ型である。
 * ここで要求されている値の内容は、以下の関心事による。
 * - 決済金額に関する見積もり
 * - ポイント適用価格に関する見積もり
 */
export interface CouponEstimateParameter {
  id: string,
  amount: number,
  specifiedItems?: string[],
}

interface ErrorWithResponse extends Error {
  response?: Response
}
