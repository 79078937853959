import * as Parallel from 'async-parallel'
import { Either, left, right } from 'fp-ts/lib/Either'
import { none, Option, some } from 'fp-ts/lib/Option'

import { log } from '@/log'
import { ApiClient } from '@/services/api/client'
import { callWithAuth } from '@/services/auth/call-with-auth'
import { CartService as AjaxCartService } from '@ajax/modules/services/cart'
import { CartService as SpaCartService } from '@spa/services/api/cart'
import { ValidPrice } from './scheme'

const namespace: string = 'cart'

export interface ReplacePriceRepository {
  /**
   * 指定された各IDのカートの価格を変更する。
   * @deprecated current_valid_priceが価格更新まで行うようになったのでこのメソッドは廃止
   * マージを優先してコメントにとどめている
   * @see https://github.com/my-color/front/pull/6157#discussion_r831727659
   */
  replace(paramList: ReplaceCartPriceParameter[]): Promise<Option<Error>>

  /**
   * ユーザのカート内商品に対する最新有効価格、およびそれとカート内価格の異同を返す。
   * また、異同がある場合にはEngineのcommerce_user_cartリソースの更新を行う。
   *
   * 将来的には /api/user/cart をGETしたら常に最新有効価格が取得できるようにし、
   * また、その背後でEngineリソースの更新も行うようにすることを検討する。
   * @see https://github.com/my-color/front/pull/6157#issuecomment-1050318090
   */
  updateCurrentValidPrice(): Promise<Either<Error, ValidPrice>>
}

/**
 * spa, ajaxの双方にCartServiceが存在してしまっているため、ここで集約し、
 * いずれはspa/ajaxにあるCartServiceは消滅させる。
 */
export class DefaultReplacePriceRepository implements ReplacePriceRepository {
  static create(appId: string): DefaultReplacePriceRepository {
    return new DefaultReplacePriceRepository(appId)
  }

  private constructor(private readonly appId: string) {}

  private get ajaxCartService(): AjaxCartService {
    return new AjaxCartService(this.appId)
  }

  private get spaCartService(): SpaCartService {
    return new SpaCartService(this.appId)
  }

  /**
   * @inheritdoc
   * 内部実装としては、カートリソースを削除して、新しい価格を持ったカートリソースを追加している。
   */
  public async replace(paramList: ReplaceCartPriceParameter[]): Promise<Option<Error>> {
    try {
      await Parallel.map(paramList, async (param) => {
        await this.spaCartService.remove(param.cartId)
        /**
         * Ajaxの方が本来のユーザによるカート追加を担当していたため、より改善されたserver側のカート追加APIの方に繋がっている。
         */
        await this.ajaxCartService.post({
          productId: param.skuId,
          unitPrice: param.unitPrice,
          isPaidMembershipPrice: param.isPaidMembershipPrice,
          unitCount: param.unitCount,
        })
      })

      return none
    } catch (error) {
      return some(error)
    }
  }

  public async updateCurrentValidPrice(): Promise<Either<Error, ValidPrice>> {
    try {
      const response = await callWithAuth(() => ApiClient.put(`/api/user/cart/current_valid_price`))

      log.debug({ service: `${namespace}/listLatestPrice`, response })

      return right(response.body.data as ValidPrice)
    } catch (error) {
      log.error({ error })

      return left(error)
    }
  }
}

export class ReplaceCartPriceParameter {
  cartId: string
  skuId: string
  unitPrice: number
  isPaidMembershipPrice: boolean
  unitCount: number
}
