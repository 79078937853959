






















































import { Either } from 'fp-ts/lib/Either'
import _ from 'lodash'
import isInt from 'validator/lib/isInt'
import Vue from 'vue'
import { Component, Emit, Prop } from 'vue-property-decorator'

import Spinner from '@/components/atoms/Spinner.vue'
import DummyItem from '@/components/molecules/DummyItem.vue'
import RankingLabel from '@/components/molecules/item-list/RankingLabel.vue'
import Item from '@/components/molecules/ItemWithLink.vue'
import AutoLoadContainer from '@/components/organisms/auto-load/AutoLoadContainer.vue'
import { AutoLoadComplete, AutoLoadError } from '@/components/organisms/auto-load/model'
import PaginatedItemListContainer from '@/components/organisms/item-list/PaginatedContainer.vue'
import { log } from '@/log'
import { ListWithPagination, Pagination } from '@/models/api/response/list'
import { PageCollection } from '@/models/page'
import { URL } from '@/util/url'
import { forceUpdateWindowHistoryUrl } from '@/util/windowHistory'
import PaginationCounter from './PaginationCounter.vue'

import { ItemKind } from '../../../models/item/kind'

import SelectThumbnailImageBuilder from '@/services/product-image/select-thumbnail-image-builder'

@Component({
  components: {
    AutoLoadContainer,
    DummyItem,
    Item,
    PaginatedItemListContainer,
    RankingLabel,
    Spinner,
    PaginationCounter,
  },
})
export default class AutoLoadableItemList extends Vue {
  static readonly COLUMN_MIN = 1
  static readonly COLUMN_MAX = 10

  @Prop({ required: true })
  fetchCallback: (baseQuery: object) => Promise<Either<Error, ListWithPagination<any>>> // productclass[]

  // この場合'none'は「ランキングデザインを適用しない」を意味する
  @Prop({ default: 'none' })
  displayType: string

  // The minimum page number which is under the Vue control.
  // ex. If MPA renders the first page, basePage will be 2.
  @Prop({ default: 1 })
  basePage: number

  @Prop({
    default: 4,
    validator: value => (
      AutoLoadableItemList.COLUMN_MIN <= value && value <= AutoLoadableItemList.COLUMN_MAX
    ),
  })
  column: number

  @Prop({ default: false })
  instaview: boolean

  @Prop({ default: 30 })
  loadRange: number

  @Prop({ default: 'div' })
  listTag: string

  @Prop({ default: 'div' })
  itemTag: string

  @Prop({ default: true })
  shouldUpdatePageQuery

  @Prop({ default: false })
  dynamicMode: boolean

  @Prop({ default: true })
  prefetch: boolean

  @Prop({ default: false })
  counterMode: boolean

  @Prop({ default: 'default' })
  itemKind: ItemKind

  @Prop({ default: () => SelectThumbnailImageBuilder.createDefault() })
  selectThumbnailImageBuilder: SelectThumbnailImageBuilder

  @Prop({ default: false })
  isSpa: boolean

  @Prop({ default: false })
  showAddToCartButton: boolean

  isAfterJump: boolean = false
  initialPageQuery: number = 1
  currentPage: number = 1

  private lastPage: number | null = null

  get query(): object {
    return {
      'part.status_id': 1,
    }
  }

  get itemClass(): object {
    const baseClass = {
      'p-goods-wrapper': true,
      'p-goods-wrapper--contact-lens': this.itemKind === 'contact-lens',
      [`p-${this.column}column`]: true,
      'px-0': this.instaview,
      'p-2': true,
    }
    if (this.dynamicMode) {
      return baseClass
    }

    const extraClass = {
      'p-2column': true,
    }

    return {
      ...baseClass,
      ...extraClass,
    }
  }

  get isRanking(): boolean {
    return this.displayType === 'ranking'
  }

  makeRankingNumber(arrayIndex: number, pageNumber: number): number {
    return (pageNumber - 1) * this.loadRange + arrayIndex + 1
  }

  shouldShowPages<T>(pages: PageCollection<T>): boolean {
    if (this.initialPageQuery < this.basePage) {
      return true
    }

    return !!pages.find(this.initialPageQuery)
  }

  created() {
    const page = this.parsePageQuery()

    if (!page) {
      return  // Skip when no page is specified
    }

    // Setting initialPageQuery will cause 'onPageChange' event handler
    this.initialPageQuery = page
    this.currentPage = page
  }

  /**
   * :pensive: https://github.com/my-color/front/issues/3126 のadhoc対応
   *
   * #complete および #onVisiblePageChange において、↑のissueのachoc対応を行った.
   * 対応内容の詳細はissue参照.
   *
   * この対応では、未だ
   * - page=9999 等、意図的に範囲外のページを指定した場合
   * - サイト回遊中に大量の商品が非公開にされ、商品一覧の最終ページが大きく後退した場合
   * これらで同様の問題が発生する.
   *
   * この現象の根本対応には、そもそものページネーションの状態管理を見直し、
   * また、ページネーション/オートロードにおける状態管理の設計が必要と思われる.
   * 今回はhotfix対応だったこともあり、一旦は通常操作では発生しないように処置するに留めた.
   *
   * TODO: ページネーション/オートロードにおける状態管理の、概念レベルの再設計
   * TODO: 設計を反映する形での実装整理あるいは再実装
   */
  @Emit()
  complete(result: AutoLoadComplete) {
    if (result.pages.isEmpty) {
      log.info('any pages not found. maybe could not find any items which matched query')

      return
    }

    const lastLoadedPage = result.pages.list[result.pages.list.length - 1]
    if (!lastLoadedPage) {
      log.error('when autoload completed, at least one page must exist, but not found!!')

      return result
    }

    if (lastLoadedPage.count === 0) {
      this.lastPage = lastLoadedPage.number - 1

      return result
    }

    this.lastPage = lastLoadedPage.number
  }

  @Emit()
  error(_error: AutoLoadError) {
    // nothing to do
  }

  onVisiblePageChange(newPage: number) {
    if (!this.shouldUpdatePageQuery) {
      return
    }
    const page = (() => {
      if (this.lastPage === null) {
        return newPage
      }

      return newPage > this.lastPage ? this.lastPage : newPage
    })()

    this.updatePageQuery(page)
    this.currentPage = page
  }

  onAfterJump() {
    this.isAfterJump = true
  }

  shouldShowPaginationCounter(counterMode: boolean, pagination: Pagination | null): boolean {
    return counterMode && pagination != null && pagination.total > 0
  }

  paginationCounterCurrent(perPage: number, pagination: Pagination): number {
    return Math.min(this.currentPage * perPage, pagination.total)
  }

  private updatePageQuery(newPage: number) {
    const fullPath = this.isSpa ? `/s${this.$route.fullPath}` : this.$route.fullPath
    const url =  URL.merge(fullPath).with(`?page=${newPage}`)
    forceUpdateWindowHistoryUrl(url)
  }

  private parsePageQuery(): number | null {
    const pageQuery = this.$route.query.page

    if (!pageQuery || typeof pageQuery !== 'string') {
      return null
    }

    if (!this.pageQueryIsValid(pageQuery)) {
      return null
    }

    return parseInt(pageQuery, 10)
  }

  private pageQueryIsValid(pageQuery: string): boolean {
    return isInt(pageQuery, { min: this.basePage })
  }
}
