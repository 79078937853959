import $ from 'jquery'

import throttle from 'lodash/throttle'

import Vue from 'vue'
import { Mixin } from 'vue-mixin-decorator'
import { Prop, Watch } from 'vue-property-decorator'

type EventHandler = (e: TouchEvent) => void

interface EventFreeze {
  onStart: EventHandler
  onMove: EventHandler
}

@Mixin
export default class LockBackground extends Vue {
  static readonly lockStyle = 'u-scroll--lock'

  rootBackground = $('html,body')

  @Prop({ default: true })
  lock: boolean

  @Prop({ default: false })
  keep: boolean

  get scrollable(): HTMLElement {
    // hook method. override prevent touch move on specify class dom.
    // default return null
    return this.$el as HTMLElement
  }

  mounted() {
    if (this.lock) {
      this.doLock()
    }
  }
  beforeDestroy() {
    if (this.keep) {
      return
    }

    this.doUnlock()
  }

  @Watch('lock')
  onChangeLock() {
    this.lock ? this.doLock() : this.doUnlock()
  }

  private get freezeBackground(): EventFreeze {
    const store = {
      onTopEnd: true,
      onBottomEnd: true,
      touch: {
        prevY: 0,
      },
    }

    const onStart = (e: TouchEvent) => {
      const target = this.scrollable

      const currentTop = target.scrollTop

      store.onTopEnd = currentTop <= 0
      store.onBottomEnd = currentTop >= target.scrollHeight - target.offsetHeight

      store.touch.prevY = e.targetTouches[0].pageY
    }
    const onMove = (e: TouchEvent) => {
      const currentY = e.targetTouches[0].pageY
      const prevY = store.touch.prevY
      store.touch.prevY = currentY

      const toUp = currentY > prevY
      const toDown = currentY < prevY

      if (toUp && store.onTopEnd) {
        e.preventDefault()

        return
      }
      if (toDown && store.onBottomEnd) {
        e.preventDefault()

        return
      }
    }

    // Vue.jsのcomputedは、依存するリアクティブプロパティが更新されない限り、2回目はキャッシュを返す.
    return {
      onStart,
      onMove: throttle(onMove),
    }
  }

  private doLock() {
    this.rootBackground.addClass(LockBackground.lockStyle)

    const { onStart, onMove } = this.freezeBackground

    // https://www.imuza.com/entry/2018/06/12/172357
    // iOS + Safariで、背景のバウンスを防ぎ、コンテンツのスクロールが阻害されないようにする.
    document.body.addEventListener('touchstart', onStart)
    document.body.addEventListener('touchmove', onMove, { passive: false })
  }
  private doUnlock() {
    this.rootBackground.removeClass(LockBackground.lockStyle)

    const { onStart, onMove } = this.freezeBackground

    // iOS + Safari対策の後始末
    document.body.removeEventListener('touchstart', onStart)
    document.body.removeEventListener('touchmove', onMove)
  }
}
