import { Either, Left } from 'fp-ts/lib/Either'
import _ from 'lodash'
import superagent from 'superagent'

import ApiBrand from '@/models/api/brand'
import { ApiTag, RawTag } from '@/models/api/tags'
import { BrandService } from '@/services/api/brand'
import { TagsService } from './api/tags'
import UserService from './api/user'

/**
 * メルマガをtagsで運用している点について
 * https://github.com/my-color/front/pull/1894
 * https://github.com/my-color/front/pull/1894#issuecomment-512081188
 * https://www.chatwork.com/#!rid87550748-1203171264068321280
 * の議論を参照.
 *
 * 将来的にはappresourceでの運用(メルマガ実装当時は存在せず)に切り替わるかもしれない.
 *
 * メルマガの有効設定がブランドにタグ登録する形で実現されている点について
 * 上記の議論を参照.
 *
 * tagにタグ追加が可能であれば、メルマガタグに有効タグを付与する形に移行する可能性あり.
 * 直近、 https://github.com/my-color/front/issues/1869 の対応としては
 * 「ブランドにタグ追加」の方針で実施.
 */
export class MailMagazineService {
  static readonly tagDirectory: string[] = [
    'mycolor',
    'mailmagazine',
  ]

  private tagsService: TagsService
  private userService: UserService
  private brandService: BrandService

  constructor(appId?: string) {
    this.tagsService = new TagsService(appId)
    this.userService = new UserService(appId)
    this.brandService = new BrandService()
  }

  async list(query: any = {}): Promise<Either<Error, RawTag[]>> {
    return this.listMagazines(query, q => this.tagsService.list(q))
  }
  async listAsUser(query: any = {}): Promise<Either<Error, RawTag[]>> {
    return this.listMagazines(query, q => this.tagsService.listAsUser(q))
  }

  async subscribe(userId: string, pathnames: string[]): Promise<Either<Error, superagent.Response>>  {
    return this.userService.follow(userId, pathnames)
  }
  async changeSubscriptionStatus(userId: string, subscriptions: string[], unSubscriptions: string[]) {
    return this.userService.changeFollowStatus(userId, subscriptions, unSubscriptions)
  }

  private async listMagazines(
    query: object = {},
    loader: (query: object) => Promise<Either<Error, RawTag[]>>
  ): Promise<Either<Error, RawTag[]>> {
    const [
      mailMagazineTagsResult,
      brandsResult,
    ] = await Promise.all([
      loader({
        scope: 'tree',
        pathname: MailMagazineService.tagDirectory.join('.'),
        ...query,
      }),
      this.brandService.list(),
    ])

    if (brandsResult.isLeft()) {
      return new Left(brandsResult.value)
    }

    const brandsPublishingMailMagazine = (brandsResult.value.body.data as any[])
      .map(b => new ApiBrand(b))
      .filter(brand => brand.isEnabledMailMagazine)

    return mailMagazineTagsResult
      .map(raw => new ApiTag(raw))
      .map(mailMagazineTag =>
       _.flatten(
         mailMagazineTag.children.map(company => company.children)
        )
      )
      .map(tags =>
        tags.filter(
          this.mailMagazineIsEnabledByBrand(brandsPublishingMailMagazine)
        )
      )
      // TODO: make mail magazine component use ApiTag instead of raw tag
      .map(brandTags => brandTags.map(t => t.toRaw()))
  }

  private mailMagazineIsEnabledByBrand(brands: ApiBrand[]): (tag: ApiTag) => boolean {
    return (tag: ApiTag) => brands.some(
      brand => (new RegExp(`^.+\.${brand.englishName}$`)).test(tag.pathname)
    )
  }
}
