




























































































import CartInNotification from '@/components/organisms/dialog/ErrorDialog.vue'
import BehaviorConfig from '@/models/app-config/behavior/behavior'
import AddItemToCart from '@/models/cart/command/add-item-to-cart'
import ContactLensFKURepository from '@/models/item/fku/repository'
import ContactLensRootRepository from '@/models/item/root/repository'
import ContactLensSKU from '@/models/item/sku/contact-lens'
import { DefaultFetchSkuPrice } from '@/models/item/sku/repository/impl/fetch-sku-price'
import AppId from '@/util/appid'
import { BrandRouteResolver } from '@/util/brandRouteResolver'
import { CartService } from '@ajax/modules/services/cart'
import { ProductService as AjaxProductService } from '@ajax/modules/services/product'
import Util from '@ajax/modules/util'
import { foldAddResult } from '@ajax/vue/components/organisms/contact-lens/model/fold-add-result'
import { log } from '@spa/log'
import { Either, left, right } from 'fp-ts/lib/Either'
import _ from 'lodash'
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import ContactLensItemDetailStore from './store'
import ContactLensItemDetailStoreBuilder from './store-builder'

@Component({
  components: {
    CartInNotification,
  },
})
export default class ContactLensCartIn extends Vue {
  @Prop({ required: true })
  id: string

  store: ContactLensItemDetailStore | null = null

  addToCartStatus: ProcessStatus = ProcessStatus.asNotProcessing()
  cartInNotificationMsg: string = ''

  private cartService: CartService | null = null
  private ajaxProductService: AjaxProductService | null = null

  async created() {
    try {
      const brandName = BrandRouteResolver.resolveBrandFromPathElement(this.$route.params.brand_english_name)
      const appId = AppId.getByBrandName(brandName)
      const storeBuilder = ContactLensItemDetailStoreBuilder.getInstance(
        new ContactLensRootRepository(appId),
        new ContactLensFKURepository(appId)
      )
      this.store = await storeBuilder.build(
        this.id,
        this.behavior
      )

      this.cartService = CartService.createFromBrandEnglishName(brandName)
      this.ajaxProductService = new AjaxProductService(AppId.getByBrandName(brandName))
    } catch (error) {
      console.error(error)
    }
  }

  get displayTotalCount(): boolean {
    return this.selectedTotalBoxCount !== null && this.selectedTotalLensCount !== null
  }

  get selectedTotalBoxCount(): number | null {
    return this.store ? this.store.selectedTotalBoxCount : null
  }

  get selectedTotalLensCount(): number | null {
    return this.store ? this.store.selectedTotalLensCount : null
  }

  get totalPrice(): string | null {
    return this.store ? Util.formatJPYPrice(this.store.totalPrice) : null
  }

  get buttonClassesStr(): string {
    const classes = [
      'c-btn c-btn--primary c-btn--w-large c-btn--h-xlarge c-btn--center c-btn--effect__pop cart-in__button',
    ]
    if (!this.selectedTotalBoxCount ||
        !(this.store && this.store.selectedSKUsAreOverRegularSaleStart)
    ) classes.push('disabled')

    return classes.join(' ')
  }

  get leftSku(): ContactLensSKU | null {
    return this.store ? this.store.selectedLeftSku : null
  }

  /**
   * 商品のbrandIdを取得
   */
  get brandId(): string {
    return this.store ? this.store.root.brandId : ''
  }

  get brandEnglishName(): string {
    return this.store ? this.store.root.brandEnglishName : ''
  }

  get displayNormalButton(): boolean {
    return this.buttonType === 'normal'
  }

  get displayNewItemRequestButton(): boolean {
    return this.buttonType === 'request_new_item'
  }

  get displayRestockRequestButton(): boolean {
    return this.buttonType === 'request_restock'
  }

  get newItemRequest(): ContactLensSKU | null {
    return this.store ? this.store.newItemRequest : null
  }

  get restockRequest(): ContactLensSKU | null {
    return this.store ? this.store.restockRequest(this.behavior) : null
  }

  async onClickCartIn(): Promise<void> {
    if (this.notificationIfShortOfLeftOrRightSku()) {
      return this.displayCartInNotification(this.notificationIfShortOfLeftOrRightSku())
    }

    return this.startCartInProcess()
  }

  /**
   * カート追加時のnotificationを閉じる。
   * 閉じた後、カート追加処理を開始する。
   */
  closeCartInNotificationAndContinueProcess(): void {
    this.cartInNotificationMsg = ''
    this.startCartInProcess()
  }

  private get behavior(): BehaviorConfig {
    const brandName = BrandRouteResolver.resolveBrandFromPathElement(this.$route.params.brand_english_name)

    return BehaviorConfig.createFromBrand(brandName)
  }

  private get buttonType(): 'normal' | 'request_new_item' | 'request_restock' | null {
    if (!this.store) {
      return null
    }

    if (this.newItemRequest) {
      return 'request_new_item'
    }

    if (this.restockRequest) {
      return 'request_restock'
    }

    return 'normal'
  }

  /**
   * カート追加処理
   *
   * 左目SKUまたは右目SKUのいずれかのカート追加に失敗した場合、エラーモーダルを表示する。
   * それ以外の場合には、カート画面に遷移する。
   */
  private async startCartInProcess(): Promise<void> {
    this.addToCartStatus = ProcessStatus.asProcessing()

    Promise.all([
      this.addLeftToCart(),
      this.addRightToCart(),
    ]).then(([leftResult, rightResult]) => {
      const result = foldAddResult({
        rightEye: rightResult,
        leftEye: leftResult,
      })

      result.fold(
        (joinedErrorMessages) => {
          /**
           * TODO: <br>で結合した状態ではなく、単なる string[] でエラーメッセージが返ってくるようにする.
           * 元のエラーメッセージを返す機構が、最初から <br> でjoinした状態で返す仕組みになっている.
           * 既に結合されているため、重複したメッセージを取り除こうと思うと、↓のように split -> join という順を踏まなくてはならない.
           * また、エラーメッセージが <br/> や \n ではなく <br> で区切られている、という内部詳細とも
           * 暗黙的に結合することを強制されている.
           *
           * 内部で勝手に結合するのではなく、単なる string[] で返して、
           * あとは呼び出し元で都合の良いように加工する形を取れば、こうした問題は解消可能.
           * 呼び出し元で必要に応じて join('<br>') を行う必要はあるが、元々大した手間ではないし、
           * <br> 以外で結合したい or そもそも結合したくないケースにも、無理なく対応できる.
           */
          const errorMessages = _.uniq(
            _.flatMap(joinedErrorMessages, (msg: string) => msg.split('<br>'))
          )
          log.error({ joined: joinedErrorMessages, display: errorMessages })

          this.$store.commit('error/set', {
            message: [
              '一部または全部のカート追加に失敗しました。',
              ...errorMessages,
            ].join('<br>'),
          })
        },
        (endpoint) => {
          if (endpoint) {
            location.href = endpoint
          }
        }
      )
    }).catch((reason) => {
      log.error(reason)

      this.$store.commit('error/set', { message: '一部または全部のカート追加に失敗しました。' })

      this.addToCartStatus = ProcessStatus.asNotProcessing()
    })
  }

  /**
   * 左目SKUのカート追加処理
   *
   * @return string | null カート追加した場合には遷移先のエンドポイント、追加するアイテムがない場合にはnullを返す
   */
  private async addLeftToCart(): Promise<Either<string, string | null>> {
    if (this.store && this.store.selectedLeftSku && this.store.selectedLeftBoxCount) {
      return this.addToCart(
        this.store.selectedLeftSku.id.long,
        this.store.selectedLeftBoxCount,
        this.store.selectedLeftSku.price
      )
    }

    return Promise.resolve(right(null))
  }

  /**
   * 右目SKUのカート追加処理
   *
   * @return string | null カート追加した場合には遷移先のエンドポイント、追加するアイテムがない場合にはnullを返す
   */
  private async addRightToCart(): Promise<Either<string, string | null>> {
    if (this.store && this.store.selectedRightSku && this.store.selectedRightBoxCount) {
      return await this.addToCart(
        this.store.selectedRightSku.id.long,
        this.store.selectedRightBoxCount,
        this.store.selectedRightSku.price
      )
    }

    return Promise.resolve(right(null))
  }

  /**
   * カート追加処理
   *
   * @return string 遷移先のエンドポイント
   */
  private async addToCart(skuLongId: string, count: number, unitPrice: number): Promise<Either<string, string>> {
    return new Promise(async (resolve, reject) => {
      if (!this.cartService) {
        return reject(new Error('this.cartService is null'))
      }

      if (!this.ajaxProductService) {
        return reject(new Error('this.ajaxProductService is null'))
      }

      /**
       * 右目・左目それぞれのカート追加ごとにSKU価格をserverから取得している。
       * より効率的に、事前にSKU価格の一覧を取得しておくほうがパフォーマンス的によいかもしれない。
       * ただし、コンタクトレンズはすぐ稼働するわけではなく、またそもそもserverから有効なSKU価格込みのSKU情報を最初から渡してもらう方がよいので、
       * 今後の改善を検討するに留めておく。
       */
      const maybeSkuPrice = await DefaultFetchSkuPrice.create(this.brandEnglishName).fetchById(skuLongId)
      if (maybeSkuPrice.isLeft()) {
        return left(maybeSkuPrice.value.message)
      }

      const result = await new AddItemToCart(
        this.cartService,
        this.ajaxProductService
      ).execute(skuLongId, count, maybeSkuPrice.value)

      result.fold(
        (errorMsg: string) => {
          resolve(left(errorMsg))
        },
        (productBelongingBrandEnglishName: string) => {
          const query = {
            isCartIn: true,
            productId: skuLongId,
            unitPrice,
          }

          resolve(
            right(
              BrandRouteResolver.makeResourceEndpoint({
                brand: productBelongingBrandEnglishName,
                path: ['cart'],
                query,
                withSpaPrefix: true,
              })
            )
          )
        }
      )
    })
  }

  /**
   * カート追加時のnotificationを表示する。
   */
  private displayCartInNotification(message: string) {
    this.cartInNotificationMsg = message
  }

  /**
   * 選択数量が、左目または右目の選択可能上限数を上回る場合に、「在庫切れのためカートに追加できない商品がありました。」
   * というメッセージを表示。
   */
  private notificationIfShortOfLeftOrRightSku(): string {
    return (
      this.store &&
      this.store.selectedQuantityIsLargerThanMaxSelectableQuantityOfLeftOrRightSku
    ) ? '在庫切れのためカートに追加できない商品がありました。' : ''
  }
}

class ProcessStatus {
  static asProcessing(): ProcessStatus {
    return new ProcessStatus(true)
  }

  static asNotProcessing(): ProcessStatus {
    return new ProcessStatus(false)
  }

  private constructor(readonly inProcessing: boolean) {}
}
