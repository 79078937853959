import { Color } from '../color'

/**
 * 商品カラータグの翻訳機
 * エンジンから取得するカラータグをそのまま表示せず別の色として表示したいケースがある
 *
 * もともと、エンジンから取得したカラータグリストをそのままフロントで表示していたので
 * 本来はフロントで翻訳するのではなくエンジンまたはコンソールで適切なカラーを設定してもらいたい
 * しかし以下の経緯により、一時的にフロント対応することとなった
 * @see: https://www.chatwork.com/#!rid158613418-1467031315499974656
 *
 * コンソール側対応の状況
 * @see: https://github.com/my-color/front/pull/5488#discussion_r672197056
 */
export interface ColorTranslator {
  translate: (color: Color) => Color
}

export class Identity {
  translate(color: Color): Color {
    return color
  }
}

interface ColorTranslationRule {
  from: {
    name: string
  }
  to: {
    cssClassSuffix: string
    title: string
  }
}

export class ColorTranslatorBase implements ColorTranslator {
  protected constructor(private readonly rules: ColorTranslationRule[]) {}

  translate(color: Color): Color {
    const matchedRule = this.rules.find(rule => rule.from.name === color.name)

    if (matchedRule) {
      return color.changeTo(matchedRule.to.cssClassSuffix, matchedRule.to.title)
    }

    return color
  }
}

/**
 * ホテラバ用のカラータグ翻訳機  仕様は以下
 * @see: https://github.com/my-color/front/issues/5462
 */
export class TranslatorForHotelLovers extends ColorTranslatorBase {
  static create(): TranslatorForHotelLovers {
    return new TranslatorForHotelLovers([
      {
        from: { name: 'yellow' },
        to: { cssClassSuffix: 'hazel', title: 'ヘーゼル' },
      },
      {
        from: { name: 'silver' },
        to: { cssClassSuffix: 'navy', title: 'ネイビー' },
      },
      {
        from: { name: 'gold' },
        to: { cssClassSuffix: 'olive', title: 'オリーブ' },
      },
    ])
  }
}
