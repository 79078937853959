import { ExistBrand, InstanceType, URL } from '@/util/url'
import { getPreviousPathInSite, HistoryOption, HistorySource } from '@/util/windowHistory'

export const getUrlToContinueShopping =
  (instanceType: InstanceType) =>
  (existBrand: ExistBrand) =>
  (historySource: HistorySource, historyOption: HistoryOption) =>
  async (): Promise<string> => {
    const path = getPreviousPathInSite(historySource)(historyOption)
    const isItemListPage = URL.isItemListPage(instanceType)

    if (instanceType.isSingle()) {
      return isItemListPage(path) ? '/itemlist' : '/'
    }

    const isBrandPage = URL.isBrandPage(existBrand)

    const pathIsBrandPage = await isBrandPage(path)
    if (!pathIsBrandPage) {
      return '/'
    }

    if (isItemListPage(path)) {
      const [top, itemList] = path.replace(/^\//, '').split('/')

      return `/${top}/${itemList}`
    }

    return URL.getTopPath(path)
  }
