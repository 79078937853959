











































import { Validator } from 'vee-validate'
import Vue from 'vue'
import { Component, Inject,  Prop } from 'vue-property-decorator'
import { InitialLabelItem, SelectItem } from './model/SelectItem'

/**
 * セレクトボックスに初期表示する項目は、ユーザに対してこのセレクトボックスで何を選べばよいのか伝えるために使われることが多い。
 * そのような使い方がセレクトボックスの使い方として望ましいのか（別途ラベル用のDOMを設けるべきetc.）はさておき、事実上その用途は無視できない。
 * 初期表示する項目は、option hidden方式と、option disabled方式で実現しうる。
 * - option hidden方式に対応するものとして、"initialLabelItem" propを用意している。
 * - option disabled方式は、"list" propにdisabled属性をセットしたoptionを渡す。
 * @see https://github.com/my-color/front/issues/5680#issuecomment-918118197
 * @see https://github.com/my-color/front/pull/5736
 */
@Component
export default class DropdownSelect extends Vue {
  @Inject()
  readonly $validator: Validator

  @Prop({ default: false })
  readonly disabled: boolean

  @Prop({ required: false })
  readonly id: string

  @Prop({ required: true })
  readonly name: string

  @Prop({ default: undefined })
  readonly initialLabelItem: InitialLabelItem | undefined

  @Prop({ required: true })
  readonly list: SelectItem[]

  @Prop({ required: true })
  readonly value: string

  @Prop({ default: () => ({}) })
  readonly htmlClass: any

  @Prop({ default: () => ({}) })
  readonly wrapperClass: any

  /*
   * Whether or not the field has validation dependencies on other fields.
   * If this flag is set, some own logic on validation will be expected.
   */
  @Prop({ default: false })
  readonly hasDependencies: boolean

  onInput() {
    // inputイベントでvalueは存在するのにバリデーションエラーとなってしまうためvalidatorを停止（無効化）する
    this.$validator.pause()
  }

  onChange(event) {
    // inputイベントで停止したvalidatorを再開（有効化）する
    this.$validator.resume()
    /**
     * IE/Edgeにおいて、<select>はinputをサポートしていない。
     * https://developer.mozilla.org/en-US/docs/Web/Events/input#Browser_compatibility
     *
     * 一方で、v-modelディレクティブはinputイベントを購読するsyntax sugarであるため、
     * v-modelを利用できるようchangeイベントをinputイベントに翻訳する実装を選択した。
     * https://github.com/my-color/front/pull/1137
     */
    this.$emit('input', event.target.value)
  }

  hasError(): boolean {
    if (this.hasDependencies) {
      return this.$validator.errors.has(this.name) && this.isTouched(this.name)
    }

    return this.$validator.errors.has(this.name)
  }

  getErrorMessage(): string {
    return this.$validator.errors.first(this.name)
  }

  get _class() {
    return {
      'c-select--dropdown': true,
      'form-control': true,
      'is-invalid': this.hasError(),
      ...this.htmlClass,
    }
  }

  private isTouched(name: string): boolean {
    const field = this.$validator.fields.find({ name })

    if (!field) {
      return true
    }

    return field.flags.touched
  }
}
