






import _ from 'lodash'
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

interface Star {
  readonly type: 'full-star' | 'half-star' | 'empty-star'
}

@Component
export default class ItemRating extends Vue {
  @Prop({ required: true })
  readonly rating: number

  get stars(): Star[] {
    return [...this.fullStars, ...this.halfStars, ...this.emptyStars]
  }

  get fullStars(): Star[] {
    return _.range(0, Math.floor(this.rating)).map(() => ({ type: 'full-star' }))
  }

  get halfStars(): Star[] {
    return this.rating === this.fullStars.length ? [] : [{ type: 'half-star' }]
  }
  get emptyStars(): Star[] {
    const maxOfStar = 5

    return _.range(this.fullStars.length + this.halfStars.length, maxOfStar).map(() => ({ type: 'empty-star' }))
  }

}
