import { CartScope } from '@/models/cart/group/scope'
import { Either, Left, Right } from 'fp-ts/lib/Either'
import { none, Option, Some } from 'fp-ts/lib/Option'
import { Response } from 'superagent'

import { log } from '@/log'
import ApiBrand from '@/models/api/brand'
import { ApiClient } from './client'
import { ApiService } from './service'

const namespace: string = 'brand'
export interface BrandLogo {
  url: string
  origin: string
}

export type Finder = (brand: ApiBrand) => boolean

export interface ExistByEnglishNameService {
  existByEnglishName(englishName: string): Promise<Either<Error, boolean>>
}
export interface FindCartScopeService {
  findCartScope(brandName: string): Promise<Either<Error, CartScope>>
}

export class BrandService extends ApiService implements ExistByEnglishNameService, FindCartScopeService {
  async findByEnglishName(englishName: string | null): Promise<Either<Error, Option<ApiBrand>>> {
    if (!englishName) {
      return new Right(none)
    }

    return this.findBy(brand => brand.englishNameIs(englishName))
  }

  async list(): Promise<Either<Error, Response>> {
    try {
      const response = await ApiClient
        .get(`/api/common/brand`)

      log.debug({ service: `${namespace}/list`, response })

      return new Right(response)
    } catch (error) {
      log.error({ error })

      return new Left(error)
    }
  }

  async findLogoImage(brandName: string): Promise<Either<Error, BrandLogo|null>> {
    if (brandName === '') {
      return new Left(new Error('brand name must not be empty, but actual is empty string'))
    }
    try {
      const response = await ApiClient
        .get(`/api/common/${brandName}/logo-images`)

      log.debug({ service: `${namespace}/findLogoImage}`, response })

      return new Right(response.body.data || null)
    } catch (error) {
      log.error({ error })

      return new Left(error)
    }
  }

  async findCartScope(brandName: string): Promise<Either<Error, CartScope>> {
    try {
      const response = await ApiClient
        .get(`/api/common/${brandName}/cart-scope`)

      return new Right(CartScope.valueOf(response.body.data))
    } catch (error) {
      log.error({ error })

      return new Left(error)
    }
  }

  async existByEnglishName(englishName: string): Promise<Either<Error, boolean>> {
    return this.existBy(brand => brand.englishNameIs(englishName))
  }

  async resolveBrandIdFromEnglishName(brandEnglishName: string | null): Promise<number | null> {
    if (brandEnglishName === null) {
      return null
    }

    const brandEither = await this.findByEnglishName(brandEnglishName)

    if (brandEither.isLeft()) {
      return null
    }

    const brand = brandEither.value.toNullable()

    return brand ? Number(brand.id) : null
  }

  private async findBy(finder: Finder): Promise<Either<Error, Option<ApiBrand>>> {
    return (await this.list()).map((response) => {
      const rawBrands = response.body.data as any[]
      const brands = rawBrands.map(b => new ApiBrand(b))
      const foundBrand = brands.find(finder)

      return foundBrand ? new Some(foundBrand) : none
    })
  }

  private async existBy(finder: Finder): Promise<Either<Error, boolean>> {
    return (await this.findBy(finder)).map(maybe => maybe.isSome())
  }
}
