import { ApiProduct } from '@/models/api/product'

import Cookies from 'js-cookie'
import _ from 'lodash'
import queryString from 'query-string'

/**
 * もともと共通した一つのI/Fだったが、
 * 商品リストページ用のItemlistUrlQueryParameterと、
 * 商品詳細ページ用のItemDetailUrlQueryParameterに分けた
 * TODO: 各I/Fのパラメータをそれぞれ必要なもののみに絞る
 */
interface ItemlistUrlQueryParameter {
  brand?: string
  category?: string
  price?: string
  color_family?: string
  sort?: string
  page?: number
  from?: string
  disabled?: string
  sale?: string
  stock?: string
}

interface ItemDetailUrlQueryParameter {
  brand?: string
  category?: string
  price?: string
  color?: string
  sort?: string
  page?: number
  from?: string
  disabled?: string
  sale?: string
  stock?: string
}

export interface CartInfo {
  wait: boolean,
  newitem: boolean,
  restock: boolean,
  cart: boolean,
  wazka: boolean,
  soldout: boolean,
  stock: number,
  on_sale: boolean,
  presales: boolean,
}

/**
 * class Util
 */
export default class MyColorUtil {
  /**
   * 数値を金額（カンマ区切り文字列）へフォーマットします
   * @param {Number} number
   * @return {String} カンマ区切り文字列
   */
  public static numberToMoney(number) {
    const money = number === undefined ? 0 : number

    return String(money).replace(/(\d)(?=(\d{3})+$)/g, '$1,')
  }

  /**
   * 非会員かどうかを返します
   * @return {Boolean}
   */
  public static isGuest() {
    const accessToken = Cookies.get('a')

    return typeof accessToken === 'undefined' || accessToken === null
  }

  /**
   * URLパラメータを連想配列に変換します。商品リスト用
   */
  public static convertItemlistUrlParamsToAssociativeArray(): ItemlistUrlQueryParameter {
    return queryString.parse(location.search)
  }

  /**
   * URLパラメータを連想配列に変換します。商品詳細用
   */
  public static convertItemDetailUrlParamsToAssociativeArray(): ItemDetailUrlQueryParameter {
    return queryString.parse(location.search)
  }

  public static isSpa() {

    return location.pathname === '/s'
  }

  /**
   * カートへ入れる画面の表示仕様に則った連想配列を返却します。
   * NOTE: できる限り利用側で引数を ApiProduct にして、 getCartInfoForApiProductを使うこと
   *
   * @param product
   */
  public static getCartInfo(product: any) {
    const sku = new ApiProduct(product)

    return this.getCartInfoForApiProduct(sku)
  }

  public static getCartInfoForApiProduct(product: ApiProduct) {
    // 入荷待ち
    const isWait: boolean = (
      product.isAvailable &&
      !product.overRegularSaleStart &&
      !product.acceptNewItemRequest
    ) || (
      product.isDeleted &&
      !product.overRegularSaleStart &&
      !product.acceptNewItemRequest
    )

    // 新作リクエスト
    const isNewitem: boolean = product.isOnNewItemRequest

    // 再入荷リクエスト
    const isRestock: boolean = product.isOnRestockRequest

    // SOLDOUT
    const isSoldout: boolean = (
      product.isAvailable &&
      product.isOnRegularSale &&
      product.isSoldOut &&
      !product.acceptRestockRequest
    ) || (
      product.isDeleted &&
      product.isOnRegularSale &&
      !product.acceptRestockRequest
    )

    // 残りわずか
    const isWazka: boolean = product.isAvailable &&
                             product.isOnRegularSale &&
                             product.hasFewStocks

    // カートに入れる
    const isCart: boolean = product.isAvailable &&
                            product.isOnRegularSale &&
                            product.hasManyStocks

    const cartInfo: any = {
      wait: isWait,
      newitem: isNewitem,
      restock: isRestock,
      cart: isCart,
      wazka: isWazka,
      soldout: isSoldout,
      stock: product.stock,
      on_sale: product.isOnDiscountSale,
      presales: product.isPreSale,
      notAvailablePreSales: product.isNotAvailablePreSale,
    }

    return cartInfo
  }

  /**
   * 商品詳細画面のボタン表示仕様に則った連想配列を返却します。
   */
  public static getItemDetailInfo(productclasses: any[]) {

    const cartsInfo: any[] = []

    productclasses.forEach((productclass) => {
      productclass.parts.forEach((part) => {
        const cartInfo = this.getCartInfo(part)
        cartsInfo.push(cartInfo)
      })
    })

    const skuCart = _.some(cartsInfo, cartInfo => cartInfo['cart'])
    const skuWazka = _.some(cartsInfo, cartInfo => cartInfo['wazka'])
    const skuNewitem = _.some(cartsInfo, cartInfo => cartInfo['newitem'])
    const skuRestock = _.some(cartsInfo, cartInfo => cartInfo['restock'])
    const skuWait    = _.some(cartsInfo, cartInfo => cartInfo['wait'])

    const cart = skuCart || skuWazka
    const request = skuNewitem
    const soldoutRestock = !request && skuRestock
    const commingsoon = !request && !soldoutRestock && skuWait
    const presales = !(request || soldoutRestock) && cartsInfo.some(c => c.presales)

    const itemDetailInfo = {
      cart,
      request,
      'soldout.restock': soldoutRestock,
      commingsoon,
      presales,
    }

    return itemDetailInfo
  }

  /**
   * 日本円(JPY)の通貨表示に整形します。
   * ex. 1234456 to ¥1,234,456
   * 変換できない場合は-を返却します。
   */
  public static formatJPYPrice(price: number): string {
    return price ? price.toLocaleString('ja-JP', { style: 'currency', currency: 'JPY' }) : '-'
  }
}
