
export function assertAppIdMustUnique<T extends object>(map: T): T {
  const values = Object.values(map)
  const uniqued = new Set(values)

  if (values.length !== uniqued.size) {
    throw new Error(`failed assertAppIdMustUnique! map = ${JSON.stringify(map)}`)
  }

  return map
}
