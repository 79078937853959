



















import { interaction } from '@/components/organisms/cart/add-item/interaction'
import CommandAddItemToCart from '@/models/cart/command/add-item-to-cart'
import { SkuPrice } from '@/models/item/sku'
import AppId from '@/util/appid'
import { BrandRouteResolver } from '@/util/brandRouteResolver'
import { CartService } from '@ajax/modules/services/cart'
import { ProductService as AjaxProductService } from '@ajax/modules/services/product'
import { AddToCartErrorEvent, AddToCartEvent } from '@ajax/vue/components/organisms/addtocart/view-models/event'
import Vue from 'vue'
import { Component, Emit, Prop } from 'vue-property-decorator'

export type GetSkuPriceOnClick = () => Promise<SkuPrice>

@Component
export default class AddItemToCart extends Vue {
  @Prop({ required: true })
  id: string

  @Prop({ default: 1 })
  count: number

  @Prop({ required: true })
  /**
   * 価格か、あるいはレンダリング時点で価格が決定できない/したくない場合は価格を算出するコールバック関数を指定する.
   *
   * 「レンダリング時点で価格を決定したくない」の例としては、価格取得処理の実行を遅延させたい場合など.
   * 具体例として{@see AddToCartButtonBySku}を参照.
   *
   * コールバック関数を指定した場合、コールバック関数実行中に発生したエラーのハンドリングは利用側コンポーネントの責務とする.
   * このコンポーネントはあくまでカート追加処理失敗時のフォローのみを行うので、
   * 価格取得失敗によるエラー通知やエラーハンドリングは呼び出し元で制御すること.
   */
  price: SkuPrice | GetSkuPriceOnClick

  @Prop({ default: 'c-btn c-btn--primary c-btn--w-middle c-btn--h-middle c-btn--effect__pop' })
  buttonClass: string

  /**
   * カート追加処理の発火を無効化するフラグ。
   */
  @Prop({ default: false })
  disabled: boolean

  /**
   * 処理状態を示すフラグ。
   * 処理状態に応じてボタンのアイコンを変更するなどのユースケースが想定される。
   *
   * 利用側コンポーネントからpropとして注入してもよいが、利用側コンポーネントで複数のカート追加ボタンのうちどのボタンが
   * 処理中になっているかを識別できる必要がある。
   * 識別すること自体は可能だが（このコンポーネントからstart/stopイベントでSKU IDも送信している）、
   * 簡便のため、自分が処理中であることを知っているこのコンポーネントのdataとして管理している。
   * ※そもそも本来であれば、カート追加処理（API通信など）はより上位のコンテナコンポーネントに分離しておきたいところ。
   */
  processing: boolean = false

  /**
   * Add a product to cart.
   * When the product is the parent of a hidden set(紐付き商品), also add its child in the background.
   */
  async addToCart() {
    try {
      this.onStartEvent()

      const brandName = BrandRouteResolver.resolveBrandFromPathElement(this.$route.params.brand_english_name)
      const addItem = interaction.addItem({
        command: new CommandAddItemToCart(
          new CartService(brandName),
          new AjaxProductService(AppId.getByBrandName(brandName))
        ),
        location,
      })

      const price = await (typeof this.price === 'function'
                           ? this.price()
                           : this.price)

      const result = await addItem({
        item: {
          id: this.id,
          count: this.count,
          price,
        },
      })

      result.mapLeft((errorMsg: string) => {
        const error = { message: errorMsg }

        this.$store.commit('error/set', error)
        this.publishErrorEvent(error)
      })
    } catch (cause) {
      const error = {
        message: 'エラーが発生しました。※改善されない場合、お問い合わせよりカスタマーセンターまでお問い合わせください。',
        cause,
      }

      this.$store.commit('error/set', error)
      this.publishErrorEvent(error)
    } finally {
      this.onStopEvent()
    }
  }

  private onStartEvent(): void {
    this.processing = true
    this.publishStartEvent()
  }

  private onStopEvent(): void {
    this.processing = false
    this.publishStopEvent()
  }

  @Emit('start')
  private publishStartEvent(): AddToCartEvent {
    return {
      skuId: this.id,
      status: 'start',
    }
  }

  @Emit('stop')
  private publishStopEvent(): AddToCartEvent {
    return {
      skuId: this.id,
      status: 'stop',
    }
  }

  @Emit('error')
  private publishErrorEvent(error: AddToCartErrorEvent) {
    return error
  }
}
