import { ApiAppSetting } from '@/models/api/app_setting'

export const initialAppSetting: AppSetting = {
  appSetting: new ApiAppSetting({
    content: {
      /**
       * TODO: 現状の構造ママだと、他のパラメータが増えた時に、都度デフォルト値をFront固定で保つ必要が生じる
       * p2.4.0スコープでHostに設定された値をデフォルト値として取り込む仕組みを用意するので、そこで↑の問題は解消される見込み.
       *
       * TODO: Hostの設定も取得失敗した場合に備えて、個別の最終的なデフォルト値も容易が必要そう.
       * また、会計画面の送料固定等、取得失敗したらそれ以上進ませたくない値も有りそうなので、要確認.
       */
      scheduled_delivery: false,
    },
  }),
  error: {},
}

export interface AppSetting {
  appSetting: ApiAppSetting,
  error: {
    load?: Error | null,
  }
}
