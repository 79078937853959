import { Either, Left, Right } from 'fp-ts/lib/Either'

import { log } from '@/log'
import { ApiService } from '@/services/api/service'
import { ApiClient } from './client'

const namespace: string = 'inquery'

// tslint:disable-next-line:no-magic-numbers
type RestockRequest = 1101
// tslint:disable-next-line:no-magic-numbers
type NewRequest = 1102
interface Inquiry {
  inquiry_kind: RestockRequest | NewRequest
  resource_id: string
  application_event?: {
    is_notified: boolean
  }
}

export class InquiryService extends ApiService {
  static readonly RESTOCK_REQUEST_ID: RestockRequest = 1101
  static readonly NEW_REQUEST_ID: NewRequest = 1102

  async register(query): Promise<Either<Error, object>> {
    try {
      const response = await ApiClient
        .post(`/api/user-proxy/${this.appId}/issue_inquiry`)
        .send(query)

      const result = response.body

      log.debug({ service: `${namespace}/register`, response })

      return new Right(result)
    } catch (error) {
      log.error({ error })

      return new Left(error)
    }
  }

  async fetchUserInquiries(query: object): Promise<Either<Error, Inquiry[]>> {
    try {
      const response = await ApiClient
        .get(`/api/user-proxy/${this.appId}/user_inquiry`)
        .query(query)

      const result = response.body

      log.debug({ service: `${namespace}/register`, response })

      return new Right(result)
    } catch (error) {
      log.error({ error })

      return new Left(error)
    }
  }
}
