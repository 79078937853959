import AbstractValue from '@/models/app-config/behavior/abstract-value'

export interface RawLayer {
  useBand: boolean
}

export default class Layer extends AbstractValue<RawLayer> {
  get useBand(): boolean {
    return this.raw.useBand
  }
}
