






















import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component
export default class SortItem extends Vue {
  @Prop({ default: false })
  checked: boolean

  @Prop({ required: true })
  id: string
}
