


















import Vue from 'vue'
import { Component, Emit, Prop } from 'vue-property-decorator'

import ItemList from '../ItemList.vue'

import { ApiProductclass } from '@/models/api/productclass'

@Component({
  components: {
    ItemList,
  },
})
export default class ItemListContainer extends Vue {
  @Prop({ default: 'div' })
  tag: string

  @Prop({ required: true })
  itemList: any[]

  get fkuList(): ApiProductclass[] {
    return this.itemList.map(item => new ApiProductclass(item))
  }

  mounted() {
    this.onMounted()
  }

  @Emit('mounted')
  private onMounted(): void {
    // Do nothing
  }
}
