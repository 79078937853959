export interface BrandMaintenance {
  brandEnglishName: string
}

export class BrandMaintenanceList {
  static get empty(): BrandMaintenanceList {
    return new BrandMaintenanceList([])
  }

  static createFromBrandEnglishNameList(data: string[]): BrandMaintenanceList {
    return new BrandMaintenanceList(data.map((brandEnglishName: string) => {
      return { brandEnglishName }
    }))
  }

  private constructor(
    private list: BrandMaintenance[]
  ) {}

  underMaintenance(brandEnglishName: string): boolean {
    return this.list.some((brandMaintenance: BrandMaintenance) =>
      brandMaintenance.brandEnglishName === brandEnglishName
    )
  }
}
