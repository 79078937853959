import { Price, PriceRange } from '@/components/molecules/item/price/model/item-price-view'
import { PaidMemberItemPriceLoader } from '@/components/molecules/item/price/model/paid-member-item-price-loader'
import { log } from '@/log'
import { SkuPrice, SkuPriceCandidate } from '@/models/api/paid-member-price/sku-price/scheme'
import { SkuPriceRepository } from '@/models/api/paid-member-price/sku-price/sku-price-repository'
import { UtilService } from '@spa/services/util'
import { none, Option, some } from 'fp-ts/lib/Option'

/**
 * 実行効率は度外視して、とりあえず要求されたらすぐデータを取得しにいく実装.
 */
export class SimplePaidMemberItemPriceLoader implements PaidMemberItemPriceLoader {
  constructor(private readonly skuPriceRepository: SkuPriceRepository) {
  }

  async getPriceForFku({ skuIds }): Promise<Option<Price>> {
    return (await this.skuPriceRepository.list(skuIds)).fold(
      (e) => {
        log.warn(e)

        return none
      },
      skuPrices => this.extractPaidMemberPrices(skuPrices).map((paidMemberPrices) => {
        const minPaidMemberPrice = Math.min(...paidMemberPrices)

        return {
          exists: true,
          withTax: minPaidMemberPrice.toLocaleString('ja-JP'),
          withoutTax: UtilService.getPriceBeforeTax(minPaidMemberPrice).toLocaleString('ja-JP'),
          range: paidMemberPrices.length > 1 ? PriceRange.some() : PriceRange.none(),
        }
      })
    )
  }

  private extractPaidMemberPrices(skuPrices: SkuPrice[]): Option<number[]> {
    const selectedPaidMemberPrices = skuPrices
      .map(sku => sku.prices.selected)
      .filter(candidate => candidate.is_paid_membership_price)
      .map(c => c.price)

    if (selectedPaidMemberPrices.length > 0) {
      return some(selectedPaidMemberPrices)
    }

    const unselectedPaidMemberPrices = skuPrices
      .reduce((xs: SkuPriceCandidate[], x) => [...xs, ...x.prices.unselected], [])
      .filter(p => p.is_paid_membership_price)
      .map(p => p.price)

    return unselectedPaidMemberPrices.length > 0
      ? some(unselectedPaidMemberPrices)
      : none
  }
}
