import GetStockStatus from '@/usecase/get-stock-status'
import { BundlePurchaseItem } from '../../../bundle-purchase-item'
import { AddToPurchaseListSpec } from '../index'

export default class MustNotAddtoListMoreThanStock implements AddToPurchaseListSpec {
  constructor(
    private readonly currentList: BundlePurchaseItem[],
    private readonly getStockStatus: GetStockStatus
  ) { }

  isSatisfiedBy(item: BundlePurchaseItem): boolean {
    const countOfItemAlreadyAddedList = this.currentList.filter(
      itemInList => itemInList.id === item.id
    ).length
    const restOfStock = this.getStockStatus.amount(item.stock)

    return restOfStock - countOfItemAlreadyAddedList > 0
  }
}
