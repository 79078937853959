








































import { videoStartImagePath } from '@/config/assets/video-start-image'
import { log } from '@/util/log'
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component
export default class VideoThumbnail extends Vue {
  $refs: {
    canvas: HTMLCanvasElement
  }

  @Prop({ required: true })
  src: string

  /**
   * ajaxコンポーネントとして利用される際にイベントの受け渡しが難しいので、
   * 本コンポーネントがクリックされた際に操作する対象をクエリセレクタとしてpropで受け取っている。
   * 汎用的なコンポーネント (atom) であるという前提に立つと、必ずしもクエリセレクタが必要になるとは限らないので、
   * 必要に応じてI/Fの変更やコンポーネントの再設計を行うこと。
   * @see https://github.com/my-color/front/pull/5410#discussion_r660196293
   */
  @Prop({ required: true })
  videoElementSelector: string

  @Prop({ required: false, default: null })
  thumbnailSrc: string | null

  async created() {
    if (this.thumbnailSrc) {
      return
    }

    const video = await this.tryToCreateVideoElementMaxThreeTimes()
    const squareCanvas = this.createSquareCanvas(video.videoWidth)

    this.drawThumbnailOnVerticalCenter(squareCanvas, video)
  }

  get videoStartImageUrl(): string {
    return videoStartImagePath
  }

  onClick() {
    const videoElementList = document.querySelectorAll<HTMLVideoElement>(this.videoElementSelector)
    videoElementList.forEach(
      (element: HTMLVideoElement) => element.play()
    )
  }

  /**
   * 動画サムネイルが表示されないことがある原因が未解明のためリトライでカバー
   * CW: https://www.chatwork.com/#!rid87550748-1233669541448384512
   * PR: https://github.com/my-color/front/pull/2602
   * リトライの必要性の有無が判断できるまでは残しておく
   * https://www.chatwork.com/#!rid87550748-1233973615351824384
   */
  private tryToCreateVideoElementMaxThreeTimes(): Promise<HTMLVideoElement> {
    return this.createVideoElement()
               .catch(this.createVideoElement)
               .catch(this.createVideoElement)
               .then((result) => {
                 return result
               })
               .catch((result) => {
                 return result
               })
  }

  /**
   * 動画サムネイルが表示されないことがある原因について（未解明）
   * 過去にstalledイベントを購読していることが原因という説があったが、無視しても解消しなかった
   * 対応1：https://github.com/my-color/front/issues/2080
   * 対応2：https://github.com/my-color/front/pull/2602
   */
  private createVideoElement(): Promise<HTMLVideoElement> {
    const video: HTMLVideoElement = document.createElement('video')

    return new Promise((resolve, reject) => {
      // なぜかSafariでサムネを表示するにはautoplayが必要
      // https://github.com/my-color/front/pull/2191
      video.autoplay = true
      video.controls = false
      // 少なくともSafariでは自動再生のためにautoplayとmutedの両方が必要
      video.muted = true
      video.preload = 'auto'
      video.onloadeddata = () => {
        resolve(video)
      }
      video.onerror = (e) => {
        log.error(e)
        reject(video)
      }
      video.onstalled = () => {
        reject(video)
      }

      /**
       * srcプロパティを設定するとすぐにリソース読み込みが始まるはず
       * https://github.com/my-color/front/pull/2602#discussion_r333318124
       */
      video.src = this.src

      /**
       * Safariではautoplay設定に関わらず明示的に読み込ませる必要がある
       * https://github.com/my-color/front/pull/2602#discussion_r333316853
       */
      video.load()
    })
  }

  private createSquareCanvas(edgeLength: number): HTMLCanvasElement {
    const canvas = this.$refs.canvas

    canvas.height = edgeLength
    canvas.width = edgeLength

    return canvas
  }

  private drawThumbnailOnVerticalCenter(canvas: HTMLCanvasElement, src: HTMLVideoElement): void {
    const context = canvas.getContext('2d')!
    // tslint:disable-next-line:no-magic-numbers
    const start = (canvas.height / 2) - (src.videoHeight / 2)

    context.drawImage(src, 0, start)
  }
}
