import AbstractValue from '@/models/app-config/behavior/abstract-value'

export interface RawNotification {
  content: string
}

export default class Notification extends AbstractValue<RawNotification> {
  get content(): string | null {
    return this.raw.content
  }

  get hasContent(): boolean {
    return !!this.content
  }
}
