import BehaviorConfig from '@/models/app-config/behavior/behavior'
import { ColorList } from '../color-list'
import {
  ColorTranslator,
  Identity,
  TranslatorForHotelLovers,
} from './color-translator'

export class ColorListTranslator {
  static createWith(behavior: BehaviorConfig) {
    if (behavior.isHl) {
      return new ColorListTranslator(TranslatorForHotelLovers.create())
    }

    return new ColorListTranslator(new Identity())
  }
  private constructor(private readonly translator: ColorTranslator) {}

  translate(colorList: ColorList): ColorList {
    return ColorList.valuOf(colorList.map(c => this.translator.translate(c)))
  }
}
