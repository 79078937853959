






























































import Vue from 'vue'
import { Component } from 'vue-property-decorator'

import PreviewService from '@/services/api/preview'
import { CookieService } from '@/services/cookie'
import LoadingMixin from '@spa/mixins/loading'

import { DateFormat } from '@/util/format'

@Component({
  mixins: [
    LoadingMixin,
  ],
})
export default class PreviewNotification extends Vue {
  async mounted() {
    const previewTime = CookieService.timeTravelTime

    if (!previewTime) {
      return
    }

    if (!DateFormat.validate(previewTime)) {
      this.$notify({
        group: this.notificationErrorGroup,
        type: 'error',
        duration: -1,
        title: 'プレビューできません',
        text: `不正なフォーマット: ${previewTime}`,
      })

      return
    }

    this.$notify({
      group: this.notificationGroup,
      duration: -1,
      title: 'プレビュー中',
      text: previewTime,
    })
  }

  async close() {
    if (!confirm('プレビューモードを終了してTOPページに移動してもよろしいですか？')) {
      return
    }

    await this.previewService.disable()

    this.$notify({
      group: this.notificationGroup,
      clean: true,
    })

    location.href = '/'
  }

  private get notificationGroup() {
    return 'preview'
  }

  private get notificationErrorGroup()  {
    return 'preview-error'
  }

  private get previewService() {
    return new PreviewService()
  }
}
