import ApiBrand from '@/models/api/brand'
import { FkuPrice } from '@/models/api/price/fku-price'
import { ApiTag } from '@/models/api/tags'
import BehaviorConfig from '@/models/app-config/behavior/behavior'
import * as SKUFactory from '@/models/item/sku/factory/sku'
import { ApiImage } from '../../api/image'
import { ApiProductclass } from '../../api/productclass'
import ContactLensSKU from '../sku/contact-lens'

interface LensDetail {
  power: string | null
}

export default class ContactLensFKU {
  static create(fku: ApiProductclass): ContactLensFKU {
    return new ContactLensFKU(fku)
  }

  private constructor(private _fku: ApiProductclass) {}

  /**
   * ConatactLensFKU が ApiProductclass と透過的に利用できる必要があるなら、委譲ではなく継承にしても良いかもしれない.
   * @deprecated rawを露出させず、 ConatactLensFKU が ApiProductclass と互換になったら削除 or private化
   * @see client/src/components/molecules/ItemContactLens.vue:7
   */
  get raw(): ApiProductclass {
    return this._fku
  }

  get id(): string {
    return this._fku.id
  }

  get shortId(): string {
    return this._fku.shortId
  }

  get belongingBrand(): ApiBrand {
    return this._fku.belongingBrand
  }

  get price(): FkuPrice {
    return this._fku.price
  }

  get isOnDiscount(): boolean {
    return this._fku.isOnDiscount
  }

  get productName(): string {
    return this._fku.productName
  }

  get color() {
    return this._fku.color
  }

  get colorLabel(): string {
    return this.color ? this.color.label : ''
  }

  get colorName(): string {
    return this.color ? this.color.name : ''
  }

  get disposalSchedule(): string {
    if (!this.disposalScheduleTag) {
      return ''
    }

    switch (this.disposalScheduleTag.name) {
      case '1day':
        return 'ワンデー'
      case '2weeks':
        return '2週間'
      case '1month':
        return '1ヶ月'
      default:
        return ''
    }
  }

  get lensCountPerBox(): number {
    return this.root ? this.root.get('aux_long_1', 0) : 0
  }

  get bc(): string {
    return this.bcTag ? this.bcTag.label : ''
  }

  get colorDia(): string {
    return this.colorDiaTag ? this.colorDiaTag.label : ''
  }

  get dia(): string {
    return this.diaTag ? this.diaTag.label : ''
  }

  get power(): string | null {
    return this.lensDetail.power
  }

  /**
   * レンズ画像のurlを取得する
   */
  get lensImageUrl(): string {
    return this.lensImage ? this.lensImage.url : ''
  }

  /**
   * レンズ画像のメディアIDを取得する
   */
  get lensImageMediaId(): string {
    return this.lensImage ? this.lensImage.id : ''
  }

  get supportCompactDelivery(): boolean {
    return this.root ? this.root.supportCompactDelivery : false
  }

  get supportNekoposuDelivery(): boolean {
    return this.root ? this.root.supportNekoposuDelivery : false
  }

  skus(behavior: BehaviorConfig): ContactLensSKU[] {
    return this._fku.products.map(product => SKUFactory.createContactLens(product, behavior))
  }

  get images(): ApiImage[] {
    return this._fku.images
  }

  /**
   * レンズ画像を取得
   */
  private get lensImage(): ApiImage | undefined {
    return this._fku.images.find(image => image.imageAppKind === '1020')
  }

  private get root(): ApiProductclass | null {
    return this._fku.root
  }

  private get disposalScheduleTag(): ApiTag | undefined {
    const rootTags = this.root ? this.root.tags : []

    return rootTags.find(tag => /mycolor\.product\.contactlens\.wear_period\..*/.test(tag.pathname))
  }

  private get bcTag(): ApiTag | undefined {
    const tags = this._fku.tags

    return tags.find(tag => /mycolor\.product\.contactlens\.bc\..*/.test(tag.pathname))
  }

  private get colorDiaTag(): ApiTag | undefined {
    const tags = this._fku.tags

    return tags.find(tag => /mycolor\.product\.contactlens\.color_dia\..*/.test(tag.pathname))
  }

  private get diaTag(): ApiTag | undefined {
    const rootTags = this.root ? this.root.tags : []

    return rootTags.find(tag => /mycolor\.product\.contactlens\.dia\..*/.test(tag.pathname))
  }

  private get lensDetail(): LensDetail {
    return this.raw.get('lens_detail', {})
  }
}
