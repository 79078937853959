import { CreditcardService, CreditcardServiceForPurchase } from '@/services/api/creditcard'
import _ from 'lodash'

import { ActionTree } from 'vuex'

import { RootState } from '../../types'
import { CreditcardModel, CreditcardState, } from './types'

export interface LoadPayload {
  force?: boolean
  service?: CreditcardService
}

export const actions: ActionTree<CreditcardState, RootState> = {
  async load({ commit, state }, payload?: LoadPayload) {
    if (!_.get(payload, 'force') && !_.isEmpty(state.items)) {
      return
    }

    const service = _.get(payload, 'service', new CreditcardServiceForPurchase())
    const result = await service.list()

    if (result.isLeft()) {
      const error = result.value
      commit('errorOnLoad', {
        error,
      })

      return
    }

    const items: CreditcardModel[] = result.value

    commit('load', items)
  },

  async remove({ commit }, id: string) {
    const result = await new CreditcardServiceForPurchase().remove(id)

    if (result.isLeft()) {
      const error: Error = result.value

      commit('errorOnRemove', {
        id,
        error,
      })

      return
    }

    commit('remove', id)
  },
}
