import _ from 'lodash'

import { AppSetting, initialAppSetting } from './modules/appSetting/types'
import { CartState, initialCartState } from './modules/cart/types'
import { ContactState, initialContactState } from './modules/contact/types'
import { CouponState, initialCouponState } from './modules/coupon/types'
import { CreditcardState, initialCreditcardState } from './modules/creditcard/types'
import { HistoryState, initialHistoryState } from './modules/history/types'
import { initialOrderConfirmState, OrderConfirmState } from './modules/orderConfirm/types'
import { initialOrderHistoryState, OrderHistoryState } from './modules/orderHistory/types'
import { initialPurchaseState, PurchaseState } from './modules/purchase/types'
import { initialUserState, UserState } from './modules/user/types'
import { initialViewState, ViewState } from './modules/view/types'

export const initialRootState: RootState = {
  version: '1.0',
  appSetting: _.cloneDeep(initialAppSetting),
  cart: _.cloneDeep(initialCartState),
  coupon: _.cloneDeep(initialCouponState),
  contact: _.cloneDeep(initialContactState),
  creditcard: _.cloneDeep(initialCreditcardState),
  history: _.cloneDeep(initialHistoryState),
  orderConfirm: _.cloneDeep(initialOrderConfirmState),
  orderHistory: _.cloneDeep(initialOrderHistoryState),
  purchase: _.cloneDeep(initialPurchaseState),
  user: _.cloneDeep(initialUserState),
  view: _.cloneDeep(initialViewState),
}

export interface RootState {
  version: string,
  appSetting: AppSetting,
  cart: CartState,
  contact: ContactState,
  coupon: CouponState,
  creditcard: CreditcardState,
  history: HistoryState,
  orderConfirm: OrderConfirmState,
  orderHistory: OrderHistoryState,
  purchase: PurchaseState,
  user: UserState,
  view: ViewState,
}
