






















import { Component, Prop, Vue } from 'vue-property-decorator'

import { ViewModel } from './model/view-model'

@Component({})
export default class CancelView extends Vue {
  @Prop({ required: true })
  model: ViewModel

  moveToNext() {
    location.href = this.model.nextPath
  }
}
