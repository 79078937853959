










import Vue from 'vue'
import { Component } from 'vue-property-decorator'

@Component
export default class SearchContactLensParent extends Vue {
  isLoading = false

  onLoadingStart() {
    this.isLoading = true
  }

  onLoadingStop() {
    this.isLoading = false
  }
}
