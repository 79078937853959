import _ from 'lodash'

import { log } from '@/log'
import { RecentlyViewedRepository } from './recently-viewed-repository'

export default class LocalStorageRepository implements RecentlyViewedRepository {
  private static readonly key = 'checked_productclass'

  public constructor(private listLimit: number | null = null) { }

  public list(): string[] {
    const ids = this.listAll()

    if (this.listLimit === null) {
      return ids
    }

    return _.take(ids, this.listLimit)
  }

  public listOnly(limit: number): RecentlyViewedRepository {
    return new LocalStorageRepository(limit)
  }

  public updateMostRecent(mostRecentId: string) {
    const other = this.listAll().filter(id => id !== mostRecentId)

    this.overwrite([
      mostRecentId,
      ...other,
    ])
  }

  public overwrite(ids: string[]) {
    this.writeValue(JSON.stringify(ids))
  }

  public clearAll(): void {
    this.removeItem(LocalStorageRepository.key)
  }

  private listAll(): string[] {
    const idsStr = this.loadValue()
    if (idsStr === null) {
      return []
    }

    const list = JSON.parse(idsStr)

    if (!_.isArray(list)) {
      log.error(`LocalStorage ${LocalStorageRepository.key} should have array type value.`)

      return []
    }

    return list.filter(id => _.isString(id)).map(id => `${id}`)
  }

  private loadValue(): string | null {
    return localStorage.getItem(LocalStorageRepository.key)
  }
  private writeValue(value: string) {
    localStorage.setItem(LocalStorageRepository.key, value)
  }
  private removeItem(key: string): void {
    return localStorage.removeItem(key)
  }
}
