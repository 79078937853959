








import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import BehaviorConfig from '@/models/app-config/behavior/behavior'
import { InstanceResolver } from '@/util/instanceResolver'

@Component
export default class CopyRight extends Vue {
  @Prop({ default: '' })
  brandName: string

  get behavior(): BehaviorConfig {
    return BehaviorConfig.createFromBrand(this.brandName)
  }

  get shouldShowBrandName(): boolean {
    if (InstanceResolver.isSingle()) {
      return false
    }

    return this.brandName.length > 0
  }

  get companyKindLabel(): string {
    return this.behavior.company.kind.label
  }

  get groupLabel(): string {
    return this.behavior.groupLabel
  }
}
