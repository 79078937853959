




































import { Validator } from 'vee-validate'
import Vue from 'vue'
import { Component, Inject, Prop } from 'vue-property-decorator'

@Component
export default class Input extends Vue {
  @Inject()
  $validator: Validator

  @Prop({ default: null })
  priorValidator: Validator

  @Prop()
  value

  @Prop()
  label: string

  @Prop({ required: true })
  name: string

  @Prop({ default: 'text' })
  type: string

  @Prop()
  htmlClass: any

  @Prop()
  placeholder: string

  @Prop()
  autocomplete: string

  @Prop()
  min: number

  @Prop()
  max: number

  @Prop()
  maxlength: number

  /*
   * Whether or not the field has validation dependencies on other fields.
   * If this flag is set, some own logic on validation will be expected.
   */
  @Prop()
  hasDependencies: boolean

  hasError(): boolean {
    if (this.hasDependencies) {
      return this.validator.errors.has(this.name) && this.isTouched(this.name)
    }

    return this.validator.errors.has(this.name)
  }

  getErrorMessage(): string {
    return this.validator.errors.first(this.name)
  }

  // 親コンポーネントのスコープでも動くために、$validatorをPropで受け取ることができる
  // 渡されなかった場合、通常通り自分の$validatorを使う
  get validator(): Validator {
    return this.priorValidator || this.$validator
  }

  get _class() {
    return {
      'c-input': true,
      'form-control': true,
      'form-control-sm': true,
      'is-invalid': this.hasError(),
      ...this.htmlClass,
    }
  }

  private isTouched(name: string): boolean {
    const field = this.validator.fields.find({ name })

    if (!field) {
      return true
    }

    return field.flags.touched
  }

}
