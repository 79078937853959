import _ from 'lodash'
import { ApiTag } from './tags'

import Status from '@/models/api/resource/status'
import * as CurrentTime from '@/models/current-time/functions'

export default abstract class Resource {
  protected _data: any

  public constructor(apiData: any) {
    this._data = apiData
  }

  get(path: string, defaultValue?: any) {
    return _.get(this._data, path, defaultValue)
  }

  get id(): string {
    return _.get(this._data, 'id')
  }

  get shortId(): string {
    // tslint:disable-next-line:no-magic-numbers
    return this.id.split('-').slice(-5).join('-')
  }

  get name() {
    return this.get('name')
  }

  get title() {
    return this.get('title')
  }

  get tags(): ApiTag[] {
    return this.get('tags', []).map((t: any) => new ApiTag(t))
  }

  get images() {
    return this.get('images')
  }

  get publishAt(): Date {
    return new Date(this.get('publish_at'))
  }

  get closeAt(): Date {
    return new Date(this.get('close_at'))
  }

  get updatedAt(): Date {
    return new Date(this.get('updated_at'))
  }

  get status(): Status {
    return new Status(this.get('status_id'))
  }

  hasTag(pathname: string): boolean {
    return this.tags.some(tag => tag.pathname === pathname)
  }

  findTag(tags: any[], namespace: string[]) {
    const _namespace = `${namespace.join('.')}\\.`

    const regExp = new RegExp(`^${_namespace}(.*)`)

    return _.find(tags, tag => regExp.test(tag.pathname))
  }

  getThumbnail(): string | null {
    const images = this.images

    if (_.isEmpty(images)) {
      return null
    }

    const target = _.find(images, ['display_level', 0])

    if (target) {
      return target.url_xs
    }

    // Fallback
    return images[0].url_xs
  }

  protected get current() {
    return CurrentTime.create()
  }
}
