import { CartScopeEq } from '@/models/cart/group/scope'
import { CartGroupRepository } from '@/models/cart/repository/cart-group-repository'
import { DefaultCartGroupRepository } from '@/models/cart/repository/impl/cart-group/default-cart-group-repository'
import { ApiClientWithAuth } from '@/services/api/client'
import { CanConvertRawItem, ConvertWithBeforePrice, RawItemConverter } from '@spa/store/modules/cart/converter'
import { DefaultCartItemRepository } from '@spa/store/modules/cart/repository'
import _ from 'lodash'
import { ActionTree, Commit } from 'vuex'

import { log } from '@/log'
import { DefaultReplacePriceRepository } from '@/models/api/cart/latest-price/replace-price-reposiory'
import { CartItemRepository } from '@/models/cart/repository/cart-item-repository'
import { CartService } from '@spa/services/api/cart'

import { CartItemList } from '@/models/cart/cart-item'
import { UpdateItemCountInCartService } from '@/models/cart/service/update-item-count-in-cart-service'
import { CommandResult } from '@/models/cart/specification/deal-with-item-via-cart'
import AppId from '@/util/appid'
import { RootState } from '../../types'
import { CartItem, CartState } from './types'

export interface LoadPayload extends PayloadWithCartGroupRepository, MaybeReplaceSale {
  force?: boolean
  staleItems?: CartItem[]
}
export interface LoadWithScopePayload extends LoadPayload {
  scope: CartScopeEq
}

interface MaybeReplaceSale {
  replaceSale?: boolean
}
export interface PayloadWithCartGroupRepository {
  repository?: CartGroupRepository
}

const generateCartGroupRepository = (onReplaced: () => void = () => { /* nothing to do */ }) =>
  (payload?: LoadPayload): CartGroupRepository => {
    if (payload && payload.repository) {
      return payload.repository
    }

    const converter: CanConvertRawItem = selectConverter(onReplaced, payload)

    return new DefaultCartGroupRepository(ApiClientWithAuth, converter)
  }
const selectConverter = (onReplaced: () => void, payload?: MaybeReplaceSale): CanConvertRawItem => {
  const cartService = new CartService()
  const baseConverter = RawItemConverter.createViaApiClient(cartService)
  const replacePriceRepository = DefaultReplacePriceRepository.create(AppId.getHostId())

  if (_.get(payload, 'replaceSale', false)) {
    const replaceSalePrice = new ConvertWithBeforePrice(
      DefaultCartItemRepository.createViaApiClient(cartService),
      replacePriceRepository
    ).onReplaced(onReplaced)

    return {
      async convert(raw: any[]) {
        return replaceSalePrice.convert(await baseConverter.convert(raw))
      },
    }
  }

  return baseConverter
}

export const actions: ActionTree<CartState, RootState> = {
  async load({ commit, getters, state }, payload?: LoadPayload) {
    log.info({ action: 'cart/load start', state, payload })

    const generate = generateCartGroupRepository(() => commit('isReplacedForSale', true))
    const repository = generate(payload)

    if (!_.get(payload, 'force') && getters.currentItems.length > 0) {
      log.info({
        action: 'cart/load',
        message: `not load forcibly and cart items already exist, so skip load`,
      })

      return
    }

    try {
      const groups = await repository.list()

      commit('load', groups)
    } catch (error) {
      commit('errorOnLoad', {
        error,
      })
    } finally {
      log.info({ action: 'cart/load end', state, payload })
    }
  },

  async loadWithScope({ dispatch, commit, state }, payload: LoadWithScopePayload) {
    log.info({ action: 'cart/loadWithScope start', payload })

    await dispatch('load', payload)
    commit('useItemsForScope', payload.scope)

    log.info({ action: 'cart/loadWithScope end', state, payload })
  },

  /**
   * Update unitCount of the cart with the specified id.
   * TODO: The part of handling child product will be refactored and integrated into server-side or somwhere.
   */
  async updateUnitCount(
    { dispatch, commit, getters },
    payload: { id: string, unitCount: number, updateItemCount: UpdateItemCountInCartService }
  ) {
    const { id, unitCount, updateItemCount } = payload

    // Get cartItem with specified id
    await dispatch('load')

    const cartItems = getters.currentItems
    const cartItem = _.find(cartItems, ['id', id])

    if (!cartItem) {
      commit('errorOnUpdate', {
        id,
        error: new Error(`Cart item${id} is not found in the store`),
      })

      return
    }

    const handler = handleCommandResult('errorOnUpdate', commit)
    const result = await updateItemCount.updateCount(
      cartItem,
      unitCount,
      {
        items: new CartItemList(cartItems),
      }
    )

    handler(id, result)
  },

  async loadNovelties({ commit }, payload: LoadPayload) {
    try {
      const generate = generateCartGroupRepository()
      const repository = generate(payload)
      const items = await repository.list()

      commit('loadNovelties', items)
    } catch (error) {
      commit('errorOnLoad', {
        error,
      })
    }
  },

  /**
   * :pensive:
   * - `novelteis`の定義がコンポーネント側にハードコード
   * - store側に引き込もうとすると、キャンペーン情報だったりフィルタ関数だったり、色々なものの移動が発生
   *
   * という理由で、（storeの責務が散逸しているが）外から削除対象のノベルティ商品を渡す形を取っている.
   *
   * 何をコンポーネントスコープで管理するのか/しないのか、何をstoreで保存するのか/しないのか
   * 両者共にこれといった方針や全体像の無いままに増改築を繰り返したツケが回ってきている.
   * ここで更に後に回すとますます首が回らなくなるだけなので、
   * リファクタリング/リアーキテクチャリングに着手する.
   */
  async resetNovelties(
    { dispatch },
    { repository, novelties }: {
      repository: CartItemRepository,
      novelties: CartItem[]
    }) {
    const result = await repository.removeSome(novelties)

    if (result.isLeft()) {
      log.error(result.value)

      return
    }

    return dispatch('loadNovelties')
  },
}

const handleCommandResult = (mutationKey: string, commit: Commit) => (id: string, result: CommandResult) => {
  if (result.failed) {
    commit(mutationKey, {
      id,
      error: new Error(
        result.errors.map(e => e.message).join(', ')
      ),
    })

    return
  }

  result.processed.forEach(p => commit('replace', p))
}
