import { ApiContent } from '@/models/api/content'
import BehaviorConfig from '@/models/app-config/behavior/behavior'
import { SlideMenuItem } from '@/models/app-config/behavior/config/slide-menu'
import FreeArea from '@/services/free-area/free-area'
import SlideMenuFreeArea from '@/services/free-area/global/slide-menu-free-area'

export interface SlideMenuState {
  visibility: boolean,
  position: {
    top: number,
  },
  freeArea: SlideMenuFreeAreaType,
}

export interface SlideMenuFreeAreaType {
  top: ApiContent | null
  middle: ApiContent | null
}

export const emptySlideMenuFreeArea: SlideMenuFreeAreaType = {
  top: null,
  middle: null,
}
export const initialSlideMenuState: SlideMenuState = {
  visibility: false,
  position: {
    top: 0,
  },
  freeArea: emptySlideMenuFreeArea,
}

export function toggleSlideMenu(state: SlideMenuState): SlideMenuState {
  return {
    ...state,
    visibility: !state.visibility,
  }
}

export function setPosition(state: SlideMenuState, position: { top: number }): SlideMenuState {
  return {
    ...state,
    position,
  }
}

export async function initializeSlideMenuFreeArea(
  state: SlideMenuState,
  isBrandPage: boolean,
  brandName: string
): Promise<SlideMenuState> {
  if (!isBrandPage) {
    return state
  }

  const behavior = BehaviorConfig.createFromBrand(brandName)
  if (!behavior.slideMenu.allow(SlideMenuItem.FreeArea)) {
    return state
  }

  const freeAreaService = FreeArea.createFromBrandName(brandName, SlideMenuFreeArea)
  const [freeArea, topFreeArea] = await freeAreaService.fetch()

  return {
    ...state,
    freeArea: {
      top: topFreeArea,
      middle: freeArea,
    },
  }
}
