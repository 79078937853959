import { VNodeDirective } from 'vue'
import { DirectiveOptions } from 'vue/types/options'

const msDefaultTimer = 5000

const autoRedirect: DirectiveOptions = {
  bind(_: HTMLElement, binding: VNodeDirective) {
    const timer = binding.arg ? Number(binding.arg) : msDefaultTimer
    const to = binding.value

    setTimeout(
      () => location.href = to,
      timer
    )
  },
}

export default autoRedirect
