import AbstractValue from '@/models/app-config/behavior/abstract-value'
import CompanyKind, { RawCompanyKind } from './company/kind'

export interface RawCompany {
  kind: RawCompanyKind
}

export default class Company extends AbstractValue<RawCompany> {
  get kind(): CompanyKind {
    return new CompanyKind(this.raw.kind)
  }
}
