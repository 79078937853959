import { Response } from 'superagent'
/**
 * この手続をclientで行うこと自体がおかしい.
 * 対応可能なアーキテクチャの設計と実装が完了されるまでの緊急回避.
 *
 * この実装をどんどん拡張していく想定は無いし、削除可能になればすぐに削除する.
 * そうした位置づけなので、この関数は積極的に使うべきではないし、仕組みとして組み込まれるべきでもない.
 *
 * なるべく独立させておいて、容易に削除できるようにしておく.
 */
export function handleFaildToEnterShopError(errorResponse: Response): void {
  if (errorResponse.body.status !== 'FailToEnterShopException') {
    return
  }

  const currentPath = location.pathname.replace(/^\//, '')

  location.href = `/entrance/${currentPath}`
}
