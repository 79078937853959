import { CartButtonInItemListState } from '@ajax/vue/store/itemlist/cart-button/types'
import { RootState } from '@ajax/vue/store/types'
import uuidv4 from 'uuid/v4'
import { ActionTree } from 'vuex'

export const actions: ActionTree<CartButtonInItemListState, RootState> = {
  async publishNewButtonId(): Promise<string> {
    return uuidv4()
  },
  async startProcess({ commit }, payload: { buttonId: string }): Promise<void> {
    commit('startProcess', payload)
  },
  async stopProcess({ commit }): Promise<void> {
    commit('stopProcess')
  },
}
