import _ from 'lodash'

import SKUStock from '@/models/item/sku/stock'
import IStock from '@/models/item/stock'
import {
  DisableDisplayStockValue,
  DisplayStockValue,
  EnableDisplayStockValue,
} from '@/models/item/stock/value/display-stock-value'
import StockValue from '@/models/item/stock/value/stock-value'
import WebStockValue from '@/models/item/stock/value/web-stock'

export default class Stock implements IStock {
  constructor(private readonly skuStocks: SKUStock[]) { }

  get web(): WebStockValue {
    return new WebStockValue(
      this.getStockAmount(stock => stock.web)
    )
  }
  get display(): DisplayStockValue {
    const allSkuDisplayStockDisabled = this.skuStocks.every(stock => stock.display.isDisable)

    if (allSkuDisplayStockDisabled) {
      return DisableDisplayStockValue.value
    }

    return new EnableDisplayStockValue(
      this.getStockAmount(stock => stock.display)
    )
  }

  private getStockAmount(functor: (stock: SKUStock) => StockValue): number {
    const values = this.skuStocks.map(functor)
    const stocksWithoutEmpty = values.filter(v => v.exists)

    if (stocksWithoutEmpty.length === 0) {
      return 0
    }

    const amounts = stocksWithoutEmpty.map(stock => stock.amount)

    return _.min(amounts) || 0
  }
}
