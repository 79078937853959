import AbstractValue from '@/models/app-config/behavior/abstract-value'
import Notification, { RawNotification } from './mypage/notification'

export interface RawMypage {
  notification: RawNotification
}

export default class Mypage extends AbstractValue<RawMypage> {
  get notification(): Notification {
    return new Notification(this.raw.notification)
  }
}
