


















import _ from 'lodash'
import { Mixins } from 'vue-mixin-decorator'
import { Component } from 'vue-property-decorator'

import { HOST_ID } from '@/util/appid'
import ApiMixin from '../../mixin/Api'

@Component
export default class Search extends Mixins<ApiMixin>(ApiMixin) {

  // 表示用データ
  data: any = {
    brand: [],
    category: [],
  }

  // モデル化により冗長処理の共通化を可能とする
  model: any = {
    brand: {
      name: 'ブランド',
      display: 'name',
      condition: {
        isRecursion: false,
        key: 'id',
        endpoint: `/api/common/brand`,
        query: {},
      },
    },
    category: {
      name: 'カテゴリー',
      display: 'title',
      condition: {
        isRecursion: true,
        key: 'name',
        endpoint: `/api/common-proxy/${HOST_ID}/tags`,
        query: {
          pathname: 'company.product.category',
          scope: 'tree',
        },
      },
    },
  }

  mounted() {
    this.search()
  }

  search(): void {

    _.map(this.model, (model, prefix) => {
      this.$get(model.condition.endpoint, model.condition.query)
        .then((response) => {
          if (model.condition.isRecursion) {
            this.$set(this.data, prefix, response.body.data.children)
          } else {
            this.$set(this.data, prefix, response.body.data)
          }
        })
    })
  }

}
