import { CommerceSalesOrder } from '@/models/api/commerce-sales-order'
import {
  ErrorResponse,
  OrderPurchaseFail as PurchaseFail,
  OrderPurchaseResult as PurchaseResult,
  OrderPurchaseSuccess as PurchaseSuccess,
} from '../../../services/order'

import { SelectOptionList } from '@/models/api/payment/parameter/type/user-select'
import { Payment as ApiPayment } from '@/models/api/payment/payment'
import { PaymentType } from '@/models/app-config/behavior/types'
import { ResolverResource } from '@/services/payment/variable-resolver'

export const initialOrderConfirmState: OrderConfirmState = {
  cartIds: null,
  payment: null,
  delivery: null,
  deliveryDate: null,
  couponCode: null,
  point: {
    applied: 0,
    discounted: 0,
  },
  complete: null,
}

export interface Delivery {
  deliveryType: string
  id: string
  deliveryMethod: string
}

export interface DeliveryDate {
  deliveryPlanDate: string
  deliveryTimeSlot: string
}

/**
 * 見積もり・決済時のポイント利用パラメータ
 * @see https://my-color.esa.io/posts/559
 */
export interface Point {
  kind: 'point',
  amount: number,
}

/**
 * TODO: Separate each type definition for each paymentType
 * TODO: @/services/payment/types のPaymentを利用するよう置換していく
 *
 * lastCreditDateは注文確定リクエストまでの中間データとしてのみ必要だったり、
 * 逆にpaymentid, auth_tokenなどは注文確定リクエストのpayloadとしてのみ必要だったりするので
 * 最終的には中間データと注文確定リクエストのpayloadとで分けるのが良さそう
 *
 * そもそも、このデータ型が何を意味するものなのか、不明瞭？
 * store管理のためのデータ構造なのか、APIパラメータのデータ構造なのか.
 * 前者と捉えるには最適化されていないし、後者と捉えるにはoptional含めノイズが多い.
 * 実質、APIパラメータ用データを直接保持しつつ、計算に便利な値をついでに生やしている、という格好になっているか.
 *
 * アプリケーション管理の範囲と外部サービス依存の範囲が暗黙的に混濁し結合した状態になっているので、
 * 両者をそれぞれ分離した上で、透過的に使得る形にした方が良いか、両者を完全に分断するか検討した方が良さそうか.
 * {@link https://github.com/my-color/front/pull/6787#discussion_r932773438} も参照.
 */
export interface Payment {
  payment_type: PaymentType,
  point?: Point,

  token?: string, // creditcard
  numberLast4?: string // creditcard
  cardType?: string // creditcard
  lastCreditDate?: string // creditcard
  paymentid?: string, // atone | paidy
  auth_token?: string, // atone
  auth_pass_full_name?: string, // creditcard(kuroneko)
  auth_pass_tel?: string, // creditcard(kuroneko)
  auth_pass_email?: string, // creditcard(kuroneko)
  /**
   * クレジットカードの場合 & サブスクリプションの場合のみ、
   * Engine都合で app_kind というキーを指定する必要がある
   * @see https://github.com/my-color/front/issues/5636
   * @see https://my-color.esa.io/posts/707
   */
  app_kind?: string // creditcard

  // 支払い方法一覧APIから取得するオブジェクトをベースにstoreするパターン
  // コンビニ決済とキャリア決済で利用
  // 今後は上の個別のパラメータ管理をやめ、こちらに寄せる予定
  // apiというprefixはAPIから取得したことを強調するために付けているが、
  // すべての決済方法がApiPaymentを利用するようになればprefixは削除する
  apiPayment?: ApiPayment,
  selectedOptionList?: SelectOptionList,
  variableResource?: ResolverResource,
}

// FIXME: Until common 'Payment' is moved away with refactoring, make sure this type within 'Payment' keys.
export interface SimplePayment {
  payment_type: PaymentType
  point?: Point,
}

/**
 * TODO: Separate interface of 'kuroneko' and other creditcard types.
 * FIXME: Until common 'Payment' is moved away with refactoring, make sure this type within 'Payment' keys.
 * FIXME: Payment と定義が二重化している? ↑がstore用で↓がパラメータ? 役割分担が不明. 影響範囲が大きくなるのでついでの統合は難しそう.
 * クレジットカード用パラメータが増えるたびに二箇所に定義追加するのは無駄なので、一元化したい & するべき
 *
 * @see mutations.setPaymentToCreditcard
 */
export interface CreditcardPayment {
  payment_type: 'creditcard'
  token: string,
  numberLast4: string,
  cardType: string,
  lastCreditDate?: string
  /**
   * クロネコによるカード決済専用
   * @see https://github.com/my-color/front/issues/1347
   */
  auth_pass_full_name?: string,
  /**
   * クロネコによるカード決済専用
   * @see https://github.com/my-color/front/issues/1347
   */
  auth_pass_tel?: string,
  /**
   * クロネコによるカード決済専用
   * @see https://github.com/my-color/front/issues/1347
   */
  auth_pass_email?: string,
  /**
   * 定期購入でクレジットカードを利用する際、Engine都合により
   * 「クレジットカードで決済したとき、そのカードをどの購入方法で利用したのか」を区別する必要がある.
   * そのため、 frontから app_kind として その利用方法を明示することが要求されている.
   *
   * @see https://my-color.esa.io/posts/707
   */
  app_kind?: string,
  point?: Point,
}

export const isCreditCard = (payment: Payment): payment is CreditcardPayment => {
  return payment.payment_type === 'creditcard'
}

// FIXME: Until common 'Payment' is moved away with refactoring, make sure this type within 'Payment' keys.
export interface AtonePayment {
  payment_type: 'atone'
  paymentid?: string,
  auth_token?: string
  point?: Point,
}

export const isAtone = (payment: Payment): payment is AtonePayment => {
  return payment.payment_type === 'atone'
}

// FIXME: Until common 'Payment' is moved away with refactoring, make sure this type within 'Payment' keys.
export interface PaidyPayment {
  payment_type: 'paidy'
  paymentid?: string,
  point?: Point,
}

export const isPaidy = (payment: Payment): payment is PaidyPayment => {
  return payment.payment_type === 'paidy'
}

export interface Complete {
  orderResults: OrderResult[]
  bundleDiscountPrice: number
  couponDiscountPrice: number
  isFirstShareItem: boolean
  isFreeShipping: boolean
  canScheduleDelivery: boolean
}
export interface OrderResult extends PurchaseResult {
  commerceSalesOrder: CommerceSalesOrder | null
}

export interface OrderResultSuccess extends OrderResult, PurchaseSuccess  {
  commerceSalesOrder: CommerceSalesOrder
  success: true
  error: null
}

export interface OrderResultFail extends OrderResult, PurchaseFail {
  commerceSalesOrder: null
  success: false
  error: ErrorResponse
  redirectHtml: undefined
}

export interface OrderConfirmState {
  cartIds: string[] | null,
  payment: Payment | null,
  delivery: Delivery | null,
  deliveryDate: DeliveryDate | null,
  couponCode: string | null,
  point: AppliedPoint,
  complete: Complete | null
}

export interface AppliedPoint {
  applied: number
  discounted: number
}
