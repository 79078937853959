import _ from 'lodash'
import { ActionTree } from 'vuex'

import { PurchaseService } from '../../../services/api/purchase'
import { RootState } from '../../types'
import { PurchaseState } from './types'

export const actions: ActionTree<PurchaseState, RootState> = {

  async load({ commit, state }, payload?: { force?: boolean }) {
    if (!_.get(payload, 'force') && !_.isEmpty(state.items)) {
      return
    }

    const result = (await new PurchaseService().list())

    if (result.isLeft()) {
      const error = result.value

      commit('errorOnLoad', error)

      return
    }

    const items = result.value.body.data

    commit('list', items)
  },
}
