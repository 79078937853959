































































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import AjaxConditionModalItem from '@ajax/vue/components/organisms/condition/ConditionModalItem.vue'

@Component({
  components: {
    AjaxConditionModalItem,
  },
})
export default class ConditionDropdown extends Vue {
  /**
   * 再帰オブジェクトデータ
   *   子データは[children]キーで保持する
   */
  @Prop()
  recursion: any

  // TODO: v-model での利用による Vue Warn を回避する
  // https://github.com/my-color/front/pull/2654#issuecomment-545497359
  @Prop()
  checked: any[]

  @Prop({ default: false })
  isChildren: boolean

  open: boolean = false

  mounted() {
    if (this.hasChildren && this.hasCheckedInChildrenIfParentUnchecked()) {
      this.open = true
    }
  }

  toggle() {
    if (this.hasChildren) {
      this.open = !this.open
    }
  }

  toggleJustOnce(event) {
    // label要素やi要素のclickイベントを購読しない
    if (event.target.tagName.toUpperCase() === 'INPUT') {
      this.toggle()
    }
  }

  change(checked, item) {
    this.$emit('change', checked, item)
  }

  enter(el) {
    el.style.height = 'auto'
    const height = getComputedStyle(el).height
    el.style.height = 0
    setTimeout(() => {
      el.style.height = height
    })
  }

  afterEnter(el) {
    el.style.height = 'auto'
  }

  leave(el) {
    const height = getComputedStyle(el).height
    el.style.height = height
    setTimeout(() => {
      el.style.height = 0
    })
  }

  get hasChildren() {
    return this.recursion && this.recursion.children && this.recursion.children.length
  }

  get status() {
    if (this.hasChildren) {
      return this.open ? 'fa-chevron-down show' : 'fa-chevron-down'
    }

    return ''
  }

  get topStatus() {
    if (!this.isChildren) {
      return this.hasCheckedInChildren() ? 'includes__checked' : ''
    }

    return ''
  }

  hasCheckedInChildren(): boolean {
    let hasChecked = false
    const hasCheckedInChildren = (item, target) => {
      if (target.id === item.id) {
        hasChecked = true

        return true
      }
      if (item.children) {
        item.children.some(child => hasCheckedInChildren(child, target))
      }

      return false
    }
    const recursion = this.recursion
    this.checked.some(target => hasCheckedInChildren(recursion, target))

    return hasChecked
  }

  /**
   * for if checked some child,
   * show children checkbox
   */
  hasCheckedInChildrenIfParentUnchecked(): boolean {
    let hasChecked = false
    const hasCheckedInChildren = (item, target, isStartParent) => {
      if (target.id === item.id) {
        // if parent is checked return false
        if (isStartParent) {
          hasChecked = false

          return true
        }
        hasChecked = true

        return true
      }
      if (item.children) {
        item.children.some(child => hasCheckedInChildren(child, target, false))
      }

      return false
    }

    const recursion = this.recursion
    this.checked.some(target => hasCheckedInChildren(recursion, target, true))

    return hasChecked
  }
}
