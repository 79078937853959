import AbstractValue from '@/models/app-config/behavior/abstract-value'
import Border, { RawBorder } from './few/border'

export interface RawFewBadge {
  border: RawBorder
  optionClass: string | null
}

export default class FewBadge extends AbstractValue<RawFewBadge> {
  get border(): Border {
    return new Border(this.raw.border)
  }

  get optionClass(): string | null {
    return this.raw.optionClass
  }
}
