import Vue, { VueConstructor } from 'vue'

import { CatalogDemandDto } from '@/models/api/catalog/catalog-demand'
import { CartService } from '@ajax/modules/services/cart'
import CatalogDemandDisabled from '../button/CatalogDemandDisabled.vue'
import CatalogDemandEnabled from '../button/CatalogDemandEnabled.vue'
import CatalogDemandRetry from '../button/CatalogDemandRetry.vue'

export interface CatalogDemandState {
  readonly button: VueConstructor
  readonly isLoading: boolean

  onClick(): Promise<void>
}
export class AsLoading implements CatalogDemandState {
  readonly button: VueConstructor = Vue.extend({
    template: '<div></div>', // ローディング中の表示や振る舞いが増えるならコンポーネント化
  })
  readonly isLoading = true

  async onClick() {
    // nothing to do
  }
}
export class AsDisable implements CatalogDemandState {
  readonly button: VueConstructor = CatalogDemandDisabled
  readonly isLoading = false

  async onClick() {
    // nothing to do
  }
}

export class AsEnable implements CatalogDemandState {
  static create(brand: string, catalog: CatalogDemandDto): CatalogDemandState {
    const cartService = CartService.createFromBrandEnglishName(brand)

    return new AsEnable(brand, catalog, cartService)
  }
  readonly button: VueConstructor = CatalogDemandEnabled
  readonly isLoading = false

  constructor(
    private readonly brand: string,
    private readonly catalog: CatalogDemandDto,
    private readonly cartService: CartService
  ) { }

  async onClick() {
    const resultAddToCart = await this.cartService.post({
      productId: this.catalog.id,
      unitPrice: this.catalog.price,
      isPaidMembershipPrice: false,
      unitCount: 1, // add only one at once
    })

    resultAddToCart.map(() => {
      location.href = `/s/${this.brand}/cart`
    }).mapLeft((error) => {
      throw error
    })
  }
}
export class AsRetry implements CatalogDemandState {
  readonly button: VueConstructor = CatalogDemandRetry
  readonly isLoading = false

  async onClick() {
    location.reload()
  }
}
