import loglevel from 'loglevel'

if (process.env.FRONT_ENV === 'production') {
  loglevel.setLevel('warn')
} else {
  loglevel.setLevel('debug')
}
if (
  process.env.NODE_ENV === 'test' &&
  (process.env.ENABLE_LOG_ON_TEST || 'false') === 'false'
) {
  loglevel.disableAll()
}

export const log = loglevel
