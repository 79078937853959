



































import { orderByValue } from '@/util/collection/order-by-value'

// TODO: このコンポーネントは、粒度としてはmoleculesから逸脱しているので、page + templateとして定義したい。
// templateで現時点のhtml構造を定義し、pageから、APIから取得した実データを注入する。
import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

import BundlePurchaseFkuList from '@/components/molecules/bundle-purchase/BundlePurchaseFkuList.vue'
import BundlePurchaseModal from '@/components/molecules/bundle-purchase/BundlePurchaseModal.vue'
import BundlePurchaseNavigation from '@/components/molecules/bundle-purchase/BundlePurchaseNavigation.vue'
import ErrorDialog from '@/components/organisms/dialog/ErrorDialog.vue'
import { log } from '@/log'
import { ApiProductclass } from '@/models/api/productclass'
import BehaviorConfig from '@/models/app-config/behavior/behavior'
import * as CurrentTime from '@/models/current-time/functions'
import GetStockStatus from '@/usecase/get-stock-status'
import {
  ErrorModalState,
  FailedToFetchFkuListError,
  FailedToFetchRootItemError,
  NoError,
} from './model/bundle-purchase-error-modal-state'
import { BundlePurchaseItem } from './model/bundle-purchase-item'
import LoadFkuList from './model/command/initialize/load-fku-list'
import HasItemsThanRequired from './model/specification/add-items-to-cart/each/list-has-items-more-than-required-count'
import { AddItemsToCartSpec, AddItemsToCartSpecFactory } from './model/specification/add-items-to-cart/index'
import ItemMustHaveStock from './model/specification/add-to-purchase-list/each/item-must-have-stock'
import ItemMustInRegularSale from './model/specification/add-to-purchase-list/each/item-must-in-regular-sale-period'
import LimitPurchaseListSize from './model/specification/add-to-purchase-list/each/limit-purchase-list-size'
import NotAddMoreThanStock from './model/specification/add-to-purchase-list/each/must-not-add-to-list-more-than-stock'
import {
  AddToPurchaseListSpec,
  AddToPurchaseListSpecFactory,
} from './model/specification/add-to-purchase-list/index'

const orderFkuById = orderByValue<ApiProductclass, string>(i => i.id)()

@Component({
  components: {
    BundlePurchaseFkuList,
    BundlePurchaseModal,
    BundlePurchaseNavigation,
    ErrorDialog,
  },
})
export default class BundlePurchaseContainer extends Vue {
  @Prop({ required: true })
  brand: string

  @Prop({ required: true })
  fkuIds: string[]

  @Prop({ required: true })
  requiredItemCount: number

  @Prop({ required: true })
  priceOnBundled: number

  fkuListForItemListView: ApiProductclass[] = []
  skuListAddedToCartAsBundlePurchase: BundlePurchaseItem[] = []
  fkuMaybeItsSkuAddedToCartAsBundlePurchase: ApiProductclass | null = null

  errorModalState: ErrorModalState = new NoError()

  async created() {
    const initializers = [
      LoadFkuList.withBrand(this.brand)
              .withFkuIds(this.fkuIds)
              .onLoad(items => this.fkuListForItemListView = [
                ...this.fkuListForItemListView,
                ...items,
              ]),
    ].map(command => command.run())

    const [
      loadFkuResult,
    ] = await Promise.all(initializers)

    loadFkuResult.mapLeft(
      (error) => {
        log.error({ error })

        this.errorModalState = new FailedToFetchFkuListError()
      }
    )
  }

  get orderedFkuList(): ApiProductclass[] {
    return orderFkuById(this.fkuListForItemListView, this.fkuIds)
  }

  get showModal(): boolean {
    return this.fkuMaybeItsSkuAddedToCartAsBundlePurchase !== null
  }

  get addToPurchaseListSpec(): AddToPurchaseListSpec {
    const getStockStatus = GetStockStatus.createViaConfig(
      BehaviorConfig.createFromBrand(this.brand)
    )

    const factory = new AddToPurchaseListSpecFactory([
      new LimitPurchaseListSize({
        limit: this.requiredItemCount,
        currentList: this.skuListAddedToCartAsBundlePurchase,
      }),
      new ItemMustHaveStock(getStockStatus),
      new ItemMustInRegularSale(CurrentTime.create().now),
      new NotAddMoreThanStock(
        this.skuListAddedToCartAsBundlePurchase,
        getStockStatus
      ),
    ])

    return factory.create()
  }

  get addItemsToCartSpec(): AddItemsToCartSpec {
    const factory = new AddItemsToCartSpecFactory([
      new HasItemsThanRequired(this.requiredItemCount),
    ])

    return factory.create()
  }

  get totalPriceInList(): number {
    return this.skuListAddedToCartAsBundlePurchase
      .map(item => item.price.regular.withConsumptionTax)
      .reduce((sum, price) => sum + price, 0)
  }

  onSelectFkuWhichItsSkuAddToCart(selectedFku: ApiProductclass) {
    this.fkuMaybeItsSkuAddedToCartAsBundlePurchase = selectedFku
  }

  handleErrorOnFetchItemDetail() {
    this.errorModalState = new FailedToFetchRootItemError()
  }
  onAddItem(skuItem: BundlePurchaseItem) {
    this.addItem(skuItem)
  }

  onRemoveItem(listIndex: number) {
    this.removeItem(listIndex)
  }

  onCloseModal() {
    this.fkuMaybeItsSkuAddedToCartAsBundlePurchase = null
  }

  addItem(skuItem: BundlePurchaseItem) {
    this.skuListAddedToCartAsBundlePurchase = this.skuListAddedToCartAsBundlePurchase.concat(skuItem)
  }

  removeItem(listIndex: number) {
    // TODO: 可読性を損なわない範囲で可能なら、明示的な変更に修正する
    this.skuListAddedToCartAsBundlePurchase.splice(listIndex, 1)
  }

  onCloseErrorModal() {
    this.errorModalState = new NoError()
  }
}
