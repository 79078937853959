import Calculator from './calculator'

export default class SubtractionCalculator implements Calculator {
  /**
   * @param number discountPrice Discount price, like -¥1,000
   */
  public constructor(public discountPrice: number) {
  }

  calculateDiscountPrice(originalPrice: number): number {
    return Math.min(this.discountPrice, originalPrice)
  }
}
