import { PayloadSetPaymentToCreditcard } from '@spa/store/modules/orderConfirm/actions'
import { MutationTree } from 'vuex'

import { AppliedPoint, OrderConfirmState } from './types'

import { log } from '@/log'
import { Payment } from '@spa/store/modules/orderConfirm/types'

const namespace = 'orderConfirm'

export const mutations: MutationTree<OrderConfirmState> = {
  setPaymentToCreditcard(
    state,
    payload: PayloadSetPaymentToCreditcard
  ) {
    state.payment = {
      payment_type: 'creditcard',
      ...payload,
    }

    log.debug({ commit: `${namespace}/setPaymentToCreditcard`, state })
  },

  setPaymentToCashOnDelivery(state) {
    state.payment = {
      payment_type: 'cash-on-delivery',
    }

    log.debug({ commit: `${namespace}/setPaymentToCashOnDelivery`, state })
  },

  setPaymentToNpPostpay(state) {
    state.payment = {
      payment_type: 'np-postpay',
    }

    log.debug({ commit: `${namespace}/setPaymentToNpPostpay`, state })
  },

  setPaymentToAtone(state) {
    state.payment = {
      payment_type: 'atone',
      paymentid: '',
      auth_token: '',
    }

    log.debug({ commit: `${namespace}/setPaymentToAtone`, state })
  },

  setPaymentToPaidy(state) {
    state.payment = {
      payment_type: 'paidy',
      paymentid: '',
    }

    log.debug({ commit: `${namespace}/setPaymentToPaidy`, state })
  },

  setPayment(state, payload: { payment: Payment }) {
    state.payment = payload.payment

    log.debug({ commit: `${namespace}/setPayment`, state })
  },

  unsetPayment(state) {
    state.payment = null

    log.debug({ commit: `${namespace}/unsetPayment`, state })
  },

  initializeDelivery(state, payload: { id: string, presetMethod?: string }) {
    // プリセット由来で取得できるようになったら、presetMethodを非optionalに変更
    const { id, presetMethod } = payload

    state.delivery = {
      deliveryType: 'registered',
      id,

      // 初期値として既存配送方法（宅急便）固定で良いか？それともこの時点でプリセットから取得するか。
      // プリセットの用意が手間 -> 一旦固定値で、後でプリセット由来に変更
      // プリセットの用意がそれほど手間でない -> プリセット由来に変更
      deliveryMethod: presetMethod || 'ヤマト宅急便',
    }

    log.debug({ commit: `${namespace}/setDelivery`, state })
  },

  setDeliveryMethod(state, payload: { method: string }) {
    if (state.delivery) {
      const { method } = payload
      state.delivery = {
        deliveryType: state.delivery.deliveryType,
        id: state.delivery.id,
        deliveryMethod: method,
      }
    } else {
      log.warn(`${namespace}/setDeliveryMethod was ignored because delivery is null`, state)
    }

    log.debug({ commit: `${namespace}/setDeliveryMethod`, state })
  },

  unsetDelivery(state) {
    state.delivery = null

    log.debug({ commit: `${namespace}/unsetDelivery`, state })
  },

  setDeliveryDate(state, payload: { deliveryPlanDate: string, deliveryTimeSlot: string }) {
    state.deliveryDate = payload

    log.debug({ commit: `${namespace}/setDeliveryDate`, state })
  },

  setCouponCode(state, couponCode) {
    state.couponCode = couponCode
    log.debug({ commit: `${namespace}/setCouponCode`, state })
  },

  applyPoint(state, applied: AppliedPoint) {
    state.point = applied

    log.debug({ commit: `${namespace}/applyPoint`, state })
  },

  setComplete(state, payload) {
    state.complete = payload

    log.debug({ commit: `${namespace}/setComplete`, state })
  },
}
