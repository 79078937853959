import _ from 'lodash'

import { CouponCode } from '@/models/api/coupon/coupon-code/coupon-code'
import { CartItem } from '@/models/cart/cart-item'
import {
  CouponWithSpecifiedItems
} from '@/models/estimate/usecase/coupon-discount/extract-cart-items-applicable-to-coupons'

/**
 * 商品指定というクーポンの適用可能性と、クーポンの指定方法(e.g. クーポンコードにより指定)は、直交する概念である。
 * したがって本来は、このクラスがさだめる商品指定周りのロジックを、CouponCodeから分離できるようにしておくのが望ましい。
 * ただしそのためには、
 * - ApiCouponに依存した既存の仕組みの手術が必要になるのと、
 * - いまのところ商品指定はクーポンコード形式でのみ実現される。ユーザに「どの商品には使えます」という情報を示す導線の関係と思われる。
 * という理由から、いったんは商品指定×クーポンコードという組合せをハードコードする仕方でこのクラスを定義しておく。
 *
 * 将来的には、いくつか手術の戦略が考えられる:
 * - クーポン型そのものに、適用可能性と指定方法をコンストラクタ引数で指定できるようにする。この場合、このクラスのような子クラスを定義しなくてもよい。
 * - 適用可能性と指定方法をそれぞれミックスインとして定義する。https://typescript-jp.gitbook.io/deep-dive/type-system/mixins
 * - 商品指定方法や指定方法を、基底クラスに埋めこむ。これは基底クラスが神クラス化するので避けたい。
 */
export class ItemsSpecifiedCouponCode extends CouponCode implements CouponWithSpecifiedItems {
  constructor(
    apiData: any,
    /**
     * 商品を指定するためのIDとして、FKU IDを使用する。
     * https://github.com/my-color/front/issues/5157#issuecomment-842080668
     * https://github.com/my-color/front/issues/5157#issuecomment-842102143
     *
     * FKU IDを使用することは現状ではプロダクト全体で比較的安定していると考えられるので、ここでデフォルト値としておく。
     * このあたりの事情が流動化してきたら（e.g. ブランドやクーポンの種類によって、SKUを参照する）、適宜修正してください。
     */
    private readonly specificationIdPolicy: SpecificationIdPolicy = new SpecificationByFkuIdPolicy()
  ) {
    super(apiData)
  }

  isApplicableFor(cartItem: CartItem): boolean {
    return super.isApplicableFor(cartItem) &&
      this.specifiedItems.includes(
        this.specificationIdPolicy.getSpecificationId(cartItem)
      )
  }

  get specifiedItems(): string[] {
    /**
     * 空の場合は、デフォルト値として「クーポンが適用できる商品の配列は空」、つまり「クーポンはどの商品にも適用できない」と解釈する。
     */
    return this.get('content.ids', [])
  }
}

export function isItemsSpecifiedCouponCode(item: any): boolean {
  /**
   * クーポンの種類が商品指定か否かのフラグにより判定する。
   * なおフラグとは別に、具体的にどの商品が指定されているかを示す情報も存在する。
   * その情報に不備がある場合（キーが存在しない、キーは存在するが値がfalsy）に、商品指定クーポンコードとしてどう振る舞うかは別途定められる。
   */
  return _.get(item, 'content.discount_target') === 'ids'
}

export interface SpecificationIdPolicy {
  getSpecificationId(cartItem: CartItem): string
}

class SpecificationByFkuIdPolicy implements SpecificationIdPolicy {
  getSpecificationId(cartItem: CartItem): string {
    return cartItem.fkuId
  }
}
