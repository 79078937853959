import AbstractValue from '@/models/app-config/behavior/abstract-value'
import ItemListFilter, { RawItemListFilter } from './item-list/filter'
import ItemOnItemList, { RawItemOnItemList } from './item-list/item'

export interface RawItemList {
  item: RawItemOnItemList
  filter: RawItemListFilter
}

export default class ItemList extends AbstractValue<RawItemList> {
  get item(): ItemOnItemList {
    return new ItemOnItemList(this.raw.item)
  }

  get filter(): ItemListFilter {
    return new ItemListFilter(this.raw.filter)
  }
}
