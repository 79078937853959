import { BundlePurchaseItem } from '../../../bundle-purchase-item'
import { AddItemsToCartSpec } from '../index'

export default class ListHasItemsMoreThanRequiredCount implements AddItemsToCartSpec {
  constructor(private readonly requiredCount: number) { }

  isSatisfiedBy(purchaseList: BundlePurchaseItem[]): boolean {
    return purchaseList.length >= this.requiredCount
  }
}
