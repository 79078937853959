export function resolveAllWithRandom() {
  $('.js-split-resolve-list--random').each((_i: number, wrapper: HTMLElement) => {
    const splits = $(wrapper).children('.js-split-resolve-item')
    const random = Math.floor(Math.random() * splits.length)
    $(splits).each((index: number, split: HTMLElement) => {
      if (index !== random) {
        $(split).remove()
      }
    })
    $(wrapper).show()
  })
}
