








import Vue from 'vue'
import { Component, Emit, Prop } from 'vue-property-decorator'

import ErrorModal from './ErrorModal.vue'

import { ErrorContent } from '@/store/modules/error/types'

@Component({
  components: {
    ErrorModal,
  },
})
export default class ErrorModalContainer extends Vue {
  @Prop({ default: false })
  keep: boolean

  get error(): ErrorContent | null {
    return this.$store.state.error.error
  }

  @Emit()
  close() {
    // nothing to do
  }
}
