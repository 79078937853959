export interface AppSetting {
  content: {
    scheduled_delivery: boolean,
    service_fee?: {
      percent?: number,
    },
  },
}

export class ApiAppSetting {
  protected _data: AppSetting

  public constructor(apiData: AppSetting) {
    this._data = apiData
  }

  get scheduledDelivery(): boolean {
    return this._data.content.scheduled_delivery
  }

  /**
   * @deprecated 最終的に廃止するため、既存以上の再利用は原則禁止
   * @see https://github.com/my-color/front/issues/6325
   */
  get serviceFeePercent(): number | null {
    if (!this._data.content.service_fee) {
      return null
    }

    return this._data.content.service_fee.percent || null
  }
}
