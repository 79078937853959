import { Either, Left, Right } from 'fp-ts/lib/Either'
import { Response } from 'superagent'

import { log } from '@/log'
import { ApiService } from '@/services/api/service'
import { ApiClient } from './client'

const namespace: string = 'contact'

export class ContactService extends ApiService {

  async list(query?: any): Promise<Either<Error, Response>> {
    try {
      const response = await ApiClient
        .get(`/api/user-proxy/${this.appId}/commerce_user_contact/`)
        .query(query)

      log.debug({ service: `${namespace}/list`, response })

      return new Right(response)
    } catch (error) {
      log.error({ error })

      return new Left(error)
    }
  }

  async get(id: string): Promise<Either<Error, Response>> {
    try {
      const response = await ApiClient
        .get(`/api/user-proxy/${this.appId}/commerce_user_contact/${id}`)

      log.debug({ service: `${namespace}/get`, response })

      return new Right(response)
    } catch (error) {
      log.error({ error })

      return new Left(error)
    }
  }

  async create(payload: ApiContact, query: any = {}): Promise<Either<Error, Response>> {
    try {
      const response = await ApiClient
        .post(`/api/commerce_user_contact`)
        .query(query)
        .send(payload)

      log.debug({ service: `${namespace}/create`, response })

      return new Right(response)
    } catch (error) {
      log.error({ error })

      return new Left(error)
    }
  }

  async update(id: string, payload: ApiContact): Promise<Either<Error, Response>> {
    try {
      const response = await ApiClient
        .put(`/api/commerce_user_contact/${id}`)
        .send(payload)

      log.debug({ service: `${namespace}/update`, response })

      return new Right(response)
    } catch (error) {
      log.error({ error })

      return new Left(error)
    }
  }

  async remove(id: string): Promise<Either<Error, Response>> {
    try {
      const response = await ApiClient
        .delete(`/api/user-proxy/${this.appId}/commerce_user_contact/${id}`)

      log.debug({ service: `${namespace}/remove`, response })

      return new Right(response)
    } catch (error) {
      log.error({ error })

      return new Left(error)
    }
  }
}

// TODO: Refactoring
export interface ApiContact {
  id?: string
  location_kind?: number
  postal_code?: string
  region?: string
  locality?: string
  street_address?: string
  extended_address?: string
  pobox?: string
  supplement?: string
  first_name?: string
  last_name?: string
  phonetic_first_name?: string
  phonetic_last_name?: string
  tel?: string
  display_level?: number
  is_delivery?: boolean
  is_billing?: boolean
  country_code?: string
  region_code?: string
  locality_code?: string
}
