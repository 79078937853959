import _ from 'lodash'
import superagent from 'superagent'

export class ApiClient {
  static get(url: string): superagent.SuperAgentRequest {
    const request = superagent
      .get(url)
      .withCredentials()
      .set({ Accept: 'application/json' })

    if (!this.shouldBasicAuth()) {
      return request
    }

    const { id, password } = this.getBasichAuthInfo()

    return request.auth(id, password)
  }

  static post(url: string): superagent.SuperAgentRequest {
    const request = superagent
      .post(url)
      .withCredentials()
      .set({
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
      })
      .type('form')

    if (!this.shouldBasicAuth()) {
      return request
    }

    const { id, password } = this.getBasichAuthInfo()

    return request.auth(id, password)
  }

  static postMultipart(url: string): superagent.SuperAgentRequest {
    const request = superagent
      .post(url)
      .withCredentials()
      .set({
        Accept: 'application/json',
        'Cache-Control': 'no-cache',
      })

    if (!this.shouldBasicAuth()) {
      return request
    }

    const { id, password } = this.getBasichAuthInfo()

    return request.auth(id, password)
  }

  static postPlain(endpoint: string): superagent.SuperAgentRequest {
    const request = superagent
      .post(endpoint)
      .withCredentials()
      .set('Accept', 'application/json')
      .set('Content-Type', 'text/plain')

    if (!this.shouldBasicAuth()) {
      return request
    }

    const { id, password } = this.getBasichAuthInfo()

    return request.auth(id, password)
  }

  static put(url: string): superagent.SuperAgentRequest {
    const request =  superagent
      .put(url)
      .withCredentials()
      .set({
        Accept: 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      })
      .type('form')

    if (!this.shouldBasicAuth()) {
      return request
    }

    const { id, password } = this.getBasichAuthInfo()

    return request.auth(id, password)
  }

  static delete(url: string): superagent.SuperAgentRequest {
    const request =  superagent
      .delete(url)
      .withCredentials()
      .set({ Accept: 'application/json' })

    if (!this.shouldBasicAuth()) {
      return request
    }

    const { id, password } = this.getBasichAuthInfo()

    return request.auth(id, password)
  }

  private static shouldBasicAuth(): boolean {
    const id = process.env.BASIC_AUTH_ID
    const password =  process.env.BASIC_AUTH_PASSWORD

    return !_.isEmpty(id) && !_.isEmpty(password)
  }

  private static getBasichAuthInfo(): { id: string, password: string} {
    return {
      id: process.env.BASIC_AUTH_ID || '',
      password: process.env.BASIC_AUTH_PASSWORD || '',
    }
  }
}
