import { render, staticRenderFns } from "./RegularPrice.vue?vue&type=template&id=ab564c12&scoped=true&"
import script from "./RegularPrice.vue?vue&type=script&lang=ts&"
export * from "./RegularPrice.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ab564c12",
  null
  
)

export default component.exports