import _ from 'lodash'

export interface RawTag {
  id: string
  display_level: number
  follow: boolean
  name: string
  pathname: string
  status_id: number
  title: string
  children?: RawTag[]
}

export class ApiTag {
  constructor(private _data: any) {}

  get id(): string {
    return this.get('id', '')
  }

  get pathname(): string {
    return this.get('pathname', '')
  }

  /**
   * @note 将来的に、定期購入の解約可能期間をサーバー経由で取得できるようになったら不要となる想定
   * @see https://github.com/my-color/front/issues/5587
   */
  get pathAtLast(): string {
    return this.pathAt(this.paths.length - 1) || ''
  }

  get children(): ApiTag[] {
    return this.get('children', []).map(c => new ApiTag(c))
  }

  get displayLevel(): number {
    return this.get('display_level', 0)
  }

  get label(): string {
    return this.get('label', '')
  }

  get name(): string {
    return this.get('name', '')
  }

  get title(): string {
    return this.get('title', '')
  }

  isLaterThan(other: ApiTag): boolean {
    return this.displayLevel > other.displayLevel
  }

  convertTo<T extends ApiTag>(converter: (data: any) => T): T {
    return converter(this._data)
  }

  toRaw(): RawTag {
    return {
      ...this._data,
    }
  }

  private get<T>(pathname: string, defaultVal: T): T {
    return _.get(this._data, pathname, defaultVal)
  }
  private get paths(): string[] {
    return this.pathname.split('.')
  }
  private pathAt(index: number): string | null {
    return this.paths[index] || null
  }

}
