import ShippingFee from './index'

/**
 * 常に固定の送料
 */
export default class FixedShippingFee implements ShippingFee {
  constructor(readonly fee: number) { }

  canApply(): boolean {
    return true
  }

  remainingPriceUntilFreeShipping(): number | null {
    return null
  }
}
