import AbstractValue from '@/models/app-config/behavior/abstract-value'

export interface RawCompanyKind {
  label: string
}

export default class CompanyKind extends AbstractValue<RawCompanyKind> {
  get label(): string {
    return this.raw.label
  }
}
