interface BrandPageContext {
  readonly onBrand: true
  readonly brand: string
  readonly onHost: false
  readonly onCrossBrand: false
}
export const createBrandPageContext = (brand: string): BrandPageContext => ({
  brand,
  onBrand: true,
  onHost: false,
  onCrossBrand: false ,
})

interface HostPageContext {
  readonly onBrand: false
  readonly brand: null
  readonly onHost: true
  readonly onCrossBrand: false
}
export const hostPageContext: HostPageContext = {
  brand: null,
  onBrand: false,
  onHost: true,
  onCrossBrand: false,
}

interface CrossBrandPageContext {
  readonly onBrand: false
  readonly brand: null
  readonly onHost: false
  readonly onCrossBrand: true
}
export const crossBrandPageContext: CrossBrandPageContext = {
  brand: null,
  onBrand: false,
  onHost: false,
  onCrossBrand: true,
}

type PageContext = BrandPageContext | HostPageContext | CrossBrandPageContext

export interface WithPageContext {
  readonly page: PageContext
}
